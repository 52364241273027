import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";

var apiUrl = env.url;
var imageUrl = env.img_url;
class Permission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            pagelist: [],
            permissions: [],
            userId: "",
            userName: "",
            userEmail: "",
            userMobile: "",
            show_form: false
        }
    }
    componentDidMount = () => {
        this.getAllAdmin();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    getAllAdmin = () => {
        Axios.get(`${apiUrl}/admin/getadmin`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("users response", res)
            if (res.data.success) {
                this.setState({
                    users: res.data.data.users
                })
            } else {
                this.setState({
                    users: []
                })
            }
        })
    }
    showadmin = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            permissions: [],
            pagelist: []
        })
    }
    vieweditform = (e = null, val) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: !this.state.show_form,
            userId: val.id,
            userName: val.name,
            userEmail: val.email,
            userMobile: val.mobile,
            userPassword: ""
        })
    }
    cancelform = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: !this.state.show_form,
            userId: "",
            userName: "",
            userEmail: "",
            userMobile: "",
            userPassword: ""
        })
    }
    updateAdmin = e => {
        e.preventDefault();
        if (this.state.userName == "") {
            this.notify('error', 'Please enter name.')
        }
        if (this.state.userEmail == "") {
            this.notify('error', 'Please enter email.')
        }
        if (this.state.userMobile == "") {
            this.notify('error', 'Please enter mobile.')
        }
        var lengthcheck = this.state.userMobile.length;
        //  console.log("lenght",lengthcheck);
        if (lengthcheck !== 10) {
            this.notify("error", "Please enter correct mobile number.");
            return false;
        }
        const formdata = new FormData();
        if (this.state.userId && this.state.userId !== "") {
            formdata.append('id', this.state.userId);
        }

        if (this.state.userId != 1) {
            formdata.append('name', this.state.userName);
            formdata.append('email', this.state.userEmail);
            formdata.append('mobile', this.state.userMobile);
        }
        formdata.append('password', this.state.userPassword);
        Axios.post(`${apiUrl}/admin/updatedetail`, formdata, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify('success', res.data.message)
                this.cancelform();
                this.setState({
                    users: res.data.data.users
                })

            } else {
                this.notify('error', res.data.message)

            }
        }

        )

    }
    givepermission = (e, name) => {

        Axios.post(`${apiUrl}/admin/givepermission`, { name: name, user_id: this.state.userId }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("pagelist response", res)
            if (res.data.success) {
                this.notify('success', res.data.message)
                this.setState({
                    pagelist: res.data.data.adminpermission,
                    permissions: res.data.data.adminpermission
                })
            } else {
                this.notify('error', res.data.message)
                this.setState({
                    pagelist: res.data.data.adminpermission,
                    permissions: res.data.data.adminpermission
                })
            }
        }, err => {
            console.log(err)
        })
    }
    viewdetails = (e, val) => {
        e.preventDefault();
        Axios.get(`${apiUrl}/admin/viewpermission/${val.id}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("pagelist response", res)
            if (res.data.success) {
                this.setState({
                    userName: val.name,
                    userEmail: val.email,
                    userId: val.id,
                    pagelist: res.data.data.adminpermission,
                    permissions: res.data.data.adminpermission
                })
            } else {
                this.setState({
                    pagelist: [],
                    permissions: []
                })
            }
        }, err => {
            console.log(err)
        })
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    addNewStaff = e => {
        e.preventDefault();
        this.setState({
            userId: "",
            show_form: true,
            userName: "",
            userEmail: "",
            userMobile: "",
            userPassword: "",
        });
    }
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.pagelist.length == 0 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="title" >Admin List
                                            <small className="float-right">
                                                <a href="" onClick={this.addNewStaff} className="btn btn-success">+ Add New</a>
                                            </small>
                                        </h3>

                                        <div className="card-body">
                                            {this.state.show_form && <Form onSubmit={this.updateAdmin}>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <label> UserName </label>
                                                            <input type="text" className="form-control" name="userName" value={this.state.userName} onChange={(e) => { this.inputChange(e) }} readOnly={this.state.userId == 1} />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <label> E-mail </label>
                                                            <input type="text" className="form-control" name="userEmail" value={this.state.userEmail} onChange={(e) => { this.inputChange(e) }} readOnly={this.state.userId == 1} />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <label> Mobile </label>
                                                            <input type="text" className="form-control" name="userMobile" value={this.state.userMobile} onChange={(e) => { this.inputChange(e) }} readOnly={this.state.userId == 1} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <label> Password </label>
                                                            <input type="password" className="form-control" name="userPassword" value={this.state.userPassword} onChange={(e) => { this.inputChange(e) }} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Button className="btn-fill" color="primary" type="button" onClick={this.cancelform}>
                                                    Cancel
                                                </Button>
                                                <Button className="btn-fill" color="primary" type="submit">
                                                    Save
                                                </Button>
                                            </Form>}
                                            <div className="table-responsive">
                                                <table className="tablesorter table">
                                                    <thead className="text-primary" >
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>E-mail</th>
                                                            <th>Mobile</th>
                                                            <th>User Role</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.users.map((val, key) => {

                                                            return <tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{val.name}</td>
                                                                <td>{val.email}</td>
                                                                <td>{val.mobile}</td>
                                                                <td>{val.role}</td>
                                                                <td>
                                                                    {val.id !== 1 &&
                                                                        <a href="" onClick={(e) => { this.viewdetails(e, val) }} ><i className="fa fa-eye" aria-hidden="true"></i></a>
                                                                    }
                                                                    &nbsp;
                                                                    <a href="" onClick={(e) => { this.vieweditform(e, val) }} >
                                                                        <i className="fas fa-edit"></i>
                                                                    </a></td>
                                                            </tr>
                                                        })}
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.pagelist.length > 0 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1 className="title" >Permission List
                                            <small className="float-right">
                                                <button type="button" onClick={this.showadmin}  >Back</button>
                                            </small>
                                        </h1>
                                        <div className="card-body">
                                            <p>User Name: {this.state.userName}</p>  <p>E-mail: {this.state.userEmail}</p>
                                            <div className="table-responsive">
                                                <table className="tablesorter table">
                                                    <thead className="text-primary" >
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Page Name</th>
                                                            <th>Permission</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.pagelist.map((val, key) => {
                                                            return <tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{val.name}</td>
                                                                <td><input type="checkbox" onChange={(e) => { this.givepermission(e, val.name) }} defaultChecked={val.permission == "yes"} /> </td>
                                                            </tr>
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }
}
export default Permission;

