import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import { Alert } from "reactstrap";
import logo from "assets/img/react-logo.png";
import "assets/css/black-dashboard-react.css";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
var ps;

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: "blue",
            sidebarOpened:
                document.documentElement.className.indexOf("nav-open") !== -1,
            maintanance_mode: sessionStorage.getItem('maintanance_mode')
        };
    }
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.documentElement.className += " perfect-scrollbar-off";
            document.documentElement.classList.remove("perfect-scrollbar-on");
        }
    }
    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            if (navigator.platform.indexOf("Win") > -1) {
                let tables = document.querySelectorAll(".table-responsive");
                for (let i = 0; i < tables.length; i++) {
                    ps = new PerfectScrollbar(tables[i]);
                }
            }
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
    }
    // this function opens and closes the sidebar on small devices
    toggleSidebar = (e, name) => {
        if (e) e.preventDefault();
        // document.documentElement.classList.toggle("nav-open");
        this.setState({ page_name: name });
        sessionStorage.setItem("page_name", name);
    };
    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                if (prop.dropdown.length === 0) {
                    return (
                        <Route
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                        />
                    );
                }
                else {
                    prop.dropdown.map((dro, ke) => {
                        return (<Route
                            path={dro.layout + dro.path}
                            component={dro.component}
                            key={"dr" + ke}
                        />);
                    })
                }
            } else {
                return null;
            }
        });
    };
    handleBgClick = color => {
        this.setState({ backgroundColor: color });
    };
    getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
            if (
                this.props.location.pathname.indexOf(
                    routes[i].layout + routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };
    render() {
        return (
            <>
                <div className="wrapper">
                <AdminNavbar
                            {...this.props}
                            brandText={this.getBrandText(this.props.location.pathname)}
                            toggleSidebar={this.toggleSidebar}
                            sidebarOpened={this.state.sidebarOpened}
                        />
                    <Sidebar
                        {...this.props}
                        routes={routes}
                        bgColor={this.state.backgroundColor}
                        logo={{
                            outterLink: "",
                            text: "Online Saathi",
                            imgSrc: logo
                        }}
                        toggleSidebar={this.toggleSidebar}
                    />
                    <div
                        className="main-panel"
                        ref="mainPanel"
                        data={this.state.backgroundColor}
                    >
                       

                        {routes.map((prop, key) => {
                            if (!prop.show) return null;
                            if (prop.dropdown.length === 0) {
                                return (
                                    <Route
                                        path={prop.layout + prop.path}
                                        component={prop.component}
                                        key={key}
                                    />
                                );
                            }
                            else {
                                return (prop.dropdown.map((sbprop, skey) => {
                                    // console.log("else ", sbprop.layout + sbprop.path)
                                    // console.log("if ", prop.layout + prop.path)
                                    return (
                                        <Route
                                            path={sbprop.layout + sbprop.path}
                                            component={sbprop.component}
                                            key={key + "-" + skey}
                                        />
                                    );
                                })
                                )
                            }
                        })}
                        {/* <Switch>
                            {this.getRoutes(routes)}
                            <Redirect from="*" to="/admin/dashboard" />
                        </Switch> */}
                        {this.state.maintanance_mode === "on" && <Alert color="danger" className="text-center">
                            Maintanace mode is on!
                        </Alert>}
                        {this.props.location.pathname.indexOf("maps") !== -1 ? null : (
                            <Footer fluid />
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Admin;
