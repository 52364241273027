import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  Alert,
  Button,
  InputGroup
} from "reactstrap";
import Axios from "axios";
import env from "variables/constants";
import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);
var apiUrl = process.env.REACT_APP_API_URL_LIVE;

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_super: false,
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      url: process.env.REACT_APP_API_URL,
      maintanance_mode: "off"
    };
  }
  componentDidMount() {
    this.cusersCheck();
    this.checklogin();
    setInterval(() => {
      this.cusersCheck();
    }, 15000);
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  cusersCheck = () => {
    // var x = sessionStorage.getItem('cuser') ? Cryp.decrypt(sessionStorage.getItem('cuser')) : null;
    // console.log(x)
    // if (x && x.id !== 1) {
    //   this.setState({
    //     is_super: false
    //   })
    //   if (window.location.href.includes("dashboard")) {
    //     this.props.history.push(`/admin/all-users`);
    //   }
    //   else if (window.location.href.includes("add-money-requests")) {
    //     this.props.history.push(`/admin/all-users`);
    //   }
    //   else if (window.location.href.includes("settings")) {
    //     this.props.history.push(`/admin/all-users`);
    //   }
    // } else {
    //   this.setState({
    //     is_super: true
    //   })
    // }
  }
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };
  checklogin = () => {
    Axios.get(`${apiUrl}/check/login`, {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
    }).then(res => {
      // console.log("check login response", res)
      this.setState({ maintanance_mode: res.data.maintanance_mode })
      sessionStorage.setItem("maintanance_mode", res.data.maintanance_mode);
      if (!res.data.success) {
        alert("Session expired. Pleaase login again.")
        sessionStorage.clear();
        this.props.history.push(`/`);
      }
    }, err => {
      window.alert("Session expired. Pleaase login again.")
      sessionStorage.clear();
      this.props.history.push(`/`);
    })
  }
  logout = e => {
    // console.log("ok")
    Axios.get(`${apiUrl}/logout`, {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
    }).then(res => {
      // console.log(res)
      sessionStorage.clear();
      this.props.history.push(`/`);
    }, err => {
      sessionStorage.clear();
      this.props.history.push(`/`);
    })
  }
  render() {
    return (
      <>
        <Navbar
          id="admin-navbar-absolute"
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
         >
          <Container fluid>

            <div className="navbar-wrapper">
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              {/*} <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                {this.props.brandText}
              </NavbarBrand>*/}
              <div className="logo-dashboard">
                <img alt="..." src={require("assets/images/new/online-sath-logo-new.png")} />
              </div>
            </div>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navigation"
              data-toggle="collapse"
              id="navigation"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>

              <div style={{width: "100%",display: "flex", justifyContent: "space-around", alignItems: "center"}}>

                <div>
                  <div className="navbar-admin-dashboard-div">
                    <b className="navbar-admin-dashboard">Admin Dashboard</b>
                  </div>
                </div>

                <div className="home-nav-container">
                    <form className="home-nav-content-form">
                    <input type="search" placeholder="Search" className="navbar-container-input-search" />
                    <button type="submit">Search</button>
                    </form>
                </div>
                 
                <div className="nav-notification-content" style={{display:"flex"}}>   
                  <div>
                    <img alt="..." src={require("../../assets/images/notifiactionIcon.svg")} className="notifiactionIMG"/>
                  </div>

                  <div>
                    <div className="adminContent">
                      {/* <p><b>Hii Admin</b></p> */}
                      <p style={{color: "#B9B9C3"}}>Admin</p>
                    </div>
                  </div>
                </div>  

              </div>

            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>

                {/* <InputGroup className="search-bar" placeholder="type">
                  <Button
                    color="link"
                    data-target="#searchModal"
                    data-toggle="modal"
                    id="search-button"
                    onClick={this.toggleModalSearch}
                  >
                    <i className="tim-icons icon-zoom-split" />
                    <span className="d-lg-none d-md-block">Search</span>
                  </Button>
                </InputGroup> */}
                {/* <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <div className="notification d-none d-lg-block d-xl-block" />
                    <i className="tim-icons icon-sound-wave" />
                    <p className="d-lg-none">Notifications</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Mike John responded to your email
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        You have 5 more tasks
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Your friend Michael is in town
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Another notification
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Another one
                      </DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <div className="photo">
                      <img alt="..." src={require("assets/img/anime3.png")} />
                    </div>
                    <b className="caret d-none d-lg-block d-xl-block" />
                    <p className="d-lg-none">Log out</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    {/* <NavLink tag="li">
                      <DropdownItem className="nav-item">Profile</DropdownItem>
                    </NavLink> */}
                    <NavLink tag="li">
                      {this.state.is_super && <DropdownItem className="nav-item">
                        <Link className="nav-item" to="/admin/settings">Settings</Link>
                      </DropdownItem>}
                    </NavLink>
                    <DropdownItem divider tag="li" />
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={(e) => { this.logout(e) }}>Log out</DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
            {this.state.maintanance_mode === "on" && <Alert color="danger" className="text-center">
              Maintanace mode is on!
          </Alert>}
          </Container>


                            <div>
                              <div className="dashboard-category-parent-container">
                                <div className="dashboard-main-category-container">
                                  <div className="dashboard-category-container">
                                    <ul className="dashboard-category-ul-container">

                                       <li className="dashboard-category-li-container">
                                         <img alt="..." src={require("../../assets/images/dashboardServicesIcon.svg")} className="dashboard-catogery-ContainerImg"/> 
                                         <a href="/"> Services </a>
                                        </li>
                                        <li className="dashboard-category-li-container">
                                         <img alt="..." src={require("../../assets/images/dashoardJobsIcon.svg")} className="dashboard-catogery-ContainerImg"/> 
                                         <a href="/"> Jobs </a>
                                        </li>
                                        <li className="dashboard-category-li-container">
                                         <img alt="..." src={require("../../assets/images/dashboardSchemeIcon.svg")} className="dashboard-catogery-ContainerImg"/> 
                                         <a href="/"> Scheme </a>
                                        </li>
                                        <li className="dashboard-category-li-container">
                                         <img alt="..." src={require("../../assets/images/dashboardBankIcon.svg")} className="dashboard-catogery-ContainerImg"/> 
                                         <a href="/"> Banking </a>
                                        </li>
                                        <li className="dashboard-category-li-container">
                                         <img alt="..." src={require("../../assets/images/dashboardRechargeIcon.svg")} className="dashboard-catogery-ContainerImg"/> 
                                         <a href="/"> Recharge </a>
                                        </li>
                                        <li className="dashboard-category-li-container">
                                         <img alt="..." src={require("../../assets/images/dashboardInsuranceIcon.svg")} className="dashboard-catogery-ContainerImg"/> 
                                         <a href="/"> Insurance </a>
                                        </li>
                                        <li className="dashboard-category-li-container">
                                         <img alt="..." src={require("../../assets/images/dashboardGovernmentIcon.svg")} className="dashboard-catogery-ContainerImg"/> 
                                         <a href="/"> Government </a>
                                        </li>
                                        <li className="dashboard-category-li-container">
                                         <img alt="..." src={require("../../assets/images/dashboardFastTagIcon.svg")} className="dashboard-catogery-ContainerImg"/> 
                                         <a href="/"> FASTag </a>
                                        </li> 
                                        <li className="dashboard-category-li-container">
                                         <img alt="..." src={require("../../assets/images/dashboardTravelIcon.svg")} className="dashboard-catogery-ContainerImg"/> 
                                         <a href="/"> Travel </a>
                                        </li>   
                                        <li className="dashboard-category-li-container">
                                         <img alt="..." src={require("../../assets/images/dashboardBillPayment.svg")} className="dashboard-catogery-ContainerImg"/> 
                                         <a href="/"> Bill Payment </a>
                                        </li>

                                    </ul> 
                                  </div>
                                </div>
                              </div>
                            </div>

        </Navbar>


        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
         >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>

      </>
    );
  }
}
export default AdminNavbar;
