import React from "react";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import swal from 'sweetalert';
import ReceiptModel from "./ReceiptModel";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Userregister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:"",
            name:"",
            email:"",
            mobile:"",
            pincode:"",
            country:"",
            state:"",
            city:"",
            district:"",
            address:"",
            password:"",
            id_type:"",
            otp:"",
            verifyOtp:"",
            id_back:null,
            id_front:null,
            profile_pic:null,
            id_backs:null,
            id_fronts:null,
            profile_pics:null,
            check_user:true,
            register:false,
            
        }
    }

    componentDidMount = () => {
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })  
    }

    getData = (page = 1) => {

    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleInputMobile = e => {
        if (e.target.value.length <= 10) {
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            return false;
        }
    }
    handleInputCheckbox = e => {
        this.setState({
            save_beneficiary: e.target.checked ? "yes" : "no"
        })
    }
    handleInputOTP = e => {
        if (e.target.value.length <= 4) {
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            console.log(this.state.otp)
            return false;
        }
    }

    
  

    checkMobile = e => {
        e.preventDefault();
       
        var lengthcheck = this.state.mobile.length;
         //  console.log("lenght",lengthcheck);
        if (lengthcheck !== 10) {
            swal("", "Please enter correct mobile number.", "error");
            return false;
        }

        this.setState({ loading: true })
        Axios.post(`${apiUrl}/checkmobile`, { mobile: this.state.mobile }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res);
            this.setState({ loading: false })
            if (res.data.success) {
      
                this.setState({
                    check_user: false,
                    register:true
                });
            } else {
               
                    swal({ text: res.data.message, icon: "error" });
                
            }
        })
    }
  

 
    

    submit_register = e => {
        e.preventDefault();
       
        if (this.state.name === "") {
            swal("", "Please enter name.", "error");
            return false;
        }
        if (this.state.email === "") {
            swal("", "Please enter email.", "error");
            return false;
        }
        if (this.state.password === "") {
            swal("", "Please enter password.", "error");
            return false;
        }
        if (this.state.mobile === "") {
            swal("", "Please  mobile number.", "error");
            return false;
        }
        var lengthcheck = this.state.mobile.length;
        //  console.log("lenght",lengthcheck);
       if (lengthcheck !== 10) {
           swal("", "Please enter correct mobile number.", "error");
           return false;
       }
        if (this.state.pincode === "") {
            swal("", "Please enter pincode.", "error");
            return false;
        }
        if (this.state.country === "") {
            swal("", "Please enter country.", "error");
            return false;
        }

        if (this.state.state === "") {
            swal("", "Please enter state.", "error");
            return false;
        }
        if (this.state.city === "") {
            swal("", "Please enter city.", "error");
            return false;
        }
        if (this.state.district === "") {
            swal("", "Please enter district.", "error");
            return false;
        } 
        if (this.state.address === "") {
            swal("", "Please enter address.", "error");
            return false;
        } 
        
        if (this.state.id_type === "") {
            swal("", "Please select  Proof Type.", "error");
            return false;
        } 
        if (this.state.profile_pic === "") {
            swal("", "Please enter profile pic.", "error");
            return false;
        } 
        if (this.state.id_front === "") {
            swal("", "Please enter Id Front.", "error");
            return false;
        } 
        if (this.state.id_back === "") {
            swal("", "Please enter Id Back.", "error");
            return false;
        } 
     
        this.setState({ loading: true })

        var formdata = new FormData();
        formdata.append("name", this.state.name);
        formdata.append("email", this.state.email);
        formdata.append("mobile", this.state.mobile);
        formdata.append("password", this.state.password);
        formdata.append("pincode", this.state.pincode);
        formdata.append("city", this.state.city);
        formdata.append("state", this.state.state);
        formdata.append("country", this.state.country);
        formdata.append("district", this.state.district);
        formdata.append("address", this.state.address);
        formdata.append("agent_request", 'no');
        
        formdata.append("id_type", this.state.id_type);
        formdata.append("id_back", this.state.id_back);
        formdata.append("id_front", this.state.id_front);
        formdata.append("profile_pic", this.state.profile_pic);
      
        Axios.post(`${apiUrl}/distribuor/register-agent`, formdata, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log("send transaction response", res)
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.setState({
                    otp: res.data.otp,
                    id: res.data.id,
                    register:false,
                    show_otp: true,
                    name: "",
                    email: "",
                    mobile: "",
                    password:"",
                    pincode: "",
                    city: "",
                    state: "",
                    country: "",
                    district: "",
                    address:"",
                    id_type: "",
                    id_back: "",
                    id_front: "",
                    profile_pic: "",
                })
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            this.setState({ loading: false })
            swal("", err.message, "error");
            // swal("", "Session expired. Please login again.").then(o => {
            //  //   sessionStorage.clear();
            // //    this.props.history.push("/");
            // })
        })
    }
    toggleModal = e => {
        this.setState({
            modalState: !this.state.modalState
        })
    }
    verifyOtp = e => {
        e.preventDefault();
        var data = {
            id: this.state.id,
            verifyOtp: this.state.verifyOtp,
           
        }
        if (this.state.verifyOtp === "" || this.state.verifyOtp.length < 4) {
            swal("", "Please enter valid OTP.", "error");
            return false;
        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/distribuor/verifyagentOTP`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
           
            if (res.data.success) {
           
                  
                    this.setState({
                        verifyOtp: "",
                        id:"",
                        show_otp:false,
                        check_user:true,

                    })
                
                swal("", res.data.message, "success");
                this.clearForms();
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            swal("", err.message, "error");
        })
    }
   
    clearForms = () => {
        this.setState({
         
            register:false,
            check_user: true,
            name: "",
            email: "",
            mobile: "",
            password:"",
            pincode: "",
            city: "",
            state: "",
            country: "",
            district: "",
            address:"",
            id_type: "",
            id_back: null,
            id_front: null,
            profile_pic: null,
        })
    }
    handleImage = (e, name) => {
        this.setState({
            [name]: e.target.files[0]
        })
    }
   
    getRelationship = (id) => {
        var x = id;
        this.state.relationship.forEach((rs) => {
            if (rs.option_id === id) {
                x = rs.value;
            }
        });
        return x;
    }
    selectSender = (e, bene) => {
        e.preventDefault();
        this.setState({
            receiver_name: bene.ReceiverName,
            receiver_address: bene.ReceiverAddress,
            receiver_gender: bene.ReceiverGender,
            receiver_mobile_number: bene.ReceiverMobileNo,
            receiver_city: bene.ReceiverCity,
            relationshipw: bene.Relationship,
            source_fund: bene.SourceOfFund,
            remittance_reason: bene.PurposeOfRemittance,
            payment_type: bene.PaymentType,
            bank_id: bene.BankId ? bene.BankId : "",
            bank_branch_id: bene.BankBranchId ? bene.BankBranchId : "",
            bank_account_number: bene.BankAccountNumber ? bene.BankAccountNumber : "",
            show_form: "remittance",
            bene_selected: true
        })
    }
    newTransaction = e => {
        e.preventDefault();
        this.setState({ show_form: "remittance" })
    }
    render() {
        return <>
            <Userheader user={this.state.user} />
          

            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="form-sec-dash">

                                {this.state.check_user &&  <> 
                                <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p> Check Mobile Number</p>
                                       
                                    </h3>
                                </div>

                              <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label >Mobile</label>
                                            <input type="number" className="form-control" name="mobile" placeholder="Enter Mobile Number" onChange={e => { this.handleInputMobile(e) }} value={this.state.mobile} />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="send-bt-dash">
                                            <a href="" onClick={e => { this.checkMobile(e) }}>Check</a>
                                        </div>
                                    </div>
                                </div>

                                </>}

                                {this.state.show_otp &&  <> 
                                <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p>Otp verification</p>
                                       
                                    </h3>
                                </div>

                                  <div className="row">
                                     <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label >Otp</label>
                                            <input type="number" className="form-control" name="verifyOtp" placeholder="Enter Otp" onChange={e => { this.handleInputMobile(e) }} value={this.state.verifyOtp} />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="send-bt-dash">
                                            <a href="" onClick={e => { this.verifyOtp(e) }}>Verify</a>
                                        </div>
                                    </div>
                                </div>

                                </>}

                          {this.state.register &&  <> 
                             <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p> Register User </p>
                                       
                                    </h3>
                                </div>
                              <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            

                                            <label >Name</label>
                                            <input type="text" className="form-control" placeholder="Enter  Name" onChange={e => { this.handleInput(e) }} name="name" value={this.state.name}  />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label > Mobile No</label>
                                            <input type="text" placeholder="Enter Mobile No" onChange={e => { this.handleInput(e) }} name="mobile" value={this.state.mobile}  />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label > E-mail</label>
                                            <input type="text" placeholder="Enter E-mail" onChange={e => { this.handleInput(e) }} name="email" value={this.state.email}  />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label >Password</label>
                                            <input type="text" placeholder="Enter password" onChange={e => { this.handleInput(e) }} name="password" value={this.state.password}  />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label > Pincode</label>
                                            <input type="text" placeholder="Enter pincode" onChange={e => { this.handleInput(e) }} name="pincode" value={this.state.pincode}  />
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label > Country</label>
                                            <input type="text" placeholder="Enter Country" onChange={e => { this.handleInput(e) }} name="country" value={this.state.country}  />
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="form-group">                         
                                            <label > State</label>
                                            <input type="text" placeholder="Enter State" onChange={e => { this.handleInput(e) }} name="state" value={this.state.state}  />
                                        </div>
                                    </div>
                                     <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label > City</label>
                                            <input type="text" placeholder="Enter City" onChange={e => { this.handleInput(e) }} name="city" value={this.state.city}  />
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label > District</label>
                                            <input type="text" placeholder="Enter District" onChange={e => { this.handleInput(e) }} name="district" value={this.state.district}  />
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label >Address</label>
                                            <input type="text" placeholder="Enter address" onChange={e => { this.handleInput(e) }} name="address" value={this.state.address}  />
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label > Id Proof Type</label>
                                            <select type="text"  onChange={e => { this.handleInput(e) }} name="id_type" value={this.state.id_type}  >
                                            <option value="">Select Proof</option>
                                            <option value="Driving Licence">Driving Licence</option>
                                            <option value="Aadhar Card">Aadhar Card</option>
                                            <option value="Pan Card">Pan Card</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="">
                                            
                                            <label >Front</label>
                                            <input type="file" className="form-control" onChange={e => { this.handleImage(e ,'id_front') }} name="id_fronts"   />
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="">
                                            
                                            <label >Back</label>
                                            <input type="file"  className="form-control" onChange={e => { this.handleImage(e,'id_back') }} name="id_backs"   />
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="">
                                            
                                            <label >Profile Image</label>
                                            <input type="file"  className="form-control" onChange={e => { this.handleImage(e,'profile_pic') }} name="profile_pics"   />
                                        </div>
                                    </div> 
                                   
                                    <div className="col-md-12">
                                       
                                        <div className="send-bt-dash">

                                            <a href="" onClick={e => { this.clearForms(e) }}>Cancel</a>&nbsp;&nbsp;
                                            <a href="" onClick={e => { this.submit_register(e) }}>Send</a>
                                        </div>
                                    </div>
                                </div>
                                </>}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <ReceiptModel modalState={this.state.modalState} toggleModal={this.toggleModal} />
            <Userfooter />
        </>;
    }
}
export default Userregister;
function Tabledata(props) {
    return <><tr className="spacer" >
        <td colSpan="100%"></td>
    </tr>
        <tr>
            <td>{props.bene.ReceiverName}</td>
            <td>{props.bene.ReceiverMobileNo}</td>
            <td>{props.bene.ReceiverAddress}</td>
            <td>{props.bene.ReceiverCity}</td>
            <td>{props.getRelationship(props.bene.Relationship)}</td>
            <td>{props.bene.PaymentType === "C" ? "Cash Payment" : "Bank Deposit"}</td>
            <td><a href="" onClick={e => { props.selectSender(e, props.bene) }} title="select beneficiary"><i className="fa fa-send"></i></a></td>
        </tr></>
}
