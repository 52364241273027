import Dashboard from "views/custom/Dashboard.js";
import Addmoney from "views/custom/Addmoney.js";
import Categories from "views/custom/Categories.js";
import Topcategories from "views/custom/Topcategories";
import Serviceproviders from "views/custom/Serviceproviders.js";
import Banners from "views/custom/Banners.js";
import Wallethistory from "views/custom/Wallethistory.js";
import Addmoneyhistory from "views/custom/Addmoneyhistory.js";
import Remittancewallethistory from "views/custom/Remittancewallethistory.js";
import Adminhistroy from "views/custom/Adminhistroy.js";
import Remittancehistory from "views/custom/Remittancehistory.js";
import Users from "views/custom/Users.js";
import AgentRequest from "views/custom/AgentRequest.js";

import Dealers from "views/custom/Dealers.js";
import Distributors from "views/custom/Distributors.js";

import Settings from "views/custom/Settings.js";

import Userdashboard from "./layouts/Users/Dashboard.js";
import Userwallethistory from "./layouts/Users/Wallethistory";
import Userremittancehistory from "./layouts/Users/Remittancehistory";
import Bankdetails from "./layouts/Users/Bankdetails";
import Userremittance from "./layouts/Users/Remittance";
import Agentregister from "./layouts/Users/Agentregister";
import Reports from "./layouts/Users/Reports";
import Userregister from "./layouts/Users/Userregister";
import Userlist from "./layouts/Users/Userlist";
import Agentlist from "./layouts/Users/Agentlist";
import Editprofile from "./layouts/Users/Editprofile";
import Changepassword from "./layouts/Users/Changepassword";
import Nepalrechargehistory from "./layouts/Users/Nepalrechargehistory";
import Indiarechargehistory from "./layouts/Users/Indiarechargehistory";
import Addmoneyuser from "./layouts/Users/Addmoney";
import Commissionreports from "./layouts/Users/Commissionreports";
import PrabhuRemittance from "./layouts/Users/PrabhuRemittance";
import Prabhuhistory from "./layouts/Users/Prabhuhistory";


import Permission from "./views/custom/Permission";
import Notice from "./views/custom/Task";
import Transactionreports from "./views/custom/Transactionreports";
import Agentcommissionreports from "./views/custom/Agentcommissionreports";
import Agentgstreports from "./views/custom/Agentgstreports";
import Customerchats from "./views/custom/Customerchats";
import Predefinetemlate from "./views/custom/Predefinetemlate";
import Rechargehistory from "./views/custom/Rechargehistory";
import Notification from "./views/custom/Notification";
import Admincspcodes from "./views/custom/AdminCspCodes";
import Ntcpack from "./views/custom/Ntcpack";
import Ncellpack from "./views/custom/Ncellpack";
import Becomesaathilist from "./views/custom/Becomesaathilist";

import SimpleCrypto from "simple-crypto-js";
import UserAccess from "views/custom/Rolemanagement/UserAccess.js";
import Dmt from "layouts/Users/DMT/Dmt.js";

import ReportIssue from "./views/custom/Reportissue";
import BecomeSaathi from "layouts/Users/BecomeSaathi/Becomandagent.js";
import DmtHistory from "views/custom/DmtHistory.js";
import PaidServicesRequests from "views/custom/PaidServicesRequests.js";
import KycList from "views/custom/KycList.js";
import MemberList from "views/custom/MemberList.js";
import SathiList from "views/custom/SathiList.js";
import IndianRechargeHistory from "views/custom/IndiaRechargehistory.js";
import PrabhuRemittanceHistory from "views/custom/PrabhuRemittanceHistory.js";
import Bank from "views/custom/Bank.js";
import Support from "views/custom/Support.js";
import Inquery from "views/custom/Inquery.js";
import Faq from "views/custom/Faq.js";
import Memberpayment from "views/custom/Memberpayment.js";
import Rejectedmember from "views/custom/Rejectedmember.js";
import Memberrequest from "views/custom/Memberrequest.js";
import Sathirequest from "views/custom/Sathirequest.js";
import Rejectedsathi from "views/custom/Rejectedsathi.js";
import Externallink from "views/custom/Externallink";
import Thirdparty from "views/custom/Thirdparty.js";
import GovernanceCategory from "views/custom/Good-governance/Category.js";
import GovernanceDocument from "views/custom/Good-governance/Document.js";
import Detail from "views/custom/Detail.js";
import Commission from "views/custom/Commission.js";
import GovernanceScheme from "views/custom/Good-governance/Scheme.js";
import Commonquestion from "views/custom/Good-governance/Commonquestion.js";
import BecomeMember from "../src/layouts/Users/BcomeMember/BecomeMember";
import BecomeSathi from "layouts/Users/Becomesathi/BecomeSathi.js";
import AllSchemes from "layouts/Users/Becomesathi/allSchemes.js";
import NoticeApp from "views/custom/Notice.js";
import MemberBenefit from "views/custom/MemberBenefit.js";
import SathiBenefit from "views/custom/SathiBenefit.js";
import Reward from "views/custom/Reward.js";
import SaathiDashboard from "../src/layouts/Dashboard/layout/index";
import Homedashboard from "layouts/Dashboard/pages/homeDashboard/Homedashboard.js";

import allMember from "../src/assets/images/banner-image.png";
import Scheme from "views/custom/Good-governance/Scheme.js";


const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);


var cu = sessionStorage.getItem('cuser') ? Cryp.decrypt(sessionStorage.getItem('cuser')) : null;
const allpermission = sessionStorage.getItem('adminpermission') ? JSON.parse(sessionStorage.getItem("adminpermission")) : null;


function checkAccess(page) {
  var ok = false;
  if (allpermission) {
    allpermission.map((val) => {
      if (val.page_name === page) {
        ok = true;
      }
    });
  }
  return ok;
}
var is_super = cu && cu.id === 1;

var routes = [
  {
    path: "/saathidashboard",
    name: "Dashboard",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component:Homedashboard,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Userdashboard,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/wallet-history",
    name: "Dashboard",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Userwallethistory,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/become-saathi",
    name: "Become Saathi",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: BecomeSaathi,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/become-member",
    name: "Become Member",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: BecomeMember,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/become-saathi2",
    name: "Become Saathi",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: BecomeSathi,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/All-Schemes",
    name: "Become scheme",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: AllSchemes,
    layout: "/user",
    show: false,
    dropdown: []
  },
  // {
  //   path: "/schemes",
  //   name: "Schemes",
  //   rtlName: "",
  //   icon: "tim-icons icon-chart-pie-36",
  //   image: "uploads/sidebar-icons/dashboard.png",
  //   component: Schemes,
  //   layout: "/user",
  //   show: false,
  //   dropdown: []
  // },
  {
    path: "/remittance-history",
    name: "Dashboard",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Userremittancehistory,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/bank-details",
    name: "Dashboard",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Bankdetails,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/remittance",
    name: "Dashboard",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Userremittance,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/money-transfer",
    name: "Money Transfer",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Dmt,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/agent-register",
    name: "agent register",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Agentregister,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/user-register",
    name: "user register",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Userregister,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/list",
    name: "user list",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Userlist,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/agent-list",
    name: "agent list",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Agentlist,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Editprofile,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/change-password",
    name: "Change password",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Changepassword,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/add-money",
    name: "Add money requests",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Addmoneyuser,
    layout: "/user",
    show: false,
    dropdown: []
  },

  {
    path: "/report",
    name: "Reports",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Reports,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/nepal-recharge-history",
    name: "Nepal Recharge History",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Nepalrechargehistory,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/india-recharge-history",
    name: "India Recharge History",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Indiarechargehistory,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/commission-reports",
    name: "Commission Reports",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: Commissionreports,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/prabhu-remittance",
    name: "Prabhu Remittance",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "uploads/sidebar-icons/dashboard.png",
    component: PrabhuRemittance,
    layout: "/user",
    show: false,
    dropdown: []
  },
  {
    path: "/prabhu-remittance-history",
    name: "Prabhu Remittance History",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "../src/assets/images/sidebar-icons/category.png",
    component: Prabhuhistory,
    layout: "/user",
    show: false,
    dropdown: []
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "../src/assets/images/sidebar-icons/category.png",
    component: Dashboard,
    layout: "/admin",
    show: true,
    dropdown: []
  },
  {
    path: null,
    name: "All Members",
    rtlName: "",
    // icon: "tim-icons icon-atom",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: Users,
    layout: "/admin",
    show: checkAccess("/all-users"),
    dropdown: [
      {
        path: "/user/detail",
        name: "",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Detail,
        layout: "/admin",
        show: false,
        dropdown: []
      },
      {
        path: "/all-dealers",
        name: "State Partner",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Dealers,
        layout: "/admin",
        show: checkAccess("/all-dealers"),
        dropdown: []
      },
      {
        path: "/all-distributors",
        name: "Partner",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Distributors,
        layout: "/admin",
        show: checkAccess("/all-dealers"),
        dropdown: []
      },
      {
        path: "/all-users",
        name: "Registration",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Users,
        layout: "/admin",
        show: checkAccess("/all-users"),
        dropdown: []
      },
      {
        path: "/member-list",
        name: "Membership",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: MemberList,
        layout: "/admin",
        show: true,
        show: checkAccess("/membership"),
        dropdown: []
      },
      {
        path: "/sathi-list",
        name: "Saathi",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: SathiList,
        layout: "/admin",
        show: true,
        show: checkAccess("/saathi"),
        dropdown: []
      },
      {
        path: "/third-party",
        name: "Third Party",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Thirdparty,
        layout: "/admin",
        show: true,
        show: checkAccess("/third-party"),
        dropdown: []
      },

    ]
  },

  {
    path: null,
    name: "Members",
    rtlName: "",
    icon: "tim-icons icon-atom",
    image: "https://scontent.xx.fbcdn.net/v/t1.15752-9/403629103_235865496000867_6074565160981011199_n.png?stp=cp0_dst-png&_nc_cat=108&ccb=1-7&_nc_sid=510075&_nc_aid=0&_nc_ohc=FNACkOm_eqUAX8vs635&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_AdSyYL-ldeYVbVBo2CVBr_H8PklpFZGKIOwPvlOwc5IyHA&oe=657FA9A7",
    component: null,
    layout: "/admin",
    show: checkAccess("/agent-request"),
    dropdown: [
      {
        path: "/rejected-member-list",
        name: "Rejected Members",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Rejectedmember,
        layout: "/admin",
        show: true,
        show: checkAccess("/rejected-member-list"),
        dropdown: []
      },
      {
        path: "/new-member-list",
        name: "New Request",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Memberrequest,
        layout: "/admin",
        show: true,
        show: checkAccess("/new-member-list"),
        dropdown: []
      },
    ]
  },

  {
    path: null,
    name: "Good Governance",
    rtlName: "",
    icon: "tim-icons icon-atom",
    image: "https://scontent.xx.fbcdn.net/v/t1.15752-9/403629103_235865496000867_6074565160981011199_n.png?stp=cp0_dst-png&_nc_cat=108&ccb=1-7&_nc_sid=510075&_nc_aid=0&_nc_ohc=FNACkOm_eqUAX8vs635&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_AdSyYL-ldeYVbVBo2CVBr_H8PklpFZGKIOwPvlOwc5IyHA&oe=657FA9A7",
    component: null,
    layout: "/admin",
    show: checkAccess("/agent-request"),
    dropdown: [
      {
        path: "/good-governance/category",
        name: "Category",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: GovernanceCategory,
        layout: "/admin",
        show: true,
        show: checkAccess("/good-governance/category"),
        dropdown: []
      },
      {
        path: "/good-governance/document",
        name: "Document",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: GovernanceDocument,
        layout: "/admin",
        show: true,
        show: checkAccess("/good-governance/document"),
        dropdown: []
      },
      {
        path: "/good-governance/scheme",
        name: "Scheme",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: GovernanceScheme,
        layout: "/admin",
        show: true,
        show: checkAccess("/good-governance/scheme"),
        dropdown: []
      },
      {
        path: "/good-governance/common-question",
        name: "Common Question",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Commonquestion,
        layout: "/admin",
        show: true,
        dropdown: []
      },
    ]
  },

  {
    path: null,
    name: "Saathi",
    rtlName: "",
    icon: "tim-icons icon-atom",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: null,
    layout: "/admin",
    show: checkAccess("/agent-request"),
    dropdown: [
      {
        path: "/rejected-sathi-list",
        name: "Rejected Saathi",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Rejectedsathi,
        layout: "/admin",
        show: true,
        show: checkAccess("/rejected-sathi-list"),
        dropdown: []
      },
      {
        path: "/new-sathi-list",
        name: "New Request",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Sathirequest,
        layout: "/admin",
        show: true,
        show: checkAccess("/new-sathi-list"),
        dropdown: []
      },
    ]
  },

  {
    path: "/agent-request",
    name: "Agent Request",
    rtlName: "",
    icon: "tim-icons icon-atom",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: AgentRequest,
    layout: "/admin",
    show: checkAccess("/agent-request"),
    dropdown: []
  },
  {
    path: "/become-saathi-list",
    name: "Saathi requests",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: Becomesaathilist,
    layout: "/admin",
    show: checkAccess("/become-saathi-list"),
    dropdown: []
  },
  {
    path: "/kyc-list",
    name: "KYC List",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: KycList,
    layout: "/admin",
    show: checkAccess("/kyc-list"),
    dropdown: []
  },

  {
    path: "/add-money-requests",
    name: "Add Money Requests",
    rtlName: "",
    icon: "tim-icons icon-atom",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: Addmoney,
    layout: "/admin",
    show: checkAccess("/add-money-requests"),
    dropdown: []
  },

  {
    path: "/report-issue",
    name: "Complaints",
    rtlName: "",
    icon: "tim-icons icon-atom",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: ReportIssue,
    layout: "/admin",
    show: checkAccess("/report-issue"),
    dropdown: []
  },
  {
    path: "/paid-service-requests",
    name: "Paid Services",
    rtlName: "",
    icon: "tim-icons icon-atom",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: PaidServicesRequests,
    layout: "/admin",
    show: false,
    show: checkAccess("/paid-service-requests"),
    dropdown: []
  },
  {
    path: null,
    name: "Services",
    rtlName: "",
    icon: "tim-icons icon-atom",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: Categories,
    layout: "/admin",
    show: checkAccess("/categories"),
    dropdown: [
      {
        path: "/categories",
        name: "Categories",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Topcategories,
        layout: "/admin",
        show: checkAccess("/categories"),
        dropdown: []
      },
      {
        path: "/services",
        name: "Services",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Categories,
        layout: "/admin",
        show: true,
        show: checkAccess("/services"),
        dropdown: []
      },
      {
        path: "/service-providers",
        name: "Service Providers",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Serviceproviders,
        layout: "/admin",
        show: checkAccess("/service-providers"),
        dropdown: []
      },]
  },
  {
    path: null,
    name: "Reports",
    rtlName: "",
    icon: "tim-icons icon-atom",
    image: "https://scontent.xx.fbcdn.net/v/t1.15752-9/387454726_1070099134410764_4412179722579595349_n.png?stp=cp0_dst-png&_nc_cat=100&ccb=1-7&_nc_sid=510075&_nc_ohc=yniV7RK3rMUAX9iX-jk&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_AdS-w5U7gNgff7IwJJJr-QSTn2J8q6Ny2qp_KUPXgCSVtQ&oe=657FC921",
    component: Remittancehistory,
    layout: null,
    show: checkAccess("/remittance-history"),
    dropdown: [
      {
        path: "/remittance-history",
        name: "Remittance History",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Remittancehistory,
        layout: "/admin",
        show: checkAccess("/remittance-history"),
        dropdown: []
      },
      {
        path: "/prabhu-remittance-history",
        name: "Prabhu Remittance History",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: PrabhuRemittanceHistory,
        layout: "/admin",
        show: checkAccess("/prabhu-remittance-history"),
        dropdown: []
      },
      {
        path: "/dmt-history",
        name: "DMT History",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: DmtHistory,
        layout: "/admin",
        show: true,
        show: checkAccess("/dmt-history"),
        dropdown: []
      },
      {
        path: "/wallet-history",
        name: "Wallet History",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Remittancewallethistory,
        layout: "/admin",
        show: checkAccess("/remittance-wallet-history"),
        dropdown: []
      },

      // {
      //   path: "/wallet-history",
      //   name: "Wallet History",
      //   rtlName: "",
      //   icon: "tim-icons icon-atom",
      //   image: "uploads/sidebar-icons/wallet-history.png",
      //   component: Wallethistory,
      //   layout: "/admin",
      //   show: checkAccess("/wallet-history"),
      //   dropdown: []
      // },
       {
        path: "/admin-history",
        name: "Admin History",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Adminhistroy,
        layout: "/admin",
        show: true,
        show: checkAccess("/admin-history"),
        dropdown: []
      },
      {
        path: "/add-money-history",
        name: "Add Money History",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Addmoneyhistory,
        layout: "/admin",
        show: checkAccess("/add-money-history"),
        dropdown: []
      },
      {
        path: "/transactionreports",
        name: "Transaction reports",
        rtlName: "الرموز",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Transactionreports,
        layout: "/admin",
        show: checkAccess("/transactionreports"),
        dropdown: []
      },
      {
        path: "/agentcommissionreports",
        name: "Agent commission reports",
        rtlName: "الرموز",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Agentcommissionreports,
        layout: "/admin",
        show: checkAccess("/agentcommissionreports"),
        dropdown: []
      },
      {
        path: "/agentgstreports",
        name: "Agent gst reports",
        rtlName: "الرموز",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Agentgstreports,
        layout: "/admin",
        show: is_super,
        show: checkAccess("/agentgstreports"),
        dropdown: []
      },
      {
        path: "/rechargehistory",
        name: "Recharge History",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Rechargehistory,
        layout: "/admin",
        show: checkAccess("/rechargehistory"),
        dropdown: []
      },
      {
        path: "/indian-recharge-history",
        name: "Indian Recharge History",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: IndianRechargeHistory,
        layout: "/admin",
        show: checkAccess("/rechargehistory"),
        dropdown: []
      },

    ]
  },
  {
    path: null,
    name: "Settings",
    rtlName: "الرموز",
    icon: "tim-icons icon-atom",
    image: "https://scontent.xx.fbcdn.net/v/t1.15752-9/387336511_1089024505695025_8820984714354100863_n.png?stp=cp0_dst-png&_nc_cat=102&ccb=1-7&_nc_sid=510075&_nc_ohc=ilNLGQMecIQAX9nlVS8&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_AdQdQgFR7sDRc0eLk-dBPrgZkzkaUZj1acmFmfcNhmwG2A&oe=657FCF24",
    component: Settings,
    layout: "/admin",
    show: checkAccess("/settings"),
    dropdown: [
      {
        path: "/member/benefit",
        name: "Member Benefit",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: MemberBenefit,
        layout: "/admin",
        show: checkAccess("/member/benefit"),
        dropdown: []
      },
      {
        path: "/sathi/benefit",
        name: "Saathi Benefit",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: SathiBenefit,
        layout: "/admin",
        show: checkAccess("/sathi/benefit"),
        dropdown: []
      },
      {
        path: "/commission",
        name: "Commission",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Commission,
        layout: "/admin",
        show: checkAccess("/commission"),
        dropdown: []
      },,
      {
        path: "/member-payment",
        name: "Member Payment",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Memberpayment,
        layout: "/admin",
        show: checkAccess("/member-payment"),
        dropdown: []
      },
      {
        path: "/faq",
        name: "Faq",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Faq,
        layout: "/admin",
        show: checkAccess("/faq"),
        dropdown: []
      },
      {
        path: "/support",
        name: "Support",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Support,
        layout: "/admin",
        show: checkAccess("/user-access-management"),
        dropdown: []
      },
      {
        path: "/reward",
        name: "Reward",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Reward,
        layout: "/admin",
        show: checkAccess("/user-access-management"),
        dropdown: []
      },
      {
        path: "/inquery",
        name: "Inquery Data",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Inquery,
        layout: "/admin",
        show: checkAccess("/inquery"),
        dropdown: []
      },
      {
        path: "/bank",
        name: "Bank List",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Bank,
        layout: "/admin",
        show: checkAccess("/bank"),
        dropdown: []
      },
      {
        path: "/external/link",
        name: "External Link",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Externallink,
        layout: "/admin",
        show: checkAccess("/external/link"),
        dropdown: []
      },
      {
        path: "/user-access-control",
        name: "User Acess Control",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: UserAccess,
        layout: "/admin",
        show: checkAccess("/permissions"),
        dropdown: []
      },
      {
        path: "/banners",
        name: "Banners",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Banners,
        layout: "/admin",
        show: checkAccess("/banners"),
        dropdown: []
      },
      {
        path: "/settings",
        name: "General Settings",
        rtlName: "الرموز",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Settings,
        layout: "/admin",
        show: checkAccess("/settings"),
        dropdown: []
      },
      {
        path: "/permissions",
        name: "Permission",
        rtlName: "الرموز",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Permission,
        layout: "/admin",
        show: is_super,
        dropdown: []
      },
      {
        path: "/notice",
        name: "Notice",
        rtlName: "الرموز",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Notice,
        layout: "/admin",
        show: checkAccess("/notice"),
        dropdown: []
      },
      {
        path: "/predefinetemplate",
        name: "Pre Define Templates",
        rtlName: "الرموز",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Predefinetemlate,
        layout: "/admin",
        show: checkAccess("/predefinetemplate"),
        dropdown: []
      },
      {
        path: "/admin-csp-codes",
        name: "Admin CSP Codes",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Admincspcodes,
        layout: "/admin",
        show: is_super,
        dropdown: []
      },
      {
        path: "/ntc-data-pack",
        name: "Ntc pack",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Ntcpack,
        layout: "/admin",
        show: checkAccess("/ntc-data-pack"),
        dropdown: []
      },
      {
        path: "/ncell-pack",
        name: "Ncell pack",
        rtlName: "",
        icon: "tim-icons icon-atom",
        image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
        component: Ncellpack,
        layout: "/admin",
        show: checkAccess("/ncell-pack"),
        dropdown: []
      },
    ]
  },

  {
    path: "/customerchats",
    name: "Customer chats",
    rtlName: "الرموز",
    icon: "tim-icons icon-atom",
    image: "https://scontent.xx.fbcdn.net/v/t1.15752-9/387335890_1405931800298474_1284220322512601712_n.png?stp=cp0_dst-png&_nc_cat=103&ccb=1-7&_nc_sid=510075&_nc_ohc=W5nQt6iLYqcAX9lcpxf&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_AdTy1BdgA3_-gguOYspnQCTAuBROIJIKx4SWRI1z4fwnrA&oe=657FAD49",
    component: Customerchats,
    layout: "/admin",
    show: checkAccess("/customerchats"),
    dropdown: []
  },
  {
    path: "/notification",
    name: "Notification",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: Notification,
    layout: "/admin",
    show: checkAccess("/notification"),
    dropdown: []
  },
  {
    path: "/notice-app",
    name: "Notice",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    image: "https://cdn-icons-png.flaticon.com/512/2099/2099192.png",
    component: NoticeApp,
    layout: "/admin",
    show: checkAccess("/notification"),
    dropdown: []
  },
];
export default routes;
