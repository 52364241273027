import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Banners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banners: [],
            placement: "top",
            video:"",
            link:"",
            status: "",
            banner_image: null,
            show_form: false
        }
    }
    componentDidMount = () => {
        this.getAllBanners();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onFileChange = (e) => {
        this.setState({ banner_image: e.target.files[0] })
    }

    saveBanner = e => {
        e.preventDefault();
        if (this.state.placement == "") {
            this.notify("error", "Please select banner placement.");
            return false;
        }
        if (this.state.status == "") {
            this.notify("error", "Please select banner status.");
            return false;
        }
        if (!this.state.banner_image) {
            this.notify("error", "Please select banner image.");
            return false;
        }
        const formData = new FormData();
        formData.append('banner_image', this.state.banner_image);
        formData.append('placement', this.state.placement);
        formData.append('status', this.state.status);
        formData.append('video', this.state.video);
        formData.append('link', this.state.link);
        Axios.post(`${apiUrl}/admin/save/banner`, formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    show_form: !this.state.show_form,
                    banner_image: null,
                    placement: "",
                    video:"",
                    link:"",
                    status: "",
                    banners: res.data.banners
                })
            } else {
                this.notify("error", res.data.message);
            }
        })
    }

    getAllBanners = () => {
        Axios.get(`${apiUrl}/admin/all/banners`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("banners response", res)
            if (res.data.success) {
                this.setState({
                    banners: res.data.banners
                })
            }
            else {
                this.setState({
                    banners: []
                })
            }
        })
    }
    deleteBanner = (e, id) => {
        e.preventDefault();
        Axios.get(`${apiUrl}/admin/delete/banner/${id}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.notify("success", res.data.message);
            if (res.data.success) {
                this.setState({
                    banners: res.data.banners
                })
            }
        }, err => {
            console.log(err)
        })
    }
    showForm = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: !this.state.show_form,
            banner_image: null,
            placement: "",
            status: ""
        })
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">Banners
                                    {!this.state.show_form && <small className="float-right"><a href="" onClick={(e) => { this.showForm(e) }} >Add New Banner</a></small>}
                                    </h3>
                                </CardHeader>
                                <CardBody>
                                    {this.state.show_form && <Form onSubmit={this.saveBanner}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Placement </label>
                                                    <select className="form-control" name="placement" value={this.state.placement} onChange={(e) => { this.inputChange(e) }}>
                                                        <option value="">Select</option>
                                                        <option value="top">Top</option>
                                                        <option value="bottom">Bottom</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Status </label>
                                                    <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                                                        <option value="">Select</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Banner Image</label>
                                                    <Input name="banner_image" type="file" onChange={(e) => { this.onFileChange(e) }} style={{ opacity: 1 }} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                <label>Video Link</label>
                                                <Input name="video" value={this.state.video} placeholder="Enter Youtube Link" type="text" onChange={(e) => { this.inputChange(e) }} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                <label>Link</label>
                                                <Input name="link" value={this.state.link} placeholder="Enter  Link" type="text" onChange={(e) => { this.inputChange(e) }} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                            Cancel
                                        </Button>
                                        <Button className="btn-fill" color="primary" type="submit">
                                            Save
                                        </Button>
                                    </Form>}
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>Placement</th>
                                                <th>Image</th>
                                                <th>Link</th>
                                                <th>Video Link</th>
                                                <th>Status</th>
                                                {/* <th>Position</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.banners.map((val, key) => {
                                                return <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{val.placement[0].toUpperCase() + val.placement.substr(1)}</td>
                                                    <td>
                                                        {val.image && <img src={imageUrl + val.image} alt=" " style={{ width: "50px" }} />}
                                                    </td>
                                                    <td>{val.link}</td>
                                                    <td>{val.video}</td>
                                                    <td>{val.status[0].toUpperCase() + val.status.substr(1)}</td>
                                                    {/* <td>{val.position}</td> */}
                                                    <td>
                                                        <a href="" onClick={(e) => { this.deleteBanner(e, val.id) }} >
                                                            {val.status === "active" && <i className="tim-icons icon-simple-remove" title="Inactive Category"></i>}
                                                            {val.status === "inactive" && <i className="tim-icons icon-check-2" title="Active Category"></i>}
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Banners;
