import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "routes.js";
import "assets/demo/demo.css";
import "assets/css/old-style.css";


var ps;

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }
    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/user") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    
    render() {
        return (
            <>
                {/* <div className="wrapper"> */}
                    
                        <Switch>
                            {this.getRoutes(routes)}
                            <Redirect from="*" to="/user/dashboard" />
                        </Switch>
                {/* </div> */}
            </>
        );
    }
}

export default User;
