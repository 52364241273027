import React from "react";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import swal from 'sweetalert';
import ReceiptModel from "./ReceiptModel";
import ServiceModal from "./child-components/ServiceModal";
import KycForm from "./child-components/KycForm";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class PrabhuRemittance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_form: true,
            customerotp:false,
         //   show_form: "check_user",
            search_number:"",
            search_type:"",
            show_reciver:false,
            result:null,
            sender_select:null,
            customer_id:null,
            reciver_list:[],
            show_sender_form:false,
            reciverdata:"",
            source_of_funds: [],
            id_types: [],
            states:[],
            districts:[],
            mobile:"",
            name:"",
            gender:"",
            date_of_birth:"",
            address:"",
            state:"",
            district:"",
            city:"",
            nationality:"",
            email:"",
            employer:"",
            id_type:"",
            id_number:"",
            id_issue_place:"",
            income_source:"",
            process_id:"",
            otp:"",
            show_reciver_form:false,
            relationships:[],
            account_details:false,
            banks:[],
            bank_branchs:[],
            receiver_name:"",
            receiver_gender:"",
            receiver_mobile:"",
            relationship:"",
            receiver_address:"",
            payment_mode:"",
            account_number:"",
            receiver_city:"",
            bank_name:"",
            bank_branch_name:"",
            show_details:false,
            purpose_of_remittance:[],
            remittance_reason:"",
            show_payment_details:"",
            amount:"",
            collect_amount:"",
            payout_amount:"",
            amount_paybale:"",
            service_charge:"",
            exchange_rate:"",
            txnotp:false,
            user: null,


            headers: {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access")),
                    Accept: 'application/json'
                }
            },
            service_modal: false,

        }


    }

    componentDidMount = () => {
        this.getStaticDataIDType();
        this.getStaticDataIncomeSource();
        this.getStaticDatar();
        this.getStaticDataRemittanceReason();
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");
        }
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
    }
    getStaticDataRemittanceReason = () => {
        Axios.post(`${apiUrl}/remittance/prabhu/get/static/data`, {'type' : 'RemittanceReason'},{
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("statisc data response", res.data.details.GetStaticDataResult.DataList.Data)
          //  this.setState(res.data.data)

                    this.setState({


                        purpose_of_remittance:res.data.details.GetStaticDataResult.DataList.Data,
                    //    purpose_of_remittance:res.data.data.purpose_of_remittance

                    })


        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    getStaticDataIncomeSource = () => {
        Axios.post(`${apiUrl}/remittance/prabhu/get/static/data`, {'type' : 'IncomeSource'},{
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("statisc data response", res.data.details.GetStaticDataResult.DataList.Data)
          //  this.setState(res.data.data)

                    this.setState({


                         source_of_funds:res.data.details.GetStaticDataResult.DataList.Data,
                    //    purpose_of_remittance:res.data.data.purpose_of_remittance

                    })


        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    getStaticDataIDType = () => {
        Axios.post(`${apiUrl}/remittance/prabhu/get/static/data`, {'type' : 'IDType'},{
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("statisc data response", res.data.details.GetStaticDataResult.DataList.Data)
          //  this.setState(res.data.data)

                    this.setState({

                     //   genders: res.data.data.gender,
                     //   source_of_funds: res.data.data.source_of_fund,

                         id_types:res.data.details.GetStaticDataResult.DataList.Data,
                    //    purpose_of_remittance:res.data.data.purpose_of_remittance

                    })


        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    getStaticDatar = () => {
        Axios.post(`${apiUrl}/remittance/prabhu/get/static/data`, {'type' : 'Relationship'},{
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("statisc data response", res.data.details.GetStaticDataResult.DataList.Data)
          //  this.setState(res.data.data)

                    this.setState({

                     //   genders: res.data.data.gender,
                     //   source_of_funds: res.data.data.source_of_fund,
                      //  id_types: res.data.data.owners_id_type,
                        relationships:res.data.details.GetStaticDataResult.DataList.Data,
                    //    purpose_of_remittance:res.data.data.purpose_of_remittance

                    })


        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    getStaticDatar = () => {
        Axios.post(`${apiUrl}/remittance/prabhu/get/static/data`, {'type' : 'Relationship'},{
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("statisc data response", res.data.details.GetStaticDataResult.DataList.Data)
          //  this.setState(res.data.data)

                    this.setState({

                     //   genders: res.data.data.gender,
                     //   source_of_funds: res.data.data.source_of_fund,
                      //  id_types: res.data.data.owners_id_type,
                        relationships:res.data.details.GetStaticDataResult.DataList.Data,
                    //    purpose_of_remittance:res.data.data.purpose_of_remittance

                    })


        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    checkAvailability = () => {
        // Axios.post(apiUrl + "/user/check/service/allowed", { service_id: 4 }, this.state.headers).then(res => {
        //     if (!res.data.success) {
        //         this.setState({ service_modal: true })
        //     }
        // })
    }
    toggleServiceModal = e => {
        this.props.history.push('/user/dashboard');
    }
    getData = (page = 1) => {

    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleInputMobile = e => {
        if (e.target.value.length <= 10) {
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            return false;
        }
    }
    handleInputCheckbox = e => {
        this.setState({
            save_beneficiary: e.target.checked ? "yes" : "no"
        })
    }
    handleInputOTP = e => {
        if (e.target.value.length <= 4) {
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            console.log(this.state.otp)
            return false;
        }
    }



    checkMobile = () => {
       // e.preventDefault();

        if (this.state.search_type === "") {
            swal("", "Please select type", "error");
            return false;
        }
        if (this.state.search_number === "") {
            swal("", "Please search number", "error");
            return false;
        }
        this.setState({ loading: true })

        Axios.post(`${apiUrl}/remittance/prabhu/search/customer/${this.state.search_type}`, { mobile: this.state.search_number }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res);
            this.setState({ loading: false })
            if (res.data.success) {
               this.setState({
                    result:res.data.result,
                    customer_id:res.data.result.customerId,
                    reciver_list:res.data.allReceivers,
                    show_reciver:true,
                    show_form:false,
                 })

            } else {
                this.setState({
                    show_sender_form:true,
                    mobile:this.state.search_number,
                    show_reciver:false,
                    show_form:false
                 })
             //   swal("",res.data.message, "error");
            }
        })
    }

    show_checkMobile = e => {

        this.setState({
            show_form:true,
            show_reciver:false,
        })

     }


     clearpaymentdetails = e => {

        this.setState({
            payment_details: false,
            show_reciver:true,

        })

      }
    show_reciver = e => {

            this.setState({
                show_reciver_form: true,
                show_reciver:false,

            })

    }
    show_reciver_list = e => {

        this.setState({
            show_reciver_form: false,
            show_reciver:true,
            show_details:false
        })

     }

    selectreciver = (e,hs) => {

        console.log('jdzfdsssss',this.state.result[0]);
          var id= hs.id;
         var datas="";
    //    this.state.reciver_list.map((pro) => {

    //        if (hs.id  == pro.id) {
               console.log('jdzfds',hs);

               this.setState({
                   reciverdata: hs,
                   payment_mode:hs.payment_mode

              });
    //        }
    //    })
       this.setState({

           [e.target.name]: e.target.value,
           show_details:true ,
           show_reciver:false
      });
   }

    clearreciver = e => {
        this.setState({
           show_reciver_form: false,
           show_reciver:true,
           receiver_mobile:"",
           receiver_name:"",
           receiver_gender:"",
           receiver_address:"",
           receiver_city:"",
           bank_name:"",
           bank_branch_name:"",
           account_number:"",
           payment_mode:"",
        })
   }

   sendotp = e => {
    e.preventDefault();

    if (this.state.mobile === "") {
        swal("", "Please Enter Mobile Number", "error");
        return false;
    }
    if (this.state.name === "") {
        swal("", "Please Enter Name", "error");
        return false;
    }
    if (this.state.gender === "") {
        swal("", "Please Enter Gender", "error");
        return false;
    }
    if (this.state.date_of_birth === "") {
        swal("", "Please Enter DOB", "error");
        return false;
    }
    if (this.state.address === "") {
        swal("", "Please Enter Address", "error");
        return false;
    }
    if (this.state.state === "") {
        swal("", "Please select state", "error");
        return false;
    }
    if (this.state.district === "") {
        swal("", "Please enter district", "error");
        return false;
    }
    // if (this.state.city === "") {
    //     swal("", "Please enter city", "error");
    //     return false;
    // }
    if (this.state.nationality === "") {
        swal("", "Please select nationality", "error");
        return false;
    }
    // if (this.state.email === "") {
    //     swal("", "Please enter email", "error");
    //     return false;
    // }
    if (this.state.employer === "") {
        swal("", "Please enter employer", "error");
        return false;
    }
    if (this.state.id_type === "") {
        swal("", "Please select id type", "error");
        return false;
    }
    if (this.state.id_number === "") {
        swal("", "Please enter id number", "error");
        return false;
    }
    if (this.state.id_issue_place === "") {
        swal("", "Please enter id issue place", "error");
        return false;
    }
    if (this.state.income_source === "") {
        swal("", "Please enter income source", "error");
        return false;
    }
    var data = {
        mobile: this.state.mobile,
        action: 'CreateCustomer',
        CustomerFullName:this.state.name,
        CustomerDOB:this.state.date_of_birth,
        CustomerIdNumber:this.state.id_number
    }

    this.setState({ loading: true })
    Axios.post(`${apiUrl}/remittance/prabhu/send/otp`, data, {
        headers: {
            Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
        }
    }).then(res => {
        this.setState({ loading: false })
        console.log("verify otp response", res)
        if (res.data.success) {
          //  swal("", res.data.message, "success");
            this.setState({
                process_id:res.data.process_id,
                customerotp:true,
                otp:"",
                show_sender_form:false,
            })
        }
        else {
            this.setState({
                process_id:"",
                customerotp:false,
                show_sender_form:true,
            })
            swal("", res.data.message, "error");
        }
    })
}

    create_sender = e => {
        e.preventDefault();

        if (this.state.mobile === "") {
            swal("", "Please Enter Mobile Number", "error");
            return false;
        }
        if (this.state.name === "") {
            swal("", "Please Enter Name", "error");
            return false;
        }
        if (this.state.gender === "") {
            swal("", "Please Enter Gender", "error");
            return false;
        }
        if (this.state.date_of_birth === "") {
            swal("", "Please Enter DOB", "error");
            return false;
        }
        if (this.state.address === "") {
            swal("", "Please Enter Address", "error");
            return false;
        }
        if (this.state.state === "") {
            swal("", "Please select state", "error");
            return false;
        }
        if (this.state.district === "") {
            swal("", "Please enter district", "error");
            return false;
        }
        // if (this.state.city === "") {
        //     swal("", "Please enter city", "error");
        //     return false;
        // }
        if (this.state.nationality === "") {
            swal("", "Please select nationality", "error");
            return false;
        }
        // if (this.state.email === "") {
        //     swal("", "Please enter email", "error");
        //     return false;
        // }
        if (this.state.employer === "") {
            swal("", "Please enter employer", "error");
            return false;
        }
        if (this.state.id_type === "") {
            swal("", "Please select id type", "error");
            return false;
        }
        if (this.state.id_number === "") {
            swal("", "Please enter id number", "error");
            return false;
        }
        if (this.state.id_issue_place === "") {
            swal("", "Please enter id issue place", "error");
            return false;
        }
        if (this.state.income_source === "") {
            swal("", "Please enter income source", "error");
            return false;
        }
        var data = {
            mobile:this.state.mobile,
            name:this.state.name,
            gender:this.state.gender,
            date_of_birth:this.state.date_of_birth,
            address:this.state.address,
            state:this.state.state,
            district:this.state.district,
            city:this.state.city,
            nationality:this.state.nationality,
            email:this.state.email,
            employer:this.state.employer,
            id_type:this.state.id_type,
            id_number:this.state.id_number,
            id_issue_place:this.state.id_issue_place,
            income_source:this.state.income_source,
            process_id:this.state.process_id,
            otp:this.state.otp,
        }
        this.setState({ loading: true })

        Axios.post(`${apiUrl}/remittance/prabhu/create/customer`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res);
            this.setState({ loading: false })
            if (res.data.success) {
                swal("",res.data.message, "success");

                this.setState({
                  //   result:res.data.result,
                     customerotp:false,
                     show_sender_form:false,
                     show_form:true,
                  });
                  this.clearcreate();
                  this.checkMobile();
            } else {
                this.setState({
                    show_sender_form:true,
                    show_reciver:false,
                    show_form:false,
                    customerotp:false
                 })
                swal("",res.data.message, "error");
            }
            this.setState({ loading: false })
        })
        this.setState({ loading: false })
    }

     clearcreate =() => {
         this.setState({
            show_sender_form:false,
            mobile: "",
            name:"",
            gender:"",
            date_of_birth:"",
            address:"",
            state:"",
            district:"",
            city:"",
            nationality:"",
            email:"",
            employer:"",
            id_type:"",
            id_number:"",
            id_issue_place:"",
            income_source:"",
            process_id:"",
            otp:"",
         })
     }
     loader_start = () => {
        this.setState({ loading: true })
     }
    create_reciver = e => {
        e.preventDefault();
        this.loader_start();
        if (this.state.receiver_mobile === "") {
            swal("", "Please Enter Mobile Number", "error");
            this.setState({ loading: false })
            return false;
        }
        if (this.state.receiver_name === "") {
            swal("", "Please Enter Name", "error");
            this.setState({ loading: false })
            return false;
        }
        if (this.state.relationship === "") {
            swal("", "Please Select Relationship.", "error");
            this.setState({ loading: false })
            return false;
        }

        if (this.state.receiver_gender === "") {
            swal("", "Please Enter Gender", "error");
            this.setState({ loading: false })
            return false;
        }

        if (this.state.receiver_address === "") {
            swal("", "Please Enter Address", "error");
            this.setState({ loading: false })
            return false;
        }

        if (this.state.receiver_city === "") {
            swal("", "Please enter city", "error");
            this.setState({ loading: false })
            return false;
        }
        if (this.state.payment_mode === "") {
            swal("", "Please enter payment mode", "error");
            this.setState({ loading: false })
            return false;
        }

        if(this.state.payment_mode === "Account Deposit"){
            if (this.state.bank_name === "") {
                swal("", "Please select bank name", "error");
                this.setState({ loading: false })
                return false;
            }
            if (this.state.bank_branch_name === "") {
                swal("", "Please enter bank branch name", "error");
                this.setState({ loading: false })
                return false;
            }
            if (this.state.account_number === "") {
                swal("", "Please enter account number", "error");
                this.setState({ loading: false })
                return false;
            }
        }

        var data = {
            customer_id:this.state.result[0].customerId,
            receiver_mobile:this.state.receiver_mobile,
            receiver_name:this.state.receiver_name,
            receiver_gender:this.state.receiver_gender,
            receiver_address:this.state.receiver_address,
            relationship:this.state.relationship,

            receiver_city:this.state.receiver_city,
            bank_name:this.state.bank_name,
            bank_branch_name:this.state.bank_branch_name,
            account_number:this.state.account_number,
            payment_mode:this.state.payment_mode,
            process_id:this.state.process_id,
            otp:this.state.otp,
        }


        Axios.post(`${apiUrl}/remittance/prabhu/create/receivers`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res);
            this.setState({ loading: false })
            if (res.data.success) {
                swal("",res.data.message, "success");
               this.setState({
                  //  result:res.data.result,
                 //   reciver_list:res.data.allReceivers,
                    show_reciver:true,
                 })
               this.clearreciver();
               this.checkMobile();
            } else {
                this.setState({
                    show_sender_form:false,
                    show_reciver:false,
                    show_form:false
                 })
                swal("",res.data.message, "error");
            }
        })
        this.setState({ loading: false })
    }

    getStaticData = () => {
        Axios.get(`${apiUrl}/remittance/ime-forex/get/all/static/data`, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("statisc data response", res)
          //  this.setState(res.data.data)

                    this.setState({

                        genders: res.data.data.gender,
                        source_of_funds: res.data.data.source_of_fund,
                        id_types: res.data.data.owners_id_type,
                      //  relationships:res.data.data.relationship,
                        purpose_of_remittance:res.data.data.purpose_of_remittance

                    })


        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }

    sendOtpTransaction = e => {
        console.log("this.state.reciverdata",this.state.reciverdata)
        if (this.state.remittance_reason === "") {
            swal("", "Please select remittance reason.", "error");
            return false;
        }
        if (this.state.amount === "") {
            swal("", "Please enter amount.", "error");
            return false;
        }
        var data = {

            mobile: this.state.search_number,
            customer_id: this.state.reciverdata.customer_id,
            receiver_id: this.state.reciverdata.receiver_id,
            AccountNumber:this.state.reciverdata.account_number,
            PaymentMode:this.state.reciverdata.payment_mode,
            BankBranchId:this.state.reciverdata.bank_branch_id,
            action:"SendTransaction",
            SendAmount:this.state.amount

        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/prabhu/send/otp`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log("verify otp response", res)
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.setState({
                    process_id:res.data.process_id,
                    txnotp:true,
                    payment_details:false,
                })
            }
            else {
                swal("", res.data.message, "error");
            }
        })

    }


    sendTransaction = e => {
        e.preventDefault();

        if (this.state.remittance_reason === "") {
            swal("", "Please select remittance reason.", "error");
            return false;
        }
        if (this.state.otp === "") {
            swal("", "Please enter otp.", "error");
            return false;
        }

        this.setState({ loading: true })
        var data = {
            receiver_id:this.state.reciverdata.receiver_id,
            receiver_name: this.state.reciverdata.receiver_name,
            receiver_gender: this.state.reciverdata.receiver_gender,
            receiver_address: this.state.reciverdata.receiver_address,
            receiver_mobile: this.state.reciverdata.receiver_mobile,
            receiver_city: this.state.reciverdata.receiver_city,
            relectionship:this.state.reciverdata.relationship,
            payout_country:"Nepal",
            send_currency:"INR",
            pay_currency:"NPR",
            income_source:"",
            payment_type: this.state.payment_type,
            bank_id: this.state.bank_id,
            bank_branch_id: this.state.bank_branch_id,
            bank_account_number: this.state.bank_account_number,
            customer_id:this.state.reciverdata.customer_id,
            sender_id:this.state.result[0].id,
            sender_name: this.state.result[0].name,
            sender_gender: this.state.result[0].gender,
            sender_dob: this.state.result[0].date_of_birth,
            sender_address: this.state.result[0].address,
            sender_mobile:this.state.result[0].mobile,
            sender_city: this.state.result[0].city,
            sender_district: this.state.result[0].district,
            sender_state: this.state.result[0].state,
            sender_nationality: this.state.result[0].nationality,
            sender_employer: this.state.result[0].employer,
            sender_id_type: this.state.result[0].id_type,
            sender_id_number: this.state.result[0].id_number,
            sender_id_expiry_date: "",
            sender_id_issue_place: "",
            income_source: this.state.result[0].income_source,
            remittance_reason:this.state.remittance_reason,
            pay_amount:this.state.amount_paybale,
            payment_mode:this.state.reciverdata.payment_mode,
            transfer_amount:this.state.amount,
            exchange_rate:this.state.exchange_rate,
            collected_amount: this.state.collect_amount,
            forex_session_id: this.state.forex_session_id,
            service_charge: this.state.service_charge,

            save_beneficiary: this.state.save_beneficiary,
            process_id:this.state.process_id,
            otp:this.state.otp,

        }
          if(this.state.reciverdata.payment_mode === "Account Deposit"){
              data.bank_name=  this.state.reciverdata.bank_name;
              data.bank_branch_id=  this.state.reciverdata.bank_branch_id;
              data.branch_name=  this.state.reciverdata.bank_branch_name;
              data.account_number=  this.state.reciverdata.account_number;
              data.account_type=  "";
          }

        Axios.post(`${apiUrl}/remittance/prabhu/send/transaction`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log("send transaction response", res)
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.setState({
                //    otp_token: res.data.otptoken,
                //    referance_number: res.data.referance_number,
                    show_form: true,
                    txnotp:false
                })
                // var countDownDate = moment().add(60, 'seconds');
                // var diff = 60;
                // var self = this;
                // var x = setInterval(function () {
                //     diff = countDownDate.diff(moment());

                //     if (diff <= 0) {
                //         clearInterval(x);
                //         self.setState({
                //             resend_time: 0
                //         })
                //     } else
                //         self.setState({
                //             resend_time: diff
                //         })
                // }, 1000);
            }
            else {
                 this.setState({
                       show_form: true,
                       txnotp:false
                 })
                swal("", res.data.message, "error");
            }
        }, err => {
            this.setState({ loading: false })
            swal("", err.data.message, "error");

            // swal("", "Session expired. Please login again.").then(o => {
            //     sessionStorage.clear();
            //     this.props.history.push("/");
            // })
        })
    }
    toggleModal = e => {
        this.setState({
            modalState: !this.state.modalState
        })
    }

    cancel = e => {
        e.preventDefault();
        this.clearForms();
    }

    handleImage = (e, name) => {
        this.setState({
            [name]: e.target.files[0]
        })
    }
    handleInputCountry = (e) => {
        this.setState({
            [e.target.name]: e.target.value,

        })
        if (e.target.value != "") {
            this.setState({ loading: true })
            Axios.post(`${apiUrl}/remittance/prabhu/get/states`, { country: e.target.value }, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({ loading: false })
                console.log(res)

                    this.setState({
                        states: res.data.data
                    })

            })
        }
    }
    handleInputState = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/prabhu/get/districts`, { state: e.target.value }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log(res)

                this.setState({
                    districts: res.data.data
                })

        })
    }
    handleInputMethod = (e) => {
        this.setState({
            [e.target.name]: e.target.value,

        });
         if(e.target.value === "Account Deposit"){
            this.setState({ loading: true })
            Axios.post(`${apiUrl}/remittance/prabhu/get/banks`, { country: "Nepal" }, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({ loading: false })
                console.log(res)
                    this.setState({
                        banks: res.data.banks,
                        account_details:true
                    })
            })
         }else{
            this.setState({
                account_details: false,

            });
         }
    }
    handleInputBranch = (e) => {
        this.setState({
            [e.target.name]: e.target.value,

        });

            this.setState({ loading: true })
            Axios.post(`${apiUrl}/remittance/prabhu/get/bank/branches`, { country: "Nepal" ,bank_name:e.target.value }, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({ loading: false })
                console.log(res)
                    this.setState({
                        bank_branchs: res.data.banks,

                    })
            })

    }
    handleInputselectBranch = (e) => {



        this.state.bank_branchs.map((pro) => {
            if (pro.BankBranchId === e.target.value) {
                this.setState({

                     bank_branch_name: pro.BranchName
                });

            }
        })
        this.setState({
            [e.target.name]: e.target.value,

        });
    }
  show_payment_details = (e) => {


        this.setState({
           payment_details: true,
           show_details:false,
         //  show_form:false
        });
      //   alert(this.state.reciverdata.payment_mode);
        console.log('data',this.state.reciverdata);
    }




    handleInputamountcheck = e => {

        this.setState({
            [e.target.name]: e.target.value,

        });
    }

    handleInputamount = e => {
        e.preventDefault();
        var data = {
            transfer_amount:  this.state.amount,
            payment_mode:this.state.reciverdata.payment_mode,

        }
        if(this.state.reciverdata.payment_mode === 'Account Deposit'){
            data.bank_branch_id=this.state.reciverdata.bank_branch_id
        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/prabhu/get/service/charge`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log("service charge", res)
            if (res.data.success) {
              //  swal("", res.data.message, "success");
                this.setState({
                    collect_amount:res.data.data.CollectionAmount,
                    service_charge:res.data.data.ServiceCharge,
                    exchange_rate:res.data.data.ExchangeRate,
                    amount_paybale:res.data.data.PayoutAmount,



                })
            }
            else {
                swal("", res.data.message, "error");
            }
        })
    }

    getRelationship = (id) => {
        var x = id;
        this.state.relationship.forEach((rs) => {
            if (rs.option_id === id) {
                x = rs.value;
            }
        });
        return x;
    }
    selectSender = (e, bene) => {
        e.preventDefault();
        this.setState({
            receiver_name: bene.ReceiverName,
            receiver_address: bene.ReceiverAddress,
            receiver_gender: bene.ReceiverGender,
            receiver_mobile_number: bene.ReceiverMobileNo,
            receiver_city: bene.ReceiverCity,
            relationshipw: bene.Relationship,
            source_fund: bene.SourceOfFund,
            remittance_reason: bene.PurposeOfRemittance,
            payment_type: bene.PaymentType,
            bank_id: bene.BankId ? bene.BankId : "",
            bank_branch_id: bene.BankBranchId ? bene.BankBranchId : "",
            bank_account_number: bene.BankAccountNumber ? bene.BankAccountNumber : "",
            show_form: "remittance",
            bene_selected: true
        })
    }
    newTransaction = e => {
        e.preventDefault();
        this.setState({ show_form: "remittance" })
    }
    hidekyc = () => {
        this.setState({
            show_form: 'check_user',
            send_user_id: null
        })
    }
    render() {
        var kyc_functions = {
            hidekyc: this.hidekyc
        };
        return <>
            <Userheader user={this.state.user} />
            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}


            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="form-sec-dash">
                                <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p> Prabhu Remittance </p>
                                        {this.state.show_form === "check_user" && <span>Search Customer</span>}

                                    </h3>
                                </div>

                                {this.state.show_form  &&  <>
                                    {this.state.user && <>
                                     {this.state.user.prabhu_registered === 'no' && <>
                                <div className="row">
                                      <h3>Search Type</h3>
                                   <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="id_number" >ID Number</label>
                                            <input type="radio" id="id_number" style={{"width": "auto"}} name="search_type"  onChange={e => { this.handleInput(e) }} value="id_number" />

                                            <label for="mobile_type" >Mobile Number </label>
                                            <input type="radio" id="mobile_type" style={{"width": "auto"}} name="search_type"  onChange={e => { this.handleInput(e) }} value='mobile' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label >Search Coustomer</label>
                                            <input type="number" className="form-control" name="search_number" placeholder="Enter Search  Number" onChange={e => { this.handleInputMobile(e) }} value={this.state.search_number} />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="send-bt-dash">
                                            <a href="javascript:void(0)" onClick={e => { this.checkMobile(e) }}>Check</a>
                                        </div>
                                    </div>
                                    </div>

                                    </>}
                                    {this.state.user.prabhu_registered === 'yes' && <>
                                    <div className="row">
                                      <h3>You are not registered for Prabhu Remittance. Please contact to admin.</h3>

                                      </div>

                                    </>}
                                    </> }
                                    </> }

                            {this.state.show_reciver && <>
                               <div className="row">
                               <div className="col-md-12">
                                     <div className="send-bt-dash right_contentsec">
                                            <a href="javascript:void(0)" onClick={e => { this.show_reciver(e) }}>Create Reciver</a>
                                         </div>


                                    </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Relationship</th>
                                                <th>PaymentMode</th>
                                                <th>BankName</th>
                                                <th>BankBranchName</th>
                                                <th>BankBranchId</th>
                                                <th>AcNumber</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody className="table-space">
                                            {this.state.reciver_list.map((hs, key) => {
                                                return <tr>

                                                <td>{hs.receiver_name}</td>
                                                <th>{hs.receiver_mobile}</th>
                                                <td>{hs.relationship}</td>
                                                <td>{hs.payment_mode}</td>
                                                <th>{hs.bank_name ? hs.bank_name  : 'N/A' }</th>
                                                <td>{hs.bank_branch_name ? hs.bank_branch_name : 'N/A'}</td>
                                                <td>{hs.bank_branch_id ? hs.bank_branch_id : 'N/A'}</td>
                                                <td>{hs.account_number ? hs.account_number : 'N/A'}</td>
                                                <th><a href="#" onClick={e => { this.selectreciver(e ,hs) }} >Select</a></th>
                                            </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label >Select Reciver</label>
                                            <select type="number" className="form-control" name="reciverid"  onChange={e => { this.selectreciver(e) }} value={this.state.reciverid} >
                                               <option>Select Reciver</option>
                                               {this.state.reciver_list.map((hs, key) => {
                                                   return <option value={hs.id} >{hs.receiver_name}({hs.receiver_mobile})</option>
                                                 })}
                                            </select>
                                        </div>
                                    </div>    */}
                                     <div className="col-md-12">

                                     <div className="send-bt-dash right_contentsec">
                                            <a href="javascript:void(0)" onClick={e => { this.show_checkMobile(e) }}>Back</a>
                                         </div>

                                    </div>



                                </div>
                                </>}

                         {this.state.show_sender_form && <>

                                  <h3>Create Sender</h3>
                               <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Mobile No.</label>
                                            <input type="number" className="form-control" name="mobile" placeholder="Enter Mobile  Number" onChange={e => { this.handleInputMobile(e) }} value={this.state.mobile} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Name</label>
                                            <input type="text" className="form-control" name="name" placeholder="Enter Name" onChange={e => { this.handleInput(e) }} value={this.state.name} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Nationality</label>
                                            <select className="form-control" name="nationality"  onChange={e => { this.handleInput(e) }} value={this.state.nationality} >
                                               <option>Select Nationality</option>
                                               <option value="Nepalese" >Nepalese</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >DOB</label>
                                            <input type="date" className="form-control" name="date_of_birth" onChange={e => { this.handleInput(e) }} value={this.state.date_of_birth} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">

                                            <label >Gender</label>
                                            <select className="form-control" name="gender"  onChange={e => { this.handleInput(e) }} value={this.state.gender} >
                                               <option>Select Gender</option>

                                                 <option value="male" >Male</option>
                                                 <option value="female" >Female</option>
                                                 <option value="other" >Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">

                                            <label >E-mail</label>
                                            <input type="email" className="form-control" name="email" placeholder="Enter E-mail Address" onChange={e => { this.handleInput(e) }} value={this.state.email} />
                                        </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">

                                            <label >Employer</label>
                                            <input type="text" className="form-control" name="employer" placeholder="Enter Employer" onChange={e => { this.handleInput(e) }} value={this.state.employer} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">

                                            <label >Source of Found</label>
                                            <select className="form-control" name="income_source"  onChange={e => { this.handleInput(e) }} value={this.state.income_source} >
                                               <option>Select Source of Found</option>
                                                {this.state.source_of_funds.map((hs, key) => {
                                                   return <option value={hs.Value} >{hs.Value}</option>
                                                 })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">

                                            <label >Id Type</label>
                                            <select className="form-control" name="id_type"  onChange={e => { this.handleInput(e) }} value={this.state.id_type} >
                                               <option>Select Id Type</option>
                                                {this.state.id_types.map((hs, key) => {
                                                   return <option value={hs.Value} >{hs.Value}</option>
                                                 })}
                                            </select>
                                        </div>
                                    </div>
                                   </div>
                                   <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">

                                            <label >Id Type No</label>
                                            <input type="text" className="form-control" name="id_number" placeholder="Enter Id Number" onChange={e => { this.handleInput(e) }} value={this.state.id_number} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">

                                            <label >Id Place of Issue</label>
                                            <input type="text" className="form-control" name="id_issue_place" placeholder="Enter Id Place of Issue" onChange={e => { this.handleInput(e) }} value={this.state.id_issue_place} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">

                                            <label >Country</label>
                                            <select className="form-control" name="country"  onChange={e => { this.handleInputCountry(e) }} value={this.state.country} >
                                                  <option>Select Country</option>

                                                 <option value="India" >India </option>
                                                 <option value="Nepal" >Nepal</option>

                                            </select>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">

                                            <label >State</label>
                                            <select className="form-control" name="state"  onChange={e => { this.handleInputState(e) }} value={this.state.state} >
                                               <option>Select State</option>
                                                {this.state.states.map((hs, key) => {
                                                   return <option value={hs} >{hs}</option>
                                                 })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">

                                            <label >District</label>
                                            <select className="form-control" name="district"  onChange={e => { this.handleInput(e) }} value={this.state.district} >
                                               <option>Select District</option>
                                                {this.state.districts.map((hs, key) => {
                                                   return <option value={hs} >{hs}</option>
                                                 })}
                                            </select>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row" >
                                    <div className="col-md-6">
                                        <div className="form-group">

                                            <label >Address</label>
                                            <input type="text" className="form-control" name="address" placeholder="Enter address" onChange={e => { this.handleInput(e) }} value={this.state.address} />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">

                                            <label >City</label>
                                            <input type="text" className="form-control" name="city" placeholder="Enter city" onChange={e => { this.handleInput(e) }} value={this.state.city} />
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row" >



                                            <input type="checkbox" style={{    "width": "55px", "height": "18px"}} className="" name="checkbox" placeholder="Enter address" onChange={e => { this.handleInput(e) }} value={this.state.checkbox} />
                                           <p>By clicking the check box.I accept the below declaration.</p> <br></br>
                                           <p>1. I hereby declare, that time information filled here in above is correct as per the information provided by the customer.</p><br></br>
                                           <p>2. The Customer has approached me in person for the remittance service.</p><br></br>
                                           <p>3. The Customer is a Nepal Migrant.</p><br></br>
                                           <p>4. The Customer ID document have been verified in original.</p><br></br>
                                           <p>5. The Customer has not registered under any other mobile no, or under any other ID document.</p>


                                    </div>
                                    <div className="col-md-12">
                                        <div className="send-bt-dash">
                                            <a href="javascript:void(0)" onClick={e => { this.sendotp(e) }}>Next</a>
                                        </div>
                                    </div>

                                </>}

                            {this.state.customerotp && <>
                               <h3>Enter Otp</h3>
                                <div className="row">

                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Enter Otp</label>

                                          <input type="number" className="form-control" name="otp"  placeholder="Enter Otp" onChange={e => { this.handleInput(e) }} value={this.state.otp} />

                                      </div>
                                  </div>
                                  <div className="col-md-12">
                                      <div className="send-bt-dash">
                                          <a href="javascript:void(0)" onClick={e => { this.create_sender(e) }}>Submit</a>
                                      </div>
                                  </div>
                                </div>
                             </>}

                     {this.state.show_reciver_form && <>
                          <h3>Create Reciver</h3>
                             <div className="row">
                                  <div className="col-md-4">
                                      <div className="form-group">
                                          <label >Mobile No.</label>
                                          <input type="number" className="form-control" name="receiver_mobile" placeholder="Enter Mobile  Number" onChange={e => { this.handleInputMobile(e) }} value={this.state.receiver_mobile} />
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">
                                          <label >Name</label>
                                          <input type="text" className="form-control" name="receiver_name" placeholder="Enter Name" onChange={e => { this.handleInput(e) }} value={this.state.receiver_name} />
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Gender</label>
                                          <select className="form-control" name="receiver_gender"  onChange={e => { this.handleInput(e) }} value={this.state.receiver_gender} >
                                             <option>Select Gender</option>

                                               <option value="male" >Male</option>
                                               <option value="female" >Female</option>
                                               <option value="other" >Other</option>
                                          </select>
                                      </div>
                                  </div>
                           </div>
                         <div className="row">
                              <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Address</label>
                                          <input type="text" className="form-control" name="receiver_address" placeholder="Enter address" onChange={e => { this.handleInput(e) }} value={this.state.receiver_address} />
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >City</label>
                                          <input type="text" className="form-control" name="receiver_city" placeholder="Enter city" onChange={e => { this.handleInput(e) }} value={this.state.receiver_city} />
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Relationship</label>
                                          <select className="form-control" name="relationship"  onChange={e => { this.handleInput(e) }} value={this.state.relationship} >
                                             <option>Select</option>
                                              {this.state.relationships.map((hs, key) => {
                                                 return <option value={hs.Value} >{hs.Value}</option>
                                               })}
                                          </select>
                                      </div>
                                  </div>

                             </div>
                             <div className="row">
                                    <div className="col-md-4">
                                            <div className="form-group">

                                                <label >Payment Mode</label>
                                                <select className="form-control" name="payment_mode"  onChange={e => { this.handleInputMethod(e) }} value={this.state.payment_mode} >
                                                    <option>Select Payment Mode</option>
                                                    <option value="Cash Payment" >Cash Payment</option>
                                                    <option value="Account Deposit" >Account Deposit</option>
                                                </select>
                                            </div>
                                        </div>
                                    {this.state.account_details && <>
                                        <div className="col-md-4">
                                            <div className="form-group">

                                                <label >Bank Name</label>
                                                <select className="form-control" name="bank_name"  onChange={e => { this.handleInputBranch(e) }} value={this.state.bank_name} >
                                                    <option>Select</option>
                                                    {this.state.banks.map((hs, key) => {
                                                        return <option value={hs} >{hs}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                        <label >Bank Branch</label>
                                        <select className="form-control" name="bank_branch_id"  onChange={e => { this.handleInputselectBranch(e) }} value={this.state.bank_branch_id} >
                                            <option>Select</option>
                                            {this.state.bank_branchs.map((hs, key) => {
                                                return <option value={hs.BankBranchId} >{hs.BranchName}</option>
                                            })}
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                            <div className="form-group">
                                                <label >Account No</label>
                                                <input type="text" className="form-control" name="account_number" placeholder="Enter Account Number" onChange={e => { this.handleInput(e) }} value={this.state.account_number} />
                                            </div>
                                    </div>
                                     </>}
                                </div>


                                  <div className="col-md-12">
                                      <div className="send-bt-dash">
                                      <a href="javascript:void(0)" onClick={e => { this.clearreciver(e) }}>Cancel</a>&nbsp;&nbsp;
                                          <a href="javascript:void(0)" onClick={e => { this.create_reciver(e) }}>Create</a>
                                      </div>
                                  </div>

                              </>}

                      {this.state.show_details && <>
                          <h3>Sender Detail</h3>
                             <div className="row">
                               <div className="col-md-4">
                                      <div className="form-group">
                                          <label >Name :  {this.state.result ? this.state.result[0].mobile : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">
                                          <label >Mobile No : {this.state.result ? this.state.result[0].name : ""}</label>

                                      </div>
                                  </div>

                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Gender :  {this.state.result ? this.state.result[0].gender : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >DOB : {this.state.result ? this.state.result[0].date_of_birth : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Nationality :   {this.state.result ? this.state.result[0].nationality : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >State :     {this.state.result ? this.state.result[0].state : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >District : {this.state.result ? this.state.result[0].district : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Address : {this.state.result ? this.state.result[0].address : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >City : {this.state.result ? this.state.result[0].city : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Employer : {this.state.result ? this.state.result[0].employer : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Source Of Found : {this.state.result ? this.state.result[0].income_source : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Id Type : {this.state.result ? this.state.result[0].id_type : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Id Number :  {this.state.result ? this.state.result[0].id_number : ""}</label>

                                      </div>
                                  </div>
                                  </div>
                                  <h3>Reciver Detail</h3>
                                  <div className="row" >

                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Name : {this.state.reciverdata ? this.state.reciverdata.receiver_name : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Mobile Number :   {this.state.reciverdata ? this.state.reciverdata.receiver_mobile : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Gender :  {this.state.reciverdata ? this.state.reciverdata.receiver_gender : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Address :  {this.state.reciverdata ? this.state.reciverdata.receiver_address : ""}</label>

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Relationship :    {this.state.reciverdata ? this.state.reciverdata.relationship : ""}</label>

                                      </div>
                                  </div>

                                  <div className="col-md-12">
                                      <div className="send-bt-dash">

                                          <a href="#" onClick={e => { this.show_reciver_list(e) }}>Back</a>&nbsp;&nbsp;&nbsp;
                                          <a href="#" onClick={e => { this.show_payment_details(e) }}>Next</a>
                                      </div>
                                  </div>
                           </div>

                           </>}


                     {this.state.payment_details && <>
                          <h3>Payment Details</h3>
                             <div className="row">

                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Remittance Reason</label>
                                          <select className="form-control" name="remittance_reason"  onChange={e => { this.handleInput(e) }} value={this.state.remittance_reason}  >
                                             <option>Select</option>

                                             {this.state.purpose_of_remittance.map((rr, key) => {
                                                    return <option value={rr.Value} key={key}>{rr.Value}</option>
                                                })}
                                          </select>
                                      </div>
                                  </div>

                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Payment Mode</label>
                                          <p>{this.state.reciverdata ? this.state.reciverdata.payment_mode : ""}</p>
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Amount(INR)</label>
                                          <input type="number" className="form-control"  name="amount" placeholder="Enter Amount"  onChange={e => { this.handleInputamountcheck(e) }} onBlur={e => { this.handleInputamount(e) }} value={this.state.amount } />
                                      </div>
                                  </div>
                           </div>
                         <div className="row">
                              <div className="col-md-4">
                                      <div className="form-group">

                                          <label > Amount Collected (INR)</label>
                                          <input type="number" className="form-control" name="collect_amount" readOnly placeholder="Collected Amount" onChange={e => { this.handleInput(e) }} value={this.state.collect_amount} />
                                      </div>
                                  </div>
                               <div className="col-md-4">
                                  <div className="form-group">
                                    <label > Amount Payment (NPR)</label>


                                    <input type="number" className="form-control" name="amount_paybale" readOnly placeholder="Paybale Amount" onChange={e => { this.handleInput(e) }} value={this.state.amount_paybale} />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label > Service Charge (INR)</label>
                                    <input type="number" className="form-control" name="service_charge" readOnly placeholder="Service Charge" onChange={e => { this.handleInput(e) }} value={this.state.service_charge} />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">

                                        <label >Exchange Rate</label>
                                         <p>{this.state.exchange_rate}</p>
                                    </div>
                                </div>


                             </div>



                                  <div className="col-md-12">
                                      <div className="send-bt-dash">
                                      <a href="javascript:void(0)" onClick={e => { this.clearpaymentdetails(e) }}>Back</a>&nbsp;&nbsp;
                                          <a href="javascript:void(0)" onClick={e => { this.sendOtpTransaction(e) }}>Next</a>
                                      </div>
                                  </div>

                              </>}

                              {this.state.txnotp && <>
                               <h3>Enter Otp</h3>
                                <div className="row">

                                  <div className="col-md-4">
                                      <div className="form-group">

                                          <label >Enter Otp</label>

                                          <input type="number" className="form-control" name="otp"  placeholder="Enter Otp" onChange={e => { this.handleInput(e) }} value={this.state.otp} />

                                      </div>
                                  </div>
                                  <div className="col-md-12">
                                      <div className="send-bt-dash">
                                          <a href="javascript:void(0)" onClick={e => { this.sendTransaction(e) }}>Submit</a>
                                      </div>
                                  </div>
                                </div>
                             </>}

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <ReceiptModel modalState={this.state.modalState} toggleModal={this.toggleModal} />
            <Userfooter />
            <ServiceModal {...this.state} toggleServiceModal={this.toggleServiceModal} apiUrl={apiUrl} />
        </>;
    }
}
export default PrabhuRemittance;

