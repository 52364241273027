import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
import { Pagination } from 'react-laravel-paginex'
import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);


var cu = sessionStorage.getItem('cuser') ? Cryp.decrypt(sessionStorage.getItem('cuser')) : null;
const userids = [1];
const moment = require('moment');
var apiUrl = env.url;
var imageUrl = env.img_url;
class AdminHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            modal: false,
            selected_record: null,
            selected_user_id: null,
            start_date: "",
            end_date: "",
            reason: "",
            selected_id: "",
            keyword: "",
            sort_by: "all",
            rejectModal: false,
            imageModal: false,
            headers: {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access"),
                    Accept: "application/json"
                }
            },
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            },

        }
    }
    componentDidMount = () => {
        let search = this.props.history.location.search;
        let params = new URLSearchParams(search);
        let user_id = params.get('q');
        if (user_id) {
            this.setState({
                selected_user_id: +user_id
            })
        }
        setTimeout(() => {
            this.getList();
        }, 100);

    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    getList = (page = 1, keyword) => {
        var data = { keyword: keyword ? keyword : this.state.keyword, sort_by: this.state.sort_by };
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.selected_user_id) {
            data.selected_user_id = this.state.selected_user_id;
        }
        Axios.post(`${apiUrl}/admin/history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                paginatedata: res.data.details,
                list: res.data.details.data,
                selected_record: res.data.single,
                modal: res.data.single !== null
            });
        })
    }


    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    viewImage = (e, image) => {
        if (e) e.preventDefault();
        this.setState({
            selected_image: imageUrl + image,
            imageModal: true
        })
    }
    toggleImageModal = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            imageModal: !this.state.imageModal,
        })
    }
    toggle = (e, usr) => {
        if (e) e.preventDefault();
        console.log(usr)
        this.setState({
            modal: !this.state.modal,
            selected_record: usr
        })
    }
    rejectMember = e => {
        if (e) e.preventDefault();
        var data = {
            kyc_id: this.state.selected_record.id
        }
        Axios.post(apiUrl + "/history/list", data, this.state.headers).then(res => {
            console.log(res)
            this.notify(res.data.success ? "success" : "error", res.data.message);
            this.toggle();
            this.getList();
            // if (this.state.selected_user_id) {
            //     setTimeout(() => {
            //         window.location.href = "/admin/all-users"
            //     }, 2000);
            // }
        })
    }

    approveMember = e => {
        if (e) e.preventDefault();
        var data = {
            kyc_id: this.state.selected_record.id
        }
        Axios.post(apiUrl + "/history/list", data, this.state.headers).then(res => {
            this.notify(res.data.success ? "success" : "error", res.data.message);
            this.toggle();
            this.getList();
            // if (this.state.selected_user_id) {
            //     setTimeout(() => {
            //         window.location.href = "/admin/all-users"
            //     }, 2000);
            // }
        })
    }
    searchKyc = (e) => {
        var key = e.target.value
        this.setState({
            keyword: e.target.value
        })
        if (key.length > 2 || key === "") {
            this.getList(1, key);
        }
    }
    changePage = (page) => {
        console.log(page)
        this.getList(page.page)
    }
     handleError = (event) => {
        this.setState({ photo: "/uploads/static/anime3.png" });
    }
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <div className="col-md-3">
                                        <CardTitle tag="h4">Admin History List</CardTitle>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="searchbox searchbox_nar">
                                                <input type="text" name="keyword" className="form-control" onChange={(e) => { this.searchKyc(e) }} value={this.state.keyword} placeholder="Search keyword" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Filter By</label>
                                            <select name="sort_by" className="form-control" onChange={e => { this.handleInput(e) }} value={this.state.sort_by}>
                                                <option value="">all</option>
                                                {/* <option value="users">Registered Users</option> */}
                                                {/* <option value="others">Not registered</option> */}
                                                <option value="pending">Approval Pending</option>
                                                <option value="approved">Approved</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" className="btn btn-primary mt-4" onClick={e => this.getList()}><i className="fa fa-search"></i> Search</button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>User Name</th>
                                                <th>User Mobile</th>
                                                <th>Type</th>
                                                <th>Amount</th>
                                                <th>OLD BALANCE</th>
                                                <th>NEW BALANCE</th>
                                                <th>REMARKS</th>
                                                <th>UTR NO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.list.map((usr, k) => {
                                                return <tr>
                                                    <td>{this.state.paginatedata.per_page * (this.state.paginatedata.current_page - 1) + k + 1}. </td>
                                                    <td>{moment(usr.created_at).format('DD-MM-YYYY h:mm a')}</td>
                                                    <td>{usr.user.name}</td>
                                                    <td>{usr.user.mobile}</td>
                                                    <td>{usr.type}</td>
                                                    <td>₹{usr.amount}</td>
                                                    <td>₹{usr.old_balance}</td>
                                                    <td>₹{usr.new_balance}</td>
                                                    <td>{usr.description}</td>
                                                    <td>{usr.transaction_id ? usr.transaction_id : "N/A"}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default AdminHistory;
