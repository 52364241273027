import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import { Pagination } from 'react-laravel-paginex'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { CSVLink } from "react-csv";
import env from "variables/constants";
const moment = require("moment");
var apiUrl = env.url;
var imageUrl = env.img_url;
class Remittancewallethistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: [],
            options: [],
            allData:[],
            //  optionss:[],
            exceldata: [],
            selected_user_id: null,
            selected_txn_id: null,
            start_date: moment().startOf('month').format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            type: "",
            service_type:"",
            transaction_id: "",
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }
    componentDidMount = () => {
        this.getRecords(1);
    }
    getRecords = (page = 1) => {
        console.log(page)
        var data = {};
        var filter = new URLSearchParams(this.props.location.search).get('filter');
        if (this.state.selected_user_id) {
            data.user_id = this.state.selected_user_id;
        }
        if (this.state.selected_txn_id) {
            data.txn_id = this.state.selected_txn_id;
        }

        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.type != "") {
            data.type = this.state.type;
        }
        if (filter && filter !== "") {
            data.filter = filter;
        }
        if (this.state.service_type != "") {
            data.service_type = this.state.service_type;
        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/admin/remittance/wallet/history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response123", res)
            // if (page === 1 || page == "1") {


            // }
            if (res.data.success) {

                this.setState({
                    paginatedata: res.data.details,
                    details: res.data.details.data,
                    allData:res.data.allData
                })

                setTimeout(() => {
                    var exceldata = [];
                    this.state.allData.forEach((val, key) => {
                        var dat = {
                            "Date": moment(val.created_at).format('YYYY-MM-DD h:mm a'),
                            "Name": val.user ? val.user.name : "",
                            "Mobile": val.user ? val.user.mobile : "",
                            "Type": val.type,
                            "Filter": val.filter ? val.filter : "addmoney",
                            "Amount INR": (+val.amount).toLocaleString('en', 1),
                            "Amount NPR": (+val.amount / 100 * 160).toLocaleString('en', 1),
                            "Old Balance": (+val.old_balance).toLocaleString('en', 1),
                            "New Balance": (+val.new_balance).toLocaleString('en', 1),
                            "Remarks": val.description,
                            "Recharge Number": val.recharge ? val.recharge.phone : "",
                            "Company Name": val.recharge ? val.recharge.service_provider.provider_name : "",

                            "Cancel Charge": val.remittance ? val.remittance.service_charge : "",
                            "Remittance": val.remittance ? val.remittance.amount : "",
                            // "Payment Mode" : val.filter == "addmoney" ? val.addmoney.deposit_via : (val.remittance.PaymentType == "C" ? "cash" : "bank"),

                        }
                        exceldata.push(dat);
                    });
                    this.setState({
                        exceldata: exceldata
                    })
                    console.log("asdfdasfasfasdfasdf",this.state.exceldata)
                }, 100);

            }
            else {
                this.setState({
                    details: [],
                    exceldata: []
                })
            }
            this.setState({ loading: false })
        }, err => {
            this.setState({ loading: false })

        })
    }
    getRecordsExcel = (page = 1) => {
        var data = { forexcel: "yes" };
        var filter = new URLSearchParams(this.props.location.search).get('filter');
        if (this.state.selected_user_id) {
            data.user_id = this.state.selected_user_id;
        }
        if (this.state.selected_txn_id) {
            data.txn_id = this.state.selected_txn_id;
        }
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.type != "") {
            data.type = this.state.type;
        }
        if (this.state.service_type != "") {
            data.service_type = this.state.service_type;
        }
        if (filter && filter !== "") {
            data.filter = filter;
        }
        // this.setState({ loading: false })
        Axios.post(`${apiUrl}/admin/remittance/wallet/history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response excel", res)
            if (res.data.success) {
                console.log("providers response excel", res)
                var exceldata = [];
                res.data.details.forEach((val, key) => {
                    var dat = {
                        "Date": moment(val.created_at).format('YYYY-MM-DD h:mm a'),
                        "Name": val.user ? val.user.name : "",
                        "Mobile": val.user ? val.user.mobile : "",
                        "Type": val.type,
                        "Filter": val.filter ? val.filter : "addmoney",
                        "Amount INR": (+val.amount).toLocaleString('en', 1),
                        "Amount NPR": (+val.amount / 100 * 160).toLocaleString('en', 1),
                        "Old Balance": (+val.old_balance).toLocaleString('en', 1),
                        "New Balance": (+val.new_balance).toLocaleString('en', 1),
                        "Remarks": val.description,
                        "Recharge Number": val.recharge ? val.recharge.phone : "",
                        "Company Name": val.recharge ? val.recharge.service_provider.provider_name : "",

                        "Cancel Charge": val.remittance ? val.remittance.service_charge : "",
                        "Remittance": val.remittance ? val.remittance.amount : "",
                        "Payment Mode" : val.filter == "addmoney" ? val.addmoney.deposit_via : (val.remittance.PaymentType == "C" ? "cash" : "bank"),

                    }
                    exceldata.push(dat);
                });
                this.setState({
                    exceldata: exceldata
                })
                console.log("asdfdasfasfasdfasdf",this.state.exceldata)
            }
            else {
                this.setState({
                    details: []
                })
            }
            // this.setState({ loading: false })
        }, err => {
            // this.setState({ loading: false })

        })
    }
    changePage = data => {
        console.log(data)
        this.getRecords(data.page);
    }
    searchUser = e => {
        if (e.length > 2) {
            var data = { keyword: e };
            Axios.post(apiUrl + "/admin/users/list", data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                console.log(res)
                this.setState({ options: res.data.users, isLoading: false })
            })
        }

    }
    searchid = e => {
        if (e.length > 2) {
            var data = { keyword: e };
            Axios.post(apiUrl + "/admin/transaction_id", data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                console.log(res)
                this.setState({ options: res.data.result, isLoading: false })
            })
        }
    }

    selectid = selected => {
        console.log(selected)
        this.setState({ selected_txn_id: selected.length > 0 ? selected[0].id : null });
    }
    selectUser = selected => {
        console.log(selected)
        this.setState({ selected_user_id: selected.length > 0 ? selected[0].id : null });
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    render() {
        return (
            <>
                {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">
                                        Wallet History
                                        <small className="float-right">
                                            {this.state.exceldata.length > 0 && <CSVLink data={this.state.exceldata} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"Remittance-wallet-history-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}

                                            {this.state.exceldata.length === 0 && <a href="" onClick={(e) => { e.preventDefault() }} style={{ color: "#d4d6d5", background: "none", padding: "10px 8px", borderRadius: "0px", pointerEvents: "none" }} title="Export to CSV"><i className="fa fa-spinner fa-spin"></i>Excel</a>}
                                        </small>
                                    </h3>
                                    <div className="row userhistoryrem">
                                        <div className="col-md-2">
                                            <label>User</label>
                                            <AsyncTypeahead
                                                id="asdasd"
                                                isLoading={this.state.isLoading}
                                                labelKey={option => `${option.name} (${option.mobile})`}
                                                onSearch={(query) => {
                                                    this.setState({ isLoading: true });
                                                    this.searchUser(query);
                                                }}
                                                options={this.state.options}
                                                onChange={selected => { this.selectUser(selected) }}
                                            />
                                        </div>

                                        <div className="col-md-2">
                                            <label>Mobile/Transaction Id</label>
                                            <AsyncTypeahead
                                                id="transaction_id"
                                                isLoading={this.state.isLoading}
                                                labelKey={option => `${option.transaction_id} (${option.phone})`}
                                                onSearch={(query) => {
                                                    this.setState({ isLoading: true });
                                                    this.searchid(query);
                                                }}
                                                options={this.state.options}
                                                onChange={selected => { this.selectid(selected) }}
                                            />
                                        </div>

                                        {/* <div className="col-md-2">
                                            <label>Mobile/Transaction Id</label>
                                            <input type="text" name="transaction_id" placeholder="Mobile Number/Transaction Id" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.transaction_id}  />
                                        </div> */}

                                            <div className="col-md-2">
                                            <label>Service Type</label>
                                            <select className="form-control" name="service_type" onChange={(e) => { this.handleInput(e) }}>
                                                <option value="">All</option>
                                                <option value="remittance_commission">Remittance Commission</option>
                                                <option value="remittance">Remittance</option>
                                                <option value="addmoney">Add Money</option>
                                            </select>
                                        </div>

                                        <div className="col-md-2">
                                            <label>Type</label>
                                            <select className="form-control" name="type" onChange={(e) => { this.handleInput(e) }}>
                                                <option value="">All</option>
                                                <option value="credit">Credit</option>
                                                <option value="debit">Debit</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : moment().format('YYYY-MM-DD')} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.end_date ? this.state.end_date : "1900-01-01"} max={moment().format('YYYY-MM-DD')} id="end_date" />
                                        </div>
                                        <div className="col-md-2">
                                            <label>{" "}</label>
                                            <input type="button" name="submit" className="btn btn-primary searchhistory" onClick={(e) => { this.getRecords(1) }} value="Search" />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive style={{ fontFamily: "sans-serif" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Date</th>
                                                <th>User Name</th>
                                                <th>User Mobile</th>
                                                <th>Type</th>
                                                <th>Filter</th>
                                                <th>Amount</th>

                                                <th>Old Balance</th>
                                                <th>New Balance</th>
                                                <th>Payment Mode</th>
                                                <th>Remarks</th>
                                                <th>UTR No</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.details.map((val, key) => {
                                                // console.log(key, this.state.paginatedata.current_page, this.state.paginatedata.per_page)
                                                return <tr key={key}>
                                                    <td>{moment(val.created_at).format('YYYY-MM-DD h:mm a')}</td>
                                                    <td>{val.user ? val.user.name : ""}</td>
                                                    <td>{val.user ? val.user.mobile : ""}</td>
                                                    <td>{val.type}</td>
                                                    <td>{val.filter}</td>
                                                    <td>&#8377;{(+val.amount).toLocaleString('en', 1)} </td>
                                                    <td>&#8377;{(+val.old_balance).toLocaleString('en', 1)}</td>
                                                    <td>&#8377;{(+val.new_balance).toLocaleString('en', 1)}</td>
                                                    {val.filter === 'addmoney' && <>
                                                    <td>
                                                        {val.addmoney.deposit_via}
                                                    </td>
                                                    </>}
                                                    {val.filter != 'addmoney' && <>
                                                    <td>
                                                        {val.remittance ? val.remittance.PaymentType : ""}
                                                    </td>
                                                    </>}
                                                    <td>{val.description}</td>
                                                    <th>{val.addmoney ? val.addmoney.reciept_number : "N/A"}</th>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Remittancewallethistory;
