import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import { Pagination } from 'react-laravel-paginex'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { CSVLink } from "react-csv";
import SimpleCrypto from "simple-crypto-js";
import env from "variables/constants";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);
const moment = require("moment-timezone");
moment.tz.setDefault("Asia/Kolkata");
var apiUrl = env.url;
var imageUrl = env.img_url;
class Remittancehistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: [],
            options: [],
            allData:[],
            exceldata: [],
            selected_user_id: null,
            start_date: "",
            end_date: "",
            transaction_status: "",
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0,
            },
            icn_no: "",
            search_by: "user",
            refund: false,
            allpermission: [],
        }
    }
    componentDidMount = () => {
        this.getRecords(1);
        this.permissions();
    }
    permissions = () => {

        this.setState({
            allpermission: JSON.parse(sessionStorage.getItem("adminpermission")),
            cuser: Cryp.decrypt(sessionStorage.getItem("cuser")),

        })
        setTimeout(() => {

            this.state.allpermission.map((val, key) => {
                if (val.page_name === "remittance-refound") {
                    this.setState({
                        refund: true
                    })
                }
            })
            //   console.log("truepage",this.state.show_edit);
            console.log("cuser", this.state.cuser);
            console.log("allpermission", this.state.allpermission);

        }, 500);

    }
    getRecords = (page = 1) => {
        var data = {};
        if (this.state.selected_user_id) {
            data.user_id = this.state.selected_user_id;
        }
        if (this.state.icn_no) {
            data.icn_no = this.state.icn_no;
        }
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.transaction_status != "") {
            data.transaction_status = this.state.transaction_status;
        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/admin/remittance/history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response", res)
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.details,
                    details: res.data.details.data,
                    allData:res.data.allData
                })
                setTimeout(() => {
                    var exceldata = [];
                this.state.allData.forEach((val, key) => {
                    console.log(val)
                    var dat = {
                        "Date": val.created_at,
                        "User": val.user ? val.user.name + " (" + val.user.mobile + ")" : "",
                        "Sender": val.sender ? val.sender.FirstName + " (" + val.sender.MobileNo + ")" : "",
                        "Receiver": val.ReceiverName + "(" + val.ReceiverMobileNo + ")",
                        "Filter": val.filter ? val.filter : "addmoney",
                        "Collected Amount INR": (+val.amount).toLocaleString('en', 1),
                        "Send Amount NPR": (+val.PayoutAmount).toLocaleString('en', 1),
                        "Service Charge INR": (+val.service_charge).toLocaleString('en', 1),
                        "Payment Type": val.PaymentType === "B" ? "Bank Deposit" : "Cash Payment",
                        "ICN No.": val.reference_number,
                        "Ref. No.": val.reference_id,
                        "Status": val.status,
                        "admin_comm":val.admin_commission,
                        "user_type":val.user.role,
                    }
                    exceldata.push(dat);
                });
                this.setState({
                    exceldata: exceldata
                })
                }, 200);
                // if (page === 1) {

                //     this.setState({
                //         exceldata: []
                //     })
                //     this.getRecordsExcel();
                // }
            }
            else {
                this.setState({
                    details: [],
                    exceldata: []
                })
            }
            this.setState({ loading: false });
        }, err => {
            this.setState({ loading: false });
        })
    }
    getRecordsExcel = (page = 1) => {
        var data = { forexcel: "yes" };
        if (this.state.selected_user_id) {
            data.user_id = this.state.selected_user_id;
        }
        if (this.state.icn_no) {
            data.icn_no = this.state.icn_no;
        }
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.transaction_status != "") {
            data.transaction_status = this.state.transaction_status;
        }
        Axios.post(`${apiUrl}/admin/remittance/history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response excel", res)
            if (res.data.success) {
                var exceldata = [];
                res.data.details.forEach((val, key) => {
                    console.log(val)
                    var dat = {
                        "Date": val.created_at,
                        "User": val.user ? val.user.name + " (" + val.user.mobile + ")" : "",
                        "Sender": val.sender ? val.sender.FirstName + " (" + val.sender.MobileNo + ")" : "",
                        "Receiver": val.ReceiverName + "(" + val.ReceiverMobileNo + ")",
                        "Filter": val.filter ? val.filter : "addmoney",
                        "Collected Amount INR": (+val.amount).toLocaleString('en', 1),
                        "Send Amount NPR": (+val.PayoutAmount).toLocaleString('en', 1),
                        "Service Charge INR": (+val.service_charge).toLocaleString('en', 1),
                        "Payment Type": val.PaymentType === "B" ? "Bank Deposit" : "Cash Payment",
                        "ICN No.": val.reference_number,
                        "Ref. No.": val.reference_id,
                        "Status": val.status,
                        "admin_comm":val.admin_commission,
                        "user_type":val.user.role,
                    }
                    exceldata.push(dat);
                });
                this.setState({
                    exceldata: exceldata
                })
            }
            else {
                this.setState({
                    details: []
                })
            }
        })
    }
    changePage = data => {
        console.log(data)
        this.getRecords(data.page);
    }
    searchUser = e => {
        if (e.length > 2) {
            var data = { keyword: e };
            Axios.post(apiUrl + "/admin/users/list", data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                console.log(res)
                this.setState({ options: res.data.users, isLoading: false })
            })
        }

    }
    selectUser = selected => {
        console.log(selected)
        this.setState({ selected_user_id: selected.length > 0 ? selected[0].id : null });
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    downloadRemitpdf = (e, id) => {
        e.preventDefault();
        Axios.post(apiUrl + "/remittance/ime-forex/receipt", { remittance_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("pdf ", res)
            if (res.data.success) {
                window.open(res.data.data.pdfurl, "_blank");
            }
        })
    }

    refundwithservicecharge = (e, id) => {
        e.preventDefault();
        Axios.post(apiUrl + "/admin/remittance/refundwithservicecharge", { remittance_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.notify("success", res.data.message);
            this.getRecords(this.state.paginatedata.current_page);
            console.log(res)

        })
    }
    refundwithoutservicecharge = (e, id) => {
        e.preventDefault();
        Axios.post(apiUrl + "/admin/remittance/refundwithoutservicecharge", { remittance_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.notify("success", res.data.message);
            this.getRecords(this.state.paginatedata.current_page);
            console.log(res)
        })
    }
    refundreject = (e, id) => {
        e.preventDefault();
        Axios.post(apiUrl + "/admin/remittance/refundreject", { remittance_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.notify("success", res.data.message);
            this.getRecords(this.state.paginatedata.current_page);
            console.log(res)
        })
    }

    getRemimttanceDetails = (e = null, deta) => {
        if (e) e.preventDefault()
        this.setState({ loading: true })
        var data = {
            reftype: 1,
            icn_number: deta.reference_number
        }
        Axios.post(`${apiUrl}/remittance/ime-forex/trnsaction/inquiry`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.getRecords(this.state.paginatedata.current_page);
                this.setState(res.data.details);
                this.setState({ modalState: true, loading: false })
                console.log("response inquiry", res)
            } else {
                this.setState({ loading: false })
            }
        }, err => {
            this.setState({ loading: false })
        })
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    render() {
        return (
            <>
                {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}

                <div className="react-notification-alert-container">
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">
                                        Remittance History
                                        <small className="float-right">
                                            {this.state.exceldata.length > 0 && <CSVLink data={this.state.exceldata} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"Remittance-details-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i> Excel</CSVLink>}
                                            {this.state.exceldata.length === 0 && <a href="" onClick={(e) => { e.preventDefault() }} style={{ color: "#d4d6d5", background: "none", padding: "10px 8px", borderRadius: "0px", pointerEvents: "none" }} title="Export to CSV"><i className="fa fa-spinner fa-spin"></i>Excel</a>}
                                        </small>
                                    </h3>
                                    <div className="row userhistoryrem">

                                        <div className="col-md-2">
                                            <label>User</label>
                                            <AsyncTypeahead
                                                id="asdasd"
                                                isLoading={this.state.isLoading}
                                                labelKey={option => `${option.name} (${option.mobile})`}
                                                onSearch={(query) => {
                                                    this.setState({ isLoading: true });
                                                    this.searchUser(query);
                                                }}
                                                options={this.state.options}
                                                onChange={selected => { this.selectUser(selected) }}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label>Keyword(ICN/REF/Receiver)</label>
                                            <input type="text" name="icn_no" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.icn_no} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>Status</label>
                                            <select className="form-control" name="transaction_status" onChange={(e) => { this.handleInput(e) }}>
                                                <option value="">All</option>
                                                <option value="pending">Pending</option>
                                                <option value="POST">POST</option>
                                                <option value="transaction_sent">Transaction Sent</option>
                                                <option value="Un-Paid">Un-Paid</option>
                                                <option value="Cancel">Cancel</option>
                                                <option value="CancelRequest">CancelRequest</option>
                                                <option value="UnclaimedFund">UnclaimedFund</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-2">
                                            <label>{" "}</label>
                                            <input type="button" name="submit" className="btn btn-primary searchhistory" onClick={(e) => { this.getRecords(1) }} value="Search" />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive style={{ fontFamily: "sans-serif" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Date</th>
                                                <th>User</th>
                                                <th>Sender</th>
                                                <th>Receiver</th>
                                                <th>Collected Amount</th>
                                                <th>Send Amount</th>
                                                <th>Service Charge</th>
                                                <th>Payment Type</th>
                                                <th>ICN No.</th>
                                                <th>Ref. No.</th>
                                                <th>Bank Id</th>
                                                <th>Bank Branch Id</th>
                                                <th>Bank Account Number</th>

                                                <th>Status</th>
                                                <th>Action</th>
                                                <th>Refund</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.details.map((val, key) => {
                                                // console.log(key, this.state.paginatedata.current_page, this.state.paginatedata.per_page)
                                                return <tr key={key}>
                                                    <td>{moment(val.created_at).format('YYYY-MM-DD h:mm a')}</td>
                                                    <td>{val.user ? val.user.name + " (" + val.user.mobile + ")" : ""}</td>
                                                    <td>{val.sender ? val.sender.FirstName + " (" + val.sender.MobileNo + ")" : ""}</td>
                                                    <td>{val.ReceiverName} ({val.ReceiverMobileNo})</td>
                                                    <td>&#8377;{(+val.amount).toLocaleString('en', 1)}</td>
                                                    <td>NPR {(+val.PayoutAmount).toLocaleString('en', 1)}</td>
                                                    <td>&#8377;{(+val.service_charge).toLocaleString('en', 1)}</td>
                                                    <td>{val.PaymentType === "B" ? "Bank Deposit" : "Cash Payment"}</td>
                                                    <td>{val.reference_number}</td>
                                                    <td>{val.reference_id}</td>
                                                    <td>{val.BankId}</td>
                                                    <td>{val.BankBranchId}</td>
                                                    <td>{val.BankAccountNumber}</td>
                                                    <td>{val.status}</td>
                                                    <td>
                                                        <a href="" onClick={(e) => { this.downloadRemitpdf(e, val.id) }}><i className="fa fa-file-pdf-o" style={{ color: "red" }}></i></a>&nbsp;&nbsp;
                                                        <a href="" onClick={(e) => { this.getRemimttanceDetails(e, val) }}>
                                                            <i className="fa fa-refresh" style={{ color: "red" }}></i>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {this.state.refund && <>
                                                            {val.status === 'Cancel' && val.refund_status !== 'refunded' && val.refund_status !== 'reject' && <>
                                                                <a href="" onClick={(e) => {if(window.confirm('Are You Sure?')){this.refundwithservicecharge(e, val.id)};}} title="With Service Charge Refund" ><i className="fa fa-exchange" aria-hidden="true"></i></a>&nbsp;&nbsp;

                                                                <a href="" title="Without Service Charge Refund"  onClick={(e) => {if(window.confirm('Are You Sure?')){this.refundwithoutservicecharge(e, val.id)};}} >
                                                                    <i className="fa fa-exchange" aria-hidden="true"></i>
                                                                </a>
                                                                &nbsp;&nbsp;
                                                                <a href="" onClick={(e) => {if(window.confirm('Are You Sure?')){this.refundreject(e, val.id)};}}  >
                                                                    <i className="tim-icons icon-simple-remove" style={{ color: "red" }} title="Refund reject"></i>

                                                                </a>
                                                            </>
                                                            }
                                                        </>}
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Remittancehistory;
