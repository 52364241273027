import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
import { Pagination } from 'react-laravel-paginex'
import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);


var cu = sessionStorage.getItem('cuser') ? Cryp.decrypt(sessionStorage.getItem('cuser')) : null;
const userids = [1];
const moment = require('moment');
var apiUrl = env.url;
var imageUrl = env.img_url;
class SathiList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            modal: false,
            selected_record: null,
            selected_user_id: null,
            start_date: "",
            end_date: "",
            comment:"",
            reason: "",
            selected_id: "",
            keyword: "",
            sort_by: "all",
            rejectModal: false,
            imageModal: false,
            headers: {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access"),
                    Accept: "application/json"
                }
            },
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            },

        }
    }
    componentDidMount = () => {
        let search = this.props.history.location.search;
        let params = new URLSearchParams(search);
        let user_id = params.get('q');
        if (user_id) {
            this.setState({
                selected_user_id: +user_id
            })
        }
        setTimeout(() => {
            this.getList();
        }, 100);

    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    getList = (page = 1, keyword) => {
        var data = { keyword: keyword ? keyword : this.state.keyword, sort_by: this.state.sort_by };
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.selected_user_id) {
            data.selected_user_id = this.state.selected_user_id;
        }
        Axios.post(apiUrl + "/admin/new-request/sathi/list?page=" + page, data, this.state.headers).then(res => {
            this.setState({
                list: res.data.list.data,
                paginatedata: res.data.list,
                selected_record: res.data.single,
                modal: res.data.single !== null
            });
        })
    }


    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    viewImage = (e, image) => {
        if (e) e.preventDefault();
        this.setState({
            selected_image: imageUrl + image,
            imageModal: true
        })
    }
     handleError = (event) => {
        this.setState({ photo: "/uploads/static/anime3.png" });
    }
    toggleImageModal = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            imageModal: !this.state.imageModal,
        })
    }
    toggle = (e, usr) => {
        if (e) e.preventDefault();
        console.log("usseerrrrrrrrrr", usr)
        this.setState({
            modal: !this.state.modal,
            selected_record: usr
        })
    }
    rejectMember = e => {
        if (e) e.preventDefault();
        var data = {
            kyc_id: this.state.selected_record.id,
            comment:this.state.comment
        }
        Axios.post(apiUrl + "/admin/sathi/reject", data, this.state.headers).then(res => {
            console.log(res)
            this.notify(res.data.success ? "success" : "error", res.data.message);
            this.toggle();
            this.getList();
            // if (this.state.selected_user_id) {
            //     setTimeout(() => {
            //         window.location.href = "/admin/all-users"
            //     }, 2000);
            // }
        })
    }

    approveMember = e => {
        if (e) e.preventDefault();
        var data = {
            kyc_id: this.state.selected_record.id,
            comment:this.state.comment
        }
        Axios.post(apiUrl + "/admin/sathi/approve", data, this.state.headers).then(res => {
            this.notify(res.data.success ? "success" : "error", res.data.message);
            this.toggle();
            this.getList();
            // if (this.state.selected_user_id) {
            //     setTimeout(() => {
            //         window.location.href = "/admin/all-users"
            //     }, 2000);
            // }
        })
    }
    searchKyc = (e) => {
        var key = e.target.value
        this.setState({
            keyword: e.target.value
        })
        if (key.length > 2 || key === "") {
            this.getList(1, key);
        }
    }
    changePage = (page) => {
        console.log(page)
        this.getList(page.page)
    }


    render() {
        return (
            <>

                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <div className="col-md-3">
                                        <CardTitle tag="h4">Sathi Onboarding List</CardTitle>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="searchbox searchbox_nar">
                                                <input type="text" name="keyword" className="form-control" onChange={(e) => { this.searchKyc(e) }} value={this.state.keyword} placeholder="Search keyword" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Filter By</label>
                                            <select name="sort_by" className="form-control" onChange={e => { this.handleInput(e) }} value={this.state.sort_by}>
                                                <option value="">all</option>
                                                {/* <option value="users">Registered Users</option> */}
                                                {/* <option value="others">Not registered</option> */}
                                                <option value="pending">Approval Pending</option>
                                                <option value="approved">Approved</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" className="btn btn-primary mt-4" onClick={e => this.getList()}><i className="fa fa-search"></i> Search</button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Name</th>
                                                <th>Mobile No.</th>
                                                <th>gender</th>
                                                <th>Status</th>
                                                <th>Comment</th>
                                                <th>Address</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.list.map((usr, k) => {
                                                return <tr>
                                                    <td>{this.state.paginatedata.per_page * (this.state.paginatedata.current_page - 1) + k + 1}. </td>
                                                    <td>{moment(usr.created_at).format('DD-MM-YYYY h:mm a')}</td>
                                                    <td>{usr.name}</td>
                                                    <td>{usr.mobile}</td>
                                                    <td>{usr.gender}</td>
                                                    <td>{usr.status}</td>
                                                    <td>{usr.comment}</td>
                                                    <td>{usr.address}</td>
                                                    {/* <td>{usr.photo && <a href="" onClick={e => { this.viewImage(e, usr.photo) }}><img src={imageUrl + usr.photo} alt="" /></a>}</td> */}
                                                    {/* <td>{usr.proof && <a href="" onClick={e => { this.viewImage(e, usr.proof) }}><img src={imageUrl + usr.proof} alt="" /></a>}</td> */}
                                                    <td>
                                                        <a href="" onClick={e => { this.toggle(e, usr) }}><i className="fa fa-eye" /></a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">

                        <div style={{display: "flex", justifyContent: "space-between", placeItems: "center"}}>
                            <div>
                                <ModalHeader className="reject-popup" toggle={this.toggle}>Sathi details
                                    {this.state.selected_record && this.state.selected_record.status !== "pending" && <span className="badge badge-primary ml-2">{this.state.selected_record.status.toUpperCase()}</span>}
                                </ModalHeader>
                            </div>
                            <div className="block-acccount-button-div">
                                <button className="block-acccount-button">Block Account</button>
                            </div>
                        </div>

                        <ModalBody>
                            <div className="view-popup">

                                        {this.state.selected_record && <Row className="startedformsec" >


                                            <div className="saathi-first-container-main-pop-up">
                                                <div className="saathi-first-container-semi-main-pop-up">
                                                    <div className="saathi-first-container-content-main-pop-up">
                                                        
                                                        <div className="saathi-first-container-content-first-div">
                                                            <div>
                                                              <img alt="..." onError={this.handleError} src={imageUrl + this.state.selected_record.photo} style={{width: "65px", borderRadius: "15px"}}/>
                                                            </div>
                                                            <div>
                                                                <ul className="first-container-content-ul-div">
                                                                    <li ><p className="first-container-content-ul-p">{this.state.selected_record.name}</p></li>
                                                                    <li><p> Referal Code: <span className="first-container-content-ul-p">{this.state.selected_record.referal_code ? this.state.selected_record.referal_code : "N/A"}</span>  </p></li>
                                                                    <li><p> Joining Date: <span className="first-container-content-ul-p">{moment(this.state.selected_record.created_at).format('lll')}</span>  </p></li>
                                                                    <li><p> Payment Status: <span className="first-container-content-ul-p">{this.state.selected_record.payment_status ? this.state.selected_record.payment_status : "N/A"}</span>  </p></li>
                                                                    <li><p className="first-container-content-ul-p">Status: <span style={{color: "#00945D", fontWeight: "600"}}>{this.state.selected_record.status}</span></p></li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                            <label>Email:</label>
                                                                            <h4><b>{this.state.selected_record.email ? this.state.selected_record.email : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Phone Number</label>
                                                                        <h4><b>{this.state.selected_record.mobile ? this.state.selected_record.mobile : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label> Wallet Balance</label>
                                                                        <h4><b> {this.state.selected_record.wallet_balance ? this.state.selected_record.wallet_balance : "N/A"} </b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>DOB</label>
                                                                        <h4><b>{this.state.selected_record.dob ? moment(this.state.selected_record.dob).format('DD-MMM-YYYY') : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Gender</label>
                                                                        <h4><b>{this.state.selected_record.gender ? this.state.selected_record.gender : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Remittance Balance</label>
                                                                        <h4><b>{this.state.selected_record.remit_balance ? this.state.selected_record.remit_balance : "N/A"} </b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Reference</label>
                                                                        <h4><b>{this.state.selected_record.referer ? this.state.selected_record.referer : "Self"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>CSP Code</label>
                                                                        <h4><b>{this.state.selected_record.csp_code ? this.state.selected_record.csp_code : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>account Number</label>
                                                                        <h4><b>{this.state.selected_record.account_number ? this.state.selected_record.account_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="second-container-main-pop-up">
                                                <div className="first-container-semi-main-pop-up">
                                                    <div className="first-container-content-main-pop-up">

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Application Number</label>
                                                                        <h4><b>{this.state.selected_record.application_number ? this.state.selected_record.application_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Mobile</label>
                                                                        <h4><b>{this.state.selected_record.mobile ? this.state.selected_record.mobile : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Amount</label>
                                                                        <h4><b>  {this.state.selected_record.amount ? this.state.selected_record.amount : "N/A"} </b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Receipt Number</label>
                                                                        <h4><b>{this.state.selected_record.receipt_number ? this.state.selected_record.receipt_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Payment id</label>
                                                                        <h4><b>{this.state.selected_record.payment_id ? this.state.selected_record.payment_id : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Monthly Income</label>
                                                                        <h4><b>{this.state.selected_record.monthly_income ? this.state.selected_record.monthly_income : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Membership ID</label>
                                                                        <h4><b>{this.state.selected_record.membership_id ? this.state.selected_record.membership_id : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                       <label>Marital Status</label>
                                                                       <h4><b>{this.state.selected_record.marital_status ? this.state.selected_record.marital_status : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                      <label>Transaction ID</label>
                                                                      <h4><b>{this.state.selected_record.transaction_id ? this.state.selected_record.transaction_id : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>    
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                            {this.state.selected_record.nationality != 'Nepal' && <>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <label>Aadhar Number:</label>
                                                                        <h4><b>{this.state.selected_record.aadhar_number ? this.state.selected_record.aadhar_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <label>Pan Number:</label>
                                                                        <h4><b>{this.state.selected_record.pan_number ? this.state.selected_record.pan_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col></>}
                                                            </div>    

                                                                {this.state.selected_record.nationality == 'Nepal' && <>
                                                                <div className="first-container-content-child-div">   
                                                                <Col>
                                                                    <FormGroup>
                                                                        <label>Citizen Number:</label>
                                                                        <h4><b>{this.state.selected_record.citizen_number ? this.state.selected_record.citizen_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                </div>

                                                                <div className="first-container-content-child-div">
                                                                <Col>
                                                                    <FormGroup>
                                                                        <label>Passport:</label>
                                                                        <h4><b>{this.state.selected_record.passport ? this.state.selected_record.passport : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                </div>
                                                                </>}

                                                            
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                      <label>Occupation</label>
                                                                      <h4><b>{this.state.selected_record.occupation ? this.state.selected_record.occupation : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>



                                            {/* <div className="saathi-second-container-main-pop-up">
                                                <div className="saathi-first-container-semi-main-pop-up">
                                                    <div className="saathi-second-container-content-main-pop-up">

                                                        <div className="saathi-second-container-content-div-comman">
                                                            <div className="saathi-second-container-content-child-div">
                                                                <Col>
                                                                    <FormGroup>
                                                                        <label><b className="saathi-application-text">Shop Details</b></label>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="saathi-second-container-content-div-comman">
                                                            <div className="saathi-second-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <div style={{lineHeight: "20px"}}>
                                                                            <label>Shop Name</label>
                                                                            <h4><b>  </b></h4>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="saathi-second-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                       <div style={{lineHeight: "20px"}}>
                                                                            <label>Shop Address</label>
                                                                            <h4><b> </b></h4>
                                                                       </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="saathi-second-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <div style={{lineHeight: "20px"}}>
                                                                            <label>Shop District</label>
                                                                            <h4><b>{this.state.selected_record.p_district}</b></h4>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="saathi-second-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                       <div style={{lineHeight: "20px"}}>
                                                                            <label>Shop State</label>
                                                                            <h4><b>{this.state.selected_record.p_state}</b></h4>
                                                                       </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="saathi-second-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                       <div style={{lineHeight: "20px"}}>
                                                                            <label>Shop Country</label>
                                                                            <h4><b>{this.state.selected_record.country}</b></h4>
                                                                       </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> */}


                                            <div className="second-container-main-pop-up">
                                                <div className="first-container-semi-main-pop-up">
                                                    <div className="first-container-content-main-pop-up">

                                                        <div className="first-container-content-div-comman">
                                                            <div className="third-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label id="parmanentAddr">Permanent Address</label>
                                                                        <h4>{this.state.selected_record.house_number ? this.state.selected_record.house_number : "N/A"}</h4>
                                                                    </FormGroup>  
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>City</label>
                                                                        <h4><b>{this.state.selected_record.city ? this.state.selected_record.city : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>District</label>
                                                                        <h4><b>{this.state.selected_record.p_district ? this.state.selected_record.p_district : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>State</label>
                                                                        <h4><b>{this.state.selected_record.p_state ? this.state.selected_record.p_state : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Country</label>
                                                                        <h4><b>{this.state.selected_record.p_country ? this.state.selected_record.p_country : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Pin Code</label>
                                                                        <h4><b>{this.state.selected_record.p_pin_code ? this.state.selected_record.p_pin_code : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                          
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                          <div style={{display: "flex", gap: "2rem"}}>
                                                            <div>
                                                                <hr style={{width: "1px", height: "318px", backgroundColor: "grey"}} />
                                                            </div>
                                                            <div>
                                                            <div className="third-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Current Address</label>
                                                                        <h4><b>{this.state.selected_record.house_number ? this.state.selected_record.house_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>City</label>
                                                                        <h4><b>{this.state.selected_record.city ? this.state.selected_record.city.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>District</label>
                                                                        <h4><b>{this.state.selected_record.district ? this.state.selected_record.district.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>State</label>
                                                                        <h4><b>{this.state.selected_record.state ? this.state.selected_record.state.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Country</label>
                                                                        <h4><b>{this.state.selected_record.country ? this.state.selected_record.country.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Pin Code</label>
                                                                        <h4><b>{this.state.selected_record.pincode ? this.state.selected_record.pincode.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            </div>

                                                          </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-aadhaar-div">

                                                                {this.state.nationality != "Nepal" &&
                                                                <>
                                                                <Col>
                                                                    <div className="userimg">
                                                                        <label id="userimg-label">Identity Front (Aadhar Card) </label>
                                                                        <img alt="..." onError={this.handleError} src={imageUrl + this.state.selected_record.proof} />
                                                                    </div>
                                                                </Col>

                                                                <Col>
                                                                    <div className="userimg">
                                                                        <label id="userimg-label">Identity Back (Aadhar Card)</label>
                                                                        <img alt="..." onError={this.handleError} src={imageUrl + this.state.selected_record.aadhar_back} />
                                                                    </div>
                                                                </Col>
                                                                </>
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>                                              
                                            </div> 


                                            <div className="saathi-first-container-main-pop-up" id="purchase_category_div">
                                                <div className="saathi-first-container-semi-main-pop-up">
                                                    <div className="saathi-third-container-content-main-pop-up">

                                                        <div className="saathi-third-container-content-purchase-category">
                                                            <b style={ {fontWeight: "600", fontSize: "17px", color: "#000000"}}>Purchase Category</b>
                                                        </div>

                                                    <Table className="table-div">
                                                        <div className="saathi-third-container-ul-div">
                                                            <div>
                                                                <tr className="saathi-third-container-ul">
                                                                    <th>#</th>
                                                                    <th>Name</th>
                                                                    <th>Services Offered</th>
                                                                    <th>Amount</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </div>
                                                        </div>

                                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>

                                                            <div className="saathi-third-container-ul-common-div">
                                                                <div>
                                                                    {this.state.selected_record.category.map((usr, k)  => {
                                                                        return <tr className="saathi-third-container-ul-common">
                                                                            <td className="saathi-third-container-td-count">{this.state.paginatedata.per_page * (this.state.paginatedata.current_page - 1) + k + 1}. </td>
                                                                            <td className="saathi-third-container-td-name">{usr.name}</td>
                                                                            <td>{usr.Services}</td>
                                                                            <td>{usr.amount}</td>
                                                                            <td>{usr.status}</td>
                                                                            <td>{usr.action}</td>
                                                                        </tr>
                                                                    })}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </Table>

                                                    </div>
                                                </div>

                                                <div className="container-comment-input-main-div">
                                                    <div className="container-comment-input-div">
                                                        <label className="container-comment-input"><b>Comment</b></label>
                                                        <textarea className="container-comment-input-box" name="comment" onChange={e => { this.handleInput(e) }} value={this.state.comment}></textarea>
                                                    </div>
                                                </div>

                                            </div>

                                    </Row>

                                }

                            </div>
                        </ModalBody>


                        <ModalFooter>
                            {this.state.selected_record && this.state.selected_record.status === "pending" && <Row>
                                <a href="" onClick={e => { this.rejectMember(e) }} className="btn btn-danger ml-2">Reject</a>
                                <a href="" onClick={e => { this.approveMember(e) }} className="btn btn-primary mr-2">Approve</a>
                            </Row>}

                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.imageModal} toggle={this.toggleImageModal} size="lg">
                        <ModalHeader toggle={this.toggleImageModal}>Document</ModalHeader>
                        <ModalBody>
                            <img src={this.state.selected_image} alt="" />
                        </ModalBody>
                    </Modal>
                </div>
            </>
        );
    }
}

export default SathiList;
