import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import "../../assets/css/style.css";
class Becomeanagent extends React.Component {


    render() {
        return <>
            <div className="site_home">
                <Header banner="become-an-agent" h1Text="Become An Agent" bannerclassName="inner_head agenthead" />


                <section className="contact_us">
                    <div className="container">
                        <h2>Become An Agent</h2>
                        <div className="contact_form">
                            <form id="agent_form">
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Name*</label>
                                        <input type="text" className="form-control" name="name" placeholder="Name" required="required" autocomplete="on" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Name of Organization*</label>
                                        <input type="text" className="form-control" name="name_of_organization" placeholder="Name of Organization" required="required" autocomplete="on" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Select State*</label>
                                        <select className="state_select_box form-control" name="state">
                                            <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                            <option value="Assam">Assam</option>
                                            <option value="Bihar">Bihar</option>
                                            <option value="Chandigarh">Chandigarh</option>
                                            <option value="Chhattisgarh">Chhattisgarh</option>
                                            <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                                            <option value="Delhi">Delhi</option>
                                            <option value="Goa">Goa</option>
                                            <option value="Gujarat">Gujarat</option>
                                            <option value="Haryana">Haryana</option>
                                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                                            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                            <option value="Jharkhand">Jharkhand</option>
                                            <option value="Karnataka">Karnataka</option>
                                            <option value="Karnatka">Karnatka</option>
                                            <option value="Kerala">Kerala</option>
                                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                                            <option value="Maharashtra">Maharashtra</option>
                                            <option value="Manipur">Manipur</option>
                                            <option value="Meghalaya">Meghalaya</option>
                                            <option value="Mizoram">Mizoram</option>
                                            <option value="Nagaland">Nagaland</option>
                                            <option value="Odisha">Odisha</option>
                                            <option value="Puducherry">Puducherry</option>
                                            <option value="Punjab">Punjab</option>
                                            <option value="Rajasthan">Rajasthan</option>
                                            <option value="Tamil Nadu">Tamil Nadu</option>
                                            <option value="Telangana">Telangana</option>
                                            <option value="Tripura">Tripura</option>
                                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                                            <option value="Uttarakhand">Uttarakhand</option>
                                            <option value="West Bengal">West Bengal</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Select City*</label>
                                        <select className="city_select_box form-control" name="city">
                                            <option value="Chandigarh*">Chandigarh*</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Email*</label>
                                        <input type="email" className="form-control" name="email" placeholder="Email" required="required" autocomplete="on" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Contact Number*</label>
                                        <input type="number" className="form-control" name="contact_number" placeholder="Contact Number" required="required" min="0" autocomplete="on" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group ">
                                        <label>Note</label>
                                        <textarea className="form-control rounded-0" id="message" name="message" rows="3" cols="120" placeholder="Message"></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group justify-content-center">
                                        <input type="submit" className="btn_form" value="Join Us" id="send" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>;
    }
}

export default Becomeanagent;