import React, { useState } from 'react';

const Sidenav = () => {
    const [isactive, setActive] = useState('1');
    const handleChange =(number)=>{
        setActive(number);
    }
    return (
        <div className="bg-white sidebar">
            <div className="">
                <ul className="nav flex-column">
                    <li className="nav-item btns bg-light p-1">
                        <a className="nav-item active" >
                            Navigation
                </a>
                    </li>
                    <li className={isactive==='1'? "sidenavbtn nav-item active p-1":"nav-item"} onClick={()=>handleChange('1')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="/user/saathidashboard"> Dashboard</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>
                    <hr></hr>
                    <li className={isactive==='2'? "sidenavbtn nav-item active":"nav-item"} onClick={()=>handleChange('2')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="#"> Report</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>
                    <hr></hr>
                    <li className={isactive==='3'? "sidenavbtn nav-item active":"nav-item"} onClick={()=>handleChange('3')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="#"> Member</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>                
                        <hr></hr>
                    <li className={isactive==='4'? "sidenavbtn nav-item active":"nav-item"} onClick={()=>handleChange('4')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="#"> User KYC</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>
                    <hr></hr>
                    <li className={isactive==='5'? "sidenavbtn nav-item active":"nav-item"} onClick={()=>handleChange('5')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="#"> Member Request</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>
                    <hr></hr>
                    <li className={isactive==='6'? "sidenavbtn nav-item active":"nav-item"} onClick={()=>handleChange('6')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="#"> Comission</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>
                    <hr></hr>
                    <li className={isactive==='7'? "sidenavbtn nav-item active":"nav-item"} onClick={()=>handleChange('7')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="#"> AEPS</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>
                    <hr></hr>
                    <li className={isactive==='8'? "sidenavbtn nav-item active":"nav-item"} onClick={()=>handleChange('8')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="#"> API Management</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>
                    <hr></hr>
                    <li className={isactive==='9'? "sidenavbtn nav-item active":"nav-item"} onClick={()=>handleChange('9')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="#"> Wallet</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>
                    <hr></hr>
                    <li className={isactive==='10'? "sidenavbtn nav-item active":"nav-item"} onClick={()=>handleChange('10')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="#"> Support</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>
                    <hr></hr>
                    <li className={isactive==='11'? "sidenavbtn nav-item active":"nav-item"} onClick={()=>handleChange('11')}>
                        <div className='row'>
                            <div className='col'>
                                <a className="nav-link" href="#"> More</a>
                            </div>
                            <div className='col d-flex justify-content-center mt-2'>
                                <span>+</span>
                            </div>
                        </div>
                    </li>
                   </ul>
            </div>
        </div>
    )
}

export default Sidenav;
