import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import Axios from "axios";
import { CSVLink } from "react-csv";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);


var cu = sessionStorage.getItem('cuser') ? Cryp.decrypt(sessionStorage.getItem('cuser')) : null;
const userids = [1];
const moment = require('moment');
var apiUrl = env.url;
var imageUrl = env.img_url;
class Addmoney extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            start_date: "",
            end_date: "",
            keyword: "",
            receipt: "",
            modal: false,
            exceldata: [],
            selected_record: null,
            reason: "",
            selected_id: "",
            rejectModal: false,
            imageModal: false
        }
    }
    componentDidMount = () => {
        this.getList();
    }
    toggleM = (e, val) => {
        e.preventDefault();
        this.setState({
            modal: true,
            selected_record: val
        })
    }

    toggle = (e, val) => {
        e.preventDefault();
        this.setState({
            modal: !this.state.modal,
            selected_id: val
        })
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    getList = () => {
        var data = {};
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.keyword && this.state.keyword !== "") {
            data.keyword = this.state.keyword;
        }
        if (this.state.receipt && this.state.receipt !== "") {
            data.receipt = this.state.receipt;
        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/admin/add/money/list`, data, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
        }).then(res => {
            if (res.data.success) {
                this.getListForExcel();
                this.setState({
                    list: res.data.data.list,
                })
            }
            this.setState({ loading: false })
        }, err => {
            console.log("addmoney error", err)
            this.setState({ loading: false })
        })
    }

    getListForExcel = () => {
        //   var data = {};
        var data = { forexcel: "yes" };
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.keyword && this.state.keyword !== "") {
            data.keyword = this.state.keyword;
        }
        if (this.state.receipt && this.state.receipt !== "") {
            data.receipt = this.state.receipt;
        }
        Axios.post(`${apiUrl}/admin/add/money/list`, data, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
        }).then(res => {
            console.log("excel data response", res)
            if (res.data.success) {
                var exceldata = [];
                res.data.data.list.forEach((val, key) => {
                    var dat = {
                        "Date": moment(val.created_at).format('DD-MMM-YYYY h:mm a'),
                        "Deposit Date": moment(val.deposit_date).format('DD-MMM-YYYY'),
                        "Name": val.user.name,
                        "Mobile": val.user.mobile,
                        "Amount": val.amount,
                        "Deposit Via": val.deposit_via,
                        "Reciept No.": val.reciept_number,
                        "Bank Name": val.bank_name,
                        "Description": val.description,
                        "Status": val.status,
                        "Checked By": val.checked ? val.checked.name : "",
                        "Approved By": val.approve ? val.approve.name : "",

                    }
                    exceldata.push(dat);
                });
                this.setState({
                    exceldata: exceldata,
                })
            }
        }, err => {
            console.log("addmoney error", err)
        })
    }
    approveRequest = (e, id) => {
        e.preventDefault();
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/admin/approve/money/request`, { req_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            if (res.data.success) {
                this.getList();
                this.notify("success", res.data.message);
            } else {
                this.notify("error", res.data.message);
            }
            this.setState({ loading: false })
        }, err => {
            console.log("approve error", err)
            this.setState({ loading: false })
        })
    }
    checkedRequest = (e, id) => {
        e.preventDefault();
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/admin/checked/money/request`, { req_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            if (res.data.success) {
                this.getList();
                this.notify("success", res.data.message);
            } else {
                this.notify("error", res.data.message);
            }
            this.setState({ loading: false })
        }, err => {
            console.log("approve error", err)
            this.setState({ loading: false })
        })
    }
    rejectRequest = (e) => {
        if (this.state.reason === "") {
            this.notify("error", "Please enter reason.");
            return false;
        }
        this.setState({ loading: true })
        Axios.post(apiUrl + "/admin/reject/add/money/request", {
            request_id: this.state.selected_id,
            reason: this.state.reason
        }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            this.getList();
            if (type === "success") {
                this.setState({
                    reason: "",
                    selected_id: "",
                    modal: false
                })
            }
            this.notify(type, res.data.message);
            this.setState({ loading: false })
        }, err => {
            this.setState({ loading: false })

        })
    }

    toggleRejectModal = () => {
        this.setState({
            rejectModal: !this.state.rejectModal
        })
    }

    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    viewImage = (e, image) => {
        e.preventDefault();
        this.setState({
            selected_image: imageUrl + image,
            imageModal: true
        })
    }
    toggleImageModal = () => {
        this.setState({
            imageModal: !this.state.imageModal
        })
    }
    render() {
        return (
            <>
                {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Add Money Requests
                                        <small className="float-right">
                                            {this.state.exceldata.length > 0 && <CSVLink data={this.state.exceldata} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"Add-money-list" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}
                                            {this.state.exceldata.length === 0 && <a href="" onClick={(e) => { e.preventDefault() }} style={{ color: "#d4d6d5", background: "none", padding: "10px 8px", borderRadius: "0px", pointerEvents: "none" }} title="Export to CSV"><i className="fa fa-spinner fa-spin"></i>Excel</a>}
                                        </small>
                                    </CardTitle>
                                    <div className="row userhistoryrem">
                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Keyword (Name / Mobile No.)</label>
                                            <input type="text" name="keyword" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.keyword} id="keyword" />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Receipt No.</label>
                                            <input type="text" name="receipt" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.receipt} id="receipt" />
                                        </div>
                                        <div className="col-md-2">
                                            <label>{" "}</label>
                                            <input type="button" name="submit" className="btn btn-primary searchhistory" onClick={(e) => { this.getList() }} value="Search" />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Date</th>
                                                <th>Deposit Date</th>
                                                <th>Name</th>
                                                <th>Mobile No.</th>
                                                <th>Amount</th>
                                                <th>Deposit Via</th>
                                                <th>Image</th>
                                                <th>Reciept No.</th>
                                                <th>Bank Name</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>Checked by</th>
                                                <th>Approved by</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.list.map((val, key) => {
                                                return <tr>
                                                    <td>{moment(val.created_at).format('DD-MM-YYYY h:mm a')}</td>

                                                    <td>{moment(val.deposit_date).format('DD-MM-YYYY')}</td>

                                                    <td>{val.user.name}</td>
                                                    <td>{val.user.mobile}</td>
                                                    <td>&#8377;{val.amount}</td>
                                                    <td>{val.deposit_via}</td>
                                                    <td>
                                                        {val.image && <a href="" onClick={(e) => { this.viewImage(e, val.image) }}><img src={imageUrl + val.image} alt=" " style={{ width: "50px" }} /></a>}
                                                    </td>
                                                    <td>{val.reciept_number}</td>
                                                    <td>{val.bank_name}</td>
                                                    <td>{val.description}</td>
                                                    <td>{val.status}</td>
                                                    <td>{val.checked ? val.checked.name : ""}</td>
                                                    <td>{val.approved ? val.approved.name : ""}</td>
                                                    <td>
                                                        {userids.includes(cu.id) &&
                                                            val.status === "checked" &&
                                                            <a href="" onClick={(e) => { this.approveRequest(e, val.id) }} title="Approve request" > <i className="tim-icons icon-check-2"></i></a>

                                                        }
                                                        &nbsp;&nbsp;
                                                        {
                                                            val.status === "pending" &&
                                                            <a href="" onClick={(e) => { this.checkedRequest(e, val.id) }} title="Check request" > <i className="tim-icons icon-check-2"></i></a>

                                                        }
                                                        {/* { !userids.includes(cu.id) && 
                                                        
                                                        } */}
                                                        &nbsp;&nbsp;
                                                        <a href="" onClick={(e) => { this.toggle(e, val.id) }} > <i className="tim-icons icon-simple-remove"></i></a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={this.state.modal} toggle={this.toggle}>
                        <ModalHeader className="reject-popup" toggle={this.toggle}>Reject Reason</ModalHeader>
                        <ModalBody>
                            <textarea name="reason" onChange={(e) => { this.handleInput(e) }} value={this.state.reason} className="form-control"></textarea>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="primary" onClick={() => { this.rejectRequest() }}>Submit</Button>{' '}
                            {/* <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.imageModal} toggle={this.toggleImageModal} size="lg">
                        <ModalHeader toggle={this.toggleImageModal}>Receipt</ModalHeader>
                        <ModalBody>
                            <img src={this.state.selected_image} alt="" />
                        </ModalBody>
                    </Modal>
                </div>
            </>
        );
    }
}

export default Addmoney;
