import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Axios from "axios";
import { Pagination } from 'react-laravel-paginex'
import { AsyncTypeahead, TypeaheadInputSingle } from 'react-bootstrap-typeahead';
import { CSVLink } from "react-csv";
import SimpleCrypto from "simple-crypto-js";
import env from "variables/constants";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);
const moment = require("moment");
const url = window.location.href;
const $ = window.jQuery;
var apiUrl = env.url;
var imageUrl = env.img_url;
class IndianRechargeHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view:false,
            edit:false,
            delete:false,
            export:false,
            details: [],
            options: [],
            exceldata: [],
            selected_user_id: null,
            start_date: "",
            end_date: "",
            type: "",
            categories:[],
            status:"",
            category:"",
            payment_mode:"",
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            },
            short:10,
            site_title:"Taka24 Order History",
            show_search:false,
            search_value:"",
            viwpaymentdetails:false,
            credit_card:0,
            net_banking:0,
            upi:0,
            other_wallet:0,
            debit_card:0,
            debit_card_1:0,
            wallet_balance:0,

        }
    }
    componentDidMount = () => {
        document.title = this.state.site_title

        if(sessionStorage.getItem("order_filter")){
            this.setState({
                filter: JSON.parse(sessionStorage.getItem("order_filter")),
            })
            setTimeout(() => {
                 
                this.state.filter.map((val, key) => {
                   
                            this.setState({
                                selected_user_id:val.selected_user_id,
                                start_date:val.start_date,
                                end_date:val.end_date,
                               
                                category: val.category,
                                search_value:val.search_value,
                                status:val.status,
                                payment_mode:val.payment_mode,
                                short: val.short,
                                show_search:true
                            })
                    })
                    this.getRecords(1);
                    this.getAllCategories();
                    this.permissions()
                }, 500);    
        }else{

            this.setState({
                show_search: true,
            },() => { 
                this.getRecords(1);
                this.getAllCategories();
                this.permissions()
               
             })      
    }
 }
 
    permissions = () => {

        // this.setState({
        //     allpermission: JSON.parse(sessionStorage.getItem("adminpermission")),
        //     cuser: Cryp.decrypt(sessionStorage.getItem("cuser")),
  
        // })
        // setTimeout(() => {
  
        //     this.state.allpermission.map((val, key) => {
        //         if (val.page_name === "/rechargehistory") {
        //                if(val.view == 'yes'){
        //                 this.setState({
        //                     view:true,
        //                 })
        //                }
        //                if(val.edit == 'yes'){
        //                 this.setState({
        //                     edit:true, 
        //                 })
        //                }
        //                if(val.delete == 'yes'){
        //                 this.setState({
        //                     delete:true,
        //                 })
        //                }
        //                if(val.export == 'yes'){
        //                 this.setState({
        //                     export:true,
        //                 })
        //                }
        //         }
                
                
        //     })
        //     //   console.log("truepage",this.state.show_edit);
        //   //  console.log("cuser", this.state.cuser);
        //     console.log("allpermission", this.state.allpermission);
  
        // }, 500);
  
    }
    handleInputshort = e => {
        // this.setState({
        //     [e.target.name]: e.target.value
        // });
       // sessionStorage.setItem("recharge_short", e.target.value);
        this.setState({ short: e.target.value }, () => { this.getRecords(1) });
        
    }

    viwpaymentDetails = (e, user) => {
        // e.preventDefault();
        // this.setState({
        //     credit_card:user.credit_card,
        //     net_banking:user.net_banking,
        //     upi:user.upi,
        //     other_wallet:user.other_wallet,
        //     debit_card:user.debit_card,
        //     debit_card_1:user.debit_card_1,
        //     wallet_balance:user.wallet_balance,
           
        // },() => { 
        //     this.togglebankdetails();
           
        //  });
      
       
        
     //   console.log(user);
        
    }
    togglebankdetails = () => {
        this.setState({
            viwpaymentdetails: !this.state.viwpaymentdetails
        });
    }
   getAllCategories = () => {
    Axios.get(`${apiUrl}/admin/all/categories`, {
       headers: {
          Authorization: "Bearer " + sessionStorage.getItem("_access")
       }
    }).then(res => {
       console.log("categories response", res)
       if (res.data.success) {
          this.setState({
             categories: res.data.categories
          })
       }
       else {
          this.setState({
             categories: []
          })
       }
    })
 }
    getRecords = (page = 1) => {
        var data = {};
        if (this.state.selected_user_id) {
            data.user_id = this.state.selected_user_id;
        }
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.status && this.state.status != "") {
            data.status = this.state.status;
        }
        if (this.state.category && this.state.category != "") {
            data.category = this.state.category;
        }
        if (this.state.payment_mode && this.state.payment_mode != "") {
            data.payment_mode = this.state.payment_mode;
        }
        
        data.short = this.state.short;

        var filter =[{
            selected_user_id: this.state.selected_user_id,
            start_date: this.state.start_date,
            end_date:  this.state.end_date,
            status:this.state.status,
            category: this.state.category,
            payment_mode: this.state.payment_mode,
            search_value:this.state.search_value,
            short:this.state.short
        }];
 
      sessionStorage.setItem("order_filter", JSON.stringify(filter));
        Axios.post(`${apiUrl}/admin/indian-recharge-history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response", res)
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.data,
                    details: res.data.data.data,
                })
                if (page === 1) {
                    this.setState({
                        exceldata: []
                    })
                    this.getRecordsExcel();
                }
            }
            else {
                this.setState({
                    details: [],
                    exceldata: []
                })
            }
        })
    }
    getRecordsExcel = (page = 1) => {
        var data = { forexcel: "yes" };
        if (this.state.selected_user_id) {
            data.user_id = this.state.selected_user_id;
        }
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.status && this.state.status != "") {
            data.status = this.state.status;
        }
        if (this.state.category && this.state.category != "") {
            data.category = this.state.category;
        }
        if (this.state.payment_mode && this.state.payment_mode != "") {
            data.payment_mode = this.state.payment_mode;
        }
         console.log("send_data",data);
        Axios.post(`${apiUrl}/admin/indian-recharge-history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response excel", res)
            if (res.data.success) {
                var exceldata = [];
                res.data.data.forEach((val, key) => {
                    var dat = {

                        "Date": moment(val.created_at).format('YYYY-MM-DD h:mm a'),
                        "Name": val.user ? val.user.name : "",
                        "Mobile": val.user ? val.user.mobile : "",
                        "Recharge Mobile": val.phone,
                        "Order Id":val.order_id,
                        "Transaction Id": val.transaction_id,
                        "Service Provider":val.service_provider ? val.service_provider.provider_name : "",
                        "Category": val.category ? val.category.category_name : "",
                        
                        "Amount INR": (+val.amount).toLocaleString('en', 1),
                        "Bill Number": val.bill_number,
                        "Payment Mode": val.payment_mode,
                    }
                    exceldata.push(dat);
                });
                this.setState({
                    exceldata: exceldata
                })
            }
            else {
                this.setState({
                    details: []
                })
            }
        })
    }
    changePage = data => {
        console.log(data)
        this.getRecords(data.page);
    }
    searchUser = e => {
        if (e.length > 2) {
            var data = { keyword: e };
            Axios.post(apiUrl + "/admin/users/list", data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                console.log(res)
                this.setState({ options: res.data.users, isLoading: false, selected_user_id: null,
                    search_value: "" })
            })
        }else{
            this.setState({ 
            
                selected_user_id: null,
                search_value: ""
            
            });
        }

    }
    selectUser = selected => {
        console.log(selected)
        this.setState({ selected_user_id: selected.length > 0 ? selected[0].id : null,
        
            search_value: selected.length > 0 ? selected[0].name + '(' + selected[0].mobile +')':""
     });
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">
                                        Order  History
                                        {this.state.export && <> 
                                        <small className="float-right">
                                            {this.state.exceldata.length > 0 && <CSVLink data={this.state.exceldata} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"order-history-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}

                                            {this.state.exceldata.length === 0 && <a href="" onClick={(e) => { e.preventDefault() }} style={{ color: "#d4d6d5", background: "none", padding: "10px 8px", borderRadius: "0px", pointerEvents: "none" }} title="Export to CSV"><i className="fa fa-spinner fa-spin"></i>Excel</a>}
                                        </small>
                                        </>}
                                    </h3>
                                    <div className="row userhistoryrem">
                                    {/* <div className="col-md-3">
                                            <label>Sort Pagination </label>
                                            <select name="short" className="form-control" onChange={e => { this.handleInputshort(e); } } value={this.state.short}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                             
                                            </select>
                                        </div> */}
                                        <div className="col-md-3">
                                            <label>User</label>
                                            {this.state.show_search && <> 
                                            <AsyncTypeahead
                                                id="asdasd"
                                                isLoading={this.state.isLoading}
                                                defaultInputValue={this.state.search_value}
                                                labelKey={option => `${option.name} (${option.mobile})`}
                                                onSearch={(query) => {
                                                    this.setState({ isLoading: true });
                                                    this.searchUser(query);
                                                }}
                                                options={this.state.options}
                                                onChange={selected => { this.selectUser(selected) }}
                                            />
                                            </> }
                                        </div>
                                  
                                        <div className="col-md-3">
                                            <label>Category </label>
                                            <select name="category" className="form-control" onChange={e => { this.handleInput(e); } } value={this.state.category}>
                                                <option value="">Select</option>
                                                {this.state.categories.map((val, key) => {
                                        
                                               return <option key={key} value={val.id} >{val.category_name}</option>
                                              
                                                }) }
                                             
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label>Status </label>
                                            <select name="status" className="form-control" onChange={e => { this.handleInput(e); } } value={this.state.status}>
                                                <option value="">Select</option>
                                        
                                        
                                               <option  value="SUCCESS" >SUCCESS</option>
                                               <option  value="FAILED"  >FAILED</option>
                                           
                                             
                                            </select>
                                        </div>
                                        {/* <div className="col-md-2 mt20">
                                            <label>Payment Mode </label>
                                            <select name="payment_mode" className="form-control" onChange={e => { this.handleInput(e); } } value={this.state.payment_mode}>
                                                <option value="">Select</option>
                                        
                                        
                                               <option  value="TAKA24 WALLET" >TAKA24 WALLET</option>
                                               <option  value="Wallet"  >Wallet</option>
                                               <option  value="Net Banking"  >Net Banking</option>
                                               <option  value="Credit Card"  >Credit Card</option>
                                               <option  value="Debit Card"  >Debit Card</option>
                                               <option  value="Unified Payments"  >UPI</option>
                                             
                                            </select>
                                        </div> */}
                                        <div className="col-md-2 mt20">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2 mt20">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-2 mt20">
                                            <label>{" "}</label>
                                            <input type="button" name="submit" className="btn btn-primary searchhistory" onClick={(e) => { this.getRecords(1) }} value="Search" />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive style={{ fontFamily: "sans-serif" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Date</th>
                                                <th>User Name</th>
                                                <th>User Mobile</th>
                                                <th> Mobile</th>
                                                <th>Order Id</th>
                                                <th>Transaction Id</th>
                                                <th>Service Provider</th>
                                                <th>Category</th>
                                                <th>Amount</th>
                                                {/* <th>Taka24 Wallet Pay</th>
                                                <th>Online  Pay</th>
                                                <th>Convenience Fees</th>
                                                <th>Payment Mode</th> */}
                                                <th>Status</th>
                                                
                                                <th>ResText</th>
                                                {/* <th>Bill Number</th> */}
                                            
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.details.map((val, key) => {
                                                // console.log(key, this.state.paginatedata.current_page, this.state.paginatedata.per_page)
                                                return <tr key={key}>
                                                    <td>{key +1 }</td>
                                                    <td>{moment(val.created_at).format('YYYY-MM-DD h:mm a')}</td>
                                                    <td>{val.user ? val.user.name : ""}</td>
                                                    <td>{val.user ? val.user.mobile : ""}</td>
                                                    
                                                    <td>{val.phone}</td>
                                                    <td>{val.order_id}</td>
                                                    <td>{val.txnid}</td>
                                                    <td>{val.service_provider ? val.service_provider.provider_name : ""}</td>
                                                    <td>{val.category ? val.category.category_name : ""}</td>
                                                    <td>&#8377;{(+val.amount).toLocaleString('en', 3)} </td>
                                                    {/* <td>&#8377;{(+val.wallet_pay).toLocaleString('en', 3)}    {val.wallet_pay !== 0.00 && <>  <a href="" title="View  Payment Details" onClick={(e) => { this.viwpaymentDetails(e, val) }}> < i class="fa fa-eye" aria-hidden="true" style={{ fontSize: "17px" }}></i></a>&nbsp;&nbsp; </>} 
                                                      </td>
                                                    <td>&#8377;{(+val.online_pay).toLocaleString('en', 3)} </td>
                                                    <td>&#8377;{(+val.convenience_fees).toLocaleString('en', 3)} </td>
                                                    <td>{val.payment_mode}</td> */}
                                                    <td>{val.status}</td> 
                                             
                                                   
                                                    <td>{val.resText}</td>
                                                    
                                                    {/* <td>{val.bill_number}</td> */}
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={this.state.viwpaymentdetails} toggle={this.togglebankdetails}>
                            <ModalHeader toggle={this.togglebankdetails}><label style={{ 'font-size': '25px' }} >Payment  Mode</label></ModalHeader>
                            <ModalBody>
                                {this.state.wallet_balance > 0 && <> 
                            <div className="form-group">
                                    <label>Taka24 Wallet Balance: Rs.{this.state.wallet_balance} </label>
                                </div>
                                </>}
                                {this.state.credit_card > 0 && <> 
                                <div className="form-group">
                                    <label>Credit Card Wallet : Rs.{this.state.credit_card} </label>
                                </div> 
                                </>}
                                {this.state.net_banking > 0 && <> 
                                <div className="form-group">
                                    <label>Net Banking Wallet : Rs.{this.state.net_banking} </label>
                                </div>
                                </>}
                                {this.state.upi > 0 && <> 
                                <div className="form-group">
                                    <label>UPI Wallet Balance: Rs.{this.state.upi} </label>
                                </div>
                                </>}
                                {this.state.other_wallet > 0 && <> 
                                <div className="form-group">
                                    <label>Wallet Balance: Rs.{this.state.other_wallet} </label>
                                </div>
                                </>}
                                {this.state.debit_card > 0 && <> 
                                <div className="form-group">
                                    <label>Debit Card  Below 2000 Wallet : Rs.{this.state.debit_card} </label>
                                </div>
                                </>}
                                {this.state.debit_card_1 > 0 && <> 
                                <div className="form-group">
                                    <label>Debit Card equal and greater 2000 Wallet : Rs.{this.state.debit_card_1} </label>
                                </div>
                                </>}
                                
                                  
                                 
                                
                            </ModalBody>
                          
                        </Modal>
                </div>
            </>
        );
    }
}

export default IndianRechargeHistory;
