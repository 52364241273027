import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    FormGroup,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Button
} from "reactstrap";
import Axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import NotificationAlert from "react-notification-alert";
import { Pagination } from 'react-laravel-paginex';
import { CSVLink } from "react-csv";
import SimpleCrypto from "simple-crypto-js";
import env from "variables/constants";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);
const moment = require('moment');
var apiUrl = env.url;
var imageUrl = env.img_url;
class Distributors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rewardamount: 0,
            viewRewardModal: false,
            dropdownOpen: false,
            users: [],
            placement: "top",
            status: "",
            banner_image: null,
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 15,
                prev_page_url: null,
                to: 0,
                total: 0
            },
            show_form: false,
            viewModal: false,
            blockModal: false,
            imageModal: false,
            input_block_reason: "",
            deductModal: false,
            selected_user: null,
            deduct_from: "remit_balance",
            deduct_amount: 0,
            deduct_reason: "",
            keyword: "",
            excelusers: [],
            uploadDocumentForm: false,
            uploadOther: null,
            documents: [],
            selected_user: null,
            showCspForm: false,
            imagesrcView: null,
            allpermission: [],
            show_edit: false,
            caseback: false,
            balance: false,
            excel: false

        }
    }
    componentDidMount = () => {
        this.getAllUsers();
        this.permissions();
    }
    permissions = () => {

        this.setState({
            allpermission: JSON.parse(sessionStorage.getItem("adminpermission")),
            cuser: Cryp.decrypt(sessionStorage.getItem("cuser")),

        })
        setTimeout(() => {

            this.state.allpermission.map((val, key) => {
                if (val.page_name === "edit-dealers") {
                    this.setState({
                        show_edit: true
                    })
                } else if (val.page_name === "cashback") {
                    this.setState({
                        caseback: true
                    })
                } else if (val.page_name === "deduct-balance") {
                    this.setState({
                        balance: true
                    })
                } else if (val.page_name === "export-excel") {
                    this.setState({
                        excel: true
                    })
                }
            })
            //   console.log("truepage",this.state.show_edit);
            // console.log("cuser", this.state.cuser);
            // console.log("allpermission", this.state.allpermission);

        }, 500);

    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    toggleDropdown = (id) => {
        this.setState({
            dropdownOpen: this.state.dropdownOpen === id ? 0 : id
        })
    }
    getAllUsers = (page = 1, keyword = null) => {
        var data = {
            keyword: keyword ? keyword : this.state.keyword
        }
        Axios.post(`${apiUrl}/admin/all/distributors?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            // console.log("users response", res)
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.users,
                    users: res.data.users.data
                })
                this.getAllUsersExcel();
            }
            else {
                this.setState({
                    users: []
                })
            }
        })
    }

    exportPDF = (page = 1, keyword = null) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 0;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "All Distributors";
        const headers = [[ "Name", "Mobile", "Email", "Wallet Balance", "Remittance Balance", "Reference", "State", "District", "City", "Address", "CSP Code", "Status"]];

        var data = {
            keyword: keyword ? keyword : this.state.keyword,
            forexcel: "all"
        }
        Axios.post(`${apiUrl}/admin/all/distributors?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {

                setTimeout(() => {
                      const data = res.data.users.map(elt=> [ elt.name, elt.mobile, elt.email, elt.wallet_balance, elt.remit_balance, elt.referer, elt.state, elt.district, elt.city, elt.address, elt.csp_code, elt.status]);
                    let content = {
                        startY: 50,
                        head: headers,
                        body: data
                      };

                      doc.text(title, marginLeft, 10);
                      doc.autoTable(content);
                      doc.save("User-List.pdf")
                }, 200);


            }
            else {
                this.setState({
                    users: []
                })
            }
        })
    }

    getAllUsersExcel = (page = 1, keyword = null) => {
        var data = {
            keyword: keyword ? keyword : this.state.keyword,
            forexcel: "all"
        }
        Axios.post(`${apiUrl}/admin/all/distributors?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            // console.log("users response", res)
            if (res.data.success) {
                var excelusers = [];
                res.data.users.forEach((usr, ki) => {
                    var xx = {
                        "JOINING DATE": moment(usr.created_at).format('lll'),
                        "Name": usr.name,
                        "Mobile": usr.mobile,
                        "Email": usr.email,
                        "Wallet Balance": usr.wallet_balance,
                        "Remittance Balance": usr.remit_balance,
                        "Reference": usr.referer ? usr.referer : "Self",
                        "State": usr.state,
                        "District": usr.district,
                        "City": usr.city,
                        "Address": usr.address,
                        "CSP Code": usr.csp_code,
                        "Status": usr.status
                    };
                    excelusers.push(xx);
                })
                this.setState({
                    excelusers: excelusers
                })
            }
            else {
                this.setState({
                    users: []
                })
            }
        })
    }
    getStaticData = () => {
        this.setState({ loading: true })
        Axios.get(apiUrl + "/remittance/ime-forex/get/all/static/data", {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            // console.log("response", res)
            this.setState(res.data.data);
            this.setState({ loading: false })
        })
    }

    makeDealer = (e, val) => {
        e.preventDefault();
        this.getStaticData();
        this.setState({
            selected_user: val,
            showCspForm: true,
            uploadDocumentForm: val.csp_code !== null
        });
        this.getDocumentsList(val.id);
        this.setState(val);
        // if (window.confirm(`Role of "${val.name}" will be changed to dealer.`)) {
        //     Axios.post(`${apiUrl}/admin/make/dealer`, { user_id: val.id }, {
        //         headers: {
        //             Authorization: "Bearer " + sessionStorage.getItem("_access")
        //         }
        //     }).then(res => {
        // console.log("make dealer response", res)
        //         this.getAllUsers(this.state.paginatedata.current_page);
        //         if (res.data.success) {
        //             this.notify('success', res.data.message);
        //         }
        //         else {
        //             this.notify('error', res.data.message);
        //         }
        //     }, err => {
        //         console.log("make dealer error", err)
        //         this.notify('error', "Something went wrong.");
        //     })
        // }
        // console.log(val)
    }
    changePage = data => {
        // console.log(data)
        this.getAllUsers(data.page);
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };


    viwDetails = (e, user) => {
        e.preventDefault();
        this.setState(user);
        this.setState({ selected_user: user })
        // console.log(user);
        this.toggleViewModal();
    }
    handleError = (event) => {
        this.setState({ profile_pic: "/uploads/static/anime3.png" });
    }
    toggleViewModal = () => {
        this.setState({
            viewModal: !this.state.viewModal
        });
    }



    toggleBlockModal = () => {
        this.setState({
            blockModal: !this.state.blockModal
        })
    }
    toggleImageModal = (e, x = null) => {
        this.setState({
            imageModal: !this.state.imageModal,
            imagesrcView: x
        })
    }
    blockUser = () => {
        if (this.state.input_block_reason === "") {
            this.notify("error", "Please enter reason to block.");
            return false;
        }

        Axios.post(apiUrl + "/admin/block/user", { id: this.state.id, reason: this.state.input_block_reason }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            this.getAllUsers(this.state.paginatedata.current_page);
            if (type === "success") {
                this.setState({
                    status: "blocked",
                    block_reason: this.state.input_block_reason,
                    blockModal: false,
                    input_block_reason: ""
                })
            }
        })
    }
    activeUser = e => {
        e.preventDefault();
        Axios.post(apiUrl + "/admin/make/user/acive", { id: this.state.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.getAllUsers(this.state.paginatedata.current_page);
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            if (type === "success") {
                this.setState({
                    status: "active",
                    block_reason: "",
                    input_block_reason: ""
                })
            }
        })
    }
    toggleDeductModal = () => {
        this.setState({
            deductModal: !this.state.deductModal
        })
    }
    deductBalance = (e, val) => {
        e.preventDefault();
        this.setState({
            selected_user: val,
            deductModal: true
        })
    }
    deductBalanceSubint = () => {
        if (this.state.deduct_amount <= 0) {
            this.notify('error', "Amount should be more than 0.");
            return false;
        }
        if (this.state.deduct_from === "wallet_balance") {
            if (this.state.selected_user.wallet_balance < this.state.deduct_amount) {
                this.notify("error", "Amount should be less than or equal to user's wallet balance.");
                return false;
            }
        }
        if (this.state.deduct_from === "remit_balance") {
            if (this.state.selected_user.remit_balance < this.state.deduct_amount) {
                this.notify("error", "Amount should be less than or equal to user's wallet balance.");
                return false;
            }
        }
        if (this.state.deduct_reason === "") {
            this.notify('error', "Please enter reason.");
            return false;
        }
        var data = {
            deduct_amount: this.state.deduct_amount,
            user_id: this.state.selected_user.id,
            deduct_from: this.state.deduct_from,
            deduct_reason: this.state.deduct_reason,
        }
        Axios.post(apiUrl + `/admin/deduct/balance`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            if (type === "success") {
                this.getAllUsers(this.state.paginatedata.current_page);
                this.setState({
                    deduct_amount: 0,
                    selected_user: null,
                    deduct_from: "remit_balance",
                    deduct_reason: "",
                    deductModal: false
                })
            }
            this.notify(type, res.data.message);
            // console.log(res)
        })
    }

    searchUser = e => {
        e.preventDefault();
        var keyword = e.target.value;
        this.setState({
            keyword: keyword
        })
        setTimeout(() => {
            if (keyword.length > 2 || keyword === "") {
                // console.log(keyword);
                this.getAllUsers(1, keyword);
            }
        }, 200);
    }
    approveCsp = (e, val) => {
        e.preventDefault();
        if (!val.csp_code) {
            this.notify("error", "CSP code not found.");
            return false;
        }
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/ime-forex/check/csp", { user_id: val.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.setState({ loading: false })
                if (window.confirm("CSP code will be approved.")) {
                    this.setState({ loading: true })
                    Axios.post(`${apiUrl}/admin/approve/cspcode`, { user_id: val.id }, {
                        headers: {
                            Authorization: "Bearer " + sessionStorage.getItem("_access")
                        }
                    }).then(res => {
                        this.setState({ loading: false })
                        var type = res.data.status ? "success" : "error";
                        this.notify(type, res.data.message);
                        this.getAllUsers(this.state.paginatedata.current_page);
                    })
                }
            }
            else {
                this.getAllUsers(this.state.paginatedata.current_page);
                this.setState({ loading: false })
                this.notify("error", res.data.message);
            }
        })

    }
    updateCommission = e => {
        e.preventDefault()
        if (+this.state.selected_user.remittance_commission === +this.state.remittance_commission) {
            return false;
        }
        Axios.post(apiUrl + "/admin/update/user/commission",
            {
                type: 'remittance_commission',
                amount: this.state.remittance_commission,
                user_id: this.state.selected_user.id,
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                console.log(res)
            })
    }

    getDocumentsList = (id) => {
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/ime-forex/csp/documents/list/user", { user_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(Res => {
            console.log("document response", Res)
            this.setState({
                documents: Res.data.data,
                loading: false
            })
        }, err => {
            this.setState({ loading: false })
        })
    }

    uplaodOtherPh = (e, id) => {
        e.preventDefault();
        this.setState({
            uploadOther: id
        })
    }
    cancelUploadOtherPh = (e, id) => {
        e.preventDefault();
        this.setState({
            uploadOther: null
        })
    }
    editDetails = (e, det) => {
        e.preventDefault();
        this.setState({
            edit_name: det.name,
            edit_email: det.email,
            edit_mobile: det.mobile,
            edit_district: det.district,
            edit_city: det.city,
            edit_state: det.state,
            edit_country: det.country,
            edit_pincode: det.pincode,
            edit_address: det.address,
            user_id: det.id,
            editModal: true
        })
    }
    editaDetailsSubmit = e => {
        if (!this.state.edit_name || this.state.edit_name === "") {
            this.notify("error", "Please enter name");
            return false;
        }
        if (!this.state.edit_email || this.state.edit_email === "") {
            this.notify("error", "Please enter email");
            return false;
        }
        if (!this.state.edit_mobile || this.state.edit_mobile === "") {
            this.notify("error", "Please enter mobile number");
            return false;
        }
        var lengthcheck = this.state.edit_mobile.length;
        //  console.log("lenght",lengthcheck);
        if (lengthcheck !== 10) {
            this.notify("error", "Please enter correct mobile number.");
            return false;
        }
        if (!this.state.edit_address || this.state.edit_address === "") {
            this.notify("error", "Please enter address");
            return false;
        }
        if (!this.state.edit_country || this.state.edit_country === "") {
            this.notify("error", "Please enter country");
            return false;
        }
        if (!this.state.edit_state || this.state.edit_state === "") {
            this.notify("error", "Please enter state");
            return false;
        }
        if (!this.state.edit_city || this.state.edit_city === "") {
            this.notify("error", "Please enter city");
            return false;
        }
        if (!this.state.edit_district || this.state.edit_district === "") {
            this.notify("error", "Please enter district");
            return false;
        }
        if (!this.state.edit_pincode || this.state.edit_pincode === "") {
            this.notify("error", "Please enter pincode");
            return false;
        }
        var data = {
            name: this.state.edit_name,
            email: this.state.edit_email,
            mobile: this.state.edit_mobile,
            address: this.state.edit_address,
            country: this.state.edit_country,
            state: this.state.edit_state,
            city: this.state.edit_city,
            district: this.state.edit_district,
            pincode: this.state.edit_pincode,
            user_id: this.state.user_id
        }
        Axios.post(`${apiUrl}/admin/edit/user/details`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.getAllUsers(this.state.paginatedata.current_page);
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    edit_email: "",
                    edit_name: "",
                    edit_mobile: "",
                    edit_address: "",
                    edit_country: "",
                    edit_state: "",
                    edit_city: "",
                    edit_district: "",
                    edit_pincode: "",
                    editModal: false,
                    user_id: null
                })
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");
        })
    }
    toggleEditModal = () => {
        this.setState({
            editModal: !this.state.editModal
        })
    }
    submitUpload = e => {
        e.preventDefault();
        this.state.documents.forEach((doc, ki) => {
            if (doc.is_required === "Yes" && !doc.file_path) {
                this.notify("error", `Please upload ${doc.document.value}`);
                return false;
            }
        })
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/ime-forex/csp/documents/upload", { user_id: this.state.selected_user.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({ loading: false });
            if (res.data.success) {
                this.setState({
                    showCspForm: false,
                    uploadDocumentForm: false
                })
                Axios.post(`${apiUrl}/admin/make/dealer`, { user_id: this.state.selected_user.id }, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("_access")
                    }
                }).then(res => {
                    console.log("make dealer response", res)
                    this.getAllUsers(this.state.paginatedata.current_page);
                    if (res.data.success) {
                        this.notify('success', "Documents uploaded successfully.");
                    }
                    else {
                        this.notify('error', res.data.message);
                    }
                }, err => {
                    console.log("make dealer error", err)
                    this.notify('error', "Something went wrong.");
                })
            } else {
                this.notify("error", res.data.message)
            }
            console.log("upload documens fil", res)
        })
    }

    onFileChange = (e, id) => {
        this.setState({ loading: true });
        var formData = new FormData();
        formData.append('image', e.target.files[0]);
        formData.append('id', id);
        formData.append('user_id', this.state.selected_user.id);
        Axios.post(apiUrl + "/remittance/ime-forex/csp/single/document/upload", formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                documents: res.data.data,
                loading: false,
                uploadOther: null
            });
        }, err => {
            this.setState({ loading: false });
        })
    }

    sendCashback = (e, user = null) => {
        e.preventDefault();
        console.log(user);
        this.setState({
            viewRewardModal: !this.state.viewRewardModal,
            selected_user: user
        })
    }
    submitReward = (e) => {
        e.preventDefault();
        if (this.state.rewardamount === 0) {
            this.notify("error", "Please enter amount.");
            return false;

        }
        var data = {
            user_id: this.state.selected_user.id,
            amount: this.state.rewardamount
        }
        Axios.post(`${apiUrl}/admin/send/cashback`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    selected_user: null,
                    viewRewardModal: false,
                    rewardamount: 0
                });
                this.getAllUsers();
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");

        })
    }

    render() {
        return (
            <>{this.state.loading && <div className="loading-overlay">
                <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
            </div>}
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <div className="col-md-5">
                                            <h3 className="title userlistsec">Distributors List</h3>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="searchbox">
                                                <input type="text" name="keyword" className="form-control" onChange={(e) => { this.searchUser(e) }} value={this.state.keyword} placeholder="Search keyword" />
                                            </div>
                                            {this.state.excel && <>
                                                <small className="float-right excel-sec">
                                                <a style={{ background: "white", }} href="javascript::void(0)" onClick={() => this.exportPDF()}><i className="fa fa-file-pdf-o fa-2x">Pdf</i></a>

                                                    {this.state.excelusers.length > 0 && <CSVLink data={this.state.excelusers} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"User-List-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}
                                                </small>
                                            </>
                                            }
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {!this.state.uploadDocumentForm && <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>JOINING Date</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                {/* <th>Wallet Balance</th> */}
                                                <th>Wallet Balance</th>
                                                <th>Referal Code</th>
                                                <th>CSP Code</th>
                                                <th>CSP Code Approved</th>
                                                {/* <th>IME Registered</th> */}
                                                {/* <th>Remittance Commission</th> */}
                                                <th>Upgrade</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.users.map((val, key) => {
                                                var sr = (this.state.paginatedata.per_page * (this.state.paginatedata.current_page - 1)) + key + 1;
                                                var bal = val.remit_balance && val.remit_balance > 0 ? val.remit_balance : val.wallet_balance;
                                                return <tr key={key}>
                                                    <td>{sr}</td>
                                                    <td>{moment(val.created_at).format('lll')}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.mobile}</td>
                                                    {/* <td>{"₹" + val.wallet_balance.toFixed(2)}</td> */}
                                                    <td>{val.remit_balance ? "₹" + val.remit_balance.toFixed(2) : "₹0.00"}</td>
                                                    <td>{val.referal_code}</td>
                                                    <td>{val.csp_code}<br /> {val.prabhu_csp_code}</td>
                                                    <td>
                                                        {val.ime_registered = 'yes' && val.cspcode_approved && val.cspcode_approved == "no" && <a href="" onClick={(e) => { this.approveCsp(e, val) }} title="Approve csp code.">Check</a>}
                                                        {val.cspcode_approved === "yes" ? "Yes" : ""}
                                                    </td>
                                                    {/* <td>{val.ime_registered}</td> */}
                                                    {/* <td>&#8377;{val.remittance_commission}</td> */}
                                                    <td>{val.status.toUpperCase()}</td>
                                                    <td>
                                                        {val.ime_registered === "no" && <> <a href={"/admin/all-users?aid=" + val.id + "&type=IME"} className="badge badge-dark text-white" title="Make IME agent">
                                                            Make IME Agent
                                                        </a><br /></>}
                                                        {val.prabhu_registered == "no" && <> <a href={"/admin/all-users?aid=" + val.id + "&type=Prabhu"} className="badge badge-dark text-white" title="Make Prabhu agent">Make Prabhu Agent</a><br /></>}
                                                    </td>
                                                    <td className="img-delar">
                                                        <a href="" title="View Details" onClick={(e) => { this.viwDetails(e, val) }}><img src={require('assets/images/other-icons/view.png')} alt="View Details" style={{ width: "25px" }} /></a>&nbsp;&nbsp;
                                                        {this.state.balance && <> {bal > 0 && <a href="" className="balanceammout" title="Deduct Balance" onClick={(e) => { this.deductBalance(e, val) }}><i className="fa fa-money" style={{ fontSize: "20px" }} ></i></a>}&nbsp; &nbsp; </>}
                                                        {val.cspcode_approved == "no" && <> <a href="" className="balanceammout" title="Reupload Documents" onClick={(e) => { this.makeDealer(e, val) }}><i className="fa fa-upload" style={{ fontSize: "20px" }} ></i></a>&nbsp; &nbsp;</>}

                                                        {this.state.show_edit && <a href="" className="detailsEdit" title="Edit Details" onClick={(e) => { this.editDetails(e, val) }}><i className="fa fa-pencil-square-o" style={{ fontSize: "20px", verticalAlign: "middle" }} ></i></a>}

                                                        {this.state.caseback && <a href="" className="detailsEdit" title="Send Cashback" onClick={(e) => { this.sendCashback(e, val) }}><i className="fa fa-gift" style={{ fontSize: "20px", verticalAlign: "middle" }} ></i></a>}
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>}
                                    {!this.state.uploadDocumentForm && <Pagination changePage={this.changePage} data={this.state.paginatedata} />}

                                    {this.state.uploadDocumentForm && <>
                                        <div className="col-md-6">
                                            {this.state.documents.map((doc, ki) => {
                                                if (!doc.file_path) {
                                                    return <><div key={ki}>
                                                        <label>{doc.document ? doc.document.value : ""} {doc.is_required === "Yes" && <span className="text-danger">*</span>}</label>
                                                        <Input type="file" name="license" onChange={(e) => { this.onFileChange(e, doc.id) }} />
                                                    </div>
                                                        <hr /></>
                                                }
                                                else {
                                                    if (this.state.uploadOther === doc.id) {
                                                        return <><div key={ki}>
                                                            <label>{doc.document ? doc.document.value : ""} {doc.is_required === "Yes" && <span className="text-danger">*</span>} &nbsp;&nbsp; <a href="" onClick={(e) => { this.cancelUploadOtherPh(e, doc.id) }}> Cancel</a></label>
                                                            <Input type="file" name="license" onChange={(e) => { this.onFileChange(e, doc.id) }} />
                                                        </div>
                                                            <hr /></>
                                                    }
                                                    else {
                                                        if (doc.file_path.includes(".pdf")) {
                                                            return <><div key={ki}>
                                                                <label>{doc.document ? doc.document.value : ""} {doc.is_required === "Yes" && <span className="text-danger">*</span>} &nbsp;&nbsp; <a href="" onClick={(e) => { this.uplaodOtherPh(e, doc.id) }}> Upload other</a></label>
                                                                <embed src={imageUrl + doc.file_path} alt="" /></div>
                                                                <hr /></>
                                                        } else {
                                                            return <><div key={ki}>
                                                                <label>{doc.document ? doc.document.value : ""} {doc.is_required === "Yes" && <span className="text-danger">*</span>} &nbsp;&nbsp; <a href="" onClick={(e) => { this.uplaodOtherPh(e, doc.id) }}> Upload other</a></label>
                                                                <img src={imageUrl + doc.file_path} alt="" /></div>
                                                                <hr /></>
                                                        }
                                                    }
                                                }
                                            })}
                                            <Button type="button" onClick={(e) => { this.submitUpload(e) }}>Submit</Button>
                                        </div>
                                    </>}
                                </CardBody>
                            </Card>
                        </Col>

                        {/* ##############  View dealer details popup ############################# */}
                        <Modal modalClassName="modal" isOpen={this.state.viewModal} toggle={this.toggleViewModal} size="lg" >
                            <div className="modal-header">
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleViewModal} style={{ zIndex: "999" }}>
                                    <i className="tim-icons icon-simple-remove" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="view-popup">
                                    <h3>View Dealer Detail </h3>
                                    <div className="userddetails">
                                        <div className="userimg">
                                            <img alt="..." onError={this.handleError} src={imageUrl + this.state.profile_pic} />
                                        </div>
                                        <div className="user-details">
                                            <strong className="username">{this.state.name}</strong>
                                            <p className="mobileno">{this.state.mobile}</p>
                                        </div>
                                    </div>
                                    <Row className="startedformsec">
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Name</label>
                                                <h4>{this.state.name}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Email</label>
                                                <h4>{this.state.email ? this.state.email : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Phone Number</label>
                                                <h4>{this.state.mobile ? this.state.mobile : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Referal Code</label>
                                                <h4>{this.state.referal_code ? this.state.referal_code : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Address</label>
                                                <h4>{this.state.address ? this.state.address : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>PIN Code</label>
                                                <h4>{this.state.pincode ? this.state.pincode : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>City</label>
                                                <h4>{this.state.city ? this.state.city.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>State</label>
                                                <h4>{this.state.state ? this.state.state.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>

                                        {this.state.csp_code && <Col md="6">
                                            <FormGroup>
                                                <label>CSP COde</label>
                                                <h4>{this.state.csp_code}</h4>
                                            </FormGroup>
                                        </Col>}
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Country</label>
                                                <h4>{this.state.country ? this.state.country.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Dirtrict</label>
                                                <h4>{this.state.district ? this.state.district.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Account Status</label>
                                                <h4>{this.state.status ? this.state.status.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        {this.state.status === "blocked" && <Col md="6">
                                            <FormGroup>
                                                <label>Block Reason</label>
                                                <h4>{this.state.block_reason ? this.state.block_reason : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>}

                                        {/* {this.state.status !== "blocked" && <Col md="6">
                                            <FormGroup>
                                                <label>Remittance Commission in &#8377;</label>
                                                <div className="input-group">
                                                    <input className="form-control" type="number" min="0" name="remittance_commission" placeholder="Remittance Commission" value={this.state.remittance_commission} onChange={(e) => { this.handleInput(e) }} />
                                                    <div className="input-group-append" style={{ height: "45px", border: "none" }}>
                                                        <a href="" className="input-group-text" style={{ borderRadius: "0 50px 50px 0", color: "#00945d" }} onClick={(e) => { this.updateCommission(e) }}>Save</a>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Col>} */}

                                        {!this.state.status === "blocked" && <Col md="6">
                                            <FormGroup>
                                                <label>Account Status</label>
                                                <h4>{this.state.status ? this.state.status.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>}
                                    </Row>
                                    <Row>
                                        {this.state.license_back && <Col md="6">
                                            <div className="">
                                                <label style={{ textAlign: "center" }}>Identity Back ({this.state.proof_type.replace("_", " ")})</label>
                                                <img src={imageUrl + this.state.license_back} alt=" " onClick={e => { this.toggleImageModal(e, imageUrl + this.state.license_back) }} />
                                            </div>
                                        </Col>}
                                        {this.state.license_front && <Col md="6">
                                            <div className="">
                                                <label style={{ textAlign: "center" }}>Identity Front({this.state.proof_type.replace("_", " ")})</label>
                                                <img src={imageUrl + this.state.license_front} alt=" " onClick={e => { this.toggleImageModal(e, imageUrl + this.state.license_front) }} />
                                            </div>
                                        </Col>}
                                        {this.state.status == "active" && <button type="button" className="btn btn-danger" onClick={(e) => { this.toggleBlockModal(e) }}>Block Account</button>}
                                        {this.state.status !== "active" && <button type="button" className="btn btn-danger" onClick={(e) => { this.activeUser(e) }}>Active User</button>}
                                    </Row>
                                </div>
                            </div>
                        </Modal>
                        <Modal isOpen={this.state.imageModal} toggle={this.toggleImageModal} size="lg" >
                            <ModalHeader toggle={this.toggleImageModal}>Identity</ModalHeader>
                            <ModalBody>
                                <img src={this.state.imagesrcView} alt="" />
                            </ModalBody>
                        </Modal>
                        {/* ##############  block dealer popup ############################# */}
                        <Modal isOpen={this.state.blockModal} toggle={this.toggleBlockModal}>
                            <ModalHeader toggle={this.toggleBlockModal}>Block Reason</ModalHeader>
                            <ModalBody>
                                <textarea name="input_block_reason" onChange={(e) => { this.handleInput(e) }} value={this.state.input_block_reason} className="form-control"></textarea>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-danger" onClick={this.blockUser}>
                                    Block <b>{this.state.name} ({this.state.mobile})</b>
                                </button>
                            </ModalFooter>
                        </Modal>

                        {/* ##############  deduct balance popup ############################# */}
                        <Modal isOpen={this.state.deductModal} toggle={this.toggleDeductModal}>
                            <ModalHeader toggle={this.toggleDeductModal}>Deduct Amount</ModalHeader>
                            <ModalBody>
                                <div className="form-group">
                                    {this.state.selected_user && <label>Name: {this.state.selected_user.name} ({this.state.selected_user.mobile})</label>}
                                </div>
                                <div className="form-group">
                                    {this.state.selected_user && <label>Wallet Balance: {this.state.selected_user.remit_balance}</label>}
                                </div>
                                <div className="form-group">
                                    <label>Amount</label>
                                    <input type="number" min="0" className="form-control" onChange={(e) => { this.handleInput(e) }} name="deduct_amount" value={this.state.deduct_amount} />
                                </div>
                                <div className="form-group">
                                    <label>Reason</label>
                                    <textarea name="deduct_reason" onChange={(e) => { this.handleInput(e) }} value={this.state.deduct_reason} className="form-control"></textarea>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-danger" onClick={this.deductBalanceSubint}>
                                    Submit
                                </button>
                            </ModalFooter>
                        </Modal>
                        <Modal modalClassName="modal" isOpen={this.state.editModal} toggle={this.toggleEditModal} size="lg" >
                            <div className="modal-header">
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleEditModal} style={{ zIndex: "999" }} >
                                    <i className="tim-icons icon-simple-remove" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="view-popup">
                                    <h3>Edit Dealer Detail</h3>
                                    <div className="edituserddetails">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Name</lebel>
                                                    <input className="form-control" type="text" name="edit_name" value={this.state.edit_name} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Email</lebel>
                                                    <input className="form-control" type="text" name="edit_email" value={this.state.edit_email} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Mobile Number</lebel>
                                                    <input className="form-control" type="text" name="edit_mobile" value={this.state.edit_mobile} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Address</lebel>
                                                    <input className="form-control" type="text" name="edit_address" value={this.state.edit_address} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Country</lebel>
                                                    <input className="form-control" type="text" name="edit_country" value={this.state.edit_country} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>State</lebel>
                                                    <input className="form-control" type="text" name="edit_state" value={this.state.edit_state} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>City</lebel>
                                                    <input className="form-control" type="text" name="edit_city" value={this.state.edit_city} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>District</lebel>
                                                    <input className="form-control" type="text" name="edit_district" value={this.state.edit_district} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Pincode</lebel>
                                                    <input className="form-control" type="text" name="edit_pincode" value={this.state.edit_pincode} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ModalFooter>
                                        <button type="button" className="btn btn-danger" onClick={this.editaDetailsSubmit}>
                                            Submit
                                        </button>
                                    </ModalFooter>
                                </div>
                            </div>
                        </Modal>
                    </Row>
                </div>

                <Modal
                    modalClassName="modal"
                    isOpen={this.state.viewRewardModal}
                    toggle={this.sendCashback}
                >
                    <div className="modal-header">
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.sendCashback} style={{ zIndex: "999" }} >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="view-popup">
                            {this.state.selected_user && <h3>Send Reward to {this.state.selected_user.name} ({this.state.selected_user.mobile})</h3>}
                            <div className="userddetails">
                                <Row>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <lebel>Amount</lebel>
                                            <input className="form-control" type="number" min="0" name="rewardamount" value={this.state.rewardamount} onChange={(e) => { this.handleInput(e) }} />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger mb-4 float-right" onClick={this.submitReward}>
                            Submit
                        </button>
                    </ModalFooter>
                </Modal>

            </>
        );
    }
}

export default Distributors;
