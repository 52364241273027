import React from "react";
import { Button, Card, CardHeader, CardBody, Row, Col, FormGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import { Pagination } from 'react-laravel-paginex';
import { CSVLink } from "react-csv";
import SimpleCrypto from "simple-crypto-js";
import ImeUploadDocs from "./forms/ImeUploadDocs";
import ImeCsRegister from "./forms/ImeCspRegister";
import Prabhucsp from "./forms/Prabhucsp";
import swal from "sweetalert";
import env from "variables/constants";
import KycForm from "./forms/KycForm";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);
var apiUrl = env.url;
var imageUrl = env.img_url;
const moment = require('moment');
class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_user_id: null,
            payment_method_agent: 'free',
            remittance_type: "",
            rewardamount: 0,
            reward_comment: "",
            viewRewardModal: false,
            loading: false,
            dropdownOpen: false,
            showCspForm: false,
            uploadDocumentForm: false,
            users: [],
            placement: "top",
            status: "",
            banner_image: null,
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 15,
                prev_page_url: null,
                to: 0,
                total: 0
            },
            show_form: false,
            viewModal: false,
            blockModal: false,
            imageModal: false,
            input_block_reason: "",
            deductModal: false,
            selected_user: null,
            deduct_from: "remit_balance",
            deduct_amount: 0,
            deduct_reason: "",
            keyword: "",
            additional_course: [],
            alternate_occupation_type: [],
            bank_account_type: [],
            csp_address_proof_type: [],
            csp_business_type: [],
            csp_device: [],
            csp_device_connectivity_type: [],
            csp_document_type: [],
            csp_registration_type: [],
            customer_identification_type_indian: [],
            customer_identification_type_neplease: [],
            educational_qualification: [],
            gender: [],
            marital_status: [],
            occupation: [],
            owner_category: [],
            owners_address_proof_type: [],
            owners_id_type: [],
            physically_handicapped: [],
            purpose_of_remittance: [],
            relationship: [],
            source_of_fund: [],
            transaction_cancel_reason: [],
            states: [],
            districts: [],
            ostates: [],
            odistricts: [],
            municipalities: [],
            banks: [],
            registration_type: "",
            bussiness_type: "",
            pan_number: "",
            csp_country: "",
            csp_state: "",
            csp_district: "",
            municipality: "",
            csp_city: "",
            house_number: "",
            road_name: "",
            pin_code: "",
            address_proof_type: "",
            contact_person_name: "",
            mobile_number: "",
            mobile_number2: "",
            land_line_number: "",
            csp_email: "",
            bank: "",
            csp_bank_account_type: "",
            bank_account_number: "",
            ifsc: "",
            device: "",
            connectivity_type: "",
            provider: "",
            start_time: "",
            end_time: "",
            has_week_off: "",
            off_day: "",
            owners_name: "",
            owners_gender: "",
            owners_dob: "",
            owners_father_name: "",
            owners_spouse_name: "",
            owners_category: "",
            owners_is_handicapped: "",
            owners_mobile_number: "",
            owners_contact_number: "",
            owners_email: "",
            owners_alternate_occupation: "",
            owners_pan_card: "",
            owners_id_types: "",
            owners_id_number: "",
            owners_country: "",
            owners_state: "",
            owners_district: "",
            owners_city: "",
            owners_house_number: "",
            owners_road_name: "",
            owners_pin_code: "",
            owners_village_code: "",
            owners_address_prrof_type: "",
            owners_qualification: "",
            owners_additional_course: "",
            owners_institute_name: "",
            owners_date_of_passed: "",
            uploadOther: null,
            documents: [],
            excelusers: [],
            tempLoginModal: false,
            input_notice: "",
            imagesrcView: null,
            sort_by: "remit_desc",
            status_filter: "all",
            allpermission: [],
            show_edit: false,
            caseback: false,
            balance: false,
            excel: false,
            otp_verified: false,
            otp: "",
            selected_user: null,
            otp_token: "",
            upgrade_type: "",
            csp_status: "",
            atype: "IME",
            submit_status: "pending",
            headers: {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }
        }
    }
    componentDidMount = () => {
        let search = this.props.history.location.search;
        let params = new URLSearchParams(search);
        let user_id = params.get('aid');
        let atype = params.get('type');
        if (user_id) {
            this.setState({
                selected_user_id: +user_id,
                atype: atype
            })
        }
        if (atype) {
            this.setState({
                agent_type: atype
            })
        }
        setTimeout(() => {
            this.getAllUsers();
            this.permissions();
        }, 200);
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    toggleDropdown = (id) => {
        this.setState({
            dropdownOpen: this.state.dropdownOpen === id ? 0 : id
        })
    }
    permissions = () => {

        this.setState({
            allpermission: JSON.parse(sessionStorage.getItem("adminpermission")),
            cuser: Cryp.decrypt(sessionStorage.getItem("cuser")),

        })
        setTimeout(() => {

            this.state.allpermission.map((val, key) => {
                if (val.page_name === "edit-users") {
                    this.setState({
                        show_edit: true
                    })
                } else if (val.page_name === "cashback") {
                    this.setState({
                        caseback: true
                    })
                } else if (val.page_name === "deduct-balance") {
                    this.setState({
                        balance: true
                    })
                } else if (val.page_name === "export-excel") {
                    this.setState({
                        excel: true
                    })
                }
            })
            //   console.log("truepage",this.state.show_edit);
            console.log("cuser", this.state.cuser);
            console.log("allpermission", this.state.allpermission);

        }, 500);

    }
    getAllUsers = (page = 1, keyword = null) => {
        var data = {
            keyword: keyword ? keyword : this.state.keyword,
            sort_by: this.state.sort_by,
            status_filter: this.state.status_filter
        }
        if (this.state.selected_user_id) {
            data.selected_user_id = this.state.selected_user_id;
        }
        Axios.post(`${apiUrl}/admin/all/users?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.users,
                    users: res.data.users.data,
                    become_saathi_fees: res.data.become_saathi_fees,
                });
                if (this.state.selected_user_id && res.data.single) {
                    this.makeDealer(null, res.data.single, true);
                    this.setState({
                        upgrade_type: "dealer",
                        remittance_type: this.state.atype
                    })
                }
                this.getAllUsersExcel();
            }
            else {
                this.setState({
                    users: []
                })
            }
        })
    }
    getAllUsersExcel = (page = 1, keyword = null) => {
        var data = {
            keyword: keyword ? keyword : this.state.keyword,
            sort_by: this.state.sort_by,
            status_filter: this.state.status_filter,
            forexcel: "all"
        }
        Axios.post(`${apiUrl}/admin/all/users?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("users response", res)
            if (res.data.success) {
                var excelusers = [];
                res.data.users.forEach((usr, ki) => {
                    var xx = {
                        "JOINING DATE": moment(usr.created_at).format('lll'),
                        "Name": usr.name,
                        "Mobile": usr.mobile,
                        "Email": usr.email,
                        "Wallet Balance": usr.wallet_balance,
                        "Remittance Balance": usr.remit_balance,
                        "Reference": usr.referer ? usr.referer : "Self",
                        "Country": usr.country,
                        "State": usr.state,
                        "District": usr.district,
                        "City": usr.city,
                        "Address": usr.address,
                        "Status": usr.status,
                        "Pincode": usr.pincode,
                        "CSP Code": usr.csp_code,

                    };
                    excelusers.push(xx);
                })
                this.setState({
                    excelusers: excelusers
                })
            }
            else {
                this.setState({
                    users: []
                })
            }
        })
    }
    getStaticData = () => {
        this.setState({ loading: true })
        Axios.get(apiUrl + "/remittance/ime-forex/get/all/static/data", {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("response", res)
            this.setState(res.data.data);
            this.setState({ loading: false })
        })
    }
    makeDealer = (e, val, x = null) => {
        if (e) e.preventDefault();
        this.getStaticData();
        this.setState({
            selected_user: val,
            showCspForm: true,
            uploadDocumentForm: false
        });
        this.getDocumentsList(val.id);
        this.setState(val);
        this.getCspDetails(val.id);
        // if (window.confirm(`Role of "${val.name}" will be changed to dealer.`)) {
        //     Axios.post(`${apiUrl}/admin/make/dealer`, { user_id: val.id }, {
        //         headers: {
        //             Authorization: "Bearer " + sessionStorage.getItem("_access")
        //         }
        //     }).then(res => {
        //         console.log("make dealer response", res)
        //         this.getAllUsers(this.state.paginatedata.current_page);
        //         if (res.data.success) {
        //             this.notify('success', res.data.message);
        //         }
        //         else {
        //             this.notify('error', res.data.message);
        //         }
        //     }, err => {
        //         console.log("make dealer error", err)
        //         this.notify('error', "Something went wrong.");
        //     })
        // }
        console.log(val)
    }
    changePage = data => {
        console.log(data)
        this.getAllUsers(data.page);
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    viwDetails2 = (e, user) => {
        e.preventDefault();
        sessionStorage.setItem("id",user.id)
        window.location.href="/admin/user-detail";
    }


    viwDetails = (e, user) => {
        e.preventDefault();
        this.setState(user);
        console.log(user);
        this.getStaticData();
        this.toggleViewModal();
    }
    handleError = (event) => {
        this.setState({ profile_pic: "/uploads/static/anime3.png" });
    }
    toggleViewModal = () => {
        this.setState({
            viewModal: !this.state.viewModal
        });
    }
    toggleBlockModal = () => {
        this.setState({
            blockModal: !this.state.blockModal
        })
    }
    toggleImageModal = (e, x = null) => {
        this.setState({
            imageModal: !this.state.imageModal,
            imagesrcView: x
        })
    }
    toggleTempLoginModal = () => {
        this.setState({
            tempLoginModal: !this.state.tempLoginModal
        })
    }
    blockUser = () => {
        if (this.state.input_block_reason === "") {
            this.notify("error", "Please enter reason to block.");
            return false;
        }

        Axios.post(apiUrl + "/admin/block/user", { id: this.state.id, reason: this.state.input_block_reason }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            this.getAllUsers(this.state.paginatedata.current_page);
            if (type === "success") {
                this.setState({
                    status: "blocked",
                    block_reason: this.state.input_block_reason,
                    blockModal: false,
                    input_block_reason: ""
                })
            }
        })
    }
    approveUser = () => {
        if (this.state.input_notice === "") {
            this.notify("error", "Please add notice.");
            return false;
        }

        Axios.post(apiUrl + "/admin/approve/user", { id: this.state.id, status: "temp_login", notice: this.state.input_notice }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            this.getAllUsers(this.state.paginatedata.current_page);
            if (type === "success") {
                this.setState({
                    status: "temp_login",
                    notice: this.state.input_notice,
                    blockModal: false,
                    input_notice: "",
                    tempLoginModal: false
                })
            }
        })
    }
    activeUser = e => {
        e.preventDefault();
        Axios.post(apiUrl + "/admin/make/user/acive", { id: this.state.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.getAllUsers(this.state.paginatedata.current_page);
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            if (type === "success") {
                this.setState({
                    status: "active",
                    block_reason: "",
                    input_block_reason: ""
                })
            }
        })
    }
    toggleDeductModal = () => {
        this.setState({
            deductModal: !this.state.deductModal
        })
    }
    deductBalance = (e, val) => {
        e.preventDefault();
        this.setState({
            selected_user: val,
            deductModal: true
        })
    }
    deductBalanceSubint = () => {
        if (this.state.deduct_amount <= 0) {
            this.notify('error', "Amount should be more than 0.");
            return false;
        }
        if (this.state.deduct_from === "wallet_balance") {
            if (this.state.selected_user.wallet_balance < this.state.deduct_amount) {
                this.notify("error", "Amount should be less than or equal to user's wallet balance.");
                return false;
            }
        }
        if (this.state.deduct_from === "remit_balance") {
            if (this.state.selected_user.remit_balance < this.state.deduct_amount) {
                this.notify("error", "Amount should be less than or equal to user's wallet balance.");
                return false;
            }
        }
        if (this.state.deduct_reason === "") {
            this.notify('error', "Please enter reason.");
            return false;
        }
        var data = {
            deduct_amount: this.state.deduct_amount,
            user_id: this.state.selected_user.id,
            deduct_from: this.state.deduct_from,
            deduct_reason: this.state.deduct_reason,
        }
        Axios.post(apiUrl + `/admin/deduct/balance`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            if (type === "success") {
                this.getAllUsers(this.state.paginatedata.current_page);
                this.setState({
                    deduct_amount: 0,
                    selected_user: null,
                    deduct_from: "remit_balance",
                    deduct_reason: "",
                    deductModal: false
                })
            }
            this.notify(type, res.data.message);
            console.log(res)
        })
    }
    searchUser = e => {
        e.preventDefault();
        var keyword = e.target.value;
        this.setState({
            keyword: keyword
        })
        setTimeout(() => {
            if (keyword.length > 2 || keyword === "") {
                this.getAllUsers(1, keyword);
            }
        }, 200);
    }
    handleCountryChange = (e, param, val = null) => {
        var value = val ? val : e.target.value;
        var data = { country_id: value }
        var x = 'states';
        if (param === 'owners_state') {
            x = 'ostates';
            this.setState({ owners_country: value, loading: true })
        }
        else {
            this.setState({ csp_country: value, loading: true })
        }
        this.getBanks(value);
        Axios.post(apiUrl + "/remittance/ime-forex/get/state", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                [x]: res.data.data,
                loading: false
            })
        })
    }
    handleInputState = (e, param, val = null) => {
        var value = val ? val : e.target.value;
        var x = 'districts';
        if (param === 'owners_district') {
            x = 'odistricts';
            this.setState({ owners_state: value, loading: true })
        }
        else {
            this.setState({ csp_state: value, loading: true })

        }
        var data = { state_id: value }
        Axios.post(apiUrl + "/remittance/ime-forex/get/district", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                [x]: res.data.data,
                loading: false
            })
        })
    }

    handleInputDistrict = (e, param, val = null) => {
        var value = val ? val : e.target.value;
        if (param === 'municipality') {
            this.setState({ csp_district: value });
        }
        else {
            this.setState({ owners_district: value });
        }
        if (this.state.csp_country === "NPL") {
            var data = { district_id: value }
            this.setState({
                loading: true
            })
            Axios.post(apiUrl + "/remittance/ime-forex/get/municipality", data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                this.setState({
                    municipalities: res.data.data,
                    loading: false
                })
            })
        }
    }

    getBanks = (country_id) => {
        this.setState({
            loading: true
        })
        Axios.post(apiUrl + "/remittance/ime-forex/get/banks", { country_id: country_id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                loading: false
            })
            this.setState({ banks: res.data.data })
        }, err => {
            this.setState({
                loading: false
            })
        })
    }
    cancelCSPReg = e => {
        e.preventDefault();
        this.setState({ showCspForm: false })
    }
    submitCSP = e => {
        e.preventDefault();
        var data = {
            user_type: this.state.upgrade_type,
            registration_type: this.state.registration_type,
            bussiness_type: this.state.bussiness_type,
            cspname: this.state.cspname,
            pan_number: this.state.pan_number,
            country: this.state.csp_country,
            state: this.state.csp_state,
            district: this.state.csp_district,
            municipality: this.state.municipality,
            city: this.state.csp_city,
            house_number: this.state.house_number,
            road_name: this.state.road_name,
            pin_code: this.state.pin_code,
            address_proof_type: this.state.address_proof_type,
            contact_person_name: this.state.contact_person_name,
            mobile_number: this.state.mobile_number,
            mobile_number2: this.state.mobile_number2,
            land_line_number: this.state.land_line_number,
            email: this.state.csp_email,
            bank: this.state.bank,
            bank_account_type: this.state.csp_bank_account_type,
            bank_account_number: this.state.bank_account_number,
            ifsc: this.state.ifsc,
            device: this.state.device,
            connectivity_type: this.state.connectivity_type,
            provider: this.state.provider,
            start_time: this.state.start_time,
            end_time: this.state.end_time,
            has_week_off: this.state.has_week_off,
            off_day: this.state.off_day,
            owners_name: this.state.owners_name,
            owners_gender: this.state.owners_gender,
            owners_dob: this.state.owners_dob,
            owners_father_name: this.state.owners_father_name,
            owners_spouse_name: this.state.owners_spouse_name,
            owners_category: this.state.owners_category,
            owners_is_handicapped: this.state.owners_is_handicapped,
            owners_mobile_number: this.state.owners_mobile_number,
            owners_contact_number: this.state.owners_contact_number,
            owners_email: this.state.owners_email,
            owners_alternate_occupation: this.state.owners_alternate_occupation,
            owners_pan_card: this.state.owners_pan_card,
            owners_id_type: this.state.owners_id_types,
            owners_id_number: this.state.owners_id_number,
            owners_country: this.state.owners_country,
            owners_state: this.state.owners_state,
            owners_district: this.state.owners_district,
            owners_city: this.state.owners_city,
            owners_house_number: this.state.owners_house_number,
            owners_road_name: this.state.owners_road_name,
            owners_pin_code: this.state.owners_pin_code,
            owners_village_code: this.state.owners_village_code,
            owners_address_prrof_type: this.state.owners_address_prrof_type,
            owners_qualification: this.state.owners_qualification,
            owners_additional_course: this.state.owners_additional_course,
            owners_institute_name: this.state.owners_institute_name,
            owners_date_of_passed: this.state.owners_date_of_passed,
            user_id: this.state.selected_user.id
        }
        if (data.user_type === "") {
            this.notify("error", "Please select upgrade type.");
            return false;
        }
        if (data.registration_type === "") {
            this.notify("error", "Please select Registration type.");
            return false;
        }
        if (data.bussiness_type === "") {
            this.notify("error", "Please select Business type.");
            return false;
        }
        if (data.cspname === "") {
            this.notify("error", "Please CSP name.");
            return false;
        }
        if (data.pan_number === "") {
            this.notify("error", "Please enter pan number.");
            return false;
        }
        if (data.country === "") {
            this.notify("error", "Please select country.");
            return false;
        }
        if (data.state === "") {
            this.notify("error", "Please select state.");
            return false;
        }
        if (data.district === "") {
            this.notify("error", "Please select district.");
            return false;
        }
        if (data.municipality === "" && data.country === "NPL") {
            this.notify("error", "Please select municipality.");
            return false;
        }
        if (data.city === "") {
            this.notify("error", "Please enter city.");
            return false;
        }
        if (data.pin_code === "") {
            this.notify("error", "Please enter pincode.");
            return false;
        }
        if (data.address_proof_type === "") {
            this.notify("error", "Please select address proof type.");
            return false;
        }
        if (data.contact_person_name === "") {
            this.notify("error", "Please enter contact person name.");
            return false;
        }
        if (data.mobile_number === "") {
            this.notify("error", "Please enter mobile number.");
            return false;
        }
        if (data.email === "") {
            this.notify("error", "Please enter email.");
            return false;
        }
        if (data.bank === "") {
            this.notify("error", "Please select Bank.");
            return false;
        }
        if (data.bank_account_type === "") {
            this.notify("error", "Please select bank account type.");
            return false;
        }
        if (data.bank_account_number === "") {
            this.notify("error", "Please enter bank account number.");
            return false;
        }
        if (data.ifsc === "") {
            this.notify("error", "Please enter IFSC.");
            return false;
        }
        if (data.device === "") {
            this.notify("error", "Please select device.");
            return false;
        }
        if (data.connectivity_type === "") {
            this.notify("error", "Please select connectivity type.");
            return false;
        }
        if (data.provider === "") {
            this.notify("error", "Please select internet service provider.");
            return false;
        }
        if (data.start_time === "") {
            this.notify("error", "Please select business start time.");
            return false;
        }
        if (data.end_time === "") {
            this.notify("error", "Please select business end time.");
            return false;
        }
        if (data.has_week_off === "") {
            this.notify("error", "Please select if you has week off.");
            return false;
        }
        if (data.has_week_off === "Y" && data.off_day === "") {
            this.notify("error", "Please select off day.");
            return false;
        }
        if (data.owners_name === "") {
            this.notify("error", "Please enter owner's name.");
            return false;
        }
        if (data.owners_gender === "") {
            this.notify("error", "Please select owner's gender.");
            return false;
        }
        if (data.owners_dob === "") {
            this.notify("error", "Please enter owner's dob.");
            return false;
        }
        if (data.owners_father_name === "") {
            this.notify("error", "Please select owner's father name.");
            return false;
        }

        if (data.owners_mobile_number === "") {
            this.notify("error", "Please enter owner's mobile number.");
            return false;
        }

        if (data.owners_pan_card === "") {
            this.notify("error", "Please enter owner's pan card.");
            return false;
        }

        if (data.owners_id_type === "") {
            this.notify("error", "Please select owner's id type.");
            return false;
        }

        if (data.owners_country === "") {
            this.notify("error", "Please select owner's country.");
            return false;
        }

        if (data.owners_state === "") {
            this.notify("error", "Please select owner's state.");
            return false;
        }

        if (data.owners_district === "") {
            this.notify("error", "Please select owner's district.");
            return false;
        }

        if (data.owners_city === "") {
            this.notify("error", "Please enter owner's city.");
            return false;
        }
        if (data.owners_road_name === "") {
            this.notify("error", "Please enter owner's road name.");
            return false;
        }
        if (data.owners_pin_code === "") {
            this.notify("error", "Please enter owner's pin code.");
            return false;
        }
        if (data.owners_village_code === "") {
            this.notify("error", "Please enter owner's village code.");
            return false;
        }
        if (data.owners_address_prrof_type === "") {
            this.notify("error", "Please select owner's address prrof type.");
            return false;
        }
        if (data.owners_qualification === "") {
            this.notify("error", "Please select owner's qualification.");
            return false;
        }
        if (data.owners_additional_course === "") {
            this.notify("error", "Please select owner's additional course.");
            return false;
        }
        if (data.owners_institute_name === "") {
            this.notify("error", "Please enter owner's institute name.");
            return false;
        }
        if (data.owners_date_of_passed === "") {
            this.notify("error", "Please enter owner's passing date.");
            return false;
        }
        this.setState({
            loading: true
        })
        console.log(data)
        Axios.post(apiUrl + "/remittance/ime-forex/csp-registration", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log(res)
            this.setState({
                loading: false
            })
            if (!res.data.success) {
                this.notify("error", res.data.message);
            }
            else {
                this.getDocumentsList(this.state.selected_user.id);
                this.setState({
                    uploadDocumentForm: true,
                })
            }
        }, err => {
            console.log(err)
        })
    }
    loadingState = x => {
        this.setState({
            loading: x
        })
    }
    submitAction = (res) => {
        if (!res.data.success) {
            this.notify("error", res.data.message);
        }
        else {
            this.notify("success", res.data.message);
        }
    }
    getDocumentsList = (id) => {
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/ime-forex/csp/documents/list/user", { user_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(Res => {
            console.log("document response", Res)
            this.setState({
                documents: Res.data.data,
                loading: false
            })
        }, err => {
            this.setState({ loading: false })
        })
    }
    onFileChange = (e, id) => {
        this.setState({ loading: true });
        var formData = new FormData();
        formData.append('image', e.target.files[0]);
        formData.append('id', id);
        formData.append('user_id', this.state.selected_user.id);
        Axios.post(apiUrl + "/remittance/ime-forex/csp/single/document/upload", formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                documents: res.data.data,
                loading: false,
                uploadOther: null
            });
        }, err => {
            this.setState({ loading: false });
        })
    }
    uplaodOtherPh = (e, id) => {
        e.preventDefault();
        this.setState({
            uploadOther: id
        })
    }
    cancelUploadOtherPh = (e, id) => {
        e.preventDefault();
        this.setState({
            uploadOther: null
        })
    }
    submitUpload = e => {
        e.preventDefault();
        this.state.documents.forEach((doc, ki) => {
            if (doc.is_required === "Yes" && !doc.file_path) {
                this.notify("error", `Please upload ${doc.document.value}`);
                return false;
            }
        })
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/ime-forex/csp/documents/upload", { user_id: this.state.selected_user.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({ loading: false });
            if (res.data.success) {
                this.setState({
                    showCspForm: false,
                    uploadDocumentForm: false
                })
                Axios.post(`${apiUrl}/admin/make/dealer`, { user_id: this.state.selected_user.id }, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("_access")
                    }
                }).then(res => {
                    console.log("make dealer response", res)
                    this.getAllUsers(this.state.paginatedata.current_page);
                    if (res.data.success) {
                        this.notify('success', res.data.message);
                    }
                    else {
                        this.notify('error', res.data.message);
                    }
                }, err => {
                    console.log("make dealer error", err)
                    this.notify('error', "Something went wrong.");
                })
            } else {
                this.notify("error", res.data.message)
            }
            console.log("upload documens fil", res)
        })
    }
    editDetailsOtp = (e, det) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/send/otp`, { user_id: det.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                editModal: true,
                otp_verified: false,
                selected_user: det,
                otp_token: res.data.otp_token
            })
        })
    }
    editDetails = (e) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/verify/otp`, { otp: this.state.otp, otp_token: this.state.otp_token }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                var det = this.state.selected_user;
                e.preventDefault();
                this.notify("success", res.data.message)
                this.setState({
                    edit_name: det.name,
                    edit_email: det.email,
                    edit_mobile: det.mobile,
                    edit_district: det.district,
                    edit_city: det.city,
                    edit_state: det.state,
                    edit_country: det.country,
                    edit_pincode: det.pincode,
                    edit_address: det.address,
                    user_id: det.id,
                    editModal: true,
                    otp_verified: true
                })
            }
            else {
                this.notify("error", res.data.message)
            }
        })
    }
    editaDetailsSubmit = e => {
        if (!this.state.edit_name || this.state.edit_name === "") {
            this.notify("error", "Please enter name");
            return false;
        }
        if (!this.state.edit_email || this.state.edit_email === "") {
            this.notify("error", "Please enter email");
            return false;
        }
        if (!this.state.edit_mobile || this.state.edit_mobile === "") {
            this.notify("error", "Please enter mobile number");
            return false;
        }
        var lengthcheck = this.state.edit_mobile.length;
        //  console.log("lenght",lengthcheck);
        if (lengthcheck !== 10) {
            this.notify("error", "Please enter correct mobile number.");
            return false;
        }
        if (!this.state.edit_address || this.state.edit_address === "") {
            this.notify("error", "Please enter address");
            return false;
        }
        if (!this.state.edit_country || this.state.edit_country === "") {
            this.notify("error", "Please enter country");
            return false;
        }
        if (!this.state.edit_state || this.state.edit_state === "") {
            this.notify("error", "Please enter state");
            return false;
        }
        if (!this.state.edit_city || this.state.edit_city === "") {
            this.notify("error", "Please enter city");
            return false;
        }
        if (!this.state.edit_district || this.state.edit_district === "") {
            this.notify("error", "Please enter district");
            return false;
        }
        if (!this.state.edit_pincode || this.state.edit_pincode === "") {
            this.notify("error", "Please enter pincode");
            return false;
        }
        var data = {
            name: this.state.edit_name,
            email: this.state.edit_email,
            mobile: this.state.edit_mobile,
            address: this.state.edit_address,
            country: this.state.edit_country,
            state: this.state.edit_state,
            city: this.state.edit_city,
            district: this.state.edit_district,
            pincode: this.state.edit_pincode,
            user_id: this.state.user_id
        }
        Axios.post(`${apiUrl}/admin/edit/user/details`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.getAllUsers(this.state.paginatedata.current_page);
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    edit_email: "",
                    edit_name: "",
                    edit_mobile: "",
                    edit_address: "",
                    edit_country: "",
                    edit_state: "",
                    edit_city: "",
                    edit_district: "",
                    edit_pincode: "",
                    editModal: false,
                    user_id: null,
                    selected_user: null
                })
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");
        })
    }
    toggleEditModal = () => {
        this.setState({
            editModal: !this.state.editModal,
            edit_email: "",
            edit_name: "",
            edit_mobile: "",
            edit_address: "",
            edit_country: "",
            edit_state: "",
            edit_city: "",
            edit_district: "",
            edit_pincode: "",
            user_id: null,
            selected_user: null,
            otp: ""
        })
    }
    allowMaintanance = (e, user) => {
        var data = {
            user_id: user.id
        }
        if (e.target.checked) {
            if (window.confirm("You are allowing this user to use app in maintanace mode.")) {
                data.panel_access = "full_in_maintanance";
            } else {
                e.target.checked = false;
            }
        }
        else {
            data.panel_access = "full";
        }
        Axios.post(`${apiUrl}/admin/change/maintanance/access`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");
        })
    }
    sendCashback = (e, user = null) => {
        e.preventDefault();
        console.log(user);
        this.setState({
            viewRewardModal: !this.state.viewRewardModal,
            selected_user: user
        })
    }
    submitReward = (e) => {
        e.preventDefault();
        if (this.state.rewardamount === 0) {
            this.notify("error", "Please enter amount.");
            return false;

        }
        if (!this.state.reward_comment || this.state.reward_comment === "") {
            this.notify("error", "Please enter comment.");
            return false;

        }
        var data = {
            user_id: this.state.selected_user.id,
            amount: this.state.rewardamount,
            reward_comment: this.state.reward_comment
        }
        Axios.post(`${apiUrl}/admin/send/cashback`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    selected_user: null,
                    viewRewardModal: false,
                    rewardamount: 0
                });
                this.getAllUsers();
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");

        })
    }

    getCspDetails = (user_id) => {
        this.setState({ loading: true });
        var data = { user_id: user_id }
        Axios.post(apiUrl + "/user/get/csp/form/details", data, this.state.headers).then(res => {
            var det = res.data.details;
            if (det) {
                if (det.owners_country) {
                    this.handleCountryChange(null, 'owners_state', det.owners_country);
                }
                if (det.owners_state) {
                    this.handleInputState(null, 'owners_district', det.owners_state);
                }
                if (det.owners_district) {
                    this.handleInputDistrict(null, 'omunicipality', det.owners_district);
                }
                if (det.country) {
                    this.handleCountryChange(null, 'states', det.country);
                }
                if (det.state) {
                    this.handleInputState(null, 'districts', det.state);
                }
                if (det.district) {
                    this.handleInputDistrict(null, 'municipality', det.district);
                }
                setTimeout(() => {
                    this.setState({
                        documents: res.data.documents,
                        bussiness_type: det.bussiness_type,
                        cspname: det.cspname,
                        pan_number: det.pan_number,
                        csp_country: det.country,
                        csp_state: det.state,
                        csp_district: det.district,
                        municipality: det.municipality,
                        csp_city: det.city,
                        house_number: det.house_number,
                        road_name: det.road_name,
                        pin_code: det.pin_code,
                        address_proof_type: det.address_proof_type,
                        contact_person_name: det.contact_person_name,
                        mobile_number: det.mobile_number,
                        mobile_number2: det.mobile_number2,
                        land_line_number: det.land_line_number,
                        csp_email: det.email,
                        bank: det.bank,
                        csp_bank_account_type: det.bank_account_type,
                        bank_account_number: det.bank_account_number,
                        ifsc: det.ifsc,
                        device: det.device,
                        connectivity_type: det.connectivity_type,
                        provider: det.provider,
                        start_time: det.start_time,
                        end_time: det.end_time,
                        has_week_off: det.has_week_off,
                        off_day: det.off_day,
                        owners_name: det.owners_name,
                        owners_gender: det.owners_gender,
                        owners_dob: det.owners_dob,
                        owners_father_name: det.owners_father_name,
                        owners_spouse_name: det.owners_spouse_name,
                        owners_category: det.owners_category,
                        owners_is_handicapped: det.owners_is_handicapped,
                        owners_mobile_number: det.owners_mobile_number,
                        owners_contact_number: det.owners_contact_number,
                        owners_email: det.owners_email,
                        owners_alternate_occupation: det.owners_alternate_occupation,
                        owners_pan_card: det.owners_pan_card,
                        owners_id_types: det.owners_id_type,
                        owners_id_number: det.owners_id_number,
                        owners_country: det.owners_country,
                        owners_state: det.owners_state,
                        owners_district: det.owners_district,
                        owners_city: det.owners_city,
                        owners_house_number: det.owners_house_number,
                        owners_road_name: det.owners_road_name,
                        owners_pin_code: det.owners_pin_code,
                        owners_village_code: det.owners_village_code,
                        owners_address_prrof_type: det.owners_address_prrof_type,
                        owners_qualification: det.owners_qualification,
                        owners_additional_course: det.owners_additional_course,
                        owners_institute_name: det.owners_institute_name,
                        owners_date_of_passed: det.owners_date_of_passed,
                        loading: false,
                        registration_type: det.registration_type,
                        load_form: true,
                        csp_status: det.status,
                        upgrade_type: "agent",
                        remittance_type: "IME",
                        submit_status: det.status
                    });
                }, 4000);
            } else {
                this.setState({ loading: false, load_form: true });
            }
        }, err => {
            this.setState({ loading: false, load_form: true });
        })
    }
    backBtnCsp = e => {
        if (e) e.preventDefault();
        this.getCspDetails(this.state.selected_user.id);
        this.setState({ uploadDocumentForm: false })
    }
    viewDocs = (e) => {
        e.preventDefault();
        this.getDocumentsList(this.state.selected_user.id);
        this.setState({ uploadDocumentForm: true })

    }
    uploadUrl = (e, doc) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formData = new FormData();
        formData.append('update_url', doc.file_path);
        formData.append('id', doc.id);
        formData.append('user_id', this.state.selected_user.id);
        Axios.post(apiUrl + "/remittance/ime-forex/csp/single/document/upload", formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                documents: res.data.data,
                loading: false,
                uploadOther: null
            });
        }, err => {
            this.setState({ loading: false });
        })
    }

    displayStatic = (val, name) => {
        var value = val;
        if (this.state[name]) {
            this.state[name].forEach((cou) => {
                if (val === cou.option_id) {
                    value = cou.value;
                }
            })
        }
        return value;
    }
    makeAgentWithoutIME = e => {
        if (e) e.preventDefault();
        swal({ title: "Make Agent", text: `Do you want to make ${this.state.name} an agent.`, buttons: ['No', 'Yes'] }).then(yes => {
            if (yes) {
                Axios.post(apiUrl + "/admin/make/agent/without/ime", { user_id: this.state.id, payment_method: this.state.payment_method_agent }, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("_access")
                    }
                }).then(res => {
                    if (res.data.success) {
                        this.notify('success', res.data.message);
                        setTimeout(() => {
                            // window.location.reload();
                            this.getAllUsers(this.state.paginatedata.current_page);
                        }, 1000);
                    }
                    else {
                        this.notify('error', res.data.message);
                    }
                })
            }
        })
    }
    handlePaymentMethod = (e, method) => {
        this.setState({ payment_method_agent: method });
    }
    approveUser = (e, user) => {
        if (e) e.preventDefault();
        swal({ title: "Approve " + user.name + "!", text: "Do you want to approve this user?", buttons: ["No", "Yes"] }).then(yes => {
            if (yes) {
                Axios.post(apiUrl + "/admin/approve/new/user", { user_id: user.id }, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("_access")
                    }
                }).then(res => {
                    if (res.data.success) {
                        this.notify('success', res.data.message);
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 1000);
                        this.getAllUsers(this.state.paginatedata.current_page);

                    }
                    else {
                        this.notify('error', res.data.message);
                    }
                }, err => {
                    this.notify('error', "Something went wrong!");
                })
            }
        })
    }
    render() {

        return (
            <>
                {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
                <div className="content">

                    <div className="react-notification-alert-container notification_alert_con">
                        <NotificationAlert ref="notificationAlert" />
                    </div>

                </div>


            </>
        );
    }
}

export default Users;
