import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";
import { CSVLink } from "react-csv";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import { Pagination } from 'react-laravel-paginex';
import env from "variables/constants";
const moment = require('moment');
var apiUrl = env.url;
var imageUrl = env.img_url;
class Addmoneyhistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            start_date: "",
            end_date: "",
            keyword: "",
            receipt: "",
            exceldata: [],
            allData:[],
            loading: false,
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 15,
                prev_page_url: null,
                to: 0,
                total: 0
            },
        }
    }
    componentDidMount = () => {
        this.getList();
    }
    changePage = data => {

        console.log(data)
        this.getList(data.page);
    }
    getList = (page = 1) => {
        this.setState({
            loading: true
        })
        var data = {};
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.keyword && this.state.keyword !== "") {
            data.keyword = this.state.keyword;
        }
        if (this.state.receipt && this.state.receipt !== "") {
            data.receipt = this.state.receipt;
        }
        Axios.post(`${apiUrl}/admin/add/money/history?page=${page}`, data, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
        }).then(res => {
            if (res.data.success) {
                // this.getListForExcel();
                this.setState({
                    paginatedata: res.data.data.list,
                    list: res.data.data.list.data,
                    allData: res.data.data.allData,
                    // exceldata: [],
                    loading: false
                })
                setTimeout(() => {
                    var exceldata = [];
                    this.state.allData.forEach((val, key) => {
                        var dat = {
                            "Date": moment(val.created_at).format('DD-MMM-YYYY h:mm a'),
                            "Deposit Date": moment(val.deposit_date).format('DD-MMM-YYYY h:mm a'),
                            "Name": val.user.name,
                            "Mobile": val.user.mobile,
                            "Amount": val.amount,
                            "Deposit Via": val.deposit_via,
                            "Receipt No.": val.reciept_number,
                            "Bank Name": val.bank_name,
                            "Description": val.description,
                            "Status": val.status,
                            "Checked By": val.checked ? val.checked.name : "",
                            "Approved By": val.approve ? val.approve.name : "",
                            "Cancelled By": val.cancel ? val.cancel.name : ""
                        }
                        exceldata.push(dat);
                    });
                    this.setState({
                        exceldata: exceldata,
                    })
                }, 200);
            }
        }, err => {
            this.setState({
                loading: false
            })
            console.log("addmoney error", err)
        })
    }

    getListForExcel = () => {
        var data = {};
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.keyword && this.state.keyword !== "") {
            data.keyword = this.state.keyword;
        }
        if (this.state.receipt && this.state.receipt !== "") {
            data.receipt = this.state.receipt;
        }
        Axios.post(`${apiUrl}/admin/add/money/history/excel`, data, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
        }).then(res => {
            console.log("excel data response", res)
            if (res.data.success) {
                var exceldata = [];
                res.data.data.forEach((val, key) => {
                    var dat = {
                        "Date": moment(val.created_at).format('DD-MMM-YYYY h:mm a'),
                        "Deposit Date": moment(val.deposit_date).format('DD-MMM-YYYY h:mm a'),
                        "Name": val.user.name,
                        "Mobile": val.user.mobile,
                        "Amount": val.amount,
                        "Deposit Via": val.deposit_via,
                        "Receipt No.": val.reciept_number,
                        "Bank Name": val.bank_name,
                        "Description": val.description,
                        "Status": val.status,
                        "Checked By": val.checked ? val.checked.name : "",
                        "Approved By": val.approve ? val.approve.name : "",
                        "Cancelled By": val.cancel ? val.cancel.name : ""
                    }
                    exceldata.push(dat);
                });
                this.setState({
                    exceldata: exceldata,
                })
            }
        }, err => {
            console.log("addmoney error", err)
        })
    }

    // approveRequest = (e, id) => {
    //     e.preventDefault();
    //     Axios.post(`${apiUrl}/admin/approve/money/request`, { req_id: id }, {
    //         headers: {
    //             Authorization: "Bearer " + sessionStorage.getItem('_access')
    //         }
    //     }).then(res => {
    //         if (res.data.success) {
    //             this.getList();
    //             this.notify("success", res.data.message);
    //         } else {
    //             this.notify("error", res.data.message);
    //         }
    //     }, err => {
    //         console.log("approve error", err)
    //     })
    // }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    refundAmount = (e, val) => {
        e.preventDefault();
        console.log(val)
        var data = {
            bank_rrn: val.reciept_number,
            user_id: val.user.id
        }
        Axios.post(`${apiUrl}/upi/refund/amount`, data, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
        }).then(res => {
            console.log(res)
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.getList(this.state.paginatedata.current_page);
            }
            else {
                this.notify("error", res.data.message);
            }
        })
    }
    render() {
        return (
            <>
                {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Add Money History
                                        <small className="float-right">
                                            {this.state.exceldata.length > 0 && <CSVLink data={this.state.exceldata} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"Agent-commission-reports-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}
                                            {this.state.exceldata.length === 0 && <a href="" onClick={(e) => { e.preventDefault() }} style={{ color: "#d4d6d5", background: "none", padding: "10px 8px", borderRadius: "0px", pointerEvents: "none" }} title="Export to CSV"><i className="fa fa-spinner fa-spin"></i>Excel</a>}
                                        </small>
                                    </CardTitle>
                                    <div className="row userhistoryrem">
                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Keyword (Name / Mobile No.)</label>
                                            <input type="text" name="keyword" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.keyword} id="keyword" />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Receipt No.</label>
                                            <input type="text" name="receipt" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.receipt} id="receipt" />
                                        </div>
                                        <div className="col-md-2">
                                            <label>{" "}</label>
                                            <input type="button" name="submit" className="btn btn-primary searchhistory" onClick={(e) => { this.getList() }} value="Search" />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Date</th>
                                                <th>Deposit Date</th>
                                                <th>Name</th>
                                                <th>Mobile No.</th>
                                                <th>Amount</th>
                                                <th>Deposit Via</th>
                                                <th>Image</th>
                                                <th>Reciept No.</th>
                                                <th>Bank Name</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>Checked by</th>
                                                <th>Cancel by</th>
                                                <th>Approved by</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.list.map((val, key) => {
                                                return <tr>
                                                    <td className="date_nar">{moment(val.created_at).format('DD-MMM-YYYY h:mm a')}</td>
                                                    <td className="date_nar">{moment(val.deposit_date).format('DD-MMM-YYYY')}</td>

                                                    <td>{val.user ? val.user.name : ""}</td>
                                                    <td>{val.user ? val.user.mobile : ""}</td>
                                                    <td>&#8377;{val.amount}</td>
                                                    <td>{val.deposit_via}</td>
                                                    <td className="wallethistoryimg">
                                                        {val.image && <img src={imageUrl + val.image} alt=" " style={{ width: "50px" }} />}
                                                    </td>
                                                    <td>{val.reciept_number}</td>
                                                    <td>{val.bank_name}</td>
                                                    <td>{val.description}</td>
                                                    <td>{val.status}</td>
                                                    <td>{val.checked ? val.checked.name : ""}</td>
                                                    <td>{val.cancel ? val.cancel.name : ""}</td>
                                                    <td>{val.approve ? val.approve.name : ""}</td>
                                                    <td>
                                                        {val.status === "approved" && <a href="" onClick={e => { this.refundAmount(e, val) }} >Refund</a>}
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Addmoneyhistory;
