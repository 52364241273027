import React, { useEffect, useState, componentDidMount } from "react";
import PhoneInput from "react-phone-number-input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import { State, City } from "country-state-city";
import Userfooter from '../Footer';
import Userheader from '../Header';
import Usersidebar from '../Usersidebar';
import defaultImg from '../../../assets/img/userIconPic.svg';
import indianflag from '../../../assets/img/indianflag.png';
import nepalflag from '../../../assets/img/nepalflag.png';
import companyLogo from "assets/images/new/online-sath-logo-new.png";
import UserCameraIcon from '../../../assets/img/UserCameraIcon.svg'
import axios from "axios";
import Razorpay from "razorpay";

const apiUrl = process.env.REACT_APP_API_URL_LIVE; 
const imgApiUrl = process.env.REACT_APP_IMAGE_URL_LIVE;

const BecomeMember = () => {
  const [step, setStep] = useState(0);
  const [countryelected, setcountryelected] = useState();
  const [States, setStates] = useState(State?.getStatesOfCountry("IN"));
  const [Cities, setCities] = useState(City?.getCitiesOfState("IN", "DL"));
  const [photo, setPhoto] = useState(null);
  const [picture, setPicture] = useState({});
  const [proof, setProof] = useState(null);
  const [errors, setErrors] = useState({});

  const [rzp, setRzp] = useState(null);
  const [payNowClicked, setPayNowClicked] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState({ razorpay_payment_id: null, payment_id: null, payment_status: null, amount: null });
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [storedRazorpayPaymentId, setStoredRazorpayPaymentId] = useState('');

  const [imageId, setImageId] = useState('');


  const [formData, setFormData] = useState({
    membership_id: "",
    application_number:"",
    receipt_number: "",
    photo: "",
    mobile: "",
    email: "",
    name: "",
    post: "",
    education: "",
    occupation: "",
    dob: "",
    gender: "",
    marital_status: "",
    nationality: "",
    country: "",
    state: "",
    city: "",
    address: "",
    pincode: "",
    municipality: "",
    wardnumber: "",
    proof: "",
    uploadbackproofId: "",
    beneficiary: "",
    monthly_income: "",
    checkbox: "",
    amount: "",
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFormData(prevData => ({
        ...prevData,
        membership_id: generateRandomMembershipId(),
        application_number: generateRandomApplicationnumber(),
        receipt_number: generateRandomReceiptnumber(),
        country: countrySeleceted(),
      }));
    }, 8000); // 5000 milliseconds = 5 seconds
  
    // Clear the timeout to avoid side effects if the component unmounts before the timeout completes
    return () => clearTimeout(timeoutId);
  }, []);
  

  const generateRandomMembershipId = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000);
  };

  const generateRandomApplicationnumber = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000);
  };

  const generateRandomReceiptnumber = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000);
  };

  const countrySeleceted = () => {
    return (formData.nationality === "Nepal") ? "Nepal" : "India"
  }

  const profilepicturehandler = (e) => {
    console.log(e.target.files);
    const blob = new Blob(e.target.files);
    const blobUrl = URL.createObjectURL(blob);
    setPhoto(blobUrl);
    setFormData({
      ...formData,
      photo: e.target.files,
    });
    setErrors({
      ...errors,
      photo: "",
    });
  };

  const userIdentityHandler = (e) => {
    console.log(e.target.files);
    setFormData({
      ...formData,
      proof: e.target.files[0],
    });
    console.log("set formmmmmm",formData )
    setErrors({
      ...errors,
      proof: "",
    });
  };

  const clickCountryelected = () => {
    setcountryelected(formData.nationality)
  }

  const clickCountryelectedNepal = () => {
    setcountryelected(formData.nationality)
  }

  const hndlechangechecked = (e) => {
    // console.log(e.target.checked);
    if (e.target.checked === true) {
      setFormData({
        ...formData,
        checkbox: e.target.checked,
      });
      setErrors({
        ...errors,
        checkbox: "",
      });
    } else if (e.target.checked === false) {
      setFormData({
        ...formData,
        checkbox: false,
      });
      setErrors({
        ...errors,
        checkbox: "This fields required",
      });
    }
  };


  const handleChangeState = (e) => {
    if (e) {
      setFormData({
        ...formData,
        state: e,
      });
      const list = States.filter((list) => list.name === e);
      setCities(
        City.getCitiesOfState(
          list[0].countryCode.toString(),
          list[0].isoCode.toString()
        )
      );
      setErrors({
        ...errors,
        state: "",
      });
    } else {
      setFormData({
        ...formData,
        state: "",
      });
      setErrors({
        ...errors,
        state: "This field is required",
      });
    }
    const list = States.filter((list) => list.name === e);
    setCities(
      City.getCitiesOfState(
        list[0].countryCode.toString(),
        list[0].isoCode.toString()
      )
    );
  };
  const handleChangeCity = (e) => {
    console.log(e);
    if (e) {
      setFormData({
        ...formData,
        city: e,
      });
      setErrors({
        ...errors,
        city: "",
      });
    } else {
      setFormData({
        ...formData,
        city: "",
      });
      setErrors({
        ...errors,
        city: "This field is required",
      });
    }
  };


  const handleDateChange = (value) => {
    if (value) {
      setFormData({
        ...formData,
        dob: value,
      });
      setErrors({
        ...errors,
        dob: "",
      });
    } else {
      setFormData({
        ...formData,
        dob: "",
      });
      setErrors({
        ...errors,
        dob: "This field is required",
      });
    }
  };

  useEffect(() => {
    if (step !== 0) {
      validateStep(step);

    } else {
      setStep(1)
    }
  }, [formData])

  const validateStep = (step) => {
    const errors = {};
    const numberRegex = /^-?\d+(\.\d+)?$/;
    const digitRegex = /\d/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    switch (step) {
      case 1:
        if (formData.photo === '' || formData.photo === null) {
          errors.photo = "This field is required.";
        } else {
          delete errors.photo;
        }
        if (formData.mobile?.trim() === "") {
          errors.mobile = "This field is required.";
        } else if (formData.mobile.length > 14) {
          errors.mobile = "Number should be less then 10 char";
        } else {
          delete errors.mobile;
        }
        if (formData.email?.trim() === "") {
          errors.email = "This field is required.";
        } else if (!emailRegex.test(formData.email)) {
          errors.email = "Invalid email format.";
        } else {
          delete errors.email;
        }

        if (formData.name?.trim() === "") {
          errors.name = "This field is required.";
        } else if (digitRegex.test(formData.name)) {
          errors.name = "Numbers are not allowed in this field.";
        } else if (formData.name.length > 30) {
          errors.name = "Letter should be less then 30 char";
        } else {
          delete errors.name;
        }
        if (formData.post?.trim() === "") {
          errors.post = "This field is required.";
        } else if (formData.post.length > 30) {
          errors.post = "Letter should be less then 30 char";
        } else {
          delete errors.post;
        }
        if (formData.education?.trim() === "") {
          errors.education = "This field is required.";
        } else {
          delete errors.education;
        }
        if (formData.occupation?.trim() === "") {
          errors.occupation = "This field is required.";
        } else {
          delete errors.occupation;
        }
        if (formData.dob === "") {
          errors.dob = "This field is required.";
        } else {
          delete errors.dob;
        }
        if (formData.gender?.trim() === "") {
          errors.gender = "This field is required.";
        } else {
          delete errors.gender;
        }
        if (formData.marital_status?.trim() === "") {
          errors.marital_status = "This field is required.";
        } else {
          delete errors.marital_status;
        }
        if (formData.nationality?.trim() === "") {
          errors.nationality = "This field is required.";
        } else {
          delete errors.nationality;
        }

        break;
      case 2:
        if (formData.nationality === "India") {
          if (formData.state?.trim() === "") {
            errors.state = "This field is required.";
          } else {
            delete errors.state;
          }
          if (formData.city?.trim() === "") {
            errors.city = "This field is required.";
          } else {
            delete errors.city;
          }
          if (formData.address?.trim() === "") {
            errors.address = "This field is required.";
          } else {
            delete errors.address;
          }
          if (formData.pincode?.trim() === "") {
            errors.pincode = "This field is required.";
          } 
          // else if (numberRegex.test(formData.pincode)) {
          //   errors.pincode = "Enter valid pincode";
          // }
           else {
            delete errors.pincode;
          }
        }
        else {
          if (formData.nationality === "Nepal") {
          if (formData.municipality?.trim() === "") {
            errors.municipality = "This field is required.";
          } else {
            delete errors.municipality;
          }
          if (formData.wardnumber?.trim() === "") {
            errors.wardnumber = "This field is required.";
          } else {
            delete errors.wardnumber;
          }
        }
        }
        break;
        
      case 3:
        if (
          formData.beneficiary?.trim() === "" ||
          formData.beneficiary === undefined
        ) {
          errors.beneficiary = "This field is required.";
        } else {
          delete errors.beneficiary;
        }

        if (formData.proof?.trim() === "") {
          errors.proof = "This field is required.";
        } else {
          delete errors.proof;
        }
        if (formData.uploadbackproofId?.trim() === "") {
          errors.uploadbackproofId = "This field is required.";
        } else {
          delete errors.uploadbackproofId;
        }

        if (formData.monthly_income?.trim() === "" ||  
          formData.monthly_income === undefined
          ) {
          errors.monthly_income = "This field is required.";
        } else {
          delete errors.monthly_income;
        }

        if (formData.checkbox === false) {
          errors.checkbox = "This field is required.";
        } else {
          delete errors.checkbox;
        }
        break;
      default:
        break;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateStep(step)
    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   [name]: "",
    // }));
  };

  const uploadPicture = (e) => {
    try {
      const selectedFile = e.target.files[0];
  
      console.log("Selected File:", selectedFile);
  
      // Update FormData
      setFormData((prevData) => ({
        ...prevData,
        proof: selectedFile,
      }));
  
      if (selectedFile) {
        setPicture({
          picturePreview: URL.createObjectURL(selectedFile),
          pictureAsFile: selectedFile,
        });
  
        console.log("Picture state updated:", {
          picturePreview: URL.createObjectURL(selectedFile),
          pictureAsFile: selectedFile,
        });
      } else {
        console.error("No file selected");
      }
  
      console.log("FormData updated:", { proof: selectedFile });
  
      validateStep(step);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };


  // const handleFileUpload = (event) => {

  //   // get the selected file from the input
  //   const file = event.target.files[0];

  //   // create a new FormData object and append the file to it
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   axios.post(`${apiUrl}/uploads/member`, formData,{
  //     headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }}).then(res=>{
  //     console.log(res);
  //   })
  //   .catch((err)=>{
  //     console.log("errr", err);
  //   });
  // };

  const handlePhoneNumberChange = (value) => {
    console.log(value);
    if (value) {
      setFormData({
        ...formData,
        mobile: value,
      });
      setErrors({
        ...errors,
        mobile: "",
      });
    } else {
      setFormData({
        ...formData,
        mobile: "",
      });
      setErrors({
        ...errors,
        mobile: "This field is required",
      });
    }
  };


  const handlePaymentSuccess = (response) => {

    alert('Payment successful! Payment ID: ' + response.razorpay_payment_id);
    // for handle the success response here

    sessionStorage.setItem('razorpay_payment_id_session', response.razorpay_payment_id);
  
    setIsPaymentSuccessful(true);
    console.log('Payment successful! Payment ID:', response.razorpay_payment_id);
    console.log("vvvvvvvvvvvvvvvvvvvv", sessionStorage.getItem('razorpay_payment_id_session'))

    var za = null
    var payment_status = response.razorpay_payment_id ? "successfull" : "faild"
    var amount = options.amount ? (options.amount)/100 : "Free"

    if(response.razorpay_payment_id){
      abc(response.razorpay_payment_id, za, payment_status, amount)
    }
  
  };

const handlePaymentFailure = (error) => {
  alert('Payment failed. Please try again.')
      setIsPaymentSuccessful(false);
  console.error('Payment failed! Error details:', error);
  // Additional error handling logic

  if (error.code === 'PAYMENT_FAILED') {
    setErrors((prevErrors) => ({
      ...prevErrors,
      paymentFailed: 'Payment failed. Please try again.',
    }));
  }
};

const options = {
  key: 'rzp_test_tYQzj7eoqvt0RZ', // Razorpay Key ID
  amount: formData.amount ? formData.amount : 100, // Amount in paise (20000 paise = ₹200)
  name: 'Online Saathi',
  description: 'Payment for your service',
  image: "https://play-lh.googleusercontent.com/QD0rI-xtHDXNtYUaFSoFhdfT4GIRbSBDt4WHk_YzvmDO6Np6LOeHZVRUB0_Kg-NlKcw",
  prefill: {
      name: `${formData.name}`,
      contact: `${formData.mobile}`,
  },
  theme: {
      color: '#F37254', // Customize the color theme
  },
  handler: handlePaymentSuccess, handlePaymentFailure
};

useEffect(() => {
  const script = document.createElement('script');
  script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  script.async = true;
  script.onload = handleScriptLoad;
  document.body.appendChild(script);

  return () => {
      // Cleanup script on component unmount
      document.body.removeChild(script);
  };
}, []);

const handleScriptLoad = () => {
  const razorpayInstance = new window.Razorpay(options);
  setRzp(razorpayInstance);
};

const handlePayNow = () => {
  if (rzp) {
    rzp.open();

    setPayNowClicked(true);
    setErrors((prevErrors) => ({ ...prevErrors, payNow: undefined }));

  }
};

function abc(payment_id, payment_status, amount){
 
 var RequestData = ""

  var ccc = JSON.parse(sessionStorage.getItem("data"))
  
  ccc.payment_id = payment_id ? payment_id : "payment failed"
  ccc.payment_status = payment_id || options.amount < 1 ? "successfull" : "Free"
  ccc.amount = options.amount ? (options.amount)/100 : "Free"

  if(payment_id || options.amount === 0){

    const formDataa = new FormData();
    formDataa.append("file", picture.pictureAsFile);

    console.log("pictureeeeeeeeeee",picture.pictureAsFile);

    for (var key of formDataa.entries()) {
      console.log(key[0] + ", " + key[1]);
    }


    axios.post(`${apiUrl}/member/form/web`, ccc, formData,{
      headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }})
      .then(res=>{
      console.log(res);
    })
    .catch((err)=>{
      console.log("errr", err);
    });

  sessionStorage.removeItem('razorpay_payment_id_session');
  sessionStorage.removeItem('data');
  alert("form submitted successfully")
}

  console.log("payment idddd", payment_id)
  console.log('payment successssssssssssss:', ccc);

}

const handleSubmit = (e) => {

  e.preventDefault();

  if (step < 4 && validateStep(step)) {
    setStep(step + 1);
  }

  else {

    const razorpayPaymentId = sessionStorage.getItem('razorpay_payment_id_session');
    const paymentStatus = sessionStorage.getItem('razorpay_payment_id_session') ? "successfull" : "faild"
    console.log("fffffffff", razorpayPaymentId, paymentStatus)
    
    if (step === 4 ) {
      const y = {...formData}
      sessionStorage.setItem("data",JSON.stringify(y));
      
      var z = ""

      var bbc = abc(z, y)
    }  
    
  }
};

const onbtnClick = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
  setErrors((prevErrors) => ({
    ...prevErrors,
    [name]: "",
  }));
};

const getdata =()=>{
console.log(sessionStorage);
  axios.post(`${apiUrl}/admin/remittance/ime-forex/get/datamunicipality`, {
    headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
  })
    .then(res => {
      console.log(res)
      console.log(sessionStorage);
    
    })
    .catch(err => {
      console.log(err);
     
    });
};


useEffect(()=>{
getdata();
},[])

  return (
    <>
      <Userheader />
          {/* <div className="the-loader">
              <div className="spinner-border">
                  <span className="sr-only">Loading...</span>
              </div>
              </div> */}
      <section className="main-sidebar_dash">
        <div className="container-fluid">
          <div className="row">
            <Usersidebar />
            <div className="col-md-10 right_contentsec">
              <div className="maine-table-dash-sec">
                <div className="heading-title-sec">
                  <h3 className="admin-heading bg-offwhite">
                    <p>Become An Member</p>
                  </h3>
                </div>
                <div className="container">
                  <div className="row center-div">
                    <div className="col-md-12">
                      <div className="card" id="become-membership-form">
                        <h1 className="text-center mt-4" id="become-membership-h1">Membership Form</h1>
                        <div class="step-container">
                          <div class={[1, 2, 3, 4].includes(step) ? "step active" : "step"}>
                            <p className="text-dark mt-5 mb-0">Basic information</p>
                          </div>
                          <div
                            class={[2, 3, 4].includes(step) ? "line bg-primary" : "line"}>
                          </div>
                          <div class={[2, 3, 4].includes(step) ? "step2 active" : "step2"}>
                            <p className="text-dark mt-5 mb-0">Address</p>
                          </div>
                          <div
                            class={[3, 4].includes(step) ? "line bg-primary" : "line"}>
                          </div>
                          <div class={[3, 4].includes(step) ? "step3 active" : "step3"}>
                            <p className="text-dark mt-5 mb-0">Documents</p>
                          </div>
                        </div>
                      </div>

                       
                      <div className="card p-4" id="become-membership-container">
                        <form onSubmit={handleSubmit}>
                          {step === 1 && (
                            <div>
                              <div class="panel panel-default">
                                <div class="panel-body">
                                  <label
                                    for="fileInput"
                                    className=" d-flex justify-content-center"
                                  >
                                    <div className="img-circle profile-avatar" id="userProfileDiv"> 
                                      <img
                                        src={photo ? photo : defaultImg}
                                        id="imgprofile"
                                        alt="User avatar"
                                      />
                                      { photo === null && (
                                          <img
                                          src={UserCameraIcon}
                                          id="imgprofieSecond"
                                          alt="User avatar"
                                        />
                                      )}
                                    </div>
                                  </label>
                                  <input
                                    name='photo'
                                    type="file"
                                    id="fileInput"
                                    hidden
                                    // value={formData.photo}
                                    onChange={profilepicturehandler}
                                    accept=".png, .jpeg, .jpg"
                                  />
                                </div>
                                <div>
                                  {errors.photo && (
                                    <p className="error text-center">{errors.photo}</p>
                                  )}
                                </div>
                              </div>

                                <div style={{display:"none"}}>
                                  <label>Membership id</label>
                                      <input
                                        type="number"
                                        name="membership_id"
                                        value={formData.membership_id}
                                        onChange={handleInputChange}
                                        id="form-mobile-input"
                                        readOnly
                                      />
                                </div>
                                <div style={{display:"none"}}>
                                  <label>Application number</label>
                                      <input
                                        type="number"
                                        name="application_number"
                                        value={formData.application_number}
                                        onChange={handleInputChange}
                                        id="form-mobile-input"
                                        readOnly
                                      />
                                </div> 
                                <div style={{display:"none"}}>
                                  <label>Application number</label>
                                      <input
                                        type="number"
                                        name="receipt_number"
                                        value={formData.receipt_number}
                                        onChange={handleInputChange}
                                        id="form-mobile-input"
                                        readOnly
                                      />
                                </div>
                              <div className="row" id="Become-Membership-Row">
                                <div class="col-md-6">
                                  <div>
                                    <label htmlFor="mobile fw-bold">Mobile Number</label>
                                  </div>
                                  <div className="phone-input-container" id="phoneInput">
                                    <div className="d-grid justify-content-center">
                                      <PhoneInput
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handlePhoneNumberChange}
                                        id="form-mobile-input"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                      {errors.mobile && (
                                        <p className="error">{errors.mobile}</p>
                                      )}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                  <div>
                                    <label htmlFor="email fw-bold">Email</label>
                                  </div>
                                  <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder="exampe@gmail.com"
                                    className="form-control"
                                  />
                                  <div>
                                    {errors.email && <p className="error">{errors.email}</p>}
                                  </div>
                                </div>
                              </div>
                              <div className="row" id="Become-Membership-Row">
                                <div class="col-md-6">
                                  <div>
                                    <label htmlFor="name fw-bold">Full Name</label>
                                  </div>
                                  <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder="full name"
                                    className="form-control"
                                  />
                                  <div>
                                    {errors.name && (
                                      <p className="error">{errors.name}</p>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div>
                                    <label htmlFor="post fw-bold">Post</label>
                                  </div>
                                  <input
                                    type="text"
                                    name="post"
                                    value={formData.post}
                                    onChange={handleInputChange}
                                    placeholder="post"
                                    className="form-control"
                                  />
                                  <div>
                                    {errors.post && <p className="error">{errors.post}</p>}
                                  </div>
                                </div>
                              </div>
                              <div className="row" id="Become-Membership-Row">
                                <div className="col-md-6">
                                  <div>
                                    <label htmlFor="education fw-bold">Education</label>
                                  </div>
                                  <select
                                    style={{ width: "200px" }}
                                    name="education"
                                    id="selectOption"
                                    value={formData.education}
                                    onChange={handleInputChange}
                                    className="form-control w-100"
                                  >
                                    <option value="">Select...</option>
                                    <option value="primary">Primary</option>
                                    <option value="secondary"> Secondary</option>
                                    <option value="high-secondary">High Secondary</option>
                                    <option value="graduate-drop-out">
                                      Graduate Drop Out
                                    </option>
                                    <option value="under-graduation">
                                      Under Graduation
                                     </option>
                                    <option value="post-graduate"> Post Graduate</option>
                                  </select>
                                  <div>
                                    {errors.education && (
                                      <p className="error">{errors.education}</p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div>
                                    <label htmlFor="occupation fw-bold">Occupation</label>
                                  </div>
                                  <select
                                    style={{ width: "200px" }}
                                    name="occupation"
                                    id="occupation"
                                    value={formData.occupation}
                                    onChange={handleInputChange}
                                    className="form-control w-100"
                                  >
                                    <option value="">Select...</option>
                                    <option value="self-emloyed"> Self Employed</option>
                                    <option value="small-business">Small Business</option>
                                    <option value="hospitality-restaurant">
                                      Hospitality Restaurant
                                    </option>
                                    <option value="company&factory">
                                      Company & Factory
                                    </option>
                                    <option value="househelp&security">
                                      Househelp & Security
                                    </option>
                                    <option value="building&construction">
                                      Building & Construction
                                     </option>
                                    <option value="daily-wager-earner">
                                      Daily Wager Earner
                                    </option>
                                    <option value="public-transport">Public Transport</option>
                                    <option value="farmer">Farmer</option>
                                    <option value="asha-worker/comminity-worket">
                                      Asha Worker/Community Worker
                                     </option>
                                    <option value="others">Others</option>
                                  </select>
                                  <div>
                                    {errors.occupation && (
                                      <p className="error">{errors.occupation}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row" id="Become-Membership-Row">
                                <div className="col-md-6">
                                  <div>
                                    <label htmlFor="dob fw-bold">Date of Birth</label>
                                  </div>
                                  <div id="phoneInput">
                                    <FontAwesomeIcon
                                      icon={faCalendarAlt}
                                      className="calendar-icon"
                                    />
                                    
                                    <DatePicker
                                      name="dob"
                                      selected={formData.dob}
                                      onChange={handleDateChange}
                                      dateFormat="dd-MM-yyyy"
                                      isClearable
                                      placeholderText="dd-MM-yyyy"
                                      className="form-control"
                                      id="react-date-picker-id"
                                    />                                   
                                  </div>
                                    <div>
                                      {errors.dob && <p className="error">{errors.dob}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="">
                                    <label htmlFor="gender fw-bold">Gender</label>
                                  </div>
                                  <div className="flex flex-wrap gap-2 ">
                                    <input
                                      name="gender"
                                      value="Male"
                                      type="button"
                                      onClick={onbtnClick}
                                      className={
                                        formData.gender === "Male"
                                          ? "p-button-text btn-active "
                                          : "p-button-text"
                                      }
                                    />
                                    <input
                                      name="gender"
                                      value="Female"
                                      type="button"
                                      onClick={onbtnClick}
                                      className={
                                        formData.gender === "Female"
                                          ? "p-button-text btn-active "
                                          : "p-button-text"
                                      }
                                      label="Female"
                                    />
                                    <input
                                      name="gender"
                                      value="Other"
                                      type="button"
                                      onClick={onbtnClick}
                                      className={
                                        formData.gender === "Other"
                                          ? "p-button-text btn-active "
                                          : "p-button-text"
                                      }
                                      label="Other"
                                    />
                                  </div>
                                  <div>
                                    {errors.gender && (
                                      <p className="error">{errors.gender}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row" id="Become-Membership-Row">
                                <div className="col-md-6">
                                  <div className="">
                                    <label htmlFor="marital_status fw-bold">
                                      Marital Status
                                     </label>
                                  </div>
                                  <div className="flex flex-wrap gap-2">
                                    <input
                                      name="marital_status"
                                      value="Married"
                                      onClick={onbtnClick}
                                      className={
                                        formData.marital_status === "Married"
                                          ? "p-button-text p-button-text btn-active"
                                          : "p-button-text p-button-text "
                                      }
                                      label="Married"
                                      type="button"
                                    />
                                    <input
                                      name="marital_status"
                                      type="button"
                                      value="Unmarried"
                                      onClick={onbtnClick}
                                      className={
                                        formData.marital_status === "Unmarried"
                                          ? "p-button-text p-button-text btn-active"
                                          : "p-button-text p-button-text"
                                      }
                                      label="Unmarried"
                                    />
                                  </div>
                                  <div>
                                    {errors.marital_status && (
                                      <p className="error">{errors.marital_status}</p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="">
                                    <label htmlFor="nationality fw-bold">nationality</label>
                                  </div>
                                  <div className="d-flex gap-2">

                                    <div className="nationalityInputImgDiv">
                                      <img src={indianflag} className="flag" alt="..." id="indianFlagIMG"/>  
                                      <input
                                      name="nationality"
                                      value="India"
                                      type="button"
                                      onClick={onbtnClick}
                                      className={
                                        formData.nationality === "India"
                                          ? "p-button-text p-button-text btn-active"
                                          : " p-button-text p-button-text"
                                      }
                                      label="India"
                                      id="indiaInput"
                                      />
                                    </div>

                                    <div className="nationalityInputImgDiv"> 
                                      <img src={nepalflag} className="flag" alt="..." id="indianFlagIMG"/>  
                                      <input
                                        type="button"
                                        value="Nepal"
                                        name="nationality"
                                        onClick={onbtnClick}
                                        className={
                                          formData.nationality === "Nepal"
                                            ? "p-button-text p-button-text btn-active"
                                            : "p-button-text p-button-text"
                                        }
                                        label="Nepal"
                                        id="indiaInput"
                                      />
                                    </div>

                                  </div>
                                  <div>
                                    {errors.nationality && (
                                      <p className="error">{errors.nationality}</p>
                                    )}
                                  </div>
                                </div>
                              </div>

                            </div>
                          )}
                          {step === 2 && (
                            <div>
                              <h2>Step 2</h2>
                              <div className="mt-2">
                                
                                  <div className="d-flex gap-2">

                                  <div>
                                     <label htmlFor="nationality fw-bold">Country</label> <br />

                                  <div className="d-flex gap-2">
                                    <div className="nationalityInputImgDiv">
                                      <img src={indianflag} className="flag" alt="..." id="indianFlagIMG"/>  
                                      <input
                                      name="country"
                                      value="India"
                                      type="button"
                                      onClick={onbtnClick}
                                      className={
                                        formData.nationality === "India"
                                          ? "p-button-text p-button-text btn-active"
                                          : " p-button-text p-button-text"
                                      }
                                      id="indiaInput"
                                      />
                                    </div>

                                    <div className="nationalityInputImgDiv"> 
                                      <img src={nepalflag} className="flag" alt="..." id="indianFlagIMG"/>  
                                      <input
                                        type="button"
                                        value="Nepal"
                                        name="country"
                                        onClick={onbtnClick}
                                        className={
                                          formData.nationality === "Nepal"
                                            ? "p-button-text p-button-text btn-active"
                                            : "p-button-text p-button-text"
                                        }
                                        id="indiaInput"
                                      />
                                    </div>
                                  </div>
      
                                  </div>  
                                  </div>
                              </div>

                              {formData.nationality === "India" && (
                                <>
                                <div className="row">
                                  <div className="col mt-3">
                                    <label
                                      htmlFor="state"
                                      className="form-label theme-label color__paragraph fw-bold"
                                    >
                                      State
                                     </label>
                                    <select
                                      name="state"
                                      id="countries"
                                      className="form-control"
                                      onChange={(e) => handleChangeState(e.target.value)}
                                      value={formData.state}
                                    >
                                      <option value="" selected>
                                        Choose a country
                                      </option>
                                      {States?.map((list, i) => {
                                        return <option value={list.name}>{list.name}</option>;
                                      })}
                                    </select>
                                    {errors.state && <p className="error">{errors.state}</p>}
                                  </div>

                                  <div className="col mt-3">
                                    <label
                                      htmlFor="city"
                                      className="form-label theme-label color__paragraph fw-bold"
                                    >
                                      City
                                     </label>
                                    <select
                                      name="city"
                                      id="countries"
                                      className="form-control"
                                      onChange={(e) => handleChangeCity(e.target.value)}
                                      value={formData.city}
                                    >
                                      <option value="" selected>
                                        Choose a city
                                       </option>
                                      {Cities.map((list, i) => {
                                        return <option value={list.name}>{list.name}</option>;
                                      })}
                                    </select>
                                    <div>
                                      {errors.city && <p className="error">{errors.city}</p>}
                                    </div>
                                  </div>
                                </div>  


                                <div className="row">
                                  <div className="col mt-2">
                                    <div>
                                      <label htmlFor="address fw-bold">Address</label>
                                    </div>
                                    <input
                                      type="text"
                                      name="address"
                                      value={formData.address}
                                      onChange={handleInputChange}
                                      placeholder="House Number/ Road/ Society"
                                      className="form-control"
                                    />
                                    <div>
                                      {errors.address && (
                                        <p className="error">{errors.address}</p>
                                      )}
                                    </div>
                                  </div>
                                  
                                  <div className="col mt-2">
                                    <div>
                                      <label htmlFor="pincode fw-bold">Pincode</label>
                                    </div>
                                    <input
                                      type="number"
                                      name="pincode"
                                      value={formData.pincode}
                                      onChange={handleInputChange}
                                      placeholder="pincode"
                                      className="form-control"
                                    />
                                    <div>
                                      {errors.pincode && (
                                        <p className="error">{errors.pincode}</p>
                                      )}
                                    </div>
                                  </div>
                                 
                                </div>
                                </>
                              )}
                              {formData.nationality === "Nepal" && (
                                <>
                                  <div className="mt-0">
                                    <div>
                                      <label htmlFor="municipality fw-bold">
                                        Municipality
                                     </label>
                                    </div>
                                    <input
                                      type="text"
                                      name="municipality"
                                      value={formData.municipality}
                                      onChange={handleInputChange}
                                      placeholder="municipality"
                                      className="form-control"
                                    />
                                    <div>
                                      {errors.municipality && (
                                        <p className="error">{errors.municipality}</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="mt-0">
                                    <div>
                                      <label htmlFor="wardnumber fw-bold">Ward Number</label>
                                    </div>
                                    <input
                                      type="text"
                                      name="wardnumber"
                                      value={formData.wardnumber}
                                      onChange={handleInputChange}
                                      placeholder="wardnumber"
                                      className="form-control"
                                    />
                                    <div>
                                      {errors.wardnumber && (
                                        <p className="error">{errors.wardnumber}</p>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                          {step === 3 && (
                            <>
                             <div class="mt-5">
                              <center><b>Upload Proof</b></center>
                              </div>
                              <div class="container mt-0">
                                  <label htmlFor="proof" className="mb-1 fw-bold">
                                    Front Photo
                                  </label>
                                <div class="custom-uploader text-center mt-2"
                                      id="Become-member-upload-photo-div">
                                  <FontAwesomeIcon
                                    icon={faCloudArrowUp}
                                    size="2xl"
                                    style={{ color: "#6455d8", marginTop: "2.8rem" }}
                                  />
                                  <div>
                                    <label for="customFile1" class="custom-uploader-text" id="upload-text">
                                      Upload Aadhar Card or Election Card {formData.proof}
                                    </label>
                                  </div>
                                  <input
                                    type="file"
                                    class="custom-file-input"
                                    id="customFile1"
                                    name="proof"
                                    onChange={handleInputChange}
                                    // onChange={handleFileUpload}
                                    
                                    // onChange={userIdentityHandler}
                                    accept=".png, .jpeg, .jpg"
                                  />
                                </div>
                                <div>
                                  {errors.proof && (
                                    <p className="error text-center">{errors.proof}</p>
                                  )}
                                </div>
                              </div>
                              <div class="container mt-5 mb-5">
                                <label className="mb-1 fw-bold">Upload Back Photo</label>
                                <div class="custom-uploader text-center mt-2" id="Become-member-upload-photo-div">
                                  <FontAwesomeIcon
                                    icon={faCloudArrowUp}
                                    size="2xl"
                                    style={{ color: "#6455d8", marginTop: "2.8rem" }}
                                  />
                                  <div>
                                    <label for="customFile" class="custom-uploader-text" id="upload-text">
                                      Upload Back Photo {formData.uploadbackproofId}
                                    </label>
                                  </div>
                                  <input
                                    type="file"
                                    class="custom-file-input"
                                    id="customFile"
                                    name="uploadbackproofId"
                                    onChange={handleInputChange}
                                    accept=".png, .jpeg, .jpg"
                                  />
                                </div>
                                  <div>
                                    {errors.uploadbackproofId && (
                                      <p className="error text-center">{errors.uploadbackproofId}</p>
                                    )}
                                  </div>
                              </div>


                            <div class="d-flex justify-content-between align-items-baseline" >
                              <div class="row" id="become-member-beneficiary">
                                <div class="col-md-6">
                                  <div class="col-md-6">
                                    <div class="inline-content mb-2"  id="beeficiary">
                                      <strong>
                                        Are you beneficiary of ESIC & PF?
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="inline-content">
                                    <div className="flex flex-wrap gap-2">
                                      <input
                                        name="beneficiary"
                                        type="button"
                                        value="yes"
                                        onClick={onbtnClick}
                                        className={
                                          formData.beneficiary === "yes"
                                            ? "p-button-text selectbtn btn-active "
                                            : "p-button-text selectbtn"
                                        }
                                      />
                                      <input
                                        name="beneficiary"
                                        type="button"
                                        value="no"
                                        onClick={onbtnClick}
                                        className={
                                          formData.beneficiary === "no"
                                            ? "p-button-text selectbtn btn-active "
                                            : "p-button-text selectbtn"
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  {errors.beneficiary && (
                                    <p className="error">{errors.beneficiary}</p>
                                  )}
                                </div>
                              </div>

                              <div class="row" id="become-member-beneficiary">
                                <div class="col-md-6">
                                  <div class="col-md-6">
                                    <div class="inline-content mb-2"  id="beeficiary">
                                      <strong>
                                        Monthly income
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="inline-content">
                                    <div className="d-flex gap-2">
                                      <input
                                        name="monthly_income"
                                        type="button"
                                        value="Below 15000 INR"
                                        onClick={onbtnClick}
                                        className={
                                          formData.monthly_income === "Below 15000 INR"
                                            ? "p-button-text selectbtn btn-active "
                                            : "p-button-text selectbtn"
                                        }
                                      />
                                      <input
                                        name="monthly_income"
                                        type="button"
                                        value="Above 15000 INR"
                                        onClick={onbtnClick}
                                        className={
                                          formData.monthly_income === "Above 15000 INR"
                                            ? "p-button-text selectbtn btn-active "
                                            : "p-button-text selectbtn"
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  {errors.monthly_income && (
                                    <p className="error">{errors.monthly_income}</p>
                                  )}
                                </div>
                              </div>
                            </div>  

                              <div className="mt-5">
                                <p className="smaller">
                                  <strong>Delclaration:</strong>
                                  <small>
                                  All the above given details are correct and my only .I'm
                                  taking liability if found false in the above details. I am
                                  liable to be punished by Indian Law. I also confirm the
                                  the above information can be helpful to provide more
                                  valuable service to me.
                                  </small>
                                </p>
                              </div>
                              <div>
                                <div className="d-flex d-inline p-2">
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    onChange={(e) => hndlechangechecked(e)}
                                  />
                                  <p className="mx-2">I accept Terms and Conditions.</p>
                                  <div> </div>
                                </div>
                                {errors.checkbox && (
                                  <p className="error">{errors.checkbox}</p>
                                )}
                              </div>
                            </>
                          )}
                          {step === 2 && (
                            <button
                              className="become-member-previous mt-3"
                              type="submit"
                              onClick={() => setStep(1)}
                            >
                              Previous {step}
                            </button>
                          )}
                          {step === 3 && (
                            <button
                              className="become-member-previous mt-3"
                              type="submit"
                              onClick={() => setStep(2)}
                            >
                              Previous {step}
                            </button>
                          )}
                          {step < 5 && ![1, 2, 3].includes(step) && (
                            <p className="text-primary fs-5">Click the button to Pay Fee and Submit the Form!!</p>
                          )}
                          
                          <button
                           className="become-member-next mt-3" 
                             type="submit" 
                             value={step === 4 
                             ? formData.payNow 
                             : null} 
                           onClick={
                              step === 4 && options.amount > 0
                              ? handlePayNow
                              : null
                              }>
                            {step < 4 ? "Save and Next" : `${(options.amount > 0) ? "Pay Now & Submit" : "Submit"}`}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Userfooter />
    </>
  )
}

export default BecomeMember;
