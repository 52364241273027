import React from "react";
import { NavLink } from "react-router-dom";
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navClass: "nav-item pl-4 pl-md-0 ml-0 pr-md-0",
            open_menu: false,
            collapsing: "collapse"
        }
    }
    handleOpen = () => {
        this.setState({
            navClass: "nav-item pl-4 pl-md-0 ml-0 pr-md-0 show"
        })
    }
    handleClose = () => {
        this.setState({
            navClass: "nav-item pl-4 pl-md-0 ml-0 pr-md-0"
        })
    }
    toggleBurger = (e) => {
        e.preventDefault();
        this.setState({
            collapsing: "collapsing"
        })
        setTimeout(() => {
            this.setState({
                open_menu: !this.state.open_menu,
                collapsing: "collapse"
            })

        }, 300);
    }
    render() {
        var banner = this.props.banner ? this.props.banner : "default";
        var bannerClass = this.props.bannerClass ? this.props.bannerClass : "";
        return <><header className={"site_head " + bannerClass}>
            <div className="top_bar">
                <div className="container">
                    <div className="login_bar">
                        <div className="login_cnt"><NavLink to="/login">Agent login</NavLink><a href="tel:+919099005251">Call: +919099005251</a><a target="blank_" href=" https://wa.me/919099005251"><i className="fab fa-whatsapp"></i></a><a target="blank_" href="https://www.facebook.com/Onlinesaathi"><i className="fab fa-facebook-f"></i></a><a target="blank_" href="http://google.com/OnlineSaathiIndia"><i className="fab fa-google"></i></a></div>
                    </div>
                </div>
            </div>
            <div className="navigation-wrap home_nav start-style">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <NavLink className="navbar-brand" to="/" >
                                    <img src={require("assets/images/new/online-sath-logo.png")} alt="Online Saathi" />
                                </NavLink>
                                <button className={this.state.open_menu ? "navbar-toggler collapsed" : "navbar-toggler"} onClick={this.toggleBurger} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={this.state.open_menu ? "true" : "false"} aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className={this.state.open_menu ? "navbar-collapse show "+this.state.collapsing : "navbar-collapse "+this.state.collapsing} id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto py-4 py-md-0">
                                        <li className="nav-item pl-4 pl-md-0 pr-md-0 ml-0">
                                            <NavLink className="nav-link" to="/" activeClassName="active" >Home</NavLink> </li>
                                        <li className={this.state.navClass} onMouseEnter={this.handleOpen} onMouseLeave={this.handleClose}>
                                            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">About</a>
                                            <div className="dropdown-menu">
                                                <NavLink className="dropdown-item" to="/overview" activeClassName="active">Overview</NavLink>
                                                <NavLink className="dropdown-item" to="/vision-mission" activeClassName="active">Vision & Mission</NavLink>
                                                <NavLink className="dropdown-item" to="/message-from-chairman" activeClassName="active">Message from Chairman</NavLink>
                                                <NavLink className="dropdown-item" to="our-team" activeClassName="active">Our Team</NavLink> </div>
                                        </li>
                                        <li className="nav-item pl-4 pl-md-0 pr-md-0 ml-0">
                                            <NavLink className="nav-link" to="/services" activeClassName="active" >Services</NavLink>
                                        </li>
                                        <li className="nav-item pl-4 pl-md-0 pr-md-0 ml-0">
                                            <NavLink className="nav-link" to="/network" activeClassName="active">Network</NavLink>
                                        </li>
                                        <li className="nav-item pl-4 pl-md-0 pr-md-0 ml-0">
                                            <NavLink className="nav-link" to="/contact" activeClassName="active" >Contact</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            
            {banner === "default" && <div className="banner_cnt">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <h1>Bridging Gaps for India's Unorganized Workforce</h1>
                            <p>81% of India's workforce operates in the unorganized sector, facing 
                                multifaceted challenges that hinder their access to essential services, 
                                livelihood opportunities, and crucial government schemes. The stark reality 
                                is reflected in distressing statistics, with 25% of suicide cases in India 
                                attributed to financial problems and limited access to education among poor 
                                migrant laborers. This underscores the pressing need for a holistic solution 
                                that addresses the root causes of these issues, providing a lifeline for those 
                                grappling with the complexities of the unorganized secto
                            </p>

                            <a href="#"><img src={require("assets/images/new/playbadge-c.png")} alt="playbadge" /></a> </div>
                        <div className="col-lg-5 col-md-5">
                            <div className="banner_form">
                                <h4>Request a Call Back</h4>
                                <form id="request-call" name="request_call" method="POST">
                                    <div className="form-group mx-2">
                                        <input type="text" className="form-control" name="name" placeholder="Name*" required="" />
                                    </div>
                                    <div className="form-group mx-2">
                                        <input type="email" className="form-control" name="email" placeholder="Email" />
                                    </div>
                                    <div className="form-group mx-2">
                                        <input type="number" className="form-control" name="mobile" placeholder="Mobile No.*" required="" />
                                    </div>
                                    <div className="form-group mx-2">
                                        <select className="form-control" name="services">
                                            <option value="">Select Service</option>
                                            <option value="Indo-Nepal Remittance">Safe Jobs Connect</option>
                                            <option value="Nepal Recharge & Bill Payment">Government Schemes Services</option>
                                            <option value="Indian Money Transfer"> On-Demand Services</option>
                                            <option value="Indian Recharge & Bill Payment"> Social Community </option>
                                        </select>
                                    </div>
                                    <button type="submit" className="btn btn-primary mx-2" id="request_call_submit">Submit</button>
                                    <input type="hidden" name="form_name" value="Request Call" />
                                    <input type="hidden" name="is_valid" value="mrnsnuoquq" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {banner !== "default" && <div className="banner_cnt inner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <h1>{this.props.h1Text}</h1>
                        </div>
                        <div className="col-lg-5"> </div>
                    </div>
                </div>
            </div>}
        </header>
        </>;
    }
}

export default Header;