import React from "react";
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Form,
   Input,
   Row,
   Col,
   Table,
   Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Bank extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading:false,
         categories: [],
         position:"",
         description: "",
         status: "",
         id:"",
         category_name: "",
         position: "",
         category_icon: null,
         show_form: false,
         modal: false,
         selected_category: null,
         account_holder_name:"",
         account_number:"",
         bank_name:"",
         ifsc:"",
         account_type:""
      }
   }
   componentDidMount = () => {
      this.getAllBank();
   }
   inputChange = (e) => {
      this.setState({
         [e.target.name]: e.target.value
      })
   }
   onFileChange = (e) => {
      this.setState({ category_icon: e.target.files[0] })
   }

   saveCategory = e => {
      e.preventDefault();
      if (this.state.bank_name == "") {
         this.notify("error", "Please enter Bank name.");
         return false;
      }
      if (this.state.account_number == "") {
         this.notify("error", "Please enter account number.");
         return false;
      }
      if (this.state.ifsc == "") {
        this.notify("error", "Please enter IFSC code.");
        return false;
     }
     if (this.state.account_holder_name == "") {
        this.notify("error", "Please enter account holder name.");
        return false;
     }
     if (this.state.account_type == "") {
        this.notify("error", "Please choose account type.");
        return false;
     }
      if (this.state.status == "") {
         this.notify("error", "Please select status.");
         return false;
      }

      const formData = new FormData();
      formData.append('account_type', this.state.account_type);
      formData.append('status', this.state.status);
      formData.append('account_number', this.state.account_number);
      formData.append('account_holder_name', this.state.account_holder_name);
      formData.append('bank_name', this.state.bank_name);
      formData.append('ifsc', this.state.ifsc);
      if(this.state.id){
        formData.append('id', this.state.id);
        Axios.post(`${apiUrl}/admin/update/bank`, formData, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            if (res.data.success) {
               this.notify("success", res.data.message);
               this.showForm();
               this.getAllBank();
            } else {
               this.notify("error", res.data.message);
            }
         })
      }else{
        Axios.post(`${apiUrl}/admin/save/bank`, formData, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            if (res.data.success) {
               this.notify("success", res.data.message);
               this.showForm();
               this.getAllBank();
            } else {
               this.notify("error", res.data.message);
            }
         })
      }

   }

   getAllBank = () => {
      Axios.get(`${apiUrl}/admin/all/bank`, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            this.setState({
               categories: res.data.categories
            })
         }
         else {
            this.setState({
               categories: []
            })
         }
      })
   }
   statusChangeCategory = (e, id) => {
      e.preventDefault();
      Axios.post(`${apiUrl}/admin/category/change/status`, { category_id: id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.getAllBank();
         }
         else {
            this.notify("error", res.data.message);
         }
      }, err => {
         this.notify("error", "Something went wrong");
         console.log("status error", err)
      })
   }

   editForm = (e ,val) => {
    if (e) e.preventDefault();
    this.setState({
        show_form: true,
       bank_name: val.bank_name,
       status:val.status,
       id:val.id,
       account_type:val.account_type,
       account_number:val.account_number,
       account_holder_name:val.account_holder_name,
       ifsc:val.ifsc

    })
 }

   showForm = (e = null) => {
      if (e) e.preventDefault();
      this.setState({
         show_form: !this.state.show_form,
         category_icon: null,
         bank_name: "",
         account_type: "",
         status: "",
         id:"",
         account_number: "",
         account_holder_name:"",
         ifsc:"",

      })
   }
   positionChangeCategory = (e, cat) => {
      e.preventDefault();
      this.setState({
         modal: true,
         selected_category: cat,
         position: cat.position
      })
   }
   notify = (type, message) => {
      var atype = type === "error" ? "danger" : type;
      var options = {};
      options = {
         place: process.env.REACT_APP_TOAST_POSITION,
         message: (
            <div>
               <div>
                  {message}
               </div>
            </div>
         ),
         type: atype,
         icon: "tim-icons icon-bell-55",
         autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
   };
   toggle = (e) => {
      this.setState({
         modal: !this.state.modal
      })
   }
   changePosition = (e) => {
      if (this.state.selected_category.position != this.state.position) {
         var data = {
            new_position: this.state.position,
            id: this.state.selected_category.id,
            old_position: this.state.selected_category.position
         }
         Axios.post(`${apiUrl}/admin/change/category/position`, data, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
         }).then(res => {
            this.notify("success", "Posiotn changed successfully.");
            this.setState({
               categories: res.data.categories,
               modal: false
            })
         })
      }
   }
   commingSoonActioon = (e, cat) => {
      e.preventDefault();
      console.log(cat)
      Axios.post(`${apiUrl}/admin/comming/soon/manage`, { category_id: cat.id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.notify(res.data.success ? "success" : "error", res.data.message);
         this.getAllBank();

      })
   }
   changeAccess = (e, val, type) => {
      e.preventDefault();
      this.setState({
         loading: true
      })
      var data = {
         type: type,
         category_id: val.id,
         status: e.target.checked ? "yes" : "no"
      }
      Axios.post(`${apiUrl}/admin/categories/update/access`, data, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.setState({
            loading: false
         })
         this.notify(res.data.success ? "success" : "error", res.data.message);
         if (res.data.success) {
            this.setState({
               categories: res.data.categories
            })
         }

      },err=>{

         this.setState({
            loading: false
         })
         this.notify("error", "Something went wrong");
      })
   }
   render() {
      return (
         <>
         {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
            <div className="content">
               <div className="react-notification-alert-container">
                  <NotificationAlert ref="notificationAlert" />
               </div>
               <Row>
                  <Col md="12">
                     <Card>
                        <CardHeader>
                           <h3 className="title">Bank List
                              {!this.state.show_form && <small className="float-right"><a href="" className="btn btn-success" onClick={(e) => { this.showForm(e) }} >+ Add New</a></small>}
                           </h3>
                        </CardHeader>
                        <CardBody>
                           {this.state.show_form && <Form onSubmit={this.saveCategory}>
                              <Row>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Bank Name</label>
                                       <Input value={this.state.bank_name} name="bank_name" placeholder="Bank Name" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Account Holder Name</label>
                                       <Input value={this.state.account_holder_name} name="account_holder_name" placeholder="account_holder_name" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Account Number</label>
                                       <Input value={this.state.account_number} name="account_number" placeholder="account_number" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>IFSC Code</label>
                                       <Input value={this.state.ifsc} name="ifsc" placeholder="ifsc" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>
                                          Account Type
                                       </label>
                                       <select className="form-control" name="account_type" value={this.state.account_type} onChange={(e) => { this.inputChange(e) }}>
                                          <option value="">Select</option>
                                          <option value="saving">Saving</option>
                                          <option value="current">Current</option>
                                       </select>
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>
                                          Status
                                       </label>
                                       <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                                          <option value="">Select</option>
                                          <option value="active">Active</option>
                                          <option value="inactive">Inactive</option>
                                       </select>
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                 Cancel
                              </Button>
                              <Button className="btn-fill" color="primary" type="submit">
                                 Save
                              </Button>
                           </Form>}
                           <Table className="tablesorter" responsive>
                              <thead className="text-primary">
                                 <tr>
                                    <th>Sr.</th>
                                    <th>Bank Name</th>
                                    <th>Account Holder Name</th>
                                    <th>Account Number</th>
                                    <th>IFSC Code</th>
                                    <th>Account Type</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.categories.map((val, key) => {
                                    return <tr key={key}>
                                       <td>{key+1}</td>
                                       <td>{val.bank_name}</td>
                                       <td>{val.account_holder_name}</td>
                                       <td>{val.account_number}</td>
                                       <td>{val.ifsc}</td>
                                       <td>{val.account_type}</td>
                                       <td>{val.status}</td>
                                       <td>
                                          <a href="" onClick={(e) => { this.editForm(e, val) }} >
                                            <i className="fa fa-pencil-square-o" title="Edit Category"></i>

                                          </a>
                                       </td>
                                    </tr>
                                 })}
                              </tbody>
                           </Table>
                        </CardBody>
                        {/* <CardFooter>

                                </CardFooter> */}
                     </Card>
                  </Col>
               </Row>
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} >
               <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
               <ModalBody>
                  <p>Changing position warning</p>
                  <FormGroup>
                     <label>Position</label>
                     <Input name="position" value={this.state.position} placeholder="position" type="number" min={1} max={this.state.categories.length} onChange={(e) => { this.inputChange(e) }} />
                  </FormGroup>
               </ModalBody>
               <ModalFooter>
                  <Button color="primary" onClick={this.changePosition}>Update</Button>{' '}
                  <Button color="secondary" onClick={this.toggle}>Cancel</Button>
               </ModalFooter>
            </Modal>
         </>
      );
   }
}

export default Bank;
