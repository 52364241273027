/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Nav, NavItem, NavLink } from "reactstrap";

import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

class Footer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      user: null
    }
  }

  componentDidMount = () => {
    var details = sessionStorage.getItem('cuser') ? Cryp.decrypt(sessionStorage.getItem('cuser')) : null
    this.setState({ user: details })
  }
  render() {
    return (
      <footer className="footer">
        <Container fluid>
          <Nav>
            {this.state.user && <NavItem>
              <NavLink href="#">Last loggedin at {this.state.user.last_login}</NavLink>
            </NavItem>}
            {/* <NavItem>
              <NavLink href="#">About Us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#">Blog</NavLink>
            </NavItem> */}
          </Nav>
          <div className="copyright">
            © {new Date().getFullYear()}&nbsp;
            <a
              href="#"
              target="_blank"
            >
              OnlineSaathi
            </a>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
