import Header from 'layouts/Dashboard/header/Header';
import Sidenav from 'layouts/Dashboard/sidenav/Sidenav';
import Userfooter from 'layouts/Users/Footer';
import React from 'react';

const Homedashboard = () => {
  return (
    <div className="">
      <div className="row">
        <div className='col-12'>
          <Header />
        </div>
      </div>
      <div className="row">
        <div className='col-md-3 col-lg-2 d-md-block '>
          <Sidenav />
        </div>
        <div className='col-md-9 bg-white d-flex  justify-content-end'
          style={{
            left: '50px'
          }}
        >
          <div className=" border border-2 w-100 border-gray-200 border-dashed rounded-lg border-white">
            <div className="row mb-4">
              <div className="col-md-3">
                <div className="d-flex justify-content-center align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                    <span className='d-flex justify-content-center fa-lg'><i className="fa fa-file-text fa-2x" aria-hidden="true"></i>&nbsp;</span>
                    <h4 className='mt-3 fs-1'>SUCCESS TRANSACTION</h4>
                    <div className="row">
                      <div className='position-absolute' style={{left: '50px'}}>Rupees</div>
                      <div className='position-absolute'style={{right: '50px'}}>
                      <i className="fa fa-inr" aria-hidden="true"></i>&nbsp;0</div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex justify-content-center align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                    <span className='d-flex justify-content-center fa-lg'><i className="fa fa-file-excel-o fa-2x" aria-hidden="true"></i>&nbsp;</span>
                    <h4 className='mt-3 fs-1'>FAILED TRANSACTION</h4>
                    <div className="row">
                      <div className='position-absolute' style={{left: '50px'}}>Rupees</div>
                      <div className='position-absolute'style={{right: '50px'}}>
                      <i className="fa fa-inr" aria-hidden="true"></i>&nbsp;0</div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex justify-content-center align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                    <span className='d-flex justify-content-center fa-lg'><i className="fa fa-clock-o fa-2x" aria-hidden="true"></i>&nbsp;</span>
                    <h4 className='mt-3 fs-1'>PENDING TRANSACTION</h4>
                    <div className="row">
                      <div className='position-absolute' style={{left: '50px'}}>Rupees</div>
                      <div className='position-absolute'style={{right: '50px'}}>
                      <i className="fa fa-inr" aria-hidden="true"></i>&nbsp;0</div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex justify-content-center align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                    <span className='d-flex justify-content-center fa-lg'><i className="fa fa-handshake-o fa-2x" aria-hidden="true"></i>&nbsp;</span>
                    <h4 className='mt-3 fs-1'>FUND TRANSACTION</h4>
                    <div className="row">
                      <div className='position-absolute' style={{left: '50px'}}>Rupees</div>
                      <div className='position-absolute'style={{right: '50px'}}>
                      <i className="fa fa-inr" aria-hidden="true"></i>&nbsp;0</div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-4">
            <div className="col-md-3">
                <div className="d-flex justify-content-center align-items-center h-100 rounded bg-white shadow bg-body rounded border-top-0 ">
                  <p className="text-2xl text-muted">
                    <span className='d-flex justify-content-center fa-lg mt-4'><i className="fa fa-user text-info fa-2x" aria-hidden="true"></i>&nbsp;
                    <h2 className='mt-0 fs-1'>&nbsp;Members</h2>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-3">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                    <h2 className='mt-3 fs-1'>Registration</h2>
                    <div className="row">
                      <div className='position-absolute text-dark' style={{left: '50px'}}>
                        <h2>1<span className='text-info fs-5'>(<i className="fa fa-inr" aria-hidden="true"></i>0)</span></h2></div>
                      <div className='position-absolute rounded-circle bg-white shadow p-2 bg-body rounded border-top-0 'style={{right: '50px'}}>
                      <i className="fa fa-user text-info" aria-hidden="true"></i>&nbsp;</div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                    <h2 className='mt-3 fs-1'>Member</h2>
                    <div className="row">
                      <div className='position-absolute text-dark' style={{left: '50px'}}>
                        <h2>1<span className='text-success fs-5'>(<i className="fa fa-inr" aria-hidden="true"></i>0)</span></h2></div>
                      <div className='position-absolute rounded-circle bg-white shadow p-2 bg-body rounded border-top-0 'style={{right: '50px'}}>
                      <i className="fa fa-users text-success" aria-hidden="true"></i>&nbsp;</div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                    <h2 className='mt-3 fs-1'>Saathi</h2>
                    <div className="row">
                      <div className='position-absolute text-dark' style={{left: '50px'}}>
                        <h2>1<span className='text-primary fs-5'>(<i className="fa fa-inr" aria-hidden="true"></i>0)</span></h2></div>
                      <div className='position-absolute rounded-circle bg-white shadow p-2 bg-body rounded border-top-0 'style={{right: '50px'}}>
                      <i className="fa fa-users text-primary" aria-hidden="true"></i>&nbsp;</div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                    <h2 className='mt-3 fs-1'>Partner</h2>
                    <div className="row">
                      <div className='position-absolute text-dark' style={{left: '50px'}}>
                        <h2>1<span className='text-warning fs-5'>(<i className="fa fa-inr" aria-hidden="true"></i>0)</span></h2></div>
                      <div className='position-absolute rounded-circle bg-white shadow p-2 bg-body rounded border-top-0 'style={{right: '50px'}}>
                      <i className="fa fa-user text-warning" aria-hidden="true"></i>&nbsp;</div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-3">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                    <h2 className='mt-3 fs-1'>State Partner</h2>
                    <div className="row">
                      <div className='position-absolute text-dark' style={{left: '50px'}}>
                        <h2>1<span className='text-info fs-5'>(<i className="fa fa-inr" aria-hidden="true"></i>0)</span></h2></div>
                      <div className='position-absolute rounded-circle bg-white shadow p-2 bg-body rounded border-top-0 'style={{right: '50px'}}>
                      <i className="fa fa-users text-info" aria-hidden="true"></i>&nbsp;</div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                    <h2 className='mt-3 fs-1'>Total Balance</h2>
                    <div className="row">
                      <div className='position-absolute text-dark' style={{left: '50px'}}>
                        <h2>1<span className='text-success fs-5'>(<i className="fa fa-inr" aria-hidden="true"></i>0)</span></h2></div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0">
                  <p className="text-2xl text-muted">
                  <div className='position-absolute rounded-circle bg-red p-2 shadow  bg-body rounded border-top-0 'style={{right: '80px'}}>
                      <i className="fa fa-users text-success" aria-hidden="true"></i>&nbsp;</div>
                    <h2 className='mt-5 text-center' style={{color:"gray",fontSize:"20px"}}>BBPS</h2>
                    <div className="row">
                      <div className='position-absolute text-dark' style={{left: '50px'}}>
                        <h2>1<span className='text-primary fs-5'>(<i className="fa fa-inr" aria-hidden="true"></i>0)</span></h2></div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-3 mb-5 bg-body rounded border-top-0 text-danger">
                  <p className="text-2xl text-muted">
                  <div className='position-absolute rounded-circle p-2 shadow bg-body rounded border-top-0 'style={{right: '80px'}}>
                      <i className="fa fa-users text-success" aria-hidden="true"></i>&nbsp;</div>
                      <h2 className='mt-5 text-center' style={{color:"gray",fontSize:"15px"}}>Total Recharge</h2>
                    <div className="row">
                      <div className='position-absolute text-dark' style={{left: '50px'}}>
                        <h2>1<span className='text-warning fs-5'>(<i className="fa fa-inr" aria-hidden="true"></i>0)</span></h2></div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-4">
            <div className="col-md-3">
                <div className="d-flex justify-content-center align-items-center h-100 rounded bg-white shadow bg-body rounded border-top-0 ">
                  <p className="text-2xl text-muted">
                    <span className='d-flex justify-content-center fa-lg mt-4'><i className="fa fa-user text-info fa-2x" aria-hidden="true"></i>&nbsp;
                    <h2 className='mt-0 fs-1'>&nbsp;Reports</h2>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-6">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-1  bg-body rounded border-top-0">
                  <p className="text-2xl text-muted">
                    <h2 className='text-start mx-3 mt-2' style={{color:"black",fontSize:"20px"}}>FUND  REQUEST </h2>
                    <div className="row">
                    <div className=' text-dark bg-info col-4' style={{left: '40px', width:'360px'}}>
                          <h2 className='mt-2' style={{color:"white",fontSize:"15px"}}>TOTAL REQUESTS</h2>
                        <span className='text-white fs-5'><i className="fa fa-inr text-white" aria-hidden="true"></i>0</span></div>
                        <div className=' text-dark bg-warning col-4' style={{right: '-112px'}}>
                          <h2 className='mt-2' style={{color:"white",fontSize:"15px"}}>PENDING REQUESTS</h2>
                        <span className='text-white fs-5 '><i className="fa fa-inr text-white" aria-hidden="true"></i>0</span></div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px"}}><i className="fa fa-stop-circle-o text-success" aria-hidden="true"></i>&nbsp;Accepted Requests</p>
                  </div>
                  <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px", textAlign: 'end'}}><i className="fa fa-inr " aria-hidden="true"></i>&nbsp;0</p>
                  </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px"}}><i className="fa fa-stop-circle-o text-danger" aria-hidden="true"></i>&nbsp;Rejected Requests</p>
                  </div>
                  <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px", textAlign: 'end'}}><i className="fa fa-inr " aria-hidden="true"></i>&nbsp;0</p>
                  </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px"}}><i className="fa fa-stop-circle-o text-danger" aria-hidden="true"></i>&nbsp;Pending Requests</p>
                  </div>
                  <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px", textAlign: 'end'}}><i className="fa fa-inr " aria-hidden="true"></i>&nbsp;0</p>
                  </div>
                </div>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-1  bg-body rounded border-top-0">
                  <p className="text-2xl text-muted">
                  <h2 className='text-start mx-3 mt-2' style={{color:"black",fontSize:"20px"}}>RECHARGE  REQUEST </h2>
                  <div className="row">
                    <div className=' text-dark bg-info col-4' style={{left: '40px', width:'360px'}}>
                          <h2 className='mt-2' style={{color:"white",fontSize:"15px"}}>TOTAL REQUESTS</h2>
                        <span className='text-white fs-5'><i className="fa fa-inr text-white" aria-hidden="true"></i>0</span></div>
                        <div className=' text-dark bg-warning col-4' style={{right: '-112px'}}>
                          <h2 className='mt-2' style={{color:"white",fontSize:"15px"}}>PENDING REQUESTS</h2>
                        <span className='text-white fs-5 '><i className="fa fa-inr text-white" aria-hidden="true"></i>0</span></div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px"}}><i className="fa fa-stop-circle-o text-success" aria-hidden="true"></i>&nbsp;Accepted Requests</p>
                  </div>
                  <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px", textAlign: 'end'}}><i className="fa fa-inr " aria-hidden="true"></i>&nbsp;0</p>
                  </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px"}}><i className="fa fa-stop-circle-o text-danger" aria-hidden="true"></i>&nbsp;Rejected Requests</p>
                  </div>
                  <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px", textAlign: 'end'}}><i className="fa fa-inr " aria-hidden="true"></i>&nbsp;0</p>
                  </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px"}}><i className="fa fa-stop-circle-o text-danger" aria-hidden="true"></i>&nbsp;Pending Requests</p>
                  </div>
                  <div className="col-md-6">
                     <p className='p-2' style={{fontSize:"20px", textAlign: 'end'}}><i className="fa fa-inr " aria-hidden="true"></i>&nbsp;0</p>
                  </div>
                </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5">
            <div className="col-md-4">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-1  bg-body rounded border-top-0">
                  <p className="text-2xl text-muted w-100">
                    <h2 className='text-center bg-info text-white p-2 d-flex text-start' style={{color:"black",fontSize:"20px"}}>3rd Party Agent</h2>
                    <table className="table table-borderless">
            <thead>
                <tr>
                    <th>Member Type</th>
                    <th>Count</th>
                    <th>Stock</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Prabhu</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                <td>IME</td>
                    <td>0</td>
                    <td>0</td>  
                </tr>
                <tr>
                    <td>DMT</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                <td>AEPS</td>
                    <td>0</td>
                    <td>0</td>  
                </tr>
            </tbody>
        </table>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex justify-content-start align-items-center h-100 rounded bg-white shadow p-1  bg-body rounded border-top-0">
                  <p className="text-2xl text-muted w-100">
                    <h2 className='text-center bg-info text-white p-2 d-flex text-start' style={{color:"black",fontSize:"20px"}}>Balances</h2>
                    <table className="table table-borderless">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Wallet Type</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><i className="fa fa-inr text-warning" aria-hidden="true"></i>&nbsp;</td>
                    <td>Utility Balance</td>
                    <td><i className="fa fa-inr text-info" aria-hidden="true"></i>&nbsp;1000</td>
                </tr>
                <tr>
                    <td><i className="fa fa-inr text-warning" aria-hidden="true"></i>&nbsp;</td>
                    <td>AePs Balance</td>
                    <td><i className="fa fa-inr text-info" aria-hidden="true"></i>&nbsp;1000</td>
                </tr>
                <tr>
                    <td><i className="fa fa-inr text-warning" aria-hidden="true"></i>&nbsp;</td>
                    <td>AePs Balance</td>
                    <td><i className="fa fa-inr text-info" aria-hidden="true"></i>&nbsp;1000</td>
                </tr>
            </tbody>
        </table>
                  </p>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
      <Userfooter />
    </div>
  )
}

export default Homedashboard;
