import React from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";
import axios from "axios";
import NotificationAlert from "react-notification-alert";

import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    // console.log(url)
    apiUrl = process.env.REACT_APP_API_URL;
}
class Forgetpassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: apiUrl,
            password: "",
            email: "",
            otp:"",
            sendotp:true,
            updatepassword:false,
            thanks:false
        };
    }
    componentDidMount = () => {
        // console.log(this.state)
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = () => {
        if (this.state.email === "") {
            this.notify("error", "Please enter mobile number.");
            return false;
        }
        axios.post(`${this.state.url}/forgot/password`, { mobile: this.state.email})
            .then(d => {
       
                if (d.data.success) {
                    this.notify("success", d.data.message)
                         this.setState({
                             sendotp :false,
                             updatepassword:true,
                         })
                } else {
                    this.setState({
                        sendotp :true,
                        updatepassword:false,
                    })
                    this.notify("error", d.data.message)
                }
            }, err => {
                this.setState({
                    sendotp :true,
                    updatepassword:false,
                })
                this.notify("error", "Wrong credentials.")
            })
    }
    submitpassword = () => {

        if (this.state.email === "") {
            this.notify("error", "Please enter mobile number.");
            return false;
        }
        if (this.state.otp === "") {
            this.notify("error", "Please enter Otp.");
            return false;
        }
        if (this.state.password === "") {
            this.notify("error", "Please enter password.");
            return false;
        }
        axios.post(`${this.state.url}/forgot/password/update`, { mobile: this.state.email,otp:this.state.otp,password:this.state.password})
            .then(d => {
       
                if (d.data.success) {
                    this.notify("success", d.data.message)
                         this.setState({
                            thanks :true,
                             updatepassword:false,
                         })
                } else {
                   
                    this.notify("error", d.data.message)
                }
            }, err => {
             
                this.notify("error", "Wrong credentials.")
            })
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row className="login-page">
                        <Col md={{ size: 4, offset: 4 }} className="mt-5">
                            <Card className="card-user">
                                <CardHeader className="login-section">
                                    <div className="row text-center">
                                        <Col md="12">
                                            <div className="login-logo">
                                                <img src={require("assets/images/new/online-sath-logo.png")} alt="" style={{ width: "50%", padding: "15px" }} />
                                            </div>
                                            <hr /><br />
                                            <h4>Forgot Password</h4>
                                        </Col>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                     {this.state.sendotp && <> 
                                    <Form>
                                        <Row>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Mobile</label>
                                                    <Input
                                                        placeholder="Mobile Number"
                                                        type="text"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                         
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <Button type="button" onClick={() => this.handleSubmit()} className="btn btn-round btn-block loginbtn" color="success">
                                                        Send Otp
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            {/* <Col className="pl-md-1" md={{ size: 8, offset: 2 }}>
                                                <h5>Don't have an account? <Link to="/register">Register</Link> here.</h5>
                                            </Col> */}
                                        </Row>
                                    </Form>
                                    </>}
                                    {this.state.updatepassword && <> 
                                    <Form>
                                        <Row>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Mobile</label>
                                                    <Input
                                                        placeholder="Mobile Number"
                                                        type="text"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Otp</label>
                                                    <Input
                                                        placeholder="Otp"
                                                        type="text"
                                                        name="otp"
                                                        value={this.state.otp}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Password</label>
                                                    <Input
                                                        placeholder="Password"
                                                        type="password"
                                                        name="password"
                                                        value={this.state.password}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                    <Link onClick={() => this.handleSubmit()} style={{
                                                        float: "right",
                                                        marginTop: "-6%",
                                                        marginBottom: "4%",
                                                        fontSize: "12px"
                                                    }}>Resend  Otp</Link> 
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <Button type="button" onClick={() => this.submitpassword()} className="btn btn-round btn-block loginbtn" color="success">
                                                        Send Otp
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                          
                                        </Row>
                                    </Form>
                                    </>}
                                    {this.state.thanks && <> 
                                    <Form>
                                        <Row>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                   <h5>Password updated successfully!</h5>
                                                </FormGroup>
                                            </Col>
                                         
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <a href="/login"  className="btn btn-round btn-block loginbtn" color="success">
                                                        Login
                                                    </a>
                                                </FormGroup>
                                            </Col>
                                           
                                        </Row>
                                    </Form>
                                    </>}
                                </CardBody>
                               
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Forgetpassword;
