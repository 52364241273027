import React from "react";
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Row,
   Col,
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Settings extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         dmt_charges: 0,
         bank_convenience_fees: 0,
         admin_commission: 0,
         remittance_commission: 0,
         max_transfer_amount: 0,
         max_wallet_balance_user: 0,
         max_remit_balance_user: 0,
         max_wallet_balance_dealer: 0,
         max_remit_balance_dealer: 0,
         maintanance_mode: "off",
         referral_cashback_status: "off",
         referral_cashback_type: 0,
         referral_cashback: 0,
         joining_cashback_status: "off",
         joining_cashback_type: 0,
         joining_cashback: 0,
         map_api_key: null,
         agent_remittance_commission: 0,
         distributor_remittance_commission: 0,
         agent_npr_charges: 0,
         distributor_npr_charges: 0,
         become_saathi_fees: 0,
         certificate_heading: "",
         certificate_name: "",
         certificate_image: "",
         share_text:"",
         share_image:"",
         refer_text:"",
         refer_image:"",
         prabhu_admin_csp_code: "",
         p_remittance_commission: 0,
         p_agent_remittance_commission: 0,
         p_distributor_remittance_commission: 0,
         admin_fees_remittance:0,
         p_admin_fees_remittance:0,
         notice:"",
         bottom_notice:""
      }
   }
   componentDidMount = () => {
      this.getSettings();
   }
   inputChange = (e) => {
      this.setState({
         [e.target.name]: e.target.value
      })
   }
   onFileChange = (e) => {
      this.setState({ certificate_image: e.target.files[0] })
   }

   onFileChangeShare = (e) => {
      this.setState({ share_image: e.target.files[0] })
   }

   onFileChangeRefer = (e) => {
      this.setState({ refer_image: e.target.files[0] })
   }
   getSettings = () => {
      Axios.get(`${apiUrl}/admin/get/settings`, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         var arr = [];
         console.clear();
         if (res.data.data) {
            res.data.data.map((val, key) => {
               this.setState({ [val.meta_key]: isNaN(+val.meta_value) ? val.meta_value : +val.meta_value })
            })
            this.setState({
               settings: arr
            })
            setTimeout(() => {
               var mm = sessionStorage.getItem("maintanance_mode");
               if (this.state.maintanance_mode !== mm) {
                  sessionStorage.setItem("maintanance_mode", this.state.maintanance_mode);
                  window.location.reload();
               }
            }, 1000);
         }
         console.log("settings response", res.data.data)
      })
   }
   saveSettings = e => {
      if (this.state.admin_commission < 0) {
         this.notify("error", "Admin commission cannot be less than 0.");
         return false;
      }
      if (this.state.remittance_commission < 0) {
         this.notify("error", "Remittance commission cannot be less than 0.");
         return false;
      }

      const formData = new FormData();
      formData.append('dmt_charges', this.state.dmt_charges);
      formData.append('bank_convenience_fees', this.state.bank_convenience_fees);
      formData.append('admin_fees_remittance', this.state.admin_fees_remittance);
      formData.append('p_admin_fees_remittance', this.state.p_admin_fees_remittance);
      formData.append('remittance_commission', this.state.remittance_commission);
      formData.append('agent_remittance_commission', this.state.agent_remittance_commission);
      formData.append('distributor_remittance_commission', this.state.distributor_remittance_commission);
      formData.append('p_remittance_commission', this.state.p_remittance_commission);
      formData.append('p_agent_remittance_commission', this.state.p_agent_remittance_commission);
      formData.append('p_distributor_remittance_commission', this.state.p_distributor_remittance_commission);
      formData.append('admin_commission', this.state.admin_commission);
      formData.append('agent_npr_charges', this.state.agent_npr_charges);
      formData.append('distributor_npr_charges', this.state.distributor_npr_charges);
      formData.append('max_transfer_amount', this.state.max_transfer_amount);

      formData.append('max_wallet_balance_user', this.state.max_wallet_balance_user);
      formData.append('max_remit_balance_user', this.state.max_remit_balance_user);
      formData.append('max_wallet_balance_dealer', this.state.max_wallet_balance_dealer);
      formData.append('max_remit_balance_dealer', this.state.max_remit_balance_dealer);
      formData.append('maintanance_mode', this.state.maintanance_mode);
      formData.append('map_api_key', this.state.map_api_key);
      formData.append('referral_cashback_status', this.state.referral_cashback_status);
      formData.append('referral_cashback_type', this.state.referral_cashback_type);
      formData.append('referral_cashback', this.state.referral_cashback);
      formData.append('joining_cashback_status', this.state.joining_cashback_status);

      formData.append('joining_cashback_type', this.state.joining_cashback_type);
      formData.append('joining_cashback', this.state.joining_cashback);


      formData.append('certificate_heading', this.state.certificate_heading);
      formData.append('certificate_name', this.state.certificate_name);
      formData.append('certificate_image', this.state.certificate_image);
      formData.append('share_image', this.state.share_image);
      formData.append('share_text', this.state.share_text);
      formData.append('refer_image', this.state.refer_image);
      formData.append('refer_text', this.state.refer_text);

      formData.append('become_saathi_fees', this.state.become_saathi_fees);
      formData.append('prabhu_admin_csp_code', this.state.prabhu_admin_csp_code);

      formData.append('notice', this.state.notice);
      formData.append('bottom_notice', this.state.bottom_notice);
      Axios.post(`${apiUrl}/admin/save/settings`, formData, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         var type = res.data.success ? "success" : "error";
         this.getSettings();
         this.notify(type, res.data.message);
      })
   }
   notify = (type, message) => {
      var atype = type === "error" ? "danger" : type;
      var options = {};
      options = {
         place: process.env.REACT_APP_TOAST_POSITION,
         message: (
            <div>
               <div>
                  {message}
               </div>
            </div>
         ),
         type: atype,
         icon: "tim-icons icon-bell-55",
         autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
   };
   render() {
      return (
         <>
            <div className="content">
               <div className="react-notification-alert-container">
                  <NotificationAlert ref="notificationAlert" />
               </div>
               <Row>
                  <Col md="12 wrap_nar_seting">
                     <Card>
                        <CardHeader>
                           <Row className="set_row">
                              <Col md="8">
                                 <h3 className="title">
                                    Settings
                                 </h3>
                              </Col>
                              <Col md="4">
                                 <Button type="button" className="btn-fill savebtn" color="primary" onClick={(e) => { this.saveSettings(e) }}>
                                    Save
                                 </Button>
                              </Col>
                           </Row>
                        </CardHeader>
                        <CardBody>
                           <Row>
                              <Col md="12 g_setting_row">

                                 <FormGroup>
                                    <Row>
                                       <Col md="6">
                                          <label> Maintenance Mode</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <select className="form-control" name="maintanance_mode" value={this.state.maintanance_mode} onChange={(e) => { this.inputChange(e) }}>
                                                <option value="off">off</option>
                                                <option value="on">on</option>
                                             </select>
                                          </div>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col md="6">
                                          <label>Prabhu CSP CODE</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="text" className="form-control" name="prabhu_admin_csp_code" value={this.state.prabhu_admin_csp_code} onChange={(e) => { this.inputChange(e) }} />

                                          </div>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col md="6">
                                          <label>Saathi Registration Fees (in &#8377;)</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="become_saathi_fees" value={this.state.become_saathi_fees} onChange={(e) => { this.inputChange(e) }} min="0" />

                                          </div>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col md="6">
                                          <label>IME Income Admin (in &#8377;)</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="admin_fees_remittance" value={this.state.admin_fees_remittance} onChange={(e) => { this.inputChange(e) }} min="0" />

                                          </div>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col md="6">
                                          <label>Prabhu Income Admin (in &#8377;)</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="p_admin_fees_remittance" value={this.state.p_admin_fees_remittance} onChange={(e) => { this.inputChange(e) }} min="0" />

                                          </div>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col md="6">
                                          <label>Bank Conveinience Fees (in &#8377;)</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="bank_convenience_fees" value={this.state.bank_convenience_fees} onChange={(e) => { this.inputChange(e) }} min="0" />

                                          </div>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col md="6">
                                          <label>DMT Charges (in &#8377;)</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="dmt_charges" value={this.state.dmt_charges} onChange={(e) => { this.inputChange(e) }} min="0" />

                                          </div>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col md="6">
                                          <label> Max Wallet Balance For User (in &#8377;)</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="max_wallet_balance_user" value={this.state.max_wallet_balance_user} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col md="6">
                                          <label> Max Wallet Balance for Dealer (in &#8377;)</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="max_wallet_balance_dealer" value={this.state.max_wallet_balance_dealer} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col md="2">
                                          <label>Notice</label>
                                       </Col>
                                       <Col md="8">
                                          <div className="fieldinput">
                                             <textarea  className="form-control" name="notice" value={this.state.notice}  onChange={(e) => { this.inputChange(e) }} />
                                          </div>
                                       </Col>
                                    </Row>
                                    <br></br>

                                    <Row>
                                       <Col md="2">
                                          <label>Bottom Notice</label>
                                       </Col>
                                       <Col md="8">
                                          <div className="fieldinput">
                                             <textarea  className="form-control" name="bottom_notice" value={this.state.bottom_notice}  onChange={(e) => { this.inputChange(e) }} />
                                          </div>
                                       </Col>
                                    </Row>

                                 </FormGroup>
                                 <hr />
                                 <h2 className="g_heading">Nepali Recharge Service Charges (in %)</h2>
                                 <FormGroup>
                                    <Row>
                                       <Col md="6">
                                          <label>User</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="admin_commission" value={this.state.admin_commission} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                 </FormGroup>
                                 <FormGroup>
                                    <Row>
                                       <Col md="6">
                                          <label>Agent</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="agent_npr_charges" value={this.state.agent_npr_charges} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                 </FormGroup>
                                 <FormGroup>
                                    <Row>
                                       <Col md="6">
                                          <label>Distributor</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="distributor_npr_charges" value={this.state.distributor_npr_charges} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                 </FormGroup>
                                 <hr />
                                 <h2 className="g_heading">IME Remittance Commission (in &#8377;)</h2>
                                 <FormGroup>
                                    <Row>
                                       <Col md="6">
                                          <label> User</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="remittance_commission" value={this.state.remittance_commission} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                 </FormGroup>
                                 <FormGroup>
                                    <Row>
                                       <Col md="6">
                                          <label> Agent</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="agent_remittance_commission" value={this.state.agent_remittance_commission} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                 </FormGroup>
                                 <FormGroup>
                                    <Row>
                                       <Col md="6">
                                          <label> Distributor</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="distributor_remittance_commission" value={this.state.distributor_remittance_commission} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                 </FormGroup>
                                 <hr />
                                 <h2 className="g_heading">PRABHU Remittance Commission (in &#8377;)</h2>
                                 <FormGroup>
                                    <Row>
                                       <Col md="6">
                                          <label> User</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="p_remittance_commission" value={this.state.p_remittance_commission} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                 </FormGroup>
                                 <FormGroup>
                                    <Row>
                                       <Col md="6">
                                          <label> Agent</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="p_agent_remittance_commission" value={this.state.p_agent_remittance_commission} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                 </FormGroup>
                                 <FormGroup>
                                    <Row>
                                       <Col md="6">
                                          <label> Distributor</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="p_distributor_remittance_commission" value={this.state.p_distributor_remittance_commission} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                 </FormGroup>
                                 {/* <FormGroup>
                                                <hr />
                                                <Row>
                                                    <Col md="6">
                                                        <label> Max Amount Transfer (in &#8377;)</label>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="fieldinput">
                                                            <input type="number" className="form-control" name="max_transfer_amount" value={this.state.max_transfer_amount} onChange={(e) => { this.inputChange(e) }} min="0" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md="6">
                                                        <label> Max Wallet Balance For User (in &#8377;)</label>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="fieldinput">
                                                            <input type="number" className="form-control" name="max_wallet_balance_user" value={this.state.max_wallet_balance_user} onChange={(e) => { this.inputChange(e) }} min="0" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md="6">
                                                        <label> Max Remittance Wallet Balance for User (in &#8377;)</label>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="fieldinput">
                                                            <input type="number" className="form-control" name="max_remit_balance_user" value={this.state.max_remit_balance_user} onChange={(e) => { this.inputChange(e) }} min="0" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md="6">
                                                        <label> Max Wallet Balance for Dealer (in &#8377;)</label>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="fieldinput">
                                                            <input type="number" className="form-control" name="max_wallet_balance_dealer" value={this.state.max_wallet_balance_dealer} onChange={(e) => { this.inputChange(e) }} min="0" />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <hr />
                                                <Row>
                                                    <Col md="6">
                                                        <label> Max Remittance Wallet Balance for Dealer (in &#8377;)</label>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="fieldinput">
                                                            <input type="number" className="form-control" name="max_remit_balance_dealer" value={this.state.max_remit_balance_dealer} onChange={(e) => { this.inputChange(e) }} min="0" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md="6">
                                                        <label> Map Api Key </label>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="fieldinput">
                                                            <input type="text" className="form-control" name="map_api_key" value={this.state.map_api_key} onChange={(e) => { this.inputChange(e) }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </FormGroup>
                                                */}
                                 <FormGroup>
                                    <h2 className="g_heading">Referral Cashback settings</h2>
                                    <Row>
                                       <Col md="6">
                                          <label>Referral Cashback Status</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <select className="form-control" name="referral_cashback_status" value={this.state.referral_cashback_status} onChange={(e) => { this.inputChange(e) }}>
                                                <option value="off">off</option>
                                                <option value="on">on</option>
                                             </select>
                                          </div>
                                       </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                       <Col md="6">
                                          <label>Referral Cashback Type</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <select className="form-control" name="referral_cashback_type" value={this.state.referral_cashback_type} onChange={(e) => { this.inputChange(e) }}>

                                                <option value="0">First Payment</option>
                                                <option value="1">Without Payment</option>
                                             </select>
                                          </div>
                                       </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                       <Col md="6">
                                          <label> Referral Cashback (in &#8377;)</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="referral_cashback" value={this.state.referral_cashback} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>
                                    <hr />
                                    <h2 className="g_heading">Joining Cashback settings</h2>
                                    <Row>
                                       <Col md="6">
                                          <label>Joining Cashback Status</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <select className="form-control" name="joining_cashback_status" value={this.state.joining_cashback_status} onChange={(e) => { this.inputChange(e) }}>
                                                <option value="off">off</option>
                                                <option value="on">on</option>
                                             </select>
                                          </div>
                                       </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                       <Col md="6">
                                          <label>Joining Cashback Type</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <select className="form-control" name="joining_cashback_type" value={this.state.joining_cashback_type} onChange={(e) => { this.inputChange(e) }}>

                                                <option value="0">First Payment</option>
                                                <option value="1">Without Payment</option>
                                             </select>
                                          </div>
                                       </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                       <Col md="6">
                                          <label> Joining Cashback (in &#8377;)</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="number" className="form-control" name="joining_cashback" value={this.state.joining_cashback} onChange={(e) => { this.inputChange(e) }} min="0" />
                                          </div>
                                       </Col>
                                    </Row>


                                 </FormGroup>
                                 <FormGroup>
                                    <h2 className="g_heading">Certificate Details</h2>



                                    <Row>
                                       <Col md="6">
                                          <label>Certificate Heading</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="text" className="form-control" name="certificate_heading" value={this.state.certificate_heading} onChange={(e) => { this.inputChange(e) }} />
                                          </div>
                                       </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                       <Col md="6">
                                          <label>Certificate Name</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="text" className="form-control" name="certificate_name" value={this.state.certificate_name} onChange={(e) => { this.inputChange(e) }} />
                                          </div>
                                       </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                       <Col md="6">
                                          <label>Certificate Image</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="file" className="form-control" name="certificate_image" onChange={(e) => { this.onFileChange(e) }} style={{ 'opacity': '1', 'margin': 'auto' }} />

                                          </div>
                                       </Col>
                                       {this.state.certificate_image && <img src={imageUrl + this.state.certificate_image} alt=" " style={{ width: "50px" }} />}
                                    </Row>
                                 </FormGroup>

                                 <hr />
                                 <br></br>
                                 <FormGroup>
                                    <h2 className="g_heading">Share Detail</h2>



                                    <Row>
                                       <Col md="6">
                                          <label>Text</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="text" className="form-control" name="share_text" value={this.state.share_text} onChange={(e) => { this.inputChange(e) }} />
                                          </div>
                                       </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                       <Col md="6">
                                          <label> Image</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="file" className="form-control" name="share_image" onChange={(e) => { this.onFileChangeShare(e) }} style={{ 'opacity': '1', 'margin': 'auto' }} />

                                          </div>
                                       </Col>
                                       {this.state.share_image && <img src={imageUrl + this.state.share_image} alt=" " style={{ width: "50px" }} />}
                                    </Row>
                                 </FormGroup>


                                 <hr />
                                 <br></br>
                                 <FormGroup>
                                    <h2 className="g_heading">Refer & Earn Detail</h2>



                                    <Row>
                                       <Col md="6">
                                          <label>Text</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="text" className="form-control" name="refer_text" value={this.state.refer_text} onChange={(e) => { this.inputChange(e) }} />
                                          </div>
                                       </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                       <Col md="6">
                                          <label> Image</label>
                                       </Col>
                                       <Col md="4">
                                          <div className="fieldinput">
                                             <input type="file" className="form-control" name="refer_image" onChange={(e) => { this.onFileChangeRefer(e) }} style={{ 'opacity': '1', 'margin': 'auto' }} />

                                          </div>
                                       </Col>
                                       {this.state.refer_image && <img src={imageUrl + this.state.refer_image} alt=" " style={{ width: "50px" }} />}
                                    </Row>
                                 </FormGroup>
                              </Col>
                           </Row>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </div>
         </>
      );
   }
}

export default Settings;
