import React from "react";
import {
    Row,
    Modal,
} from "reactstrap";
import Slider from "react-slick";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Axios from "axios";
import swal from 'sweetalert';
import SimpleCrypto from "simple-crypto-js";
import ServiceModal from "./child-components/ServiceModal";
import Marquee from "react-fast-marquee";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }

    ]
};
const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}
const noticeshow = sessionStorage.getItem('noticeshow') ? sessionStorage.getItem("noticeshow") : null;


class Userdashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            banners: [],
            notice: [],
            napel_service: [],
            categories: [],
            providers: [],
            check_plans: [],
            cat_name: "",
            category_id: "",
            mobile: "",
            amount: "",
            min: "",
            max: "",
            extra_charge: "",
            service_provider_id: "",
            plan_type: "ALL",
            plans: [],
            offers: [],
            service_code: "",
            show_offer: false,
            show_plan: false,
            postpaid: false,
            Indiadth: false,
            typefixed: false,
            typerange: false,
            typecheck: false,
            Indiabill: false,
            subisu: false,
            subisu_plan: null,
            bill_details: null,
            plans_type: "",
            provider_details: null,
            remittance_sum: 0,
            remittance_count: 0,
            dmt_transactions_count: 0,
            dmt_transactions_sum: 0,
            nepal_recharge: [],
            indian_recharge: [],
            customer_id: "",
            plans: [],
            fields: [],
            circle: "",
            operator: "",
            use_balance: "wallet_balance",
            show_cat: true,
            show_provider: false,
            service_charges: 0,
            typedthcheck: false,
            RefStan: "",
            bill_number: "",
            due_date: "",
            user_info: "",
            typeinternetcheck: false,
            sc_number: "",
            typenea: false,
            branch_code: "",
            branch: [],
            group: [],
            bill_amount: "",
            notices:"",
            headers: {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access")),
                    Accept: 'application/json'
                }
            },
            service_modal: false,
            recharge_access: true,
            userpermission: sessionStorage.getItem('userpermission') ? JSON.parse(sessionStorage.getItem("userpermission")) : null,


        }
    }
    checkAccess(page) {
        var ok = false;
        if (this.state.userpermission) {
            this.state.userpermission.map((val) => {
                if (val.page_name === page) {
                    ok = true;
                }
            });
        }
        return ok;
    }
    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");

        }
        this.checkAvailability();
        this.getData();
        if (noticeshow !== 'yes') {
            this.getactivenotice();
        }

        //   sessionStorage.setItem("text", Cryp.decrypt(sessionStorage.getItem("_access")));

    }

    checkAvailability = () => {
        // Axios.post(apiUrl + "/user/check/service/allowed", { service_id: 3 }, this.state.headers).then(res => {
        //     if (res.data.success) {
        //         this.setState({ recharge_access: true })
        //     }
        // })
    }
    toggleServiceModal = e => {
        this.setState({ service_modal: !this.state.service_modal })
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleInputoperater = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.category_id === 8) {
                this.get_offers();
            }


        });

    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }


    getData = () => {
        Axios.get(`${apiUrl}/user/web/dashboard`, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            var user = res.data.data.user;
            this.setState({
                banners: res.data.data.top_banners,
                napel_service: res.data.data.popular_service_nepal,
                user: user,
                categories: res.data.data.categories,
                group: res.data.data.group,
                remittance_sum: res.data.data.remittance_sum,
                remittance_count: res.data.data.remittance_count,
                dmt_transactions_count: res.data.data.dmt_transactions_count,
                dmt_transactions_sum: res.data.data.dmt_transactions_sum,
                nepal_recharge: res.data.data.nepal_recharge,
                indian_recharge: res.data.data.indian_recharge,
                notices:res.data.data.notice,
                service_charges: user.npl_recharge_charges ? +user.npl_recharge_charges : 0
            });
            sessionStorage.setItem(process.env.REACT_APP_USER_DETAILS, Cryp.encrypt(res.data.data.user));
        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }

    cancelprovider = (e) => {


        this.setState({
            providers: [],
            show_provider: false,
            show_cat: true,
            cat_name: "",
            category_id: ""

        });

    }
    selectplan = (e, price) => {


        this.setState({
            amount: price,
        });

    }

    cancel_recharge = (e) => {

        this.setState({
            show_provider: false,
            postpaid: false,
            Indiadth: false,
            show_cat: true,
            cat_name: "",
            category_id: "",
            mobile: "",
            amount: "",
            service_provider_id: "",
            circle: "",
            operator: "",
        });

    }

    select_provider = (e, operator, details) => {
        if (!this.state.recharge_access) {
            this.setState({ service_modal: true });
            return false;
        }
        if (this.state.category_id === 7) {
            this.setState({
                show_provider: false,
                postpaid: true,
                operator: operator,
                provider_details: details,
            });
        }
        if (this.state.category_id === 9) {
            this.setState({
                show_provider: false,
                Indiadth: true,
                operator: operator,
                provider_details: details,
            });
            // alert(operator);
        }
        if (details.plan_type === "fixed" && details.filter === "nepal") {

            this.setState({
                show_provider: false,
                typefixed: true,
                operator: operator,
                provider_details: details,
                service_provider_id: details.id,
                plans: details.plans,
                fields: details.fields,
            });
        }
        if (details.plan_type === "range" && details.filter === "nepal") {
            if (details.company_code === "596") {
                this.setState({
                    show_provider: false,
                    typerange: true,
                    operator: operator,
                    provider_details: details,
                    service_provider_id: details.id,
                    plans: details.plans,
                    fields: details.fields,
                    subisu: true,
                });
            } else {
                this.setState({
                    show_provider: false,
                    typerange: true,
                    operator: operator,
                    provider_details: details,
                    service_provider_id: details.id,
                    plans: details.plans,
                    fields: details.fields,
                });
            }

        }

        if (details.plan_type === "check" && details.category_id === 2) {

            if (details.provider_name === 'NTC Data Package') {
                var type = 'ntc_data_service';
            }
            if (details.provider_name === 'NTC Voice Package') {
                var type = 'ntc_voice_service';
            }
            if (details.provider_name === 'NTC Recommended Package') {
                var type = 'ntc_recommended_service';
            }

            if (details.company_code === "78") {
                this.setState({
                    show_provider: false,
                    typecheck: true,
                    operator: operator,
                    provider_details: details,
                    service_provider_id: details.id,
                    plans_type: "NCell_Data",
                    fields: details.fields,
                }, () =>
                    this.checkplans('NCell_Data')
                );
            }
            if (details.company_code !== "78") {
                this.setState({
                    show_provider: false,
                    typecheck: true,
                    operator: operator,
                    provider_details: details,
                    service_provider_id: details.id,
                    plans_type: "NTC",
                    fields: details.fields,
                }, () =>
                    this.checkplans(type)

                );
            }


        }

        if (details.plan_type === "check" && details.category_id === 3) {
            this.setState({
                show_provider: false,
                typedthcheck: true,
                operator: operator,
                provider_details: details,
                service_provider_id: details.id,
                fields: details.fields,
            });
        }
        if (details.plan_type === "check" && details.category_id === 4 && details.company_code !== '598') {
            this.setState({
                show_provider: false,
                typeinternetcheck: true,
                operator: operator,
                provider_details: details,
                service_provider_id: details.id,
                fields: details.fields,
            });
        }
        if (details.company_code === '598') {
            this.setState({
                show_provider: false,
                typenea: true,
                operator: operator,
                provider_details: details,
                service_provider_id: details.id,
                fields: details.fields,
            }, () =>
                this.getbranch()

            );
        }

        if (this.state.category_id !== 9 && this.state.category_id !== 7 && details.filter === "India") {
            //  alert(details);

            this.setState({
                show_provider: false,
                Indiabill: true,
                operator: operator,
                provider_details: details,
            });
        }
    }



    serviceprovider = (category) => {

        var category_id = category.id;
        var cat_name = category.category_name;
        if (category_id === 10) {
            window.location.href = "remittance";
            return false;
        }
        else if (category_id === 27) {
            window.location.href = "prabhu-remittance";
            return false;
        }
        else if (category_id === 28) {
            window.location.href = "add-money";
            return false;
        }
        else if (category_id === 17) {
            window.location.href = "money-transfer";
            return false;
        }
        else if (category_id === 12) {
            window.location.href = "become-saathi";
            return false;
        }
        else if (category_id === 18) {
            window.location.href = "list?type=kyc";
            return false;
        }

        else if (category_id === 26) {
            window.location.href = "report";
            return false;
        }
        else if (category_id === 15) {
            window.open('https://forms.gle/QF8S7iovZo4vaMUcA', '_blank').focus();
            return false;
        }

        else if (category_id === 13) {
            window.open('https://forms.gle/UKp1nLafAyeY54QWA', '_blank').focus();
            return false;
        }

        else if (category_id === 14) {
            window.open('https://forms.gle/NvYzt8e5SD5iWp2g9', '_blank').focus();
            return false;
        }
        else if (category.comming_soon === "active") {
            swal({ title: "Coming Soon", text: "We are getting this ready.", icon: "info", buttons: false, timer: 4000 });
            return false;
        }
        else if (!this.state.recharge_access) {
            this.setState({ service_modal: true });
            return false;
        }
        else {
            var data = {
                category_id: category_id
            }
            Axios.post(`${apiUrl}/user/service/providers`, data, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({
                    providers: res.data.data.providers,
                    show_provider: true,
                    show_cat: false,
                    cat_name: cat_name,
                    category_id: category_id,
                });

            }, err => {
                swal("", "Session expired. Please login again.").then(o => {
                    sessionStorage.clear();
                    this.props.history.push("/");
                })
            })
        }
    }

    handleInputmobile = e => {
        this.setState({
            mobile: e.target.value
        }, () => {
            if (this.state.mobile.length === 10) {
                this.autooperator();
            }
        });
    }
    cancel_indiabill = (e) => {

        this.setState({
            show_provider: true,
            bill_details: null,
            Indiabill: false,
            service_code: "",
            mobile: "",
            service_provider_id: "",
            operator: "",
            amount: "",
            provider_details: null,
            plans: [],
            fields: [],
        });

    }

    fetchbill = () => {

        var data = {
            number: this.state.mobile,
            operator: this.state.operator,
        }
        Axios.post(`${apiUrl}/prbbps/bill/info`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                // Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            this.setState({
                bill_details: res.data.data ? res.data.data : "",
                amount: res.data.data ? res.data.data.dueAmount : "",
                //  circle:data.circle,
            });

        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }


    pay_indiabill = () => {
        if (this.state.mobile === "") {
            swal("", "Please enter  mobile number.", "error");
            return false;
        }
        if (this.state.operator === "") {
            swal("", "Please select  operator.", "error");
            return false;
        }
        if (this.state.circle === "") {
            swal("", "Please select  circle.", "error");
            return false;
        }
        if (this.state.amount === "") {
            swal("", "Please enter  amount.", "error");
            return false;
        }
        var id = "";
        var data = {
            customer_id: this.state.mobile,
            service_provider_id: "",
            amount: this.state.amount,
            circle: this.state.circle,
            use_balance: this.state.use_balance,
        }

        this.state.providers.map((pro) => {
            if (pro.company_code === this.state.operator) {
                data.service_provider_id = pro.id;
            }
        })
        Axios.post(`${apiUrl}/prbbps/bill/pay`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            swal("", res.data.message)

        }, err => {
            swal("", err.message)
        })
    }
    autooperator = () => {
        if (this.state.mobile.length === 10) {


            if (this.state.mobile.length !== 10) {
                swal("", "Please enter valid mobile number.", "error");
                return false;
            }
            var data = {
                mobile: this.state.mobile
            }
            Axios.post(`${apiUrl}/indian-recharge/auto/operator`, data, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                    // Authorization: "Bearer " + this.state.token
                }
            }).then(res => {
                if (this.state.category_id === 9) {
                    this.setState({
                        //operator: res.data.data ? res.data.data.operator : "",
                        circle: res.data.data ? res.data.data.circle : "",

                    });
                } else {
                    this.setState({
                        operator: res.data.data ? res.data.data.operator : "",
                        circle: res.data.data ? res.data.data.circle : "",

                    });
                }


            }, err => {
                swal("", "Session expired. Please login again.").then(o => {
                    sessionStorage.clear();
                    this.props.history.push("/");
                })
            })
        }
    }
    handleInputplan = e => {
        this.setState({
            plan_type: e.target.value
        }, () => {
            if (this.state.plan_type !== "") {
                this.get_plans();
            }
        });
    }

    rechargenow = () => {
        if (this.state.mobile === "") {
            swal("", "Please enter  mobile number.", "error");
            return false;
        }
        if (this.state.mobile.length !== 10) {
            swal("", "Please enter valid mobile number.", "error");
            return false;
        }
        if (this.state.operator === "") {
            swal("", "Please select  operator.", "error");
            return false;
        }
        if (this.state.circle === "") {
            swal("", "Please select  circle.", "error");
            return false;
        }
        if (this.state.amount === "") {
            swal("", "Please enter  amount.", "error");
            return false;
        }
        var id = "";
        var data = {
            customer_id: this.state.mobile,
            //  service_provider_id:id,
            amount: this.state.amount,
            circle: this.state.circle,
            use_balance: this.state.use_balance,
        }

        this.state.providers.map((pro) => {
            if (pro.company_code === this.state.operator) {
                data.service_provider_id = pro.id;
            }
        })
        Axios.post(`${apiUrl}/indian-recharge/pay`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            swal("", res.data.message)

        }, err => {
            swal("", err.message)
        })
    }

    get_offers = () => {
        if (this.state.mobile === "") {
            swal("", "Please enter  mobile number.", "error");
            return false;
        }
        if (this.state.mobile.length !== 10) {
            swal("", "Please enter valid mobile number.", "error");
            return false;
        }
        if (this.state.operator === "") {
            swal("", "Please select  operator.", "error");
            return false;
        }
        var data = {
            mobile: this.state.mobile,
            operator: this.state.operator,

        }
        Axios.post(`${apiUrl}/indian-recharge/get/offers`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            this.setState({
                offers: res.data.data ? res.data.data.RDATA : [],
                show_offer: true,
                show_plan: false,

            });

        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    get_plans = () => {
        if (this.state.mobile === "") {
            swal("", "Please enter  mobile number.", "error");
            return false;
        }
        if (this.state.mobile.length !== 10) {
            swal("", "Please enter valid mobile number.", "error");
            return false;
        }
        if (this.state.circle === "") {
            swal("", "Please select  circle.", "error");
            return false;
        }
        if (this.state.operator === "") {
            swal("", "Please select  operator.", "error");
            return false;
        }
        var data = {
            circle: this.state.circle,
            operator: this.state.operator,
            type: 'ALL'
        }
        Axios.post(`${apiUrl}/indian-recharge/get/plans`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                // Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            this.setState({
                plans: res.data.data,
                show_offer: false,
                show_plan: true,

            });

        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    //plan type fixed//

    cancel_typefixed = (e) => {

        this.setState({
            show_provider: true,
            postpaid: false,
            Indiadth: false,
            service_code: "",
            mobile: "",
            service_provider_id: "",
            operator: "",
            typefixed: false,
            provider_details: null,
            plans: [],
            fields: [],
        });

    }

    handleInputfixedmobile = e => {
        this.setState({
            mobile: e.target.value
        });
    }

    handleInputfixedplan = e => {
        this.setState({
            service_code: e.target.value,

        });
    }

    nepalfixedrecharge = () => {

        if (this.state.mobile === "") {
            swal("", "Please enter  mobile number.", "error");
            return false;
        }
        if (this.state.service_code === "") {
            swal("", "Please select  plan.", "error");
            return false;
        }
        var id = "";


        var data = {
            phone: this.state.mobile,
            category_id: this.state.category_id,
            service_provider_id: this.state.service_provider_id,
            use_balance: this.state.use_balance,
            company_code: this.state.operator,
            service_code: this.state.service_code
        }

        this.state.plans.map((pro) => {
            if (pro.service_code === this.state.service_code) {
                data.amount = pro.amount;
            }
        })
        Axios.post(`${apiUrl}/recharge/nepal`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            swal("", res.data.message)

        }, err => {
            swal("", err.message)
        })
    }


    //plan type range//

    cancel_typerange = (e) => {

        this.setState({
            show_provider: true,
            service_code: "",
            mobile: "",
            amount: "",
            service_provider_id: "",
            operator: "",
            typerange: false,
            provider_details: null,
            plans: [],
            fields: [],
            subisu: false,
        });

    }




    handleInputrangeamount = e => {

        var persentage = (e.target.value * this.state.service_charges / 100);

        var persentage_new = Number(e.target.value) + Number(persentage);
        this.state.plans.map((pro) => {
            this.setState({
                service_code: pro.service_code,
                min: pro.min,
                max: pro.max,
            });
        })

        this.setState({
            amount: e.target.value,
            extra_charge: "Amount " + persentage_new
        });

    }


    subisucheck = () => {

        if (this.state.mobile === "") {
            swal("", "Please enter  mobile number.", "error");
            return false;
        }
        if (this.state.customer_id === "") {
            swal("", "Please enter  customer Id.", "error");
            return false;
        }

        var id = "";


        var data = {
            mobile_number: this.state.mobile,
            category_id: this.state.category_id,
            service_provider_id: this.state.service_provider_id,

            company_code: this.state.operator,
            service_code: this.state.service_code
        }


        Axios.post(`${apiUrl}/recharge/subisu/payment/check`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            swal("", res.data.message)
            this.setState({
                subisu_plan: res.data.data,
                amount: res.data.data.amount
            })
        }, err => {
            swal("", err.message)
        })
    }
    subisupay = () => {

        if (this.state.mobile === "") {
            swal("", "Please enter  mobile number.", "error");
            return false;
        }
        if (this.state.customer_id === "") {
            swal("", "Please enter  customer Id.", "error");
            return false;
        }

        var id = "";
        if (this.state.amount < this.state.min) {
            swal("", "Amount should be" + this.state.min + " to " + this.state.max, "error");
            return false;
        }
        if (this.state.amount > this.state.max) {
            swal("", "Amount should be" + this.state.min + " to " + this.state.max, "error");
            return false;
        }
        var id = "";


        var data = {
            mobile_number: this.state.mobile,
            category_id: this.state.category_id,
            service_provider_id: this.state.service_provider_id,
            use_balance: this.state.use_balance,
            company_code: this.state.operator,
            service_code: this.state.service_code,
            bill_number: this.state.subisu_plan ? this.state.subisu_plan.bill_number : "",
            RefStan: this.state.subisu_plan ? this.state.subisu_plan.RefStan : "",
            package_id: "",
            amount:this.state.amount
        }
        Axios.post(`${apiUrl}/recharge/subisu/pay`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            swal("", res.data.message)

        }, err => {
            swal("", err.message)
        })
    }
    nepalrangerecharge = () => {

        if (this.state.mobile === "") {
            swal("", "Please enter  mobile number.", "error");
            return false;
        }
        if (this.state.amount === "") {
            swal("", "Please enter  amount.", "error");
            return false;
        }
        if (this.state.amount < this.state.min) {
            swal("", "Amount should be" + this.state.min + " to " + this.state.max, "error");
            return false;
        }
        if (this.state.amount > this.state.max) {
            swal("", "Amount should be" + this.state.min + " to " + this.state.max, "error");
            return false;
        }
        var id = "";


        var data = {
            phone: this.state.mobile,
            category_id: this.state.category_id,
            service_provider_id: this.state.service_provider_id,
            use_balance: this.state.use_balance,
            company_code: this.state.operator,
            service_code: this.state.service_code,
            amount: this.state.amount
        }


        Axios.post(`${apiUrl}/recharge/nepal`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            swal("", res.data.message)

        }, err => {
            swal("", err.message)
        })
    }

    ///fixed type range end///

    //check type //

    cancel_ntc = (e) => {

        this.setState({
            show_provider: true,
            service_code: "",
            mobile: "",
            amount: "",
            service_provider_id: "",
            operator: "",
            typecheck: false,
            provider_details: null,
            plans_type: "",
            plans: [],
            fields: [],
        });

    }
    checkplans = (type) => {
        var data = {
            type: type
        }
        if (type === 'NCell_Data') {
            Axios.get(`${apiUrl}/recharge/ncell/data/plans`, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({
                    check_plans: res.data.data,
                });

            }, err => {
                swal("", "Session expired. Please login again.").then(o => {
                    sessionStorage.clear();
                    this.props.history.push("/");
                })
            })
        } else {
            Axios.post(`${apiUrl}/recharge/ntc/data/plans`, data, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({
                    check_plans: res.data.data,
                });

            }, err => {
                swal("", "Session expired. Please login again.").then(o => {
                    sessionStorage.clear();
                    this.props.history.push("/");
                })
            })
        }


    }

    selectnclplan = (e, plan) => {
        var persentage = (plan.amount * 2 / 100);

        var persentage_new = persentage + plan.amount;


        this.setState({

            service_code: plan.service_code,

            amount: plan.amount,
            extra_charge: "Amount " + persentage_new
        });

    }
    selectntcplan = (e, plan) => {
        var persentage = (plan.price * 2 / 100);

        var persentage_new = persentage + plan.price;


        this.setState({

            service_code: plan.product_code,

            amount: plan.price,
            extra_charge: "Amount " + persentage_new
        });

    }

    nepalntcncellrecharge = () => {

        if (this.state.mobile === "") {
            swal("", "Please enter  mobile number.", "error");
            return false;
        }
        if (this.state.amount === "") {
            swal("", "Please select  plan.", "error");
            return false;
        }
        var data = {
            phone: this.state.mobile,
            category_id: this.state.category_id,
            service_provider_id: this.state.service_provider_id,
            use_balance: this.state.use_balance,
            company_code: this.state.operator,
            service_code: this.state.service_code
        }


        Axios.post(`${apiUrl}/recharge/nepal`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            swal("", res.data.message)

        }, err => {
            swal("", err.message)
        })
    }
    /***********nepal check dth************** */
    cancel_checkdth = (e) => {

        this.setState({
            show_provider: true,
            service_code: "",
            customer_id: "",
            amount: "",
            service_provider_id: "",
            operator: "",
            typedthcheck: false,
            provider_details: null,
            plans_type: "",
            plans: [],
            fields: [],
            RefStan: "",
            bill_number: "",
            due_date: "",
            user_info: "",
        });

    }
    payment_check = () => {


        if (this.state.customer_id === "") {
            swal("", "Please enter" + this.state.fields[0], "error");
            return false;
        }

        var id = "";


        var data = {
            customer_id: this.state.customer_id,
            category_id: this.state.category_id,
            service_provider_id: this.state.service_provider_id,
            company_code: this.state.operator,
        }

        if (this.state.operator === '799') {
            data.service_code = 1;
        } else {
            data.service_code = 0;
        }

        Axios.post(`${apiUrl}/recharge/nepal/payment/check`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {
            console.log("checkpayment", res.data.data)
            swal("", res.data.message)
            this.setState({
                plans: res.data.data ? res.data.data.packages : [],
                amount: res.data.data ? res.data.data.amount : "",
                RefStan: res.data.data ? res.data.data.RefStan : "",
                bill_number: res.data.data ? res.data.data.bill_number : "",
                due_date: res.data.data ? res.data.data.due_date : "",
                user_info: res.data.data ? res.data.data.user_info : "",
            })
        }, err => {
            swal("", err.message)
        })
    }

    selectdthcheckplan = (e, plan) => {
        var persentage = (plan.amount * 2 / 100);

        var persentage_new = persentage + plan.amount;


        this.setState({

            service_code: plan.service_code,
            package_id: plan.id,
            amount: plan.amount,
            extra_charge: "Amount " + persentage_new
        });

    }





    nepaldthcheckrecharge = () => {

        if (this.state.customer_id === "") {
            swal("", "Please enter" + this.state.fields[0], "error");
            return false;
        }
        if (this.state.amount === "") {
            swal("", "Please select  plan.", "error");
            return false;
        }
        if (this.state.amount === "0") {
            swal("", "Please select  plan.", "error");
            return false;
        }
        var data = {
            customer_id: this.state.customer_id,
            category_id: this.state.category_id,
            service_provider_id: this.state.service_provider_id,
            use_balance: this.state.use_balance,
            company_code: this.state.operator,
            service_code: this.state.service_code,
            bill_number: this.state.bill_number,
            RefStan: this.state.RefStan,
            amount: this.state.amount,

        }
        if (this.state.package_id !== '') {
            data.package_id = this.state.package_id
        }
        if (this.state.operator === '799') {
            data.service_code = 1;
        } else {
            data.service_code = 0;
        }

        Axios.post(`${apiUrl}/recharge/nepal/internet/pay`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            swal("", res.data.message)

        }, err => {
            swal("", err.message)
        })
    }
    /***********nepal check internetpay************** */
    cancel_typeinternetcheck = (e) => {

        this.setState({
            show_provider: true,
            service_code: "",
            customer_id: "",
            amount: "",
            service_provider_id: "",
            operator: "",
            typeinternetcheck: false,
            provider_details: null,
            plans_type: "",
            plans: [],
            fields: [],
            RefStan: "",
            bill_number: "",
            due_date: "",
            user_info: "",
        });

    }

    /*********nea recharge************** */
    cancel_nea = (e) => {

        this.setState({
            show_provider: true,
            service_code: "",
            customer_id: "",
            amount: "",
            service_provider_id: "",
            operator: "",
            typenea: false,
            provider_details: null,
            plans_type: "",
            plans: [],
            fields: [],
            RefStan: "",
            bill_number: "",
            due_date: "",
            user_info: "",
            sc_number: "",
            branch: [],
            branch_code: "",


        });

    }

    getbranch = () => {

        Axios.get(`${apiUrl}/recharge/electricity/offices`, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //Authorization: "Bearer " + this.state.token
            }
        }).then(res => {

            this.setState({
                branch: res.data.data.offices,

            });

        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    checknea_bill_check = () => {

        if (this.state.sc_number === "") {
            swal("", "Please enter sc number", "error");
            return false;
        }
        if (this.state.branch_code === "") {
            swal("", "Please select branch", "error");
            return false;
        }
        if (this.state.customer_id === "") {
            swal("", "Please enter customer id", "error");
            return false;
        }

        var id = "";


        var data = {
            customer_id: this.state.customer_id,
            category_id: this.state.category_id,
            service_provider_id: this.state.service_provider_id,
            company_code: this.state.operator,
            service_code: this.state.branch_code,
            sc_number: this.state.sc_number
        }



        Axios.post(`${apiUrl}/recharge/nepal/electricity/bill/query`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {
            console.log("checkpayment", res.data.data)
            swal("", res.data.message)
            this.setState({
                // plans: res.data.data.packages,
                amount: res.data.data.Amount,
                bill_amount: res.data.data.Amount,
                RefStan: res.data.data.RefStan,
                bill_number: res.data.data.bill_number,
                due_date: res.data.data.DueDate,
                user_info: res.data.data.name,
            })
        }, err => {
            swal("", err.message)
        })
    }
    pay_nea_bill = () => {

        if (this.state.sc_number === "") {
            swal("", "Please enter sc number", "error");
            return false;
        }
        if (this.state.branch_code === "") {
            swal("", "Please select branch", "error");
            return false;
        }
        if (this.state.customer_id === "") {
            swal("", "Please enter customer id", "error");
            return false;
        }
        if (this.state.amount > 0) {
            swal("", "Please enter valid amount.", "error");
            return false;
        }
        var id = "";


        var data = {
            customer_id: this.state.customer_id,
            category_id: this.state.category_id,
            service_provider_id: this.state.service_provider_id,
            company_code: this.state.operator,
            service_code: this.state.branch_code,
            sc_number: this.state.sc_number,
            bill_number: this.state.bill_number,
            bill_amount: this.state.bill_amount,
            amount: this.state.amount,
            RefStan: this.state.RefStan
        }



        Axios.post(`${apiUrl}/recharge/nepal/electricity/pay`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                //  Authorization: "Bearer " + this.state.token
            }
        }).then(res => {
            console.log("checkpayment", res.data.data)

            swal("", res.data.message)
        }, err => {
            swal("", err.message)
        })
    }


    getactivenotice = () => {
        Axios.get(`${apiUrl}/active-notice`, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("notice", res.data)

            this.setState({
                notice: res.data.data.task
            })
            this.toggleViewModal();
        })
    }

    toggleViewModal = () => {
        this.setState({
            viewModal: !this.state.viewModal
        });
    }
    dont_again = () => {
        sessionStorage.setItem("noticeshow", "yes");
        this.setState({
            viewModal: !this.state.viewModal
        });
    }
    render() {
        return <>
            <Userheader user={this.state.user} />
            <section className="main-sidebar_dash">
                {this.state.loading && <div className="the-loader">
                    <div className="spinner-border">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>}
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec3">

                            <div className="form-sec-dash">

                                {/* ##########################################   NEw html Here ################### */}
                                <section>
                                    <Row>
                                         {this.state.notices !== "" && 
                                        <Marquee text="swetha" style={{"background": "#0094d8", "color": "#fff", "height": "45px" }}>{this.state.notices}</Marquee>
                                         }
                                        <div className="col-lg-8 col-md-6 col-sm-6">
                                            <div className="form-sec-dash">
                                                {this.state.banners.length > 0 &&
                                                    <Slider {...settings} className="banner-slider">
                                                        {this.state.banners.map((banner, key) => {
                                                            return <div className="col-md-12 wrap_right" key={key}>
                                                                <div className="banner-image-dash ">
                                                                    <img onError={this.handleError} src={imageUrl + banner.image} alt="" />
                                                                </div>
                                                            </div>
                                                        })}
                                                    </Slider>
                                                }
                                            </div>
                                            <div className="card card-stats icons_wrap">


                                                {this.state.group.map((group, key) => {

                                                    return <div className="">
                                                        {this.state.show_cat && <>   <h3>{group}</h3>
                                                            <div className=" box_row box_row2">

                                                                {this.state.categories.map((categorie, key) => {
                                                                    //  {group === categorie.parents  

                                                                    var cna = ![17, 12].includes(categorie.id) && categorie.comming_soon === "active" ? "bg-danger" : "";
                                                                    console.log("this.checkAccess('become-saathi')", this.checkAccess('/become-saathi'))
                                                                    if (categorie.id === 12 && !this.checkAccess('/become-saathi')) {
                                                                        return null;
                                                                    }
                                                                    else {
                                                                        if (group === categorie.parents) {
                                                                            return <div className={"box col-md-2 " + cna} style={{ cursor: "pointer" }} onClick={e => { this.serviceprovider(categorie) }}>

                                                                                <div className="form-group">

                                                                                    <span>  {categorie.category_name}</span>
                                                                                    <img onError={this.handleError} src={imageUrl + categorie.category_icon} alt="" />
                                                                                </div>
                                                                            </div>
                                                                        } else {
                                                                            return null;
                                                                        }

                                                                    }
                                                                    //    }  
                                                                })}

                                                            </div></>}
                                                    </div>

                                                })}

                                                {this.state.show_provider && <div className="box_row form_pre">
                                                    {this.state.category_id !== 8 && <>
                                                        {this.state.providers.map((provider, key) => {
                                                            return <div className="col-md-2 pre_col_2" style={{ cursor: "pointer" }} onClick={e => { this.select_provider(e, provider.company_code, provider) }}>
                                                                <div className="form-group">
                                                                    <span>  {provider.provider_name}</span>
                                                                    <img src={imageUrl + provider.image} alt="" />
                                                                </div>
                                                            </div>
                                                        })}
                                                        <div className="col-md-12 form_pre_col_12">
                                                            <a href="javascript:void(0)" className="float-right btn btn-primary" onClick={e => { this.cancelprovider(e) }}>Cancel</a>&nbsp;&nbsp;
                                                        </div>
                                                    </>}
                                                    {this.state.category_id === 8 && <>

                                                        <div className="col-md-6">
                                                            <div className="form-group"  >
                                                                <label>Mobile Number</label>
                                                                <input type="text" placeholder="Enter Mobile Number" onChange={e => { this.handleInputmobile(e) }} name="mobile" value={this.state.mobile} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" >
                                                                <label>  Operator</label>
                                                                <select type="text" onChange={e => { this.handleInputoperater(e) }} name="operator" value={this.state.operator}  >
                                                                    <option value="">Select Operator</option>
                                                                    {this.state.providers.map((provider, key) => {
                                                                        return <option value={provider.company_code}  >{provider.provider_name}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" >
                                                                <label>Circle</label>
                                                                <select type="text" onChange={e => { this.handleInput(e) }} name="circle" value={this.state.circle}  >
                                                                    <option value="">Select Circle</option>
                                                                    {this.state.providers[0].circle.map((circle, key) => {
                                                                        return <option value={circle.circle} >{circle.state}</option>

                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" >
                                                                <label>Amount</label>
                                                                <input type="text" placeholder="Enter Recharge Amount" onChange={e => { this.handleInput(e) }} name="amount" value={this.state.amount} />

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="send-bt-dash">
                                                                <a href="javascript:void(0)" onClick={e => { this.cancel_recharge(e) }}>Cancel</a>&nbsp;&nbsp;
                                                                <a href="javascript:void(0)" onClick={e => { this.get_offers(e) }}>Get Offers</a>
                                                                <a href="javascript:void(0)" onClick={e => { this.rechargenow(e) }}>Recharge</a>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group" >

                                                                <a href="javascript:void(0)" onClick={e => { this.get_offers(e) }}> Offers</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" >

                                                                <a href="javascript:void(0)" onClick={e => { this.get_plans(e) }}> Plans</a>
                                                            </div>
                                                        </div>

                                                        {this.state.show_plan && <>
                                                            <div className="col-md-6">
                                                                <div className="form-group" >
                                                                    <label>Plan Type</label>
                                                                    <select type="text" onChange={e => { this.handleInputplan(e) }} name="plan_type" value={this.state.plan_type}  >
                                                                        <option value="ALL">All Plan</option>
                                                                        <option value="TUP" >Top-up Recharge</option>
                                                                        <option value="FTT" >Full Talk-time Recharge</option>
                                                                        <option value="DATA" >Data Recharge</option>

                                                                        <option value="SMS" >SMS Pack Recharge</option>
                                                                        <option value="LSC" >Local/STD/ISD Call Recharge</option>
                                                                        <option value="PUP" >Other Recharge</option>
                                                                        <option value="RMG" >National/International Roaming Recharge</option>

                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="table-responsive">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            {/* <th>S.No</th> */}
                                                                            <th>Type</th>
                                                                            <th>Description</th>
                                                                            <th>Amount</th>
                                                                            <th>Talktime</th>
                                                                            <th>Validity</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="table-space">

                                                                        {this.state.plans.map((plan, key) => {

                                                                            return <tr>
                                                                                {plan.type === this.state.plan_type && <>
                                                                                    {/* <td>{key + 1}</td> */}
                                                                                    <td>{plan.type}</td>
                                                                                    <td>{plan.Description}</td>
                                                                                    <td>{plan.Amount}</td>
                                                                                    <td>{plan.Talktime}</td>
                                                                                    <td>{plan.Validity}</td>
                                                                                    <td><a href="javascript:void(0)" onClick={e => { this.selectplan(e, plan.Amount) }}>Select</a></td>
                                                                                </>}
                                                                                {this.state.plan_type === 'ALL' && <>
                                                                                    {/* <td>{key + 1}</td> */}
                                                                                    <td>{plan.type}</td>
                                                                                    <td>{plan.Description}</td>
                                                                                    <td>{plan.Amount}</td>
                                                                                    <td>{plan.Talktime}</td>
                                                                                    <td>{plan.Validity}</td>
                                                                                    <td><a href="javascript:void(0)" onClick={e => { this.selectplan(e, plan.Amount) }}>Select</a></td>
                                                                                </>}
                                                                            </tr>


                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </>}
                                                        {this.state.show_offer && <>
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>

                                                                            <th>Offer Text</th>
                                                                            <th>Description</th>
                                                                            <th>Amount</th>

                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="table-space">

                                                                        {this.state.offers.map((offer, key) => {

                                                                            return <tr>

                                                                                <td>{offer.ofrtext}</td>
                                                                                <td>{offer.logdesc}</td>
                                                                                <td>{offer.price}</td>

                                                                                <td><a href="javascript:void(0)" onClick={e => { this.selectplan(e, offer.price) }}>Select</a></td>


                                                                            </tr>


                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </>}

                                                    </>}
                                                </div>}

                                                {/* recharge screens */}
                                                {this.state.postpaid && <>
                                                    <div className="col-md-6">
                                                        <div className="form-group"  >
                                                            <label>Mobile Number</label>
                                                            <input type="text" placeholder="Enter Mobile Number" onChange={e => { this.handleInputmobile(e) }} name="mobile" value={this.state.mobile} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group" >
                                                            <label>  Operator</label>
                                                            <select type="text" onChange={e => { this.handleInputoperater(e) }} name="operator" value={this.state.operator}  >
                                                                <option value="">Select Operator</option>
                                                                {this.state.providers.map((provider, key) => {
                                                                    return <option value={provider.company_code}  >{provider.provider_name}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group" >
                                                            <label>Circle</label>
                                                            <select type="text" onChange={e => { this.handleInput(e) }} name="circle" value={this.state.circle}  >
                                                                <option value="">Select Circle</option>
                                                                {this.state.providers[0].circle.map((circle, key) => {
                                                                    return <option value={circle.circle} >{circle.state}</option>

                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group" >
                                                            <label>Amount</label>
                                                            <input type="text" placeholder="Enter Recharge Amount" onChange={e => { this.handleInput(e) }} name="amount" value={this.state.amount} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10 ">
                                                        <div className="send-bt-dash">
                                                            <a href="javascript:void(0)" onClick={e => { this.cancel_recharge(e) }}>Cancel</a>&nbsp;&nbsp;

                                                            <a href="javascript:void(0)" onClick={e => { this.rechargenow(e) }}>Recharge</a>
                                                        </div>
                                                    </div>

                                                </>}
                                                {this.state.Indiadth && <>
                                                    <div className="col-md-6">
                                                        <div className="form-group"  >
                                                            <label>Customer Id</label>
                                                            <input type="text" placeholder="Enter Customer Id" onChange={e => { this.handleInput(e) }} name="mobile" value={this.state.mobile} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group" >
                                                            <label>Circle</label>
                                                            <select type="text" onChange={e => { this.handleInput(e) }} name="circle" value={this.state.circle}  >
                                                                <option value="">Select Circle</option>
                                                                {this.state.providers[0].circle.map((circle, key) => {
                                                                    return <option value={circle.circle} >{circle.state}</option>

                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group" >
                                                            <label>Amount</label>
                                                            <input type="text" placeholder="Enter Recharge Amount" onChange={e => { this.handleInput(e) }} name="amount" value={this.state.amount} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <div className="send-bt-dash">
                                                            <a href="javascript:void(0)" onClick={e => { this.cancel_recharge(e) }}>Cancel</a>&nbsp;&nbsp;

                                                            <a href="javascript:void(0)" onClick={e => { this.rechargenow(e) }}>Recharge</a>
                                                        </div>
                                                    </div>
                                                </>}
                                                {this.state.Indiabill && <>
                                                    <div className="col-md-6">
                                                        <div className="form-group"  >
                                                            <label>Account/Mobile Number</label>
                                                            <input type="text" placeholder="Enter Customer Id" onChange={e => { this.handleInput(e) }} name="mobile" value={this.state.mobile} />
                                                        </div>
                                                    </div>
                                                    {this.state.bill_details && <>
                                                        {this.state.bill_details.status === "SUCCESS" && <>
                                                            <div className="col-md-6">
                                                                <div className="form-group" >
                                                                    <label>Circle</label>
                                                                    <select type="text" onChange={e => { this.handleInput(e) }} name="circle" value={this.state.circle}  >
                                                                        <option value="">Select Circle</option>
                                                                        {this.state.providers[0].circle.map((circle, key) => {
                                                                            return <option value={circle.circle} >{circle.state}</option>

                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group" >
                                                                    <label>Amount</label>
                                                                    <input type="text" placeholder="Enter  Amount" onChange={e => { this.handleInput(e) }} name="amount" value={this.state.amount} />
                                                                </div>
                                                            </div>

                                                        </>}
                                                    </>}
                                                    <div className="col-md-10">
                                                        <div className="send-bt-dash">

                                                            <a href="javascript:void(0)" onClick={e => { this.cancel_indiabill(e) }}>Cancel</a>&nbsp;&nbsp;
                                                            {this.state.bill_details && <>
                                                                {this.state.bill_details.status === "SUCCESS" && <>
                                                                    <a href="javascript:void(0)" onClick={e => { this.pay_indiabill(e) }}>Pay Bill</a>
                                                                </>}
                                                            </>}
                                                            {!this.state.bill_details && <>
                                                                <a href="javascript:void(0)" onClick={e => { this.fetchbill(e) }}>Process</a>
                                                            </>}

                                                        </div>
                                                    </div>

                                                    {this.state.bill_details && <>

                                                        <div className="col-md-6">
                                                            <div className="form-group"  >
                                                                {this.state.bill_details.status === "SUCCESS" && <>

                                                                    <label>Operator: {this.state.bill_details.operator ? this.state.bill_details.operator : ""} </label><br></br>
                                                                    <label>Customer Name:- {this.state.bill_details.customerName ? this.state.bill_details.customerName : ""} </label><br></br>
                                                                    <label>Due Amount:- {this.state.bill_details.dueAmount ? this.state.bill_details.dueAmount : ""} </label><br></br>
                                                                    <label>Due Date:- {this.state.bill_details.dueDate ? this.state.bill_details.dueDate : ""} </label><br></br>
                                                                    <label>RefId:- {this.state.bill_details.refId ? this.state.bill_details.refId : ""} </label><br></br>
                                                                    <label>Bill Date:- {this.state.bill_details.billDate ? this.state.bill_details.billDate : ""} </label>
                                                                </>}
                                                                {this.state.bill_details.status !== "SUCCESS" && <>
                                                                    <p>No Bill Found</p>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </>}
                                                </>}
                                                {this.state.typefixed && <>
                                                    <div className="col-md-6">
                                                        <div className="form-group"  >
                                                            <label>{this.state.fields[0]}</label>
                                                            <input type="text" placeholder={this.state.fields[0]} onChange={e => { this.handleInputfixedmobile(e) }} name="mobile" value={this.state.mobile} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group" >
                                                            <label>  {this.state.fields[1]}</label>
                                                            <select type="text" onChange={e => { this.handleInputfixedplan(e) }} name="service_code" value={this.state.service_code}  >
                                                                <option value="">Select Plan</option>
                                                                {this.state.plans.map((plan, key) => {
                                                                    return <option value={plan.service_code} label={plan.amount}  >{plan.amount}</option>
                                                                })}
                                                            </select>
                                                            {this.state.service_charges > 0 && <span className="text-danger" >Processing fee @{this.state.service_charges}% will be charged extra</span>}
                                                        </div>
                                                    </div>


                                                    <div className="col-md-10">
                                                        <div className="send-bt-dash">
                                                            <a href="javascript:void(0)" onClick={e => { this.cancel_typefixed(e) }}>Cancel</a>&nbsp;&nbsp;

                                                            <a href="javascript:void(0)" onClick={e => { this.nepalfixedrecharge(e) }}>Recharge</a>
                                                        </div>
                                                    </div>
                                                </>}

                                                {this.state.typerange && <>
                                                    {this.state.subisu && <>
                                                        <div className="col-md-6">
                                                            <div className="form-group" >
                                                                <label>{this.state.fields[0]}</label>
                                                                <input type="text" placeholder={this.state.fields[0]} onChange={e => { this.handleInput(e) }} name="customer_id" value={this.state.customer_id} />

                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <div className="form-group"  >
                                                                <label>{this.state.fields[1]}</label>
                                                                <input type="text" placeholder={this.state.fields[1]} onChange={e => { this.handleInputfixedmobile(e) }} name="mobile" value={this.state.mobile} />

                                                            </div>
                                                        </div>

                                                        {this.state.subisu_plan &&
                                                            <div className="col-md-6">
                                                                <div className="form-group"  >
                                                                    <label>{this.state.fields[2]}</label>
                                                                    <input type="text" placeholder={this.state.fields[2]} onChange={e => { this.handleInputrangeamount(e) }} name="amount" value={this.state.amount} />
                                                                    {this.state.service_charges > 0 && <span className="text-danger" >Processing fee @{this.state.service_charges}% will be charged extra</span>}
                                                                </div>
                                                            </div>

                                                        }
                                                        <div className="col-md-10">
                                                            <div className="send-bt-dash">
                                                                <a href="javascript:void(0)" onClick={e => { this.cancel_typerange(e) }}>Cancel</a>&nbsp;&nbsp;
                                                                {this.state.subisu_plan &&
                                                                    <a href="javascript:void(0)" onClick={e => { this.subisupay(e) }}>Process</a>
                                                                }
                                                                {!this.state.subisu_plan &&
                                                                    <a href="javascript:void(0)" onClick={e => { this.subisucheck(e) }}>Process</a>
                                                                }
                                                            </div>
                                                        </div>

                                                    </>}
                                                    {!this.state.subisu && <>
                                                        <div className="col-md-6">
                                                            <div className="form-group"  >
                                                                <label>{this.state.fields[0]}</label>
                                                                <input type="text" placeholder={this.state.fields[0]} onChange={e => { this.handleInputfixedmobile(e) }} name="mobile" value={this.state.mobile} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" >
                                                                <label>{this.state.fields[1]}</label>
                                                                <input type="text" placeholder={this.state.fields[1]} onChange={e => { this.handleInputrangeamount(e) }} name="amount" value={this.state.amount} />
                                                                {this.state.service_charges > 0 && <span className="text-danger" >Processing fee @{this.state.service_charges}% will be charged extra</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="send-bt-dash">
                                                                <a href="javascript:void(0)" onClick={e => { this.cancel_typerange(e) }}>Cancel</a>&nbsp;&nbsp;
                                                                <a href="javascript:void(0)" onClick={e => { this.nepalrangerecharge(e) }}>Process</a>
                                                            </div>
                                                        </div>
                                                    </>}
                                                </>}

                                                {this.state.typecheck && <>
                                                    <div className="col-md-6">
                                                        <div className="form-group"  >
                                                            <label>{this.state.fields[0]}</label>
                                                            <input type="text" placeholder={this.state.fields[0]} onChange={e => { this.handleInputfixedmobile(e) }} name="mobile" value={this.state.mobile} />
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-group" >
                                                            <label> Select Package</label>
                                                            <input type="text" placeholder="Select Package" onChange={e => { this.handleInputrangeamount(e) }} name="amount" value={this.state.amount} />
                                                            {this.state.service_charges > 0 && <span className="text-danger" >Processing fee @{this.state.service_charges}% will be charged extra</span>}
                                                        </div>
                                                    </div>


                                                    <div className="col-md-10">
                                                        <div className="send-bt-dash">
                                                            <a href="javascript:void(0)" onClick={e => { this.cancel_ntc(e) }}>Cancel</a>&nbsp;&nbsp;

                                                            <a href="javascript:void(0)" onClick={e => { this.nepalrangerecharge(e) }}>Recharge</a>
                                                        </div>
                                                    </div>

                                                    {this.state.check_plans && <>
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>

                                                                        <th>Text</th>

                                                                        <th>Amount</th>

                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-space">
                                                                    {this.state.plans_type === 'NTC' && <>
                                                                        {this.state.check_plans.map((offer, key) => {

                                                                            return <tr>
                                                                                <td>{offer.package_name} <br></br> {offer.description}</td>
                                                                                <td>{offer.price}</td>
                                                                                <td><a href="javascript:void(0)" onClick={e => { this.selectntcplan(e, offer) }}>Select</a></td>
                                                                            </tr>
                                                                        })}
                                                                    </>}
                                                                    {this.state.plans_type === 'NCell_Data' && <>
                                                                        {this.state.check_plans.map((offer, key) => {

                                                                            return <tr>
                                                                                <td>{offer.name} <br></br> {offer.first_view}</td>
                                                                                <td>{offer.amount}</td>
                                                                                <td><a href="javascript:void(0)" onClick={e => { this.selectnclplan(e, offer) }}>Select</a></td>
                                                                            </tr>
                                                                        })}
                                                                    </>}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>}
                                                </>}




                                                {this.state.typedthcheck && <>
                                                    <div className="col-md-6">
                                                        <div className="form-group"  >

                                                            <label>{this.state.fields[0]}</label>
                                                            <input type="text" className="form-control" placeholder={this.state.fields[0]} onChange={e => { this.handleInput(e) }} name="customer_id" value={this.state.customer_id} />
                                                        </div>
                                                    </div>
                                                    {this.state.RefStan !== "" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group"  >
                                                                <label> Select Package</label>
                                                                <input type="text" className="form-control" placeholder="Select Package" onChange={e => { this.handleInputrangeamount(e) }} name="amount" value={this.state.amount} />
                                                                {this.state.service_charges > 0 && <span className="text-danger" >Processing fee @{this.state.service_charges}% will be charged extra</span>}
                                                            </div>
                                                        </div>
                                                    }


                                                    <div className="col-md-10">
                                                        <div className="form-group" >
                                                            <button type="button" class="btn btn-primary btn_back" onClick={e => { this.cancel_checkdth(e) }} >Back</button>
                                                            {this.state.RefStan === "" &&

                                                                <button type="button" onClick={e => { this.payment_check(e) }} class="btn btn-primary btn_sum">Process</button>
                                                            }
                                                            {this.state.RefStan !== "" &&

                                                                <button type="button" onClick={e => { this.nepaldthcheckrecharge(e) }} class="btn btn-primary btn_sum">Recharge</button>
                                                            }

                                                        </div>
                                                    </div>





                                                    <div className="row">
                                                        {this.state.user_info !== "" &&
                                                            <div className="col"  >
                                                                <label>User Info</label>
                                                                <p>{this.state.user_info}</p>
                                                            </div>
                                                        }
                                                        {this.state.RefStan !== "" &&
                                                            <div className="col"  >
                                                                <label>RefStan</label>
                                                                <p>{this.state.RefStan}</p>
                                                            </div>
                                                        }
                                                        {this.state.bill_number !== "" &&
                                                            <div className="col"  >
                                                                <label>Bill Number</label>
                                                                <p>{this.state.bill_number}</p>
                                                            </div>
                                                        }
                                                        {this.state.bill_number !== "" &&
                                                            <div className="col"  >
                                                                <label>Due Date</label>
                                                                <p>{this.state.bill_number}</p>
                                                            </div>
                                                        }
                                                        {this.state.amount !== "" &&
                                                            <div className="col"  >
                                                                <label>Amount</label>
                                                                <p>{this.state.amount}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                    {this.state.plans.length > 0 && <>
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>

                                                                        <th>ID</th>

                                                                        <th>Amount</th>

                                                                        <th>value</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-space">

                                                                    {this.state.plans.map((offer, key) => {

                                                                        return <tr>
                                                                            <td>{offer.id} </td>
                                                                            <td>{offer.currency} {offer.amount}</td>

                                                                            <td>{offer.value}</td>
                                                                            <td><a href="javascript:void(0)" onClick={e => { this.selectdthcheckplan(e, offer) }}>Select</a></td>
                                                                        </tr>
                                                                    })}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>}

                                                </>}


                                                {this.state.typeinternetcheck && <>



                                                    <div className="col-md-10">
                                                        <div className="form-group" >
                                                            <label>{this.state.fields[0]}</label>
                                                            <input type="text" className="form-control" placeholder={this.state.fields[0]} onChange={e => { this.handleInput(e) }} name="customer_id" value={this.state.customer_id} />
                                                        </div>
                                                    </div>
                                                    {this.state.RefStan !== "" &&
                                                        <div className="col-md-10">
                                                            <div className="form-group" >
                                                                <label> Select Package</label>
                                                                <input type="text" className="form-control" placeholder="Select Package" onChange={e => { this.handleInputrangeamount(e) }} name="amount" value={this.state.amount} />
                                                                {this.state.service_charges > 0 && <span className="text-danger" >Processing fee @{this.state.service_charges}% will be charged extra</span>}
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state.service_charges > 0 && <span className="text-danger" >Processing fee @{this.state.service_charges}% will be charged extra</span>}
                                                    <div className="col-md-10">
                                                        <div className="form-group" >
                                                            <button type="button" class="btn btn-primary btn_back" onClick={e => { this.cancel_typeinternetcheck(e) }} >Back</button>
                                                            {this.state.RefStan === "" &&

                                                                <button type="button" onClick={e => { this.payment_check(e) }} class="btn btn-primary btn_sum">Process</button>
                                                            }
                                                            {this.state.RefStan !== "" &&

                                                                <button type="button" onClick={e => { this.nepaldthcheckrecharge(e) }} class="btn btn-primary btn_sum">Recharge</button>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        {this.state.user_info !== "" &&
                                                            <div className="col"  >
                                                                <label>User Info</label>
                                                                <p>{this.state.user_info}</p>
                                                            </div>
                                                        }
                                                        {this.state.RefStan !== "" &&
                                                            <div className="col"  >
                                                                <label>RefStan</label>
                                                                <p>{this.state.RefStan}</p>
                                                            </div>
                                                        }
                                                        {this.state.bill_number !== "" &&
                                                            <div className="col"  >
                                                                <label>Bill Number</label>
                                                                <p>{this.state.bill_number}</p>
                                                            </div>
                                                        }
                                                        {this.state.bill_number !== "" &&
                                                            <div className="col"  >
                                                                <label>Due Date</label>
                                                                <p>{this.state.bill_number}</p>
                                                            </div>
                                                        }
                                                        {this.state.amount !== "" &&
                                                            <div className="col"  >
                                                                <label>Amount</label>
                                                                <p>{this.state.amount}</p>
                                                            </div>
                                                        }
                                                    </div>


                                                    {this.state.plans.length > 0 && <>
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>

                                                                        <th>ID</th>

                                                                        <th>Amount</th>

                                                                        <th>value</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-space">

                                                                    {this.state.plans.map((offer, key) => {

                                                                        return <tr>
                                                                            <td>{offer.id} </td>
                                                                            <td>{offer.currency} {offer.amount}</td>

                                                                            <td>{offer.value}</td>
                                                                            <td><a href="javascript:void(0)" onClick={e => { this.selectdthcheckplan(e, offer) }}>Select</a></td>
                                                                        </tr>
                                                                    })}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>}
                                                </>}
                                                {this.state.typenea && <>


                                                    <div className="col-md-6">
                                                        <div className="form-group"  >
                                                            <label>Amount Rs</label>
                                                            <input type="number" className="form-control" placeholder="Amount" onChange={e => { this.handleInputrangeamount(e) }} name="amount" value={this.state.amount} />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group"  >
                                                            <label>SC Number</label>
                                                            <input type="text" className="form-control" placeholder="SC Number" onChange={e => { this.handleInput(e) }} name="sc_number" value={this.state.sc_number} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group"  >
                                                            <label>Customer Id</label>
                                                            <input type="text" className="form-control" placeholder="Customer Id" onChange={e => { this.handleInput(e) }} name="customer_id" value={this.state.customer_id} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group"  >
                                                            <label>Branch</label>
                                                            <select className="form-control" placeholder="Customer Id" onChange={e => { this.handleInput(e) }} name="branch_code" value={this.state.branch_code} >
                                                                <option>Select Branch</option>
                                                                {this.state.branch.map((branchs, key) => {
                                                                    return <option value={branchs.office_code}>{branchs.office_name}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {this.state.service_charges > 0 && <span className="text-danger" >Processing fee @{this.state.service_charges}% will be charged extra</span>}
                                                    <div className="col-md-10">
                                                        <div className="form-group"  >
                                                            <button type="button" class="btn btn-primary btn_back" onClick={e => { this.cancel_nea(e) }} >Back</button>
                                                            {this.state.RefStan === "" &&

                                                                <button type="button" onClick={e => { this.checknea_bill_check(e) }} class="btn btn-primary btn_sum">Process</button>
                                                            }
                                                            {this.state.RefStan !== "" &&

                                                                <button type="button" onClick={e => { this.pay_nea_bill(e) }} class="btn btn-primary btn_sum">Pay Now</button>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {this.state.user_info !== "" &&
                                                            <div className="col"  >
                                                                <label>User Info</label>
                                                                <p>{this.state.user_info}</p>
                                                            </div>
                                                        }
                                                        {this.state.RefStan !== "" &&
                                                            <div className="col"  >
                                                                <label>RefStan</label>
                                                                <p>{this.state.RefStan}</p>
                                                            </div>
                                                        }
                                                        {this.state.bill_number !== "" &&
                                                            <div className="col"  >
                                                                <label>Bill Number</label>
                                                                <p>{this.state.bill_number}</p>
                                                            </div>
                                                        }
                                                        {this.state.bill_number !== "" &&
                                                            <div className="col"  >
                                                                <label>Due Date</label>
                                                                <p>{this.state.bill_number}</p>
                                                            </div>
                                                        }
                                                        {this.state.bill_amount !== "" &&
                                                            <div className="col"  >
                                                                <label>Bill Amount</label>
                                                                <p>{this.state.bill_amount}</p>
                                                            </div>
                                                        }
                                                    </div>



                                                </>}
                                            </div>
                                        </div>


                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                            <div className="card card-stats">
                                                <div className="card-header">
                                                    <div className="card-icon">
                                                        <i className="material-icons">Remittance</i>
                                                    </div>
                                                    <p className="card-category">This Month</p>
                                                    <h3 className="card-title">&#8377; {this.state.remittance_sum}</h3>
                                                </div>
                                                <div className="card-footer">
                                                    <div className="stats">
                                                        <i className="material-icons">Total Transection</i> {this.state.dmt_transactions_count}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card card-stats">
                                                <div className="card-header">
                                                    <div className="card-icon">
                                                        <i className="material-icons">Domestic Money Transfer</i>
                                                    </div>
                                                    <p className="card-category">This Month</p>
                                                    <h3 className="card-title">&#8377; {this.state.remittance_sum}</h3>
                                                </div>
                                                <div className="card-footer">
                                                    <div className="stats">
                                                        <i className="material-icons">Total Transection</i> {this.state.remittance_count}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card card-stats dash_table">
                                                <div className="card-header">
                                                    <h2 className="mdl-card">Recharge Transaction</h2>
                                                </div>
                                                <table className="display nowrap table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th>Mobile</th>
                                                            <th>Status</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.nepal_recharge.map((hs, key) => {
                                                            return <tr>
                                                                <td>{hs.phone}</td>
                                                                <td>{hs.status}</td>
                                                                <td>&#8377; {hs.phone}</td>
                                                            </tr>

                                                        })}
                                                        {this.state.indian_recharge.map((hs, key) => {
                                                            return <tr>
                                                                <td>{hs.phone}</td>
                                                                <td>{hs.status}</td>
                                                                <td>&#8377; {hs.phone}</td>
                                                            </tr>

                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Row>
                                </section>
                                {/* ##########################################   NEw html Here ################### */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Userfooter />
            <ServiceModal {...this.state} toggleServiceModal={this.toggleServiceModal} apiUrl={apiUrl} />

            {this.state.notice.length > 0 && <>
                <Modal modalClassName="modal noticemodal" isOpen={this.state.viewModal} toggle={this.toggleViewModal} size="lg" >
                    <div className="modal-header">
                        <h1 className="title" >Notice
                        </h1>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleViewModal} style={{ zIndex: "999" }}>
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        &nbsp;	&nbsp;	&nbsp;
                    </div>
                    <div className="modal-body">
                        <div className="col-md-12">
                            <Slider {...settings} className="banner-slider">
                                {this.state.notice.map((val, key) => {
                                    return <div key={key} className="view-popup notice-section">
                                        {val.image && <img src={imageUrl + val.image} alt=" " />}
                                        <h5>{val.task}</h5><p>{val.description}</p>
                                    </div>
                                })}
                            </Slider>
                            <small className="float-right">
                                <input type="checkbox" onClick={this.dont_again} className="btn-fill btn btn-primary" />Don't Show Again
                            </small>
                        </div>
                    </div>
                </Modal>

            </>
            }
        </>;
    }
}

export default Userdashboard;
function NextArrow() {
    return <div className="slick-next"><i className="i-next"></i><span className="sr-only sr-only-focusable"></span></div>;
}
function PrevArrow() {
    return <div className="slick-prev"><i className="i-prev"></i><span className="sr-only sr-only-focusable"></span></div>;
}
