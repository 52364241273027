/*eslint-disable*/
import React from "react";
import { NavLink} from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import env from "variables/constants";

var ps;
var apiUrl = env.url;
var imageUrl = env.img_url;
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = {
      page_name: "Dashboard"
    }
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    var x = sessionStorage.getItem("page_name");
    this.setState({
      page_name: x ? x : "Dashboard"
    })
  }
  toggleSidebar = (e, name) => {
    if (e) e.preventDefault();
    this.setState({ page_name: name });
    sessionStorage.setItem("page_name", name);
    this.props.toggleSidebar(e, name);
  };
  render() {
    const { bgColor, routes, rtlActive, logo } = this.props;

    return (
      <>

        <div className="new_sidebar open">
          <div className="logo-details">
            <i className='bx bxl-c-plus-plus'></i>
            <span className="logo_name"></span>
          </div>
          <ul className="nav-links">
            {routes.map((prop, key) => {
              var xClass = "";
              if (this.state.page_name === prop.name) {
                xClass = "showMenu";
              }
              // console.log(prop)
              var image = prop.image;
              if (prop.redirect) return null;
              if (!prop.show) return null;
              if (prop.path) {
                return (
                  <li className="mb-3" key={key}>
                    <NavLink to={prop.layout + prop.path} onClick={() => this.toggleSidebar(null, prop.name)} activeClassName="active" >
                      <img className="ml-2" src={image} alt="" style={{ height: "20px", filter: "opacity(0.7)" }} />
                      <span className="link_name" style={{ padding: "5px", marginLeft: "10px" }}>{prop.name}</span>
                    </NavLink>
                  </li>
                );
              }
              else {
                return (
                  <li className={xClass} key={key}>
                    <div className="iocn-link">
                      <a href="" onClick={e => this.toggleSidebar(e, prop.name)}>
                        <img className="ml-2" src={image} alt="" style={{ height: "20px", width: "20px", filter: "opacity(0.7)" }} />
                        <span className="link_name" style={{ padding: "5px", marginLeft: "10px" }}>{prop.name}</span>
                      </a>
                      <i className='fa fa-angle-right'></i>
                    </div>
                    <ul className="sub-menu">
                      {prop.dropdown.map((drop, ke) => {
                        if (!drop.show) return null;
                        else {
                          return <li key={ke}><NavLink to={drop.layout + drop.path} activeClassName="active">{drop.name}</NavLink></li>
                        }
                      })}
                    </ul>
                  </li>
                )
              }
            })}

          </ul>
        </div>
      </>
    );
  }
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: "primary",
  routes: [{}]
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(["primary", "blue", "green"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
};

export default Sidebar;
