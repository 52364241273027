import React from "react";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import { Modal } from "reactstrap";
import ReceiptModel from "./ReceiptModel";
import swal from 'sweetalert';
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Remittancehistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: false,
            modalState: false,
            selected_detail: null,
            history: [],
            modify_modal: false,
            receiver_name: "",
            receiver_address: "",
            receiver_gender: "",
            receiver_mobile_number: "",
            receiver_city: "",
            relationshipw: "",
            source_fund: "",
            payment_type: "",
            bank_id: "",
            bank_branch_id: "",
            bank_account_number: "",
            otp_token: "",
            referance_number: "",
            remittance_reason: "",
            show_otp: false,
            otp: "",
            action: "modify",
            cancel_reason: "",
            banks_list: [],
            branch_list: [],
            gender: [],
            relationship: [],
            source_of_fund: [],
            purpose_of_remittance: [],
            transaction_cancel_reason: [],
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }

    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");

        }
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
      
        this.getData();
        this.getStaticData();
       
    }
    getRemimttanceDetails = (e = null, deta) => {
        if (e) e.preventDefault()
        this.setState({ loading: true })
        var data = {
            reftype: 2,
            icn_number: deta.reference_id
        }
        Axios.post(`${apiUrl}/remittance/ime-forex/trnsaction/inquiry`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            if (res.data.success) {
                this.setState(res.data.details);
                this.setState({ modalState: true, loading: false })
                console.log("response inquiry", res)
            }
        }, err => {
            this.setState({ loading: false })
        })
    }
    toggleModal = (e = null,) => {
        this.setState({
            modalState: !this.state.modalState,
        })
    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
    getStaticData = () => {
        Axios.get(`${apiUrl}/remittance/ime-forex/get/all/static/data`, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("statisc data response", res)
            this.setState(res.data.data)
        }, err => {
            console.log("error static data", err)
        })
    }
    getData = (page = 1) => {
        var data = {};
        Axios.post(`${apiUrl}/user/remittance/history/web?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res)
            this.setState({
                history: res.data.data.remittance.data,
                pagination_data: res.data.data.remittance
            })
        },err=>{
            swal("","Session expired. Please login again.").then(o=>{
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    downloadRemitpdf = (e, id) => {
        e.preventDefault();
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/ime-forex/receipt", { remittance_id: id }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("pdf ", res)
            this.setState({ loading: false })
            if (res.data.success) {
                window.open(res.data.data.pdfurl, "_blank");
            }
        })
    }
    getGender = (val) => {
        var gen = this.state.gender;
        var x = val;
        gen.map((g, v) => {
            if (g.option_id === val || g.value === val) {
                x = g.option_id;
            }
        })
        return x;
    }
    deletModal = (e, det) => {
        e.preventDefault();
        swal({
            title: "Do you want to cancel this transaction?",
            text: "",
            icon: "warning",
            buttons: ["No", "Yes"],
        }).then((ok) => {
            if (ok) {
                var data = {
                    action: "delete",
                    icn_number: det.icn_number
                }
                this.setState({ loading: true });
                Axios.post(`${apiUrl}/remittance/ime-forex/send/otp`, data, {
                    headers: {
                        Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                    }
                }).then(res => {
                    this.setState({ loading: false })
                    if (res.data.success) {
                        swal({ text: "OTP has been sent on registered number.", icon: "success" });
                        this.setState({
                            otp_token: res.data.otptoken,
                            referance_number: res.data.referance_number,
                            show_otp: true,
                            selected_detail: det,
                            action: "delete",
                            modify_modal: true
                        })
                    }
                    else {
                        swal({ text: res.data.message, icon: "error" });
                    }
                });
            }
        })
    }
    modifyModal = (e, det) => {
        e.preventDefault();
        console.log(det)
        this.setState({
            modify_modal: true,
            receiver_name: det.ReceiverName,
            receiver_mobile_number: det.ReceiverMobileNo,
            receiver_gender: this.getGender(det.ReceiverGender),
            receiver_address: det.ReceiverAddress,
            relationshipw: det.Relationship,
            source_fund: det.SourceOfFund,
            bank_id: det.BankId,
            bank_branch_id: det.BankBranchId,
            bank_account_number: det.BankAccountNumber,
            remittance_reason: det.PurposeOfRemittance,
            selected_detail: det
        })
    }
    toggleModifyModal = e => {
        this.setState({
            modify_modal: !this.state.modify_modal
        })
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    getOtp = (e) => {
        e.preventDefault();
        if (this.state.receiver_name === "") {
            swal({ text: "Please enter receiver name.", icon: "error" });
            return false;
        }
        if (this.state.receiver_mobile_number === "") {
            swal({ text: "Please enter receiver mobile number.", icon: "error" });
            return false;
        }
        if (this.state.receiver_gender === "") {
            swal({ text: "Please select receiver gender.", icon: "error" });
            return false;
        }
        if (this.state.relationshipw === "") {
            swal({ text: "Please select reletionship.", icon: "error" });
            return false;
        }
        if (this.state.source_fund === "") {
            swal({ text: "Please select source of fund.", icon: "error" });
            return false;
        }

        if (this.state.remittance_reason === "") {
            swal({ text: "Please select purpose of remittance.", icon: "error" });
            return false;
        }
        if (this.state.selected_detail.PaymentType === "B") {
            if (this.state.bank_id === "") {
                swal({ text: "Please select bank.", icon: "error" });
                return false;
            }
            if (this.state.bank_branch_id === "") {
                swal({ text: "Please select bank branch.", icon: "error" });
                return false;
            }
            if (this.state.bank_account_number === "") {
                swal({ text: "Please enter bank account number.", icon: "error" });
                return false;
            }
        }
        var data = {
            action: "modify",
            icn_number: this.state.selected_detail.icn_number
        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/ime-forex/send/otp`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            if (res.data.success) {
                swal({ text: "OTP has been sent on registered number.", icon: "success" });
                this.setState({
                    otp_token: res.data.otptoken,
                    referance_number: res.data.referance_number,
                    show_otp: true,
                    action: "modify"
                })
            }
            else {
                swal({ text: res.data.message, icon: "error" });
            }
        })
    }
    handleInputOTP = e => {
        if (e.target.value.length <= 4) {
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            console.log(this.state.otp)
            return false;
        }
    }
    submitDeleteOtp = e => {
        e.preventDefault();
        var data = {
            icn_number: this.state.selected_detail.icn_number,
            cancel_reason: this.state.cancel_reason,
            otp: this.state.otp,
            otp_token: this.state.otp_token
        }
        if (this.state.cancel_reason === "") {
            swal("", "Please enter cancel reason.", "error");
            return false;
        }
        if (this.state.otp === "") {
            swal("", "Please enter OTP.", "error");
            return false;
        }
        if (this.state.otp.length < 4) {
            swal("", "OTP should be 4 digit long.", "error");
            return false;
        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/ime-forex/cancel/transaction`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            if (res.data.success) {
                this.getData(this.state.pagination_data.current_page);
                swal("", res.data.message, "success");
                this.setState({
                    aciotn: "modify",
                    show_otp: false,
                    otp: "",
                    selected_detail: null,
                    modify_modal: false,
                });
            }
            else {
                swal("", res.data.message, "error");
            }
        })
    }
    submitOtp = (e) => {
        e.preventDefault();
        var data = {
            icn_number: this.state.selected_detail.icn_number,
            receiver_name: this.state.receiver_name,
            receiver_gender: this.state.receiver_gender,
            receiver_address: this.state.receiver_address,
            relationship: this.state.Relationship,
            source_of_fund: this.state.source_fund,
            receiver_mobile: this.state.receiver_mobile_number,
            bank_id: this.state.bank_id,
            bank_branch_id: this.state.bank_branch_id,
            account_number: this.state.bank_account_number,
            remittance_reason: this.state.remittance_reason,
            otp: this.state.otp,
            otp_token: this.state.otp_token
        };
        if (this.state.otp === "") {
            swal("", "Please enter OTP.", "error");
            return false;
        }
        if (this.state.otp.length < 4) {
            swal("", "OTP should be 4 digit long.", "error");
            return false;
        }
        this.setState({ loading: true })

        Axios.post(`${apiUrl}/remittance/ime-forex/modify/transaction`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.setState({
                    modify_modal: false,
                    receiver_name: "",
                    receiver_address: "",
                    receiver_gender: "",
                    receiver_mobile_number: "",
                    receiver_city: "",
                    relationshipw: "",
                    source_fund: "",
                    payment_type: "",
                    bank_id: "",
                    bank_branch_id: "",
                    bank_account_number: "",
                    otp_token: "",
                    referance_number: "",
                    remittance_reason: "",
                    show_otp: false,
                    otp: "",
                    selected_detail: null,
                    modify_modal: false,
                });
                this.getData(this.state.pagination_data.current_page);
            } else {
                swal("", res.data.message, "error");
            }
        })

    }
    render() {
        return <>
            <Userheader user={this.state.user} />
            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="maine-table-dash-sec">
                                <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p> Remittance History </p>
                                        <span>{`Showing ${this.state.pagination_data.from} to ${this.state.pagination_data.to} from ${this.state.pagination_data.total} records`}</span>
                                    </h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Sender</th>
                                                <th>Receiver</th>
                                                <th>Collected Amount</th>
                                                <th>Send Amount</th>
                                                <th>Service Charge</th>
                                                <th>Method</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-space">
                                            {this.state.history.map((hs, key) => {
                                                return <Tabledata hs={hs} key={key} getRemimttanceDetails={this.getRemimttanceDetails} modifyModal={this.modifyModal} deletModal={this.deletModal} downloadRemitpdf={this.downloadRemitpdf} />
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination pagination_data={this.state.pagination_data} getData={this.getData} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ReceiptModel modalState={this.state.modalState} toggleModal={this.toggleModal} details={this.state.remittance} sender={this.state.sender} receiver={this.state.receiver} transaction={this.state.transaction} />
            <Modal modalClassName="modal" isOpen={this.state.modify_modal} toggle={this.toggleModifyModal} size="lg" >
                <div className="modal-header">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModifyModal} style={{ zIndex: "999" }} >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                </div>
                <div className="modal-body">
                    {this.state.show_otp && <div className="row">
                        {this.state.action === "delete" && <div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >Cancel Reason</label>
                                <select className="form-control" name="cancel_reason" onChange={e => { this.handleInput(e) }} value={this.state.cancel_reason} >
                                    <option value=""></option>
                                    {this.state.transaction_cancel_reason.map((reason, key) => {
                                        return <option value={reason.option_id}>{reason.value}</option>
                                    })}
                                </select>
                            </div>
                        </div>}
                        <div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >OTP</label>
                                <input type="number" className="form-control" name="otp" placeholder="Enter OTP" onChange={e => { this.handleInputOTP(e) }} value={this.state.otp} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="send-bt-dash">
                                {this.state.action === "modify" && <a href="" onClick={e => { this.submitOtp(e) }}>Submit</a>}
                                {this.state.action === "delete" && <a href="" onClick={e => { this.submitDeleteOtp(e) }}>Submit</a>}
                            </div>
                        </div>
                    </div>}

                    {!this.state.show_otp && <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >Receiver Name</label>
                                <input type="text" className="form-control" name="receiver_name" placeholder="Enter Name" onChange={e => { this.handleInput(e) }} value={this.state.receiver_name} />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >Receiver Mobile Number</label>
                                <input type="text" className="form-control" onChange={e => { this.handleInput(e) }} name="receiver_mobile_number" value={this.state.receiver_mobile_number} placeholder="Enter Mobile Number" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >Receiver Gender</label>
                                <select className="form-control" onChange={e => { this.handleInput(e) }} name="receiver_gender" value={this.state.receiver_gender}>
                                    <option value="">Select</option>
                                    {this.state.gender.map((gen, key) => {
                                        return <option value={gen.option_id} key={key}>{gen.value}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >Receiver Address</label>
                                <input type="text" className="form-control" name="receiver_address" placeholder="Enter Address" onChange={e => { this.handleInput(e) }} value={this.state.receiver_address} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >Relationship</label>
                                <select className="form-control" onChange={e => { this.handleInput(e) }} name="relationshipw" value={this.state.relationshipw}>
                                    <option value="">Select</option>
                                    {this.state.relationship.map((rel, key) => {
                                        return <option value={rel.option_id} key={key}>{rel.value}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >Source of Fund</label>
                                <select className="form-control" onChange={e => { this.handelPaymentType(e) }} name="source_fund" value={this.state.source_fund}>
                                    <option value="">Select</option>
                                    {this.state.source_of_fund.map((sf, key) => {
                                        return <option value={sf.option_id} key={key}>{sf.value}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >Purpose of Remittance</label>
                                <select className="form-control" onChange={e => { this.handelPaymentType(e) }} name="remittance_reason" value={this.state.remittance_reason}>
                                    <option value="">Select</option>
                                    {this.state.purpose_of_remittance.map((rr, key) => {
                                        return <option value={rr.option_id} key={key}>{rr.value}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        {this.state.payment_type === "B" && <><div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >Bank</label>
                                <select className="form-control" onChange={e => { this.handleBanksInput(e) }} name="bank_id" value={this.state.bank_id}>
                                    <option value="">Select</option>
                                    {this.state.banks_list.map((bank, key) => {
                                        return <option value={bank.bank_id} key={key}>{bank.bank}</option>
                                    })}

                                </select>
                            </div>
                        </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <img src="images/mobile_no.png" alt="" />
                                    <label >Bank Branch</label>
                                    <select className="form-control" onChange={e => { this.handleInput(e) }} name="bank_branch_id" value={this.state.bank_branch_id}>
                                        <option value="">Select</option>
                                        {this.state.branch_list.map((br, key) => {
                                            return <option value={br.branch_id} key={key}>{br.branch}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <img src="images/mobile_no.png" alt="" />
                                    <label >Bank Account Number</label>
                                    <input type="text" onChange={e => { this.handleInput(e) }} name="bank_account_number" value={this.state.bank_account_number} placeholder="Enter Bank Account Number" />
                                </div>
                            </div></>}

                        <div className="col-md-12">
                            <div className="send-bt-dash">
                                <a href="" onClick={e => { this.getOtp(e) }}>Submit</a>
                            </div>
                        </div>
                    </div>}
                </div>
            </Modal>
            <Userfooter />
        </>;
    }
}
export default Remittancehistory;
function Tabledata(props) {
    var hs = props.hs;
    var status = hs.status.toLowerCase();
    var modify = ['paid', 'cancel_request', 'cancel'].includes(status);
    var cls = ['cancel_request', 'cancel'].includes(status) ? " bg-warning text-white" : status !== "paid" ? "bg-light text-white" : "";
    return <><tr className="spacer" >
        <td colSpan=""></td>
    </tr>
        <tr>
            <td>{moment(hs.created_at).calendar()}</td>
            <td>{hs.sender.FirstName}<br />{hs.sender.MobileNo}</td>
            <td>{hs.ReceiverName}<br />{hs.ReceiverMobileNo}</td>
            <td><i className="fa fa-inr" aria-hidden="true"></i>{(+hs.CollectAmount)}</td>
            <td>NPR {(+hs.PayoutAmount)}</td>
            <td><i className="fa fa-inr" aria-hidden="true"></i>{(+hs.service_charge)}</td>
            <td>{hs.PaymentType === "C" ? "Cash" : "Bank"}</td>
            <td className="status-dash"><span className={cls}>{hs.status}</span></td>
            <td className="icon-sec">
                <a href="" onClick={(e) => { props.getRemimttanceDetails(e, hs) }} className="text-info">
                    <i className="fa fa-eye" area-hidden="true"></i>
                </a>

                <a href="" onClick={(e) => { props.downloadRemitpdf(e, hs.id) }} className="text-warning">
                    <i className="fa fa-file-pdf" area-hidden="true"></i>
                </a>
                {!modify && <a href="" onClick={(e) => { props.modifyModal(e, hs) }} className="text-info">
                    <i className="fa fa-edit" area-hidden="true"></i>
                </a>}
                {!modify && <a href="" onClick={(e) => { props.deletModal(e, hs) }} className="text-info">
                    <i className="fa fa-trash" area-hidden="true"></i>
                </a>}
            </td>
        </tr>
    </>;
}
