import React, { useEffect, useState } from "react";
import Axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import { State, City } from "country-state-city";
import Userfooter from '../Footer';
import Usersidebar from "../../Users/Usersidebar";
import myAppointment from '../../../assets/img/myAppointment.png';
import myApplication from '../../../assets/img/myApplication.png';
import checkEligbility from '../../../assets/img/checkEligbility.png';
import documentAdvisor from '../../../assets/img/documentAdvisor.png';
import bankLoan from '../../../assets/img/bankLoan.png';
import Vector from '../../../assets/img/Vector.png';
import Rectangle155 from '../../../assets/img/Rectangle 155.png';
import Rectangle156 from '../../../assets/img/Rectangle 156.png';
import Rectangle157 from '../../../assets/img/Rectangle 157.png';
import Rectangle158 from '../../../assets/img/Rectangle 158.png';
import Userheader from '../Header';
import SimpleCrypto from "simple-crypto-js";

const BecomeSathi = () => {
  const [step, setStep] = useState(0);
//   const [countryelected, setcountryelected] = useState("india");
  const [States, setStates] = useState(State?.getStatesOfCountry("IN"));
  const [Cities, setCities] = useState(City?.getCitiesOfState("IN", "DL"));
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [bookSaathiButton, setBookSaathiButton] = useState(false)

  const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const Cryp = new SimpleCrypto(_secretKey);
  
  const url = window.location.href;
  var apiUrl = process.env.REACT_APP_API_URL_LIVE;
  var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
  
  if (!url.includes('onlinesaathi.org')) {
      apiUrl = process.env.REACT_APP_API_URL;
      imageUrl = process.env.REACT_APP_IMAGE_URL;
  }

useEffect(() => {
  fetchData();
}, []);

const fetchData = async () => {
  try {
    const response = await Axios.get("https://api.onlinesaathi.org/api/v1/user/good/gov/home", {
      headers: {
        Authorization: "Bearer " + "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMGYzMWUzYWZiNThiZDI5ZGM2N2U3MDI5M2JkNzgzMzE0YWZjOGQ2ODUxMTQzMGM4MjU0MGJlMzhlYzIzYzFkYTExMWViNjljZDdjZTE0N2IiLCJpYXQiOjE3MDI2Mzk4NzUuNDUzNzQxMDczNjA4Mzk4NDM3NSwibmJmIjoxNzAyNjM5ODc1LjQ1Mzc0NjA4MDM5ODU1OTU3MDMxMjUsImV4cCI6MTczNDI2MjI3NS40NDg1MzkwMTg2MzA5ODE0NDUzMTI1LCJzdWIiOiIxNjQwOCIsInNjb3BlcyI6W119.o9uC8F7hxZeKG-yrKDffuOynkwZwoYXanSanjQRcTxTsp5iQYNNbvIlJ1UZ5Ujzeh2SQupfCh-sGuyAqYzadYoeJJE5k4i2ugwqUtAoGKeGhwlZDBPypxat9xA5gmdwDhaKAv-gsfTMXNbGSyFJPfj-VSgE6UYlee-kToOrX3Zzt-YZEgBXXX3YJ8hnhO1-xhiGY1f4Qay7JviGUEvy_2IsbqVkGe4qbMUClrmIaPzbcQBwvrzw_ur3X8kf4dwgMn0QqguwiIZ4QuGScfBiiO7wXQXBgsC_V1QUz3crTgbdJ6iQZbKM8yNmVic6Qg8_M6ThyEuKahMVYMhLE2RAbz7lsWtLLBy82FzTUU25Vq9pisvl3ayV_m8CD4Ix1Y0gEhaNi-WDY8cYCjy6sxvUap37vEJfLtALSk3u88Y3SJOspta9nhhxaCdMnY5ks0v2hgxxEoyAhERWP4fmdOrNGJcj4moJkxfK1c5MIGknK4P06ppzUMlvjOrQ9Oh7JcENM1iIRvMDNIXaookBTEpb6DkTmsj-R9P5ggbbXzFvdSlCUkWgkWvk72iQiZ6aQ1FvdHrkZwukS49fUOfjpgTms9poin_aVpoJhMIhkbg-WOUC1-a-5rj59QVOdbH_7EOguFUB-IVC-i94mWBk3MGaFrWuCgbl_MpGH4acp0y-7E7g"
      }
    });

    if (response.data.success) {
      setData(response.data.data);
    } else {
      setData([]);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

  const [formData, setFormData] = useState({
    age: "",
    gender: "",
    selects: "Categories",
    states: "",
    eligbility: "",
    area_of_residence: "",
    belong: "",
    abled: "",
    student: "",
    current_employment: "",
    government_employee: "",
    occupation: "",
    bpl_type: "",
    minority: "",
    abled_percentage: "",
    following_condition: "",
    annual_income: "",
    guardian_annual_income: "",
  });

useEffect(()=>{
if(step===0){
  setStep(1);
}else{
  validateStep(step);
}
},[formData])


useEffect(() => {
  if (!formData.selects) {
    setFormData({
      ...formData,
      selects: "Categories",
    });
  }
}, [formData.selects]);

const titleClick = () => {
  // Toggle the visibility of the div
  setIsDivVisible(!isDivVisible);
};

const handleApplyScheme = (e) => {
  e.preventDefault();

  setIsVisible(true);
}

const bookSaathiHandle = (e) => {
  e.preventDefault();

  setBookSaathiButton(true)
}

  const handleChangeState = (e) => {
    if (e) {
      setFormData({
        ...formData,
        states: e,
      });
      const list = States.filter((list) => list.name === e);
      setCities(
        City.getCitiesOfState(
          list[0].countryCode.toString(),
          list[0].isoCode.toString()
        )
      );
      setErrors({
        ...errors,
        states: "",
      });
    } else {
      setFormData({
        ...formData,
        states: "",
      });
      setErrors({
        ...errors,
        states: "This field is required",
      });
    }
    const list = States.filter((list) => list.name === e);
    setCities(
      City.getCitiesOfState(
        list[0].countryCode.toString(),
        list[0].isoCode.toString()
      )
    );
  };

  const handleInputChange = (e) => {console.log(e)
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
       validateStep(step);

    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   [name]: "",
    // }));
  };
  const onbtnClick = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateStep = (step) => {
    const errors = {};
    const digitRegex = /\d/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    switch (step) {
      case 1:

        if (formData.age?.trim() === "") {
          errors.age = "This field is required.";
        } else if (formData.age.length > 2) {
          errors.age = "Number should be less then 3 digit";
        } else {
          delete errors.age;
        }

        if (formData.gender?.trim() === "") {
          errors.gender = "This field is required.";
        } else {
          delete errors.gender;
        }

        if (formData.area_of_residence?.trim() === "") {
          errors.area_of_residence = "This field is required.";
        } else {
          delete errors.area_of_residence;
        }

        if (formData.belong?.trim() === "") {
          errors.belong = "This field is required.";
        } else {
          delete errors.belong;
        }

        if (formData.abled?.trim() === "") {
          errors.abled = "This field is required.";
        } else {
          delete errors.abled;
        }

        if (formData.minority?.trim() === "") {
          errors.minority = "This field is required.";
        } else {
          delete errors.minority;
        }
        
        if (formData.abled_percentage?.trim() === "") {
          errors.abled_percentage = "This field is required.";
        } else {
          delete errors.abled_percentage;
        }

        if (formData.annual_income?.trim() === "") {
          errors.annual_income = "This field is required.";
        } else {
          delete errors.annual_income;
        }
        
        if (formData.guardian_annual_income?.trim() === "") {
          errors.guardian_annual_income = "This field is required.";
        } else {
          delete errors.guardian_annual_income;
        }
        
        if (formData.student?.trim() === "") {
          errors.student = "This field is required.";
        } else {
          delete errors.student;
        }

        if (formData.current_employment?.trim() === "") {
          errors.current_employment = "This field is required.";
        } else {
          delete errors.current_employment;
        }

        if (formData.government_employee?.trim() === "") {
          errors.government_employee = "This field is required.";
        } else {
          delete errors.government_employee;
        }

        if (formData.bpl_type?.trim() === "") {
          errors.bpl_type = "This field is required.";
        } else {
          delete errors.bpl_type;
        }

        if (formData.following_condition?.trim() === "") {
          errors.following_condition = "This field is required.";
        } else {
          delete errors.following_condition;
        }
        
        if (formData.occupation?.trim() === "") {
          errors.occupation = "This field is required.";
        } else {
          delete errors.occupation;
        }

        if (formData.states?.trim() === "") {
          errors.states = "This field is required.";
        } else {
          delete errors.states;
        }

        break;

       default:
        break;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Axios.post("https://api.onlinesaathi.org/api/v1/user/search/scheme/data", formData, {
      headers: {
        Authorization: "Bearer " + "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMGYzMWUzYWZiNThiZDI5ZGM2N2U3MDI5M2JkNzgzMzE0YWZjOGQ2ODUxMTQzMGM4MjU0MGJlMzhlYzIzYzFkYTExMWViNjljZDdjZTE0N2IiLCJpYXQiOjE3MDI2Mzk4NzUuNDUzNzQxMDczNjA4Mzk4NDM3NSwibmJmIjoxNzAyNjM5ODc1LjQ1Mzc0NjA4MDM5ODU1OTU3MDMxMjUsImV4cCI6MTczNDI2MjI3NS40NDg1MzkwMTg2MzA5ODE0NDUzMTI1LCJzdWIiOiIxNjQwOCIsInNjb3BlcyI6W119.o9uC8F7hxZeKG-yrKDffuOynkwZwoYXanSanjQRcTxTsp5iQYNNbvIlJ1UZ5Ujzeh2SQupfCh-sGuyAqYzadYoeJJE5k4i2ugwqUtAoGKeGhwlZDBPypxat9xA5gmdwDhaKAv-gsfTMXNbGSyFJPfj-VSgE6UYlee-kToOrX3Zzt-YZEgBXXX3YJ8hnhO1-xhiGY1f4Qay7JviGUEvy_2IsbqVkGe4qbMUClrmIaPzbcQBwvrzw_ur3X8kf4dwgMn0QqguwiIZ4QuGScfBiiO7wXQXBgsC_V1QUz3crTgbdJ6iQZbKM8yNmVic6Qg8_M6ThyEuKahMVYMhLE2RAbz7lsWtLLBy82FzTUU25Vq9pisvl3ayV_m8CD4Ix1Y0gEhaNi-WDY8cYCjy6sxvUap37vEJfLtALSk3u88Y3SJOspta9nhhxaCdMnY5ks0v2hgxxEoyAhERWP4fmdOrNGJcj4moJkxfK1c5MIGknK4P06ppzUMlvjOrQ9Oh7JcENM1iIRvMDNIXaookBTEpb6DkTmsj-R9P5ggbbXzFvdSlCUkWgkWvk72iQiZ6aQ1FvdHrkZwukS49fUOfjpgTms9poin_aVpoJhMIhkbg-WOUC1-a-5rj59QVOdbH_7EOguFUB-IVC-i94mWBk3MGaFrWuCgbl_MpGH4acp0y-7E7g"
      },
    })
    .then(response => {
      alert("Form Submitted Successfully")
      console.log(response);
      console.log("formdataaaa", formData, response)
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
  };

  

  var responsiveSlider = function() {

    var slider = document.getElementById("slider");
    var sliderWidth = slider.offsetWidth;
    var slideList = document.getElementById("slideWrap");
    var count = 1;
    var items = slideList.querySelectorAll("li").length;
    var prev = document.getElementById("prev");
    var next = document.getElementById("next");
    
    window.addEventListener('resize', function() {
      sliderWidth = slider.offsetWidth;
    });
    
    var prevSlide = function() {
      if(count > 1) {
        count = count - 2;
        slideList.style.left = "-" + count * sliderWidth + "px";
        count++;
      }
      else if(count = 1) {
        count = items - 1;
        slideList.style.left = "-" + count * sliderWidth + "px";
        count++;
      }
    };
    
    var nextSlide = function() {
      if(count < items) {
        slideList.style.left = "-" + count * sliderWidth + "px";
        count++;
      }
      else if(count = items) {
        slideList.style.left = "0px";
        count = 1;
      }
    };
    
    next.addEventListener("click", function() {
      nextSlide();
    });
    
    prev.addEventListener("click", function() {
      prevSlide();
    });
    
    setInterval(function() {
      nextSlide()
    }, 3000);
    
    };
    
    window.onload = function() {
    responsiveSlider();  
    }

  return (
    <div className=''>
       <Userheader />
      {/* <div className="the-loader">
      <div className="spinner-border">
          <span className="sr-only">Loading...</span>
      </div>
  </div> */}
      <section className="main-sidebar_dash">
        <div className="container-fluid">
          <div className="row">
            <Usersidebar />
            <div className="col-md-10 right_contentsec">
              <div className="maine-table-dash-sec">
                <div className="heading-title-sec">
                </div>
                <div className="container">
                  <div>
                    <div id="slider">
                      <ul id="slideWrap"> 
                        {data.map(category => (
                          <li key={category.id}>
                            <img src={category.category_icon} alt={`Category ${category.id}`} />
                          </li>
                        ))}
                      </ul>
                      <a id="prev" href="#">&#8810;</a>
                      <a id="next" href="#">&#8811;</a>
                    </div>

                    <div>
                      <div className="schemes-first-category-container-head">
                        <div className="schemes-first-category-container-child">
                            <p className="schemes-p">Schemes</p>
                          <ul>
                            <li>
                              <a href="#"> 
                                <img src={myAppointment} style={{width: "34px", height: "34px"}} alt="..." />
                                 <p>My Appointment</p>
                              </a>
                            </li>

                            <li>
                              <a href="#">
                                <img src={myApplication} style={{width: "34px", height: "34px"}} alt="..." />
                                <p>My Application</p>
                              </a>
                            </li>    

                            <li className="Check-Eligibility"> 
                                <img src={checkEligbility} style={{width: "34px", height: "34px"}} alt="..." />
                                <input
                                      name="eligbility"
                                      value="Check-Eligibility"
                                      type="button"
                                      onClick={onbtnClick}
                                      className={
                                        formData.eligbility === "Check-Eligibility"
                                          ? "p-button-text btn-active"
                                          : "p-button-text"
                                      }
                                      id="Check-Eligibility-button"
                                    />
                            </li>    
                            
                            <li>
                              <a href="#">
                                <img src={documentAdvisor} style={{width: "34px", height: "34px"}} alt="..." />
                                <p>Document Advisor</p>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    {formData.eligbility === "Check-Eligibility" && ( <> 
                          <div className="Check-Eligibility-head-div">
                                    <div className="check-eligbility-heading">
                                       <b>Fill this form to check eligibility</b>
                                    </div>
                              <div className="check-eligbility-content-head">

                                <div className="Check-Eligibility-div-flex">
                                  <div className="col-md-6">
                                    <div className="">
                                      <label htmlFor="gender fw-bold">Tell us about yourself, you are a</label>
                                    </div>
                                    <div className="flex flex-wrap gap-2 ">
                                      <input
                                        name="gender"
                                        value="Male"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.gender === "Male"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="gender"
                                        value="Female"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.gender === "Female"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                        label="Female"
                                      />
                                      <input
                                        name="gender"
                                        value="Other"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.gender === "Other"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                        label="Other"
                                      />
                                    </div>
                                      <div>
                                        {errors.gender && (
                                          <p className="error">{errors.gender}</p>
                                        )}
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div>
                                      <label htmlFor="age fw-bold">Age</label>
                                    </div>
                                    <div id="phoneInput">                                      
                                      <input
                                        type="number"
                                        name="age"
                                        selected={formData.age}
                                        onChange={handleInputChange}
                                        placeholderText="enter your age"
                                        className="form-control"
                                        id="react-date-picker-id"
                                      />                                   
                                    </div>
                                      <div>
                                        {errors.age && <p className="error">{errors.age}</p>}
                                      </div>
                                  </div>
                                </div>

                                <div className="Check-Eligibility-div-flex">
                                  <div className="col mt-4">
                                    <label
                                      htmlFor="states"
                                      className="form-label theme-label color__paragraph fw-bold"
                                    >
                                      State
                                     </label>
                                    <select
                                      name="states"
                                      id="countries"
                                      className="form-control"
                                      onChange={(e) => handleChangeState(e.target.value)}
                                      value={formData.states}
                                    >
                                      <option value="" selected>
                                        Choose a country
                                      </option>
                                      {States?.map((list, i) => {
                                        return <option value={list.name}>{list.name}</option>;
                                      })}
                                    </select>
                                    {errors.states && <p className="error">{errors.states}</p>}
                                  </div>
                                  <div className="col mt-4">
                                    <div className="">
                                      <label htmlFor="area_of_residence fw-bold">Please Select Your Area Of Residence</label>
                                    </div>
                                    <div className="flex flex-wrap gap-2 ">
                                      <input
                                        name="area_of_residence"
                                        value="Urban"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.area_of_residence === "Urban"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="area_of_residence"
                                        value="Rural"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.area_of_residence === "Rural"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                    </div>
                                      <div>
                                        {errors.area_of_residence && (
                                          <p className="error">{errors.area_of_residence}</p>
                                        )}
                                      </div>
                                  </div>
                                </div>

                                <div className="Check-Eligibility-div-flex">
                                  <div className="col mt-4">
                                    <div className="">
                                      <label htmlFor="belong fw-bold">You Belong to</label>
                                    </div>
                                    <div className="flex flex-wrap gap-2 ">
                                      <input
                                        name="belong"
                                        value="General"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.belong === "General"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="belong"
                                        value="OBC"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.belong === "OBC"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                       <input
                                        name="belong"
                                        value="PVTG"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.belong === "PVTG"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="belong"
                                        value="SC"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.belong === "SC"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="belong"
                                        value="ST"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.belong === "ST"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                    </div>
                                      <div>
                                        {errors.belong && (
                                          <p className="error">{errors.belong}</p>
                                        )}
                                      </div>
                                  </div>
                                  <div className="col mt-4">
                                    <div className="">
                                      <label htmlFor="abled fw-bold">Are You differently abled?</label>
                                    </div>
                                    <div className="flex flex-wrap gap-2 ">
                                      <input
                                        name="abled"
                                        value="YES"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.abled === "YES"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="abled"
                                        value="NO"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.abled === "NO"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                    </div>
                                      <div>
                                        {errors.abled && (
                                          <p className="error">{errors.abled}</p>
                                        )}
                                      </div>
                                  </div>
                                </div>

                                  {formData.abled === "NO" && (<> 
                                    <div className="Check-Eligibility-div-flex">
                                      <div className="col mt-4">
                                        <div className="">
                                          <label htmlFor="minority fw-bold">DO you belong to minority?</label>
                                        </div>
                                        <div className="flex flex-wrap gap-2 ">
                                          <input
                                            name="minority"
                                            value="YES"
                                            type="button"
                                            onClick={onbtnClick}
                                            className={
                                              formData.minority === "YES"
                                                ? "p-button-text btn-active "
                                                : "p-button-text"
                                            }
                                          />
                                          <input
                                            name="minority"
                                            value="NO"
                                            type="button"
                                            onClick={onbtnClick}
                                            className={
                                              formData.minority === "NO"
                                                ? "p-button-text btn-active "
                                                : "p-button-text"
                                            }
                                          />
                                        </div>
                                          <div>
                                            {errors.minority && (
                                              <p className="error">{errors.minority}</p>
                                            )}
                                          </div>
                                      </div> 
                                    </div>
                                  </>)}

                                  {formData.abled === "YES" && (<> 
                                    <div className="Check-Eligibility-div-flex">
                                      <div className="col mt-4">
                                        <div>
                                          <label htmlFor="abled_percentage fw-bold">what is your differently abled percentage?</label>
                                        </div>
                                        <select
                                          style={{ width: "200px" }}
                                          name="abled_percentage"
                                          id="occupation"
                                          value={formData.abled_percentage}
                                          onChange={handleInputChange}
                                          className="form-control w-100"
                                          >
                                            <option value="">Select an percentage</option>
                                              {/* Generate options from 1 to 99 using a for loop */}
                                              {(() => {
                                                const options = [];
                                                for (let i = 1; i <= 99; i++) {
                                                  options.push(
                                                    <option key={i} value={i}>
                                                      {i}
                                                    </option>
                                                  );
                                                }
                                                return options;
                                              })()}
                                        </select>
                                        <div>
                                          {errors.abled_percentage && (
                                            <p className="error">{errors.abled_percentage}</p>
                                          )}
                                        </div>
                                      </div> 
                                    </div> 
                                  </>)}
                                
                                <div className="Check-Eligibility-div-flex">
                                  <div className="col mt-4">
                                    <div className="">
                                      <label htmlFor="student fw-bold">Are you a student?</label>
                                    </div>
                                    <div className="flex flex-wrap gap-2 ">
                                      <input
                                        name="student"
                                        value="YES"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.student === "YES"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="student"
                                        value="NO"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.student === "NO"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                    </div>
                                      <div>
                                        {errors.student && (
                                          <p className="error">{errors.student}</p>
                                        )}
                                      </div>
                                  </div>
                                  <div className="col mt-5">
                                    <div className="">
                                      <label htmlFor="current_employment fw-bold">What is your current emploayment status</label>
                                    </div>
                                    <div className="flex flex-wrap gap-2 ">
                                      <input
                                        name="current_employment"
                                        value="Emploayed"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.current_employment === "Emploayed"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="current_employment"
                                        value="Unemploayed"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.current_employment === "Unemploayed"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                       <input
                                        name="current_employment"
                                        value="Self-Emploayed/Entrepreneur"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.current_employment === "PVTG"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                    </div>
                                      <div>
                                        {errors.current_employment && (
                                          <p className="error">{errors.current_employment}</p>
                                        )}
                                      </div>
                                  </div>
                                </div>

                                <div className="Check-Eligibility-div-flex">
                                  <div className="col mt-4">
                                    <div className="">
                                      <label htmlFor="government_employee fw-bold">Are you currently working as a government employee?</label>
                                    </div>
                                    <div className="flex flex-wrap gap-2 ">
                                      <input
                                        name="government_employee"
                                        value="YES"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.government_employee === "YES"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="government_employee"
                                        value="NO"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.government_employee === "NO"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                    </div>
                                      <div>
                                        {errors.government_employee && (
                                          <p className="error">{errors.government_employee}</p>
                                        )}
                                      </div>
                                  </div>
                                  <div className="col mt-4">
                                    <div>
                                      <label htmlFor="occupation fw-bold">Occupation</label>
                                    </div>
                                    <select
                                      style={{ width: "200px" }}
                                      name="occupation"
                                      id="occupation"
                                      value={formData.occupation}
                                      onChange={handleInputChange}
                                      className="form-control w-100"
                                       >
                                      <option value="">Select...</option>
                                      <option value="self-emloyed"> Salaried</option>
                                      <option value="small-business">Police,Army,etc.</option>
                                      <option value="hospitality-restaurant">
                                        Businessman
                                      </option>
                                      <option value="company&factory">
                                         Government-employee
                                      </option>
                                      <option value="househelp&security">
                                         House-Wife
                                      </option>
                                      <option value="building&construction">
                                        Farmer,techer,engineer,etc.
                                      </option>
                                      <option value="daily-wager-earner">
                                        Retiree
                                      </option>
                                      <option value="public-transport">Self-employed</option>
                                      <option value="farmer">Students</option>
                                      <option value="asha-worker/comminity-worket">
                                       Unemployed
                                      </option>
                                    </select>
                                    <div>
                                      {errors.occupation && (
                                        <p className="error">{errors.occupation}</p>
                                      )}
                                    </div>
                                  </div> 
                                </div>

                                  <div className="col mt-4">
                                    <div className="">
                                      <label htmlFor="bpl_type fw-bold">Do you belong to BPL category?</label>
                                    </div>
                                    <div className="flex flex-wrap gap-2 ">
                                      <input
                                        name="bpl_type"
                                        value="YES"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.bpl_type === "YES"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="bpl_type"
                                        value="NO"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.bpl_type === "NO"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                    </div>
                                      <div>
                                        {errors.bpl_type && (
                                          <p className="error">{errors.bpl_type}</p>
                                        )}
                                      </div>
                                  </div>

                                 {formData.bpl_type === "YES" && (<> 
                                  <div className="col mt-4">
                                    <div className="">
                                      <label htmlFor="following_condition fw-bold">Are you in any of the following Condition-Destitute /Penury /Extreme Hardship /Distress</label>
                                    </div>
                                    <div className="flex flex-wrap gap-2 ">
                                      <input
                                        name="following_condition"
                                        value="YES"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.following_condition === "YES"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                      <input
                                        name="following_condition"
                                        value="NO"
                                        type="button"
                                        onClick={onbtnClick}
                                        className={
                                          formData.following_condition === "NO"
                                            ? "p-button-text btn-active "
                                            : "p-button-text"
                                        }
                                      />
                                    </div>
                                      <div>
                                        {errors.following_condition && (
                                          <p className="error">{errors.following_condition}</p>
                                        )}
                                      </div>
                                  </div>
                                  </>)}

                                  {formData.bpl_type === "NO" && (<> 
                                    <div className="Check-Eligibility-div-flex">
                                      <div className="col mt-4">
                                        <div>
                                          <label htmlFor="annual_income fw-bold">What is your family's annual income</label>
                                        </div>
                                        <div id="phoneInput">                                      
                                          <input
                                            type="text"
                                            name="annual_income"
                                            selected={formData.annual_income}
                                            onChange={handleInputChange}
                                            placeholderText="enter your family's annual income"
                                            className="form-control"
                                            id="react-date-picker-id"
                                          />                                   
                                        </div>
                                          <div>
                                            {errors.annual_income && <p className="error">{errors.annual_income}</p>}
                                          </div>
                                      </div>
                                      <div className="col mt-4">
                                        <div>
                                          <label htmlFor="guardian_annual_income fw-bold">What is your parent / guardian's annual income</label>
                                        </div>
                                        <div id="phoneInput">                                      
                                          <input
                                            type="text"
                                            name="guardian_annual_income"
                                            selected={formData.guardian_annual_income}
                                            onChange={handleInputChange}
                                            placeholderText="enter your family's annual income"
                                            className="form-control"
                                            id="react-date-picker-id"
                                          />                                   
                                        </div>
                                          <div>
                                            {errors.guardian_annual_income && <p className="error">{errors.guardian_annual_income}</p>}
                                          </div>
                                      </div>
                                    </div>
                                  </>)}
                                
                                 <div style={{display:"grid", placeItems: "center"}}>
                                  <button
                                    className="scheme-button mt-3" 
                                    type="submit" 
                                    onClick={handleSubmit}
                                    >
                                    SUBMIT
                                  </button>
                                 </div>
                                
                              </div>
                          </div>
                     </> )}

                    <div>
                      <div className="schemes-first-category-container-head">
                        <div className="schemes-first-category-container-child">
                            <p className="schemes-p">Schemes for Business</p>
                          <ul id="scheme2-business">
                            <li>
                              <a href="#"> 
                                <img src={myAppointment} style={{width: "34px", height: "34px"}} alt="..." />
                                 <p>Registration & Information</p>
                              </a>
                            </li>

                            <li>
                              <a href="#">
                                <img src={bankLoan} style={{width: "34px", height: "34px"}} alt="..." />
                                <p>Finantial & Bank Loan</p>
                              </a>
                            </li>    
                          </ul>
                        </div>
                      </div>
                    </div>

                              <div className="flex flex-wrap gap-2 mt-5">   
                                    <input
                                      name="selects"
                                      value="Categories"
                                      type="button"
                                      onClick={() => onbtnClick("Categories")}
                                      className={
                                        formData.selects === "Categories"
                                          ? "p-button-text btn-active "
                                          : "p-button-text"
                                      }
                                    />
                                    <input
                                      name="selects"
                                      value="State/Union Territory"
                                      type="button"
                                      onClick={onbtnClick}
                                      className={
                                        formData.selects === "State/Union Territory"
                                          ? "p-button-text btn-active "
                                          : "p-button-text"
                                      }
                                    />
                                    <input
                                      name="selects"
                                      value="Central-Ministry"
                                      type="button"
                                      onClick={onbtnClick}
                                      className={
                                        formData.selects === "Central-Ministry"
                                          ? "p-button-text btn-active "
                                          : "p-button-text"
                                      }
                                      label="Other"
                                    />
                              </div>
 
                      {formData.selects === "Categories" && (
                        <>
                      
                        <div>
                          {data.map(category => (
                            <section key={category.id}>
                              <details>
                                <summary>
                                  <div>
                                    <div className="schemes-first-category-container-head">
                                      <div className="schemes-first-category-container-child-id" id={`schemes-first-category-container-id${category.id}`}>
                                        <div className="schemes-categories-flex">
                                          <div>
                                            <img src={category.category_icon} style={{ width: "40px", height: "40px" }} alt="Category Icon" />
                                          </div>
                                          <div style={{ paddingLeft: "1rem" }}>
                                            <p className="scheme-count">{category.scheme_count} Scheme</p>
                                            <p>{category.name}</p>
                                          </div>
                                        </div>
                                          <div>
                                            <img src={Vector} style={{ width: "14px", height: "10px" }} alt="Vector" />
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                </summary>
                                <div className="schemes-insider-main-head">
                                  <div className="schemes-insider-content-head">
                                    
                                    {category.all_scheme.map(scheme => (
                                      <div> 
                                          <div key={scheme.id} className="scheme-insider-content">
                                            <div className="scheme-insider-child-content">
                                                <div>
                                                  <img src={scheme.scheme_image} alt="..." className="scheme-insider-img" />
                                                </div>
                                                <div style={{paddingTop: "1rem"}}>
                                                  <p><b>{category.name}</b></p>
                                                  <p onClick={titleClick} className="scheme-count">{scheme.scheme_title}</p>
                                                </div>

                                                {isDivVisible && isVisible === false && (
                                                    <div className="schemes-click-main-head">
                                                      <div className="schemes-click-container-head">
                                                        <div className="schemes-click-container">  
                                                          <p><b>{category.name}</b></p>
                                                          <div className="schemes-button-head-div">
                                                            <button className="schemes-button">Chief Minister</button>
                                                            <button className="schemes-button">Financial Assistance</button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                        <div className="scemes-details-page-para">
                                                          <p>Description</p>
                                                          <b>Objective</b>
                                                          <div > 
                                                              <ol>
                                                                {scheme.detail.split('<li>').map((item, index) => (
                                                                  item.trim() && <li key={index} dangerouslySetInnerHTML={{ __html: `<p>${item}</p>` }} />
                                                                ))}
                                                              </ol>
                                                          </div>

                                                          <b>Benifits</b>
                                                          <div > 
                                                              <ul>
                                                                {scheme.benefit.split('<li>').map((item, index) => (
                                                                  item.trim() && <li key={index} dangerouslySetInnerHTML={{ __html: `<p>${item}</p>` }} />
                                                                ))}
                                                              </ul>
                                                          </div>  

                                                            <b>Eligibility</b>
                                                          <div > 
                                                              <ul>
                                                                {scheme.eligibility.split('<li>').map((item, index) => (
                                                                  item.trim() && <li key={index} dangerouslySetInnerHTML={{ __html: `<p>${item}</p>` }} />
                                                                ))}
                                                              </ul>
                                                          </div>  

                                                          <b>Application Process</b>
                                                          <div > 
                                                              <ul>
                                                                {scheme.application_process.split('<li>').map((item, index) => (
                                                                  item.trim() && <li key={index} dangerouslySetInnerHTML={{ __html: `<p>${item}</p>` }} />
                                                                ))}
                                                              </ul>
                                                          </div>  
                                                          <div style={{display:"grid", placeItems: "center"}}>
                                                            <button
                                                              className="scheme-button mt-3" 
                                                              type="submit" 
                                                              onClick={handleApplyScheme}
                                                              >
                                                              Apply Now
                                                            </button>
                                                          </div>
                                                        </div>
                                                  
                                                    </div>
                                                )}

                                               {isVisible === true && (<> 
                                                    {bookSaathiButton === false && (<>
                                                    <div className="schemes-click-main-head">
                                                      <div className="schemes-click-container-head">
                                                        <div className="schemes-click-container">  
                                                          <p><b>{category.name}</b></p>
                                                          <div className="schemes-button-head-div">
                                                            <button className="schemes-button">Chief Minister</button>
                                                            <button className="schemes-button">Financial Assistance</button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="scemes-details-page-para">
                                                          <b> Documents Checklists </b>
                                                          <div className="scheme-saathi-documents-list-head">
                                                            <div className="scheme-saathi-documents-list-flex">
                                                              <div>
                                                                  <p>Pan Card</p>
                                                                  <p style={{marginLeft: "10px"}}><i>if have not please apply here</i></p>
                                                              </div>
                                                              <div>
                                                                    <label class="check-control">
                                                                      <input type="checkbox" name="checkbox" />
                                                                    </label>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="scheme-saathi-documents-list-head">
                                                            <div className="scheme-saathi-documents-list-flex">
                                                              <div>
                                                                  <p>Aadhaar Card</p>
                                                                  <p style={{marginLeft: "10px"}}><i>if have not please apply here</i></p>
                                                              </div>
                                                              <div>
                                                                    <label class="check-control">
                                                                      <input type="checkbox" name="checkbox" />
                                                                    </label>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="scheme-saathi-documents-list-head">
                                                            <div className="scheme-saathi-documents-list-flex">
                                                              <div>
                                                                  <p>Passport</p>
                                                                  <p style={{marginLeft: "10px"}}><i>if have not please apply here</i></p>
                                                              </div>
                                                              <div>
                                                                    <label class="check-control">
                                                                      <input type="checkbox" name="checkbox" />
                                                                    </label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                            <div className="apply-schemes-button-div">
                                                              <div style={{display:"grid", placeItems: "center"}}>
                                                                <button
                                                                  className="scheme-button mt-3" 
                                                                  id="book-saathi-button"
                                                                  type="submit" 
                                                                  onClick={bookSaathiHandle}
                                                                  >
                                                                  Book saathi
                                                                </button>
                                                              </div>
                                                              <div style={{display:"grid", placeItems: "center"}}>
                                                                <button
                                                                  className="scheme-button mt-3" 
                                                                  type="submit" 
                                                                  >
                                                                  Apply Now
                                                                </button>
                                                              </div>
                                                            </div>
                                                      </div>
                                                  
                                                    </div>
                                                    </>)}

                                                    {bookSaathiButton === true && (<>
                                                    <div className="schemes-click-main-head">
                                                      <div className="schemes-click-container-head">
                                                        <div className="schemes-click-container">  
                                                          <p><b>Book saathi</b></p>
                                                          <p>Find Perfect Saathi Nearby You</p>
                                                        </div>
                                                      </div>
                                                      <div className="scemes-details-page-para">
                                                          <div className="scheme-saathi-documents-list-head">
                                                            <div className="scheme-saathi-documents-list-flex">
                                                              <div>
                                                                  <div className="scheme-book-saathi-img-div">
                                                                    <div>
                                                                      <img src={Rectangle155} alt="..." style={{width: "70px", height: "70px"}} />
                                                                    </div>
                                                                    <div className="scheme-saathi-user-name">
                                                                      <b>Rajesh Patel</b>
                                                                      <p>+91 8989332223</p>
                                                                      <p>Sg. High Way, Mumbai</p>
                                                                    </div>
                                                                  </div>
                                                              </div>
                                                              <div>
                                                                    <label class="check-control">
                                                                      <input type="checkbox" name="checkbox" />
                                                                    </label>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="scheme-saathi-documents-list-head">
                                                            <div className="scheme-saathi-documents-list-flex">
                                                              <div>
                                                                  <div className="scheme-book-saathi-img-div">
                                                                    <div>
                                                                      <img src={Rectangle156} alt="..." style={{width: "70px", height: "70px"}} />
                                                                    </div>
                                                                    <div className="scheme-saathi-user-name">
                                                                      <b>Rajesh Patel</b>
                                                                      <p>+91 8989332223</p>
                                                                      <p>Sg. High Way, Mumbai</p>
                                                                    </div>
                                                                  </div>
                                                              </div>
                                                              <div>
                                                                    <label class="check-control">
                                                                      <input type="checkbox" name="checkbox" />
                                                                    </label>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="scheme-saathi-documents-list-head">
                                                            <div className="scheme-saathi-documents-list-flex">
                                                              <div>
                                                                  <div className="scheme-book-saathi-img-div">
                                                                    <div>
                                                                      <img src={Rectangle157} alt="..." style={{width: "70px", height: "70px"}} />
                                                                    </div>
                                                                    <div className="scheme-saathi-user-name">
                                                                      <b>Rajesh Patel</b>
                                                                      <p>+91 8989332223</p>
                                                                      <p>Sg. High Way, Mumbai</p>
                                                                    </div>
                                                                  </div>
                                                              </div>
                                                              <div>
                                                                    <label class="check-control">
                                                                      <input type="checkbox" name="checkbox" />
                                                                    </label>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="scheme-saathi-documents-list-head">
                                                            <div className="scheme-saathi-documents-list-flex">
                                                              <div>
                                                                  <div className="scheme-book-saathi-img-div">
                                                                    <div>
                                                                      <img src={Rectangle158} alt="..." style={{width: "70px", height: "70px"}} />
                                                                    </div>
                                                                    <div className="scheme-saathi-user-name">
                                                                      <b>Rajesh Patel</b>
                                                                      <p>+91 8989332223</p>
                                                                      <p>Sg. High Way, Mumbai</p>
                                                                    </div>
                                                                  </div>
                                                              </div>
                                                              <div>
                                                                    <label class="check-control">
                                                                      <input type="checkbox" name="checkbox" />
                                                                    </label>
                                                              </div>
                                                            </div>
                                                          </div>

                                                            <div className="apply-schemes-button-div">
                                                              <div style={{display:"grid", placeItems: "center"}}>
                                                                <button
                                                                  className="scheme-button mt-3" 
                                                                  id="book-saathi-button"
                                                                  type="submit" 
                                                                  onClick={bookSaathiHandle}
                                                                  >
                                                                  Book saathi
                                                                </button>
                                                              </div>
                                                            </div>
                                                      </div>
                                                  
                                                    </div>
                                                    </>)}
                                                </>)}

                                              <div>
                                                <ul>
                                                  {scheme.benefit.split('<li>').map((item, index) => (
                                                    item.trim() && <li key={index} dangerouslySetInnerHTML={{ __html: `<p>${item}</p>` }} />
                                                  ))}
                                                </ul>
                                              </div>
                                              <div className="schemes-button-head-div">
                                                <button className="schemes-button">Chief Minister</button>
                                                <button className="schemes-button">Financial Assistance</button>
                                              </div>
                                            </div>
                                          </div>

                                      </div>
                                    ))}

                                  </div>
                                </div>
                              </details>
                            </section>
                          ))}

                        </div>

                        </>
                      )} 

                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </section>
      <Userfooter />
    </div>

  )
}

export default BecomeSathi;
