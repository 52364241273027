import React from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
// if (!url.includes('onlinesaathi.org')) {
//     // console.log(url)
//     apiUrl = process.env.REACT_APP_API_URL;
// }
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: apiUrl,
            password: "",
            email: "",


        };
    }
    componentDidMount = () => {
        // console.log(this.state)
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    checkmobile = (e) => {
        this.setState({
            checkmobile:true,
            login:false
        })
    }


    handleSubmit = () => {
        axios.post(`${apiUrl}/admin/login`, { email: this.state.email, password: this.state.password })
            .then(d => {
                 console.log('resp',d.data);
                if (d.data.success) {
                    var token = d.data.token.token;
                    if (d.data.type === "user") {
                        var token = Cryp.encrypt(token);
                        sessionStorage.setItem("_access", token);
                        sessionStorage.setItem("test", d.data.token.token);
                        sessionStorage.setItem("cuser", Cryp.encrypt(d.data.token.user));
                        sessionStorage.setItem("userpermission", JSON.stringify(d.data.token.userpermission));

                        this.props.history.push(`/${d.data.type}/dashboard`);
                    } else if(d.data.type === "admin") {
                        sessionStorage.setItem("cuser", Cryp.encrypt(d.data.user));
                        sessionStorage.setItem("_access", token);
                        sessionStorage.setItem("adminpermission", JSON.stringify(d.data.token.adminpermission));
                        // if (d.data.user.id !== 1) {
                        //     window.location.href = `/admin/all-users`;
                        // } else {
                            window.location.href = "/admin/dashboard";
                       // }
                    }else{
                        var token = Cryp.encrypt(token);
                        sessionStorage.setItem("_access", token);
                        sessionStorage.setItem("test", d.data.token.token);
                        sessionStorage.setItem("cuser", Cryp.encrypt(d.data.token.user));
                        sessionStorage.setItem("userpermission", JSON.stringify(d.data.token.userpermission));

                        this.props.history.push(`/${d.data.type}/dashboard`);
                    }
                } else {
                    this.notify("error", d.data.message)
                }
            }, err => {
                this.notify("error", "Wrong credentials.")
            })
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row className="login-page">
                        <Col md={{ size: 4, offset: 4 }} className="mt-5">
                            <Card className="card-user">
                                <CardHeader className="login-section">
                                    <div className="row text-center">
                                        <Col md="12">
                                            <div className="login-logo">
                                                <img src={require("assets/images/new/online-sath-logo.png")} alt="" style={{ width: "50%", padding: "15px" }} />
                                            </div>
                                            <hr /><br />
                                            <h4>Sign In {}</h4>
                                        </Col>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Mobile</label>
                                                    <Input
                                                        placeholder="Email/Mobile"
                                                        type="text"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Password</label>
                                                    <Input
                                                        placeholder="Password"
                                                        type="password"
                                                        name="password"
                                                        value={this.state.password}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                     <Link to="/forgot-password" style={{
                                                        float: "right",
                                                        marginTop: "-6%",
                                                        marginBottom: "4%",
                                                        fontSize: "12px"
                                                    }}>Forgot Password</Link>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <Button type="button" onClick={() => this.handleSubmit()} className="btn btn-round btn-block loginbtn" color="success">
                                                        Login
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                <Link to="/register"  className="btn btn-round btn-block loginbtn" color="success">
                                                        Register
                                                    </Link>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                                {/* <CardFooter>
                                    <div className="button-container">
                                        <Button className="btn-icon btn-round" color="facebook">
                                            <i className="fab fa-facebook" />
                                        </Button>
                                        <Button className="btn-icon btn-round" color="twitter">
                                            <i className="fab fa-twitter" />
                                        </Button>
                                        <Button className="btn-icon btn-round" color="google">
                                            <i className="fab fa-google-plus" />
                                        </Button>
                                    </div>
                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Login;
