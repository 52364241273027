import React from "react";
import { Button, Card, CardHeader, CardBody, Row, Col, FormGroup, Input, Table, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";

import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import { Modal } from "reactstrap";
import ReceiptModel from "./ReceiptModel";
import swal from 'sweetalert';
import KycForm from "./child-components/KycForm";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Userlist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deductModal: false,
            viewModal: false,
            blockModal: false,
            tempLoginModal: false,
            otp_verified: false,
            otp_token: "",
            balance_type: "add",
            otp: "",
            input_notice: "",
            details: [],
            deduct_amount: "",
            deduct_reason: "",
            kycform: false,
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            },
            user_id: "",
            mobile: "",
            membership_id: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            nationality: "",
            marital_status: "",
            dob: "",
            gender: "",
            p_name: "",
            occupation: "",
            source_of_found: "",
            country: "",
            state: "",
            district: "",
            address: "",
            id_type: "",
            id_number: "",
            issue_date: "",
            expire_date: "",
            id_issue_place: "",
            nepal_id_number: "",
            nepal_issue_place: "",
            term_condition: "",
        }
    }

    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");

        }
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })

        this.getData();

        let search = this.props.history.location.search;
        let params = new URLSearchParams(search);
        let atype = params.get('type');
        if (atype) {
            this.setState({ loading: true })
            setTimeout(() => {
                this.setState({ kycform: true, loading: false })
            }, 2000);
        }
    }

    getData = (page = 1) => {
        var data = {};
        Axios.post(`${apiUrl}/user/list?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res.data.data.data)
            this.setState({
                details: res.data.data ? res.data.data.data : [],
                pagination_data: res.data.data
            })
        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }


    kyc_submit = () => {
        if (this.state.mobile === '') {
            swal("", "mobile number required", "error");

            return false;
        }
        if (this.state.first_name === '') {
            swal("", "first name required", "error");

            return false;
        }
        if (this.state.nationality === '') {
            swal("", "nationality required", "error");

            return false;
        }
        if (this.state.marital_status === '') {
            swal("", "marital status required", "error");

            return false;
        }
        if (this.state.dob === '') {
            swal("", "dob required", "error");

            return false;
        }
        if (this.state.gender === '') {
            swal("", "gender required", "error");

            return false;
        }
        if (this.state.p_name === '') {
            swal("", "Parent name required", "error");

            return false;
        }
        if (this.state.occupation === '') {
            swal("", "occupation required", "error");

            return false;
        }
        if (this.state.country === '') {
            swal("", "country required", "error");

            return false;
        }
        if (this.state.state === '') {
            swal("", "state required", "error");

            return false;
        }
        if (this.state.district === '') {
            swal("", "district required", "error");

            return false;
        }
        if (this.state.address === '') {
            swal("", "address required", "error");

            return false;
        }
        if (this.state.id_type === '') {
            swal("", "id type required", "error");

            return false;
        }
        if (this.state.id_number === '') {
            swal("", "id number required", "error");

            return false;
        }
        // if (this.state.issue_date === '') {
        //     swal("", "issue date   number required", "error");

        //     return false;
        // }
        // if (this.state.expire_date === '') {
        //     swal("", "expire date   number required", "error");

        //     return false;
        // }
        // if (this.state.id_issue_place === '') {
        //     swal("", "id issue place  number required", "error");

        //     return false;
        // }
        // if (this.state.nepal_id_number === '') {
        //     swal("", "nepal id number   required", "error");

        //     return false;
        // }
        // if (this.state.nepal_issue_place === '') {
        //     swal("", "nepal issue place  required", "error");

        //     return false;
        // }
        if (this.state.term_condition === '') {
            swal("", "term condition    required", "error");

            return false;
        }

        var data = {
            user_id: this.state.user_id,
            mobile: this.state.mobile,
            membership_id: this.state.membership_id,
            first_name: this.state.first_name,
            middle_name: this.state.middle_name,
            last_name: this.state.last_name,
            nationality: this.state.nationality,
            marital_status: this.state.marital_status,
            dob: this.state.dob,
            gender: this.state.gender,
            p_name: this.state.p_name,
            occupation: this.state.occupation,
            source_of_found: this.state.source_of_found,
            country: this.state.country,
            state: this.state.state,
            district: this.state.district,
            address: this.state.address,
            id_type: this.state.id_type,
            id_number: this.state.id_number,
            issue_date: this.state.issue_date,
            expire_date: this.state.expire_date,
            id_issue_place: this.state.id_issue_place,
            nepal_id_number: this.state.nepal_id_number,
            nepal_issue_place: this.state.nepal_issue_place,
            term_condition: this.state.term_condition,
        }
        Axios.post(apiUrl + `/user/kyc/form`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";

            swal("", res.data.message, "success");

            console.log(res)
        })
    }
    deductBalance = (e, val) => {
        e.preventDefault();
        this.setState({
            selected_user: val,
            deductModal: true
        })
    }
    deductBalanceSubint = () => {
        if (this.state.deduct_amount <= 0) {
            swal("", "Amount should be more than 0.", "error");

            return false;
        }

        if (this.state.deduct_reason === "") {
            swal("", "Please enter reason.", "error");

            return false;
        }
        var data = {
            deduct_amount: this.state.deduct_amount,
            user_id: this.state.selected_user.id,
            balance_type: this.state.balance_type,
            deduct_reason: this.state.deduct_reason,
        }
        Axios.post(apiUrl + `/user/add/balance`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            if (type === "success") {
                this.getData();
                this.setState({
                    deduct_amount: 0,
                    selected_user: null,
                    deduct_from: "remit_balance",
                    deduct_reason: "",
                    deductModal: false
                })
            }
            swal("", res.data.message, "success");

            console.log(res)
        })
    }
    activeUser = e => {
        e.preventDefault();
        Axios.post(apiUrl + "/admin/make/user/acive", { id: this.state.id }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.getData();
            var type = res.data.success ? "success" : "error";
            swal("", res.data.message, "success");
            if (type === "success") {
                this.setState({
                    status: "active",

                })
            }
        })
    }
    approveUser = () => {
        if (this.state.input_notice === "") {
            swal("", "Please add notice.", "error");

            return false;
        }

        Axios.post(apiUrl + "/admin/approve/user", { id: this.state.id, status: "temp_login", notice: this.state.input_notice }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";

            swal("", res.data.message, "success");
            this.getData();
            if (type === "success") {
                this.setState({
                    status: "temp_login",
                    notice: this.state.input_notice,
                    //  blockModal: false,
                    input_notice: "",
                    tempLoginModal: false
                })
            }
        })
    }
    viwDetails = (e, user) => {
        e.preventDefault();
        this.setState(user);
        console.log(user);
        this.toggleViewModal();
    }
    toggleViewModal = () => {
        this.setState({
            viewModal: !this.state.viewModal
        });
    }
    toggleTempLoginModal = () => {
        this.setState({
            tempLoginModal: !this.state.tempLoginModal
        })
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
    toggleDeductModal = e => {
        this.setState({
            deductModal: !this.state.deductModal
        })
    }
    kycform = (e, user) => {
        this.setState({
            kycform: !this.state.kycform,
            user_id: user.id
        });
        // , () =>

        //     Axios.post(`${apiUrl}/user/kyc/data`, { user_id: user.id }, {
        //         headers: {
        //             Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
        //         }
        //     }).then(res => {
        //         this.setState({
        //             //  user_id:res.data.data.user_id,
        //             mobile: res.data.data ? res.data.data.mobile : null,
        //             membership_id: res.data.data ? res.data.data.membership_id : null,
        //             first_name: res.data.data ? res.data.data.first_name : null,
        //             middle_name: res.data.data ? res.data.data.middle_name : null,
        //             last_name: res.data.data ? res.data.data.last_name : null,
        //             nationality: res.data.data ? res.data.data.nationality : null,
        //             marital_status: res.data.data ? res.data.data.marital_status : null,
        //             dob: res.data.data ? res.data.data.dob : null,
        //             gender: res.data.data ? res.data.data.gender : null,
        //             p_name: res.data.data ? res.data.data.p_name : null,
        //             occupation: res.data.data ? res.data.data.occupation : null,
        //             source_of_found: res.data.data ? res.data.data.source_of_found : null,
        //             country: res.data.data ? res.data.data.country : null,
        //             state: res.data.data ? res.data.data.state : null,
        //             district: res.data.data ? res.data.data.district : null,
        //             address: res.data.data ? res.data.data.address : null,
        //             id_type: res.data.data ? res.data.data.id_type : null,
        //             id_number: res.data.data ? res.data.data.id_number : null,
        //             issue_date: res.data.data ? res.data.data.issue_date : null,
        //             expire_date: res.data.data ? res.data.data.expire_date : null,
        //             id_issue_place: res.data.data ? res.data.data.id_issue_place : null,
        //             nepal_id_number: res.data.data ? res.data.data.nepal_id_number : null,
        //             nepal_issue_place: res.data.data ? res.data.data.nepal_issue_place : null,
        //             term_condition: res.data.data ? res.data.data.term_condition : null,
        //         })
        //     })


    }


    editDetailsOtp = (e, det) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/send/otp`, { user_id: det.id }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({
                //   editModal: true,
                deductModal: true,
                otp_verified: false,
                selected_user: det,
                otp_token: res.data.otp_token
            })
            //      this.toggleDeductModal();
        })
    }
    verifyOtp = (e) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/verify/otp`, { otp: this.state.otp, otp_token: this.state.otp_token }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {

            if (res.data.success) {
                var det = this.state.selected_user;
                e.preventDefault();
                //  this.notify("success", res.data.message)
                swal("", res.data.message, "success");
                this.setState({
                    //    deductModal: true,
                    otp_verified: true
                })
            } else {
                swal("", res.data.message, "error");

            }

        })
    }
    hidekyc = () => {
        window.location.href = "dashboard";
    }
    render() {
        var kyc_functions = {
            handleInput: this.handleInput,
            kyc_submit: this.kyc_submit,
            hidekyc: this.hidekyc
        };
        return <>
            <Userheader user={this.state.user} />
            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />

                        {this.state.kycform && <>
                            <KycForm {...this.state} {...kyc_functions} />
                        </>}
                        {!this.state.kycform && <>
                            <div className="col-md-10 right_contentsec">
                                <div className="maine-table-dash-sec">


                                    <div className="heading-title-sec">
                                        <h3 className="admin-heading bg-offwhite">
                                            <p> User List </p>
                                            <span>{`Showing ${this.state.pagination_data.from} to ${this.state.pagination_data.to} from ${this.state.pagination_data.total} records`}</span>

                                        </h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>JOINING DATE</th>
                                                    <th>Name</th>
                                                    <th>E-mail</th>
                                                    <th>Mobile</th>
                                                    <th>REFERAL CODE</th>
                                                    <th>Wallet Balance</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-space">
                                                {this.state.details.map((hs, key) => {
                                                    return <tr>
                                                        <td>{moment(hs.created_at).calendar()}</td>
                                                        <td>{hs.name}</td>
                                                        <td>{hs.email}</td>
                                                        <td>{hs.mobile}</td>
                                                        <td>{hs.referer}</td>
                                                        <td>{hs.remit_balance}</td>
                                                        <td className="status-dash">{hs.status}</td>
                                                        <td className="status-dash">
                                                            <a className="btn_view" href="" title="View Details" onClick={(e) => { this.viwDetails(e, hs) }}><img src={require('assets/images/other-icons/view.png')} alt="View Details" style={{ width: "25px" }} /></a>&nbsp;

                                                            <a href="" className="balanceammout" title="Deduct Balance" onClick={(e) => { this.editDetailsOtp(e, hs) }}><i className="fa fa-money" style={{ fontSize: "20px" }}></i></a>

                                                            {this.state.user && this.state.user.is_kyc_allowed === "yes" && <a className="btn_view" href="#" title="KYC" onClick={(e) => { this.kycform(e, hs) }}><i class="fa fa-id-card" style={{ fontSize: "20px" }}></i></a>}&nbsp;

                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination pagination_data={this.state.pagination_data} getData={this.getData} />

                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </section>
            <Modal isOpen={this.state.deductModal} toggle={this.toggleDeductModal}>
                <ModalHeader toggle={this.toggleDeductModal} style={{ width: "100%" }}>Adjust Amount
                </ModalHeader>
                <ModalBody>
                    {!this.state.otp_verified && <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <lebel>OTP</lebel>
                                <input class="form-control" type="number" name="otp" value={this.state.otp} onChange={(e) => { this.handleInput(e) }} max="999999" min="100000" />
                            </div>
                        </div>
                    </div>}
                    {this.state.otp_verified && <>
                        <div className="form-group">
                            {this.state.selected_user && <label>Name: {this.state.selected_user.name} ({this.state.selected_user.mobile})</label>}
                        </div>

                        <div className="form-group">
                            {this.state.selected_user && <label>Wallet Balance: {this.state.selected_user.remit_balance}</label>}
                        </div>
                        <div className="form-group">
                            <label>Balance Type</label>
                            <select className="form-control" onChange={(e) => { this.handleInput(e) }} name="balance_type" value={this.state.balance_type}>
                                <option value="add">Add  Balance</option>
                                <option value="deduct">Deduct  Balance</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Amount</label>
                            <input type="number" min="0" className="form-control" onChange={(e) => { this.handleInput(e) }} name="deduct_amount" value={this.state.deduct_amount} />
                        </div>
                        <div className="form-group">
                            <label>Reason</label>
                            <textarea name="deduct_reason" onChange={(e) => { this.handleInput(e) }} value={this.state.deduct_reason} className="form-control"></textarea>
                        </div>

                    </>}
                </ModalBody>
                <ModalFooter>
                    {this.state.otp_verified &&
                        <button type="button" className="btn btn-danger" onClick={this.deductBalanceSubint}>
                            Submit
                        </button>
                    }
                    {!this.state.otp_verified && <button type="button" className="btn btn-danger" onClick={e => this.verifyOtp(e)}>
                        Verify OTP
                    </button>}

                </ModalFooter>
            </Modal>

            <Modal isOpen={this.state.tempLoginModal} toggle={this.toggleTempLoginModal}>
                <ModalHeader toggle={this.toggleTempLoginModal}>Notice</ModalHeader>
                <ModalBody>
                    <textarea name="input_notice" placeholder="Notice" onChange={(e) => { this.handleInput(e) }} value={this.state.input_notice} className="form-control"></textarea>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={this.approveUser}>
                        Submit
                    </button>
                </ModalFooter>
            </Modal>
            <Modal
                modalClassName="modal"
                isOpen={this.state.viewModal}
                toggle={this.toggleViewModal}
                size="lg"
            >
                <div className="modal-header">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleViewModal} style={{ zIndex: "999" }} >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="view-popup">
                        <h3>View User Detail</h3>
                        <div className="userddetails">
                            <Row>
                                <Col md="7">
                                    <div className="userimg">
                                        <img alt="..." onError={this.handleError} src={imageUrl + this.state.profile_pic} />

                                    </div>
                                    <div className="user-details">
                                        <strong className="username">{this.state.name}</strong>
                                        <p>Referal Code: <span>{this.state.referal_code ? this.state.referal_code : "N/A"}</span></p>

                                    </div>
                                </Col>
                                <Col md="5">
                                    <div className="activestatus">
                                        <p><strong className="username">Status</strong> <span>{this.state.status}</span></p>
                                        <div className="btn-account">

                                            {this.state.status === "pending" && <button type="button" className="btn btn-danger" onClick={(e) => { this.toggleTempLoginModal(e) }}>Reject</button>}
                                            {this.state.status !== "active" && <button type="button" className="btn btn-danger" onClick={(e) => { this.activeUser(e) }}>{this.state.status === "pending" ? "Approve" : "Active"} User</button>}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Row className="startedformsec">
                            <Col md="4">
                                <FormGroup>
                                    <label>Name</label>
                                    <h4>{this.state.name}</h4>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>Email</label>
                                    <h4>{this.state.email ? this.state.email : "N/A"}</h4>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>Phone Number</label>
                                    <h4>{this.state.mobile ? this.state.mobile : "N/A"}</h4>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <label>Address</label>
                                    <h4>{this.state.address ? this.state.address : "N/A"}</h4>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>City</label>
                                    <h4>{this.state.city ? this.state.city : "N/A"}</h4>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>District</label>
                                    <h4>{this.state.district ? this.state.district : "N/A"}</h4>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <label>State</label>
                                    <h4>{this.state.state ? this.state.state : "N/A"}</h4>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>Country</label>
                                    <h4>{this.state.country ? this.state.country : "N/A"}</h4>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>PIN Code</label>
                                    <h4>{this.state.pincode ? this.state.pincode : "N/A"}</h4>
                                </FormGroup>
                            </Col>
                            {this.state.csp_code && <Col md="6">
                                <FormGroup>
                                    <label>CSP COde</label>
                                    <h4>{this.state.csp_code}</h4>
                                </FormGroup>
                            </Col>}
                        </Row>
                        <Row>
                            {this.state.status === "blocked" && <Col md="6">
                                <FormGroup>
                                    <label>Block Reason</label>
                                    <h4>{this.state.block_reason ? this.state.block_reason : "N/A"}</h4>
                                </FormGroup>
                            </Col>}
                            {this.state.status === "temp_login" && <Col md="6">
                                <FormGroup>
                                    <label>Notice</label>
                                    <h4>{this.state.notice ? this.state.notice : "N/A"}</h4>
                                </FormGroup>
                            </Col>}
                        </Row>
                        <Row>
                            {this.state.license_back && <Col md="6">
                                <div className="imageidentity">
                                    <label style={{ textAlign: "center" }}>Identity Back ({this.state.proof_type.replace("_", " ")})</label>
                                    <img src={imageUrl + this.state.license_back} alt=" " onClick={e => { this.toggleImageModal(e, imageUrl + this.state.license_back) }} />
                                </div>
                            </Col>}
                            {this.state.license_front && <Col md="6">
                                <div className="imageidentity">
                                    <label style={{ textAlign: "center" }}>Identity Front({this.state.proof_type.replace("_", " ")})</label>
                                    <img src={imageUrl + this.state.license_front} alt=" " onClick={e => { this.toggleImageModal(e, imageUrl + this.state.license_front) }} />
                                </div>
                            </Col>}

                        </Row>
                    </div>
                </div>
            </Modal>
            <Userfooter />
        </>;
    }
}
export default Userlist;
