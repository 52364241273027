import React from "react";
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Form,
   Input,
   Row,
   Col,
   Table,
   Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Categories extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading:false,
         commission_type:"flat",
         categories: [],
         admin_commission:0,
         package_id:"",
         saathi_commission:0,
         partner_commission:0,
         state_partner_commission:0,
         api_client:0,
         user_commission:0,
         position:"",
         description: "",
         ServiceData:[],
         amount:"",
         status: "",
         id:"",
         package_name: "",
         position: "",
         module:"",
         category_icon: null,
         show_form: false,
         modal: false,
         selected_category: null
      }
   }
   componentDidMount = () => {
      this.getAllCommissionPackage();
   }
   inputChange = (e) => {
      this.setState({
         [e.target.name]: e.target.value
      })
   }

   inputChange3 = (e, id, type) => {
      console.log(e.target.name, id, this.state.commission_type, this.state.package_id)
      this.setState({
         [e.target.name]: e.target.value
      })
      Axios.post(`${apiUrl}/admin/save/commission`, { value: e.target.value, commission_type:this.state.commission_type,  package_id:this.state.package_id, type:type, service_id:id}, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.setState({
            })
         }
         else {
            this.setState({
            })
         }
      })

   }

   inputChange4 = (e, id) => {
      console.log(e.target.name, id, this.state.commission_type, this.state.package_id)
      this.setState({
         [e.target.name]: e.target.value
      })
      Axios.post(`${apiUrl}/admin/update/commission`, { value: e.target.value,  package_id:this.state.package_id, service_id:id}, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.setState({
            })
         }
         else {
            this.setState({
            })
         }
      })

   }



   inputChange2 = (e, id) => {
      this.setState({
         [e.target.name]: e.target.value,
         package_id:id
      })
      this.getModule(e.target.value, id)
   }

   getModule = (value, id) => {
      Axios.post(`${apiUrl}/admin/filter/service/provider`, { type: value, package_id:id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            this.setState({
               modal: true,
               ServiceData: res.data.categories
            })
         }
         else {
            this.setState({
               ServiceData: []
            })
         }
      })
   }
   onFileChange = (e) => {
      this.setState({ category_icon: e.target.files[0] })
   }

   saveCategory = e => {
      e.preventDefault();
      if (this.state.package_name == "") {
         this.notify("error", "Please enter category name.");
         return false;
      }
      if (this.state.status == "") {
         this.notify("error", "Please select category status.");
         return false;
      }

      const formData = new FormData();
      formData.append('package_name', this.state.package_name);
      formData.append('status', this.state.status);
      if(this.state.id){
        formData.append('id', this.state.id);
        Axios.post(`${apiUrl}/admin/update/commission/package`, formData, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            if (res.data.success) {
               this.notify("success", res.data.message);
               this.showForm();
               this.getAllCommissionPackage();
            } else {
               this.notify("error", res.data.message);
            }
         })
      }else{
        Axios.post(`${apiUrl}/admin/save/commission/package`, formData, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            if (res.data.success) {
               this.notify("success", res.data.message);
               this.showForm();
               this.getAllCommissionPackage();
            } else {
               this.notify("error", res.data.message);
            }
         })
      }

   }

   getAllCommissionPackage = () => {
      Axios.get(`${apiUrl}/admin/all/commission/package`, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            this.setState({
               categories: res.data.categories
            })
         }
         else {
            this.setState({
               categories: []
            })
         }
      })
   }
   statusChangeCategory = (e, id) => {
      e.preventDefault();
      Axios.post(`${apiUrl}/admin/category/change/status`, { category_id: id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.getAllCommissionPackage();
         }
         else {
            this.notify("error", res.data.message);
         }
      }, err => {
         this.notify("error", "Something went wrong");
         console.log("status error", err)
      })
   }

   editForm = (e ,val) => {
    if (e) e.preventDefault();
    this.setState({
        show_form: true,
       package_name: val.package_name,
       status:val.status,
       id:val.id,
       position:val.position,
       amount:val.amount

    })
 }

   showForm = (e = null) => {
      if (e) e.preventDefault();
      this.setState({
         show_form: !this.state.show_form,
         category_icon: null,
         package_name: "",
         description: "",
         status: "",
         id:"",
         position: "",
         amount:""
      })
   }
   positionChangeCategory = (e, cat) => {
      e.preventDefault();
      this.setState({
         modal: true,
         selected_category: cat,
         position: cat.position
      })
   }
   notify = (type, message) => {
      var atype = type === "error" ? "danger" : type;
      var options = {};
      options = {
         place: process.env.REACT_APP_TOAST_POSITION,
         message: (
            <div>
               <div>
                  {message}
               </div>
            </div>
         ),
         type: atype,
         icon: "tim-icons icon-bell-55",
         autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
   };
   toggle = (e) => {
      this.setState({
         modal: !this.state.modal
      })
   }
   changePosition = (e) => {
      if (this.state.selected_category.position != this.state.position) {
         var data = {
            new_position: this.state.position,
            id: this.state.selected_category.id,
            old_position: this.state.selected_category.position
         }
         Axios.post(`${apiUrl}/admin/change/category/position`, data, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
         }).then(res => {
            this.notify("success", "Posiotn changed successfully.");
            this.setState({
               categories: res.data.categories,
               modal: false
            })
         })
      }
   }
   commingSoonActioon = (e, cat) => {
      e.preventDefault();
      console.log(cat)
      Axios.post(`${apiUrl}/admin/comming/soon/manage`, { category_id: cat.id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.notify(res.data.success ? "success" : "error", res.data.message);
         this.getAllCommissionPackage();

      })
   }
   changeAccess = (e, val, type) => {
      e.preventDefault();
      this.setState({
         loading: true
      })
      var data = {
         type: type,
         category_id: val.id,
         status: e.target.checked ? "yes" : "no"
      }
      Axios.post(`${apiUrl}/admin/categories/update/access`, data, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.setState({
            loading: false
         })
         this.notify(res.data.success ? "success" : "error", res.data.message);
         if (res.data.success) {
            this.setState({
               categories: res.data.categories
            })
         }

      },err=>{

         this.setState({
            loading: false
         })
         this.notify("error", "Something went wrong");
      })
   }

   handleChange(name, e){
      var change = {};
      change[name] = e.target.value;
      this.setState(change);
      console.log(name)
    }
   render() {
      return (
         <>
         {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
            <div className="content">
               <div className="react-notification-alert-container">
                  <NotificationAlert ref="notificationAlert" />
               </div>
               <Row>
                  <Col md="12">
                     <Card>
                        <CardHeader>
                           <h3 className="title">Commission Slab
                              {!this.state.show_form && <small className="float-right"><a href="" className="btn btn-success" onClick={(e) => { this.showForm(e) }} >+ Add New</a></small>}
                           </h3>
                        </CardHeader>
                        <CardBody>
                           {this.state.show_form && <Form onSubmit={this.saveCategory}>
                              <Row>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Package Name</label>
                                       <Input value={this.state.package_name} name="package_name" placeholder="Category Name" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>
                                          Status
                                       </label>
                                       <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                                          <option value="">Select</option>
                                          <option value="active">Active</option>
                                          <option value="inactive">Inactive</option>
                                       </select>
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                 Cancel
                              </Button>
                              <Button className="btn-fill" color="primary" type="submit">
                                 Save
                              </Button>
                           </Form>}
                           <Table className="tablesorter" responsive>
                              <thead className="text-primary">
                                 <tr>
                                    <th>Sr.</th>
                                    <th>Package Name</th>
                                    <th>Status</th>
                                    <th>Assign Commission</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.categories.map((val, key) => {
                                    return <tr key={key}>
                                       <td>{key+1}</td>
                                       <td>{val.package_name}</td>
                                       <td>{val.status}</td>
                                       <td>
                                          <select class="form-control" name="module-{ key }" defaultValue={this.state.module} onChange={(e) => { this.inputChange2(e, val.id) }}>
                                                <option value=''>Choose Module</option>
                                                <option value='Recharge Indian'>Indian Recharge</option>
                                                <option value='Rechage Nepal'>Nepal Recharge</option>
                                                <option value='Bill Indian'>Indian Bill Payment</option>
                                                <option value='Bill Nepal'>Nepal Bill Payment</option>
                                                <option value='Ime'>IME</option>
                                                <option value='Prabhu'>Prabhu</option>
                                          </select>
                                       </td>
                                       <td>
                                          <a href="" onClick={(e) => { this.editForm(e, val) }} >
                                            <i className="fa fa-pencil-square-o" title="Edit Category"></i>

                                          </a>
                                       </td>
                                    </tr>
                                 })}
                              </tbody>
                           </Table>
                        </CardBody>
                        {/* <CardFooter>

                                </CardFooter> */}
                     </Card>
                  </Col>
               </Row>
            </div>
            <Modal  size="lg" isOpen={this.state.modal} toggle={this.toggle} >
               <ModalHeader toggle={this.toggle}>Recharge Commission</ModalHeader>
               <ModalBody>
               <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                     <tr>
                        <th>Sr.</th>
                        <th>Operator Name</th>
                        <th>Type</th>
                        <th>Admin Commission</th>
                        <th>Saathi Commission</th>
                        <th>Partner Commission</th>
                        <th>State Partner Commission</th>
                        <th>User Commission</th>
                        <th>API Client</th>
                     </tr>
                  </thead>
                  <tbody>
                     {this.state.ServiceData.map((val, key) => {
                        return <tr key={key}>
                           <td>{key+1}</td>
                           <td>{val.provider_name}</td>
                           <td>
                              <select name="commission_type-{ key }" onChange={(e) => { this.inputChange4(e, val.id) }} defaultValue={val.commission_type}>
                                 <option value="flat">Flat</option>
                                 <option value="percent">Percent</option>
                              </select>
                           </td>
                           <td><input type="text" onBlur={(e) => { this.inputChange3(e, val.id, "admin_commission") }} style={{ "width": "40%"}} name="admin_commission-{ key }" defaultValue={val.admin_commission} /></td>

                           <td><input type="text"  onBlur={(e) => { this.inputChange3(e, val.id, "saathi_commission") }}  style={{ "width": "40%"}} name="saathi_commission-{ key }" defaultValue={val.saathi_commission} /></td>


                           <td><input type="text" onBlur={(e) => { this.inputChange3(e, val.id, "partner_commission") }} style={{ "width": "40%"}} name="partner_commission-{ key }" defaultValue={val.partner_commission} /></td>
                           <td><input type="text" onBlur={(e) => { this.inputChange3(e ,val.id, "state_partner_commission") }} style={{ "width": "40%"}} name="state_partner_commission-{ key }" defaultValue={val.state_partner_commission} /></td>

                           <td><input type="text" onBlur={(e) => { this.inputChange3(e, val.id, "user_commission") }} style={{ "width": "40%"}} name="user_commission-{ key }" defaultValue={val.user_commission} /></td>

                           <td><input type="text" onBlur={(e) => { this.inputChange3(e, val.id, "api_client") }} style={{ "width": "40%"}} name="api_client-{ key }" defaultValue={val.api_client} /></td>


                        </tr>
                     })}
                  </tbody>
               </Table>
               </ModalBody>
               <ModalFooter>
                  {/* <Button color="primary" onClick={this.changePosition}>Update</Button>{' '} */}
                  {/* <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
               </ModalFooter>
            </Modal>
         </>
      );
   }
}

export default Categories;
