import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import { Pagination } from 'react-laravel-paginex';
import swal from "sweetalert";
import { CSVLink } from "react-csv";
import BSList from "./forms/BSList";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Becomesaathilist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageModal: false,
            loading: false,
            Reports: [],
            exceldata: [],
            Report_name: "",
            commission: "",
            sort_by:"date_desc",
            status: "pending",
            keyword: "",
            icon: "",
            id: "",
            start_date: "",
            end_date: "",
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            },
            short: 10,
            site_title: "Online Saathi Report Issue",
            make_agent_modal: null,
            selected_record: null,
            message: ""
        }
    }
    componentDidMount = () => {
        document.title = this.state.site_title
        this.getAllReports(1);
    }

    getAllReports = (page = 1, keyword) => {

        var data = { keyword: keyword ? keyword : this.state.keyword };
        data.short = this.state.short;
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.status != "") {
            data.status = this.state.status;
        }
        if (this.state.sort_by != "") {
            data.sort_by = this.state.sort_by;
        }
        
        if (this.props.limit) {
            data.limit = this.props.limit;
        }
        Axios.post(`${apiUrl}/admin/Becomeagent/list?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("data response", res)
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.data,
                    Reports: res.data.data.data
                })
                if (page === 1) {
                    this.setState({
                        exceldata: []
                    })
                    this.getRecordsExcel();
                }
            }
            else {
                this.setState({
                    Reports: [],
                    exceldata: []
                })
            }
        })
    }

    getRecordsExcel = (page = 1) => {
        //      var data = {};
        var data = { forexcel: "yes", keyword: this.state.keyword };
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.sort_by != "") {
            data.sort_by = this.state.sort_by;
        }
        
        Axios.post(`${apiUrl}/admin/Becomeagent/list?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response excel", res)
            if (res.data.success) {
                var exceldata = [];
                res.data.data.forEach((val, key) => {
                    var dat = {

                        "Name": val.name,
                        "Mobile": val.contact_number,
                        "E-mail": val.email,
                        "Organization": val.organization,
                        "State": val.state,
                        "City": val.city,
                        "Description": val.description,
                        "Aadhar No": val.adhar_card_number,
                        "Pan No": val.pan_card_number,
                        "Account No": val.bank_account_number,
                        "Status": val.status
                    }
                    exceldata.push(dat);
                });
                this.setState({
                    exceldata: exceldata
                })
            }
            else {
                this.setState({
                    details: []
                })
            }
        })
    }

    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    handleInputshort = e => {
        sessionStorage.setItem("report_short", e.target.value);
        this.setState({ short: e.target.value }, () => { this.getAllReports(1) });

    }
    makeAgentModal = (e, val = null) => {
        if (e) e.preventDefault();
        this.setState({
            make_agent_modal: !this.state.make_agent_modal,
            selected_record: val,
            user: val ? val.user : null
        })
    }
    makeAgentWithoutIME = (e, val) => {
        if (e) e.preventDefault();
        swal({ title: "Make Agent", text: `Do you want to make ${val.name} an agent.`, buttons: ['No', 'Yes'] }).then(yes => {
            if (yes) {
                Axios.post(apiUrl + "/admin/make/agent/without/ime", { user_id: val.user_id, payment_method: 'free' }, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("_access")
                    }
                }).then(res => {
                    if (res.data.success) {
                        this.notify('success', res.data.message);
                        this.getAllReports(1);
                    }
                    else {
                        this.notify('error', res.data.message)
                    }
                })
            }
        })
    }
    rejectAgent = (e, val) => {
        if (e) e.preventDefault();
        swal({ title: "Reject Agent", text: `Do you want to reject ${val.name} an agent.`, buttons: ['No', 'Yes'] }).then(yes => {
            if (yes) {
                Axios.post(apiUrl + "/admin/reject/agent", { id: val.id }, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("_access")
                    }
                }).then(res => {
                    if (res.data.success) {
                        this.notify('success', res.data.message);
                        this.getAllReports(1);
                    }
                    else {
                        this.notify('error', res.data.message)
                    }
                })
            }
        })
    }
    changePage = data => {
        console.log(data)
        this.getAllReports(data.page);
    }
    statusChangeReport = (e, id) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/admin/Report/change/status`, { Report_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.getAllReports(this.state.paginatedata.current_page);
            }
            else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "Something went wrong");
            console.log("status error", err)
        })
    }


    notify = (type, message) => {
        console.log("this.refs.notificationAlert before", this.refs.notificationAlert)

        if (this.refs.notificationAlert && this.refs.notificationAlert.state.notifyTR.length <= 1) {
            var atype = type === "error" ? "danger" : type;
            var options = {};
            options = {
                place: process.env.REACT_APP_TOAST_POSITION,
                message: (
                    <div>
                        <div>
                            {message}
                        </div>
                    </div>
                ),
                type: atype,
                icon: "tim-icons icon-bell-55",
                autoDismiss: 7
            };
            this.refs.notificationAlert.notificationAlert(options);
            console.log("this.refs.notificationAlert", this.refs.notificationAlert)
        }
    };


    savenotification = (user_id) => {

        if (this.state.message == "") {
            this.notify("error", "Please enter message.");
            return false;
        }
        const formData = new FormData();
        formData.append('title', 'Insufficient Balance');
        formData.append('type', 'single');
        formData.append('user_id', user_id);
        formData.append('message', this.state.message);
        formData.append('status', "active");
        Axios.post(`${apiUrl}/admin/create-coustom-notification`, formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.makeAgentModal();
            } else {
                this.notify("error", res.data.message);
            }
        })
    }
    sendReply = (e, id) => {
        if (e) e.preventDefault();
        if (this.state.message === "") {
            this.notify("error", "Please enter message.");
            return false;
        }
        this.savenotification(id);
    }
    viewImage = (e, image = null) => {
        e.preventDefault();
        this.setState({
            imageModal: !this.state.imageModal,
            selected_image: image ? imageUrl + image : null
        })
    }

    searchKeywords = (e) => {
        var key = e.target.value;
        this.setState({ keyword: key });
        if (key.length > 2 || key === "") {
            this.getAllReports(1, key);
        }
    }
    render() {
        var listFunctions = {
            rejectAgent: this.rejectAgent,
            makeAgentModal: this.makeAgentModal,
            viewImage: this.viewImage
        }
        return (
            <>
                {!this.props.embed && <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">Become Saathi List
                                        <small className="float-right">
                                            {this.state.exceldata.length > 0 && <CSVLink data={this.state.exceldata} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"become-saathi-list-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}
                                            {this.state.exceldata.length === 0 && <a href="" onClick={(e) => { e.preventDefault() }} style={{ color: "#d4d6d5", background: "none", padding: "10px 8px", borderRadius: "0px", pointerEvents: "none" }} title="Export to CSV"><i className="fa fa-spinner fa-spin"></i>Excel</a>}
                                        </small>

                                    </h3>
                                    <div className="row userhistoryrem">
                                        <div className="col-md-3">
                                            <label>Keywords</label>
                                            <input type="text" className="form-control" name="keyword" value={this.state.keyword} onChange={(e) => { this.searchKeywords(e) }} />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Status</label>
                                            <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.handleInput(e) }}>
                                                <option value="approved">Approved</option>
                                                <option value="pending">Pending</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label>Sort By</label>
                                            <select name="sort_by" className="form-control" onChange={e => { this.handleInput(e) }} value={this.state.sort_by}>
                                                <option value="date_asc">Latest Request First</option>
                                                <option value="date_desc">Latest Request Last</option>
                                                <option value="remit_desc">Wallet Balance High to Low</option>
                                                <option value="remit_asc">Wallet Balance Low to High</option>
                                             
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-2">
                                            <label>{" "}</label>
                                            <input type="button" name="submit" className="btn btn-primary searchhistory" onClick={(e) => { this.getAllReports(1) }} value="Search" />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>

                                    <BSList {...this.state} {...listFunctions} action={true} />
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>}
                {this.props.embed && <BSList {...this.state} {...listFunctions} action={true} />}
                <Modal isOpen={this.state.make_agent_modal} toggle={this.makeAgentModal} size="lg">
                    <ModalHeader toggle={this.makeAgentModal}>Receipt</ModalHeader>
                    {this.state.selected_record && <ModalBody>
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Wallet Balance</th>
                                    <th>E-mail</th>
                                    <th>Organization</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Description</th>
                                    <th>Aadhar No</th>
                                    <th>Pan No</th>
                                    <th>Account No</th>
                                    <th>Pan Image</th>
                                    <th>Aadhar Front Image</th>
                                    <th>Aadhar Back Image</th>
                                    <th>Bank  Image</th>
                                    <th>Other  Image</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.state.selected_record.name}</td>
                                    <td>{this.state.selected_record.contact_number}</td>
                                    <td>&#8377;{this.state.user ? this.state.user.remit_balance : 0}</td>
                                    <td>{this.state.selected_record.email}</td>
                                    <td>{this.state.selected_record.organization}</td>
                                    <td>{this.state.selected_record.state}</td>
                                    <td>{this.state.selected_record.city}</td>
                                    <td>{this.state.selected_record.description}</td>
                                    <td>{this.state.selected_record.adhar_card_number}</td>
                                    <td>{this.state.selected_record.pan_card_number}</td>
                                    <td>{this.state.selected_record.bank_account_number}</td>
                                    <td>{this.state.selected_record.pan_image && <a href="" onClick={(e) => { this.viewImage(e, this.state.selected_record.pan_image) }} > <img src={imageUrl + this.state.selected_record.pan_image} alt=" " style={{ width: "50px" }} /></a>}</td>
                                    <td>{this.state.selected_record.adhar_front && <a href="" onClick={(e) => { this.viewImage(e, this.state.selected_record.adhar_front) }} > <img src={imageUrl + this.state.selected_record.adhar_front} alt=" " style={{ width: "50px" }} /></a>}</td>
                                    <td>{this.state.selected_record.adhar_back && <a href="" onClick={(e) => { this.viewImage(e, this.state.selected_record.adhar_back) }} > <img src={imageUrl + this.state.selected_record.adhar_back} alt=" " style={{ width: "50px" }} /></a>}</td>
                                    <td>{this.state.selected_record.bank_image && <a href="" onClick={(e) => { this.viewImage(e, this.state.selected_record.bank_image) }} > <img src={imageUrl + this.state.selected_record.bank_image} alt=" " style={{ width: "50px" }} /></a>}</td>
                                    <td>{this.state.selected_record.other_documents && <a href="" onClick={(e) => { this.viewImage(e, this.state.selected_record.other_documents) }} > <img src={imageUrl + this.state.selected_record.other_documents} alt=" " style={{ width: "50px" }} /></a>}</td>


                                    <td>{this.state.selected_record.status}</td>

                                </tr>
                            </tbody>
                        </Table>
                        <div>
                            <label>Message</label>
                            <textarea name="message" className="form-control" onChange={this.inputChange}></textarea>
                        </div>
                        <a href="" className="btn btn-warning" onClick={(e) => { this.rejectAgent(e, this.state.selected_record) }} >
                            Reject
                        </a>
                        <a href="" className="btn btn-success ml-2" onClick={(e) => { this.makeAgentWithoutIME(e, this.state.selected_record) }} >
                            Make Agent
                        </a>
                        <a href="#" className="btn btn-info ml-2" onClick={(e) => { this.sendReply(e, this.state.selected_record.user_id) }} >
                            Send Notification
                        </a>
                    </ModalBody>}
                </Modal>
                <Modal isOpen={this.state.imageModal} toggle={this.viewImage} size="lg">
                    <ModalHeader toggle={this.viewImage}>{" "}</ModalHeader>
                    <ModalBody>
                        <img src={this.state.selected_image} alt="" />
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default Becomesaathilist;


