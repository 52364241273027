import React from "react";
import { NavLink } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "../../assets/css/style.css";
class Home extends React.Component {


    render() {
        return <>

            <div className="site_home">
                <Header />
                <section className="services_sec">
                    <div className="container">
                        <h2>Our Solutions</h2>
                    
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="service">
                                    <div className="ser_img"> <img src={require("assets/images/Safe Jobs Connect.png")} alt="nepalese-rupee" /> </div>
                                    <div className="ser_cnt">
                                        <h4>Safe Jobs Connect</h4>
                                        <p>Discover a world of opportunities with our Safe Jobs Connect services. We are 
                                           dedicated to enhancing your employment journey, providing a holistic approach to 
                                           job readiness and placement </p>
                                        <NavLink to="/services" >Read more</NavLink> </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="service">
                                    <div className="ser_img"> <img src={require("assets/images/Government Schemes Services.png")} alt="" /> </div>
                                    <div className="ser_cnt">
                                        <h4>Government Schemes Services</h4>
                                        <p>Navigate the world of government schemes with ease. Our services are designed to 
                                           empower you by providing information, application support, and seamless liaising 
                                           with government agencies </p>
                                        <NavLink to="/services" >Read more</NavLink> </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="service">
                                    <div className="ser_img"> <img src={require("assets/images/On-Demand Services.png")} alt="" /> </div>
                                    <div className="ser_cnt">
                                        <h4> On-Demand Services </h4>
                                        <p>Access a spectrum of essential services at your fingertips. Our on-demand services 
                                            cover banking, insurance, legal assistance, travel services, health services, 
                                            documentation, and business services.</p>
                                        <NavLink to="/services" >Read more</NavLink></div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="service">
                                    <div className="ser_img"> <img src={require("assets/images/Social Community.png")} alt="" /> </div>
                                    <div className="ser_cnt">
                                        <h4>Social Community:</h4>
                                        <p>Join a vibrant social community where individuals connect, share, and grow 
                                            together. Our platform fosters the creation of self-help and youth groups, providing 
                                            a space for collaboration and empowerment. </p>
                                        <NavLink to="/services" >Read more</NavLink> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="work section" id="work">
                    <div className="container">
                        <h2 className="center_head"> Mission </h2>
                        <div id="missonSection">
                            To create 1 Lakh community leaders (Saathis) who appreciate the challenges and 
                            will commit to provide on demand services to targeted people in the community.
                        </div>
                    </div>
                </section>

                <section className="work section" id="work">
                    <div className="container">
                        <h2 className="center_head"> Our Reach </h2>
                        <div className="servies-reach-head">
                            <div className="servies-reach-provider">
                                <div className="servies-reach-h3"> <h3>25000 +</h3> </div>
                                <div className="servies-reach-name"> <h3>Citizen</h3> </div>
                            </div>
                            <div className="servies-reach-provider-second">
                                <div className="servies-reach-h3"> <h3>1000 +</h3> </div>
                                <div className="servies-reach-name"> <h3>Saathis Network</h3> </div>
                            </div>
                            <div className="servies-reach-provider-third">
                                <div className="servies-reach-h3"> <h3>120 +</h3> </div>
                                <div className="servies-reach-name"> <h3>Services Provider </h3> </div>
                            </div>
                            <div className="servies-reach-provider-forth">
                                <div className="servies-reach-h3"> <h3>12 +</h3> </div>
                                <div className="servies-reach-name"> <h3>States Footprint </h3> </div>
                            </div>
                        </div>
                    </div>
                </section>

                 <section className="work section" id="work">
                    <div className="container">
                    <div class="testimonials text-center">

                        <div class="container">
                        <h2 className="center_head">Testimonials</h2>
                        <div class="row">
                            <div class="col-md-6 col-lg-4">
                            <div class="card border-light bg-light text-center" id="testimonial-continer-parent">
                                <i class="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                <div class="card-body blockquote" style={{border: "none"}}>
                                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer bibendum enim eu nibh finibus</p>
                                <footer class="blockquote-footer"><cite title="Source Title">Mohamed Nady</cite></footer>
                                </div>
                            </div>
                            </div>

                            <div class="col-md-6 col-lg-4">
                            <div class="card border-light bg-light text-center" id="testimonial-continer-parent">
                                <i class="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                <div class="card-body blockquote" style={{border: "none"}}>
                                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer bibendum enim eu nibh finibus</p>
                                <footer class="blockquote-footer"><cite title="Source Title">Mohamed Reda</cite></footer>
                                </div>
                            </div>
                            </div>

                            <div class="col-md-6 col-lg-4">
                            <div class="card border-light bg-light text-center" id="testimonial-continer-parent">
                                <i class="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                <div class="card-body blockquote" style={{border: "none"}}>
                                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer bibendum enim eu nibh finibus</p>
                                <footer class="blockquote-footer"><cite title="Source Title">Mohamed Atef</cite></footer>
                                </div>
                            </div>
                            </div>

                        </div>
                        </div>

                    </div>
                    </div>
                </section>

                <section className="work section bg-2" id="work">
                    <div className="container">
                        <h2 className="center_head">How Its work</h2>
                        <div className="row">
                            <div className="col-lg-6 align-self-center text-center">
                                <div className="image-block"> <img className="phone-thumb-md" src={require("assets/images/new/iphone-feature.png")} alt="iphone-feature" /> </div>
                            </div>
                            <div className="col-lg-6 col-md-10 m-md-auto align-self-center ml-auto">
                                <div className="work-block">
                                    <div className="work-item">
                                        <div className="icon"> <img src={require("assets/images/new/add-user.png")} alt="#" /> </div>
                                        <div className="content">
                                            <h5>01.Create account</h5>
                                            <p>Download Online Saathi app from playstore/Appstore and creat your account easily after the fill few details.</p>
                                        </div>
                                    </div>
                                    <div className="work-item active">
                                        <div className="icon"> <img src={require("assets/images/new/add-money-w.png")} alt="#" /> </div>
                                        <div className="content">
                                            <h5>02.Add Money</h5>
                                            <p>Add money to your Online Saathi wallet using IMPS/NEFT/UPI and debit/credit card to instant update your Online Saathi wallet.</p>
                                        </div>
                                    </div>
                                    <div className="work-item">
                                        <div className="icon"> <img src={require("assets/images/new/transaction.png")} alt="#" /> </div>
                                        <div className="content">
                                            <h5>03.Recharge Remit</h5>
                                            <p>Do Recharge/Remitt and bill payment within seconds after the few click and enjoy with your friends and family.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <div className="texti_sec">
                    <div className="container">
                        <h2 className="">What Our Customers Are Saying </h2>
                        <section id="demos">
                            <div className="row">
                                <div className="col-lg-12">
                                    <OwlCarousel className="owl-carousel owl-theme" items={1} nav={true}>
                                        <div className="item">
                                            <div className="textmonial">
                                                <div className="row testi_rw">
                                                    <div className="col-lg-2 col-sm-2 col-md-2 col-xs-12">
                                                        <div className="client">
                                                            <div className="client_img"><img src={require("assets/images/new/testi-img.jpg")} alt="" /></div>
                                                            <h4>Droff Jangra</h4>
                                                            <h5>Designer</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9 col-sm-9 col-md-9 col-xs-12">
                                                        <div className="client_text">
                                                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer lacinia massa ut odio mollis efficitur. Proin vitae fermentum risus. Nam augue lorem, mollis sit amet feugiat non, tincidunt sit amet dolor. Quisque sit amet aliquam neque, et euismod neque. Praesent nec efficitur magna, eu fringilla dolor. Vestibulum urna diam, dignissim ac luctus nec, consectetur id tellus. Morbi sit amet lectus vel nunc pellentesque tempor non molestie eros. Duis porta sit amet velit ut blandit. </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-sm-1 col-md-1 col-xs-12"> </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="textmonial">
                                                <div className="row testi_rw">
                                                    <div className="col-lg-2 col-sm-2 col-md-2 col-xs-12">
                                                        <div className="client">
                                                            <div className="client_img"><img src={require("assets/images/new/testi-img.jpg")} alt="" /></div>
                                                            <h4>Droff Jangra</h4>
                                                            <h5>Designer</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9 col-sm-9 col-md-9 col-xs-12">
                                                        <div className="client_text">
                                                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer lacinia massa ut odio mollis efficitur. Proin vitae fermentum risus. Nam augue lorem, mollis sit amet feugiat non, tincidunt sit amet dolor. Quisque sit amet aliquam neque, et euismod neque. Praesent nec efficitur magna, eu fringilla dolor. Vestibulum urna diam, dignissim ac luctus nec, consectetur id tellus. Morbi sit amet lectus vel nunc pellentesque tempor non molestie eros. Duis porta sit amet velit ut blandit. </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-sm-1 col-md-1 col-xs-12"> </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="textmonial">
                                                <div className="row testi_rw">
                                                    <div className="col-lg-2 col-sm-2 col-md-2 col-xs-12">
                                                        <div className="client">
                                                            <div className="client_img"><img src={require("assets/images/new/testi-img.jpg")} alt="" /></div>
                                                            <h4>Droff Jangra</h4>
                                                            <h5>Designer</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9 col-sm-9 col-md-9 col-xs-12">
                                                        <div className="client_text">
                                                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer lacinia massa ut odio mollis efficitur. Proin vitae fermentum risus. Nam augue lorem, mollis sit amet feugiat non, tincidunt sit amet dolor. Quisque sit amet aliquam neque, et euismod neque. Praesent nec efficitur magna, eu fringilla dolor. Vestibulum urna diam, dignissim ac luctus nec, consectetur id tellus. Morbi sit amet lectus vel nunc pellentesque tempor non molestie eros. Duis porta sit amet velit ut blandit. </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-sm-1 col-md-1 col-xs-12"> </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="textmonial">
                                                <div className="row testi_rw">
                                                    <div className="col-lg-2 col-sm-2 col-md-2 col-xs-12">
                                                        <div className="client">
                                                            <div className="client_img"><img src={require("assets/images/new/testi-img.jpg")} alt="" /></div>
                                                            <h4>Droff Jangra</h4>
                                                            <h5>Designer</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9 col-sm-9 col-md-9 col-xs-12">
                                                        <div className="client_text">
                                                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer lacinia massa ut odio mollis efficitur. Proin vitae fermentum risus. Nam augue lorem, mollis sit amet feugiat non, tincidunt sit amet dolor. Quisque sit amet aliquam neque, et euismod neque. Praesent nec efficitur magna, eu fringilla dolor. Vestibulum urna diam, dignissim ac luctus nec, consectetur id tellus. Morbi sit amet lectus vel nunc pellentesque tempor non molestie eros. Duis porta sit amet velit ut blandit. </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-sm-1 col-md-1 col-xs-12"> </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="textmonial">
                                                <div className="row testi_rw">
                                                    <div className="col-lg-2 col-sm-2 col-md-2 col-xs-12">
                                                        <div className="client">
                                                            <div className="client_img">
                                                                <img src={require("assets/images/new/testi-img.jpg")} alt="" />
                                                            </div>
                                                            <h4>Droff Jangra</h4>
                                                            <h5>Designer</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9 col-sm-9 col-md-9 col-xs-12">
                                                        <div className="client_text">
                                                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer lacinia massa ut odio mollis efficitur. Proin vitae fermentum risus. Nam augue lorem, mollis sit amet feugiat non, tincidunt sit amet dolor. Quisque sit amet aliquam neque, et euismod neque. Praesent nec efficitur magna, eu fringilla dolor. Vestibulum urna diam, dignissim ac luctus nec, consectetur id tellus. Morbi sit amet lectus vel nunc pellentesque tempor non molestie eros. Duis porta sit amet velit ut blandit. </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-sm-1 col-md-1 col-xs-12"> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </section>
                    </div>
                </div> */}
                <section className="news_sec">
                    <div className="container">
                        <h2>Latest News</h2>
                        <p>Get to know about our latest updates</p>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="news">
                                    <div className="news_img"> <img src={require("assets/images/new/online-sath-logo.png")} alt="nepalese-rupee" /> </div>
                                    <div className="ser_cnt">
                                        <h4>प्रवासी नेपालीको सहूलियत को लागि अनलाइन साथी एप संचालन</h4>
                                        <p>काठमाडौँ । भारतको गुजरातको गुजरात मा हेड ऑफिस भएको कंपनी शुभलक्ष्मी मल्टी सर्विसिस इंडिया प्राइवेट लिमिटेडद्धारा अनलाइन साथि एप संचालनमा ल्याइएको छ ।
                                        गुजरातस्थित शुभलक्ष्मी मल्टी सर्विसिस इंडिया प्राइवेट लिमिटेड भारतमा बस्दै आएका नरेश सिजापतिको टीम द्वारा नेपाली प्रवासी लाइ सहज रुपमा नेपालमा पैसा पठाउन उक्त एप संचालनमा ल्याएको बताइएको छ ।
                                        </p>
                                        <a href="https://navadristi.com/archives/45307">Read More</a> </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="news">
                                    <div className="news_img"> <img src={require("assets/images/new/online-sath-logo.png")} alt="" /> </div>
                                    <div className="ser_cnt">
                                        <h4>नेपाली प्रवासीहरुको सहूलियतको लागि भारतमा अनलाइन साथी एप सार्वजनिक</h4>
                                        <p>टेकराज खत्री भारत:भारतको गुजरातमा हेड अफिस रहेको कंपनी शुभलक्मी मल्टी सर्विसिस इंडिया प्राइभेट लिमिटेडको पहलमा नरेश सिजापति र उनको टीमद्वारा नेपाली प्रवासीलाइ नेपाल पैसा पठाउन बैंक धाउन पर्ने बैंकमा समय लागने र नेपाल रिचार्ज गर्न प्राय जसो धेरै पैसा खर्च गरी रिचार्ज गर्नु पर्ने समस्यालाइ मध्य नजर गरी कंपनीले अनलाइन साथी नामक एप्प मार्केटमा लिएर आएको छ।</p>
                                        <a href="https://visionnewsnepal.com/7645/">Read More</a> </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>;
    }
}

export default Home;