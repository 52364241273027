import React from "react";
import {
    Row,
    Col,
    FormGroup,
    Input,
    Button,
} from "reactstrap";
import Axios from "axios";
import env from "variables/constants";
var apiUrl = env.url;
var headers = null;
class Prabhucsp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            call_districts: [],
            all_states: [],
            all_districts: [],
            all_entity_types: [
                {
                    "Value": "Individual",
                    "Label": "Individual"
                },
                {
                    "Value": "Partnership Firm",
                    "Label": "Partnership Firm"
                },
                {
                    "Value": "Sole Proprietor",
                    "Label": "Sole Proprietor"
                }
            ],
            all_off_days: [
                {
                    "Value": "None",
                    "Label": "None"
                },
                {
                    "Value": "Sunday",
                    "Label": "Sunday"
                },
                {
                    "Value": "Monday",
                    "Label": "Monday"
                },
                {
                    "Value": "Tuesday",
                    "Label": "Tuesday"
                },
                {
                    "Value": "Wednesday",
                    "Label": "Wednesday"
                },
                {
                    "Value": "Thursday",
                    "Label": "Thursday"
                },
                {
                    "Value": "Friday",
                    "Label": "Friday"
                },
                {
                    "Value": "Saturday",
                    "Label": "Saturday"
                }
            ],
            all_occupations: [
                {
                    "Value": "None",
                    "Label": "None"
                },
                {
                    "Value": "Government",
                    "Label": "Government"
                },
                {
                    "Value": "Public Sector",
                    "Label": "Public Sector"
                },
                {
                    "Value": "Self Employed",
                    "Label": "Self Employed"
                },
                {
                    "Value": "Private",
                    "Label": "Private"
                },
                {
                    "Value": "Other",
                    "Label": "Other"
                }
            ],
            all_qualifications: [
                {
                    "Value": "Under 10th",
                    "Label": "Under 10th"
                },
                {
                    "Value": "10th",
                    "Label": "10th"
                },
                {
                    "Value": "12th",
                    "Label": "12th"
                },
                {
                    "Value": "Graduate",
                    "Label": "Graduate"
                },
                {
                    "Value": "Post Graduate",
                    "Label": "Post Graduate"
                },
                {
                    "Value": "Doctorate",
                    "Label": "Doctorate"
                },
                {
                    "Value": "Other",
                    "Label": "Other"
                }
            ],
            all_id_types: [
                {
                    "Value": "Aadhaar Card",
                    "Label": "Aadhaar Card"
                },
                {
                    "Value": "Indian Driving License",
                    "Label": "Indian Driving License"
                },
                {
                    "Value": "Nepalese Citizenship",
                    "Label": "Nepalese Citizenship"
                },
                {
                    "Value": "Nepalese Passport",
                    "Label": "Nepalese Passport"
                },
                {
                    "Value": "Pan Card",
                    "Label": "Pan Card"
                }
            ],
            entity_type: "",
            name: "",
            phone: "",
            email: "",
            state: "",
            district: "",
            city: "",
            address: "",
            pin_code: "",
            pan_number: "",
            gstin: "",
            device: "",
            connectivity: "",
            start_time: "",
            end_time: "",
            off_day: "",
            bank_name: "",
            account_type: "",
            account_number: "",
            ifsc: "",
            contact_person: "",
            cgender: "",
            cfather_name: "",
            cdob: "",
            centity_type: "",
            cstate: "",
            cdistrict: "",
            ccity: "",
            caddress: "",
            cemail: "",
            cmobile: "",
            coccupation: "",
            cqualification: "",
            cid_type: "",
            cid_number: "",
        }
    }
    componentDidMount = () => {
        headers = {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access"),
                Accept: 'appliaction/json'
            }
        };
        this.getStates();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    getStates = () => {
        Axios.post(apiUrl + "/remittance/prabhu/get/states", { country: 'India' }, headers).then(res => {
            this.setState({
                all_states: res.data.data
            })
        })
    }
    stateChange = (e) => {
        var state = e.target.value;
        this.setState({
            state: state
        })
        if (state !== "") {
            Axios.post(apiUrl + "/remittance/prabhu/get/districts", { state: state }, headers).then(res => {
                this.setState({
                    all_districts: res.data.data,
                })
            })
        }
    }
    cstateChange = (e) => {
        var state = e.target.value;
        this.setState({
            cstate: state
        })
        if (state !== "") {
            Axios.post(apiUrl + "/remittance/prabhu/get/districts", { state: state }, headers).then(res => {
                this.setState({
                    call_districts: res.data.data,
                })
            })
        }
    }

    saveCsp = e => {
        e.preventDefault();
        this.props.loadingState(true);
        var data = {
            upgrade_type: this.props.upgrade_type,
            user_id: this.props.user_id,
            entity_type: this.state.entity_type,
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            state: this.state.state,
            district: this.state.district,
            city: this.state.city,
            address: this.state.address,
            pin_code: this.state.pin_code,
            pan_number: this.state.pan_number,
            gstin: this.state.gstin,
            device: this.state.device,
            connectivity: this.state.connectivity,
            start_time: this.state.start_time,
            end_time: this.state.end_time,
            off_day: this.state.off_day,
            bank_name: this.state.bank_name,
            account_type: this.state.account_type,
            account_number: this.state.account_number,
            ifsc: this.state.ifsc,
            contact_person: this.state.contact_person,
            cgender: this.state.cgender,
            cfather_name: this.state.cfather_name,
            cdob: this.state.cdob,
            centity_type: this.state.centity_type,
            cstate: this.state.cstate,
            cdistrict: this.state.cdistrict,
            ccity: this.state.ccity,
            caddress: this.state.caddress,
            cemail: this.state.cemail,
            cmobile: this.state.cmobile,
            coccupation: this.state.coccupation,
            cqualification: this.state.cqualification,
            cid_type: this.state.cid_type,
            cid_number: this.state.cid_number,
        };
        Axios.post(apiUrl + "/remittance/prabhu/create/csp/code", data, headers).then(res => {
            console.log(res)
            this.props.loadingState(false);
            if (res.data.success) {
                this.clearForm();
            }
            this.props.submitAction(res)
        })

    }
    clearForm = () => {
        this.setState({
            entity_type: "",
            name: "",
            phone: "",
            email: "",
            state: "",
            district: "",
            city: "",
            address: "",
            pin_code: "",
            pan_number: "",
            gstin: "",
            device: "",
            connectivity: "",
            start_time: "",
            end_time: "",
            off_day: "",
            bank_name: "",
            account_type: "",
            account_number: "",
            ifsc: "",
            contact_person: "",
            cgender: "",
            cfather_name: "",
            cdob: "",
            centity_type: "",
            cstate: "",
            cdistrict: "",
            ccity: "",
            caddress: "",
            cemail: "",
            cmobile: "",
            coccupation: "",
            cqualification: "",
            cid_type: "",
            cid_number: "",
        })
    }
    render() {
        return <>

                <Row>
                    <Col md='12'>
                        <h4 style={{ backgroundColor: "#ccc", padding: "15px" }}>User Details</h4>
                    </Col>
                </Row>
                <FormGroup>
                    <label>Entity Type</label>
                    <select className="form-control" name="entity_type" value={this.state.entity_type} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        {this.state.all_entity_types.map((det, k) => {
                            return <option value={det.Value} key={k}>{det.Label}</option>
                        })}
                    </select>
                </FormGroup>


                <FormGroup>
                    <label>Name</label>
                    <Input value={this.state.name} name="name" placeholder="Name" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>



                <FormGroup>
                    <label>Phone</label>
                    <Input value={this.state.phone} name="phone" placeholder="Phone" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>Email</label>
                    <Input value={this.state.email} name="email" placeholder="Email" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>



                <FormGroup>
                    <label>State</label>
                    <select className="form-control" name="state" value={this.state.state} onChange={(e) => { this.stateChange(e) }}>
                        <option value="">Select</option>
                        {this.state.all_states.map((det, k) => {
                            return <option value={det} key={k}>{det}</option>
                        })}
                    </select>
                </FormGroup>



                <FormGroup>
                    <label>District</label>
                    <select className="form-control" name="district" value={this.state.district} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        {this.state.all_districts.map((det, k) => {
                            return <option value={det} key={k}>{det}</option>
                        })}
                    </select>
                </FormGroup>


                <FormGroup>
                    <label>City</label>
                    <Input value={this.state.city} name="city" placeholder="City" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>Address</label>
                    <Input value={this.state.address} name="address" placeholder="Address" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>Pin Code</label>
                    <Input value={this.state.pin_code} name="pin_code" placeholder="Pin Code" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>



                <FormGroup>
                    <label>PAN Number</label>
                    <Input value={this.state.pan_number} name="pan_number" placeholder="PAN Number" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>GSTIN</label>
                    <Input value={this.state.gstin} name="gstin" placeholder="GSTIN" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>



                <FormGroup>
                    <label>Device</label>
                    <select className="form-control" name="device" value={this.state.device} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        <option value="Laptop">Laptop</option>
                        <option value="Hand Held">Hand Held</option>
                    </select>
                </FormGroup>


                <FormGroup>
                    <label>Connectivity</label>
                    <select className="form-control" name="connectivity" value={this.state.connectivity} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        <option value="LandLine">LandLine</option>
                        <option value="Mobile">Mobile</option>
                        <option value="VSAT">VSAT</option>
                    </select>
                </FormGroup>


                <FormGroup>
                    <label>Business Start Time</label>
                    <Input value={this.state.start_time} name="start_time" placeholder="Start Time" type="time" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>Business End Time</label>
                    <Input value={this.state.end_time} name="end_time" placeholder="End Time" type="time" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>



                <FormGroup>
                    <label>Off Day</label>
                    <select className="form-control" name="off_day" value={this.state.off_day} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        {this.state.all_off_days.map((det, k) => {
                            return <option value={det.Value} key={k}>{det.Label}</option>
                        })}
                    </select>
                </FormGroup>



                <FormGroup>
                    <label>Bank Name</label>
                    <Input value={this.state.bank_name} name="bank_name" placeholder="Bank Name" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>Account Type</label>
                    <select className="form-control" name="account_type" value={this.state.account_type} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        <option value="Saving">Saving</option>
                        <option value="Current">Current</option>
                    </select>
                </FormGroup>


                <FormGroup>
                    <label>Account Number</label>
                    <Input value={this.state.account_number} name="account_number" placeholder="Account Number" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>IFSC</label>
                    <Input value={this.state.ifsc} name="ifsc" placeholder="IFSC" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>
                <Row>
                    <Col md='12'>
                        <h4 style={{ backgroundColor: "#ccc", padding: "15px" }}>Contact Person Details</h4>
                    </Col>
                </Row>
                <FormGroup>
                    <label>Contact Person</label>
                    <Input value={this.state.contact_person} name="contact_person" placeholder="Contact Person" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>



                <FormGroup>
                    <label>Gender</label>
                    <select className="form-control" name="cgender" value={this.state.cgender} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Other">Other</option>
                    </select>
                </FormGroup>


                <FormGroup>
                    <label>Father's Name</label>
                    <Input value={this.state.cfather_name} name="cfather_name" placeholder="Father's Name" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>Date of birth</label>
                    <Input value={this.state.cdob} name="cdob" placeholder="Date of birth" type="date" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>Entity Type</label>
                    <select className="form-control" name="centity_type" value={this.state.centity_type} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        {this.state.all_entity_types.map((det, k) => {
                            return <option value={det.Value} key={k}>{det.Label}</option>
                        })}
                    </select>
                </FormGroup>



                <FormGroup>
                    <label>State</label>
                    <select className="form-control" name="cstate" value={this.state.cstate} onChange={(e) => { this.cstateChange(e) }}>
                        <option value="">Select</option>
                        {this.state.all_states.map((det, k) => {
                            return <option value={det} key={k}>{det}</option>
                        })}
                    </select>
                </FormGroup>



                <FormGroup>
                    <label>District</label>
                    <select className="form-control" name="cdistrict" value={this.state.cdistrict} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        {this.state.call_districts.map((det, k) => {
                            return <option value={det} key={k}>{det}</option>
                        })}
                    </select>
                </FormGroup>


                <FormGroup>
                    <label>City</label>
                    <Input value={this.state.ccity} name="ccity" placeholder="City" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>Address</label>
                    <Input value={this.state.caddress} name="caddress" placeholder="Address" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>



                <FormGroup>
                    <label>Email</label>
                    <Input value={this.state.cemail} name="cemail" placeholder="Email" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>


                <FormGroup>
                    <label>Mobile</label>
                    <Input value={this.state.cmobile} name="cmobile" placeholder="Mobile" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>



                <FormGroup>
                    <label>Occupation</label>
                    <select className="form-control" name="coccupation" value={this.state.coccupation} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        {this.state.all_occupations.map((det, k) => {
                            return <option value={det.Value} key={k}>{det.Label}</option>
                        })}
                    </select>
                </FormGroup>



                <FormGroup>
                    <label>Quaification</label>
                    <select className="form-control" name="cqualification" value={this.state.cqualification} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        {this.state.all_qualifications.map((det, k) => {
                            return <option value={det.Value} key={k}>{det.Label}</option>
                        })}
                    </select>
                </FormGroup>



                <FormGroup>
                    <label>Id Type</label>
                    <select className="form-control" name="cid_type" value={this.state.cid_type} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        {this.state.all_id_types.map((det, k) => {
                            return <option value={det.Value} key={k}>{det.Label}</option>
                        })}
                    </select>
                </FormGroup>


                <FormGroup>
                    <label>Id Number</label>
                    <Input value={this.state.cid_number} name="cid_number" placeholder="Id Number" type="text" onChange={(e) => { this.inputChange(e) }} />
                </FormGroup>



                <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                    Cancel
                </Button>
                <Button className="btn-fill" color="primary" type="button" onClick={this.saveCsp}>
                    Save
                </Button>
        </>;
    }
}

export default Prabhucsp;