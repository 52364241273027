import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import { Pagination } from 'react-laravel-paginex'
import { CSVLink } from "react-csv";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Agentcommissionreports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: [],
            options: [],
            exceldata: [],
            selected_user_id: null,
            start_date: "",
            end_date: "",
            type: "user",
            checktype: "",
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }
    componentDidMount = () => {
        this.getRecords(1);
    }
    getRecords = (page = 1) => {
        var data = {};
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }

        Axios.post(`${apiUrl}/admin/agentcommissionreports?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response", res.data.data.details.data)
            if (res.data.success) {
                console.log(res.data.data.details.data);
                this.setState({
                    paginatedata: res.data.data.details,
                    details: res.data.data.details.data,
                })
                if (page === 1) {
                    this.setState({
                        exceldata: []
                    })
                    this.getRecordsExcel();
                }
            }
            else {
                this.setState({
                    details: [],
                    exceldata: []
                })
            }
        })
    }
    getRecordsExcel = (page = 1) => {
        var data = { forexcel: "yes" };
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        Axios.post(`${apiUrl}/admin/agentcommissionreports`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response excel", res)
            if (res.data.success) {
                var exceldata = [];
                res.data.data.details.forEach((val, key) => {
                    var dat = {
                        "Csp code": val.csp_code,
                        "Agent Name": val.name,
                        "Agent Mobile": val.mobile,
                        "No of Remit": val.total_txn,
                        "Remittance send amount": val.total,
                        "Recharge Amount": val.total_recharge,
                        "Commission On Remittance": val.commission,
                        "GST": val.gst,
                        "TDS": val.tds
                    }
                    exceldata.push(dat);
                });
                this.setState({
                    exceldata: exceldata
                })
            } else {
                this.setState({
                    details: []
                })
            }
        })
    }
    changePage = data => {
        console.log(data)
        this.getRecords(data.page);
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">
                                        Agent Commission  Reports
                                        <small className="float-right">
                                            {this.state.exceldata.length > 0 && <CSVLink data={this.state.exceldata} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"Agent-commission-reports-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}
                                            {this.state.exceldata.length === 0 && <a href="" onClick={(e) => { e.preventDefault() }} style={{ color: "#d4d6d5", background: "none", padding: "10px 8px", borderRadius: "0px", pointerEvents: "none" }} title="Export to CSV"><i className="fa fa-spinner fa-spin"></i>Excel</a>}
                                        </small>
                                    </h3>
                                    <div className="row userhistoryrem">



                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-2">
                                            <label>{" "}</label>
                                            <input type="button" name="submit" className="btn btn-primary searchhistory" onClick={(e) => { this.getRecords(1) }} value="Search" />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive style={{ fontFamily: "sans-serif" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>S.No</th>
                                                <th>Csp code</th>
                                                <th>Agent Name</th>
                                                <th>Agent Mobile</th>
                                                <th>No of Remit</th>
                                                <th>Remittance send amount</th>
                                                <th>Recharge Amount</th>
                                                <th>Commission On Remittance</th>
                                                <th>GST</th>
                                                <th>TDS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.details.map((val, key) => {
                                                return <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{val.csp_code}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.mobile}</td>
                                                    <td>&#8377;{val.total_txn.toFixed(2)}</td>
                                                    <td>&#8377;{val.total.toFixed(2)}</td>
                                                    <td>&#8377;{val.total_recharge.toFixed(2)}</td>
                                                    <td>&#8377;{val.commission.toFixed(2)}</td>
                                                    <td>&#8377;{val.tds.toFixed(2)}</td>
                                                    <td>&#8377;{val.gst.toFixed(2)}</td>

                                                </tr>
                                            })}


                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Agentcommissionreports;
