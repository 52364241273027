import React from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";
import axios from "axios";
import NotificationAlert from "react-notification-alert";

import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    // console.log(url)
    apiUrl = process.env.REACT_APP_API_URL;
}
class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: apiUrl,
            password: "",
            mobile:"",
            email: "",
            otp:"",
            name:"",
            gender:"",
            country:"",
            state:"",
            state_id:"",
            city:"",
            district:"",
            address:"",
            work_area:"",
            referer:"",
            id_type:"",
            pincode:"",
            states:[],
            districts:[],
            profile_pic:"",
            license_front:"",
            license_back:"",
            profile_picS:"",
            license_frontS:"",
            license_backS:"",
            token:"",
            checkmobile:true,
            registerform:false,
            otpverify:false,
            thanks:false,
            loading:false
        };
    }
    componentDidMount = () => {
        // console.log(this.state)
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleChangecountry = (e) => {
        this.setState({ loading: true }) 
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.country !== "") {
                this.getstate();
            }
        });
        
    }
    handleChangestate = (e) => {
        
        this.setState({ loading: true })
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.state_id !== "") {
                this.state.states.map((pro) => {
                    if (pro.state_id === this.state.state_id) {
                        this.setState({
                            state: pro.state
                        });
                        
                    }
                })
                this.getdistrict();
            }
        });
        
    }
    
    getdistrict = () => {
           
        if (this.state.state_id === "") {
            this.notify("error", "Please select state.");
            return false;
        }
     
            var data = {
                state_id:this.state.state_id,  
                
               }
         
        axios.post(`${this.state.url}/get/district`, data)
            .then(d => {
                this.setState({ loading: false })
                if (d.data.success) {
                   
                         this.setState({
                            districts :d.data.data,
                         
                         })
                } else {
                   
                    this.setState({
                        districts:[]
                    })
                }
            }, err => {
                this.setState({
                    districts:[]
                })
                
            })
    } 
    getstate = () => {
           
        if (this.state.country === "") {
            this.notify("error", "Please select country.");
            return false;
        }
          if(this.state.country === 'India'){
            var data = {
                country_id:'IND',  
                
               }
          }else{
            var data = {
                country_id:'NPL',  
                
               }
          }
        axios.post(`${this.state.url}/get/state`, data)
            .then(d => {
                this.setState({ loading: false })
                if (d.data.success) {
                   
                         this.setState({
                            states :d.data.data,
                         
                         })
                } else {
                   
                    this.setState({
                        states:[]
                    })
                }
            }, err => {
                this.setState({
                    states:[]
                })
                
            })
    }
    handleSubmit = () => {
        
        if (this.state.mobile === "") {
            this.notify("error", "Please enter mobile number.");
            return false;
        }
        this.setState({ loading: true })
        axios.post(`${this.state.url}/checkmobile`, { mobile: this.state.mobile})
            .then(d => {
                this.setState({ loading: false })
                if (d.data.success) {
                   
                         this.setState({
                            checkmobile :false,
                            registerform:true,
                         })
                } else {
                   
                    this.notify("error", d.data.message)
                }
            }, err => {
                this.setState({
                    sendotp :true,
                    registerform:false,
                })
                
            })
    }
    onFileChange = (e) => {
        this.setState({ [e.target.name]: e.target.files[0] })
    }
    handleImage = (e, name) => {
        this.setState({
            [name]: e.target.files[0]
        })
    }
    submitpassword = () => {

        if (this.state.name === "") {
            this.notify("error", "Please enter name.");
            return false;
        }
        if (this.state.email === "") {
            this.notify("error", "Please enter email.");
            return false;
        }
        if (this.state.mobile === "") {
            this.notify("error", "Please enter mobile.");
            return false;
        }
        if (this.state.gender === "") {
            this.notify("error", "Please select gender.");
            return false;
        }
        if (this.state.country === "") {
            this.notify("error", "Please select country.");
            return false;
        }
        if (this.state.password === "") {
            this.notify("error", "Please enter password.");
            return false;
        }
        if (this.state.address === "") {
            this.notify("error", "Please enter address.");
            return false;
        }
        if (this.state.city === "") {
            this.notify("error", "Please enter city.");
            return false;
        }
        if (this.state.state === "") {
            this.notify("error", "Please select state.");
            return false;
        }
        if (this.state.district === "") {
            this.notify("error", "Please select district.");
            return false;
        }
        if (this.state.id_type === "") {
            this.notify("error", "Please select address proof type.");
            return false;
        }
        if (this.state.profile_pic === "") {
            this.notify("error", "Please select profile pic.");
            return false;
        }
        if (this.state.license_front === "") {
            this.notify("error", "Please Select Front ID.");
            return false;
        }
        this.setState({ loading: true })
        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('email', this.state.email);
        formData.append('mobile', this.state.mobile);
        formData.append('gender', this.state.gender);
        formData.append('mobile', this.state.password);
        formData.append('address', this.state.address);
        formData.append('country', this.state.country);
        formData.append('password', this.state.password);
        formData.append('city', this.state.city);
        formData.append('district', this.state.district);
        formData.append('state', this.state.state);
        formData.append('id_type', this.state.id_type);
        formData.append('profile_pic', this.state.profile_pic);
        formData.append('license_front', this.state.license_front);
        formData.append('license_back', this.state.license_back);
        formData.append('referer', this.state.referer);
        formData.append('pincode', this.state.pincode);
        formData.append('work_area', this.state.work_area);
    

         
        axios.post(`${this.state.url}/register2`, formData,)
            .then(d => {
                this.setState({ loading: false })
                if (d.data.success) {
                   // this.notify("success", d.data.message)
                         this.setState({
                              otpverify :true,
                              registerform:false,
                              token:d.data.data.token
                         })
                } else {
                   
                    this.notify("error", d.data.message)
                }
            }, err => {
             
                this.notify("error", "Wrong credentials.")
            })
    }
    Verify_otp = () => {
        if (this.state.otp === "") {
            this.notify("error", "Please enter otp.");
            return false;
        }
        this.setState({ loading: true })
        axios.post(`${apiUrl}/user/verify/otp`, {otp:this.state.otp}, {
            headers: {
                Authorization: "Bearer " + this.state.token
            }
        }).then(res => {
            this.setState({ loading: false })
            // console.log("send transaction response", res)
            if (res.data.success) {
               // this.notify("success", "Please enter otp.");
              
                this.setState({
                    name:"",  
                    email:"", 
                    mobile:"", 
                    gender:"", 
                    password:"",  
                    address:"", 
                    district:"", 
                    state:"", 
                    id_type:"", 
                    profile_pic:"", 
                    license_front:null, 
                    license_back:null, 
                    referer:"",
                    pincode:"",
                    work_area:"",
                    thanks:true,
                    otpverify:false,
                    otp:""
                  
                })
               
            }
            else {
                this.notify("error", res.data.message);
             
            }
        }, err => {
            this.notify("error", err.message);
        
            
        })
        
    }
    

    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
                    <Row className="login-page">
                        <Col md={{ size: 4, offset: 4 }} className="mt-5">
                            <Card className="card-user">
                                <CardHeader className="login-section">
                                    <div className="row text-center">
                                        <Col md="12">
                                            <div className="login-logo">
                                                <img src={require("assets/images/new/online-sath-logo.png")} alt="" style={{ width: "50%", padding: "15px" }} />
                                            </div>
                                            <hr /><br />
                                            {this.state.checkmobile && 
                                            <h4>Check Mobile</h4>
                                           }
                                             {this.state.registerform && 
                                            <h4>Enter Registration Details</h4>
                                           }
                                             {this.state.otpverify && <>
                                            <h4>Verify OTP</h4>
                                            <p>We sent a code to {this.state.mobile} Please enter</p>
                                         </>  }
                                        </Col>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                     {this.state.checkmobile && <> 
                                    <Form>
                                        <Row>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Mobile</label>
                                                    <Input
                                                        placeholder="Mobile Number"
                                                        type="text"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                         
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <Button type="button" onClick={() => this.handleSubmit()} className="btn btn-round btn-block loginbtn" color="success">
                                                        Next
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <a href="/login"  className="btn btn-round btn-block loginbtn" color="success">
                                                        Login
                                                    </a>
                                                </FormGroup>
                                            </Col>
                                            {/* <Col className="pl-md-1" md={{ size: 8, offset: 2 }}>
                                                <h5>Don't have an account? <Link to="/register">Register</Link> here.</h5>
                                            </Col> */}
                                        </Row>
                                    </Form>
                                    </>}
                                    {this.state.registerform && <> 
                                    <Form>
                                        <Row>
                                        <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Name</label>
                                                    <Input
                                                        placeholder="Enter Name"
                                                        type="text"
                                                        name="name"
                                                        value={this.state.name}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>E-mail</label>
                                                    <Input
                                                        placeholder="Enter E-mail"
                                                        type="text"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Mobile</label>
                                                    <input
                                                    className="form-control"
                                                        placeholder="Mobile Number"
                                                        type="text"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        readonly
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Gender</label>
                                                    <select
                                                      className="form-control"
                                                        name="gender"
                                                        value={this.state.gender}
                                                        onChange={(e) => this.handleChange(e)}
                                                    >
                                                    <option value="">Select Gender</option>   
                                                    <option value="male">Male</option> 
                                                    <option value="female">Female</option>    
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Residential Country</label>
                                                    <select
                                                      className="form-control"
                                                        name="country"
                                                        value={this.state.country}
                                                        onChange={(e) => this.handleChangecountry(e)}
                                                    >
                                                    <option value="">Select Country</option>   
                                                    <option value="India">India</option> 
                                                    <option value="Nepal">Nepal</option>    
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Area Of work</label>
                                                    <select
                                                      className="form-control"
                                                        name="work_area"
                                                        value={this.state.work_area}
                                                        onChange={(e) => this.handleChange(e)}
                                                    >
                                                    <option value="">Select</option>   
                                                    <option value="Self Employed">Self Employed</option> 
                                                    <option value="Domestic Worker">Domestic Worker</option>  
                                                    <option value="Security">Security</option>    
                                                    <option value="Hotel/Restaurant">Hotel/Restaurant</option> 
                                                    <option value="Company/Factory">Company/Factory</option>   
                                                    <option value="Professional workers">Professional workers</option>   
                                                    <option value="Government Employee">Government Employee</option>  
                                                    <option value="Others">Others</option>       
                                                    </select>
                                                </FormGroup>
                                            </Col>

                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Refer Code</label>
                                                    <Input
                                                        placeholder="Refer Code"
                                                        type="text"
                                                        name="referer"
                                                        value={this.state.referer}
                                                        
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Password</label>
                                                    <Input
                                                        placeholder="Password"
                                                        type="password"
                                                        name="password"
                                                        value={this.state.password}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                 
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Address</label>
                                                    <Input
                                                        placeholder="Address"
                                                        type="text"
                                                        name="address"
                                                        value={this.state.address}
                                                        
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Pincode</label>
                                                    <Input
                                                        placeholder="Pincode"
                                                        type="text"
                                                        name="pincode"
                                                        value={this.state.pincode}
                                                        
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>City</label>
                                                    <Input
                                                        placeholder="City"
                                                        type="text"
                                                        name="city"
                                                        value={this.state.city}
                                                        
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>State</label>
                                                    <select
                                                        className="form-control"
                                                        name="state_id"
                                                        value={this.state.state_id}
                                                        onChange={(e) => this.handleChangestate(e)}
                                                    >
                                                    <option value="">Select</option>   
                                                    {this.state.states.map((hs, key) => {
                                                    
                                                     return <option value={hs.state_id} >{hs.state}</option> 
                                                  
                                                    })}  
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>District</label>
                                                    <select
                                                       className="form-control"
                                                        name="district"
                                                        value={this.state.district}
                                                        onChange={(e) => this.handleChange(e)}
                                                    >
                                                    <option value="">Select</option>   
                                                    {this.state.districts.map((hs, key) => {
                                                    
                                                    return <option value={hs.district} >{hs.district}</option> 
                                                 
                                                   })}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Address Proof Type</label>
                                                    <select
                                                       className="form-control"
                                                        name="id_type"
                                                        value={this.state.id_type}
                                                        onChange={(e) => this.handleChange(e)}
                                                    >
                                                    <option value="">Select</option>   
                                                      {this.state.country === 'India' &&  <>
                                                    <option value="aadhar_card">Aadhar Card</option> 
                                                    <option value="driving_licence">Driving Licence</option>  
                                                    </> }
                                                    {this.state.country === 'Nepal' &&  <>
                                                    <option value="passport">Passport</option>  
                                                     </>}    
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                
                                                    <label>Profile Image</label>
                                                    <input
                                                       className="form-control"
                                                       type="file"
                                                        name="profile_pic"
                                                   
                                                    
                                                        onChange={e => { this.onFileChange(e ,'profile_pic') }}
                                                    />
                                                 
                                           
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                          
                                                    <label>Front</label>
                                                    <input
                                                     className="form-control"
                                                       type="file"
                                                        name="license_front"
                                                     
                                                        onChange={e => { this.onFileChange(e ,'license_front') }}
                                                    />
                                                 
                                        
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                         
                                                    <label>Back</label>
                                                    <input
                                                      className="form-control"
                                                         type="file"
                                                        name="license_back"
                                   
                                                        onChange={e => { this.onFileChange(e ,'license_back') }}
                                                    />
                                                 
                                   
                                            </Col>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <Button type="button" onClick={() => this.submitpassword()} className="btn btn-round btn-block loginbtn" color="success">
                                                       Process
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                          
                                        </Row>
                                    </Form>
                                    </>}

                                    {this.state.otpverify && <> 
                                    <Form>
                                        <Row>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <label>Enter Otp</label>
                                                    <Input
                                                        placeholder="Enter Otp"
                                                        type="text"
                                                        name="otp"
                                                        value={this.state.otp}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                    <Link onClick={() => this.submitpassword()} style={{
                                                        float: "right",
                                                        marginTop: "-6%",
                                                        marginBottom: "4%",
                                                        fontSize: "12px"
                                                    }}>Resend  Otp</Link> 
                                                </FormGroup>
                                            </Col>
                                         
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <Button type="button" onClick={() => this.Verify_otp()} className="btn btn-round btn-block loginbtn" color="success">
                                                    Verify Otp
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            {/* <Col className="pl-md-1" md={{ size: 8, offset: 2 }}>
                                                <h5>Don't have an account? <Link to="/register">Register</Link> here.</h5>
                                            </Col> */}
                                        </Row>
                                    </Form>
                                    </>}
                                    {this.state.thanks && <> 
                                    <Form>
                                        <Row>
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                   <h5>Registration successfully!</h5>
                                                </FormGroup>
                                            </Col>
                                         
                                            <Col className="pl-md-1" md={{ size: 10, offset: 1 }}>
                                                <FormGroup>
                                                    <a href="/login"  className="btn btn-round btn-block loginbtn" color="success">
                                                        Login
                                                    </a>
                                                </FormGroup>
                                            </Col>
                                           
                                        </Row>
                                    </Form>
                                    </>}
                                </CardBody>
                               
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Register;
