import React from "react";
import { Link } from "react-router-dom";
import { History } from 'history';

import Axios from "axios";
import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}
class Userheader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drop_dowan: false,


        }
    }
    componentDidMount = () => {

    }
    logout = e => {
        if (e) e.preventDefault();
        console.log("ok")
        Axios.get(`${apiUrl}/logout`, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
        }).then(res => {
            sessionStorage.clear();
            window.location.href = "/";
        }, err => {
            sessionStorage.clear();
            window.location.href = "/";
        })
    }
    agentpdf = e => {

     
        Axios.get(`${apiUrl}/user/agent/PDF`, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res.data.data)
           // window.location.href = "/";
        }, err => {
            console.log(err.data.data)
          //  window.location.href = "/";
        })
    }
    dropdowan = (e) => {

        this.setState({
            drop_dowan: !this.state.drop_dowan
        });
    }
    render() {
        return <> <div className="header_fixed"><section className="dashboard_new_sec">
            <div className="container-fluid">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6 col-6">
                            <div className="logo">
                                <img src={require("assets/images/new/online-sath-logo-new.png")} alt="" />
                            </div>
                        </div>
                        {this.props.user && <div className="col-md-6 col-6">
                            <div className="balance-area">

                                <div className="header-pro-thumb">
                                    <img className="rounded-circle" src={imageUrl + this.props.user.profile_pic} alt="" />
                                </div>
                                <div className="dropdown show user_drop">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="pl-4 pt-1" onClick={e => { this.dropdowan(e) }} ><b>{this.props.user.name}</b></span>
                                    </a>
                                    {this.state.drop_dowan && <>
                                        <div className="dropdown-menu hide drop_hide" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" href="edit-profile">Edit Profile</a>
                                            <a className="dropdown-item" href="change-password">Change Password</a>
                                            {this.props.user.issue_date && 
                                            <a className="dropdown-item" target="_blank" href={imageUrl + "/uploads/agent_pdf/"+this.props.user.id+".pdf"}>Certificate</a>
                                            }
                                            <a className="dropdown-item" href="#" onClick={e => { this.logout(e) }}>Logout</a>
                                            {/* <a className="dropdown-item" onClick={e => { this.logout(e) }} >Log out</a> */}
                                        </div>
                                    </>}
                                </div>

                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </section>
            <section className="wallet_balance_strip">
                <div className="container-fluid">
                    <div className="">
                        <div className="row">
                            <div className="col-md-6">
                                {/* <marquee scrollamount="3"><b>OnlineSaathi SERVICE INDIA PRIVATE LIMITED. Helpline Number: +91-8154818585</b></marquee> */}
                            </div>
                            <div className="col-md-6">
                                <div className="wallel_balance_sec">
                                    <span className="balance-text">
                                        <img src={require("assets/images/remittance_wallet_history.png")} alt="" />&nbsp;
                                    </span>
                                    {this.props.user && <span className="amount-text">Wallet Balance: &#8377; {this.props.user.remit_balance}</span>}
                                    &nbsp;&nbsp;<Link to="add-money" className="badge" style={{backgroundColor:"#ed1c24"}}>+Add Money</Link>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </>;
    }

}
export default Userheader;

