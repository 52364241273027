import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import "../../assets/css/style.css";
class Contact extends React.Component {


  render() {
    return <>
      <div className="site_home">
      <Header banner="contact-us" h1Text="Contact Us" bannerclassName="inner_head agenthead" />
        <section className="contact_us">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <div className="contact_add">
                  <h2>Contact Us</h2>
                  <ul className="contact_cnt">
                    <li><i className="fas fa-map-marker-alt"></i>
                      <p>D 109 Maruti Hight, Opp Samor Hight Naroda Muthiya, Ahmedabad, Gujarat, India, 382330</p>
                    </li>
                    <li><i className="fas fa-mobile-alt"></i><a href="tel:+919099005251">+91 9099005251</a></li>
                    <li><i className="far fa-envelope"></i><a href="#">support@onlinesaathi.com</a> </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12">
                <div className="contact_form">
                  <h2>Get In Touch</h2>
                  <form id="contact_form">
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Name*</label>
                        <input type="text" className="form-control" name="name" placeholder="Name" required="required" autocomplete="on" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Email*</label>
                        <input type="email" className="form-control" name="email" placeholder="Email" required="required" autocomplete="on" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Mobile*</label>
                        <input type="number" className="form-control" name="mobile" placeholder="Mobile" required="required" min="0" autocomplete="on" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Address*</label>
                        <input type="text" className="form-control" name="address" placeholder="Address" required="required" autocomplete="on" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group ">
                        {/* <grammarly-extension data-grammarly-shadow-root="true" style={position: "absolute", top: "0px", left: "0px", pointerEvents: "none"} className="cGcvT"></grammarly-extension>
                        <grammarly-extension data-grammarly-shadow-root="true" style="mix-blend-mode: darken; position: absolute; top: 0px; left: 0px; pointer-events: none;" className="cGcvT"></grammarly-extension> */}
                        <label>Message</label>
                        <textarea className="form-control rounded-0" id="message" name="message" rows="3" cols="120" placeholder="Message" spellcheck="false"></textarea>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group justify-content-center">
                        <input type="submit" className="btn_form" value="Send" id="send" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </div>
    </>;
  }
}

export default Contact;