import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    FormGroup,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Axios from "axios";
import { Pagination } from 'react-laravel-paginex';
import SimpleCrypto from "simple-crypto-js";
import Becomesaathilist from "./Becomesaathilist";
import Reportissue from "./Reportissue";
import env from "variables/constants";


const moment = require("moment-timezone");
moment.tz.setDefault("Asia/Kolkata");
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    // console.log(url)
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}
var cu = sessionStorage.getItem('cuser') ? Cryp.decrypt(sessionStorage.getItem('cuser')) : null;
const userids = [1];
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 5,
                prev_page_url: null,
                to: 0,
                total: 0
            },
            status: "",
            mobile: "",
            email: "",
            todaysregistration: 0,
            totalMember:0,
            totalSathi:0,
            totaldealers: 0,
            totalPartner:0,
            totalearningtodayRecharge: 0,
            totalRemittancethismonth: 0,
            totalRemittancethismonthPrabhu: 0,
            totalrechargesthismonth: 0,
            totalusers: 0,
            expense: 0,
            list: [],
            modal: false,
            selected_record: null,
            reason: "",
            selected_id: "",
            rejectModal: false,
            imageModal: false,
            total_recharge_bal: 0,
            total_remit_bal: 0,
            allpermission: [],
            cuser: null,
            show_dashboard: false,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            Reports: [],
            imeAgents: 0,
            prabhuAgents: 0,
            osAgents: 0,
        };
    }
    componentDidMount = () => {
        this.getAllUsers();
        this.dashboardData();
        this.getList();
        this.permissions();
    }
    permissions = () => {

        this.setState({
            allpermission: JSON.parse(sessionStorage.getItem("adminpermission")),
            cuser: Cryp.decrypt(sessionStorage.getItem("cuser")),

        })
        setTimeout(() => {

            this.state.allpermission.map((val, key) => {
                if (val.page_name == "/dashboard") {
                    this.setState({
                        show_dashboard: true
                    })
                }
            })
            // console.log("cuser", this.state.cuser);
            // console.log("allpermission", this.state.allpermission);

        }, 500);

    }
    dashboardData = () => {

        var data = { forexcel: "yes" };

        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }


        Axios.post(env.url + "/admin/get/dashboard/data", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            // console.log("dahboard data response", res)
            this.setState({
                totalMember:res.data.data.totalMember,
                totalSathi:res.data.data.totalSathi,
                todaysregistration: res.data.data.todaysregistration,
                totaldealers: res.data.data.totaldealers,
                totalPartner:res.data.data.totalPartner,
                totalearningtodayRecharge: res.data.data.totalearningtodayRecharge,
                totalusers: res.data.data.totalusers,
                totalRemittancethismonthPrabhu: res.data.data.totalRemittancethismonthPrabhu,
                totalRemittancethismonth: res.data.data.totalRemittancethismonth,
                totalrechargesthismonth: res.data.data.totalrechargesthismonth,
                total_remit_bal: res.data.data.remit_balance,
                total_recharge_bal: res.data.data.recharge_balance,
                expense: res.data.data.expense,
                imeAgents: res.data.data.imeAgents,
                prabhuAgents: res.data.data.prabhuAgents,
                osAgents: res.data.data.osAgents,

            })
        })
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    getAllUsers = (page = 1, keyword = null) => {
        var data = {
            keyword: ""
        }
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        Axios.post(`${env.url}/admin/new/users?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            // console.log("users response", res)
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.users,
                    users: res.data.users.data
                });
            }
            else {
                this.setState({
                    users: []
                })
            }
        })
    }
    viwDetails = (e, user) => {
        e.preventDefault();
        this.setState(user);
        this.toggleViewModal();
    }

    toggleViewModal = () => {
        this.setState({
            viewModal: !this.state.viewModal
        });
    }

    toggleTempLoginModal = () => {
        this.setState({
            tempLoginModal: !this.state.tempLoginModal
        })
    }

    approveUser = () => {
        if (this.state.input_notice === "") {
            this.notify("error", "Please add notice.");
            return false;
        }

        Axios.post(env.url + "/admin/approve/user", { id: this.state.id, status: "temp_login", notice: this.state.input_notice }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            this.getAllUsers(this.state.paginatedata.current_page);
            if (type === "success") {
                this.setState({
                    status: "temp_login",
                    notice: this.state.input_notice,
                    blockModal: false,
                    input_notice: "",
                    tempLoginModal: false
                })
            }
        })
    }
    activeUser = e => {
        e.preventDefault();
        Axios.post(env.url + "/admin/make/user/acive", { id: this.state.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.getAllUsers(this.state.paginatedata.current_page);
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            if (type === "success") {
                this.setState({
                    status: "active",
                    block_reason: "",
                    input_block_reason: ""
                })
            }
        })
    }

    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    viewImage = (e, image) => {
        e.preventDefault();
        this.setState({
            selected_image: imageUrl + image,
            imageModal: true
        })
    }
    toggleImageModal = () => {
        this.setState({
            imageModal: !this.state.imageModal
        })
    }

    toggleM = (e, val) => {
        e.preventDefault();
        this.setState({
            modal: true,
            selected_record: val
        })
    }

    toggle = (e, val) => {
        e.preventDefault();
        this.setState({
            modal: !this.state.modal,
            selected_id: val
        })
    }
    getList = () => {
        var data = {limit:5 };

        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        Axios.post(`${env.url}/admin/add/money/list`, data, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
        }).then(res => {
            if (res.data.success) {
                this.setState({
                    list: res.data.data.list
                })
            }
        }, err => {
        })
    }

    checkedRequest = (e, id) => {
        e.preventDefault();
        Axios.post(`${env.url}/admin/checked/money/request`, { req_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            if (res.data.success) {
                this.getList();
                this.notify("success", res.data.message);
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            // console.log("approve error", err)
        })
    }
    approveRequest = (e, id) => {
        e.preventDefault();
        Axios.post(`${env.url}/admin/approve/money/request`, { req_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            if (res.data.success) {
                this.getList();
                this.notify("success", res.data.message);
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            // console.log("approve error", err)
        })
    }
    rejectRequest = (e) => {
        if (this.state.reason === "") {
            this.notify("error", "Please enter reason.");
            return false;
        }
        Axios.post(env.url + "/admin/reject/add/money/request", {
            request_id: this.state.selected_id,
            reason: this.state.reason
        }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            this.getList();
            if (type === "success") {
                this.setState({
                    reason: "",
                    selected_id: "",
                    modal: false
                })
            }
            this.notify(type, res.data.message);
        })
    }

    toggleRejectModal = () => {
        this.setState({
            rejectModal: !this.state.rejectModal
        })
    }


    render() {
        return (
            <>
                {!this.state.show_dashboard &&

                    <div className="content">
                        <h1>Welcome {this.state.cuser ? this.state.cuser.name : "back"}</h1>
                    </div>
                }

                {this.state.show_dashboard &&
                    <div className="content">
                        <div className="react-notification-alert-container">
                            <NotificationAlert ref="notificationAlert" />
                        </div>


                       <div className="dashboard-container-from-date">
                            <Row>
                                <Col md="12">
                                    <Card>
                                        <CardHeader>
                                            <div className="row userhistoryrem">
                                                <div className="col-md-2">
                                                    <label>From Date</label>
                                                    <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                                </div>
                                                <div className="col-md-2">
                                                    <label>To Date</label>
                                                    <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                                </div>
                                                <div className="col-md-2">
                                                    <label>{" "}</label>
                                                    <input type="button" name="submit" className="btn btn-primary searchhistory" onClick={(e) => { this.dashboardData(); this.getAllUsers(); this.getList(); }} value="Search" />
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                        <Col lg="3" style={{ padding: '5px' }} >
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Total Users</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.totalusers}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001565.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-single-02 text-success" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>

                                        <h5 className="card-category">Registrations</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.todaysregistration}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001565.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-key-25 text-info" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>

                                        <h5 className="card-category">Total Member</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.totalMember}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001563.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-key-25 text-info" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>

                                        <h5 className="card-category">Total Sathi</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.totalSathi}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001564.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-key-25 text-info" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">State Partner</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.totaldealers}
                                            <a href="all-dealers" className="ml-2" title="View all agents"><i className="fa fa-info-circle text-danger" /></a>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001565.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-single-02 text-primary" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Total Partner</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.totalPartner}
                                            <a href="all-dealers" className="ml-2" title="View all agents"><i className="fa fa-info-circle text-danger" /></a>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001565.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-single-02 text-primary" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col lg="3">
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">IME Agents</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.imeAgents}
                                            <a href="all-dealers?type=IME" className="ml-2" title="View all IME agents"><i className="fa fa-info-circle text-danger" /></a>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001565.png')} alt="View Details" style={{width: "38px"}}  />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Prabhu Agents</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.prabhuAgents}
                                            <a href="all-dealers?type=Prabhu" className="ml-2" title="View all Prabhu agents"><i className="fa fa-info-circle text-danger" /></a>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001565.png')} alt="View Details" style={{width: "38px"}}  />
                                    </CardBody>
                                </Card>
                            </Col> */}
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Online Saathi Agents</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.osAgents}
                                            <a href="all-dealers?type=OS" className="ml-2"  title="View all Online Saathi agents"><i className="fa fa-info-circle text-danger" /></a>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001565.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-single-02 text-primary" /> */}
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Total Income</h5>
                                        <CardTitle tag="h3">
                                            {/* <i className="tim-icons icon-coins text-danger" />{" "} */}
                                            &#8377;{this.state.totalearningtodayRecharge.toFixed(2)}
                                            <a href="wallet-history?filter=income" className="ml-2"><i className="fa fa-info-circle text-danger" /></a>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001567.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Recharges</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;{this.state.totalrechargesthismonth.toFixed(2)}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001566.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-money-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Indo Nepal IME</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.totalRemittancethismonth.toFixed(0)}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001561.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-send text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Indo Nepal Prabhu</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            {this.state.totalRemittancethismonthPrabhu.toFixed(0)}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001561.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-send text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Total Expense</h5>
                                        <CardTitle tag="h3">
                                            &#8377;{this.state.expense.toFixed(2)}
                                            <a href="wallet-history?filter=expense" className="ml-2"><i className="fa fa-info-circle text-danger" /></a>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001568.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-send text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">User Wallet balance</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;{this.state.total_remit_bal.toFixed(2)}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001570.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">AEPS</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001571.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Recharge and Bill Payment</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001572.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Jobs Received</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001573.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Candidate Hired</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001574.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Government Scheme</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001575.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Citizen Screened</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001577.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}> 
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Saathi Booking</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001576.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Sucessfully Applied</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001578.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Delivered</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001578.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Available Service</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001580.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Advise Given</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001581.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Saathi Booked</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001582.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Application</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001583.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Services</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001583.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Total Bus Operator</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001584.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Total Bus/Route</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001585.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Total Booking</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001586.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Total Events</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001587.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Event Booking</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001588.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Bus Booking</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001589.png')} alt="View Details" style={{width: "38px"}}  />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Trian Booking</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001590.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Flight Booking</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001591.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Hotel Booking</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001592.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Reward Claim</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001593.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Commission Claim</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001594.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" style={{ padding: '5px' }}>
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Inquiry</h5>
                                        <CardTitle tag="h3">
                                            {" "}
                                            &#8377;0
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <img src={require('assets/images/Group 1000001595.png')} alt="View Details" style={{width: "38px"}} />
                                        {/* <i className="tim-icons icon-coins text-danger" /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="6" md="12">
                                <Card className="card-tasks">
                                    <CardHeader>
                                        <h6 className="title d-inline">New Users({this.state.users.length})</h6>
                                    </CardHeader>
                                    <CardBody>
                                        {this.state.users.length > 0 && <Table className="tablesorter" responsive>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Email</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.users.map((val, key) => {
                                                    var sr = (this.state.paginatedata.per_page * (this.state.paginatedata.current_page - 1)) + key + 1;
                                                    var bal = val.remit_balance && val.remit_balance > 0 ? val.remit_balance : val.wallet_balance;
                                                    return <tr key={key}>
                                                        <td>{sr}</td>
                                                        <td>{val.name}</td>
                                                        <td>{val.mobile}</td>
                                                        <td>{val.email}</td>
                                                        <td>{val.status.toUpperCase()}</td>
                                                        <td className="img-delar">
                                                            <a href="" title="View Details" onClick={(e) => { this.viwDetails(e, val) }}><img src={require('assets/images/other-icons/view.png')} alt="View Details" style={{ width: "25px" }} /></a>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>}
                                        {this.state.users.length > 0 && <Pagination changePage={this.changePage} data={this.state.paginatedata} />}
                                    </CardBody>
                                </Card>
                            </Col>
                            {this.state.list.length > 0 && <Col lg="6" md="12">
                                <Card className="card-tasks">
                                    <CardHeader>
                                        <CardTitle tag="h4">Add Money Requests ({this.state.list.length})</CardTitle>
                                    </CardHeader>
                                    <CardBody className="table-responsive">
                                        {this.state.list.length > 0 && <Table className="tablesorter" responsive>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Name</th>
                                                    <th>Mobile No.</th>
                                                    <th>Amount</th>
                                                    <th>Deposit Via</th>
                                                    <th>Image</th>
                                                    <th>Reciept No.</th>
                                                    <th>Bank Name</th>
                                                    <th>Description</th>
                                                    <th>Checked by</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.list.map((val, key) => {
                                                    return <tr>
                                                        <td>{moment(val.created_at).format('DD-MM-YYYY h:mm a')}</td>
                                                        <td>{val.user.name}</td>
                                                        <td>{val.user.mobile}</td>
                                                        <td>&#8377;{val.amount}</td>
                                                        <td>{val.deposit_via}</td>
                                                        <td>
                                                            {val.image && <a href="" onClick={(e) => { this.viewImage(e, val.image) }}><img src={imageUrl + val.image} alt=" " style={{ width: "50px" }} /></a>}
                                                        </td>
                                                        <td>{val.reciept_number}</td>
                                                        <td>{val.bank_name}</td>
                                                        <td>{val.description}</td>
                                                        <td>{val.checked ? val.checked.name : ""}</td>
                                                        <td>
                                                            {userids.includes(cu.id) &&
                                                                val.status === "checked" && <>
                                                                    <a href="" onClick={(e) => { this.approveRequest(e, val.id) }} > <i className="tim-icons icon-check-2"></i></a>&nbsp;
                                                                </>
                                                            }
                                                            {!userids.includes(cu.id) &&
                                                                <a href="" onClick={(e) => { this.checkedRequest(e, val.id) }} > <i className="tim-icons icon-check-2"></i></a>
                                                            }
                                                            &nbsp;
                                                            <a href="" onClick={(e) => { this.toggle(e, val.id) }} > <i className="tim-icons icon-simple-remove"></i></a>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>}
                                    </CardBody>
                                </Card>
                            </Col>}
                            <Col lg="6" md="12">
                                <Card className="card-tasks">
                                    <CardHeader>
                                        <CardTitle tag="h4">Become Saathi Requests</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Becomesaathilist embed={true} limit={5}/>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg="12" md="12">
                                <Card className="card-tasks">
                                    <CardHeader>
                                        <CardTitle tag="h4">Complaints</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Reportissue embed={true} status="pending" limit="5" />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }

                <Modal isOpen={this.state.tempLoginModal} toggle={this.toggleTempLoginModal}>
                    <ModalHeader toggle={this.toggleTempLoginModal}>Notice</ModalHeader>
                    <ModalBody>
                        <textarea name="input_notice" placeholder="Notice" onChange={(e) => { this.handleInput(e) }} value={this.state.input_notice} className="form-control"></textarea>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger" onClick={this.approveUser}>
                            Submit
                        </button>
                    </ModalFooter>
                </Modal>
                <Modal modalClassName="modal" isOpen={this.state.viewModal} toggle={this.toggleViewModal} size="lg" >
                    <div className="modal-header">
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleViewModal} style={{ zIndex: "999" }} >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="view-popup">
                            <h3>View User Detail</h3>
                            <div className="userddetails">
                                <Row>
                                    <Col md="7">
                                        <div className="userimg">
                                            <img alt="..." onError={this.handleError} src={imageUrl + this.state.profile_pic} />

                                        </div>
                                        <div className="user-details">
                                            <strong className="username">{this.state.name}</strong>
                                            <p>Referal Code: <span>{this.state.referal_code ? this.state.referal_code : "N/A"}</span></p>

                                        </div>
                                    </Col>
                                    <Col md="5">
                                        <div className="activestatus">
                                            <p><strong className="username">Status</strong> <span>{this.state.status.toUpperCase()}</span></p>
                                            <div className="btn-account">
                                                {this.state.status === "pending" && <button type="button" className="btn btn-danger" onClick={(e) => { this.toggleTempLoginModal(e) }}>Reject</button>}
                                                {this.state.status !== "active" && <button type="button" className="btn btn-danger" onClick={(e) => { this.activeUser(e) }}>{this.state.status === "pending" ? "Approve" : "Active"} User</button>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <Row className="startedformsec">
                                <Col md="4">
                                    <FormGroup>
                                        <label>Name</label>
                                        <h4>{this.state.name}</h4>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>Email</label>
                                        <h4>{this.state.email ? this.state.email : "N/A"}</h4>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>Phone Number</label>
                                        <h4>{this.state.mobile ? this.state.mobile : "N/A"}</h4>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label>Address</label>
                                        <h4>{this.state.address ? this.state.address : "N/A"}</h4>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>City</label>
                                        <h4>{this.state.city ? this.state.city.toUpperCase() : "N/A"}</h4>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>District</label>
                                        <h4>{this.state.district ? this.state.district.toUpperCase() : "N/A"}</h4>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label>State</label>
                                        <h4>{this.state.state ? this.state.state.toUpperCase() : "N/A"}</h4>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>Country</label>
                                        <h4>{this.state.country ? this.state.country.toUpperCase() : "N/A"}</h4>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>PIN Code</label>
                                        <h4>{this.state.pincode ? this.state.pincode : "N/A"}</h4>
                                    </FormGroup>
                                </Col>
                                {this.state.csp_code && <Col md="6">
                                    <FormGroup>
                                        <label>CSP COde</label>
                                        <h4>{this.state.csp_code}</h4>
                                    </FormGroup>
                                </Col>}
                            </Row>
                            <Row>
                                {this.state.status === "blocked" && <Col md="6">
                                    <FormGroup>
                                        <label>Block Reason</label>
                                        <h4>{this.state.block_reason ? this.state.block_reason : "N/A"}</h4>
                                    </FormGroup>
                                </Col>}
                                {this.state.status === "temp_login" && <Col md="6">
                                    <FormGroup>
                                        <label>Notice</label>
                                        <h4>{this.state.notice ? this.state.notice : "N/A"}</h4>
                                    </FormGroup>
                                </Col>}
                            </Row>
                            <Row>
                                {this.state.license_back && <Col md="6">
                                    <div className="imageidentity">
                                        <label style={{ textAlign: "center" }}>Identity Back ({this.state.proof_type.replace("_", " ")})</label>
                                        <img src={imageUrl + this.state.license_back} alt=" " />
                                    </div>
                                </Col>}
                                {this.state.license_front && <Col md="6">
                                    <div className="imageidentity">
                                        <label style={{ textAlign: "center" }}>Identity Front({this.state.proof_type.replace("_", " ")})</label>
                                        <img src={imageUrl + this.state.license_front} alt=" " />
                                    </div>
                                </Col>}

                            </Row>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader className="reject-popup" toggle={this.toggle}>Reject Reason</ModalHeader>
                    <ModalBody>
                        <textarea name="reason" onChange={(e) => { this.handleInput(e) }} value={this.state.reason} className="form-control"></textarea>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="primary" onClick={() => { this.rejectRequest() }}>Submit</Button>{' '}
                        {/* <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.imageModal} toggle={this.toggleImageModal} size="lg">
                    <ModalHeader toggle={this.toggleImageModal}>Receipt</ModalHeader>
                    <ModalBody>
                        <img src={this.state.selected_image} alt="" />
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default Dashboard;
