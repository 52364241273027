import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Pagination } from 'react-laravel-paginex'
import { CSVLink } from "react-csv";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Transactionreports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: [],
            options: [],
            exceldata: [],
            selected_user_id: null,
            start_date: "",
            end_date: "",
            type: "user",
            checktype: "",
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }
    componentDidMount = () => {
        this.getRecords(1);
    }
    getRecords = (page = 1) => {
        var data = {};

        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.type != "") {
            data.type = this.state.type;
            if(this.state.type === "single_user"){
                data.user_id = this.state.selected_user_id;
            }
        }

        Axios.post(`${apiUrl}/admin/transactionreports?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response", res.data.data.details.data)
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.data.details,
                    details: res.data.data.details.data,
                    checktype: this.state.type
                })
                if (page === 1) {
                    this.setState({
                        exceldata: []
                    })
                    this.getRecordsExcel();
                }
            }
            else {
                this.setState({
                    details: [],
                    exceldata: []
                })
            }
        })
    }
    getRecordsExcel = (page = 1) => {
        var data = { forexcel: "yes" };
        var checktypedata = this.state.checktype;
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.type != "") {
            data.type = this.state.type;
        }
        Axios.post(`${apiUrl}/admin/transactionreports`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response excel", res)
            if (res.data.success) {
                var exceldata = [];
                if (checktypedata === "state") {


                    res.data.data.details.forEach((val, key) => {


                        var dat = {
                            "State": val.state,
                            "No of Remit": val.total_txn,
                            "Remit Amount": val.total,
                            "Recharge Amount": val.total_recharge
                        }
                        exceldata.push(dat);
                    });
                }

                if (checktypedata === "district") {

                    res.data.data.details.forEach((val, key) => {


                        var dat = {

                            "District": val.district,
                            "No of Remit": val.total_txn,
                            "Remit Amount": val.total,
                            "Recharge Amount": val.total_recharge
                        }
                        exceldata.push(dat);
                    });
                }
                if (checktypedata === "user") {

                    res.data.data.details.forEach((val, key) => {


                        var dat = {

                            "Name": val.mobile,
                            "Mobile": val.name, "District": val.district,
                            "No of Remit": val.total_txn,
                            "Remit Amount": val.total,
                            "Recharge Amount": val.total_recharge
                        }
                        exceldata.push(dat);
                    });
                }

                this.setState({
                    exceldata: exceldata
                })
            }
            else {
                this.setState({
                    details: []
                })
            }
        })
    }
    changePage = data => {
        console.log(data)
        this.getRecords(data.page);
    }



    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }

    searchUser = e => {
        if (e.length > 2) {
            var data = { keyword: e };
            Axios.post(apiUrl + "/admin/users/list", data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                console.log(res)
                this.setState({ options: res.data.users, isLoading: false })
            })
        }

    }

    selectUser = selected => {
        console.log(selected)
        this.setState({ selected_user_id: selected.length > 0 ? selected[0].id : null });
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">
                                        Transaction Reports
                                        <small className="float-right">
                                            {/* {this.state.exceldata.length > 0 && <CSVLink data={this.state.exceldata} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={this.state.type + "-tansaction-reports-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}
                                            {this.state.exceldata.length === 0 && <a href="" onClick={(e) => { e.preventDefault() }} style={{ color: "#d4d6d5", background: "none", padding: "10px 8px", borderRadius: "0px", pointerEvents: "none" }} title="Export to CSV"><i className="fa fa-spinner fa-spin"></i>Excel</a>} */}
                                        </small>
                                    </h3>
                                    <div className="row userhistoryrem">


                                        <div className="col-md-3">
                                            <label>Type</label>
                                            <select className="form-control" name="type" onChange={(e) => { this.handleInput(e) }}>
                                                <option value="user">User-Wise</option>
                                                <option value="state">State-Wise</option>
                                                <option value="district">District-Wise</option>
                                                <option value="single_user">Single User</option>
                                            </select>
                                        </div>

                                        {this.state.type === "single_user" && <div className="col-md-3">
                                            <label>User</label>
                                            <AsyncTypeahead
                                                id="asdasd"
                                                isLoading={this.state.isLoading}
                                                labelKey={option => `${option.name} (${option.mobile})`}
                                                onSearch={(query) => {
                                                    this.setState({ isLoading: true });
                                                    this.searchUser(query);
                                                }}
                                                options={this.state.options}
                                                onChange={selected => { this.selectUser(selected) }}
                                            />
                                        </div>}
                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-2">
                                            <label>{" "}</label>
                                            <input type="button" name="submit" className="btn btn-primary searchhistory" onClick={(e) => { this.getRecords(1) }} value="Search" />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive style={{ fontFamily: "sans-serif" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>S.No</th>
                                                {this.state.checktype === "state" &&

                                                    <th>State</th>
                                                }
                                                {this.state.checktype === "district" &&

                                                    <th>District</th>
                                                }

                                                {(this.state.checktype === "user" || this.state.checktype === "single_user" )&&
                                                    <>
                                                        <th>Mobile</th>
                                                        <th>Name</th>
                                                    </>
                                                }

                                                <th>No of Remit</th>
                                                <th>Remit Amount</th>
                                                <th>Recharge Amount</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.details.map((val, key) => {
                                                return <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    {this.state.checktype === "state" &&

                                                        <td>{val.state}</td>
                                                    }
                                                    {this.state.checktype === "district" &&
                                                        <td>{val.district}</td>
                                                    }
                                                    {(this.state.checktype === "user" || this.state.checktype === "single_user") &&
                                                        <>
                                                            <td>{val.mobile}</td>
                                                            <td>{val.name}</td>
                                                        </>
                                                    }

                                                    <td>{val.total_txn}</td>
                                                    <td>{val.total}</td>
                                                    <td>{val.total_recharge}</td>

                                                </tr>
                                            })}


                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Transactionreports;
