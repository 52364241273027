import React from "react";
import { Link } from "react-router-dom";
class Userfooter extends React.Component {

    render() {
        return <>
            <footer className="footer-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 foot_col">
                            <div className="footer-text">
                                <div className="logo-footer">
                                    <Link className="navbar-brand" to="/user/dashboard">
                                        <img src={require("assets/images/new/online-sath-logo.png")} className="logo" alt="logo" />
                                    </Link>
                                </div>
                                {/* <div className="footer-text">
                                <p>Even if you are far away from your family, now you have to take care of all the financial affairs of the your Mobile.</p>
                                </div> */}

                               
                            </div>
                        </div>
                        
                        {/* <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="single-footer-widget pl-5">
                                <h3>Support</h3>
                                <ul className="list">
                                    <li>
                                        <Link to="/about-us" target="_blank">
                                            About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-and-conditions" target="_blank">
                                            Terms & Condition</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy" target="_blank">
                                            Privacy Policy</Link>
                                    </li>
                                    <li><a href="/refund-policy" target="_blank">Refunds/Cancellations</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-lg-7 col-sm-7 col-md-7">
                            <div className="single-footer-widget">
                              
                                <ul className="footer-contact-info">
                                    {/* <li className="name-copmany">Online Saathi </li> */}
                                    <li><span className="mr-1">Location:</span>D 109 Maruti Hight, Opp Samor Hight Naroda Muthiya, Ahmedabad, Gujarat, India, 382330</li>
                                    <li><span className="mr-1">Email:</span><a href="mailto:help@onlinesaathi.org" className="email-sec">help@onlinesaathi.org</a> | <span className="mr-1">Phone:</span>+91-9099005251 </li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  {/*    <div className="row">
                        <div className="col-md-6">
                            <div className="copyright-area">
                                {/* <p>© 2021 DSewa - All rights Reserved</p> 
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="copyright-area">
                                <p className="right-sec">Design &amp; Development By
                                        <a href="https://www.ganeshawebtech.com/" target="_blank" rel="noopener noreferrer">
                                        <img src={require("assets/images/gws.webp")} alt="ganeshawebtech" />
                                    </a>
                                </p>
                            </div>
                        </div> 
                    </div>*/}
                </div>
            </footer></>;
    }

}
export default Userfooter;