import React from "react";
import { Button, Card, CardHeader, CardBody, Row, Col, FormGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";


class ImeUploadDocs extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return <>
            <div className="col-md-6">
                {this.props.documents.map((doc, ki) => {
                    if (!doc.file_path) {
                        return <><div key={ki}>
                            <label>{doc.document ? doc.document.value : ""} {doc.is_required === "Yes" && <span className="text-danger">*</span>}</label>
                            <Input type="file" name="license" onChange={(e) => { this.props.onFileChange(e, doc.id) }} />
                        </div>
                            <hr /></>
                    }
                    else {
                        if (this.props.uploadOther === doc.id) {
                            return <><div key={ki}>
                                <label>{doc.document ? doc.document.value : ""} {doc.is_required === "Yes" && <span className="text-danger">*</span>} &nbsp;&nbsp; <a href="" onClick={(e) => { this.props.cancelUploadOtherPh(e, doc.id) }}> Cancel</a></label>
                                <Input type="file" name="license" onChange={(e) => { this.props.onFileChange(e, doc.id) }} />
                            </div>
                                <hr /></>
                        }
                        else {
                            if (doc.file_path.includes(".pdf")) {
                                return <><div key={ki}>
                                    <label>{doc.document ? doc.document.value : ""} {doc.is_required === "Yes" && <span className="text-danger">*</span>} &nbsp;&nbsp; <a href="" onClick={(e) => { this.props.uplaodOtherPh(e, doc.id) }}> Upload other</a></label>
                                    <embed src={this.props.imageUrl + doc.file_path} alt="" />
                                    {doc.updated == "no" && this.props.csp_status !== "submission_pending" && <a href="#" onClick={e => { this.props.uploadUrl(e, doc) }}>Update On IME</a>}
                                    </div>
                                    <hr /></>
                            } else {
                                return <><div key={ki}>
                                    <label>{doc.document ? doc.document.value : ""} {doc.is_required === "Yes" && <span className="text-danger">*</span>} &nbsp;&nbsp; <a href="" onClick={(e) => { this.props.uplaodOtherPh(e, doc.id) }}> Upload other</a></label>
                                    <img src={this.props.imageUrl + doc.file_path} alt="" style={{ width: "100%" }} />
                                    {doc.updated == "no" && this.props.csp_status !== "submission_pending" && <a href="#" onClick={e => { this.props.uploadUrl(e, doc) }}>Update On IME</a>}
                                </div>
                                    <hr /></>
                            }
                        }
                    }
                })}
                <Button type="button" onClick={(e) => { this.props.submitUpload(e) }}>Submit</Button>
            </div>
        </>;
    }
}

export default ImeUploadDocs;
