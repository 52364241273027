import React from "react";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import swal from 'sweetalert';
import ReceiptModel from "./ReceiptModel";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Changepassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:"",
            name:"",
            email:"",
            mobile:"",
            pincode:"",
            country:"",
            state:"",
            city:"",
            district:"",
            address:"",
            id_type:"",
            otp:"",
            verifyOtp:"",
            id_back:"",
            id_front:"",
            profile_pic:"",
            id_backs:"",
            id_fronts:"",
            profile_pics:"",
            license_front:"",
            license_back:"",
            profile:"",
            current_password:"",
            new_password:"",
            confirm_password:""

            
        }
    }

    componentDidMount = () => {
        this.user_detail();
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
    }

    getData = (page = 1) => {

    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    user_detail = e => {
     
        this.setState({ loading: true })
        Axios.get(`${apiUrl}/user/user-detail`, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            // console.log(res);
            this.setState({ loading: false })
            if (res.data.success) {
      
                this.setState({
                    user:res.data.data,
                    name: res.data.data.name,
                    email: res.data.data.email,
                    pincode: res.data.data.pincode,
                    city: res.data.data.city,
                    state: res.data.data.state,
                    country: res.data.data.country,
                    district: res.data.data.district,
                    address: res.data.data.address,
                   
                   id_type: res.data.data.proof_type,
                   license_back: res.data.data.license_back,
                   license_front:res.data.data.license_front,
                   profile: res.data.data.profile_pic
                });
            } else {
               
                    swal({ text: res.data.message, icon: "error" });
                
            }
        })
    }
  

 
    
    update_password = e => {
        e.preventDefault();
       
        if (this.state.current_password === "") {
            swal("", "Please enter confirm password.", "error");
            return false;
        }
        if (this.state.new_password === "") {
            swal("", "Please enter new password.", "error");
            return false;
        }
        if (this.state.confirm_password !== this.state.confirm_password) {
            swal("", "New password and confirm password not same.", "error");
            return false;
        }
    
      
   

      
     
        this.setState({ loading: true })

        var formdata = new FormData();
        formdata.append("old_password", this.state.current_password);
        formdata.append("new_password", this.state.new_password);
        formdata.append("confirm_password", this.state.confirm_password);
      
  
      
        Axios.post(`${apiUrl}/password/change`, formdata, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            // console.log("send transaction response", res)
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.setState({
                    current_password:"",
                    new_password: "",
                    confirm_password: "",
                   
                });
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            swal("", err.message, "error");
            // swal("", "Session expired. Please login again.").then(o => {
            //  //   sessionStorage.clear();
            // //    this.props.history.push("/");
            // })
        })
    }
    submit_register = e => {
        e.preventDefault();
       
        if (this.state.name === "") {
            swal("", "Please enter name.", "error");
            return false;
        }
        if (this.state.email === "") {
            swal("", "Please enter email.", "error");
            return false;
        }
       
     
      
        if (this.state.pincode === "") {
            swal("", "Please enter pincode.", "error");
            return false;
        }
        if (this.state.country === "") {
            swal("", "Please enter country.", "error");
            return false;
        }

        if (this.state.state === "") {
            swal("", "Please enter state.", "error");
            return false;
        }
        if (this.state.city === "") {
            swal("", "Please enter city.", "error");
            return false;
        }
        if (this.state.district === "") {
            swal("", "Please enter district.", "error");
            return false;
        } 
        if (this.state.address === "") {
            swal("", "Please enter address.", "error");
            return false;
        } 
        
        if (this.state.id_type === "") {
            swal("", "Please select  Proof Type.", "error");
            return false;
        } 
      
     
        this.setState({ loading: true })

        var formdata = new FormData();
        formdata.append("name", this.state.name);
        formdata.append("email", this.state.email);

      
        formdata.append("pincode", this.state.pincode);
        formdata.append("city", this.state.city);
        formdata.append("state", this.state.state);
        formdata.append("country", this.state.country);
        formdata.append("district", this.state.district);
        formdata.append("address", this.state.address);
       
        formdata.append("id_type", this.state.id_type);
        formdata.append("id_back", this.state.id_back);
        formdata.append("id_front", this.state.id_front);
        formdata.append("profile_pic", this.state.profile_pic);
      
        Axios.post(`${apiUrl}/user/update-profile`, formdata, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            // console.log("send transaction response", res)
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.setState({
                    user:res.data.data,
                    name: res.data.data.name,
                    email: res.data.data.email,
                    pincode: res.data.data.pincode,
                    city: res.data.data.city,
                    state: res.data.data.state,
                    country: res.data.data.country,
                    district: res.data.data.district,
                    address: res.data.data.address,
                   
                   id_type: res.data.data.proof_type,
                   license_back: res.data.data.license_back,
                   license_front:res.data.data.license_front,
                   profile: res.data.data.profile_pic
                });
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            swal("", err.message, "error");
            // swal("", "Session expired. Please login again.").then(o => {
            //  //   sessionStorage.clear();
            // //    this.props.history.push("/");
            // })
        })
    }
    toggleModal = e => {
        this.setState({
            modalState: !this.state.modalState
        })
    }
    verifyOtp = e => {
        e.preventDefault();
        var data = {
            id: this.state.id,
            verifyOtp: this.state.verifyOtp,
           
        }
        if (this.state.verifyOtp === "" || this.state.verifyOtp.length < 4) {
            swal("", "Please enter valid OTP.", "error");
            return false;
        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/distribuor/verifyagentOTP`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
           
            if (res.data.success) {
           
                  
                    this.setState({
                        verifyOtp: "",
                        id:"",
                        show_otp:false,
                        check_user:true,

                    })
                
                swal("", res.data.message, "success");
                this.clearForms();
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            swal("", err.message, "error");
        })
    }
   

    handleImage = (e, name) => {
        this.setState({
            [name]: e.target.files[0]
        })
    }
   
  
  
   
    render() {
        return <>
            <Userheader user={this.state.user} />
          


            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="form-sec-dash">

                            

                             

                         
                             <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p> Update Password </p>
                                       
                                    </h3>
                                </div>
                                <form autoComplete="off" >
                              <div className="row">
                                 
                                   
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label >Current Password</label>
                                            <input type="password" autoComplete="off" placeholder="Enter current password" onChange={e => { this.handleInput(e) }} name="current_password" value={this.state.current_password}  />
                                        </div>
                                    </div>
                                 
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label > New Password</label>
                                            <input type="password" autoComplete="off" placeholder="Enter New Password" onChange={e => { this.handleInput(e) }} name="new_password" value={this.state.new_password}  />
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                            <label > Confrm Password</label>
                                            <input type="password" autoComplete="off" placeholder="Enter Confirm Password" onChange={e => { this.handleInput(e) }} name="confirm_password" value={this.state.confirm_password}  />
                                        </div>
                                    </div> 
                                 
                                    <div className="col-md-12">
                                       
                                        <div className="send-bt-dash">

                                            
                                            <a href="" onClick={e => { this.update_password(e) }}>Send</a>
                                        </div>
                                    </div>
                                </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <ReceiptModel modalState={this.state.modalState} toggleModal={this.toggleModal} />
            <Userfooter />
        </>;
    }
}
export default Changepassword;

