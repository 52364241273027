import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Predefinetemlate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Templates: [],
            keyword: null,
            template_id:"",
            status: "",
            template: null,
            show_form: false
        }
    }
    componentDidMount = () => {
        this.getAllTemplate();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onFileChange = (e) => {
        this.setState({ banner_image: e.target.files[0] })
    }

    saveTemplates = e => {
        e.preventDefault();
        if (this.state.keyword == "") {
            this.notify("error", "Keyword is required.");
            return false;
        }
        if (this.state.template == "") {
            this.notify("error", "Template is required.");
            return false;
        }
      
        const formData = new FormData();
        formData.append('keyword', this.state.keyword);
        formData.append('template', this.state.template);
        
        Axios.post(`${apiUrl}/admin/post-template`, formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    show_form: !this.state.show_form,
                    keyword: null,
                    template: "",
                    status: ""
                   
                })
                this.getAllTemplate();
            } else {
                this.notify("error", res.data.message);
            }
        })
    }
    editTemplates = e => {
        e.preventDefault();
        if (this.state.keyword == "") {
            this.notify("error", "Keyword is required.");
            return false;
        }
        if (this.state.template == "") {
            this.notify("error", "Template is required.");
            return false;
        }
      
        const formData = new FormData();
        formData.append('id', this.state.template_id);
        formData.append('keyword', this.state.keyword);
        formData.append('template', this.state.template);
        
        Axios.post(`${apiUrl}/admin/post-template`, formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    edit_form: !this.state.edit_form,
                    keyword: null,
                    template: "",
                    status: "",
                    template_id:""
                   
                })
                this.getAllTemplate();
            } else {
                this.notify("error", res.data.message);
            }
        })
    }
    getAllTemplate = () => { 
        Axios.get(`${apiUrl}/admin/all/templates`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("Templates response", res.data.data.data)
            if (res.data.success) {
                this.setState({
                    Templates: res.data.data.data
                })
            }
            else {
                this.setState({
                    Templates: []
                })
            }
        })
    }
    deleteBanner = (e, id) => {
        e.preventDefault();
        Axios.get(`${apiUrl}/admin/delete/banner/${id}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.notify("success", res.data.message);
            if (res.data.success) {
                this.setState({
                    Templates: res.data.Templates
                })
            }
        }, err => {
            console.log(err)
        })
    }
    
    vieweditform = (e = null,val) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: false,
            edit_form: !this.state.edit_form,
            
            template_id:val.id,
            template:val.template,
            keyword:val.keyword, 
           
        })
    }
    showForm = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: !this.state.show_form,
            edit_form: false,
            keyword: null,
            template: "",
            template_id: ""
        })
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">Templates
                                    {!this.state.show_form && <small className="float-right"><a href="" onClick={(e) => { this.showForm(e) }} >Add New Template</a></small>}
                                    </h3>
                                </CardHeader>
                                <CardBody>
                                    {this.state.show_form && <Form onSubmit={this.saveTemplates}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Keyword </label>
                                                    <input type="text" className="form-control" name="keyword" value={this.state.keyword} onChange={(e) => { this.inputChange(e) }} />
                                                      
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                            <FormGroup>
                                                    <label> Template </label>
                                                    <input type="text" className="form-control" name="template" value={this.state.template} onChange={(e) => { this.inputChange(e) }} />
                                                      
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                     
                                        <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                            Cancel
                                        </Button>
                                        <Button className="btn-fill" color="primary" type="submit">
                                            Save
                                        </Button>
                                    </Form>}
                                    {this.state.edit_form && <Form onSubmit={this.editTemplates}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Keyword </label>
                                                    <input type="text" className="form-control" name="keyword" value={this.state.keyword} onChange={(e) => { this.inputChange(e) }} />
                                                      
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                            <FormGroup>
                                                    <label> Template </label>
                                                    <input type="text" className="form-control" name="template" value={this.state.template} onChange={(e) => { this.inputChange(e) }} />
                                                      
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                     
                                        <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                            Cancel
                                        </Button>
                                        <Button className="btn-fill" color="primary" type="submit">
                                            Save
                                        </Button>
                                    </Form>}
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>Keyword</th>
                                                <th>Template</th>
                                                <th>Status</th>
                                               
                                             <th>Action</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.Templates.map((val, key) => {
                                                return <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{val.keyword}</td>
                                                    <td>
                                                        {val.template} 
                                                    </td>
                                                    <td>
                                                        {val.status} 
                                                    </td>
                                                    <td>
                                                      
                                                        <a href="" onClick={(e) => {this.vieweditform(e ,val)}} >
                                                        <i className="fas fa-edit"></i>
                                                        </a>
                                                     </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Predefinetemlate;
