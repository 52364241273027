import React from "react";
import { NavLink } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

class Usersidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            classname: "",
            cu: sessionStorage.getItem('cuser') ? Cryp.decrypt(sessionStorage.getItem('cuser')) : null,
            userpermission: sessionStorage.getItem('userpermission') ? JSON.parse(sessionStorage.getItem("userpermission")) : null,

        }
    }
    checkAccess(page) {
        var ok = false;
        if (this.state.userpermission) {
            this.state.userpermission.map((val) => {
                if (val.page_name === page) {
                    ok = true;

                }
            });
        }
        return ok;
    }
    toggleSide = e => {
        e.preventDefault();
        this.setState({
            classname: this.state.classname === "show" ? "" : "show"
        })
    }
    render() {
        return <> <div className="col-md-2 user_sidebar">
            <div className="sidebar_fixed">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" onClick={e => { this.toggleSide(e) }}>
                    <span className="navbar-toggler-icon"><i className="fa fa-bars" aria-hidden="true"></i></span>
                </button>

                <aside className="sidebar1">
                    <div className="widget admin-widget p-0">
                        <div className={"collapse navbar-collapse " + this.state.classname} id="collapsibleNavbar">
                            <div className="Profile-menu ">

                                <ul className="nav secondary-nav">
                                    {this.checkAccess('/dashboard') && <>
                                        <li className="nav-item">
                                            <NavLink show="false" className="nav-link" to="dashboard" activeClassName="active"><img src={require("assets/images/dashboard.png")} alt="" /> Dashboard</NavLink>
                                        </li>
                                    </>}

                                    {this.checkAccess('/money-transfer') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="money-transfer" activeClassName="active"><img src={require("assets/images/remittance_wallet_history_black.png")} alt="" />Money Transfer</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/become-saathi') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="become-saathi" activeClassName="active"><img src={require("assets/images/remittance_wallet_history_black.png")} alt="" />Become Saathi</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/become-saathi') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="become-member" activeClassName="active"><img src={require("assets/images/remittance_wallet_history_black.png")} alt="" />Become Member</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/become-saathi') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="become-saathi2" activeClassName="active"><img src={require("assets/images/remittance_wallet_history_black.png")} alt="" />Scemes</NavLink>
                                        </li>
                                    </>}
                                    {/* {this.checkAccess('/schemes') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="schemes" activeClassName="active"><img src={require("assets/images/remittance_wallet_history_black.png")} alt="" />Scemes</NavLink>
                                        </li>
                                    </>} */}
                                    {this.checkAccess('/agent-register') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="agent-register" activeClassName="active"><img src={require("assets/images/remettance_history.png")} alt="" />Agent Register</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/user-register') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="user-register" activeClassName="active"><img src={require("assets/images/remettance_history.png")} alt="" />User Register</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/list') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="list" activeClassName="active"><img src={require("assets/images/remettance_history.png")} alt="" />User List</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/agent-list') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="agent-list" activeClassName="active"><img src={require("assets/images/remettance_history.png")} alt="" />Agent List</NavLink>
                                        </li>
                                    </>}
                                  
                                    {this.checkAccess('/prabhu-remittance-history') && <>
                                        <li className="nav-item"><NavLink className="nav-link" to="prabhu-remittance-history" activeClassName="active"><img src={require("assets/images/remettance.png")} alt="" /> Prabhu Remittance History</NavLink></li>
                                    </>}
                             
                                        {/* <li className="nav-item"><NavLink className="nav-link" to="prabhu-remittance" activeClassName="active"><img src={require("assets/images/remettance.png")} alt="" /> Prabhu Remittance</NavLink></li> */}
                               
                                    {this.checkAccess('/prabhu-remittance') && <>
                                        <li className="nav-item"><NavLink className="nav-link" to="prabhu-remittance" activeClassName="active"><img src={require("assets/images/remettance.png")} alt="" /> Prabhu Remittance</NavLink></li>
                                    </>} 
                                    {this.checkAccess('/bank-details') && <>
                                        <li className="nav-item"><NavLink className="nav-link" to="bank-details" activeClassName="active"><img src={require("assets/images/bank.png")} alt="" /> Bank Detail</NavLink></li>
                                    </>}
                                    {this.checkAccess('/remittance-history') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="remittance-history" activeClassName="active"><img src={require("assets/images/remettance_history.png")} alt="" />Remittance History</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/wallet-history') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="wallet-history" activeClassName="active"><img src={require("assets/images/remittance_wallet_history_black.png")} alt="" />Remittance Wallet History</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/nepal-recharge-history') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="nepal-recharge-history" activeClassName="active"><img src={require("assets/images/remittance_wallet_history_black.png")} alt="" />Nepal Recharge History</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/india-recharge-history') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="india-recharge-history" activeClassName="active"><img src={require("assets/images/remittance_wallet_history_black.png")} alt="" />India Recharge History</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/commission-reports') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="commission-reports" activeClassName="active"><img src={require("assets/images/remittance_wallet_history_black.png")} alt="" />Commission Reports</NavLink>
                                        </li>
                                    </>}
                                    {this.checkAccess('/report') && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="report" activeClassName="active"><img src={require("assets/images/remittance_wallet_history_black.png")} alt="" />Complaint</NavLink>
                                        </li>
                                    </>}
                                    
                                    <li className="nav-item" onClick={e => { this.props.logout(e) }}><a className="nav-link" href=""><img src={require("assets/images/logout.png")} alt="" />Logout</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </div></>;
    }
}
export default Usersidebar;