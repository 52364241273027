import React from "react";
import Axios from "axios";

import SimpleCrypto from "simple-crypto-js";
import swal from "sweetalert";

const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}
class KycForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: "",
            membership_id: "",
            first_name: "",
            nationality: "",
            marital_status: "",
            dob: "",
            gender: "",
            p_name: "",
            occupation: "",
            source_of_found: "",
            country: "",
            state: "",
            district: "",
            address: "",
            id_type: "",
            id_number: "",
            issue_date: "",
            expire_date: "",
            id_issue_place: "",
            nepal_id_number: "",
            nepal_issue_place: "",
            term_condition: "",
            doc1: null,
            doc2: null,
            doc3: null,
            doc4: null,
        }
    }
    componentDidMount = () => {
        if (this.props.user_id) {
            this.kycform();
        }
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    kycform = (e) => {
        if (e) e.preventDefault();
        this.setState({
            user_id: this.props.user_id
        }, () =>

            Axios.post(`${apiUrl}/user/kyc/data`, { user_id: this.props.user_id }, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({
                    //  user_id:res.data.data.user_id,
                    mobile: res.data.data ? res.data.data.mobile : null,
                    membership_id: res.data.data ? res.data.data.membership_id : null,
                    first_name: res.data.data ? res.data.data.first_name : null,
                    middle_name: res.data.data ? res.data.data.middle_name : null,
                    last_name: res.data.data ? res.data.data.last_name : null,
                    nationality: res.data.data ? res.data.data.nationality : null,
                    marital_status: res.data.data ? res.data.data.marital_status : null,
                    dob: res.data.data ? res.data.data.dob : null,
                    gender: res.data.data ? res.data.data.gender : null,
                    p_name: res.data.data ? res.data.data.p_name : null,
                    occupation: res.data.data ? res.data.data.occupation : null,
                    source_of_found: res.data.data ? res.data.data.source_of_found : null,
                    country: res.data.data ? res.data.data.country : null,
                    state: res.data.data ? res.data.data.state : null,
                    district: res.data.data ? res.data.data.district : null,
                    address: res.data.data ? res.data.data.address : null,
                    id_type: res.data.data ? res.data.data.id_type : null,
                    id_number: res.data.data ? res.data.data.id_number : null,
                    issue_date: res.data.data ? res.data.data.issue_date : null,
                    expire_date: res.data.data ? res.data.data.expire_date : null,
                    id_issue_place: res.data.data ? res.data.data.id_issue_place : null,
                    nepal_id_number: res.data.data ? res.data.data.nepal_id_number : null,
                    nepal_issue_place: res.data.data ? res.data.data.nepal_issue_place : null,
                    term_condition: res.data.data ? res.data.data.term_condition : null,
                })
            })

        );
    }
    kyc_submit = () => {
        if (this.state.mobile === '') {
            swal("", "mobile number required", "error");

            return false;
        }
        if (this.state.first_name === '') {
            swal("", "first name required", "error");

            return false;
        }
        if (this.state.nationality === '') {
            swal("", "nationality required", "error");

            return false;
        }
        if (this.state.marital_status === '') {
            swal("", "marital status required", "error");

            return false;
        }
        if (this.state.dob === '') {
            swal("", "dob  required", "error");

            return false;
        }
        if (this.state.gender === '') {
            swal("", "gender required", "error");

            return false;
        }
        if (this.state.p_name === '') {
            swal("", "Parent name  required", "error");

            return false;
        }
        if (this.state.occupation === '') {
            swal("", "occupation   required", "error");

            return false;
        }
        // if (this.state.source_of_found === '') {
        //     swal("", "source of found   number required", "error");

        //     return false;
        // }
        if (this.state.country === '') {
            swal("", "country required", "error");

            return false;
        }
        if (this.state.state === '') {
            swal("", "state required", "error");

            return false;
        }
        if (this.state.district === '') {
            swal("", "district required", "error");

            return false;
        }
        if (this.state.address === '') {
            swal("", "address required", "error");

            return false;
        }
        if (this.state.id_type === '') {
            swal("", "id type required", "error");

            return false;
        }
        if (this.state.id_number === '') {
            swal("", "id number required", "error");

            return false;
        }
        // if (this.state.issue_date === '') {
        //     swal("", "issue date   number required", "error");

        //     return false;
        // }
        // if (this.state.expire_date === '') {
        //     swal("", "expire date   number required", "error");

        //     return false;
        // }
        // if (this.state.id_issue_place === '') {
        //     swal("", "id issue place  number required", "error");

        //     return false;
        // }
        // if (this.state.nepal_id_number === '') {
        //     swal("", "nepal id number   required", "error");

        //     return false;
        // }
        // if (this.state.nepal_issue_place === '') {
        //     swal("", "nepal issue place  required", "error");

        //     return false;
        // }
        if (this.state.term_condition === '') {
            swal("", "please accept term and conditions", "error");

            return false;
        }
        if (!this.state.doc1 && !this.state.doc2 && !this.state.doc3 && !this.state.doc4) {

            swal("", "Please upload at least one document.", "error");

            return false;
        }
        var formdata = new FormData();
        formdata.append('user_id', this.state.user_id);
        formdata.append('mobile', this.state.mobile);
        formdata.append('membership_id', this.state.membership_id);
        formdata.append('first_name', this.state.first_name);
        formdata.append('middle_name', this.state.middle_name);
        formdata.append('last_name', this.state.last_name);
        formdata.append('nationality', this.state.nationality);
        formdata.append('marital_status', this.state.marital_status);
        formdata.append('dob', this.state.dob);
        formdata.append('gender', this.state.gender);
        formdata.append('p_name', this.state.p_name);
        formdata.append('occupation', this.state.occupation);
        formdata.append('source_of_found', this.state.source_of_found);
        formdata.append('country', this.state.country);
        formdata.append('state', this.state.state);
        formdata.append('district', this.state.district);
        formdata.append('address', this.state.address);
        formdata.append('id_type', this.state.id_type);
        formdata.append('id_number', this.state.id_number);
        formdata.append('issue_date', this.state.issue_date);
        formdata.append('expire_date', this.state.expire_date);
        formdata.append('id_issue_place', this.state.id_issue_place);
        formdata.append('nepal_id_number', this.state.nepal_id_number);
        formdata.append('nepal_issue_place', this.state.nepal_issue_place);
        formdata.append('term_condition', this.state.term_condition);
        if (this.state.doc1)
            formdata.append('document_1', this.state.doc1);
        if (this.state.doc2)
            formdata.append('document_2', this.state.doc2);
        if (this.state.doc3)
            formdata.append('document_3', this.state.doc3);
        if (this.state.doc4)
            formdata.append('document_4', this.state.doc4);

        Axios.post(apiUrl + `/user/kyc/form`, formdata, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            if (this.props.hidekyc) {
                this.props.hidekyc(type, res.data.message);
            }
            // else {
            swal("", res.data.message, "success");

            console.log(res)
            // }
        })
    }
    handleImage = (e, name) => {
        this.setState({
            [name]: e.target.files[0]
        })
    }
    getStates = () => {

    }
    render() {
        return <div className="col-md-10 right_contentsec">
            <div class=" form_per_details">
                {/* <a href="#">User  KYC </a> */}
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                            <p class="manage_nar">
                            User  KYC
                            </p>
                        </h2>
                    </div>

                    <div class="card_wrap1">
                        <div class="card-body">
                            <h2> PERSONAL DETAILS</h2>
                            <form>
                                <div class="form-row mb30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Mobile Number:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="number" name="mobile" value={this.state.mobile} onChange={(e) => { this.handleInput(e) }} class="form-control" id="" placeholder="Mobile Number" />
                                        </div>
                                    </div>
                                    {/* <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Membership Id:</label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="text" name="membership_id" value={this.state.membership_id} onChange={(e) => { this.handleInput(e) }} class="form-control" id="" placeholder="Membership Id:" />
                                        </div>
                                    </div> */}
                                </div>
                                <div class="form-row mt30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Name:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="text" name="first_name" value={this.state.first_name} onChange={(e) => { this.handleInput(e) }} class="form-control" id="" placeholder="Name" />
                                        </div>
                                    </div>
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-6">
                                            <input type="text" name="middle_name" value={this.state.middle_name} onChange={(e) => { this.handleInput(e) }} class="form-control" id="" placeholder="Middle Name" />
                                        </div>
                                        <div class="form-inline col-md-6">
                                            <input type="text" name="last_name" value={this.state.last_name} onChange={(e) => { this.handleInput(e) }} class="form-control" id="" placeholder="Last Name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mt30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Nationality:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <select class="custom-select" value={this.state.nationality} onChange={(e) => { this.handleInput(e) }} name="nationality" >
                                                <option value="">Select Nationality</option>
                                                <option value="Nepal">Nepal</option>
                                                <option value="India">India</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Marital Status:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <select class="custom-select" value={this.state.marital_status} onChange={(e) => { this.handleInput(e) }} name="marital_status" >
                                                <option value="">Marital Status</option>
                                                <option value="Married" >Married</option>
                                                <option value="UnMarried">UnMarried</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mt30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Date Of Birth:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="date" name="dob" value={this.state.dob} onChange={(e) => { this.handleInput(e) }} class="form-control" id="" placeholder="dd/mm/yyyy" />
                                        </div>
                                    </div>
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Gender:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <select name="gender" value={this.state.gender} onChange={(e) => { this.handleInput(e) }} class="custom-select">
                                                <option value="" >Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Transgender">Transgender</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mt30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Father/Mother Name:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="text" value={this.state.p_name} onChange={(e) => { this.handleInput(e) }} name="p_name" class="form-control" id="" placeholder="Father/Mother name" />
                                        </div>
                                    </div>
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Occupation:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <select class="custom-select" value={this.state.occupation} onChange={(e) => { this.handleInput(e) }} name="occupation">
                                                <option value="">Select Occupation</option>
                                                <option value="Govt Job">Govt Job</option>
                                                <option value="Private Job">Private Job</option>
                                                <option value="Business">Business</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="form-row mt30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Source Of Fund:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <select class="custom-select" value={this.state.source_of_found} onChange={(e) => { this.handleInput(e) }} name="source_of_found">
                                                <option value="">Select Source</option>
                                                <option value="Bonus">Bonus</option>
                                                <option value="Commission">Commission</option>

                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                            </form>

                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card_wrap2">
                        <div class="card-body">
                            <h2>Address Details</h2>
                            <form>
                                <div class="form-row mb30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Country:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <select name="country" value={this.state.country} onChange={(e) => { this.handleInput(e) }} class="custom-select">
                                                <option value="">Select Country</option>
                                                <option value="India">India</option>
                                                <option value="Nepal">Nepal</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">State<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="text" value={this.state.state} onChange={(e) => { this.handleInput(e) }} name="state" class="form-control" id="" placeholder="Enter state" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mb30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">District<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="text" value={this.state.district} onChange={(e) => { this.handleInput(e) }} name="district" class="form-control" id="" placeholder="Enter District" />
                                        </div>
                                    </div>
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Address</label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="text" class="form-control" value={this.state.address} onChange={(e) => { this.handleInput(e) }} name="address" id="" placeholder="Enter Address" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="card mb-42">
                    <div class="card_wrap4">
                        <div class="card-body">
                            <h2>IDENTITY DETAILS</h2>
                            <form>
                                <div class="form-row mb30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">ID Type:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <select class="custom-select" value={this.state.id_type} onChange={(e) => { this.handleInput(e) }} name="id_type">
                                                <option value="" >Select Id Type</option>
                                                <option value="Passport">Passport</option>
                                                <option value="Aadhar Card">Aadhar Card"</option>
                                                <option value="Driving License">Driving License</option>
                                                <option value="Nepali Citizenship">Nepali Citizenship</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">ID Number:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="text" value={this.state.id_number} onChange={(e) => { this.handleInput(e) }} name="id_number" class="form-control" id="" placeholder="Enter Id Number" />
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="form-row mb30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Id Issue Date:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="date" value={this.state.issue_date} onChange={(e) => { this.handleInput(e) }} name="issue_date" class="form-control" id="" placeholder="Enter Id Type" />
                                        </div>
                                    </div>
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">Id Expiry Date:<span>*</span></label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="date" name="expire_date" value={this.state.expire_date} onChange={(e) => { this.handleInput(e) }} class="form-control" id="" placeholder="Enter Expire Date" />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div class="form-row mb30">
                                    <div class="form-inline col-md-6">
                                        <div class="form-inline col-md-4">
                                            <label for="">ID Issued Place:</label>
                                        </div>
                                        <div class="form-inline col-md-8">
                                            <input type="text" name="id_issue_place" value={this.state.id_issue_place} onChange={(e) => { this.handleInput(e) }} class="form-control" id="" placeholder="Enter Issue Place" />
                                        </div>
                                    </div>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
                <div class="card mb0">
                    <div class="card_wrap5">
                        <div class="card-body">
                            <h2>Documents</h2>
                            <form>
                                <div class="form-row">
                                    {/* <div class="form-group col-md-4">
                                        <div class="form-inline">
                                            <label for="">ID Number:<span>*</span></label>
                                        </div>
                                        <div class="form-group ">
                                            <input type="text" value={this.state.nepal_id_number} onChange={(e) => { this.handleInput(e) }} name="nepal_id_number" class="form-control" id="" placeholder="Nepal Id Number" />
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <div class="form-inline">
                                            <label for="">Issued Place<span>*</span></label>
                                        </div>
                                        <div class="form-group ">
                                            <input type="text" value={this.state.nepal_issue_place} onChange={(e) => { this.handleInput(e) }} name="nepal_issue_place" class="form-control" id="" placeholder="Nepal Issue Place" />
                                        </div>
                                    </div> */}
                                    <div class="col-md-4">
                                        <div class="form-inline">
                                            <label for="">Documents<span>*</span></label>
                                        </div>
                                        <div class="mt-2">
                                            <input type="file" onChange={(e) => { this.handleImage(e, 'doc1') }} />
                                        </div>
                                        <div class="mt-2">
                                            <input type="file" onChange={(e) => { this.handleImage(e, 'doc2') }} />
                                        </div>
                                        <div class="mt-2">
                                            <input type="file" onChange={(e) => { this.handleImage(e, 'doc3') }} />
                                        </div>
                                        <div class="mt-2">
                                            <input type="file" onChange={(e) => { this.handleImage(e, 'doc4') }} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card_wrap6">
                        <div class="card-body">
                            <div class="form-check">
                                <input class="form-check-input" name="term_condition" type="checkbox" value="yes" onChange={(e) => { this.handleInput(e) }} id="defaultCheck1" defaultChecked={this.state.term_condition === "yes"} />
                                <label class="form-check-label" for="defaultCheck1">
                                    By Clicking the check box, I accept the below declaration.
                                </label>
                                <ol>
                                    <li>I hereby declare, that the information filled here in above is correct as per the information provided by the customer.</li>
                                    <li>The customer has approached me in person for the remittance service.</li>
                                    <li>The customer is a Nepal Migrant.</li>
                                    <li>The customer ID document have been verified in original.</li>
                                    <h4>IME FOREX INDIA PRIVATE LIMITED SUBHLAXMI MULTI SERVICES INDIA PVT LT D HEAD OFFICE</h4>
                                    <li>The customer has not registered under any other mobile no, or under any other ID document on Extension numbers 206 , 207 and 209 or email us at support@imeforex.com for related issues.</li>
                                </ol>
                            </div>
                            <button type="button" onClick={(e) => { this.kyc_submit(e) }} class="btn btn-primary">Submit</button>
                            <button type="button" onClick={(e) => { this.props.hidekyc(e) }} class="btn btn-danger">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

}

export default KycForm;