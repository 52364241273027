import React from "react";
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   CardFooter,
   CardText,
   FormGroup,
   Form,
   Input,
   Row,
   Col,
   Table,
   Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Axios from "axios";
import { Pagination } from 'react-laravel-paginex'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Notification extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         notifications: [],
         title:"",
         message: "",
         status: "active",
         type: "",
         image: null,
         show_form: false,
         selected_user_id: null,
         paginatedata: {
            current_page: 1,
            data: [],
            first_page_url: "",
            from: 0,
            last_page: 0,
            last_page_url: "",
            next_page_url: "",
            path: "",
            per_page: 10,
            prev_page_url: null,
            to: 0,
            total: 0
         }
      }
   }
   componentDidMount = () => {
      this.getAllNotification();
   }
   inputChange = (e) => {
      this.setState({
         [e.target.name]: e.target.value
      })
   }
   onFileChange = (e) => {
      this.setState({ image: e.target.files[0] })
   }

   savenotification = e => {
      e.preventDefault();
      if (this.state.type == "") {
         this.notify("error", "Please Select Sent To");
         return false;
      }
      if (this.state.status == "") {
         this.notify("error", "Please select Notification status.");
         return false;
      }
      if (this.state.message == "") {
         this.notify("error", "Please enter short description.");
         return false;
      }
      if (this.state.title == "") {
         this.notify("error", "Please enter title.");
         return false;
      }
      this.setState({ loading: true })
      const formData = new FormData();
      formData.append('type', this.state.type);
      formData.append('image', this.state.image);
      formData.append('user_id', this.state.selected_user_id);
      formData.append('message', this.state.message);
      formData.append('long_desc', this.state.long_desc);
      formData.append('status', this.state.status);
      formData.append('title', this.state.title);
      formData.append('link', this.state.link);
      formData.append('video', this.state.video);
      Axios.post(`${apiUrl}/admin/create-notice`, formData, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.showForm();
            this.getAllNotification();
         } else {
            this.notify("error", res.data.message);
         }
         this.setState({ loading: false })
      }, err => {
         this.setState({ loading: false })

      })
   }

   getAllNotification = (page = 1) => {
      this.setState({ loading: true })

      Axios.get(`${apiUrl}/admin/custom-notice?page=${page}`, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("notification response", res)
         if (res.data.success) {
            this.setState({
               paginatedata: res.data.data,
               notifications: res.data.data.data
            })
         }
         else {
            this.setState({
               notifications: []
            })
         }
         this.setState({ loading: false })
      }, error => {
         this.setState({ loading: false })

      })
   }
   changePage = data => {
      console.log(data)
      this.getAllNotification(data.page);
   }
   delete_notification = (e, id) => {
      e.preventDefault();
      Axios.post(`${apiUrl}/admin/delete-notice`, { id: id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.getAllNotification();
         }
         else {
            this.notify("error", res.data.message);
         }
      }, err => {
         this.notify("error", "Something went wrong");
         console.log("status error", err)
      })
   }
   statusChangenotification = (e, id) => {
      e.preventDefault();
      Axios.post(`${apiUrl}/admin/notification/change/status`, { id: id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.getAllNotification();
         }
         else {
            this.notify("error", res.data.message);
         }
      }, err => {
         this.notify("error", "Something went wrong");
         console.log("status error", err)
      })
   }
   showForm = (e = null) => {
      if (e) e.preventDefault();
      this.setState({
         show_form: !this.state.show_form,
         image: null,
         type: "",
         message: "",
         title:"",
         selected_user: ""

      })
   }
   searchUser = e => {
      if (e.length > 2) {
         var data = { keyword: e };
         Axios.post(apiUrl + "/admin/users/list", data, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            console.log(res)
            this.setState({ options: res.data.users, isLoading: false })
         })
      }

   }
   selectUser = selected => {
      console.log(selected)
      this.setState({ selected_user_id: selected.length > 0 ? selected[0].id : null });
   }
   notify = (type, message) => {
      var atype = type === "error" ? "danger" : type;
      var options = {};
      options = {
         place: process.env.REACT_APP_TOAST_POSITION,
         message: (
            <div>
               <div>
                  {message}
               </div>
            </div>
         ),
         type: atype,
         icon: "tim-icons icon-bell-55",
         autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
   };
   toggle = (e) => {
      this.setState({
         modal: !this.state.modal
      })
   }

   render() {
      return (
         <>
            {this.state.loading && <div className="loading-overlay">
               <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
            </div>}
            <div className="content">

               <div className="react-notification-alert-container">
                  <NotificationAlert ref="notificationAlert" />
               </div>
               <Row>
                  <Col md="12">
                     <Card>
                        <CardHeader>
                           <h3 className="title">Notice
                              {!this.state.show_form && <small className="float-right"><a href="" onClick={(e) => { this.showForm(e) }} >Add New</a></small>}
                           </h3>
                        </CardHeader>
                        <CardBody>
                           {this.state.show_form && <Form onSubmit={this.savenotification}>
                              <Row>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>
                                          Sent To
                                       </label>
                                       <select className="form-control" name="type" value={this.state.type} onChange={(e) => { this.inputChange(e) }}>
                                          <option value="">Select</option>
                                          <option value="all">All User and Agent</option>
                                          <option value="user">Only User</option>
                                          <option value="agent">Only Agent</option>
                                          <option value="single">Single  User/Agent</option>


                                       </select>
                                    </FormGroup>
                                 </Col>


                                 {/* <Col md="6">
                                    <FormGroup>
                                       <label>
                                          Status
                                                    </label>
                                       <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                                          <option value="">Select</option>
                                          <option value="active">Active</option>
                                          <option value="inactive">Inactive</option>
                                       </select>
                                    </FormGroup>
                                 </Col> */}
                                 {this.state.type === 'single' && <>
                                    <Col md="6">
                                       <FormGroup>
                                          <label>Search User/Agent</label>
                                          <AsyncTypeahead
                                             id="asdasd"
                                             isLoading={this.state.isLoading}
                                             labelKey={option => `${option.name} (${option.mobile})`}
                                             onSearch={(query) => {
                                                this.setState({ isLoading: true });
                                                this.searchUser(query);
                                             }}
                                             options={this.state.options}
                                             onChange={selected => { this.selectUser(selected) }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 </>
                                 }
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Title</label>
                                       <Input name="title" value={this.state.title} placeholder="Enter Title" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>


                                 <Col md="6">
                                    <FormGroup>
                                       <label>Short Description</label>
                                       <Input name="message" value={this.state.message} placeholder="Enter Short Description" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Long Description</label>
                                       <Input name="long_desc" value={this.state.long_desc} placeholder="Enter Long Description" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Image</label>
                                       <Input name="image" type="file" onChange={(e) => { this.onFileChange(e) }} style={{ opacity: 1 }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Link</label>
                                       <Input name="link" value={this.state.link} placeholder="Enter Link" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Video Link</label>
                                       <Input name="video" value={this.state.video} placeholder="Enter Youtube Video Link" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>

                                 <Col md="6">
                                    <FormGroup>
                                       <label>
                                          Status
                                       </label>
                                       <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                                          <option value="">Select</option>
                                          <option value="active">Active</option>
                                          <option value="inactive">Inactive</option>
                                       </select>
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                 Cancel
                              </Button>
                              <Button className="btn-fill" color="primary" type="submit">
                                 Save
                              </Button>
                           </Form>}
                           <Table className="tablesorter" responsive>
                              <thead className="text-primary">
                                 <tr>
                                    <th>Sr.</th>
                                    <th>Type</th>
                                    <th>Title</th>
                                    <th>Short Description</th>
                                    <th>Long Description</th>
                                    <th>Image</th>
                                    <th>Link</th>
                                    <th>Video Link</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.notifications.map((val, key) => {
                                    return <tr key={key}>
                                       <td>{key + 1}</td>
                                       <td>{val.type}</td>
                                       <td>{val.title}</td>
                                       <td>{val.message}</td>
                                       <td>{val.long_desc}</td>

                                       <td className="categoryimg">
                                          {val.image && <img src={imageUrl + val.image} alt=" " style={{ width: "50px" }} />}
                                       </td>
                                       <td>{val.link}</td>
                                       <td>{val.video}</td>
                                       {/* <td>{val.status}</td>


                                       <td>
                                          <a href="" onClick={(e) => { this.statusChangenotification(e, val.id) }} >
                                             {val.status === "active" && <i className="tim-icons icon-simple-remove" title="Inactive Notification"></i>}
                                             {val.status === "inactive" && <i className="tim-icons icon-check-2" title="Active Notification"></i>}
                                          </a>
                                       </td> */}
                                       <td>
                                          <a href="" onClick={(e) => { this.delete_notification(e, val.id) }} >
                                             <i className="tim-icons icon-simple-remove" title="Delete Notification"></i>


                                          </a>
                                       </td>
                                    </tr>
                                 })}
                              </tbody>
                           </Table>
                           <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                        </CardBody>
                        {/* <CardFooter>

                                </CardFooter> */}
                     </Card>
                  </Col>
               </Row>
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} >
               <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
               <ModalBody>
                  <p>Changing position warning</p>
                  <FormGroup>
                     <label>Position</label>
                     <Input name="position" value={this.state.position} placeholder="position" type="number" min={1} max={this.state.notifications.length} onChange={(e) => { this.inputChange(e) }} />
                  </FormGroup>
               </ModalBody>
               <ModalFooter>
                  <Button color="primary" onClick={this.changePosition}>Update</Button>{' '}
                  <Button color="secondary" onClick={this.toggle}>Cancel</Button>
               </ModalFooter>
            </Modal>
         </>
      );
   }
}

export default Notification;
