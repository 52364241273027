import React, { useEffect, useState } from "react";
import Axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import { State, City } from "country-state-city";
import Userfooter from '../Footer';
import Usersidebar from "../../Users/Usersidebar";
import myAppointment from '../../../assets/img/myAppointment.png';
import myApplication from '../../../assets/img/myApplication.png';
import checkEligbility from '../../../assets/img/checkEligbility.png';
import documentAdvisor from '../../../assets/img/documentAdvisor.png';
import bankLoan from '../../../assets/img/bankLoan.png';
import Vector from '../../../assets/img/Vector.png';
import Userheader from '../Header';
import SimpleCrypto from "simple-crypto-js";

const AllSchemes = () => {
  const [step, setStep] = useState(0);
//   const [countryelected, setcountryelected] = useState("india");
  const [States, setStates] = useState(State?.getStatesOfCountry("IN"));
  const [Cities, setCities] = useState(City?.getCitiesOfState("IN", "DL"));
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);

  const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const Cryp = new SimpleCrypto(_secretKey);
  
  const url = window.location.href;
  var apiUrl = process.env.REACT_APP_API_URL_LIVE;
  var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
  
  if (!url.includes('onlinesaathi.org')) {
      apiUrl = process.env.REACT_APP_API_URL;
      imageUrl = process.env.REACT_APP_IMAGE_URL;
  }

useEffect(() => {
  fetchData();
}, []);

const fetchData = async () => {
  try {
    const response = await Axios.get("https://api.onlinesaathi.org/api/v1/user/good/gov/home", {
      headers: {
        Authorization: "Bearer " + "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMGYzMWUzYWZiNThiZDI5ZGM2N2U3MDI5M2JkNzgzMzE0YWZjOGQ2ODUxMTQzMGM4MjU0MGJlMzhlYzIzYzFkYTExMWViNjljZDdjZTE0N2IiLCJpYXQiOjE3MDI2Mzk4NzUuNDUzNzQxMDczNjA4Mzk4NDM3NSwibmJmIjoxNzAyNjM5ODc1LjQ1Mzc0NjA4MDM5ODU1OTU3MDMxMjUsImV4cCI6MTczNDI2MjI3NS40NDg1MzkwMTg2MzA5ODE0NDUzMTI1LCJzdWIiOiIxNjQwOCIsInNjb3BlcyI6W119.o9uC8F7hxZeKG-yrKDffuOynkwZwoYXanSanjQRcTxTsp5iQYNNbvIlJ1UZ5Ujzeh2SQupfCh-sGuyAqYzadYoeJJE5k4i2ugwqUtAoGKeGhwlZDBPypxat9xA5gmdwDhaKAv-gsfTMXNbGSyFJPfj-VSgE6UYlee-kToOrX3Zzt-YZEgBXXX3YJ8hnhO1-xhiGY1f4Qay7JviGUEvy_2IsbqVkGe4qbMUClrmIaPzbcQBwvrzw_ur3X8kf4dwgMn0QqguwiIZ4QuGScfBiiO7wXQXBgsC_V1QUz3crTgbdJ6iQZbKM8yNmVic6Qg8_M6ThyEuKahMVYMhLE2RAbz7lsWtLLBy82FzTUU25Vq9pisvl3ayV_m8CD4Ix1Y0gEhaNi-WDY8cYCjy6sxvUap37vEJfLtALSk3u88Y3SJOspta9nhhxaCdMnY5ks0v2hgxxEoyAhERWP4fmdOrNGJcj4moJkxfK1c5MIGknK4P06ppzUMlvjOrQ9Oh7JcENM1iIRvMDNIXaookBTEpb6DkTmsj-R9P5ggbbXzFvdSlCUkWgkWvk72iQiZ6aQ1FvdHrkZwukS49fUOfjpgTms9poin_aVpoJhMIhkbg-WOUC1-a-5rj59QVOdbH_7EOguFUB-IVC-i94mWBk3MGaFrWuCgbl_MpGH4acp0y-7E7g"
      }
    });

    if (response.data.success) {
      setData(response.data.data);
    } else {
      setData([]);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

  const [formData, setFormData] = useState({
    age: "",
    gender: "",
    selects: "Categories",
    states: "",
    eligbility: "",
    area_of_residence: "",
    belong: "",
    abled: "",
    student: "",
    current_employment: "",
    government_employee: "",
    occupation: "",
    bpl_type: "",
    minority: "",
    abled_percentage: "",
    following_condition: "",
    annual_income: "",
    guardian_annual_income: "",
  });

useEffect(()=>{
if(step===0){
  setStep(1);
}else{
  validateStep(step);
}
},[formData])


useEffect(() => {
  if (!formData.selects) {
    setFormData({
      ...formData,
      selects: "Categories",
    });
  }
}, [formData.selects]);


  const handleChangeState = (e) => {
    if (e) {
      setFormData({
        ...formData,
        states: e,
      });
      const list = States.filter((list) => list.name === e);
      setCities(
        City.getCitiesOfState(
          list[0].countryCode.toString(),
          list[0].isoCode.toString()
        )
      );
      setErrors({
        ...errors,
        states: "",
      });
    } else {
      setFormData({
        ...formData,
        states: "",
      });
      setErrors({
        ...errors,
        states: "This field is required",
      });
    }
    const list = States.filter((list) => list.name === e);
    setCities(
      City.getCitiesOfState(
        list[0].countryCode.toString(),
        list[0].isoCode.toString()
      )
    );
  };

  const handleInputChange = (e) => {console.log(e)
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
       validateStep(step);

    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   [name]: "",
    // }));
  };
  const onbtnClick = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateStep = (step) => {
    const errors = {};
    const digitRegex = /\d/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    switch (step) {
      case 1:

        if (formData.age?.trim() === "") {
          errors.age = "This field is required.";
        } else if (formData.age.length > 2) {
          errors.age = "Number should be less then 3 digit";
        } else {
          delete errors.age;
        }

        if (formData.gender?.trim() === "") {
          errors.gender = "This field is required.";
        } else {
          delete errors.gender;
        }

        if (formData.area_of_residence?.trim() === "") {
          errors.area_of_residence = "This field is required.";
        } else {
          delete errors.area_of_residence;
        }

        if (formData.belong?.trim() === "") {
          errors.belong = "This field is required.";
        } else {
          delete errors.belong;
        }

        if (formData.abled?.trim() === "") {
          errors.abled = "This field is required.";
        } else {
          delete errors.abled;
        }

        if (formData.minority?.trim() === "") {
          errors.minority = "This field is required.";
        } else {
          delete errors.minority;
        }
        
        if (formData.abled_percentage?.trim() === "") {
          errors.abled_percentage = "This field is required.";
        } else {
          delete errors.abled_percentage;
        }

        if (formData.annual_income?.trim() === "") {
          errors.annual_income = "This field is required.";
        } else {
          delete errors.annual_income;
        }
        
        if (formData.guardian_annual_income?.trim() === "") {
          errors.guardian_annual_income = "This field is required.";
        } else {
          delete errors.guardian_annual_income;
        }
        
        if (formData.student?.trim() === "") {
          errors.student = "This field is required.";
        } else {
          delete errors.student;
        }

        if (formData.current_employment?.trim() === "") {
          errors.current_employment = "This field is required.";
        } else {
          delete errors.current_employment;
        }

        if (formData.government_employee?.trim() === "") {
          errors.government_employee = "This field is required.";
        } else {
          delete errors.government_employee;
        }

        if (formData.bpl_type?.trim() === "") {
          errors.bpl_type = "This field is required.";
        } else {
          delete errors.bpl_type;
        }

        if (formData.following_condition?.trim() === "") {
          errors.following_condition = "This field is required.";
        } else {
          delete errors.following_condition;
        }
        
        if (formData.occupation?.trim() === "") {
          errors.occupation = "This field is required.";
        } else {
          delete errors.occupation;
        }

        if (formData.states?.trim() === "") {
          errors.states = "This field is required.";
        } else {
          delete errors.states;
        }

        break;

       default:
        break;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Axios.post("https://api.onlinesaathi.org/api/v1/user/search/scheme/data", formData, {
      headers: {
        Authorization: "Bearer " + "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMGYzMWUzYWZiNThiZDI5ZGM2N2U3MDI5M2JkNzgzMzE0YWZjOGQ2ODUxMTQzMGM4MjU0MGJlMzhlYzIzYzFkYTExMWViNjljZDdjZTE0N2IiLCJpYXQiOjE3MDI2Mzk4NzUuNDUzNzQxMDczNjA4Mzk4NDM3NSwibmJmIjoxNzAyNjM5ODc1LjQ1Mzc0NjA4MDM5ODU1OTU3MDMxMjUsImV4cCI6MTczNDI2MjI3NS40NDg1MzkwMTg2MzA5ODE0NDUzMTI1LCJzdWIiOiIxNjQwOCIsInNjb3BlcyI6W119.o9uC8F7hxZeKG-yrKDffuOynkwZwoYXanSanjQRcTxTsp5iQYNNbvIlJ1UZ5Ujzeh2SQupfCh-sGuyAqYzadYoeJJE5k4i2ugwqUtAoGKeGhwlZDBPypxat9xA5gmdwDhaKAv-gsfTMXNbGSyFJPfj-VSgE6UYlee-kToOrX3Zzt-YZEgBXXX3YJ8hnhO1-xhiGY1f4Qay7JviGUEvy_2IsbqVkGe4qbMUClrmIaPzbcQBwvrzw_ur3X8kf4dwgMn0QqguwiIZ4QuGScfBiiO7wXQXBgsC_V1QUz3crTgbdJ6iQZbKM8yNmVic6Qg8_M6ThyEuKahMVYMhLE2RAbz7lsWtLLBy82FzTUU25Vq9pisvl3ayV_m8CD4Ix1Y0gEhaNi-WDY8cYCjy6sxvUap37vEJfLtALSk3u88Y3SJOspta9nhhxaCdMnY5ks0v2hgxxEoyAhERWP4fmdOrNGJcj4moJkxfK1c5MIGknK4P06ppzUMlvjOrQ9Oh7JcENM1iIRvMDNIXaookBTEpb6DkTmsj-R9P5ggbbXzFvdSlCUkWgkWvk72iQiZ6aQ1FvdHrkZwukS49fUOfjpgTms9poin_aVpoJhMIhkbg-WOUC1-a-5rj59QVOdbH_7EOguFUB-IVC-i94mWBk3MGaFrWuCgbl_MpGH4acp0y-7E7g"
      },
    })
    .then(response => {
      alert("Form Submitted Successfully")
      console.log(response);
      console.log("formdataaaa", formData, response)
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
  };

  return (
    <div className=''>
       <Userheader />
      {/* <div className="the-loader">
      <div className="spinner-border">
          <span className="sr-only">Loading...</span>
      </div>
  </div> */}
      <section className="main-sidebar_dash">
        <div className="container-fluid">
          <div className="row">
            <Usersidebar />
            <div className="col-md-10 right_contentsec">
              <div className="maine-table-dash-sec">
                <div className="heading-title-sec">
                </div>
                <div className="container">
                  <div>
                        <h1>hiiiiiii</h1>
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </section>
      <Userfooter />
    </div>

  )
}

export default AllSchemes;