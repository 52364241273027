import React from "react";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import { Modal } from "reactstrap";
import ReceiptModel from "./ReceiptModel";
import swal from 'sweetalert';
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Addmoney extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            add:false,
            deposit_via:"bank",
            amount:"",
            deposit_date:"",
            bank_name:"",
            image:null,
            images:null,
            transaction_number:"",
            details:[],
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }

    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");

        }
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
      
 
       
    }
    handleImage = (e, name) => {
        this.setState({
            [name]: e.target.files[0]
        })
    }
    
    clearForms = () => {
        this.setState({
            deposit_date:"",
            amount:"",
            bank_name:"",
            image:"",
            transaction_number:""
        })
    }

    submit_report = e => {
        e.preventDefault();
       
     
        if (this.state.amount.length === 0) {
            swal("", "Amount grater than 0.", "error");
            return false;
        }
        if (this.state.transaction_number === "") {
            swal("", "Please enter transaction number.", "error");
            return false;
        }
        
        if (this.state.deposit_date === "") {
            swal("", "Please select deposit date.", "error");
            return false;
        }
        if (this.state.bank_name === "") {
            swal("", "Please enter bank name.", "error");
            return false;
        }
        if (this.state.images === "") {
            swal("", "Please  select image.", "error");
            return false;
        }
       
     
        this.setState({ loading: true })

        var formdata = new FormData();
        formdata.append("deposit_via", this.state.deposit_via);
        formdata.append("amount", this.state.amount);
        formdata.append("deposit_date", this.state.deposit_date);
        formdata.append("bank_name", this.state.bank_name);
        formdata.append("transaction_id", this.state.transaction_number);
        
        formdata.append("image", this.state.images);
       
      
        Axios.post(`${apiUrl}/user/add/money`, formdata, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            // console.log("send transaction response", res)
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.setState({
                    deposit_date:"",
                    amount:"",
                    bank_name:"",
                    image:"",
                    transaction_number:""
                  
                })
               
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            swal("", err.message, "error");
            // swal("", "Session expired. Please login again.").then(o => {
            //  //   sessionStorage.clear();
            // //    this.props.history.push("/");
            // })
        })
    }
    
 
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
   
  
   
    render() {
        return <>
            <Userheader user={this.state.user} />
            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="maine-table-dash-sec">

                          
                             <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p>Send Add Money Request </p>
                                       
                                    </h3>
                                </div>
                              <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
             
                                            <label >Amount</label>
                                            <input type="number" className="form-control" placeholder="Enter  Amount" onChange={e => { this.handleInput(e) }} name="amount" value={this.state.amount}  />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="">
                                            
                                            <label >Transaction Number</label>
                                            <input type="text" className="form-control" placeholder="Enter  Transaction Number" onChange={e => { this.handleInput(e) }} name="transaction_number" value={this.state.transaction_number}  />
                                         </div>
                                    </div> 
                                    
                                    <div className="col-md-6">
                                        <div className="">
                                            
                                            <label >Bank Name</label>
                                            <input type="text" className="form-control" placeholder="Enter  Bank Name" onChange={e => { this.handleInput(e) }} name="bank_name" value={this.state.bank_name}  />
                                         </div>
                                    </div> 
                                 
                                    <div className="col-md-6">
                                        <div className="">
                                            
                                            <label >Deposit Date</label>
                                            <input type="date" className="form-control" onChange={e => { this.handleInput(e) }} name="deposit_date"  value={this.state.deposit_date}  />
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="">
                                            
                                            <label >Image</label>
                                            <input type="file"  className="form-control" onChange={e => { this.handleImage(e,'images') }} name="image"   />
                                    
                                        </div>
                                    </div> 
                                    
                                   
                                    <div className="col-md-12">
                                       
                                        <div className="send-bt-dash">

                                          
                                            <a href="javascript:void(0)" onClick={e => { this.submit_report(e) }}>Send</a>
                                        </div>
                                    </div>
                                </div>
                                
                        
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <Userfooter />
        </>;
    }
}
export default Addmoney;

