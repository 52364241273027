import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import { Pagination } from 'react-laravel-paginex';
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Ncellpack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: [],
            product_code: "",
            name: "",
            amount: "",
            headline: "",
            first_view: "",
            description: "",
            filter: "",
            show_form: false,
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 15,
                prev_page_url: null,
                to: 0,
                total: 0
            },
        }
    }
    componentDidMount = () => {
        this.getAlldetails();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onFileChange = (e) => {
        this.setState({ banner_image: e.target.files[0] })
    }

    saveBanner = e => {
        e.preventDefault();

        if (this.state.product_code === "") {
            this.notify("error", "Please enter product code.");
            return false;
        }
        if (this.state.name === "") {
            this.notify("error", "Please enter package name.");
            return false;
        }
        if (this.state.amount === "") {
            this.notify("error", "Please enter price.");
            return false;
        }
        if (this.state.headline === "") {
            this.notify("error", "Please enter headline.");
            return false;
        }
        if (this.state.first_view === "") {
            this.notify("error", "Please enter first view.");
            return false;
        }
        if (this.state.filter === "") {
            this.notify("error", "Please select  service type.");
            return false;
        }


        const formData = new FormData();
        formData.append('product_code', this.state.product_code);
        formData.append('name', this.state.name);
        formData.append('amount', this.state.amount);
        formData.append('headline', this.state.headline);
        formData.append('first_view', this.state.first_view);
        formData.append('description', this.state.description);
        formData.append('filter', this.state.filter);

        Axios.post(`${apiUrl}/admin/add/ncell/data`, formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.getAlldetails()
                this.setState({
                    show_form: !this.state.show_form,
                    product_code: "",
                    name: "",
                    amount: "",
                    headline: "",
                    first_view: "",
                    description: "",
                    filter: "",

                })
            } else {
                this.notify("error", res.data.message);
            }
        })
    }
    changePage = data => {
        console.log(data)
        this.getAlldetails(data.page);
    }
    getAlldetails = (page = 1, keyword = null) => {
        Axios.get(`${apiUrl}/admin/ncell/data/pack?page=${page}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("details response", res)
            if (res.data.success) {
                this.setState({
                    details: res.data.data.data,
                    paginatedata: res.data.data,
                })
            }
            else {
                this.setState({
                    details: []
                })
            }
        })
    }
    deletedata = (e, id) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('id', id);

        Axios.post(`${apiUrl}/admin/delete/ncell/data`, formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.notify("success", res.data.message);
            this.getAlldetails(this.state.paginatedata.current_page)
        }, err => {
            console.log(err)
        })
    }
    showForm = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: !this.state.show_form,
            product_code: "",
            name: "",
            amount: "",
            headline: "",
            first_view: "",
            description: "",
            filter: "",
        })
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">NCELL DATA/VOICE PACK
                                        {!this.state.show_form && <small className="float-right"><a href="" onClick={(e) => { this.showForm(e) }} >Add New Pack</a></small>}
                                    </h3>
                                </CardHeader>
                                <CardBody>
                                    {this.state.show_form && <Form onSubmit={this.saveBanner}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Product Code </label>
                                                    <input className="form-control" name="product_code" placeholder="Enter product code " value={this.state.product_code} onChange={(e) => { this.inputChange(e) }}>

                                                    </input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Package Name </label>
                                                    <input className="form-control" placeholder="Enter package name" name="name" value={this.state.name} onChange={(e) => { this.inputChange(e) }}>

                                                    </input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Amount </label>
                                                    <input type="number" className="form-control" placeholder="Enter amount" name="amount" value={this.state.amount} onChange={(e) => { this.inputChange(e) }}>

                                                    </input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Headline </label>
                                                    <input className="form-control" placeholder="Enter headline" name="headline" value={this.state.headline} onChange={(e) => { this.inputChange(e) }}>

                                                    </input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>First View </label>
                                                    <input className="form-control" placeholder="Enter first view" name="first_view" value={this.state.first_view} onChange={(e) => { this.inputChange(e) }}>

                                                    </input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Description </label>
                                                    <input className="form-control" placeholder="Enter description" name="description" value={this.state.description} onChange={(e) => { this.inputChange(e) }}>

                                                    </input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Type </label>
                                                    <select className="form-control" placeholder="" name="filter" value={this.state.filter} onChange={(e) => { this.inputChange(e) }}>
                                                        <option value="" >Select </option>
                                                        <option value="ncell_data_service" >Ncell Data </option>
                                                        <option value="ncell_voice_service" >Ncell Voice </option>

                                                    </select>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                            Cancel
                                        </Button>
                                        <Button className="btn-fill" color="primary" type="submit">
                                            Save
                                        </Button>
                                    </Form>}
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>Product Code</th>
                                                <th>Package Name</th>
                                                <th>Amount</th>
                                                <th>Headline</th>
                                                <th>First View</th>
                                                <th>Description</th>
                                                <th>Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.details.map((val, key) => {
                                                var sr = (this.state.paginatedata.per_page * (this.state.paginatedata.current_page - 1)) + key + 1;
                                                return <tr key={key}>
                                                    <td>{sr}</td>
                                                    <td>{val.product_code}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.amount}</td>
                                                    <td>{val.headline}</td>
                                                    <td>{val.first_view}</td>
                                                    <td>{val.description}</td>
                                                    <td>{val.filter}</td>

                                                    <td>
                                                        <a href="" onClick={(e) => { this.deletedata(e, val.id) }} >
                                                            <i className="tim-icons icon-simple-remove" title="delete data"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                        <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                    </Table>
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Ncellpack;
