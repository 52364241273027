import React from "react";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import { Modal } from "reactstrap";
import ReceiptModel from "./ReceiptModel";
import swal from 'sweetalert';
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Nepalrechargehistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: false,
       
            history: [],
           

           
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }

    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");

        }
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
      
        this.getData();
       
       
    }

    toggleModal = (e = null,) => {
        this.setState({
            modalState: !this.state.modalState,
        })
    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
   
    getData = (page = 1) => {
        var data = {};
        Axios.post(`${apiUrl}/user/recharge/history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res)
            this.setState({
                history: res.data.data.data,
                pagination_data: res.data.data
            })
        },err=>{
            swal("","Session expired. Please login again.").then(o=>{
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }

    
    
   
    toggleModifyModal = e => {
        this.setState({
            modify_modal: !this.state.modify_modal
        })
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
  
   
  

    render() {
        return <>
            <Userheader user={this.state.user} />
            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="maine-table-dash-sec">
                                <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p> Nepal Recharge History </p>
                                        <span>{`Showing ${this.state.pagination_data.from} to ${this.state.pagination_data.to} from ${this.state.pagination_data.total} records`}</span>
                                    </h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Order Id</th>
                                                <th>Transaction Id</th>
                                                <th>Coustomer Id</th>
                                                <th>Category Name</th>
                                                <th>Provider Name</th>
                                                <th>Amount</th>
                                               
                                                <th>Status</th>
                                              
                                            </tr>
                                        </thead>
                                        <tbody className="table-space">
                                            {this.state.history.map((hs, key) => {
                                                return <tr>
                                                <td>{moment(hs.created_at).calendar()}</td>
                                                <td>{hs.order_id}</td>
                                                <td>{hs.transaction_id}</td>
                                                <td>{hs.phone}</td>
                                                
                                                <td>{hs.category.category_name}</td>
                                                <td>{hs.service_provider.provider_name}</td>
                                                <td><i className="fa fa-inr" aria-hidden="true"></i>{(+hs.amount)}</td>
                                        
                                              
                                                <td className="status-dash "><span >{hs.status}</span></td>
                                           
                                            </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination pagination_data={this.state.pagination_data} getData={this.getData} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ReceiptModel modalState={this.state.modalState} toggleModal={this.toggleModal} details={this.state.remittance} sender={this.state.sender} receiver={this.state.receiver} transaction={this.state.transaction} />
           
            <Userfooter />
        </>;
    }
}
export default Nepalrechargehistory;

