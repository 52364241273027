import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Table,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
var apiUrl = env.url;
class UserAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            details:[],
        }
    }
    componentDidMount = () => {
         this.getAllPage();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    getAllPage = () => {
        Axios.get(`${apiUrl}/admin/get/useraccesses`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("users response", res)
            if (res.data.success) {
                this.setState({
                    details: res.data.data.permission
                })
            } else {
                this.setState({
                    details: []
                })
            }
        })
    }
    givepermission = (e, id,type) => {

        Axios.post(`${apiUrl}/admin/give-access-user`, { id: id, type: type }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("pagelist response", res)
            if (res.data.success) {
                this.notify('success', res.data.message)
                this.getAllPage();
            } else {
                this.notify('error', res.data.message)
                this.getAllPage();
            }
        }, err => {
            console.log(err)
        })
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    render() {
        return (
            <>
                {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">User Access Control</h3>
                                </CardHeader>
                                <CardBody>

                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Sr.</th>
                                                <th>Page Name</th>
                                                <th>User</th>
                                                <th>Member</th>
                                                <th>Saathi</th>
                                                <th>Partner</th>
                                                <th>State Partner</th>
                                                {/* <th>Distributor</th> */}
                                                {/* <th>Agent</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.details.map((val, key) => {

                                         return <tr key={key}>
                                                 <td>{key +1}</td>
                                                <td>{val.name}</td>
                                                <td><input type="checkbox" onChange={(e) => { this.givepermission(e, val.id,'user') }} defaultChecked={val.user == "yes"} /> </td>
                                                <td><input type="checkbox" onChange={(e) => { this.givepermission(e, val.id,'member') }} defaultChecked={val.member == "yes"} /> </td>
                                                <td><input type="checkbox" onChange={(e) => { this.givepermission(e, val.id,'saathi') }} defaultChecked={val.saathi == "yes"} /> </td>
                                                <td><input type="checkbox" onChange={(e) => { this.givepermission(e, val.id,'partner') }} defaultChecked={val.partner == "yes"} /> </td>
                                                <td><input type="checkbox" onChange={(e) => { this.givepermission(e, val.id,'state_partner') }} defaultChecked={val.state_partner == "yes"} /> </td>
                                                {/* <td><input type="checkbox" onChange={(e) => { this.givepermission(e, val.id,'distributor') }} defaultChecked={val.distributor == "yes"} /> </td>
                                                <td><input type="checkbox" onChange={(e) => { this.givepermission(e, val.id,'agent') }} defaultChecked={val.agent == "yes"} /> </td> */}
                                            </tr>
                                        })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default UserAccess;
