import React from "react";

class Pagination extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var arr = [];
        var cp = this.props.pagination_data.current_page;
        for (let i = 1; i <= this.props.pagination_data.last_page; i++) {
            arr.push(i);
        }
        return <ul className="pagination">

            {cp > 1 && <li className="page-item" onClick={() => { this.props.getData(cp - 1) }} style={{ cursor: "pointer" }}>
                <a className="page-link" href="" onClick={() => { this.props.getData(cp - 1) }} style={{ cursor: "pointer" }}>
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                </a>
            </li>}
            {cp === 1 && <li className="page-item disabled" style={{ cursor: "not-allowed" }}>
                <a className="page-link" href="" style={{ cursor: "not-allowed" }}>
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                </a>
            </li>}
            {arr.map((ar, key) => {
                if (cp === ar) {
                    return <li className="page-item active" key={key} style={{ cursor: "not-allowed" }}>
                        <a className="page-link" href="" style={{ cursor: "not-allowed" }}>{ar}</a>
                    </li>
                } else {
                    return <li className="page-item" key={key} onClick={() => { this.props.getData(ar) }} style={{ cursor: "pointer" }}>
                        <a className="page-link" href="" onClick={() => { this.props.getData(ar) }}>{ar}</a>
                    </li>
                }
            })}

            {cp !== this.props.pagination_data.last_page && <li className="page-item" onClick={() => { this.props.getData(cp + 1) }} style={{ cursor: "pointer" }}>
                <a className="page-link" href="" onClick={() => { this.props.getData(cp + 1) }} style={{ cursor: "pointer" }}>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
            </li>}
            {cp === this.props.pagination_data.last_page && <li className="page-item disabled" style={{ cursor: "not-allowed" }}>
                <a className="page-link" href="" style={{ cursor: "not-allowed" }}>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
            </li>}
        </ul>
    }
}
export default Pagination;