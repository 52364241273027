import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";


const moment = require('moment');
var apiUrl = env.url;
var imageUrl = env.img_url;
class PaidServicesRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            user_req: [],
            selected_request: null,
            service_modal: false,
            settings: false,
            ps_list: [],
            selected_service: null,
            edit_service_modal: false
        }
    }
    componentDidMount = () => {
        this.getList();
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    getList = () => {
        Axios.get(`${apiUrl}/admin/get/paid/service/requests`, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
        }).then(res => {
            if (res.data.success) {
                this.setState({
                    list: res.data.list
                })
            }
        }, err => {
            console.log("addmoney error", err)
        })
    }

    toggleServiceModal = () => {
        this.setState({
            service_modal: !this.state.service_modal
        })
    }

    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    handleRequest = (e, val) => {
        if (e) e.preventDefault();
        this.setState({
            selected_request: val,
            service_modal: true,
            user_req: val.psr
        });
    }
    handleDiscount = (e, key) => {
        var x = this.state.user_req;
        if (x[key].amount < +e.target.value) {
            x[key].discount = 0;
            x[key].paid_amount = x[key].amount;
            this.setState({ user_req: x });
            this.notify("error", "Discount can not be more then price.");
            return false;
        }
        x[key].discount = +e.target.value;
        x[key].paid_amount = x[key].amount - +e.target.value;
        this.setState({ user_req: x });
    }
    handleCheckBox = (e, key) => {
        var x = this.state.user_req;
        x[key].checked = e.target.checked;
        x[key].discount = 0;
        x[key].paid_amount = e.target.checked ? x[key].amount : 0;
        this.setState({ user_req: x });
    }
    handleRadio = (e, type) => {
        this.setState({ payment_method: type });
    }
    submitRequest = e => {
        var req = this.state.user_req;
        var data = [];
        var user_id = null;
        req.forEach((re) => {
            if (re.checked) {
                user_id = re.user_id;
                data.push({
                    user_id: re.user_id,
                    req_id: re.id,
                    discount: re.discount && re.discount !== '' ? +re.discount : 0,
                    paid_amount: re.paid_amount
                })
            }
        });
        console.log("data", data)
        Axios.post(apiUrl + "/admin/handle/paid/service/request",
            {
                data: data,
                payment_method: this.state.payment_method,
                user_id
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access"),
                    Accept: 'application/json'
                }
            }).then(res => {
                if (res.data.success) {
                    this.notify("success", res.data.message);
                    this.setState({
                        list: [],
                        user_req: [],
                        selected_request: null,
                        service_modal: false,
                    });
                    this.getList();
                }
                else {
                    this.notify('error', res.data.message);
                }
            })
        // console.log(this.state.user_req)
    }
    settingsButtonOn = e => {
        e.preventDefault();
        this.getAllPaidService();
        this.setState({
            settings: true
        })
    }
    settingsButtonOff = e => {
        e.preventDefault();
        this.setState({
            settings: false
        })
    }
    getAllPaidService = e => {
        Axios.get(apiUrl + "/admin/get/all/paid/services", {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access"),
                Accept: 'application/json'
            }
        }).then(res => {
            this.setState({
                ps_list: res.data.list
            })
        })
    }
    editService = (e, service) => {
        e.preventDefault();
        this.setState({ edit_service_modal: true, selected_service: service })
    }
    closeEditService = e => {
        if (e) e.preventDefault();
        this.setState({ edit_service_modal: false, selected_service: null })

    }
    updateSettings = e => {
        var data = this.state.selected_service;
        if (data.service === "") {
            this.notify("error", "Please enter service name.");
            return false;
        }
        if (data.amount === "") {
            this.notify("error", "Please enter amount.");
            return false;
        }

        Axios.post(apiUrl + "/admin/update/paid/service", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access"),
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    edit_service_modal: false,
                    selected_service: null
                });
                this.getAllPaidService();
            }
            else {
                this.notify("erroe", res.data.message);
            }
        })
    }
    handleInputEdit = (e, key) => {
        var data = this.state.selected_service;
        data[key] = e.target.value;
        this.setState({ selected_service: data })
    }
    render() {
        var total_discount = 0;
        var total_amount = 0;
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            {this.state.settings && <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Paid Services Settings
                                        <small className="float-right">
                                            <a href="#" onClick={e => { this.settingsButtonOff(e) }}>
                                                <i className="fa fa-long-arrow-left"></i>
                                            </a>
                                        </small>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Is Paid</th>
                                                <th>Status</th>
                                                <th>Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.ps_list.map((val, key) => {
                                                return <tr key={key} >
                                                    <td>{key + 1}</td>
                                                    <td>{val.service}</td>
                                                    <td><BadgeText text={val.type} /></td>
                                                    <td><Status status={val.status} /></td>
                                                    <td>&#8377;{val.amount}</td>
                                                    <td>
                                                        <a href="#" onClick={e => { this.editService(e, val) }}>
                                                            <i className="fa fa-edit"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>}
                            {!this.state.settings && <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Paid Services Requests
                                        <small className="float-right">
                                            <a href="#" onClick={e => { this.settingsButtonOn(e) }}>
                                                <i className="fa fa-gear"></i>
                                            </a>
                                        </small>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Services Requested</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.list.map((val, key) => {
                                                console.log(val.psr[0].created_at)
                                                return <tr key={key} >
                                                    <td>{key + 1}</td>
                                                    <td>{moment(val.psr[0].created_at).format('lll')}</td>
                                                    <td>{val.name}&nbsp;<a href="" onClick={e => { e.preventDefault() }} title={val.status.toUpperCase()}><i className="fa fa-info-circle"></i></a></td>
                                                    <td>{val.mobile}</td>
                                                    <td><ServiceRequested services={val.psr} /> </td>
                                                    <td><a href="#" onClick={e => { this.handleRequest(e, val) }}><i className="fa fa-eye"></i></a> </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>}
                        </Col>
                    </Row>
                    <Modal size="lg" isOpen={this.state.service_modal} toggle={this.toggleServiceModal} backdrop="static" keyboard={false}>
                        <ModalHeader className="reject-popup" toggle={this.toggleServiceModal}>Paid Request</ModalHeader>
                        <ModalBody>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Service</th>
                                            <th>Price (One Time)</th>
                                            <th>Select</th>
                                            <th>Discount (in &#8377;)</th>
                                            <th>Final Price</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-space">
                                        {this.state.user_req.map((ser, k) => {
                                            var discount = ser.discount ? ser.discount : 0;
                                            total_discount += +discount;
                                            var paid_amount = ser.paid_amount ? +ser.paid_amount : (ser.checkd ? +ser.amount : 0);
                                            total_amount += paid_amount;
                                            return <tr key={k}>
                                                <td>{k + 1}.</td>
                                                <td>{ser.paid_service.service}</td>
                                                <td>&#8377;{ser.amount.toFixed(2)}</td>
                                                <td>
                                                    &nbsp;<Input type="checkbox" onClick={e => { this.handleCheckBox(e, k) }} />
                                                </td>
                                                <td>
                                                    <Input type="number" onChange={e => { this.handleDiscount(e, k) }} value={this.state.user_req[k].discount !== 0 ? this.state.user_req[k].discount : ""} disabled={!ser.checked} />
                                                </td>
                                                <td>&#8377;{this.state.user_req[k].paid_amount.toFixed(2)}</td>
                                            </tr>
                                        })}
                                        <tr>
                                            <td colSpan="5"><h5 className="float-right">Total Discount:</h5></td>
                                            <td><h5 className="text-dark float-right">&#8377;{total_discount.toFixed(2)}</h5></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5"><h5 className="float-right">Total Amount:</h5></td>
                                            <td><h5 className="text-dark float-right">&#8377;{total_amount.toFixed(2)}</h5></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2"><span className="float-left"><b>Payment Method </b></span></td>
                                            <td colSpan="2"><div className="form-inline"><label>Cash: <Input type="radio" onClick={e => { this.handleRadio(e, 'cash') }} name="payment_method" disabled={total_amount === 0} /></label></div></td>
                                            <td colSpan="2"><div className="form-inline"><label>From Wallet (&#8377;{this.state.selected_request && this.state.selected_request.remit_balance}): <Input type="radio" onClick={e => { this.handleRadio(e, 'wallet') }} name="payment_method" disabled={total_amount === 0} /></label>
                                            </div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="primary" onClick={() => { this.submitRequest() }}>Submit</Button>{' '}
                            {/* <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
                        </ModalFooter>
                    </Modal>
                    <Modal size="lg" isOpen={this.state.edit_service_modal} toggle={this.closeEditService} backdrop="static" keyboard={false}>
                        <ModalHeader className="reject-popup" toggle={this.closeEditService}>Edit Paid service</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md="6">
                                    <div className="form-group">
                                        <label>Service <a href="#" title="This setting is applied on the specific page. please don't change this name to something to create confusion for users."><i className="fa fa-info-circle"></i></a></label>
                                        <Input type="text" onChange={e => { this.handleInputEdit(e, 'service') }} name="service" value={this.state.selected_service ? this.state.selected_service.service : ""} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div className="form-group">
                                        <label>Amount (in &#8377;)</label>
                                        <Input type="number" onChange={e => { this.handleInputEdit(e, 'amount') }} name="amount" value={this.state.selected_service ? this.state.selected_service.amount : ""} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <select name="status" onChange={e => { this.handleInputEdit(e, 'status') }} className="form-control" value={this.state.selected_service ? this.state.selected_service.status : ""}>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <select name="type" onChange={e => { this.handleInputEdit(e, 'type') }} className="form-control" value={this.state.selected_service ? this.state.selected_service.type : ""}>
                                            <option value="paid">Paid</option>
                                            <option value="free">Free</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="primary" onClick={() => { this.updateSettings() }}>Submit</Button>{' '}
                            <Button color="secondary" onClick={this.closeEditService}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}

export default PaidServicesRequests;

function ServiceRequested(props) {
    return props.services.map((ser, k) => {
        return <span className="badge badge-dark">{ser.paid_service.service}</span>
    })
}
function Status(props) {
    return <span className={props.status == 'active' ? "badge badge-success" : "badge badge-danger"}>{props.status.toUpperCase()}</span>
}
function BadgeText(props) {
    return <span className="badge badge-dark">{props.text.toUpperCase()}</span>
}
