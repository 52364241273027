const c_url = window.location.href;
const local_url = "http://localhost:8000";
const development_url = "https://i-sewa.com";
const live_url = "https://api.onlinesaathi.org";
// const base_url = c_url.includes('localhost') ? live_url : (c_url.includes('i-sewa') ? development_url : live_url);
const base_url =  c_url.includes('localhost') ? local_url : (c_url.includes('i-sewa') ? development_url : live_url);
//const base_url =  development_url;
export const env = {
    url: base_url + "/api/v1",
    img_url: base_url
}

export default env;
