import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import { Pagination } from 'react-laravel-paginex';
import moment from "moment";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Reportissue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            Reports: [],
            respond_modal: false,
            Report_name: "",
            commission: "",
            status: "",
            icon: "",
            id: "",
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            },
            short: 10,
            site_title: "Online Saathi Report Issue",
            selected_record: null,
            message: ""
        }
    }
    componentDidMount = () => {
        document.title = this.state.site_title
        this.getAllReports(1);
    }

    getAllReports = (page = 1) => {
        var data = { short: this.state.short };
        if (this.props.status) {
            data.status = this.props.status;
        }
        if (this.props.limit) {
            data.short = this.props.limit;
        }
        Axios.post(`${apiUrl}/admin/report-issue?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("data response", res)
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.data,
                    Reports: res.data.data.data
                })
            }
            else {
                this.setState({
                    Reports: []
                })
            }
        })
    }
    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleInputshort = e => {
        // this.setState({
        //     [e.target.name]: e.target.value
        // });
        sessionStorage.setItem("report_short", e.target.value);
        this.setState({ short: e.target.value }, () => { this.getAllReports(1) });

    }


    changePage = data => {
        console.log(data)
        this.getAllReports(data.page);
    }
    statusChangeReport = (e, id) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/admin/Report/change/status`, { Report_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.getAllReports(this.state.paginatedata.current_page);
                this.respond();
            }
            else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "Something went wrong");
            console.log("status error", err)
        })
    }


    notify = (type, message) => {
        console.log("this.refs.notificationAlert before", this.refs.notificationAlert)

        if (this.refs.notificationAlert && this.refs.notificationAlert.state.notifyTR.length <= 1) {
            var atype = type === "error" ? "danger" : type;
            var options = {};
            options = {
                place: process.env.REACT_APP_TOAST_POSITION,
                message: (
                    <div>
                        <div>
                            {message}
                        </div>
                    </div>
                ),
                type: atype,
                icon: "tim-icons icon-bell-55",
                autoDismiss: 7
            };
            this.refs.notificationAlert.notificationAlert(options);
            console.log("this.refs.notificationAlert", this.refs.notificationAlert)
        }
    };
    respond = (e, val = null) => {
        if (e) e.preventDefault();
        this.setState({
            respond_modal: !this.state.respond_modal,
            selected_record: val
        })
    }
    savenotification = (user_id) => {

        if (this.state.message == "") {
            this.notify("error", "Please enter message.");
            return false;
        }
        const formData = new FormData();
        formData.append('title', 'Complaint Response');
        formData.append('type', 'single');
        formData.append('user_id', user_id);
        formData.append('message', this.state.message);
        formData.append('status', "active");
        formData.append('special', "complaint");
        formData.append('complaint_id', this.state.selected_record.id);
        Axios.post(`${apiUrl}/admin/create-coustom-notification`, formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.respond();
            } else {
                this.notify("error", res.data.message);
            }
        })
    }
    sendReply = (e, id) => {
        if (e) e.preventDefault();
        if (this.state.message === "") {
            this.notify("error", "Please enter message.");
            return false;
        }
        this.savenotification(id);
    }
    render() {
        return (
            <>
                {!this.props.embed && <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">Report Issue</h3>

                                </CardHeader>
                                <CardBody>


                                    <ComplaintList {...this.state} respond={this.respond} />
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />

                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>}
                {this.props.embed && <ComplaintList {...this.state} respond={this.respond} />}
                <Modal isOpen={this.state.respond_modal} toggle={this.respond} size="lg">
                    <ModalHeader toggle={this.respond}>Respond to complaint</ModalHeader>
                    {this.state.selected_record && <ModalBody>
                        <h4>Name:{this.state.selected_record ? this.state.selected_record.user.name : "N/A"}</h4>
                        <h4>Mobile:{this.state.selected_record ? this.state.selected_record.user.mobile : "N/A"}</h4>
                        <h4>Description:{this.state.selected_record ? this.state.selected_record.description : "N/A"}</h4>
                        <div>
                            <label>Message</label>
                            <textarea name="message" className="form-control" onChange={this.inputChange}></textarea>
                        </div>
                        <a href="" className="btn btn-success" onClick={(e) => { this.statusChangeReport(e, this.state.selected_record.id) }} >
                            Mark Resolved
                        </a>
                        <a href="" className="btn btn-info ml-2" onClick={(e) => { this.sendReply(e, this.state.selected_record.user.id) }} >
                            Send Reply
                        </a>
                    </ModalBody>}
                </Modal>
            </>
        );
    }
}

export default Reportissue;

function ComplaintList(props) {
    var list = props.Reports;
    return <Table className="tablesorter" responsive>
        <thead className="text-primary">
            <tr>
                <th>#</th>
                <th>Date</th>
                <th>User Name</th>
                <th>Mobile</th>
                <th>Subject</th>
                <th>Reference Number</th>
                <th>Mode of transaction</th>
                <th>Amount</th>
                <th>Transaction Date</th>
                <th>Description</th>
                <th>Image</th>
                <th>Status</th>
                <th>Response Sent</th>

                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {list.map((val, key) => {
                return <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{moment(val.created_at).format('DD-MM-YYYY')}</td>
                    <td>{val.user.name ? val.user.name : "N/A"}</td>
                    <td>{val.user.mobile ? val.user.mobile : "N/A"}</td>
                    <td>{val.subject}</td>
                    <td>{val.reference_number}</td>
                    <td>{val.mod_of_transaction}</td>
                    {val.amount > 0 && <td>&#8377;{val.amount}</td>}
                    {val.amount === 0 && <td></td>}
                    <td>{val.transaction_date}</td>
                    <td>{val.description}</td>
                    <td>
                        {val.image && <> <a href={imageUrl + val.image} target="_blank" > <img src={imageUrl + val.image} alt=" " style={{ width: "50px" }} />  </a></>}
                    </td>
                    <td>{val.status}</td>
                    <td>{val.reply}</td>


                    <td>

                        {val.status === "pending" && <a href="" onClick={(e) => { props.respond(e, val) }} >
                            Respond
                        </a>}

                    </td>
                </tr>
            })}
        </tbody>
    </Table>
}
