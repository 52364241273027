import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
} from "reactstrap";
import Axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
import { Pagination } from 'react-laravel-paginex'
import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);


var cu = sessionStorage.getItem('cuser') ? Cryp.decrypt(sessionStorage.getItem('cuser')) : null;
const userids = [1];
const moment = require('moment');
var apiUrl = env.url;
var imageUrl = env.img_url;
class MemberList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            modal: false,
            selected_record: null,
            selected_user_id: null,
            start_date: "",
            comment:"",
            end_date: "",
            reason: "",
            excelusers:[],
            selected_id: "",
            keyword: "",
            sort_by: "all",
            rejectModal: false,
            imageModal: false,
            headers: {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access"),
                    Accept: "application/json"
                }
            },
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            },

        }
    }
    componentDidMount = () => {
        let search = this.props.history.location.search;
        let params = new URLSearchParams(search);
        let user_id = params.get('q');
        if (user_id) {
            this.setState({
                selected_user_id: +user_id
            })
        }
        setTimeout(() => {
            this.getList();
            this.getMemberExcelData()
        }, 100);

    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    getList = (page = 1, keyword) => {
        var data = { keyword: keyword ? keyword : this.state.keyword, sort_by: this.state.sort_by };
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.selected_user_id) {
            data.selected_user_id = this.state.selected_user_id;
        }
        Axios.post(apiUrl + "/admin/member/list?page=" + page, data, this.state.headers).then(res => {
            this.setState({
                list: res.data.list.data,
                paginatedata: res.data.list,
                selected_record: res.data.single,
                modal: res.data.single !== null
            });
        })
    }

    exportPDF = (page = 1, keyword = null) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 0;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "All Members";
        const headers = [[ "Date", "Application Number", "Membership ID", "Name", "Mobile", "Gender", "Comment", "Address", "Status"]];

        var data = { keyword: keyword ? keyword : this.state.keyword, sort_by: this.state.sort_by, forexcel: "all", };
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.selected_user_id) {
            data.selected_user_id = this.state.selected_user_id;
        }
        Axios.post(`${apiUrl}/admin/member/list?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            // console.log("users response", res)

                setTimeout(() => {
                      const data = res.data.list.map(elt=> [ moment(elt.created_at).format('DD-MM-YYYY h:mm a'), elt.application_number, elt.membership_id, elt.name, elt.mobile, elt.gender, elt.comment, elt.address, elt.status]);
                    let content = {
                        startY: 50,
                        head: headers,
                        body: data
                      };

                      doc.text(title, marginLeft, 10);
                      doc.autoTable(content);
                      doc.save("Member-List.pdf")
                }, 200);

        })
    }


    getMemberExcelData = (page = 1, keyword = null) => {
        var data = { keyword: keyword ? keyword : this.state.keyword, sort_by: this.state.sort_by, forexcel: "all", };
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.selected_user_id) {
            data.selected_user_id = this.state.selected_user_id;
        }
        Axios.post(`${apiUrl}/admin/member/list?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            // console.log("users response", res)
                var excelusers = [];
                res.data.list.forEach((usr, ki) => {
                    var xx = {
                        "DATE": moment(usr.created_at).format('lll'),
                        "Application Number": usr.application_number,
                        "Membership ID": usr.membership_id,
                        "Name": usr.name,
                        "Mobile": usr.mobile,
                        "Gender": usr.gender,
                        "Comment": usr.comment,
                        "Address": usr.address,
                        "Status": usr.status
                    };
                    excelusers.push(xx);
                })
                this.setState({
                    excelusers: excelusers
                })

        })
    }


    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    viewImage = (e, image) => {
        if (e) e.preventDefault();
        this.setState({
            selected_image: imageUrl + image,
            imageModal: true
        })
    }
    toggleImageModal = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            imageModal: !this.state.imageModal,
        })
    }
    toggle = (e, usr) => {
        if (e) e.preventDefault();
        console.log(usr)
        this.setState({
            modal: !this.state.modal,
            selected_record: usr
        })
    }
    rejectMember = e => {
        if (e) e.preventDefault();
        var data = {
            kyc_id: this.state.selected_record.id,
            comment:this.state.comment
        }
        Axios.post(apiUrl + "/admin/member/reject", data, this.state.headers).then(res => {
            console.log(res)
            this.notify(res.data.success ? "success" : "error", res.data.message);
            this.toggle();
            this.getList();
            // if (this.state.selected_user_id) {
            //     setTimeout(() => {
            //         window.location.href = "/admin/all-users"
            //     }, 2000);
            // }
        })
    }

    approveMember = e => {
        if (e) e.preventDefault();
        var data = {
            kyc_id: this.state.selected_record.id,
            comment:this.state.comment
        }
        Axios.post(apiUrl + "/admin/member/approve", data, this.state.headers).then(res => {
            this.notify(res.data.success ? "success" : "error", res.data.message);
            this.toggle();
            this.getList();
            // if (this.state.selected_user_id) {
            //     setTimeout(() => {
            //         window.location.href = "/admin/all-users"
            //     }, 2000);
            // }
        })
    }
    searchKyc = (e) => {
        var key = e.target.value
        this.setState({
            keyword: e.target.value
        })
        if (key.length > 2 || key === "") {
            this.getList(1, key);
        }
    }
    changePage = (page) => {
        console.log(page)
        this.getList(page.page)

    }
     handleError = (event) => {
        this.setState({ photo: "/uploads/static/anime3.png" });
    }
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <div class="row">
                                    <div className="col-md-7">
                                        <CardTitle tag="h4">Member List</CardTitle>
                                    </div>
                                    <div className="col-md-5">
                                                <small className="float-right excel-sec">
                                                <a style={{ background: "white", }} href="javascript::void(0)" onClick={() => this.exportPDF()}><i className="fa fa-file-pdf-o fa-2x">Pdf</i></a>

                                                    {this.state.excelusers.length > 0 && <CSVLink data={this.state.excelusers} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"Member-List-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}
                                                </small>

                                    </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="searchbox searchbox_nar">
                                                <input type="text" name="keyword" className="form-control" onChange={(e) => { this.searchKyc(e) }} value={this.state.keyword} placeholder="Search keyword" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Filter By</label>
                                            <select name="sort_by" className="form-control" onChange={e => { this.handleInput(e) }} value={this.state.sort_by}>
                                                <option value="">all</option>
                                                {/* <option value="users">Registered Users</option> */}
                                                {/* <option value="others">Not registered</option> */}
                                                <option value="pending">Approval Pending</option>
                                                <option value="approved">Approved</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" className="btn btn-primary mt-4" onClick={e => this.getList()}><i className="fa fa-search"></i> Search</button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th id="text-primary-tr-td">#</th>
                                                <th id="text-primary-tr-td">Date</th>
                                                <th id="text-primary-tr-td">Application Number</th>
                                                <th id="text-primary-tr-td">Membership ID</th>
                                                <th id="text-primary-tr-td">Name</th>
                                                <th id="text-primary-tr-td">Mobile No.</th>
                                                <th id="text-primary-tr-td">gender</th>
                                                <th id="text-primary-tr-td">Status</th>
                                                <th id="text-primary-tr-td">Comment</th>
                                                <th id="text-primary-tr-td">Address</th>
                                                <th id="text-primary-tr-td">Photo</th>
                                                <th id="text-primary-tr-td">Proof</th>
                                                <th id="text-primary-tr-td">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.list.map((usr, k) => {
                                                return <tr>
                                                    <td>{this.state.paginatedata.per_page * (this.state.paginatedata.current_page - 1) + k + 1}. </td>
                                                    <td>{moment(usr.created_at).format('DD-MM-YYYY h:mm a')}</td>
                                                    <td>{usr.application_number}</td>
                                                    <td>{usr.membership_id}</td>
                                                    <td>{usr.name}</td>
                                                    <td>{usr.mobile}</td>
                                                    <td>{usr.gender}</td>
                                                    <td>{usr.status}</td>
                                                    <td>{usr.comment}</td>
                                                    <td>{usr.address}</td>
                                                    <td>{usr.photo && <a href="" onClick={e => { this.viewImage(e, usr.photo) }}><img src={imageUrl + usr.photo} alt="" /></a>}</td>
                                                    <td>{usr.proof && <a href="" onClick={e => { this.viewImage(e, usr.proof) }}><img src={imageUrl + usr.proof} alt="" /></a>}</td>
                                                    <td>
                                                        <a href="" onClick={e => { this.toggle(e, usr) }}><i className="fa fa-eye" /></a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                           <div>
                            <ModalHeader className="reject-popup" toggle={this.toggle}> Member details                                                                                                           
                                    {this.state.selected_record && this.state.selected_record.status !== "pending" && <span className="badge badge-primary ml-2">{this.state.selected_record.status.toUpperCase()}</span>}
                                </ModalHeader>
                           </div>
                            <div className="block-acccount-button-div">
                                    <button className="block-acccount-button">Block Account</button> 
                            </div>
                        </div>
                        <ModalBody>
                            <div className="view-popup">

                                {this.state.selected_record && <Row className="startedformsec" >

                                        {/* <Col md="4">
                                            <FormGroup>
                                                <label>Name:</label>
                                                <h4>{this.state.selected_record.name}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Application Number</label>
                                                <h4>{this.state.selected_record.application_number}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Membership ID</label>
                                                <h4>{this.state.selected_record.membership_id}</h4>
                                            </FormGroup>
                                        </Col>
                                         <Col md="4">
                                            <FormGroup>
                                                <label>Receipt Number</label>
                                                <h4>{this.state.selected_record.receipt_number}</h4>
                                            </FormGroup>
                                        </Col>
                                         <Col md="4">
                                            <FormGroup>
                                                <label>Mobile</label>
                                                <h4>{this.state.selected_record.mobile}</h4>
                                            </FormGroup>
                                        </Col>
                                         <Col md="4">
                                            <FormGroup>
                                                <label>Gender</label>
                                                <h4>{this.state.selected_record.gender}</h4>
                                            </FormGroup>
                                        </Col>
                                         <Col md="4">
                                            <FormGroup>
                                                <label>DOB</label>
                                                <h4>{this.state.selected_record.dob}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Marital Status</label>
                                                <h4>{this.state.selected_record.marital_status}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Occupation</label>
                                                <h4>{this.state.selected_record.occupation}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Monthly Income</label>
                                                <h4>{this.state.selected_record.monthly_income}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Transaction ID</label>
                                                <h4>{this.state.selected_record.transaction_id}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Amount</label>
                                                <h4>{this.state.selected_record.amount}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Citizen</label>
                                                <h4>{this.state.selected_record.nationality}</h4>
                                            </FormGroup>
                                        </Col> */}


                                            <div className="first-container-main-pop-up">
                                                <div className="first-container-semi-main-pop-up">
                                                    <div className="first-container-content-main-pop-up">
                                                        
                                                        <div className="first-container-content-first-div">
                                                            <div>
                                                                    <img alt="..." onError={this.handleError} src={imageUrl + this.state.selected_record.photo} style={{width: "65px", borderRadius: "15px"}}/>
                                                            </div>
                                                            <div>
                                                                <ul className="first-container-content-ul-div">
                                                                    <li ><p className="first-container-content-ul-p">{this.state.selected_record.name}</p></li>
                                                                    <li><p> Referal Code: <span className="first-container-content-ul-p">{this.state.selected_record.referal_code ? this.state.selected_record.referal_code : "N/A"}</span>  </p></li>
                                                                    <li><p> Joining Date: <span className="first-container-content-ul-p">{moment(this.state.selected_record.created_at).format('lll')}</span>  </p></li>
                                                                    <li><p className="first-container-content-ul-p">Status: <span style={{color: "#00945D", fontWeight: "600"}}>{this.state.selected_record.status}</span></p></li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                            <label>Email:</label>
                                                                            <h4><b>{this.state.selected_record.email ? this.state.selected_record.email : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Phone Number</label>
                                                                        <h4><b>{this.state.selected_record.mobile ? this.state.selected_record.mobile : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label> Wallet Balance</label>
                                                                        <h4><b> {this.state.selected_record.wallet_balance ? this.state.selected_record.wallet_balance : "N/A"} </b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>DOB</label>
                                                                        <h4><b>{this.state.selected_record.dob ? moment(this.state.selected_record.dob).format('DD-MMM-YYYY') : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Gender</label>
                                                                        <h4><b>{this.state.selected_record.gender ? this.state.selected_record.gender : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Remittance Balance</label>
                                                                        <h4><b>{this.state.selected_record.remit_balance ? this.state.selected_record.remit_balance : "N/A"} </b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Reference</label>
                                                                        <h4><b>{this.state.selected_record.referer ? this.state.selected_record.referer : "Self"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>CSP Code</label>
                                                                        <h4><b>{this.state.selected_record.csp_code ? this.state.selected_record.csp_code : "Self"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Payment Status</label>
                                                                        <h4><b>{this.state.selected_record.payment_status ? this.state.selected_record.payment_status : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="second-container-main-pop-up">
                                                <div className="first-container-semi-main-pop-up">
                                                    <div className="first-container-content-main-pop-up">

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Application Number</label>
                                                                        <h4><b>{this.state.selected_record.application_number ? this.state.selected_record.application_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Mobile</label>
                                                                        <h4><b>{this.state.selected_record.mobile ? this.state.selected_record.mobile : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Amount</label>
                                                                        <h4><b>  {this.state.selected_record.amount ? this.state.selected_record.amount : "N/A"} </b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Receipt Number</label>
                                                                        <h4><b>{this.state.selected_record.receipt_number ? this.state.selected_record.receipt_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>DOB</label>
                                                                        <h4><b>{this.state.selected_record.dob ? this.state.selected_record.dob : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Monthly Income</label>
                                                                        <h4><b>{this.state.selected_record.monthly_income ? this.state.selected_record.monthly_income : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Membership ID</label>
                                                                        <h4><b>{this.state.selected_record.membership_id ? this.state.selected_record.membership_id : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                       <label>Marital Status</label>
                                                                       <h4><b>{this.state.selected_record.marital_status ? this.state.selected_record.marital_status : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                      <label>Transaction ID</label>
                                                                      <h4><b>{this.state.selected_record.transaction_id ? this.state.selected_record.transaction_id : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                           {/* <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                       <label>Payment id</label>
                                                                       <h4><b>{this.state.selected_record.payment_id ? this.state.selected_record.payment_id : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div> */}
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                      <label>Occupation</label>
                                                                      <h4><b>{this.state.selected_record.occupation ? this.state.selected_record.occupation : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="second-container-main-pop-up">
                                                <div className="first-container-semi-main-pop-up">
                                                    <div className="first-container-content-main-pop-up">

                                                        <div className="first-container-content-div-comman">
                                                            <div className="third-container-content-child-div">

                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Permanent Address</label>
                                                                        <h4><b>{this.state.selected_record.p_address ? this.state.selected_record.p_address : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>District</label>
                                                                        <h4><b>{this.state.selected_record.p_district ? this.state.selected_record.p_district : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Pin_Code</label>
                                                                        <h4><b>{this.state.selected_record.p_pin_code ? this.state.selected_record.p_pin_code : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>State</label>
                                                                        <h4><b>{this.state.selected_record.p_state ? this.state.selected_record.p_state : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Country</label>
                                                                        <h4><b>{this.state.selected_record.p_country ? this.state.selected_record.p_country : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">

                                                                {this.state.selected_record.p_country != 'Nepal' && <>
                                                                 <Col>
                                                                    <FormGroup>
                                                                        <label> Pin Code:</label>
                                                                        <h4><b>{this.state.selected_record.p_pin_code ? this.state.selected_record.p_pin_code : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <label> Home/Road/Society:</label>
                                                                        <h4><b>{this.state.selected_record.house_number ? this.state.selected_record.house_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                </>}
                                                                {this.state.selected_record.p_country == 'Nepal' && <>
                                                                 <Col>
                                                                    <FormGroup>
                                                                        <label> Municiple Number:</label>
                                                                        <h4><b>{this.state.selected_record.p_municiple_number ? this.state.selected_record.p_municiple_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <label> Ward Number:</label>
                                                                        <h4><b>{this.state.selected_record.ward_number ? this.state.selected_record.ward_number : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col> </>}
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                          <div style={{display: "flex", gap: "2rem"}}>
                                                            <div>
                                                                <hr style={{width: "1px", height: "318px", backgroundColor: "grey"}} />
                                                            </div>
                                                            <div>
                                                            <div className="third-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Current Address</label>
                                                                        <h4><b>{this.state.selected_record.address ? this.state.selected_record.address : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>City</label>
                                                                        <h4><b>{this.state.selected_record.city ? this.state.selected_record.city.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>District</label>
                                                                        <h4><b>{this.state.selected_record.district ? this.state.selected_record.district.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>State</label>
                                                                        <h4><b>{this.state.selected_record.state ? this.state.selected_record.state.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Country</label>
                                                                        <h4><b>{this.state.selected_record.country ? this.state.selected_record.country.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Pin Code</label>
                                                                        <h4><b>{this.state.selected_record.pin_code ? this.state.selected_record.pin_code.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Current location</label>
                                                                        <h4><b>{this.state.selected_record.address ? this.state.selected_record.address : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            </div>

                                                          </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-aadhaar-div">

                                                                {this.state.nationality != "Nepal" &&
                                                                <>
                                                                <Col>
                                                                    <div className="userimg">
                                                                        <label id="userimg-label">Identity Front (Aadhar Card) </label>
                                                                        <img alt="..." onError={this.handleError} src={imageUrl + this.state.selected_record.proof} />
                                                                    </div>
                                                                </Col>

                                                                <Col>
                                                                    <div className="userimg">
                                                                        <label id="userimg-label">Identity Back (Aadhar Card)</label>
                                                                        <img alt="..." onError={this.handleError} src={imageUrl + this.state.selected_record.aadhar_back} />
                                                                    </div>
                                                                </Col>
                                                                </>
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>                                              
                                            </div>

                                    </Row>

                                }
                                
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.selected_record && this.state.selected_record.status === "pending" && <Row>
                                <a href="" onClick={e => { this.rejectMember(e) }} className="btn btn-danger ml-2">Reject</a>
                                <a href="" onClick={e => { this.approveMember(e) }} className="btn btn-primary mr-2">Approve</a>
                            </Row>}

                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.imageModal} toggle={this.toggleImageModal} size="lg">
                        <ModalHeader toggle={this.toggleImageModal}>Document</ModalHeader>
                        <ModalBody>
                            <img src={this.state.selected_image} alt="" />
                        </ModalBody>
                    </Modal>
                </div>
            </>
        );
    }
}

export default MemberList;
