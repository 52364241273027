import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import "../../assets/css/style.css";
class Overview extends React.Component {


    render() {
        return <>
            <div className="site_home">
                <Header banner="overview" h1Text="Overview" bannerclassName="inner_head overv" />
                <section className="over_view">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-xs-12">
                                <div className="over_aside ovr_img">
                                    <div className="over_img">
                                        <img src={require("assets/images/new/online-sath-logo.png")} alt="mission" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-xs-12">
                                <div className="over_aside">
                                    <h2>Overview</h2>
                                    <p>At Online Saathi, is registered trademark & trusted brand of Shubhlaxmi Multiservices India Pvt Ltd that believes that transferring money between countries & Indian State should be a painless experience for everyone involved? For the sender, the receiver and even for your wallet.
                                    With so many different factors and companies to consider, our aim is to remove the difficulty in finding and using money transfer providers, guiding you through every single step and making sure you’re making the best decision for your specific needs. 
                                    Our mission isn’t just to make things easier; we believe that there are always ways to save some money in the process.. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="over_more">
                                    <p>At Online Saathi we strive to not only provide the most accurate, up-to-date and honest insight into all the money transfer providers but also to make sure you’re saving money every step of the way. 
                                    With a lack of transparency in money transfers and financial services as a whole, we aim to deliver relevant, easy to digest information to save you time, effort and money.
                                    We know that there isn’t just one party involved when transferring money, which is why we’re dedicated to making sure that not only the sender has a great experience, but the receiver also does too. We also provide E-governance services to the same sector and also multiple solutions to its users as well via the app.  Online Saathi intends to provide services of Recharge, Remittance and Bus booking, Shopping as well. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>;
    }
}

export default Overview;