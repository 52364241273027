import React from "react";
import { Table } from "reactstrap";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class BSList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return <>
            <Table className="tablesorter" responsive>
                <thead className="text-primary">
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Wallet Balance</th>
                        <th>E-mail</th>
                        <th>Organization</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Description</th>
                        <th>Aadhar No</th>
                        <th>Pan No</th>
                        <th>Account No</th>
                        <th>Pan Image</th>
                        <th>Aadhar Front Image</th>
                        <th>Aadhar Back Image</th>
                        <th>Bank  Image</th>
                        <th>Other  Image</th>
                        <th>Status</th>
                        {this.props.action && <th>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {this.props.Reports.map((val, key) => {
                        return <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{val.name}</td>
                            <td>{val.contact_number}</td>
                            <td>&#8377;{val.user ? val.user.remit_balance : 0}</td>
                            <td>{val.email}</td>
                            <td>{val.organization}</td>
                            <td>{val.state}</td>
                            <td>{val.city}</td>
                            <td>{val.description}</td>
                            <td>{val.adhar_card_number}</td>
                            <td>{val.pan_card_number}</td>
                            <td>{val.bank_account_number}</td>
                            <td>{val.pan_image && <a href="" onClick={e => { this.props.viewImage(e, val.pan_image) }}> <img src={imageUrl + val.pan_image} alt=" " style={{ width: "50px" }} /></a>}</td>
                            <td>{val.adhar_front && <a href="" onClick={e => { this.props.viewImage(e, val.adhar_front) }}> <img src={imageUrl + val.adhar_front} alt=" " style={{ width: "50px" }} /></a>}</td>
                            <td>{val.adhar_back && <a href="" onClick={e => { this.props.viewImage(e, val.adhar_back) }}> <img src={imageUrl + val.adhar_back} alt=" " style={{ width: "50px" }} /></a>}</td>
                            <td>{val.bank_image && <a href="" onClick={e => { this.props.viewImage(e, val.bank_image) }}> <img src={imageUrl + val.bank_image} alt=" " style={{ width: "50px" }} /></a>}</td>
                            <td>{val.other_documents && <a href="" onClick={e => { this.props.viewImage(e, val.other_documents) }}> <img src={imageUrl + val.other_documents} alt=" " style={{ width: "50px" }} /></a>}</td>


                            <td>{val.status}</td>


                            {this.props.action && <td>

                                {val.user_id && val.status === 'pending' && <><a href="" title="Make Agent" onClick={(e) => { this.props.makeAgentModal(e, val) }}><img src={require('assets/images/other-icons/dealer.png')} alt="" style={{ width: "25px" }} /></a>&nbsp;</>}

                                {val.status === 'pending' && <><a href="" title="Recject" onClick={(e) => { this.props.rejectAgent(e, val) }}> <i className="tim-icons icon-simple-remove" title="Reject Request"></i></a>&nbsp;</>}


                            </td>}
                        </tr>
                    })}
                </tbody>
            </Table>
        </>
    }
}

export default BSList;