import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import { Pagination } from 'react-laravel-paginex'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { CSVLink } from "react-csv";
import env from "variables/constants";
const moment = require("moment");
var apiUrl = env.url;
var imageUrl = env.img_url;
class Rechargehistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: [],
            options: [],
            exceldata: [],
            selected_user_id: null,
            start_date: "",
            end_date: "",
            type: "",
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            },
            transaction_id: "",
            status: ""
        }
    }
    componentDidMount = () => {
        this.getRecords(1);
    }
    getRecords = (page = 1) => {
        var data = {};
        if (this.state.selected_user_id) {
            data.user_id = this.state.selected_user_id;
        }
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.transaction_id && this.state.transaction_id != "") {
            data.transaction_id = this.state.transaction_id;
        }
        if (this.state.status && this.state.status != "") {
            data.status = this.state.status;
        }
        Axios.post(`${apiUrl}/admin/recharge-history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response", res)
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.data,
                    details: res.data.data.data,
                })
                if (page === 1) {
                    this.setState({
                        exceldata: []
                    })
                    // this.getRecordsExcel();
                }
            }
            else {
                this.setState({
                    details: [],
                    exceldata: []
                })
            }
        })
    }
    getRecordsExcel = (page = 1) => {
        var data = { forexcel: "yes" };
        if (this.state.selected_user_id) {
            data.user_id = this.state.selected_user_id;
        }
        if (this.state.start_date && this.state.start_date !== "") {
            data.start_date = this.state.start_date;
        }
        if (this.state.end_date && this.state.end_date != "") {
            data.end_date = this.state.end_date;
        }
        if (this.state.transaction_id && this.state.transaction_id != "") {
            data.transaction_id = this.state.transaction_id;
        }
        if (this.state.status && this.state.status != "") {
            data.status = this.state.status;
        }
        console.log("send_data", data);
        Axios.post(`${apiUrl}/admin/recharge-history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response excel", res)
            if (res.data.success) {
                var exceldata = [];
                res.data.data.forEach((val, key) => {
                    var dat = {

                        "Date": moment(val.created_at).format('YYYY-MM-DD h:mm a'),
                        "Name": val.user ? val.user.name : "",
                        "Mobile": val.user ? val.user.mobile : "",
                        "Recharge Mobile": val.phone,
                        "Transaction Id": val.transaction_id,
                        "Service Provider": val.service_provider ? val.service_provider.provider_name : "",
                        "Category": val.category ? val.category.category_name : "",
                        "Order Id": val.order_id,
                        "Amount INR": (+val.amount).toLocaleString('en', 1),
                        "Bill Number": val.bill_number,

                    }
                    exceldata.push(dat);
                });
                this.setState({
                    exceldata: exceldata
                })
            }
            else {
                this.setState({
                    details: []
                })
            }
        })
    }
    changePage = data => {
        console.log(data)
        this.getRecords(data.page);
    }
    searchUser = e => {
        if (e.length > 2) {
            var data = { keyword: e };
            Axios.post(apiUrl + "/admin/users/list", data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                console.log(res)
                this.setState({ options: res.data.users, isLoading: false })
            })
        }

    }
    selectUser = selected => {
        console.log(selected)
        this.setState({ selected_user_id: selected.length > 0 ? selected[0].id : null });
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "start_date") {
            this.setState({
                end_date: null
            })
            var enddate = document.getElementById('end_date');
            enddate.value = "";
        }
    }
    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">
                                        Recharge  History
                                        <small className="float-right">
                                            {this.state.exceldata.length > 0 && <CSVLink data={this.state.exceldata} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"Remittance-wallet-history-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}

                                            {this.state.exceldata.length === 0 && <a href="" onClick={(e) => { e.preventDefault() }} style={{ color: "#d4d6d5", background: "none", padding: "10px 8px", borderRadius: "0px", pointerEvents: "none" }} title="Export to CSV"><i className="fa fa-spinner fa-spin"></i>Excel</a>}
                                        </small>
                                    </h3>
                                    <div className="row userhistoryrem">
                                        <div className="col-md-3">
                                            <label>User</label>
                                            <AsyncTypeahead
                                                id="asdasd"
                                                isLoading={this.state.isLoading}
                                                labelKey={option => `${option.name} (${option.mobile})`}
                                                onSearch={(query) => {
                                                    this.setState({ isLoading: true });
                                                    this.searchUser(query);
                                                }}
                                                options={this.state.options}
                                                onChange={selected => { this.selectUser(selected) }}
                                            />
                                        </div>


                                        <div className="col-md-2">
                                            <label>TrxId/Mobile No./Order Id.</label>
                                            <input type="text" name="transaction_id" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.transaction_id} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>Recharge Status</label>
                                            <select name="status" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.status} >
                                                <option value="">All</option>
                                                <option value="failed">Failed</option>
                                                <option value="success">Success</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" name="start_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.start_date} max={this.state.end_date ? this.state.end_date : (new Date()).toISOString().split("T")[0]} />
                                        </div>
                                        <div className="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" name="end_date" className="form-control" onChange={(e) => { this.handleInput(e) }} value={this.state.end_date} min={this.state.start_date ? this.state.start_date : "1900-01-01"} max={(new Date()).toISOString().split("T")[0]} id="end_date" />
                                        </div>
                                        <div className="col-md-2">
                                            <label>{" "}</label>
                                            <input type="button" name="submit" className="btn btn-primary searchhistory" onClick={(e) => { this.getRecords(1) }} value="Search" />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table className="tablesorter" responsive style={{ fontFamily: "sans-serif" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Date</th>
                                                <th>User Name</th>
                                                <th>User Mobile</th>
                                                <th> Mobile</th>

                                                <th>Transaction Id</th>
                                                <th>Service Provider</th>
                                                <th>Category</th>
                                                <th>Amount</th>
                                                <th>Order Id</th>
                                                <th>Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.details.map((val, key) => {
                                                // console.log(key, this.state.paginatedata.current_page, this.state.paginatedata.per_page)
                                                return <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{moment(val.created_at).format('YYYY-MM-DD h:mm a')}</td>
                                                    <td>{val.user ? val.user.name : ""}</td>
                                                    <td>{val.user ? val.user.mobile : ""}</td>

                                                    <td>{val.phone}</td>
                                                    <td>{val.transaction_id}</td>
                                                    <td>{val.service_provider ? val.service_provider.provider_name : ""}</td>
                                                    <td>{val.category ? val.category.category_name : ""}</td>
                                                    <td>&#8377;{(+val.amount).toLocaleString('en', 1)} (NPR: {(+val.amount / 100 * 160).toLocaleString('en', 1)})</td>
                                                    <td>{val.order_id}</td>
                                                    <td>{val.status.toUpperCase()}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Rechargehistory;
