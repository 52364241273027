import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Admincspcodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            states: [],
            cspcodes: [],
            csp_code: "",
            prabhu_csp_code: "",
            statename: "",
            status: "active",
            show_form: false,
            edit_mode: false
        }
    }
    componentDidMount = () => {
        this.getAllCodes();
        this.getStates();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    saveCsp = e => {
        e.preventDefault();
        if (this.state.statename === "") {
            this.notify("error", "Please select state.");
            return false;
        }
        if (!this.state.csp_code || this.state.csp_code === "") {
            if (!this.state.prabhu_csp_code || this.state.prabhu_csp_code === "") {
                this.notify("error", "Please enter CSP Code.");
                return false;
            }
        }

        if (!this.state.prabhu_csp_code || this.state.prabhu_csp_code === "") {
            if (!this.state.csp_code || this.state.csp_code === "") {
                this.notify("error", "Please enter CSP Code.");
                return false;
            }
        }
        var data = {
            csp_code: this.state.csp_code,
            state: this.state.statename,
            status: this.state.status,
            prabhu_csp_code: this.state.prabhu_csp_code,
        }
        Axios.post(`${apiUrl}/admin/save/cspcode`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    show_form: !this.state.show_form,
                    csp_code: "",
                    state: "",
                    status: "",
                    cspcodes: res.data.cspcodes
                })
            } else {
                this.notify("error", res.data.message);
            }
        })
    }

    getAllCodes = () => {
        Axios.get(`${apiUrl}/admin/all/cspcodes`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("cspcodes response", res)
            if (res.data.success) {
                this.setState({
                    cspcodes: res.data.cspcodes
                })
            }
            else {
                this.setState({
                    banners: []
                })
            }
        })
    }
    updateStatus = (e) => {
        e.preventDefault();
        var data = {
            csp_code: this.state.csp_code,
            prabhu_csp_code: this.state.prabhu_csp_code,
            state: this.state.statename,
            status: this.state.status,
        }
        Axios.post(`${apiUrl}/admin/update/cspcode`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    cspcodes: res.data.cspcodes,
                    csp_code: "",
                    prabhu_csp_code: "",
                    statename: "",
                    status: "",
                    show_form: false,
                    edit_mode: false
                })
            }
            else {
                this.notify("error", res.data.message);
            }
        }, err => {
            console.log(err)
        })
    }
    showForm = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: !this.state.show_form,
            state: "",
            status: "",
            csp_code: "",
            edit_mode: false
        })
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    getStates = () => {
        Axios.post(`${apiUrl}/remittance/ime-forex/get/state`, { country_id: 'IND' }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                states: res.data.data
            })
        })
    }
    editCode = (e, val) => {
        e.preventDefault();
        this.setState({
            csp_code: val.csp_code,
            prabhu_csp_code: val.prabhu_csp_code,
            statename: val.state,
            status: val.status,
            show_form: true,
            edit_mode: true
        })
    }
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">CSP CODES
                                        {!this.state.show_form && <small className="float-right"><a href="" onClick={(e) => { this.showForm(e) }} >Add New CSP</a></small>}
                                    </h3>
                                </CardHeader>
                                <CardBody>
                                    {this.state.show_form && <Form onSubmit={this.saveCsp}>
                                        <Col md="6">
                                            <FormGroup>
                                                <label> Status </label>
                                                <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                                                    <option value="">Select</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label> State </label>
                                                    <select className="form-control" name="statename" value={this.state.statename} onChange={(e) => { this.inputChange(e) }} disabled={this.state.edit_mode}>
                                                        <option value="">Select</option>
                                                        {this.state.states.map((val, key) => {
                                                            return <option value={val.state} key={key}>{val.state}</option>;
                                                        })}

                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>IME CSP CODE</label>
                                                    <Input name="csp_code" type="text" value={this.state.csp_code} className="form-control" onChange={(e) => { this.inputChange(e) }} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Prabhu CSP CODE</label>
                                                    <Input name="prabhu_csp_code" type="text" value={this.state.prabhu_csp_code} className="form-control" onChange={(e) => { this.inputChange(e) }} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                            Cancel
                                        </Button>
                                        {!this.state.edit_mode && <Button className="btn-fill" color="primary" type="submit">
                                            Save
                                        </Button>}
                                        {this.state.edit_mode && <Button type="button" className="btn-fill" color="primary" onClick={e => this.updateStatus(e)}>
                                            Update
                                        </Button>}
                                    </Form>}
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>State</th>
                                                <th>IME CODE</th>
                                                <th>Prabhu CODE</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.cspcodes.map((val, key) => {
                                                return <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{val.state}</td>
                                                    <td>{val.csp_code ? val.csp_code.toUpperCase() : ""}</td>
                                                    <td>{val.prabhu_csp_code ? val.prabhu_csp_code.toUpperCase() : ""}</td>
                                                    <td>{val.status[0].toUpperCase() + val.status.substr(1)}</td>
                                                    <td>
                                                        <a href="" onClick={(e) => { this.editCode(e, val) }} >
                                                            <i className="tim-icons icon-pencil" title="Edit"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Admincspcodes;
