import React from "react";
import { NavLink } from "react-router-dom";
class Footer extends React.Component {


  render() {
    return <><footer>
    <div className="home_cta">
      <div className="container">
        <div className="row cta_cnt">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <h3>Be a part of Online Saathi Family </h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 cta_btn"> <NavLink to="/become-an-agent">Become An Agent</NavLink> </div>
        </div>
      </div>
    </div>
    <div className="foot_sec">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="foot_logo"> <img src={require("assets/images/new/online-sath-logo.png")} alt="" /> </div>
            <ul className="foot_cnt">
              <li><i className="fas fa-map-marker-alt"></i>
                <p>D 109 Maruti Hight, Opp Samor Hight Naroda Muthiya, Ahmedabad, Gujarat, India, 382330</p>
              </li>
              <li><i className="fas fa-mobile-alt"></i><a href="tel:+919099005251">+91 9099005251</a></li>
              <li><i className="far fa-envelope"></i><a href="mailto:help@onlinesaathi.org">help@onlinesaathi.org</a> </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-4">
            <h4>Useful Links</h4>
            <ul className="foot_nav">
              <li><NavLink to="/overview">Overview</NavLink></li>
              <li><NavLink to="/services">Services</NavLink></li>
              <li><NavLink to="/network">Network</NavLink></li>
              <li><NavLink to="/contact">Contact</NavLink></li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-4">
            <h4>Legal</h4>
            <ul className="foot_nav">
              <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
              <li><NavLink to="/refund-policy">Refund Policy</NavLink></li>
              <li><NavLink to="/terms-and-conditions">Terms and conditions</NavLink></li>
            </ul>
          </div>
        </div>
        <div className="row foot_copy">
          <div className="col-md-6">
            <div className="copyright-area">
              <p>© 2021 Online Saathi - All rights Reserved</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="copyright-area">
              <p className="right-sec">Design &amp; Development By<a href="http://www.panahfoundation.org/" target="_blank" rel="noopener noreferrer"> Panah Foundation</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
    </>;
  }
}

export default Footer;