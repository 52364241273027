import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import "../../assets/css/style.css";
class Services extends React.Component {


    render() {
        return <>
            <div className="site_home">
                <Header banner="Our Services" h1Text="Our Services" bannerclassName="inner_head" />

                <section className="services">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="ser_aside">
                                    <div className="ser_img"> <img src={require("assets/images/Safe Jobs Connect.png")} alt="mission" /> </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="ser_aside">
                                    <h2> Safe Jobs Connect </h2>

                                    <p> <b> Discover a world of opportunities with our Safe Jobs Connect services. We are 
                                        dedicated to enhancing your employment journey, providing a holistic approach to 
                                        job readiness and placement. </b> </p> 
                                    <p> <b> Equip yourself with the knowledge needed to make informed career decisions. </b> </p>  <br />

                                    <ol className="services-para">
                                        <li> <p>Pre-Jobs Information:
                                            - Access valuable insights about job markets, industry trends, and skill 
                                            requirements.
                                            - Equip yourself with the knowledge needed to make informed career decisions. </p> </li>

                                        <li> <p> Resume Creation:
                                            - Craft a compelling resume that highlights your skills and experiences.
                                            - Increase your chances of grabbing the attention of potential employers. </p> </li>

                                        <li> <p> Regular Jobs:
                                            - Explore a wide range of job opportunities suited to your skills and preferences.
                                            - Connect with employers actively seeking candidates like you. </p> </li>

                                        <li> <p> Post-Jobs Support:
                                            - Receive guidance and support after securing a job.
                                            - Ensure a smooth transition into your new role.</p> </li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ser_full">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="ser_aside">
                                        <h2>Government Schemes Services </h2>

                                        <p> <b> Navigate the world of government schemes with ease. Our services are designed to 
                                                empower you by providing information, application support, and seamless liaising 
                                                with government agencies </b> </p> < br />

                                        <ol className="services-para">
                                            <li> <p>Screening:
                                                - Determine your eligibility for various government schemes.
                                                - Save time by applying only to schemes that align with your qualifications. </p> </li>

                                            <li> <p> Information:
                                                - Stay informed about available government programs.
                                                - Make educated decisions regarding your participation. </p> </li>

                                            <li> <p> Application Support:
                                                - Receive assistance throughout the application process.
                                                - Increase the likelihood of approval with accurate and complete applications. </p> </li>

                                            <li> <p> Government Liaoning:
                                                - Bridge the communication gap between citizens and government bodies.
                                                - Ensure a smooth application process with effective liaison services.</p> </li>
                                        </ol>   
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="ser_aside">
                                        <div className="ser_img"> <img src={require("assets/images/Government Schemes Services.png")} alt="mission" /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="ser_aside">
                                    <div className="ser_img"> <img src={require("assets/images/On-Demand Services.png")} alt="mission" /> </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="ser_aside">
                                        <h2> On-Demand Services: </h2>

                                        <p> <b> Access a spectrum of essential services at your fingertips. Our on-demand services 
                                                cover banking, insurance, legal assistance, travel services, health services, 
                                                documentation, and business services. </b> </p> < br />

                                        <ol className="services-para">
                                            <li> <p>Banking:
                                                - Enjoy hassle-free banking services from the comfort of your home.
                                                - Access personalized financial solutions tailored to your needs. </p> </li>

                                            <li> <p> Insurance:
                                                - Secure your future with comprehensive insurance services.
                                                - Get coverage that aligns with your lifestyle and requirements. </p> </li>

                                            <li> <p> Legal Assistance:
                                                 - Receive expert legal guidance and support.
                                                 - Navigate legal complexities with confidence. </p> </li>

                                            <li> <p> Travel Services:
                                                - Plan your journeys seamlessly with our travel services.
                                                - Access personalized travel solutions for a stress-free experience.</p> </li>

                                             <li> <p> Health Services:
                                                - Prioritize your well-being with our health services.
                                                - Connect with healthcare professionals and access relevant information.</p> </li>

                                            <li> <p> Documentation Services:
                                                - Simplify the documentation process for various needs.
                                                - Ensure accuracy and compliance with our documentation services.</p> </li>

                                            <li> <p> Business Services:
                                                - Facilitate the growth of your business with our comprehensive services.
                                                - Access solutions tailored to meet your specific business requirements.</p> </li>
                                        </ol>   
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="ser_full">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="ser_aside">
                                        <h2>Social Community </h2>

                                        <p> <b>Join a vibrant social community where individuals connect, share, and grow 
                                                together. Our platform fosters the creation of self-help and youth groups, providing 
                                                a space for collaboration and empowerment</b> </p> < br />

                                        <ol className="services-para">
                                            <li> <p>social media:
                                                - Engage with our community through various social media channels.
                                                - Stay connected and updated on the latest news, events, and opportunities. </p> </li>

                                            <li> <p> Self-Help Groups:
                                                - Form meaningful connections within self-help groups.
                                                - Share experiences, insights, and support each other on your journeys. </p> </li>

                                            <li> <p> Youth Groups:
                                                - Empower the youth through dedicated groups.
                                                - Collaborate on initiatives, projects, and community development. </p> </li>
                                        </ol>   
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="ser_aside">
                                        <div className="ser_img"> <img src={require("assets/images/Social Community.png")} alt="mission" /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>;
    }
}

export default Services;