import React from "react";
import { Button, Card, CardHeader, CardBody, Row, Col, FormGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";


class ImeCsRegister extends React.Component {
    constructor(props) {
        super(props);
    }
componentDidMount = () =>{
    console.log("form propssssssssssssssssssss",this.props);
}

    render() {
        var showheader = !this.props.hide_head;
        var formclass = this.props.formgroup ? this.props.formgroup : "";
        return <>
            {showheader && <Row>
                <Col md='12'>
                    <h4 style={{ backgroundColor: "#ccc", padding: "15px" }}>User Details</h4>
                </Col>
            </Row>}
            <FormGroup className={formclass}>
                <label>Registration Type</label>
                <select className="form-control" name="registration_type" defaultValue={this.props.registration_type} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.csp_registration_type.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Business Type</label>
                <select className="form-control" name="bussiness_type" defaultValue={this.props.bussiness_type} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.csp_business_type.map((bt, k1) => {
                        return <option value={bt.option_id} key={"bt" + k1}>{bt.value}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>CSP Name</label>
                <Input type="text" placeholder="CSP Name" value={this.props.cspname} name="cspname" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Pan Number</label>
                <Input type="text" placeholder="Pan Number" value={this.props.pan_number} name="pan_number" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Country</label>
                <select className="form-control" name="csp_country" defaultValue={this.props.csp_country} onChange={(e) => { this.props.handleCountryChange(e, 'csp_state') }}>
                    <option value="">Select</option>
                    <option value="IND">India</option>
                    <option value="NPL">Nepal</option>
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>State</label>
                <select className="form-control" name="csp_state" defaultValue={this.props.csp_state} onChange={(e) => { this.props.handleInputState(e, 'csp_district') }}>
                    <option value="">Select</option>
                    {this.props.states.map((rt, k1) => {
                        return <option value={rt.state_id} key={"st" + k1}>{rt.state}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>District</label>
                <select className="form-control" name="csp_district" defaultValue={this.props.csp_district} onChange={(e) => { this.props.handleInputDistrict(e, 'municipality') }}>
                    <option value="">Select</option>
                    {this.props.districts.map((rt, k1) => {
                        return <option value={rt.district_id} key={"rt" + k1}>{rt.district}</option>
                    })}
                </select>
            </FormGroup>
            {this.props.country === "NPL" && <FormGroup className={formclass}>
                <label>Municipality</label>
                <select className="form-control" name="municipality" defaultValue={this.props.municipality} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.municipalities.map((rt, k1) => {
                        return <option value={rt.municipality_id} key={"rt" + k1}>{rt.municipality}</option>
                    })}
                </select>
            </FormGroup>}
            <FormGroup className={formclass}>
                <label>City</label>
                <Input type="text" placeholder="City" value={this.props.csp_city} name="csp_city" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>House Number</label>
                <Input type="text" placeholder="House Number" value={this.props.house_number} name="house_number" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Road Name</label>
                <Input type="text" placeholder="Road Name" value={this.props.road_name} name="road_name" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Pin Code</label>
                <Input type="text" placeholder="Pin Code" value={this.props.pin_code} name="pin_code" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Address Proof Type</label>
                <select className="form-control" name="address_proof_type" defaultValue={this.props.address_proof_type} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.csp_address_proof_type.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Contact Person Name</label>
                <Input type="text" placeholder="Contact Person Name" value={this.props.contact_person_name} name="contact_person_name" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Mobile Number</label>
                <Input type="text" placeholder="Mobile Number" value={this.props.mobile_number} name="mobile_number" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Mobile Number2</label>
                <Input type="text" placeholder="Mobile Number2" value={this.props.mobile_number2} name="mobile_number2" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Landline Number</label>
                <Input type="text" placeholder="Landline Number" value={this.props.land_line_number} name="land_line_number" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Email</label>
                <Input type="text" placeholder="Email" value={this.props.csp_email} name="csp_email" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Bank</label>
                <select className="form-control" name="bank" defaultValue={this.props.bank} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.banks.map((rt, k1) => {
                        return <option value={rt.bank_id} key={"rt" + k1}>{rt.bank}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Bank Account Type</label>
                <select className="form-control" name="csp_bank_account_type" defaultValue={this.props.csp_bank_account_type} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.bank_account_type.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Bank Account Number</label>
                <Input type="text" placeholder="Bank Account Number" value={this.props.bank_account_number} name="bank_account_number" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>IFSC</label>
                <Input type="text" placeholder="IFSC" value={this.props.ifsc} name="ifsc" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Device</label>
                <select className="form-control" name="device" defaultValue={this.props.device} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.csp_device.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Connectivity Type</label>
                <select className="form-control" name="connectivity_type" defaultValue={this.props.connectivity_type} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.csp_device_connectivity_type.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Provider</label>
                <Input type="text" placeholder="Provider" value={this.props.provider} name="provider" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Business Start Time</label>
                <Input type="time" placeholder="Business Start Time" value={this.props.start_time} name="start_time" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Business End Time</label>
                <Input type="time" placeholder="Business End Time" value={this.props.end_time} name="end_time" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Has Week Off?</label>
                <select className="form-control" name="has_week_off" defaultValue={this.props.has_week_off} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    <option value="N">No</option>
                    <option value="Y">Yes</option>
                </select>
            </FormGroup>
            {this.props.has_week_off === "Y" && <FormGroup className={formclass}>
                <label>Off Day</label>
                <select className="form-control" name="off_day" defaultValue={this.props.off_day} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                </select>
            </FormGroup>}

            <FormGroup className={formclass}>
                <label>Owner's Name</label>
                <Input type="text" placeholder="Owner's Name" value={this.props.owners_name} name="owners_name" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>

            <FormGroup className={formclass}>
                <label>Owner's Gender</label>
                <select className="form-control" name="owners_gender" defaultValue={this.props.owners_gender} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    <option value="1801">Male</option>
                    <option value="1802">Female</option>
                    <option value="1803">Other</option>
                </select>
            </FormGroup>

            <FormGroup className={formclass}>
                <label>Owner's DOB</label>
                <Input type="date" placeholder="Owner's DOB" value={this.props.owners_dob} name="owners_dob" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>

            <FormGroup className={formclass}>
                <label>Owner's Father Name</label>
                <Input type="text" placeholder="Owner's Father Name" value={this.props.owners_father_name} name="owners_father_name" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label> Owner's Spouse Name</label>
                <Input type="text" placeholder="Owner's Spouse Name" value={this.props.owners_spouse_name} name="owners_spouse_name" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>

            <FormGroup className={formclass}>
                <label>Owner's Category</label>
                <select className="form-control" name="owners_category" defaultValue={this.props.owners_category} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.owner_category.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>

            <FormGroup className={formclass}>
                <label>Owner is Handicapped?</label>
                <select className="form-control" name="owners_is_handicapped" defaultValue={this.props.owners_is_handicapped} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.physically_handicapped.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>

            <FormGroup className={formclass}>
                <label>Owner's Mobile Number</label>
                <Input type="text" placeholder="Owner's Mobile Number" value={this.props.owners_mobile_number} name="owners_mobile_number" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's Contact Number</label>
                <Input type="text" placeholder="Owner's Contact Number" value={this.props.owners_contact_number} name="owners_contact_number" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's Email</label>
                <Input type="text" placeholder="Owner's Email" value={this.props.owners_email} name="owners_email" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>

            <FormGroup className={formclass}>
                <label>Owner's Alternate Occupation'?</label>
                <select className="form-control" name="owners_alternate_occupation" defaultValue={this.props.owners_alternate_occupation} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.alternate_occupation_type.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>

            <FormGroup className={formclass}>
                <label>Owner's Pan Card</label>
                <Input type="text" placeholder="Owner's Pan Card" value={this.props.owners_pan_card} name="owners_pan_card" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's ID Type'</label>
                <select className="form-control" name="owners_id_types" defaultValue={this.props.owners_id_types} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.owners_id_type.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's ID Number</label>
                <Input type="text" placeholder="Owner's ID Number" value={this.props.owners_id_number} name="owners_id_number" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>

            <FormGroup className={formclass}>
                <label>Owner's Country</label>
                <select className="form-control" name="owners_country" defaultValue={this.props.owners_country} onChange={(e) => { this.props.handleCountryChange(e, 'owners_state') }}>
                    <option value="">Select</option>
                    <option value="IND">India</option>
                    <option value="NPL">Nepal</option>
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's State</label>
                <select className="form-control" name="owners_state" defaultValue={this.props.owners_state} onChange={(e) => { this.props.handleInputState(e, 'owners_district') }}>
                    <option value="">Select</option>
                    {this.props.ostates.map((rt, k1) => {
                        return <option value={rt.state_id} key={"st" + k1}>{rt.state}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's District</label>
                <select className="form-control" name="owners_district" defaultValue={this.props.owners_district} onChange={(e) => { this.props.handleInputDistrict(e) }}>
                    <option value="">Select</option>
                    {this.props.odistricts.map((rt, k1) => {
                        return <option value={rt.district_id} key={"rt" + k1}>{rt.district}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's City</label>
                <Input type="text" placeholder="Owner's City" value={this.props.owners_city} name="owners_city" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's House Number</label>
                <Input type="text" placeholder="Owner's House Number" value={this.props.owners_house_number} name="owners_house_number" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's Road Name</label>
                <Input type="text" placeholder="Owner's Road Name" value={this.props.owners_road_name} name="owners_road_name" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's Pin Code</label>
                <Input type="text" placeholder="Owner's P{in Code" value={this.props.owners_pin_code} name="owners_pin_code" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's Village Code</label>
                <Input type="text" placeholder="Owner's Village Code" value={this.props.owners_village_code} name="owners_village_code" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's Address Proof Type</label>
                <select className="form-control" name="owners_address_prrof_type" defaultValue={this.props.owners_address_prrof_type} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.owners_address_proof_type.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's Qualification</label>
                <select className="form-control" name="owners_qualification" defaultValue={this.props.owners_qualification} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.educational_qualification.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's Additionanl Course</label>
                <select className="form-control" name="owners_additional_course" defaultValue={this.props.owners_additional_course} onChange={(e) => { this.props.handleInput(e) }}>
                    <option value="">Select</option>
                    {this.props.additional_course.map((rt, k1) => {
                        return <option value={rt.option_id} key={"rt" + k1}>{rt.value}</option>
                    })}
                </select>
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's Institute Name</label>
                <Input type="text" placeholder="Owner's Institute Name" value={this.props.owners_institute_name} name="owners_institute_name" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup>
            <FormGroup className={formclass}>
                <label>Owner's Date of Passed</label>
                <Input type="date" placeholder="Owner's Date of Passed" value={this.props.owners_date_of_passed} name="owners_date_of_passed" onChange={(e) => { this.props.handleInput(e) }} />
            </FormGroup></>;
    }
}

export default ImeCsRegister;
