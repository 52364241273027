import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import swal from 'sweetalert';
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";

const url = window.location.href;
var apiUrl = env.url;
var imageUrl = env.img_url;
class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: [],
            id:"",
            topCategory:[],
            taskname:"",
            url:"",
            category_id:"",
            user_type:"",
            point:"",
            email:"",
            mobile:"",
            description:"",
            image:"",
            status:"",
            show_form: false,
            show_add_task:false,
            target_amount:"",
            valid_date:"",
            duration:""
        }
    }
    componentDidMount = () => {
        this.getReward();
        this.getTopCategory()
    }
    inputChange = (e) => {
        console.log(e.target.value, e.target.name)
        this.setState({
            [e.target.name]: e.target.value


        })

    }

    getTopCategory = () => {
        Axios.get(`${apiUrl}/admin/all/top/categories`, {
           headers: {
              Authorization: "Bearer " + sessionStorage.getItem("_access")
           }
        }).then(res => {
           console.log("categories response", res)
           if (res.data.success) {
              this.setState({
                 topCategory: res.data.categories
              })
           }
           else {
              this.setState({
                 topCategory: []
              })
           }
        })
     }

    fileChange = (e) => {

        this.setState({ image: e.target.files[0] })
    }
    getReward = () => {
        Axios.get(`${apiUrl}/admin/get/reward`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("users response", res)
            if (res.data.success) {
                this.setState({
                    task: res.data.data.task
                })
            } else {
                this.setState({
                    task: []
                })
            }
        })
    }

    show_add_task = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: false,
            show_add_task:!this.state.show_add_task,
            id:"",
            taskname:"",
            description:"",
            category_id:"",
            user_type:"",
            point:"",
            url:"",
            status:"Active",
            mobile:"",
            description:"",
            target_amount:"",
            valid_date:"",
            duration:""
        })
    }

    vieweditform = (e = null,val) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: !this.state.show_form,
            id:val.id,
            user_type:val.user_type,
            description:val.description,
            status:val.status,
            point:val.point,
            target_amount:val.target_amount,
            valid_date:val.valid_date,
            duration:val.duration,
            category_id:val.top_category ? val.top_category.id : "",
        })
        setTimeout(() => {
            console.log("catet",this.state.category_id)
        }, 100);

    }
    canceladdform = (e = null) => {
        if (e) e.preventDefault();
        this.setState({

            show_add_task: !this.state.show_add_task,
            id:"",
            taskname:"",
            description:"",
            status:"",
            url:"",
            mobile:"",
            description:"",
            category_id:"",
            point:"",
            user_type:"",
            target_amount:"",
            valid_date:"",
            duration:""

        })
    }

    cancelform = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: !this.state.show_form,

            id:"",
            taskname:"",
            description:"",
            status:"",
            url:"",
            mobile:"",
            description:"",
            category_id:"",
            point:"",
            user_type:"",
            target_amount:"",
            valid_date:"",
            duration:""

        })
    }


    updatestatus = (e = null,id) => {
        e.preventDefault();
    //    swal("", "Do you want to update status", "");

        swal({ text: "Do you want to update status", icon: "",buttons: ["No", "Yes"] }).then(ok => {
              console.log(ok);
              if(ok){


           const formdata = new FormData();
           formdata.append('id',id);

           Axios.post(`${apiUrl}/admin/update-task-status`,formdata,{
            headers: {
                 Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
           }).then(res =>{
                    if(res.data.success){
                         this.notify('success',res.data.message)
                         this.setState({
                            task: res.data.data.task
                        })

                    }else{
                        this.notify('error',res.data.message)

                    }
              }

           )
            }
          })


       }
    removetask = (e = null,id) => {
        e.preventDefault();
        swal({ text: "Are you sure you want to delete this item?", icon: "",buttons: ["No", "Yes"] }).then(ok => {
            if(ok){
           const formdata = new FormData();
           formdata.append('id',id);

           Axios.post(`${apiUrl}/admin/remove-task`,formdata,{
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
           }).then(res =>{
                    if(res.data.success){
                         this.notify('success',res.data.message)
                        this.setState({
                            task: res.data.data.task
                        })

                    }else{
                        this.notify('error',res.data.message)
                    }
              }
           )
          }
        })
       }
       posttask = e => {
        e.preventDefault();
           if(this.state.category_id === ""){
               this.notify('error','Please choose category.')
               return false;
           }
           if(this.state.user_type === ""){
            this.notify('error','Please choose user type.')
            return false;
           }
           if(this.state.point === ""){
            this.notify('error','Please enter point.')
            return false;
           }
           if(this.state.valid_date === ""){
            this.notify('error','Please enter valid date.')
            return false;
           }
           if(this.state.duration === ""){
            this.notify('error','Please enter duration.')
            return false;
           }
           if(this.state.target_amount === ""){
            this.notify('error','Please enter target amount.')
            return false;
           }
           if(this.state.description === ""){
            this.notify('error','Please enter description.')
            return false;
           }
           if(this.state.status === ""){
            this.notify('error','Please enter status.')
            return false;
           }
           const formdata = new FormData();

           formdata.append('category_id',this.state.category_id);
           formdata.append('user_type',this.state.user_type);
           formdata.append('point',this.state.point);
           formdata.append('description',this.state.description);
           formdata.append('status',this.state.status);
           formdata.append('duration',this.state.duration);
           formdata.append('target_amount',this.state.target_amount);
           formdata.append('valid_date',this.state.valid_date);
        Axios.post(`${apiUrl}/admin/add/reward`,formdata,{
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
           }).then(res =>{
                    if(res.data.success){
                         this.notify('success',res.data.message)
                        this.setState({
                            task: res.data.data.task,
                            taskname:"",
                            description:"",
                            email:"",
                            category_id:"",
                            user_type:"",
                            point:'',
                            url:"",
                            status:"",
                            target_amount:"",
                            valid_date:"",
                            duration:""
                        })
                    }else{
                        this.notify('error',res.data.message)
                    }
              }

           )

       }
     updatetask = e => {
        e.preventDefault();
        if(this.state.category_id === ""){
            this.notify('error','Please choose category.')
            return false;
        }
        if(this.state.user_type === ""){
         this.notify('error','Please choose user type.')
         return false;
        }
        if(this.state.point === ""){
         this.notify('error','Please enter point.')
         return false;
        }
        if(this.state.description === ""){
         this.notify('error','Please enter description.')
         return false;
        }
        if(this.state.status === ""){
         this.notify('error','Please enter status.')
         return false;
        }
        if(this.state.valid_date === ""){
            this.notify('error','Please enter valid date.')
            return false;
           }
           if(this.state.duration === ""){
            this.notify('error','Please enter duration.')
            return false;
           }
           if(this.state.target_amount === ""){
            this.notify('error','Please enter target amount.')
            return false;
           }
           const formdata = new FormData();
           formdata.append('category_id',this.state.category_id);
           formdata.append('user_type',this.state.user_type);
           formdata.append('point',this.state.point);
           formdata.append('description',this.state.description);
           formdata.append('status',this.state.status);
           formdata.append('id',this.state.id);
           formdata.append('duration',this.state.duration);
           formdata.append('target_amount',this.state.target_amount);
           formdata.append('valid_date',this.state.valid_date);
           Axios.post(`${apiUrl}/admin/add/reward`,formdata,{
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
           }).then(res =>{
                    if(res.data.success){
                         this.notify('success',res.data.message)
                         this.setState({
                            task: res.data.data.task,
                            show_add_task:false,
                            show_form:false
                         })
                    }else{
                        this.notify('error',res.data.message)
                    }
              }
           )
       }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    render() {
        return (
            <>
           <div className="content">
           <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1 className="title" >Reward
                                    <small className="float-right">
                                           <button  type="button" onClick={this.show_add_task}  className="btn-fill btn btn-primary"  >Add New</button>
                                       </small>
                                    </h1>

                                    <div className="card-body">

                                    {this.state.show_add_task && <Form onSubmit={this.posttask}>
                                        <Row>
                                            <Col md="6">
                                            <FormGroup>
                                            <label>Choose Category </label>
                                            <select className="form-control" name="category_id" value={this.state.category_id} onChange={(e) => { this.inputChange(e) }}>
                                            <option value="">Select</option>
                                            {this.state.topCategory.map((val, key) => {
                                                return <option value={val.id} >{val.name}</option>;
                                            })}

                                            </select>
                                            </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                <label>
                                                    User Type
                                                </label>
                                                <select className="form-control" name="user_type" value={this.state.user_type} onChange={(e) => { this.inputChange(e) }}>
                                                    <option value="">Select</option>
                                                    <option value="all">ALL Users</option>
                                                    <option value="saathi">Saathi</option>
                                                    <option value="partner">Partner</option>
                                                    <option value="starte_partner">State Partner</option>
                                                </select>
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Duration (in days) </label>
                                                    <input type="number" className="form-control" name="duration" value={this.state.duration} onChange={(e) => {this.inputChange(e) } }  />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Target Amount </label>
                                                    <input type="text" className="form-control" name="target_amount" value={this.state.target_amount} onChange={(e) => {this.inputChange(e) } }  />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Points </label>
                                                    <input type="text" className="form-control" name="point" value={this.state.point} onChange={(e) => {this.inputChange(e) } }  />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Description </label>
                                                    <input type="text" className="form-control" name="description" value={this.state.description} onChange={(e) => {this.inputChange(e) } }  />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Valid Upto </label>
                                                    <input type="date" name="valid_date" className="form-control" onChange={(e) => { this.inputChange(e) }} value={this.state.valid_date} />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                <label>
                                                    Status
                                                </label>
                                                <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                                                    <option value="">Select</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Button className="btn-fill" color="primary" type="button" onClick={this.canceladdform}>
                                            Cancel
                                        </Button>
                                        <Button className="btn-fill" color="primary" type="submit">
                                            Save
                                        </Button>
                                    </Form>}

                                    {this.state.show_form && <Form onSubmit={this.updatetask}>
                                        <Row>
                                            <Col md="6">
                                            <FormGroup>
                                            <label>Choose Category </label>
                                            <select className="form-control" name="category_id" value={this.state.category_id} onChange={(e) => { this.inputChange(e) }}>
                                            <option value="">Select</option>
                                            {this.state.topCategory.map((val, key) => {
                                                return <option value={val.id} key={key}>{val.name}</option>;
                                            })}

                                            </select>
                                            </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                <label>
                                                    User Type
                                                </label>
                                                <select className="form-control" name="user_type" value={this.state.user_type} onChange={(e) => { this.inputChange(e) }}>
                                                    <option value="">Select</option>
                                                    <option value="all">ALL Users</option>
                                                    <option value="saathi">Saathi</option>
                                                    <option value="partner">Partner</option>
                                                    <option value="starte_partner">State Partner</option>
                                                </select>
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Duration (in days) </label>
                                                    <input type="number" className="form-control" name="duration" value={this.state.duration} onChange={(e) => {this.inputChange(e) } }  />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Target Amount </label>
                                                    <input type="text" className="form-control" name="target_amount" value={this.state.target_amount} onChange={(e) => {this.inputChange(e) } }  />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Points </label>
                                                    <input type="text" className="form-control" name="point" value={this.state.point} onChange={(e) => {this.inputChange(e) } }  />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Description </label>
                                                    <input type="text" className="form-control" name="description" value={this.state.description} onChange={(e) => {this.inputChange(e) } }  />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Valid Upto </label>
                                                    <input type="date" name="valid_date" className="form-control" onChange={(e) => { this.inputChange(e) }} value={this.state.valid_date} />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                <label>
                                                    Status
                                                </label>
                                                <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                                                    <option value="">Select</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Button className="btn-fill" color="primary" type="button" onClick={this.cancelform}>
                                            Cancel
                                        </Button>
                                        <Button className="btn-fill" color="primary" type="submit">
                                            Save
                                        </Button>
                                    </Form>}
                                        <div className="table-responsive">
                                            <table className="tablesorter table">
                                                <thead className="text-primary" >
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Category</th>
                                                        <th>User Type</th>
                                                        <th>Point</th>
                                                        <th>Target Amount</th>
                                                        <th>Valid Upto</th>
                                                        <th>Duration</th>
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.task.map((val, key) => {

                                                            return <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>{val.top_category ?  val.top_category.name : ""}</td>
                                                            <td>{val.user_type}</td>
                                                            <td>{val.point}</td>
                                                            <td>{'₹'+val.target_amount}</td>
                                                            <td>{val.valid_date}</td>
                                                            <td>{val.duration+' days'}</td>
                                                            <td>{val.description}</td>
                                                            <td>{val.status}</td>


                                                             <td>

                                                             <a href="" onClick={(e) => {this.vieweditform(e ,val)}} ><i className="fas fa-edit"></i></a>
                                                             &nbsp;&nbsp;


                                                             </td>

                                                        </tr>
                                                    })}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        );
    }
}
export default Support;

