import React from "react";
import { Button, Card, CardHeader, CardBody, Row, Col, FormGroup, Input, Table, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";

import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import { Modal } from "reactstrap";
import ReceiptModel from "./ReceiptModel";
import swal from 'sweetalert';
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Agentlist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deductModal: false,
            otp_verified:false,
            otp_token:"",
            balance_type:"add",
            otp:"",
            details:[],
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }

    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");

        }
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
      
        this.getData();
       
    }
   
    getData = (page = 1) => {
        var data = {};
        Axios.post(`${apiUrl}/user/agent-list?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            // console.log(res.data.data.data)
            this.setState({
                details: res.data.data.data,
                pagination_data: res.data.data
            })
        },err=>{
            swal("","Session expired. Please login again.").then(o=>{
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    deductBalance = (e, val) => {
        e.preventDefault();
        this.setState({
            selected_user: val,
            deductModal: true
        })
    }
    deductBalanceSubint = () => {
        
      
        if (this.state.deduct_amount <= 0) {
            swal("", "Amount should be more than 0.", "error");

            return false;
        }

        if (this.state.deduct_reason === "") {
            swal("", "Please enter reason.", "error");

            return false;
        }
        var data = {
            deduct_amount: this.state.deduct_amount,
            user_id: this.state.selected_user.id,
            balance_type: this.state.balance_type,
            deduct_reason: this.state.deduct_reason,
        }
        Axios.post(apiUrl + `/user/add/balance`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            if (type === "success") {
                this.getData();
                this.setState({
                    deduct_amount: 0,
                    selected_user: null,
                    deduct_from: "remit_balance",
                    deduct_reason: "",
                    deductModal: false
                })
            }
            swal("", res.data.message, "success");

            // console.log(res)
        })
    }
    toggleDeductModal = e => {
        this.setState({
            deductModal: !this.state.deductModal
        })
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    editDetailsOtp = (e, det) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/send/otp`, { user_id: det.id }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({
             //   editModal: true,
                deductModal:true,
                otp_verified: false,
                selected_user: det,
                otp_token: res.data.otp_token
            })
      //      this.toggleDeductModal();
        })
    }
    verifyOtp = (e) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/verify/otp`, { otp: this.state.otp, otp_token: this.state.otp_token }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {

            if (res.data.success) {
                var det = this.state.selected_user;
                e.preventDefault();
              //  this.notify("success", res.data.message)
                swal("", res.data.message, "success");
                this.setState({
                //    deductModal: true,
                    otp_verified: true
                })
            } else {
                swal("", res.data.message, "error");
               
            }
           
        })
    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
    render() {
        return <>
            <Userheader user={this.state.user} />
            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="maine-table-dash-sec">

                              
                                <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p> Agent List </p>
                                        <span>{`Showing ${this.state.pagination_data.from} to ${this.state.pagination_data.to} from ${this.state.pagination_data.total} records`}</span>
                                       
                                    </h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>JOINING DATE</th>
                                                <th>Name</th>
                                                <th>E-mail</th>
                                                <th>Mobile</th>
                                                <th>REFERAL CODE</th> 	
                                                <th>Wallet Balance</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody className="table-space">
                                        {this.state.details.map((hs, key) => {
                                               return  <tr>
                                                 <td>{moment(hs.created_at).calendar()}</td>
                                                 <td>{hs.name}</td>
                                                 <td>{hs.email}</td>
                                                 <td>{hs.mobile}</td>
                                                 <td>{hs.referer}</td>
                                                 <td>{hs.remit_balance}</td>
                                                <td className="status-dash">{hs.status}</td>
                                                <td className="status-dash">
                                                        {/* <a href="" className="balanceammout" title="Edit Details" onClick={(e) => { this.editDetailsOtp(e, hs) }}><i className="fa fa-pencil-square-o" style={{ fontSize: "20px", verticalAlign: "middle" }} ></i></a>
                                                        */}
                                                        <a href="" className="balanceammout" title="Deduct Balance" onClick={(e) => { this.editDetailsOtp(e, hs) }}><i className="fa fa-money" style={{ fontSize: "20px" }}></i></a>
                                                    </td>
                                             </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination pagination_data={this.state.pagination_data} getData={this.getData} />
                           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal isOpen={this.state.deductModal} toggle={this.toggleDeductModal}>
                <ModalHeader toggle={this.toggleDeductModal} style={{width:"100%"}}>Adjust Balance
                </ModalHeader>
                <ModalBody>
                       {!this.state.otp_verified && <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>OTP</lebel>
                                                    <input className="form-control" type="number" name="otp" value={this.state.otp} onChange={(e) => { this.handleInput(e) }} max="999999" min="100000" />
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.otp_verified && <>
                    <div className="form-group">
                        {this.state.selected_user && <label>Name: {this.state.selected_user.name} ({this.state.selected_user.mobile})</label>}
                    </div>
                   
                    <div className="form-group">
                        {this.state.selected_user && <label>Wallet Balance: {this.state.selected_user.remit_balance}</label>}
                    </div>
                    <div className="form-group">
                        <label>Balance Type</label>
                        <select className="form-control" onChange={(e) => { this.handleInput(e) }} name="balance_type" value={this.state.balance_type}>
                            <option value="add">Add  Balance</option>
                            <option value="deduct">Deduct  Balance</option>
                        </select>
                    </div> 
                    <div className="form-group">
                        <label>Amount</label>
                        <input type="number" min="0" className="form-control" onChange={(e) => { this.handleInput(e) }} name="deduct_amount" value={this.state.deduct_amount} />
                    </div>
                    <div className="form-group">
                        <label>Reason</label>
                        <textarea name="deduct_reason" onChange={(e) => { this.handleInput(e) }} value={this.state.deduct_reason} className="form-control"></textarea>
                    </div>
                  </>}
                </ModalBody>
                <ModalFooter>
                {this.state.otp_verified &&      
                     <button type="button" className="btn btn-danger" onClick={this.deductBalanceSubint}>
                        Submit
                    </button>
                    }
                    {!this.state.otp_verified && <button type="button" className="btn btn-danger" onClick={e => this.verifyOtp(e)}>
                        Verify OTP
                    </button>}
               
                </ModalFooter>
            </Modal>
            <Userfooter />
        </>;
    }
}
export default Agentlist;

