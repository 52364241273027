import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";

class ReceiptModel extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return <>
            <Modal modalClassName="modal modal_transction_detals" isOpen={this.props.modalState} toggle={this.props.toggleModal} size="lg" className="modal-90">
                <div className="modal-header">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.props.toggleModal} style={{ zIndex: "999" }} >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                </div>
                <div className="modal-body">
                    <section className="maine-tranation_sec">
                        <div className="trainsection-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="trainsection-heading">
                                        <h3>Transaction Details</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    {this.props.sender && <div className="col-md-12">
                                        <div className="sender_details_sec">
                                            <h4>Sender Details</h4>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Name</h5>
                                                    <p>{this.props.sender.SenderName}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Gender</h5>
                                                    <p>{this.props.sender.SenderGender}</p>
                                                </div>
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Country</h5>
                                                    <p>{this.props.sender.SenderCountry}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Mobile Number</h5>
                                                    <p>{this.props.sender.SenderMobileNo}</p>
                                                </div>
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Id Tpye</h5>
                                                    <p>{this.props.sender.SenderIdType}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Mail</h5>
                                                    <p>{this.props.sender.SenderEmail}</p>
                                                </div>
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Address</h5>
                                                    <p>{this.props.sender.SenderAddress}</p>
                                                </div>
                                                <div className="gender_sec">

                                                </div>
                                            </div>


                                        </div>
                                    </div>}
                                    {this.props.receiver && <div className="col-md-12">
                                        <div className="sender_details_sec">
                                            <h4>Receiver Details</h4>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Name</h5>
                                                    <p>{this.props.receiver.ReceiverName}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Gender</h5>
                                                    <p>{this.props.receiver.ReceiverGender}</p>
                                                </div>
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Country</h5>
                                                    <p>{this.props.receiver.ReceiverCountry}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Mobile Number</h5>
                                                    <p>{this.props.receiver.ReceiverMobileNo}</p>
                                                </div>
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Relation</h5>
                                                    <p>{this.props.receiver.ReceiverRelationWithSender}</p>
                                                </div>
                                                {/* <div className="gender_sec">
                                                    <h5>Mail</h5>
                                                    <p>{this.props.receiver.ReceiverName}</p>
                                                </div> */}
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Address</h5>
                                                    <p>{this.props.receiver.ReceiverAddress}</p>
                                                </div>
                                                <div className="gender_sec">

                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.props.transaction && <div className="col-md-12">
                                        <div className="sender_details_sec">
                                            <h4>Transaction Details</h4>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Transfer Ammount</h5>
                                                    <p>&#8377;{this.props.transaction.TransferAmount}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Service Recharge</h5>
                                                    <p>&#8377;{this.props.transaction.ServiceCharge}</p>
                                                </div>
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Amount Collected (INR)</h5>
                                                    <p>&#8377;{this.props.transaction.CollectedAmount}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Exchange Rate</h5>
                                                    <p>{this.props.transaction.ExchangeRate}</p>
                                                </div>
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Payout Amount</h5>
                                                    <p>{this.props.transaction.PayoutAmount}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Payout Currency</h5>
                                                    <p>NPR</p>
                                                </div>
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Payment Mode</h5>
                                                    <p>{this.props.transaction.PaymentMethod}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Sending Branch</h5>
                                                    <p>{this.props.transaction.SendingBranch}</p>
                                                </div>
                                            </div>

                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Purpose of Remittance</h5>
                                                    <p>{this.props.transaction.PurposeOfRemit}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Source of Fund</h5>
                                                    <p>{this.props.transaction.SourceOfFund}</p>
                                                </div>
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Transaction Date</h5>
                                                    <p>{this.props.transaction.TransactionDate}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Status</h5>
                                                    <p>{this.props.transaction.Status}</p>
                                                </div>
                                            </div>
                                            <div className="user_info_sec">
                                                <div className="left-_side_sec">
                                                    <h5>Paid Date</h5>
                                                    <p>{this.props.transaction.PaidDate}</p>
                                                </div>
                                                <div className="gender_sec">
                                                    <h5>Cancel Date</h5>
                                                    <p>{this.props.transaction.CancelDate}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="invoice-download">
                                        <a href=""><img src={require("assets/images/upload.png")} alt="" />Download Invoice </a>
                                    </div>
                                    <div className="needhelp">
                                        <a href="">Need Help </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Modal>
        </>;
    }

}
export default ReceiptModel;