import React from "react";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import { Modal } from "reactstrap";
import ReceiptModel from "./ReceiptModel";
import swal from 'sweetalert';
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Prabhuhistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: false,
            modalState: false,
           
            history: [],
            modify_modal: false,
            pin_number:"",
            cancel_reason: "",
            
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }

    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");

        }
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
      
        this.getData();
      
       
    }

    toggleModal = (e = null,) => {
        this.setState({
            modalState: !this.state.modalState,
        })
    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }

    getData = (page = 1) => {
        var data = {};
        Axios.post(`${apiUrl}/remittance/prabhu/remittance/history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res)
            this.setState({
                history: res.data.list.data,
                pagination_data: res.data.list
            })
        },err=>{
            swal("","Session expired. Please login again.").then(o=>{
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    downloadRemitpdf = (e, id) => {
        e.preventDefault();
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/prabhu/receipt", { remittance_id: id }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("pdf ", res)
            this.setState({ loading: false })
            if (res.data.success) {
                window.open(res.data.data.pdfurl, "_blank");
            }
        })
    }
 
  
    modifyModal = (e, det) => {
        e.preventDefault();
        console.log(det)
        this.setState({
            modify_modal: true,
            pin_number:det.p_transaction_pin,
        })
    }
    toggleModifyModal = e => {
        this.setState({
            modify_modal: !this.state.modify_modal
        })
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
 
 
    submitDeleteOtp = e => {
        e.preventDefault();
        var data = {
            pin_number: this.state.pin_number,
            reason: this.state.cancel_reason,
           
        }
        if (this.state.cancel_reason === "") {
            swal("", "Please enter cancel reason.", "error");
            return false;
        }
       
      
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/prabhu/cancel/transaction`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            if (res.data.success) {
                this.getData(this.state.pagination_data.current_page);
                swal("", res.data.message, "success");
                this.setState({
                    cancel_reason: "",
                    modify_modal: false,
                });
            }
            else {
                swal("", res.data.message, "error");
            }
        })
    }
  
    render() {
        return <>
            <Userheader user={this.state.user} />
            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="maine-table-dash-sec">
                                <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p>Prabhu Remittance History </p>
                                        <span>{`Showing ${this.state.pagination_data.from} to ${this.state.pagination_data.to} from ${this.state.pagination_data.total} records`}</span>
                                    </h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Sender</th>
                                                <th>Receiver</th>
                                                <th>Collected Amount</th>
                                                <th>Send Amount</th>
                                                <th>Service Charge</th>
                                                <th>Method</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-space">
                                            {this.state.history.map((hs, key) => {
                                                return <Tabledata hs={hs} key={key} getRemimttanceDetails={this.getRemimttanceDetails} modifyModal={this.modifyModal} deletModal={this.deletModal} downloadRemitpdf={this.downloadRemitpdf} />
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination pagination_data={this.state.pagination_data} getData={this.getData} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ReceiptModel modalState={this.state.modalState} toggleModal={this.toggleModal} details={this.state.remittance} sender={this.state.sender} receiver={this.state.receiver} transaction={this.state.transaction} />
            <Modal modalClassName="modal" isOpen={this.state.modify_modal} toggle={this.toggleModifyModal} size="lg" >
                <div className="modal-header">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModifyModal} style={{ zIndex: "999" }} >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                </div>
                <div className="modal-body">
              
                    <div className="row">
                       <div className="col-md-6">
                            <div className="form-group">
                                <img src="images/mobile_no.png" alt="" />
                                <label >Cancel Reason</label>
                                <textarea className="form-control" name="cancel_reason" onChange={e => { this.handleInput(e) }} value={this.state.cancel_reason} >
                                     
                                </textarea>
                            </div>
                        </div>
                      

                        <div className="col-md-12">
                            <div className="send-bt-dash">
                              
                                <a href="" onClick={e => { this.submitDeleteOtp(e) }}>Submit</a>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
            <Userfooter />
        </>;
    }
}
export default Prabhuhistory;
function Tabledata(props) {
    var hs = props.hs;
    var status = hs.status.toLowerCase();
    var modify = ['pending'].includes(status);
    var cls = ['cancel_request', 'cancel'].includes(status) ? " bg-warning text-white" : status !== "paid" ? "bg-light text-white" : "";
    return <><tr className="spacer" >
        <td colSpan=""></td>
    </tr>
        <tr>
            <td>{moment(hs.created_at).calendar()}</td>
            <td>{hs.sender.name}<br />{hs.sender.mobile}</td>
            <td>{hs.receiver.receiver_name}<br />{hs.receiver.receiver_mobile}</td>
            <td><i className="fa fa-inr" aria-hidden="true"></i>{(+hs.collected_amount)}</td>
            <td>NPR {(+hs.transfer_amount)}</td>
            <td><i className="fa fa-inr" aria-hidden="true"></i>{(+hs.service_charge)}</td>
            <td>{hs.PaymentType === "C" ? "Cash" : "Bank"}</td>
            <td className="status-dash"><span className={cls}>{hs.status}</span></td>
            <td className="icon-sec">
                

                <a href="" onClick={(e) => { props.downloadRemitpdf(e, hs.id) }} className="text-warning">
                    <i className="fa fa-file-pdf" area-hidden="true"></i>
                </a>
                {modify && <a href="" onClick={(e) => { props.modifyModal(e, hs) }} className="text-info">
                <i className="fa fa-times-circle" style={{'color' : "red"}}  aria-hidden="true"></i>
               
                </a>}
               
            </td>
        </tr>
    </>;
}
