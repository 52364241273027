import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import defaultpicture from "../../../assets/img/default-avatar.png";

const Header = () => {
    const [show, setshow] = useState(false);

  return (
    <>
    <div className="">
        <section className="dashboard_new_sec">
            <div className="container-fluid">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6 col-6">
                            <div className="logo">
                                <img src={require("assets/images/new/online-sath-logo-new.png")} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-6">
                            <div className="balance-area d-flex inline p-2">
                            <p className='mt-2'>Utility:123456789/-</p>
                                <div className="header-pro-thumb d-flex inline" onClick={()=>setshow(!show)}>
                              
                                <i class="fa fa-bell-o fa-2x mt-1" aria-hidden="true"></i>

                                    <img className="rounded-circle" src={defaultpicture} alt="profilepic" />
                                    <p className='d-flex inline mt-2'>UserName<i class="fa fa-angle-down text-info" aria-hidden="true"></i></p>
                                </div>
                                <div className={show===true ? "dropdown show user_drop":"dropdown  user_drop"}>
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="pl-4 pt-1">
                                      {/* onClick={e => { this.dropdowan(e) }} ><b>{this.props.user.name}</b> */}
                                         </span>
                                    </a>
                                    
                                        <div className="dropdown-menu hide drop_hide" aria-labelledby="">
                                            <a className="dropdown-item" href="edit-profile">Edit Profile</a>
                                            <a className="dropdown-item" href="change-password">Change Password</a>
                                        
                                            <a className="dropdown-item" target="_blank" href="">Certificate</a>
                                          
                                            <a className="dropdown-item" href="#" onClick={e => { this.logout(e) }}>Logout</a>
                                            {/* <a className="dropdown-item" onClick={e => { this.logout(e) }} >Log out</a> */}
                                        </div>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section className="wallet_balance_strip">
                <div className="container-fluid">
                    <div className="">
                        <div className="row">
                            <div className="col-md-6 p-3">
                                {/* <marquee scrollamount="3"><b>OnlineSaathi SERVICE INDIA PRIVATE LIMITED. Helpline Number: +91-8154818585</b></marquee> */}
                            </div>
                            {/* <div className="col-md-6">
                                <div className="wallel_balance_sec">
                                    <span className="balance-text">
                                        <img src={require("assets/images/remittance_wallet_history.png")} alt="" />&nbsp;
                                    </span>
                                    <span className="amount-text">Wallet Balance: 0</span>
                                    &nbsp;&nbsp;<Link to="add-money" className="badge" style={{backgroundColor:"#ed1c24"}}>+Add Money</Link>
                                    
                                    
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <div className='d-flex inline'>
            &nbsp; <p style={{display:'grid'}}><i class="flex fa fa-handshake-o fa-3x mx-5" aria-hidden="true"></i>services</p>&nbsp;&nbsp;
            <p style={{display:'grid'}}><i class="fa fa-suitcase fa-3x mx-5" aria-hidden="true"></i>Jobs</p>&nbsp;
            <p style={{display:'grid'}}><i class="flex fa fa-cogs fa-3x mx-5" aria-hidden="true"></i>Scheme</p>&nbsp;&nbsp;
            <p style={{display:'grid'}}><i class="fa fa-university fa-3x mx-5" aria-hidden="true"></i>Bank</p>&nbsp;
             <p style={{display:'grid'}}><i class="flex fa fa-recycle fa-3x mx-5" aria-hidden="true"></i>Recharge</p>&nbsp;&nbsp;
            <p style={{display:'grid'}}><i class="fa fa-hand-peace-o fa-3x mx-5" aria-hidden="true"></i>Insurance</p>&nbsp;
             <p style={{display:'grid'}}><i class="flex fa fa-stack-overflow fa-3x mx-5" aria-hidden="true"></i>Government</p>&nbsp;&nbsp;
            <p style={{display:'grid'}}><i class="fa fa-cart-plus fa-3x mx-5" aria-hidden="true"></i>FASTag</p>&nbsp;
            <p style={{display:'grid'}}><i class="fa fa-suitcase fa-3x mx-4" aria-hidden="true"></i>Travel</p>&nbsp;
            <p style={{display:'grid'}}><i class="fa fa-industry fa-3x mx-4" aria-hidden="true"></i>Bill Payment</p>&nbsp;
                </div>
            <section className="wallet_balance_strip">
                <div className="container-fluid">
                    <div className="">
                        <div className="row">
                            <div className="col-md-6">
                                {/* <marquee scrollamount="3"><b>OnlineSaathi SERVICE INDIA PRIVATE LIMITED. Helpline Number: +91-8154818585</b></marquee> */}
                            </div>
                            <div className="col-md-6">
                                <div className="wallel_balance_sec">
                                    <span className="balance-text">
                                        <img src={require("assets/images/remittance_wallet_history.png")} alt="" />&nbsp;
                                    </span>
                                    <span className="amount-text">Wallet Balance: 0</span>
                                    &nbsp;&nbsp;<Link to="add-money" className="badge" style={{backgroundColor:"#ed1c24"}}>+Add Money</Link>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
  )
}

export default Header
