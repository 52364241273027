import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import "../../assets/css/style.css";
class Refundpolicy extends React.Component {


    render() {
        return <>
            <div className="site_home">
                <Header banner="Refund" h1Text="Refund Policy" bannerclassName="inner_head our_policy"/>
                <section className="policy_sec">
                    <div className="container">
                        <div className="policy">
                            <p>All sales of prepaid recharge on the Online Saathi Platform with no refund or exchange .You are responsible for the mobile number or DTH account number for which You purchase the prepaid recharge and all charges that result from those purchases. You are also responsible for the information relating to data card and toll-tag recharge and all charges that result from those purchases. Online Saathi is not responsible for any purchase of prepaid recharge for an incorrect mobile number or DTH account number or incorrect toll or data card information. 
                            However, if in a transaction performed by You on the Online Saathi Platform, money has been charged to Your card or bank account and a recharge is not delivered within 24 hours of the completion of the transaction, then You shall inform us by sending an e mail to our customer services e mail address mentioned on the ‘Contact Us’ page on the Online Saathi Platform. Please include in the e-mail the following details – the mobile number (or DTH account number or data card or toll-tag information), operator name, recharge value, transaction date and order number. Online Saathi will investigate the incident and, if it is found that money was indeed charged to your card or bank account without delivery of the recharge, then you will be refunded the money within 21 working days from the date of receipt of Your e mail. All refunds will be credited to your semi - closed wallet. You can trigger a request in Your Online Saathi wallet to transfer the money from Your Online Saathi wallet back to source. It will take 3-21 working days for the money to show in Your bank account depending on Your bank’s policy.</p>
                            
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>;
    }
}

export default Refundpolicy;