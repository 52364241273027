import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Table
} from "reactstrap";
import Axios from "axios";
import swal from 'sweetalert';
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";

const url = window.location.href;
var apiUrl = env.url;
var imageUrl = env.img_url;
class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: [],
            id:"",
            taskname:"",
            url:"",
            email:"",
            mobile:"",
            description:"",
            image:"",
            status:"",
            show_form: false,
            show_add_task:false
        }
    }
    componentDidMount = () => {
        this.getAllSupport();
    }
    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value


        })

    }
    fileChange = (e) => {

        this.setState({ image: e.target.files[0] })
    }
    getAllSupport = () => {
        Axios.get(`${apiUrl}/admin/all-support`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("users response", res)
            if (res.data.success) {
                this.setState({
                    task: res.data.data.task
                })
            } else {
                this.setState({
                    task: []
                })
            }
        })
    }

    show_add_task = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: false,
            show_add_task:!this.state.show_add_task,
            id:"",
            taskname:"",
            description:"",
            url:"",
            status:"Active",
            mobile:"",
            description:"",
        })
    }

    vieweditform = (e = null,val) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: !this.state.show_form,
            id:val.id,
            taskname:val.task,
            description:val.description,
            status:val.status,
            url:val.url,
            email:val.email,
            mobile:val.mobile,
        })
    }
    canceladdform = (e = null) => {
        if (e) e.preventDefault();
        this.setState({

            show_add_task: !this.state.show_add_task,
            id:"",
            taskname:"",
            description:"",
            status:"",
            url:"",
            mobile:"",
            description:"",

        })
    }

    cancelform = (e = null) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: !this.state.show_form,

            id:"",
            taskname:"",
            description:"",
            status:"",
            url:"",
            mobile:"",
            description:"",

        })
    }


    updatestatus = (e = null,id) => {
        e.preventDefault();
    //    swal("", "Do you want to update status", "");

        swal({ text: "Do you want to update status", icon: "",buttons: ["No", "Yes"] }).then(ok => {
              console.log(ok);
              if(ok){


           const formdata = new FormData();
           formdata.append('id',id);

           Axios.post(`${apiUrl}/admin/update-task-status`,formdata,{
            headers: {
                 Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
           }).then(res =>{
                    if(res.data.success){
                         this.notify('success',res.data.message)
                         this.setState({
                            task: res.data.data.task
                        })

                    }else{
                        this.notify('error',res.data.message)

                    }
              }

           )
            }
          })


       }
    removetask = (e = null,id) => {
        e.preventDefault();
        swal({ text: "Are you sure you want to delete this item?", icon: "",buttons: ["No", "Yes"] }).then(ok => {
            if(ok){
           const formdata = new FormData();
           formdata.append('id',id);

           Axios.post(`${apiUrl}/admin/remove-task`,formdata,{
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
           }).then(res =>{
                    if(res.data.success){
                         this.notify('success',res.data.message)
                        this.setState({
                            task: res.data.data.task
                        })

                    }else{
                        this.notify('error',res.data.message)
                    }
              }
           )
          }
        })
       }
       posttask = e => {
        e.preventDefault();
           if(this.state.email === ""){
               this.notify('error','Please enter email.')
           }
           if(this.state.mobile === ""){
            this.notify('error','Please enter mobile.')
           }
           const formdata = new FormData();

           formdata.append('email',this.state.email);
           formdata.append('mobile',this.state.mobile);
        Axios.post(`${apiUrl}/admin/post-support`,formdata,{
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
           }).then(res =>{
                    if(res.data.success){
                         this.notify('success',res.data.message)
                        this.setState({
                            task: res.data.data.task,
                            taskname:"",
                            description:"",
                            email:"",
                            mobile:'',
                            url:"",
                            status:""
                        })
                    }else{
                        this.notify('error',res.data.message)
                    }
              }

           )

       }
     updatetask = e => {
        e.preventDefault();
           if(this.state.email === ""){
               this.notify('error','Please enter email.')
           }
           if(this.state.mobile === ""){
            this.notify('error','Please enter mobile.')
           }
           const formdata = new FormData();
           formdata.append('email',this.state.email);
           formdata.append('id',this.state.id);
           formdata.append('mobile',this.state.mobile);
           Axios.post(`${apiUrl}/admin/post-support`,formdata,{
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
           }).then(res =>{
                    if(res.data.success){
                         this.notify('success',res.data.message)
                         this.setState({
                            task: res.data.data.task,
                            show_add_task:false
                         })
                    }else{
                        this.notify('error',res.data.message)
                    }
              }
           )
       }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    render() {
        return (
            <>
           <div className="content">
           <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1 className="title" >Support
                                    <small className="float-right">
                                          {this.state.task.length == 0 && <>  <button  type="button" onClick={this.show_add_task}  className="btn-fill btn btn-primary"  >Add Support</button></>}
                                       </small>
                                    </h1>

                                    <div className="card-body">

                                    {this.state.show_add_task && <Form onSubmit={this.posttask}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Email </label>
                                                    <input type="text" className="form-control" name="email" value={this.state.email}  onChange={(e) => {this.inputChange(e) } }  />

                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Mobile Number </label>
                                                    <input type="text" className="form-control" name="mobile" value={this.state.mobile} onChange={(e) => {this.inputChange(e) } }  />
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Button className="btn-fill" color="primary" type="button" onClick={this.canceladdform}>
                                            Cancel
                                        </Button>
                                        <Button className="btn-fill" color="primary" type="submit">
                                            Save
                                        </Button>
                                    </Form>}

                                    {this.state.show_form && <Form onSubmit={this.updatetask}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Email </label>
                                                    <input type="text" className="form-control" name="email" value={this.state.email}  onChange={(e) => {this.inputChange(e) } }  />

                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label> Mobile Number </label>
                                                    <input type="text" className="form-control" name="mobile" value={this.state.mobile} onChange={(e) => {this.inputChange(e) } }  />
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Button className="btn-fill" color="primary" type="button" onClick={this.cancelform}>
                                            Cancel
                                        </Button>
                                        <Button className="btn-fill" color="primary" type="submit">
                                            Save
                                        </Button>
                                    </Form>}
                                        <div className="table-responsive">
                                            <table className="tablesorter table">
                                                <thead className="text-primary" >
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Email</th>
                                                        <th>Mobile Number</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.task.map((val, key) => {

                                                            return <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>{val.email}</td>
                                                            <td>{val.mobile}</td>


                                                             <td>

                                                             <a href="" onClick={(e) => {this.vieweditform(e ,val)}} ><i className="fas fa-edit"></i></a>
                                                             &nbsp;&nbsp;


                                                             </td>

                                                        </tr>
                                                    })}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        );
    }
}
export default Support;

