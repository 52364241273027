import React from "react";
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Form,
   Input,
   Row,
   Col,
   Table,
   Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
var apiUrl = env.url;

var imageUrl = env.img_url;
class Memberbenefit extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading:false,
         categories: [],
         detail:"",
         position:"",
         description: "",
         amount:"",
         status: "",
         id:"",
         category_name: "",
         position: "",
         category_icon: null,
         show_form: false,
         modal: false,
         selected_category: null
      }
   }
   componentDidMount = () => {
      this.getALlData();
   }
   inputChange = (e) => {
      this.setState({
         [e.target.name]: e.target.value
      })
   }
   onFileChange = (e) => {
      this.setState({ category_icon: e.target.files[0] })
   }

   saveData = e => {
      e.preventDefault();


      const formData = new FormData();
      formData.append('member_data', this.state.detail);
      if(this.state.id){
        formData.append('id', this.state.id);
        Axios.post(`${apiUrl}/admin/update/sathi/benefit`, formData, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            if (res.data.success) {
               this.notify("success", res.data.message);
               this.showForm();
               this.getALlData();
            } else {
               this.notify("error", res.data.message);
            }
         })
      }else{
        Axios.post(`${apiUrl}/admin/save/sathi/benefit`, formData, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            if (res.data.success) {
               this.notify("success", res.data.message);
               this.showForm();
               this.getALlData();
            } else {
               this.notify("error", res.data.message);
            }
         })
      }

   }

   getALlData = () => {
      Axios.get(`${apiUrl}/admin/sathi/benefit`, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            this.setState({
               categories: res.data.categories
            })
         }
         else {
            this.setState({
               categories: []
            })
         }
      })
   }
   statusChangeCategory = (e, id) => {
      e.preventDefault();
      Axios.post(`${apiUrl}/admin/category/change/status`, { category_id: id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.getALlData();
         }
         else {
            this.notify("error", res.data.message);
         }
      }, err => {
         this.notify("error", "Something went wrong");
         console.log("status error", err)
      })
   }

   editForm = (e ,val) => {
    if (e) e.preventDefault();
    this.setState({
        show_form: true,
       detail: val.data,
       id:val.id,

    })
 }

   showForm = (e = null) => {
      if (e) e.preventDefault();
      this.setState({
         show_form: !this.state.show_form,
         category_icon: null,
         category_name: "",
         detail:"",
         description: "",
         status: "",
         id:"",
         position: "",
         amount:""
      })
   }
   positionChangeCategory = (e, cat) => {
      e.preventDefault();
      this.setState({
         modal: true,
         selected_category: cat,
         position: cat.position
      })
   }
   notify = (type, message) => {
      var atype = type === "error" ? "danger" : type;
      var options = {};
      options = {
         place: process.env.REACT_APP_TOAST_POSITION,
         message: (
            <div>
               <div>
                  {message}
               </div>
            </div>
         ),
         type: atype,
         icon: "tim-icons icon-bell-55",
         autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
   };
   toggle = (e) => {
      this.setState({
         modal: !this.state.modal
      })
   }
   changePosition = (e) => {
      if (this.state.selected_category.position != this.state.position) {
         var data = {
            new_position: this.state.position,
            id: this.state.selected_category.id,
            old_position: this.state.selected_category.position
         }
         Axios.post(`${apiUrl}/admin/change/category/position`, data, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
         }).then(res => {
            this.notify("success", "Posiotn changed successfully.");
            this.setState({
               categories: res.data.categories,
               modal: false
            })
         })
      }
   }
   commingSoonActioon = (e, cat) => {
      e.preventDefault();
      console.log(cat)
      Axios.post(`${apiUrl}/admin/comming/soon/manage`, { category_id: cat.id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.notify(res.data.success ? "success" : "error", res.data.message);
         this.getALlData();

      })
   }
   changeAccess = (e, val, type) => {
      e.preventDefault();
      this.setState({
         loading: true
      })
      var data = {
         type: type,
         category_id: val.id,
         status: e.target.checked ? "yes" : "no"
      }
      Axios.post(`${apiUrl}/admin/categories/update/access`, data, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.setState({
            loading: false
         })
         this.notify(res.data.success ? "success" : "error", res.data.message);
         if (res.data.success) {
            this.setState({
               categories: res.data.categories
            })
         }

      },err=>{

         this.setState({
            loading: false
         })
         this.notify("error", "Something went wrong");
      })
   }
   render() {
      return (
         <>
         {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
            <div className="content">
               <div className="react-notification-alert-container">
                  <NotificationAlert ref="notificationAlert" />
               </div>
               <Row>
                  <Col md="12">
                     <Card>
                        <CardHeader>
                           <h3 className="title">Saathi Benefit
                              {!this.state.show_form && this.state.categories.length == 0 && <small className="float-right"><a href="" className="btn btn-success" onClick={(e) => { this.showForm(e) }} >+ Add New</a></small>}
                           </h3>
                        </CardHeader>
                        <CardBody>
                           {this.state.show_form && <Form onSubmit={this.saveData}>
                              <Row>
                              <Col md="6">
                                    <FormGroup>
                                       <label>Details</label>
                                       <CKEditor
                                          editor={ ClassicEditor }
                                          data={this.state.detail}

                                          onReady={ editor => {
                                             // You can store the "editor" and use when it is needed.
                                             console.log( 'Editor is ready to use!', editor );
                                          } }
                                          onChange={ ( event, editor ) => {
                                             const data = editor.getData();
                                             this.setState({
                                                detail:data
                                             })
                                             console.log( { event, editor, data } );
                                          } }
                                          onBlur={ ( event, editor ) => {
                                             console.log( 'Blur.', editor );
                                          } }
                                          onFocus={ ( event, editor ) => {
                                             console.log( 'Focus.', editor );
                                          } }
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                 Cancel
                              </Button>
                              <Button className="btn-fill" color="primary" type="submit">
                                 Save
                              </Button>
                           </Form>}
                           <Table className="tablesorter" responsive>
                              <thead className="text-primary">
                                 <tr>
                                    <th>Sr.</th>
                                    <th>Data</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.categories.map((val, key) => {
                                    return <tr key={key}>
                                       <td>{key+1}</td>
                                       <td>{val.data}</td>
                                       <td>
                                          <a href="" onClick={(e) => { this.editForm(e, val) }} >
                                            <i className="fa fa-pencil-square-o" title="Edit Category"></i>

                                          </a>
                                       </td>
                                    </tr>
                                 })}
                              </tbody>
                           </Table>
                        </CardBody>
                        {/* <CardFooter>

                                </CardFooter> */}
                     </Card>
                  </Col>
               </Row>
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} >
               <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
               <ModalBody>
                  <p>Changing position warning</p>
                  <FormGroup>
                     <label>Position</label>
                     <Input name="position" value={this.state.position} placeholder="position" type="number" min={1} max={this.state.categories.length} onChange={(e) => { this.inputChange(e) }} />
                  </FormGroup>
               </ModalBody>
               <ModalFooter>
                  <Button color="primary" onClick={this.changePosition}>Update</Button>{' '}
                  <Button color="secondary" onClick={this.toggle}>Cancel</Button>
               </ModalFooter>
            </Modal>
         </>
      );
   }
}

export default Memberbenefit;
