import React from "react";

import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import SimpleCrypto from "simple-crypto-js";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);
class Bankdetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null
        }
    }
    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");

        }
        // console.log(process.env.REACT_APP_USER_DETAILS)
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        });
        setTimeout(() => {
            // console.log(this.state.user)
        }, 200);
    }

    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
    render() {
        return <>
            <Userheader user={this.state.user} />
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="inner-bank-details">
                                <h2><small>Beneficiary Name: </small>Online Saathi India</h2>
                                {this.state.user && <h2><small>Account Number: </small>ONSA{this.state.user.mobile}</h2>}
                                <h2><small>IFSC: </small>ICIC0000106</h2>
                            </div>
                            <hr />
                            <div className="instruction-sec">
                                <h4>Instructions</h4>
                                <ol>
                                    <li> Log into your Net Banking / Mobile Banking.</li>
                                    <li>Add  above account as beneficiary.</li>
                                    <li>Transfer fund to the above beneficiary via NEFT, RTGS or IMPS.</li>
                                    <li>Update your Online Saathi account wallet fast.</li>
                                </ol>
                            </div>
                            {/* <div class=" form_per_details">
                                <a href="#">Customer Registration </a><em>/ Customer Registration</em>
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h2 class="mb-0">
                                            <p class="manage_nar">
                                                Manage
                                            </p>
                                        </h2>
                                    </div>

                                    <div class="card_wrap1">
                                        <div class="card-body">
                                            <h2> PERSONAL DETAILS</h2>
                                            <form>
                                                <div class="form-row mb30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Mobile Number:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <input type="number" class="form-control" id="" placeholder="8488856251" />
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Membership Id:</label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <input type="text" class="form-control" id="" placeholder="Membership Id:" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row mt30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Name:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <input type="text" class="form-control" id="" placeholder="NARESH" />
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-6">
                                                            <input type="text" class="form-control" id="" placeholder="KUMAR" />
                                                        </div>
                                                        <div class="form-inline col-md-6">
                                                            <input type="text" class="form-control" id="" placeholder="SIJAPATI" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row mt30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Nationality:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>Nepal</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Marital Status:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>Married</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row mt30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Date Of Birth:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <input type="date" class="form-control" id="" placeholder="10/09/1994" />
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Gender:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>Male</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row mt30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Father/Mother Name:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <input type="text" class="form-control" id="" placeholder="10/09/1994" />
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Occupation:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>Armed police personnel (Police, Army etc.)</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row mt30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Source Of Fund:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>Bonus / Commission</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card_wrap2">
                                        <div class="card-body">
                                            <h2>TEMPORARY ADDRESS</h2>
                                            <form>
                                                <div class="form-row mb30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Country:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>India</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">State<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>ANDAMAN AND NICOBAR ISLANDS</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row mb30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">District<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>Nicobar</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Address</label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <input type="text" class="form-control" id="" placeholder="NARODA" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card_wrap3">
                                        <div class="card-body">
                                            <h2>TEMPORARY ADDRESS</h2>
                                            <form>
                                                <div class="form-row mb30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Country:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>India</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">State<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>ANDAMAN AND NICOBAR ISLANDS</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row mb30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">District<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>Nicobar</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Address</label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <input type="text" class="form-control" id="" placeholder="NARODA" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-42">
                                    <div class="card_wrap4">
                                        <div class="card-body">
                                            <h2>IDENTITY DETAILS</h2>
                                            <form>
                                                <div class="form-row mb30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">ID Type:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>Passport</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">ID Number:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>FASDF</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row mb30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Id Issue Date:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <input type="date" class="form-control" id="" placeholder="10/21/2021" />
                                                        </div>
                                                    </div>
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">Id Expiry Date:<span>*</span></label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <input type="date" class="form-control" id="" placeholder="mm/dd/yyyy" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row mb30">
                                                    <div class="form-inline col-md-6">
                                                        <div class="form-inline col-md-4">
                                                            <label for="">ID Issued Place:</label>
                                                        </div>
                                                        <div class="form-inline col-md-8">
                                                            <select class="custom-select">
                                                                <option selected>Select</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb0">
                                    <div class="card_wrap5">
                                        <div class="card-body">
                                            <h2>Neplease Citizenship Information</h2>
                                            <form>
                                                <div class="form-row">
                                                    <div class="form-group col-md-4">
                                                        <div class="form-inline">
                                                            <label for="">ID Number:<span>*</span></label>
                                                        </div>
                                                        <div class="form-group ">
                                                            <input type="text" class="form-control" id="" placeholder="DSFA" />
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <div class="form-inline">
                                                            <label for="">Issued Place<span>*</span></label>
                                                        </div>
                                                        <div class="form-group ">
                                                            <select class="custom-select">
                                                                <option selected>Achham</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card_wrap6">
                                        <div class="card-body">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                                                    <label class ="form-check-label" for="defaultCheck1">
                                                    By Clicking the check box, I accept the below declaration.
                                                    </label>
                                                    <ol>
                                                        <li>I hereby declare, that the information filled here in above is correct as per the information provided by the customer.</li>
                                                        <li>The customer has approached me in person for the remittance service.</li>
                                                        <li>The customer is a Nepal Migrant.</li>
                                                        <li>The customer ID document have been verified in original.</li>
                                                        <h4>IME FOREX INDIA PRIVATE LIMITED SUBHLAXMI MULTI SERVICES INDIA PVT LT D HEAD OFFICE</h4>
                                                        <li>The customer has not registered under any other mobile no, or under any other ID document on Extension numbers 206 , 207 and 209 or email us at support@imeforex.com for related issues.</li>
                                                    </ol>
                                                </div>
                                                <button type="submit" class="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* form_per_details end */}
                        </div>

                    </div>
                </div>
            </section>
            <Userfooter />
        </>;
    }
}
export default Bankdetails;

