import React from "react";
import { Button, Card, CardHeader, CardBody, Row, Col, FormGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";
import Axios from "axios";
import QRCode from "qrcode.react";
import NotificationAlert from "react-notification-alert";
import { Pagination } from 'react-laravel-paginex';
import { CSVLink } from "react-csv";
import SimpleCrypto from "simple-crypto-js";
import ImeUploadDocs from "./forms/ImeUploadDocs";
import ImeCsRegister from "./forms/ImeCspRegister";
import Prabhucsp from "./forms/Prabhucsp";
import swal from "sweetalert";
import env from "variables/constants";
import KycForm from "./forms/KycForm";
import html2canvas from "html2canvas";



const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);
var apiUrl = env.url;
var imageUrl = env.img_url;
const moment = require('moment');
class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_user_id: null,
            payment_method_agent: 'free',
            remittance_type: "",
            slab_id:"",
            rewardamount: 0,
            AllSlab:[],
            reward_comment: "",
            viewRewardModal: false,
            viewCertificateModal: false,
            loading: false,
            dropdownOpen: false,
            allData:[],
            exceldata:[],
            showCspForm: false,
            uploadDocumentForm: false,
            users: [],
            placement: "top",
            status: "",
            banner_image: null,
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 15,
                prev_page_url: null,
                to: 0,
                total: 0
            },
            show_form: false,
            viewModal: false,
            blockModal: false,
            imageModal: false,
            input_block_reason: "",
            deductModal: false,
            slabModal:false,
            selected_user: null,
            deduct_from: "remit_balance",
            deduct_amount: 0,
            deduct_reason: "",
            keyword: "",
            additional_course: [],
            alternate_occupation_type: [],
            bank_account_type: [],
            csp_address_proof_type: [],
            csp_business_type: [],
            csp_device: [],
            csp_device_connectivity_type: [],
            csp_document_type: [],
            csp_registration_type: [],
            customer_identification_type_indian: [],
            customer_identification_type_neplease: [],
            educational_qualification: [],
            gender: [],
            marital_status: [],
            occupation: [],
            owner_category: [],
            owners_address_proof_type: [],
            owners_id_type: [],
            physically_handicapped: [],
            purpose_of_remittance: [],
            relationship: [],
            source_of_fund: [],
            transaction_cancel_reason: [],
            states: [],
            districts: [],
            ostates: [],
            odistricts: [],
            municipalities: [],
            banks: [],
            registration_type: "",
            bussiness_type: "",
            pan_number: "",
            csp_country: "",
            csp_state: "",
            csp_district: "",
            municipality: "",
            csp_city: "",
            house_number: "",
            road_name: "",
            pin_code: "",
            address_proof_type: "",
            contact_person_name: "",
            mobile_number: "",
            mobile_number2: "",
            land_line_number: "",
            csp_email: "",
            bank: "",
            csp_bank_account_type: "",
            bank_account_number: "",
            ifsc: "",
            device: "",
            connectivity_type: "",
            provider: "",
            start_time: "",
            end_time: "",
            has_week_off: "",
            off_day: "",
            owners_name: "",
            owners_gender: "",
            owners_dob: "",
            owners_father_name: "",
            owners_spouse_name: "",
            owners_category: "",
            owners_is_handicapped: "",
            owners_mobile_number: "",
            owners_contact_number: "",
            owners_email: "",
            owners_alternate_occupation: "",
            owners_pan_card: "",
            owners_id_types: "",
            owners_id_number: "",
            owners_country: "",
            owners_state: "",
            owners_district: "",
            owners_city: "",
            owners_house_number: "",
            owners_road_name: "",
            owners_pin_code: "",
            owners_village_code: "",
            owners_address_prrof_type: "",
            owners_qualification: "",
            owners_additional_course: "",
            owners_institute_name: "",
            owners_date_of_passed: "",
            uploadOther: null,
            documents: [],
            excelusers: [],
            tempLoginModal: false,
            input_notice: "",
            imagesrcView: null,
            sort_by: "remit_desc",
            status_filter: "all",
            allpermission: [],
            show_edit: false,
            caseback: false,
            balance: false,
            excel: false,
            otp_verified: false,
            otp: "",
            selected_user: null,
            otp_token: "",
            upgrade_type: "",
            csp_status: "",
            atype: "IME",
            submit_status: "pending",
            headers: {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }
        }
    }

    componentDidMount = () => {
        let search = this.props.history.location.search;
        let params = new URLSearchParams(search);
        let user_id = params.get('aid');
        let atype = params.get('type');
        if (user_id) {
            this.setState({
                selected_user_id: +user_id,
                atype: atype
            })
        }
        if (atype) {
            this.setState({
                agent_type: atype
            })
        }
        setTimeout(() => {
            this.getAllUsers();
            this.permissions();
            this.getAllSlab()
        }, 200);
    }


    getAllSlab = () => {
        Axios.get(`${apiUrl}/admin/all/slab`, {
           headers: {
              Authorization: "Bearer " + sessionStorage.getItem("_access")
           }
        }).then(res => {
           console.log("categories response", res)
           if (res.data.success) {
              this.setState({
                 AllSlab: res.data.categories
              })
           }
           else {
              this.setState({
                AllSlab: []
              })
           }
        })
     }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    toggleDropdown = (id) => {
        this.setState({
            dropdownOpen: this.state.dropdownOpen === id ? 0 : id
        })
    }
    permissions = () => {

        this.setState({
            allpermission: JSON.parse(sessionStorage.getItem("adminpermission")),
            cuser: Cryp.decrypt(sessionStorage.getItem("cuser")),

        })
        setTimeout(() => {

            this.state.allpermission.map((val, key) => {
                if (val.page_name === "edit-users") {
                    this.setState({
                        show_edit: true
                    })
                } else if (val.page_name === "cashback") {
                    this.setState({
                        caseback: true
                    })
                } else if (val.page_name === "deduct-balance") {
                    this.setState({
                        balance: true
                    })
                } else if (val.page_name === "export-excel") {
                    this.setState({
                        excel: true
                    })
                }
            })
            //   console.log("truepage",this.state.show_edit);
            console.log("cuser", this.state.cuser);
            console.log("allpermission", this.state.allpermission);

        }, 500);

    }
    getAllUsers = (page = 1, keyword = null) => {
        var data = {
            keyword: keyword ? keyword : this.state.keyword,
            sort_by: this.state.sort_by,
            status_filter: this.state.status_filter
        }
        if (this.state.selected_user_id) {
            data.selected_user_id = this.state.selected_user_id;
        }
        Axios.post(`${apiUrl}/admin/all/users?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.users,
                    users: res.data.users.data,
                    become_saathi_fees: res.data.become_saathi_fees,
                    allData:res.data.allData
                });
                if (this.state.selected_user_id && res.data.single) {
                    this.makeDealer(null, res.data.single, true);
                    this.setState({
                        upgrade_type: "dealer",
                        remittance_type: this.state.atype
                    })
                }
                setTimeout(() => {
                    var exceldata = [];
                    this.state.allData.forEach((usr, ki) => {
                        var dat = {
                            "JOINING DATE": moment(usr.created_at).format('lll'),
                            "Name": usr.name,
                            "Mobile": usr.mobile,
                            "Email": usr.email,
                            "Wallet Balance": usr.wallet_balance,
                            "Remittance Balance": usr.remit_balance,
                            "Reference": usr.referer ? usr.referer : "Self",
                            "Country": usr.country,
                            "State": usr.state,
                            "District": usr.district,
                            "City": usr.city,
                            "Address": usr.address,
                            "Status": usr.status,
                            "Pincode": usr.pincode,
                            "CSP Code": usr.csp_code,

                        };
                        exceldata.push(dat);
                    });
                    this.setState({
                        exceldata: exceldata,
                    })
                }, 200);
                // this.getAllUsersExcel();
            }
            else {
                this.setState({
                    users: []
                })
            }
        })
    }
    getAllUsersExcel = (page = 1, keyword = null) => {
        var data = {
            keyword: keyword ? keyword : this.state.keyword,
            sort_by: this.state.sort_by,
            status_filter: this.state.status_filter,
            forexcel: "all"
        }
        Axios.post(`${apiUrl}/admin/all/users?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("users response", res)
            if (res.data.success) {
                var excelusers = [];
                res.data.users.forEach((usr, ki) => {
                    var xx = {
                        "JOINING DATE": moment(usr.created_at).format('lll'),
                        "Name": usr.name,
                        "Mobile": usr.mobile,
                        "Email": usr.email,
                        "Wallet Balance": usr.wallet_balance,
                        "Remittance Balance": usr.remit_balance,
                        "Reference": usr.referer ? usr.referer : "Self",
                        "Country": usr.country,
                        "State": usr.state,
                        "District": usr.district,
                        "City": usr.city,
                        "Address": usr.address,
                        "Status": usr.status,
                        "Pincode": usr.pincode,
                        "CSP Code": usr.csp_code,

                    };
                    excelusers.push(xx);
                })
                this.setState({
                    excelusers: excelusers
                })
            }
            else {
                this.setState({
                    users: []
                })
            }
        })
    }


    downloadMemberID = () => {
        const elementToCapture = document.getElementById('view-second-id-head');

        html2canvas(elementToCapture).then((canvas) => {
          const screenDataUrl = canvas.toDataURL('image/png');

          const newWindow = window.open();
          newWindow.document.write('<img src="' + screenDataUrl + '" style="width:100%;">');
          newWindow.document.close();

          newWindow.print();
        });
      };

      downloadFirstCertificate = () => {
        const elementToCapture = document.getElementById('view-first-certificate-head');

        html2canvas(elementToCapture).then((canvas) => {
          const screenDataUrl = canvas.toDataURL('image/png');

          const newWindow = window.open();
          newWindow.document.write('<img src="' + screenDataUrl + '" style="width:100%;">');
          newWindow.document.close();

          newWindow.print();
        });
      };

      downloadSecondCertificate = () => {
        const elementToCapture = document.getElementById('view-second-certificate-head');

        html2canvas(elementToCapture).then((canvas) => {
          const screenDataUrl = canvas.toDataURL('image/png');

          const newWindow = window.open();
          newWindow.document.write('<img src="' + screenDataUrl + '" style="width:100%;">');
          newWindow.document.close();

          newWindow.print();
        });
      };


    getStaticData = () => {
        // this.setState({ loading: true })
        Axios.get(apiUrl + "/remittance/ime-forex/get/all/static/data", {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("response", res)
            this.setState(res.data.users);
            // this.setState({ loading: false })
        }).catch(error => {
            console.error("Error fetching static data:", error);
        });
    }
    makeDealer = (e, val, x = null) => {
        if (e) e.preventDefault();
        this.getStaticData();
        this.setState({
            selected_user: val,
            showCspForm: true,
            uploadDocumentForm: false
        });
        this.getDocumentsList(val.id);
        this.setState(val);
        this.getCspDetails(val.id);
        // if (window.confirm(`Role of "${val.name}" will be changed to dealer.`)) {
        //     Axios.post(`${apiUrl}/admin/make/dealer`, { user_id: val.id }, {
        //         headers: {
        //             Authorization: "Bearer " + sessionStorage.getItem("_access")
        //         }
        //     }).then(res => {
        //         console.log("make dealer response", res)
        //         this.getAllUsers(this.state.paginatedata.current_page);
        //         if (res.data.success) {
        //             this.notify('success', res.data.message);
        //         }
        //         else {
        //             this.notify('error', res.data.message);
        //         }
        //     }, err => {
        //         console.log("make dealer error", err)
        //         this.notify('error', "Something went wrong.");
        //     })
        // }
        console.log(val)
    }
    changePage = data => {
        console.log(data)
        this.getAllUsers(data.page);
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    viwDetails2 = (e, user) => {
        e.preventDefault();
        sessionStorage.setItem("id",user.id)
        window.location.href="/admin/user-detail";
    }


    viwDetails = (e, user, res) => {
        e.preventDefault();
        this.setState(user);
        console.log("userrrrrr",user);
        this.getStaticData();
        this.toggleViewModal();
    }

    handleError = (event) => {
        this.setState({ profile_pic: "/uploads/static/anime3.png" });
        console.log("user Identity Photo", event)
    }
    toggleViewModal = () => {
        this.setState({
            viewModal: !this.state.viewModal
        });
    }
    toggleBlockModal = () => {
        this.setState({
            blockModal: !this.state.blockModal
        })
    }
    toggleImageModal = (e, x = null) => {
        this.setState({
            imageModal: !this.state.imageModal,
            imagesrcView: x
        })
    }
    toggleTempLoginModal = () => {
        this.setState({
            tempLoginModal: !this.state.tempLoginModal
        })
    }
    blockUser = () => {
        if (this.state.input_block_reason === "") {
            this.notify("error", "Please enter reason to block.");
            return false;
        }

        Axios.post(apiUrl + "/admin/block/user", { id: this.state.id, reason: this.state.input_block_reason }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            this.getAllUsers(this.state.paginatedata.current_page);
            if (type === "success") {
                this.setState({
                    status: "blocked",
                    block_reason: this.state.input_block_reason,
                    blockModal: false,
                    input_block_reason: ""
                })
            }
        })
    }
    approveUser = () => {
        if (this.state.input_notice === "") {
            this.notify("error", "Please add notice.");
            return false;
        }

        Axios.post(apiUrl + "/admin/approve/user", { id: this.state.id, status: "temp_login", notice: this.state.input_notice }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            this.getAllUsers(this.state.paginatedata.current_page);
            if (type === "success") {
                this.setState({
                    status: "temp_login",
                    notice: this.state.input_notice,
                    blockModal: false,
                    input_notice: "",
                    tempLoginModal: false
                })
            }
        })
    }
    activeUser = e => {
        e.preventDefault();
        Axios.post(apiUrl + "/admin/make/user/acive", { id: this.state.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.getAllUsers(this.state.paginatedata.current_page);
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            if (type === "success") {
                this.setState({
                    status: "active",
                    block_reason: "",
                    input_block_reason: ""
                })
            }
        })
    }

    toggleSlabModal = () => {
        this.setState({
            slabModal: !this.state.slabModal
        })
    }
    showSlabModal = (e, val) => {
        e.preventDefault();
        this.setState({
            selected_user: val,
            slabModal: true
        })
    }

    slabSubmit = () => {
        if (this.state.slab_id === "") {
            this.notify('error', "Please choose slab.");
            return false;
        }
        var data = {
            slab_id: this.state.slab_id,
            user_id: this.state.selected_user.id,
        }
        Axios.post(apiUrl + `/admin/assign/slab`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            if (type === "success") {
                this.getAllUsers(this.state.paginatedata.current_page);
                this.setState({
                    selected_user: null,
                    slab_id:"",
                    slabModal: false
                })
            }
            this.notify(type, res.data.message);
            console.log(res)
        })
    }
    toggleDeductModal = () => {
        this.setState({
            deductModal: !this.state.deductModal
        })
    }
    deductBalance = (e, val) => {
        e.preventDefault();
        this.setState({
            selected_user: val,
            deductModal: true
        })
    }
    deductBalanceSubint = () => {
        if (this.state.deduct_amount <= 0) {
            this.notify('error', "Amount should be more than 0.");
            return false;
        }
        if (this.state.deduct_from === "wallet_balance") {
            if (this.state.selected_user.wallet_balance < this.state.deduct_amount) {
                this.notify("error", "Amount should be less than or equal to user's wallet balance.");
                return false;
            }
        }
        if (this.state.deduct_from === "remit_balance") {
            if (this.state.selected_user.remit_balance < this.state.deduct_amount) {
                this.notify("error", "Amount should be less than or equal to user's wallet balance.");
                return false;
            }
        }
        if (this.state.deduct_reason === "") {
            this.notify('error', "Please enter reason.");
            return false;
        }
        var data = {
            deduct_amount: this.state.deduct_amount,
            user_id: this.state.selected_user.id,
            deduct_from: this.state.deduct_from,
            deduct_reason: this.state.deduct_reason,
        }
        Axios.post(apiUrl + `/admin/deduct/balance`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            if (type === "success") {
                this.getAllUsers(this.state.paginatedata.current_page);
                this.setState({
                    deduct_amount: 0,
                    selected_user: null,
                    deduct_from: "remit_balance",
                    deduct_reason: "",
                    deductModal: false
                })
            }
            this.notify(type, res.data.message);
            console.log(res)
        })
    }
    searchUser = e => {
        e.preventDefault();
        var keyword = e.target.value;
        this.setState({
            keyword: keyword
        })
        setTimeout(() => {
            if (keyword.length > 2 || keyword === "") {
                this.getAllUsers(1, keyword);
            }
        }, 200);
    }
    handleCountryChange = (e, param, val = null) => {
        var value = val ? val : e.target.value;
        var data = { country_id: value }
        var x = 'states';
        if (param === 'owners_state') {
            x = 'ostates';
            this.setState({ owners_country: value, loading: true })
        }
        else {
            this.setState({ csp_country: value, loading: true })
        }
        this.getBanks(value);
        Axios.post(apiUrl + "/remittance/ime-forex/get/state", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                [x]: res.data.data,
                loading: false
            })
        })
    }
    handleInputState = (e, param, val = null) => {
        var value = val ? val : e.target.value;
        var x = 'districts';
        if (param === 'owners_district') {
            x = 'odistricts';
            this.setState({ owners_state: value, loading: true })
        }
        else {
            this.setState({ csp_state: value, loading: true })

        }
        var data = { state_id: value }
        Axios.post(apiUrl + "/remittance/ime-forex/get/district", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                [x]: res.data.data,
                loading: false
            })
        })
    }

    handleInputDistrict = (e, param, val = null) => {
        var value = val ? val : e.target.value;
        if (param === 'municipality') {
            this.setState({ csp_district: value });
        }
        else {
            this.setState({ owners_district: value });
        }
        if (this.state.csp_country === "NPL") {
            var data = { district_id: value }
            this.setState({
                loading: true
            })
            Axios.post(apiUrl + "/remittance/ime-forex/get/municipality", data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                this.setState({
                    municipalities: res.data.data,
                    loading: false
                })
            })
        }
    }

    getBanks = (country_id) => {
        this.setState({
            loading: true
        })
        Axios.post(apiUrl + "/remittance/ime-forex/get/banks", { country_id: country_id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                loading: false
            })
            this.setState({ banks: res.data.data })
        }, err => {
            this.setState({
                loading: false
            })
        })
    }
    cancelCSPReg = e => {
        e.preventDefault();
        this.setState({ showCspForm: false })
    }
    submitCSP = e => {
        e.preventDefault();
        var data = {
            user_type: this.state.upgrade_type,
            registration_type: this.state.registration_type,
            bussiness_type: this.state.bussiness_type,
            cspname: this.state.cspname,
            pan_number: this.state.pan_number,
            country: this.state.csp_country,
            state: this.state.csp_state,
            district: this.state.csp_district,
            municipality: this.state.municipality,
            city: this.state.csp_city,
            house_number: this.state.house_number,
            road_name: this.state.road_name,
            pin_code: this.state.pin_code,
            address_proof_type: this.state.address_proof_type,
            contact_person_name: this.state.contact_person_name,
            mobile_number: this.state.mobile_number,
            mobile_number2: this.state.mobile_number2,
            land_line_number: this.state.land_line_number,
            email: this.state.csp_email,
            bank: this.state.bank,
            bank_account_type: this.state.csp_bank_account_type,
            bank_account_number: this.state.bank_account_number,
            ifsc: this.state.ifsc,
            device: this.state.device,
            connectivity_type: this.state.connectivity_type,
            provider: this.state.provider,
            start_time: this.state.start_time,
            end_time: this.state.end_time,
            has_week_off: this.state.has_week_off,
            off_day: this.state.off_day,
            owners_name: this.state.owners_name,
            owners_gender: this.state.owners_gender,
            owners_dob: this.state.owners_dob,
            owners_father_name: this.state.owners_father_name,
            owners_spouse_name: this.state.owners_spouse_name,
            owners_category: this.state.owners_category,
            owners_is_handicapped: this.state.owners_is_handicapped,
            owners_mobile_number: this.state.owners_mobile_number,
            owners_contact_number: this.state.owners_contact_number,
            owners_email: this.state.owners_email,
            owners_alternate_occupation: this.state.owners_alternate_occupation,
            owners_pan_card: this.state.owners_pan_card,
            owners_id_type: this.state.owners_id_types,
            owners_id_number: this.state.owners_id_number,
            owners_country: this.state.owners_country,
            owners_state: this.state.owners_state,
            owners_district: this.state.owners_district,
            owners_city: this.state.owners_city,
            owners_house_number: this.state.owners_house_number,
            owners_road_name: this.state.owners_road_name,
            owners_pin_code: this.state.owners_pin_code,
            owners_village_code: this.state.owners_village_code,
            owners_address_prrof_type: this.state.owners_address_prrof_type,
            owners_qualification: this.state.owners_qualification,
            owners_additional_course: this.state.owners_additional_course,
            owners_institute_name: this.state.owners_institute_name,
            owners_date_of_passed: this.state.owners_date_of_passed,
            user_id: this.state.selected_user.id
        }
        if (data.user_type === "") {
            this.notify("error", "Please select upgrade type.");
            return false;
        }
        if (data.registration_type === "") {
            this.notify("error", "Please select Registration type.");
            return false;
        }
        if (data.bussiness_type === "") {
            this.notify("error", "Please select Business type.");
            return false;
        }
        if (data.cspname === "") {
            this.notify("error", "Please CSP name.");
            return false;
        }
        if (data.pan_number === "") {
            this.notify("error", "Please enter pan number.");
            return false;
        }
        if (data.country === "") {
            this.notify("error", "Please select country.");
            return false;
        }
        if (data.state === "") {
            this.notify("error", "Please select state.");
            return false;
        }
        if (data.district === "") {
            this.notify("error", "Please select district.");
            return false;
        }
        if (data.municipality === "" && data.country === "NPL") {
            this.notify("error", "Please select municipality.");
            return false;
        }
        if (data.city === "") {
            this.notify("error", "Please enter city.");
            return false;
        }
        if (data.pin_code === "") {
            this.notify("error", "Please enter pincode.");
            return false;
        }
        if (data.address_proof_type === "") {
            this.notify("error", "Please select address proof type.");
            return false;
        }
        if (data.contact_person_name === "") {
            this.notify("error", "Please enter contact person name.");
            return false;
        }
        if (data.mobile_number === "") {
            this.notify("error", "Please enter mobile number.");
            return false;
        }
        if (data.email === "") {
            this.notify("error", "Please enter email.");
            return false;
        }
        if (data.bank === "") {
            this.notify("error", "Please select Bank.");
            return false;
        }
        if (data.bank_account_type === "") {
            this.notify("error", "Please select bank account type.");
            return false;
        }
        if (data.bank_account_number === "") {
            this.notify("error", "Please enter bank account number.");
            return false;
        }
        if (data.ifsc === "") {
            this.notify("error", "Please enter IFSC.");
            return false;
        }
        if (data.device === "") {
            this.notify("error", "Please select device.");
            return false;
        }
        if (data.connectivity_type === "") {
            this.notify("error", "Please select connectivity type.");
            return false;
        }
        if (data.provider === "") {
            this.notify("error", "Please select internet service provider.");
            return false;
        }
        if (data.start_time === "") {
            this.notify("error", "Please select business start time.");
            return false;
        }
        if (data.end_time === "") {
            this.notify("error", "Please select business end time.");
            return false;
        }
        if (data.has_week_off === "") {
            this.notify("error", "Please select if you has week off.");
            return false;
        }
        if (data.has_week_off === "Y" && data.off_day === "") {
            this.notify("error", "Please select off day.");
            return false;
        }
        if (data.owners_name === "") {
            this.notify("error", "Please enter owner's name.");
            return false;
        }
        if (data.owners_gender === "") {
            this.notify("error", "Please select owner's gender.");
            return false;
        }
        if (data.owners_dob === "") {
            this.notify("error", "Please enter owner's dob.");
            return false;
        }
        if (data.owners_father_name === "") {
            this.notify("error", "Please select owner's father name.");
            return false;
        }

        if (data.owners_mobile_number === "") {
            this.notify("error", "Please enter owner's mobile number.");
            return false;
        }

        if (data.owners_pan_card === "") {
            this.notify("error", "Please enter owner's pan card.");
            return false;
        }

        if (data.owners_id_type === "") {
            this.notify("error", "Please select owner's id type.");
            return false;
        }

        if (data.owners_country === "") {
            this.notify("error", "Please select owner's country.");
            return false;
        }

        if (data.owners_state === "") {
            this.notify("error", "Please select owner's state.");
            return false;
        }

        if (data.owners_district === "") {
            this.notify("error", "Please select owner's district.");
            return false;
        }

        if (data.owners_city === "") {
            this.notify("error", "Please enter owner's city.");
            return false;
        }
        if (data.owners_road_name === "") {
            this.notify("error", "Please enter owner's road name.");
            return false;
        }
        if (data.owners_pin_code === "") {
            this.notify("error", "Please enter owner's pin code.");
            return false;
        }
        if (data.owners_village_code === "") {
            this.notify("error", "Please enter owner's village code.");
            return false;
        }
        if (data.owners_address_prrof_type === "") {
            this.notify("error", "Please select owner's address prrof type.");
            return false;
        }
        if (data.owners_qualification === "") {
            this.notify("error", "Please select owner's qualification.");
            return false;
        }
        if (data.owners_additional_course === "") {
            this.notify("error", "Please select owner's additional course.");
            return false;
        }
        if (data.owners_institute_name === "") {
            this.notify("error", "Please enter owner's institute name.");
            return false;
        }
        if (data.owners_date_of_passed === "") {
            this.notify("error", "Please enter owner's passing date.");
            return false;
        }
        this.setState({
            loading: true
        })
        console.log(data)
        Axios.post(apiUrl + "/remittance/ime-forex/csp-registration", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log(res)
            this.setState({
                loading: false
            })
            if (!res.data.success) {
                this.notify("error", res.data.message);
            }
            else {
                this.getDocumentsList(this.state.selected_user.id);
                this.setState({
                    uploadDocumentForm: true,
                })
            }
        }, err => {
            console.log(err)
        })
    }
    loadingState = x => {
        this.setState({
            loading: x
        })
    }
    submitAction = (res) => {
        if (!res.data.success) {
            this.notify("error", res.data.message);
        }
        else {
            this.notify("success", res.data.message);
        }
    }
    getDocumentsList = (id) => {
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/ime-forex/csp/documents/list/user", { user_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(Res => {
            console.log("document response", Res)
            this.setState({
                documents: Res.data.data,
                loading: false
            })
        }, err => {
            this.setState({ loading: false })
        })
    }
    onFileChange = (e, id) => {
        this.setState({ loading: true });
        var formData = new FormData();
        formData.append('image', e.target.files[0]);
        formData.append('id', id);
        formData.append('user_id', this.state.selected_user.id);
        Axios.post(apiUrl + "/remittance/ime-forex/csp/single/document/upload", formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                documents: res.data.data,
                loading: false,
                uploadOther: null
            });
        }, err => {
            this.setState({ loading: false });
        })
    }
    uplaodOtherPh = (e, id) => {
        e.preventDefault();
        this.setState({
            uploadOther: id
        })
    }
    cancelUploadOtherPh = (e, id) => {
        e.preventDefault();
        this.setState({
            uploadOther: null
        })
    }
    submitUpload = e => {
        e.preventDefault();
        this.state.documents.forEach((doc, ki) => {
            if (doc.is_required === "Yes" && !doc.file_path) {
                this.notify("error", `Please upload ${doc.document.value}`);
                return false;
            }
        })
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/ime-forex/csp/documents/upload", { user_id: this.state.selected_user.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({ loading: false });
            if (res.data.success) {
                this.setState({
                    showCspForm: false,
                    uploadDocumentForm: false
                })
                Axios.post(`${apiUrl}/admin/make/dealer`, { user_id: this.state.selected_user.id }, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("_access")
                    }
                }).then(res => {
                    console.log("make dealer response", res)
                    this.getAllUsers(this.state.paginatedata.current_page);
                    if (res.data.success) {
                        this.notify('success', res.data.message);
                    }
                    else {
                        this.notify('error', res.data.message);
                    }
                }, err => {
                    console.log("make dealer error", err)
                    this.notify('error', "Something went wrong.");
                })
            } else {
                this.notify("error", res.data.message)
            }
            console.log("upload documens fil", res)
        })
    }
    editDetailsOtp = (e, det) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/send/otp`, { user_id: det.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                editModal: true,
                otp_verified: false,
                selected_user: det,
                otp_token: res.data.otp_token
            })
        })
    }
    editDetails = (e) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/verify/otp`, { otp: this.state.otp, otp_token: this.state.otp_token }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                var det = this.state.selected_user;
                e.preventDefault();
                this.notify("success", res.data.message)
                this.setState({
                    edit_name: det.name,
                    edit_email: det.email,
                    edit_mobile: det.mobile,
                    edit_district: det.district,
                    edit_city: det.city,
                    edit_state: det.state,
                    edit_country: det.country,
                    edit_pincode: det.pincode,
                    edit_address: det.address,
                    user_id: det.id,
                    editModal: true,
                    otp_verified: true
                })
            }
            else {
                this.notify("error", res.data.message)
            }
        })
    }
    editaDetailsSubmit = e => {
        if (!this.state.edit_name || this.state.edit_name === "") {
            this.notify("error", "Please enter name");
            return false;
        }
        if (!this.state.edit_email || this.state.edit_email === "") {
            this.notify("error", "Please enter email");
            return false;
        }
        if (!this.state.edit_mobile || this.state.edit_mobile === "") {
            this.notify("error", "Please enter mobile number");
            return false;
        }
        var lengthcheck = this.state.edit_mobile.length;
        //  console.log("lenght",lengthcheck);
        if (lengthcheck !== 10) {
            this.notify("error", "Please enter correct mobile number.");
            return false;
        }
        if (!this.state.edit_address || this.state.edit_address === "") {
            this.notify("error", "Please enter address");
            return false;
        }
        if (!this.state.edit_country || this.state.edit_country === "") {
            this.notify("error", "Please enter country");
            return false;
        }
        if (!this.state.edit_state || this.state.edit_state === "") {
            this.notify("error", "Please enter state");
            return false;
        }
        if (!this.state.edit_city || this.state.edit_city === "") {
            this.notify("error", "Please enter city");
            return false;
        }
        if (!this.state.edit_district || this.state.edit_district === "") {
            this.notify("error", "Please enter district");
            return false;
        }
        if (!this.state.edit_pincode || this.state.edit_pincode === "") {
            this.notify("error", "Please enter pincode");
            return false;
        }
        var data = {
            name: this.state.edit_name,
            email: this.state.edit_email,
            mobile: this.state.edit_mobile,
            address: this.state.edit_address,
            country: this.state.edit_country,
            state: this.state.edit_state,
            city: this.state.edit_city,
            district: this.state.edit_district,
            pincode: this.state.edit_pincode,
            user_id: this.state.user_id
        }
        Axios.post(`${apiUrl}/admin/edit/user/details`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.getAllUsers(this.state.paginatedata.current_page);
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    edit_email: "",
                    edit_name: "",
                    edit_mobile: "",
                    edit_address: "",
                    edit_country: "",
                    edit_state: "",
                    edit_city: "",
                    edit_district: "",
                    edit_pincode: "",
                    editModal: false,
                    user_id: null,
                    selected_user: null
                })
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");
        })
    }
    toggleEditModal = () => {
        this.setState({
            editModal: !this.state.editModal,
            edit_email: "",
            edit_name: "",
            edit_mobile: "",
            edit_address: "",
            edit_country: "",
            edit_state: "",
            edit_city: "",
            edit_district: "",
            edit_pincode: "",
            user_id: null,
            selected_user: null,
            otp: ""
        })
    }
    allowMaintanance = (e, user) => {
        var data = {
            user_id: user.id
        }
        if (e.target.checked) {
            if (window.confirm("You are allowing this user to use app in maintanace mode.")) {
                data.panel_access = "full_in_maintanance";
            } else {
                e.target.checked = false;
            }
        }
        else {
            data.panel_access = "full";
        }
        Axios.post(`${apiUrl}/admin/change/maintanance/access`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");
        })
    }
    sendCashback = (e, user = null) => {
        e.preventDefault();
        console.log(user);
        this.setState({
            viewRewardModal: !this.state.viewRewardModal,
            selected_user: user
        })
    }

    viewCertificate = (e, user = null) => {
        e.preventDefault();
        console.log(user);
        this.setState({
            viewCertificateModal: !this.state.viewCertificateModal,
            selected_user: user
        })
    }

    submitReward = (e) => {
        e.preventDefault();
        if (this.state.rewardamount === 0) {
            this.notify("error", "Please enter amount.");
            return false;

        }
        if (!this.state.reward_comment || this.state.reward_comment === "") {
            this.notify("error", "Please enter comment.");
            return false;

        }
        var data = {
            user_id: this.state.selected_user.id,
            amount: this.state.rewardamount,
            reward_comment: this.state.reward_comment
        }
        Axios.post(`${apiUrl}/admin/send/cashback`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    selected_user: null,
                    viewRewardModal: false,
                    rewardamount: 0
                });
                this.getAllUsers();
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");

        })
    }

    getCspDetails = (user_id) => {
        this.setState({ loading: true });
        var data = { user_id: user_id }
        Axios.post(apiUrl + "/user/get/csp/form/details", data, this.state.headers).then(res => {
            var det = res.data.details;
            if (det) {
                if (det.owners_country) {
                    this.handleCountryChange(null, 'owners_state', det.owners_country);
                }
                if (det.owners_state) {
                    this.handleInputState(null, 'owners_district', det.owners_state);
                }
                if (det.owners_district) {
                    this.handleInputDistrict(null, 'omunicipality', det.owners_district);
                }
                if (det.country) {
                    this.handleCountryChange(null, 'states', det.country);
                }
                if (det.state) {
                    this.handleInputState(null, 'districts', det.state);
                }
                if (det.district) {
                    this.handleInputDistrict(null, 'municipality', det.district);
                }
                setTimeout(() => {
                    this.setState({
                        documents: res.data.documents,
                        bussiness_type: det.bussiness_type,
                        cspname: det.cspname,
                        pan_number: det.pan_number,
                        csp_country: det.country,
                        csp_state: det.state,
                        csp_district: det.district,
                        municipality: det.municipality,
                        csp_city: det.city,
                        house_number: det.house_number,
                        road_name: det.road_name,
                        pin_code: det.pin_code,
                        address_proof_type: det.address_proof_type,
                        contact_person_name: det.contact_person_name,
                        mobile_number: det.mobile_number,
                        mobile_number2: det.mobile_number2,
                        land_line_number: det.land_line_number,
                        csp_email: det.email,
                        bank: det.bank,
                        csp_bank_account_type: det.bank_account_type,
                        bank_account_number: det.bank_account_number,
                        ifsc: det.ifsc,
                        device: det.device,
                        connectivity_type: det.connectivity_type,
                        provider: det.provider,
                        start_time: det.start_time,
                        end_time: det.end_time,
                        has_week_off: det.has_week_off,
                        off_day: det.off_day,
                        owners_name: det.owners_name,
                        owners_gender: det.owners_gender,
                        owners_dob: det.owners_dob,
                        owners_father_name: det.owners_father_name,
                        owners_spouse_name: det.owners_spouse_name,
                        owners_category: det.owners_category,
                        owners_is_handicapped: det.owners_is_handicapped,
                        owners_mobile_number: det.owners_mobile_number,
                        owners_contact_number: det.owners_contact_number,
                        owners_email: det.owners_email,
                        owners_alternate_occupation: det.owners_alternate_occupation,
                        owners_pan_card: det.owners_pan_card,
                        owners_id_types: det.owners_id_type,
                        owners_id_number: det.owners_id_number,
                        owners_country: det.owners_country,
                        owners_state: det.owners_state,
                        owners_district: det.owners_district,
                        owners_city: det.owners_city,
                        owners_house_number: det.owners_house_number,
                        owners_road_name: det.owners_road_name,
                        owners_pin_code: det.owners_pin_code,
                        owners_village_code: det.owners_village_code,
                        owners_address_prrof_type: det.owners_address_prrof_type,
                        owners_qualification: det.owners_qualification,
                        owners_additional_course: det.owners_additional_course,
                        owners_institute_name: det.owners_institute_name,
                        owners_date_of_passed: det.owners_date_of_passed,
                        loading: false,
                        registration_type: det.registration_type,
                        load_form: true,
                        csp_status: det.status,
                        upgrade_type: "agent",
                        remittance_type: "IME",
                        submit_status: det.status
                    });
                }, 4000);
            } else {
                this.setState({ loading: false, load_form: true });
            }
        }, err => {
            this.setState({ loading: false, load_form: true });
        })
    }
    backBtnCsp = e => {
        if (e) e.preventDefault();
        this.getCspDetails(this.state.selected_user.id);
        this.setState({ uploadDocumentForm: false })
    }
    viewDocs = (e) => {
        e.preventDefault();
        this.getDocumentsList(this.state.selected_user.id);
        this.setState({ uploadDocumentForm: true })

    }
    uploadUrl = (e, doc) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formData = new FormData();
        formData.append('update_url', doc.file_path);
        formData.append('id', doc.id);
        formData.append('user_id', this.state.selected_user.id);
        Axios.post(apiUrl + "/remittance/ime-forex/csp/single/document/upload", formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                documents: res.data.data,
                loading: false,
                uploadOther: null
            });
        }, err => {
            this.setState({ loading: false });
        })
    }

    displayStatic = (val, name) => {
        var value = val;
        if (this.state[name]) {
            this.state[name].forEach((cou) => {
                if (val === cou.option_id) {
                    value = cou.value;
                }
            })
        }
        return value;
    }
    makeAgentWithoutIME = e => {
        if (e) e.preventDefault();
        swal({ title: "Make Agent", text: `Do you want to make ${this.state.name} an agent.`, buttons: ['No', 'Yes'] }).then(yes => {
            if (yes) {
                Axios.post(apiUrl + "/admin/make/agent/without/ime", { user_id: this.state.id, payment_method: this.state.payment_method_agent }, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("_access")
                    }
                }).then(res => {
                    if (res.data.success) {
                        this.notify('success', res.data.message);
                        setTimeout(() => {
                            // window.location.reload();
                            this.getAllUsers(this.state.paginatedata.current_page);
                        }, 1000);
                    }
                    else {
                        this.notify('error', res.data.message);
                    }
                })
            }
        })
    }
    handlePaymentMethod = (e, method) => {
        this.setState({ payment_method_agent: method });
    }
    approveUser = (e, user) => {
        if (e) e.preventDefault();
        swal({ title: "Approve " + user.name + "!", text: "Do you want to approve this user?", buttons: ["No", "Yes"] }).then(yes => {
            if (yes) {
                Axios.post(apiUrl + "/admin/approve/new/user", { user_id: user.id }, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("_access")
                    }
                }).then(res => {
                    if (res.data.success) {
                        this.notify('success', res.data.message);
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 1000);
                        this.getAllUsers(this.state.paginatedata.current_page);

                    }
                    else {
                        this.notify('error', res.data.message);
                    }
                }, err => {
                    this.notify('error', "Something went wrong!");
                })
            }
        })
    }

    render() {
        var uploadImageFunctions = {
            onFileChange: this.onFileChange,
            cancelUploadOtherPh: this.cancelUploadOtherPh,
            uplaodOtherPh: this.uplaodOtherPh,
            submitUpload: this.submitUpload,
            uploadUrl: this.uploadUrl
        }
        var imeCspRegisterFormFunctions = {
            submitCSP: this.submitCSP,
            handleInput: this.handleInput,
            handleInputDistrict: this.handleInputDistrict,
            handleInputState: this.handleInputState,
            handleCountryChange: this.handleCountryChange
        };


        return (
            <>

                {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
                <div className="content">

                    <div className="react-notification-alert-container notification_alert_con">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                {this.state.showCspForm && <CardHeader>
                                    <h3 className="title">Upgrade account of {this.state.name}
                                        <small className="float-right">
                                            <a href="/admin/all-users">Cancel</a>&nbsp;
                                            {this.state.uploadDocumentForm && <a href="" onClick={(e) => { this.backBtnCsp(e) }} >View Form</a>}
                                        </small>
                                    </h3>

                                </CardHeader>}
                                {!this.state.showCspForm && <CardHeader>
                                    <Row>
                                        <div className="col-md-12">
                                            <h3 className="title userlistsec">Users</h3>
                                            {/* {this.state.excel && <> */}
                                                <small className="float-right excel-sec">
                                                    {this.state.exceldata.length > 0 && <CSVLink data={this.state.exceldata} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"User-List-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}
                                                </small>
                                            {/* </>
                                            } */}
                                        </div>
                                        <div className="col-md-3">
                                            <div className="searchbox searchbox_nar">
                                                <input type="text" name="keyword" className="form-control" onChange={(e) => { this.searchUser(e) }} value={this.state.keyword} placeholder="Search keyword" />
                                            </div>

                                        </div>
                                        <div className="col-md-3">
                                            <label>Sort By</label>
                                            <select name="sort_by" className="form-control" onChange={e => { this.handleInput(e) }} value={this.state.sort_by}>
                                                <option value="date_asc">Latest Registered Last</option>
                                                <option value="date_desc">Latest Registered First</option>
                                                <option value="remit_desc">Wallet Balance High to Low</option>
                                                <option value="remit_asc">Wallet Balance Low to High</option>
                                                {/* <option value="wallet_desc">Wallet Balance High to Low</option> */}
                                                {/* <option value="wallet_asc">Wallet Balance Low to High</option> */}
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label>Status</label>
                                            <select name="status_filter" className="form-control" onChange={e => { this.handleInput(e) }} value={this.state.status_filter}>
                                                <option value="pending">Approval Pending</option>
                                                <option value="become_saathi">Pending Become Saathi Request</option>
                                                <option value="temp_login">Temporary Login</option>
                                                <option value="blocked">Blocked</option>
                                                <option value="active">Active</option>
                                                <option value="approved">Kyc Approved</option>
                                                 <option value="pending">Kyc Approval Pending</option>
                                                <option value="all">All</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <button type="button" className="btn btn-primary mt-4" onClick={e => this.getAllUsers()}><i className="fa fa-search"></i>Search</button>
                                        </div>
                                    </Row>
                                </CardHeader>}
                                <CardBody>
                                    {!this.state.showCspForm && <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>Joining Date</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Email</th>
                                                <th>Gender</th>
                                                <th>Wallet Balance</th>
                                                <th>Referal Code</th>
                                                <th>Slab</th>
                                                <th>Skip Maintanance</th>
                                                <th>Action</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.users.map((val, key) => {
                                                var cclass = val.status === "active" ? "bg-#F2F2F2" : "bg-danger text-white";
                                                cclass = val.status === "temp_login" ? "bg-info text-white" : cclass;
                                                cclass = val.status === "pending" ? "bg-light text-white" : cclass;
                                                var sr = (this.state.paginatedata.per_page * (this.state.paginatedata.current_page - 1)) + key + 1;
                                                var bal = val.remit_balance && val.remit_balance > 0 ? val.remit_balance : val.wallet_balance;
                                                return <tr key={key} className={cclass}>
                                                    <td>{sr}</td>
                                                    <td>{moment(val.created_at).format('DD-MM-YYYY hh:ss A')}</td>
                                                    <td>{val.name}&nbsp;<a href="" onClick={e => { e.preventDefault() }} title={val.status.toUpperCase()}><i className="fa fa-info-circle"></i></a></td>
                                                    <td>{val.mobile}</td>

                                                    <td>{val.email}</td>
                                                    <td>{val.gender}</td>
                                                    {/* <td>{"₹" + val.wallet_balance.toFixed(2)}</td> */}
                                                    <td>{val.remit_balance ? "₹" + val.remit_balance.toFixed(2) : "₹0.00"}</td>
                                                    <td>{val.referal_code}</td>
                                                    <td>{val.slab ?  val.slab.package_name : ""}</td>
                                                    <td>
                                                        <input type="checkbox" defaultChecked={val.panel_access === "full_in_maintanance"} onChange={(e) => { this.allowMaintanance(e, val) }} />
                                                    </td>
                                                    <td colSpan={2} className="img-delar">

                                                        {/* <a href="" className="ml-1" title="User Details" onClick={(e) => { this.viwDetails2(e, val) }}><i className="fa fa-eye" ></i></a> */}

                                                        <a href="" className="ml-1" title="Assign Slab" onClick={(e) => { this.showSlabModal(e, val) }}><i className="fa fa-money" ></i></a>


                                                        <a href="" className="ml-1" title="View Details" onClick={(e) => { this.viwDetails(e, val) }}><i className="fa fa-eye" ></i></a>&nbsp;
                                                        {val.status === "active" && <><a className="ml-1" href="" title="Make Agent" onClick={(e) => { this.makeDealer(e, val) }}><i className="fa fa-universal-access" ></i></a>&nbsp;</>}
                                                        {this.state.balance && <>  {bal > 0 && <>
                                                        <a href="" className="ml-1" title="Deduct Balance" onClick={(e) => { this.deductBalance(e, val) }}><i className="fa fa-money" ></i></a> &nbsp;</>} </>}

                                                        {this.state.show_edit && <a href="" className="ml-1" title="Edit Details" onClick={(e) => { this.editDetailsOtp(e, val) }}><i className="fa fa-pencil-square-o" ></i></a>}

                                                        {this.state.caseback && <a href="" className="ml-1" title="Send Cashback" onClick={(e) => { this.sendCashback(e, val) }}><i className="fa fa-gift" ></i></a>}

                                                        {this.state.caseback && <a href="" className="ml-1" title="View Certificate" onClick={(e) => { this.viewCertificate(e, val) }}><i class="fa fa-print"></i></a>}

                                                        {val.kycstatus === "pending" &&
                                                            <a href="" className="ml-1" title="Approve User" onClick={e => { this.approveUser(e, val) }}>
                                                                <i className="fa fa-check" ></i>
                                                            </a>
                                                        }

                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>}
                                    {this.state.showCspForm && <>
                                        {this.state.uploadDocumentForm && <>
                                            <ImeUploadDocs {...uploadImageFunctions} documents={this.state.documents} uploadOther={this.state.uploadOther} imageUrl={imageUrl} csp_status={this.state.csp_status} />
                                        </>}
                                        {!this.state.uploadDocumentForm && <>
                                            <div className="col-md-12 listboxsec">
                                                <FormGroup>
                                                    <label>Upgrade As</label>
                                                    <select className="form-control" name="upgrade_type" onChange={(e) => { this.handleInput(e) }} value={this.state.upgrade_type}>
                                                        <option value="">Select</option>
                                                        <option value="dealer" >Agent</option>
                                                        <option value="distributor" >Distributor</option>
                                                    </select>
                                                </FormGroup>

                                                <FormGroup>
                                                    <label>Upgrade Type</label>
                                                    <select className="form-control" name="remittance_type" onChange={(e) => { this.handleInput(e) }} value={this.state.remittance_type}>
                                                        <option value="">Select</option>
                                                        <option value="OS" >Online Saathi</option>
                                                        <option value="IME" >IME</option>
                                                        <option value="Prabhu" >Prabhu</option>
                                                    </select>
                                                </FormGroup>

                                                {this.state.remittance_type === "IME" && <>
                                                    <ImeCsRegister {...imeCspRegisterFormFunctions} {...this.state} />
                                                    <a href="" className="btn btn-primary" onClick={(e) => { this.submitCSP(e) }} >Save</a>
                                                    &nbsp;{this.state.csp_status !== "" && <a href="" className="btn btn-primary" onClick={(e) => { this.viewDocs(e) }} >View Documents</a>}

                                                </>}
                                                {this.state.remittance_type === "Prabhu" && <Prabhucsp submitAction={this.submitAction} user_id={this.state.selected_user.id} loadingState={this.loadingState} upgrade_type={this.state.upgrade_type} />}

                                                {this.state.remittance_type === "OS" && <a href="" className="btn btn-primary" onClick={(e) => { this.makeAgentWithoutIME(e) }} >Make Agent</a>}
                                            </div>

                                        </>}
                                    </>}
                                    {!this.state.showCspForm && <Pagination changePage={this.changePage} data={this.state.paginatedata} />}
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                        <Modal
                            modalClassName="modal"
                            isOpen={this.state.viewModal}
                            toggle={this.toggleViewModal}
                            size="lg"
                        >
                            <div className="modal-header">
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleViewModal} style={{ zIndex: "999" }} >
                                    <i className="tim-icons icon-simple-remove" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="view-popup">

                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "4rem", marginTop: "-2rem"}}>
                                                <div>
                                                    <ModalHeader className="reject-popup" toggle={this.toggle}>View User Details
                                                        {this.state && this.state.status !== "pending" && <span className="badge badge-primary ml-2">{this.state.status.toUpperCase()}</span>}
                                                    </ModalHeader>
                                                </div>
                                                <div className="block-acccount-button-div">
                                                    <div className="activestatus">
                                                        <div className="btn-account">
                                                            {this.state.status === "active" && <button style={{marginTop: "0rem"}} type="button" className="btn btn-danger" onClick={(e) => { this.toggleBlockModal(e) }}>Block Account</button>}
                                                            {this.state.status === "pending" && <button style={{marginTop: "0rem"}} type="button" className="btn btn-danger" onClick={(e) => { this.toggleTempLoginModal(e) }}>Reject</button>}
                                                            {this.state.status !== "active" && <button style={{marginTop: "0rem"}} type="button" className="btn btn-danger" onClick={(e) => { this.activeUser(e) }}>{this.state.status === "pending" ? "Approve" : "Active"} User</button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="first-container-main-pop-up">
                                                <div className="first-container-semi-main-pop-up">
                                                    <div className="first-container-content-main-pop-up">

                                                        <div className="first-container-content-first-div">
                                                                <div>
                                                                  <img alt="..." onError={this.handleError} src={imageUrl + this.state.profile_pic} />
                                                                </div>
                                                            <div>
                                                                <ul className="first-container-content-ul-div">
                                                                    <li ><p className="first-container-content-ul-p">{this.state.name}</p></li>
                                                                    <li><p> Referal Code: <span className="first-container-content-ul-p">{this.state.referal_code ? this.state.referal_code : "N/A"}</span>  </p></li>
                                                                    <li><p> Joining Date: <span className="first-container-content-ul-p">{moment(this.state.created_at).format('lll')}</span>  </p></li>
                                                                    <li><p className="first-container-content-ul-p">Status: <span style={{color: "#00945D", fontWeight: "600"}}>{this.state.status}</span></p></li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                            <label>Email:</label>
                                                                            <h4><b>{this.state.email ? this.state.email : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Phone Number</label>
                                                                        <h4><b>{this.state.mobile ? this.state.mobile : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Wallet Balance</label>
                                                                        <h4> <b>{this.state.wallet_balance ? this.state.wallet_balance : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>DOB</label>
                                                                        <h4> <b>{this.state.dob ? moment(this.state.dob).format('DD-MMM-YYYY') : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Gender</label>
                                                                        <h4><b>{this.state.gender ? this.state.gender : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                       <label>Remittance Balance</label>
                                                                       <h4> <b>{this.state.remit_balance ? this.state.remit_balance : "N/A"}</b> </h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Reference</label>
                                                                        <h4><b>{this.state.referer ? this.state.referer : "Self"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>CSP Code</label>
                                                                        <h4><b>{this.state.csp_code}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Block Reason</label>
                                                                        <h4><b>{this.state.block_reason ? this.state.block_reason : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="second-container-main-pop-up">
                                                <div className="first-container-semi-main-pop-up">
                                                    <div className="first-container-content-main-pop-up">

                                                        <div className="first-container-content-div-comman">
                                                            <div className="third-container-content-child-div">

                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Permanent Address</label>
                                                                        <h4><b>{this.state.address ? this.state.address : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>City</label>
                                                                        <h4><b>{this.state.csp_city ? this.state.csp_city : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>District</label>
                                                                        <h4><b>{this.state.csp_district ? this.state.csp_district : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>State</label>
                                                                        <h4><b>{this.state.csp_state ? this.state.csp_state : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Country</label>
                                                                        <h4><b>{this.state.csp_country ? this.state.csp_country : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Pin Code</label>
                                                                        <h4><b>{this.state.pin_code ? this.state.pin_code : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>

                                                        </div>

                                                        <div className="first-container-content-div-comman">
                                                          <div style={{display: "flex", gap: "2rem"}}>
                                                            <div>
                                                                <hr style={{width: "1px", height: "285px", backgroundColor: "grey"}} />
                                                            </div>
                                                            <div>
                                                            <div className="third-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Current Address</label>
                                                                        <h4><b>{this.state.current_address ? this.state.current_address : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>City</label>
                                                                        <h4><b>{this.state.city ? this.state.city.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>District</label>
                                                                        <h4><b>{this.state.district ? this.state.district.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="third-container-content-child-level-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>State</label>
                                                                        <h4><b>{this.state.state ? this.state.state.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Country</label>
                                                                        <h4><b>{this.state.country ? this.state.country.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            <div className="first-container-content-child-div">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <label>Pin Code</label>
                                                                        <h4><b>{this.state.pincode ? this.state.pincode.toUpperCase() : "N/A"}</b></h4>
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>
                                                            </div>

                                                          </div>
                                                        </div>


                                                        <div className="first-container-content-div-comman">
                                                            <div className="first-container-content-child-aadhaar-div">
                                                                {this.state.license_back &&
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="userimg">
                                                                                <label id="userimg-label">Identity Back ({this.state.proof_type ? this.state.proof_type.replace("_", " ") : ""}) </label>
                                                                                <img alt="..." onError={this.handleError} src={imageUrl + this.state.license_back} onClick={e => { this.toggleImageModal(e, imageUrl + this.state.license_back) }} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                }
                                                                {this.state.license_front &&
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="userimg">
                                                                                <label id="userimg-label">Identity Front ({this.state.proof_type ? this.state.proof_type.replace("_", " ") : ""})</label>
                                                                                <img src={imageUrl + this.state.license_front} alt=" " onClick={e => { this.toggleImageModal(e, imageUrl + this.state.license_front) }} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>



                                    {/* <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Reference</label>
                                                <h4>{this.state.referer ? this.state.referer : "Self"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Address</label>
                                                <h4>{this.state.address ? this.state.address : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>City</label>
                                                <h4>{this.state.city ? this.state.city.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>District</label>
                                                <h4>{this.state.district ? this.state.district.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>State</label>
                                                <h4>{this.state.state ? this.state.state.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Country</label>
                                                <h4>{this.state.country ? this.state.country.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>PIN Code</label>
                                                <h4>{this.state.pincode ? this.state.pincode : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        {this.state.csp_code && <Col md="4">
                                            <FormGroup>
                                                <label>CSP Code</label>
                                                <h4>{this.state.csp_code}</h4>
                                            </FormGroup>
                                        </Col>}

                                        <Col md="4">
                                            <FormGroup>
                                                <label>Current Address</label>
                                                <h4>{this.state.current_address ? this.state.current_address : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>

                                        <Col md="4">
                                            <FormGroup>
                                                <label>DOB</label>
                                                <h4>{this.state.dob ? moment(this.state.dob).format('DD-MMM-YYYY') : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>


                                    </Row>
                                    <Row>
                                        {this.state.status === "blocked" && <Col md="6">
                                            <FormGroup>
                                                <label>Block Reason</label>
                                                <h4>{this.state.block_reason ? this.state.block_reason : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>}
                                        {this.state.status === "temp_login" && <Col md="6">
                                            <FormGroup>
                                                <label>Notice</label>
                                                <h4>{this.state.notice ? this.state.notice : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>}
                                    </Row>
                                    <Row>
                                        {this.state.license_back && <Col md="6">
                                            <div className="imageidentity">
                                                <label style={{ textAlign: "center" }}>Identity Back ({this.state.proof_type ? this.state.proof_type.replace("_", " ") : ""})</label>
                                                <img src={imageUrl + this.state.license_back} alt=" " onClick={e => { this.toggleImageModal(e, imageUrl + this.state.license_back) }} />
                                            </div>
                                        </Col>}
                                        {this.state.license_front && <Col md="6">
                                            <div className="imageidentity">
                                                <label style={{ textAlign: "center" }}>Identity Front({this.state.proof_type ? this.state.proof_type.replace("_", " ") : ""})</label>
                                                <img src={imageUrl + this.state.license_front} alt=" " onClick={e => { this.toggleImageModal(e, imageUrl + this.state.license_front) }} />
                                            </div>
                                        </Col>}

                                        {this.state.photo && <Col md="6">
                                            <div className="imageidentity">
                                                <label style={{ textAlign: "center" }}>Photo</label>
                                                <img src={imageUrl + this.state.photo} alt=" " onClick={e => { this.toggleImageModal(e, imageUrl + this.state.photo) }} />
                                            </div>
                                        </Col>}

                                    </Row> */}
                                    {this.state.dealer_details && <h2>Become Saathi details</h2>}

                                    {this.state.dealer_details &&
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Contact Person Details</label>
                                                    <h4>{this.state.dealer_details ? this.state.dealer_details.contact_person_name : "NA"}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>CSP Name</label>
                                                    <h4>{this.state.dealer_details ? this.state.dealer_details.cspname : "N/A"}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Address</label>
                                                    <h4>{this.state.dealer_details.house_number + ", " + this.state.dealer_details.road_name + ", " + this.state.dealer_details.city + ", " + this.state.dealer_details.pincode}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Business Type</label>
                                                    <h4>{this.displayStatic(this.state.dealer_details.bussiness_type, 'csp_business_type')}</h4>
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Registration Type</label>
                                                    <h4>{this.displayStatic(this.state.dealer_details.registration_type, 'csp_registration_type')}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Address Proof Type</label>
                                                    <h4>{this.displayStatic(this.state.dealer_details.address_proof_type, 'csp_address_proof_type')}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Gender</label>
                                                    <h4>{this.displayStatic(this.state.dealer_details.owners_gender, 'gender')}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Category</label>
                                                    <h4>{this.displayStatic(this.state.dealer_details.owners_category, 'owner_category')}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Is Handicapped</label>
                                                    <h4>{this.displayStatic(this.state.dealer_details.owners_is_handicapped, 'physically_handicapped')}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Alternate Occupation</label>
                                                    <h4>{this.displayStatic(this.state.dealer_details.owners_alternate_occupation, 'alternate_occupation_type')}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Email</label>
                                                    <h4>{this.state.dealer_details ? this.state.dealer_details.email : "N/A"}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Mobile Number</label>
                                                    <h4>{this.state.dealer_details ? this.state.dealer_details.mobile_number : "N/A"}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Bank Account Number</label>
                                                    <h4>{this.state.dealer_details ? this.state.dealer_details.bank_account_number : "N/A"}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>IFSC</label>
                                                    <h4>{this.state.dealer_details ? this.state.dealer_details.ifsc : "N/A"}</h4>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <h3>Payment Method (&#8377; 100)</h3>
                                                <FormGroup>
                                                    <lebel>Free &nbsp;</lebel>
                                                    <input type="radio" name="payment_method" onClick={e => { this.handlePaymentMethod(e, 'free') }} checked={this.state.payment_method_agent === 'free'} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <lebel>Cash &nbsp;</lebel>
                                                    <input type="radio" name="payment_method" onClick={e => { this.handlePaymentMethod(e, 'cash') }} checked={this.state.payment_method_agent === 'cash'} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <lebel>Wallet (&#8377;{this.state.remit_balance}) &nbsp;</lebel>
                                                    <input type="radio" name="payment_method" onClick={e => { this.handlePaymentMethod(e, 'wallet') }} checked={this.state.payment_method_agent === 'wallet'} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <button type="button" className="btn btn-primary" onClick={e => { this.makeAgentWithoutIME(e) }}>Make Agent</button>
                                            </Col>
                                        </Row>}
                                </div>
                            </div>
                        </Modal>


                        <Modal isOpen={this.state.imageModal} toggle={this.toggleImageModal} size="lg" >
                            <ModalHeader toggle={this.toggleImageModal}>Identity</ModalHeader>
                            <ModalBody>
                                <img src={this.state.imagesrcView} alt="" />
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.blockModal} toggle={this.toggleBlockModal}>
                            <ModalHeader toggle={this.toggleBlockModal}>Block Reason</ModalHeader>
                            <ModalBody>
                                <textarea name="input_block_reason" onChange={(e) => { this.handleInput(e) }} value={this.state.input_block_reason} className="form-control"></textarea>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-danger" onClick={this.blockUser}>
                                    Block <b>{this.state.name} ({this.state.mobile})</b>
                                </button>
                            </ModalFooter>
                        </Modal>


                        <Modal isOpen={this.state.tempLoginModal} toggle={this.toggleTempLoginModal}>
                            <ModalHeader toggle={this.toggleTempLoginModal}>Notice</ModalHeader>
                            <ModalBody>
                                <textarea name="input_notice" placeholder="Notice" onChange={(e) => { this.handleInput(e) }} value={this.state.input_notice} className="form-control"></textarea>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-danger" onClick={this.approveUser}>
                                    Submit
                                </button>
                            </ModalFooter>
                        </Modal>


                        <Modal isOpen={this.state.slabModal} toggle={this.toggleSlabModal}>
                            <ModalHeader toggle={this.toggleSlabModal}>Assign Slab </ModalHeader>
                            <ModalBody>

                                <div className="form-group">
                                <label>Choose Slab </label>
                                       <select className="form-control" name="slab_id" value={this.state.slab_id} onChange={(e) => { this.handleInput(e) }}>
                                       <option value="">Select</option>
                                       {this.state.AllSlab.map((val, key) => {
                                          return <option value={val.id} key={key}>{val.package_name}</option>;
                                       })}

                                    </select>
                                </div>


                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-danger" onClick={this.slabSubmit}>
                                    Submit
                                </button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={this.state.deductModal} toggle={this.toggleDeductModal}>
                            <ModalHeader toggle={this.toggleDeductModal}>Deduct Amount </ModalHeader>
                            <ModalBody>

                                <div className="form-group">
                                    {this.state.selected_user && <label>Name: {this.state.selected_user.name} ({this.state.selected_user.mobile})</label>}
                                </div>
                                <div className="form-group">
                                    {this.state.selected_user && <label>Wallet Balance: {this.state.selected_user.remit_balance}</label>}
                                </div>
                                {/* <div className="form-group">
                                    <label>Wallet Type</label>
                                    <select className="form-control" onChange={(e) => { this.handleInput(e) }} name="deduct_from" value={this.state.deduct_from}>
                                        <option value="wallet_balance">Deduct from Wallet Balance</option>
                                        <option value="remit_balance">Deduct from Remittance Balance</option>
                                    </select>
                                </div> */}
                                <div className="form-group">
                                    <label>Amount</label>
                                    <input type="number" min="0" className="form-control" onChange={(e) => { this.handleInput(e) }} name="deduct_amount" value={this.state.deduct_amount} />
                                </div>
                                <div className="form-group">
                                    <label>Reason</label>
                                    <textarea name="deduct_reason" onChange={(e) => { this.handleInput(e) }} value={this.state.deduct_reason} className="form-control"></textarea>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-danger" onClick={this.deductBalanceSubint}>
                                    Submit
                                </button>
                            </ModalFooter>
                        </Modal>
                        <Modal modalClassName="modal" isOpen={this.state.editModal} toggle={this.toggleEditModal} size="lg" >
                            <div className="modal-header">
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleEditModal} style={{ zIndex: "999" }} >
                                    <i className="tim-icons icon-simple-remove" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="view-popup">
                                    <h3>Edit User Detail &nbsp;&nbsp;{!this.state.otp_verified && <small>Please verify the otp to continue.</small>}</h3>
                                    <div className="edituserddetails">
                                        {!this.state.otp_verified && <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>OTP</lebel>
                                                    <input className="form-control" type="number" name="otp" value={this.state.otp} onChange={(e) => { this.handleInput(e) }} max="999999" min="100000" />
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.otp_verified && <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Name</lebel>
                                                    <input className="form-control" type="text" name="edit_name" value={this.state.edit_name} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Email</lebel>
                                                    <input className="form-control" type="text" name="edit_email" value={this.state.edit_email} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Mobile Number</lebel>
                                                    <input className="form-control" type="text" name="edit_mobile" value={this.state.edit_mobile} onChange={(e) => { this.handleInput(e) }} disabled={true} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Address</lebel>
                                                    <input className="form-control" type="text" name="edit_address" value={this.state.edit_address} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Country</lebel>
                                                    <input className="form-control" type="text" name="edit_country" value={this.state.edit_country} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>State</lebel>
                                                    <input className="form-control" type="text" name="edit_state" value={this.state.edit_state} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>City</lebel>
                                                    <input className="form-control" type="text" name="edit_city" value={this.state.edit_city} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>District</lebel>
                                                    <input className="form-control" type="text" name="edit_district" value={this.state.edit_district} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Pincode</lebel>
                                                    <input className="form-control" type="text" name="edit_pincode" value={this.state.edit_pincode} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                    <ModalFooter>
                                        {this.state.otp_verified && <button type="button" className="btn btn-danger" onClick={this.editaDetailsSubmit}>
                                            Submit
                                        </button>}
                                        {!this.state.otp_verified && <button type="button" className="btn btn-danger" onClick={e => this.editDetails(e)}>
                                            Verify OTP
                                        </button>}
                                    </ModalFooter>
                                </div>
                            </div>
                        </Modal>
                    </Row>
                </div>

                <Modal
                    modalClassName="modal"
                    isOpen={this.state.viewRewardModal}
                    toggle={this.sendCashback}
                >
                    <div className="modal-header">
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.sendCashback} style={{ zIndex: "999" }} >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="view-popup">
                            {this.state.selected_user && <h3>Send Reward to {this.state.selected_user.name} ({this.state.selected_user.mobile})</h3>}
                            <div className="userddetails">
                                <Row>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <lebel>Amount <span className="text-danger">*</span></lebel>
                                            <input className="form-control" type="number" min="0" name="rewardamount" value={this.state.rewardamount} onChange={(e) => { this.handleInput(e) }} />
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <lebel>Comments <span className="text-danger">*</span></lebel>
                                            <textarea name="reward_comment" value={this.state.reward_comment} onChange={(e) => { this.handleInput(e) }}></textarea>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger mb-4 float-right" onClick={this.submitReward}>
                            Submit
                        </button>
                    </ModalFooter>
                </Modal>


                <Modal
                    modalClassName="modal"
                    isOpen={this.state.viewCertificateModal}
                    toggle={this.viewCertificate}
                    size="lg"
                >

                    <div className="modal-body">
                        <div className="view-popup" id="view-popUp-view-certificate">
                            {this.state.selected_user &&

                                <div>
                                    <div>

                                            <div id="view-certificate-button-div">
                                                <div>
                                                <div><h1>view Certificate</h1></div>
                                                </div>
                                                <div>
                                                <div><button id="view-certificate-button" onClick={this.downloadFirstCertificate}>Download</button></div>
                                                </div>
                                            </div>


                                        <div id="view-first-certificate-head">

                                            <div>
                                              <div>
                                                   <img alt="..." src={require("../../assets/images/userCertificate.svg")} id="View-Certificate-img" />
                                              </div>

                                                <div className="view-certificate-content">

                                                    <div>
                                                        <div id="view-certificate-membership">
                                                            <p id="view-certificate-heading">Certificate of Membership</p>
                                                            <p style={{color: "#4A4F52"}}>Certificate Number: N/A</p>
                                                            <p style={ {fontWeight: "600"}}>This is to certify that <span style={{ color:"#5c5c8d" }}> {this.state.selected_user.name} </span> has been granted</p>
                                                            <p style={ {fontWeight: "600"}}>Membership in Rajshree (Online Saathi).</p>
                                                        </div>

                                                        <div id="view-member-details" >
                                                            <div id="view-member-details-para">
                                                                <p>Membership Details: {this.state.selected_user.membership_id ? this.state.selected_user.membership_id : "N/A"}</p>
                                                                <p>Contact No:  {this.state.selected_user.mobile ? this.state.selected_user.mobile : "N/A"} </p>
                                                                <p>Education: {this.state.selected_user.educational_qualification ? this.state.selected_user.educational_qualification : "N/A"}</p>
                                                            </div>
                                                            <div  id="view-member-details-para" >
                                                                <p>Date of Birth:  {this.state.selected_user.dob ? moment(this.state.selected_user.dob).format('DD-MMM-YYYY') : "N/A"}</p>
                                                                <p>KYC No:  N/A </p>
                                                                <p>Pin Code:  {this.state.selected_user.pincode ? this.state.selected_user.pincode : "N/A"}</p>
                                                                <p>UNI: N/A</p>
                                                            </div>
                                                        </div>
                                                        <div id="view-member-details-address">
                                                            <p>Current Address: {this.state.selected_user.current_address ? this.state.selected_user.current_address : "N/A"} </p>
                                                            <p>Parmanent Address: {this.state.selected_user.p_address ? this.state.selected_user.p_address : "N/A"}</p>
                                                        </div>
                                                    </div>

                                                    <div id="view-member-details-member-para">
                                                        <p>
                                                            All the above information has been provided by the member.
                                                            The correctness of the information is also confirmed by the member.
                                                            The company does not hold any liability for the information provided by the member.
                                                            Membership is provided based on the information filled by the applicant in the digital format.
                                                        </p>
                                                    </div>

                                                    <div id="view-member-date" >
                                                        <div>
                                                            <b>{moment(this.state.selected_user.created_at).format('lll')}</b>
                                                            <p>Date of Issue:</p>
                                                        </div>
                                                        <div>
                                                            <b>N/A</b>
                                                            <p>Date of Expiry:</p>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    <div>
                                            <div id="view-certificate-button-div">
                                                <div>
                                                <div><h1>view Certificate</h1></div>
                                                </div>
                                                <div>
                                                <div><button id="view-certificate-button" onClick={this.downloadSecondCertificate} >Download</button></div>
                                                </div>
                                            </div>

                                        <div id="view-second-certificate-head">
                                            <div>
                                                <div id="view-second-certificate-img-div">
                                                   <img alt="..." src={require("../../assets/images/userSecCrtificate.svg")} id="View-second-Certificate-img" />
                                                </div>
                                                <div id="view-second-certificate-content">
                                                    <div id="view-second-certificate-name">
                                                        <h1> {this.state.selected_user.name} </h1>
                                                    </div>
                                                    <div>
                                                       <div id="view-second-certificate-para">
                                                            <p>
                                                            Shubhlaxmi Multiservices India private limietd Is pledge to engage Auto NAME AS PER KYC_______Current District__________As_SAATHI__________for providing______________ Services as per they select in While Applying.
                                                            The holder of this certificate is traned to provide the Kiosk Banking Services under the BANK/Parnter Intitution Guideline.
                                                            </p>
                                                       </div>
                                                        <div id="view-second-certificate-saathi">
                                                            <p>Saathi Code: N/A</p>
                                                            <p>Link Distributor: N/A</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div>
                                            <div id="view-certificate-button-div">
                                                <div>
                                                <div><h1>view Id Card</h1></div>
                                                </div>
                                                <div>
                                                <div><button id="view-certificate-button" onClick={this.downloadMemberID} >Download</button></div>
                                                </div>
                                            </div>

                                        <div id="view-second-id-head">
                                            <div className="view-id-card">

                                               <div className="view-id-card-green">
                                                    <div className="view-id-card-first">
                                                        <div className="view-id-card-heading">
                                                            <p><b>Membership Identity Card</b></p>
                                                        </div>
                                                    </div>
                                               </div>

                                                <div className="view-id-card-second">
                                                    <div className="view-id-card-second-div-head"> <img alt="..." src={require("../../assets/images/onlineSaathiLightBG.png")} id="view-id-card-second-div-head-img" />
                                                        <div>
                                                            <ul className="view-id-card-second-logo-ul">
                                                                <li><img alt="..." src={require("assets/images/new/online-sath-logo-new.png")} style={{width: "10rem", height: "4rem"}}/></li>
                                                                <li style={{marginTop: "-1rem", marginRight: "1rem"}}><b><span style={{color: "rgb(62 64 108)"}}>Membership Number :</span> <b> {this.state.selected_user.membership_id ? this.state.selected_user.membership_id : "N/A"} </b> </b></li>
                                                            </ul>
                                                        </div>
                                                        <div className="view-id-card-second-ul-head">
                                                            <ul style={{listStyle: "none"}}>
                                                                <li><img  alt="..." src={imageUrl + this.state.profile_pic} style={{width: "fit-content", height: "8rem", marginTop: "1rem", filter: "drop-shadow(0px 0px 1px black)"}}/></li>
                                                            </ul>
                                                            <ul className="view-id-card-ul-sec-content">
                                                                <li><p style={{fontWeight: "800"}}><span style={{color: "rgb(62 64 108)"}}>Name:</span> {this.state.selected_user.name ? this.state.selected_user.name : "N/A"}</p></li>
                                                                <li><p style={{fontWeight: "800"}}><span style={{color: "rgb(62 64 108)"}}>Email:</span> {this.state.selected_user.email ? this.state.selected_user.email : "N/A"} </p></li>
                                                                <li><p style={{fontWeight: "800"}}><span style={{color: "rgb(62 64 108)"}}> Blood Group:</span> N/A  </p></li>
                                                                <li><p style={{fontWeight: "800"}}><span style={{color: "rgb(62 64 108)"}}>Date of Birth:</span> {this.state.dob ? moment(this.state.selected_user.dob).format('DD-MMM-YYYY') : "N/A"} </p></li>
                                                                <li style={{width: "93%"}}><p style={{fontWeight: "800"}}> <span style={{color: "rgb(62 64 108)"}}>Address:</span> {this.state.selected_user.address ? this.state.selected_user.address : "N/A"} </p></li>
                                                            </ul>
                                                            <ul style={{listStyle: "none"}}>
                                                                <li style={{marginLeft: "-6rem", marginTop: "10px"}}><p className="view-id-mobile"><span style={{color: "rgb(62 64 108)"}}> Mobile: </span> {this.state.selected_user.mobile ? this.state.selected_user.mobile : "N/A"} </p></li>
                                                                <li style={{marginLeft: "-3rem"}}><p style={{fontWeight: "800"}}> <span style={{color: "rgb(62 64 108)"}}>Gender:</span>   {this.state.selected_user.gender ? this.state.selected_user.gender : "N/A"}</p></li>
                                                                <li
                                                                    style={{marginLeft: "-2rem"}}>
                                                                    <QRCode
                                                                        style={{width: "85px", height: "85px"}}
                                                                        value={`Name: ${this.state.selected_user.name ? this.state.selected_user.name : "N/A"} E-mail: ${this.state.selected_user.email ? this.state.selected_user.email : "N/A"} Mobile: ${this.state.selected_user.mobile ? this.state.selected_user.mobile : "N/A"} Dob: ${this.state.selected_user.dob ? this.state.selected_user.dob : "N/A"} Gender: ${this.state.selected_user.gender ? this.state.selected_user.gender : "N/A"}`}
                                                                        />
                                                                         </li>
                                                            </ul>
                                                        </div>
                                                        <div >
                                                            <ul className="view-id-card-third-ul-head">
                                                                <li><p className="view-id-card-third-p"> <span style={{color: "rgb(62 64 108)"}}>Joining Date: </span> {moment(this.state.selected_user.created_at).format('lll')}</p></li>
                                                                <li><p className="view-id-card-third-p2"> <span style={{color: "rgb(62 64 108)"}}>Issue Date: </span> N/A </p></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="view-id-card-third-blue"> </div>
                                                    <div className="view-id-card-third">
                                                        <div>
                                                            <ul className="view-id-card-third-ul-head">
                                                                <li><b className="view-id-card-third-ul-b">website: www.onlinesaathi.org</b></li>
                                                                <li><b id="view-id-card-third-ul-b2" className="view-id-card-third-ul-b">Contact +91 9099005251</b></li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                            </div>
                                        </div>

                                    </div>


                                    </div>

                                </div>
                            }
                        </div>
                    </div>

                </Modal>


            </>
        );
    }

}

export default Users;
