import React from "react";
import Axios from "axios";
import { Modal } from "reactstrap";

import * as moment from "moment";
import socketIOClient from "socket.io-client";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
const socket = socketIOClient(imageUrl);
class Customerchats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.MIX_REACT_API_URL,
            users: [],
            selected_user: null,
            keyword: null,
            single_chat: [],
            templates: [],
            template: "",
            message: "",
            current_user: null,
            file: "",
            imgSrc: null,
            msgimage: "",
            viewModal: false
        }
    }

    componentDidMount = () => {
        this.getUsers();
        var self = this;
        socket.on("fox_2", data => {
            console.log("fox_2", data)
        })
        socket.on("new-message", data => {
            if (self.state.users.length > 0) {
                self.getUsers();
                if (self.state.selected_user && [data.data.sender.id, data.data.receiver.id].includes(self.state.selected_user.id)) {
                    var newth = data.data;
                    var wth = self.state.single_chat;
                    wth.push(newth);
                    console.log(wth)
                    self.setState({ single_chat: wth });
                    self.scrollTop();
                }
                if (data.data.receiver.id === self.state.current_user.id) {
                    var beep_one = document.getElementById("beep_one");
                    beep_one.play();
                }
            }
        });
    }

    viewimage = (e = null, msg) => {

        e.preventDefault();
        console.log(msg);
        this.setState({
            msgimage: msg
        });

        this.toggleViewModal();
    }
    toggleViewModal = () => {
        this.setState({
            viewModal: !this.state.viewModal
        });
    }
    getChat = (e = null, user) => {
        if (e) {
            this.getUsers();
            e.preventDefault();
        }
        this.setState({ selected_user: user })
        Axios.post(`${apiUrl}/chat/single`, { user_id: user.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                single_chat: res.data.data.history
            })
            this.scrollTop();
        })
    }

    getUsers = () => {
        var text = this.state.search_text;
        Axios.get(`${apiUrl}/chat/users?q=${text}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                users: res.data.data.users,
                current_user: res.data.data.current_user,
            })
        })
    }
    scrollTop = () => {
        setTimeout(() => {
            var chElem = document.getElementById('message_div_' + this.state.single_chat.length);
            chElem.scrollIntoView();
        }, 300);
    }
    sendMessage = e => {
        if (this.state.message !== "" || this.state.file !== "") {


            const formdata = new FormData();
            formdata.append('file', this.state.file);
            formdata.append('user_id', this.state.selected_user.id);
            formdata.append('message', this.state.message);
            // var data = {
            //     message: this.state.message,
            //     user_id: this.state.selected_user.id,
            //     file: this.state.file
            // }
            console.log('request', formdata);
            Axios.post(`${apiUrl}/chat/send/message`, formdata, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                this.setState({
                    message: "", templates: [], 'file': "", imgSrc: null
                })
                var x = document.getElementById("message");
                x.value = "";
                this.scrollTop();
            })
        }
    }
    onFileChange = (e) => {
        this.setState({ file: e.target.files[0] })
        var file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            this.setState({
                imgSrc: [reader.result],
            })
        }.bind(this);
        console.log(url) // Would see a path?
        // TODO: concat files
    }

    handleInput = e => {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code === 13) {
            this.sendMessage();
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
        if (e.target.name === "message") {
            this.setState({
                keyword: e.target.value
            })
            //console.log("message",this.state.keyword);
            this.gettemplate();
        }
    }
    handleInput1 = (e = null, temp) => {
        this.setState({
            message: temp,
            templates: []
        })


    }
    gettemplate = (e = null) => {

        //   this.setState({ keyword: keyword })

        Axios.post(`${apiUrl}/admin/search-templates`, { keyword: this.state.keyword }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("temp", res.data.data);
            this.setState({

                templates: res.data.data
            })

        })
    }

    cancelChat = e => {
        var x = document.getElementById("message");
        x.value = "";
        this.setState({
            message: "",
            selected_user: null,
            single_chat: [],
            imgSrc: null,
            file: null,
        });
    }
    empty_image = e => {

        this.setState({

            imgSrc: null,
            file: null,
        });
    }

    handleSearchInput = e => {
        var val = e.target.value;
        this.setState({
            search_text: val
        })
        if (val.length > 2 || val === "") {
            this.getUsers();
        }
    }

    render() {
        return <>
            {/* <NotificationContainer /> */}
            {/* <Navbar page="Admin Dashboard" /> */}
            {/* <Sidebar /> */}
            <div className="content">

                <audio id="beep_one" controls preload="auto" style={{ display: "none" }}>
                    <source src={require("assets/sound/message.mp3")} controls></source>
                </audio>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-4">
                                <h1 className="m-0">Customer Chats</h1>
                            </div>
                            <div class="col-sm-8">
                                <input type="text" className="form-control" placeholder="Search user name/mobile" name="search_text" value={this.state.search_text} onChange={e => { this.handleSearchInput(e) }} />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5">
                            {/* <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Users</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                            <i className="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <ul className="nav nav-pills flex-column">
                                        <li className="nav-item active">
                                            <a href="#" className="nav-link">
                                                <i className="fas fa-inbox"></i>
                                                Inbox
                                                <span className="badge bg-primary float-right">12</span>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div> */}
                            <div className="bg-dark">
                                <ul className="contacts-list">
                                    {this.state.users.map((chat, key) => {
                                        return <User chat={chat} current_user={this.state.current_user} key={key} getChat={this.getChat} />
                                    })
                                    }
                                </ul>

                            </div>
                        </div>
                        <div className="col-md-7">
                            {this.state.single_chat.length > 0 && <div className="card direct-chat direct-chat-primary">
                                <div className="row">
                                    <div className="col-md-12"><h3>{this.state.selected_user.name}</h3></div>
                                    <div className="float-right">
                                        <button type="button" className="" data-card-widget="remove" onClick={e => { this.cancelChat(e) }}>
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body" id="chat_box">
                                    <Directchat chat={this.state.single_chat} current_user={this.state.current_user} view_image={this.viewimage} />
                                </div>

                                <div className="card-footer">
                                    {this.state.imgSrc && <>
                                        <div className="upload-image">
                                            <span className="cross-image" onClick={(e) => { this.empty_image(e) }} ><i className="fa fa-times" aria-hidden="true"></i></span>
                                            <img src={this.state.imgSrc} />
                                        </div>
                                    </>
                                    }
                                    {/* <form action="#" method="post"> */}
                                    <div className="input-group">

                                        <input type="file" ref="file" name="file" id="file" className="form-control" onChange={(e) => { this.onFileChange(e) }} />
                                        <div className="file-icon"><i className="fa fa-picture-o" aria-hidden="true"></i></div>


                                        <input type="text" autoComplete="off" name="message" id="message" placeholder="Type Message ..." value={this.state.message} className="form-control" onChange={e => { this.handleInput(e) }} onKeyPress={e => { this.handleInput(e) }} />



                                        <span className="input-group-append">
                                            <button type="button" className="btn btn-primary" onClick={e => { this.sendMessage(e) }}>Send</button>
                                        </span>
                                    </div>
                                    {this.state.templates && <>



                                        <div className="input-group">
                                            {this.state.templates.map((temp, key) => {

                                                return <p key={key} className="chat-template" onClick={(e) => { this.handleInput1(e, temp.template) }} >{temp.template}</p>

                                            })


                                            }
                                        </div>
                                    </>}
                                    {/* </form> */}
                                </div>

                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                modalClassName="modal fll-popup"
                isOpen={this.state.viewModal}
                toggle={this.toggleViewModal}
                size="lg"
            >
                <div className="modal-header">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleViewModal} style={{ zIndex: "999" }} >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="view-popup addpopup">
                        <img alt="..." src={imageUrl + this.state.msgimage} />
                    </div>
                </div>
            </Modal>
        </>;
    }
}
export default Customerchats;
function User(props) {
    if (props.current_user.id === props.chat.sender.id) {
        return <><li>
            <a href="" onClick={e => { props.getChat(e, props.chat.receiver) }}>
                <img className="contacts-list-img" src={imageUrl + props.chat.receiver.profile_pic} alt="User Avatar" />
                <div className="contacts-list-info">
                    <span className="contacts-list-name">
                        {props.chat.receiver.name} &nbsp;&nbsp;{props.chat.count_messages > 0 && <span className="badge badge-sm badge-danger">{props.chat.count_messages}</span>}
                        <small className="contacts-list-date float-right">{moment(props.chat.created_at).calendar()}</small>
                    </span>
                    <span className="contacts-list-msg">{props.chat.message}</span>
                </div>
            </a>
        </li></>;
    }
    else {
        return <><li>
            <a href="" onClick={e => { props.getChat(e, props.chat.sender) }}>
                <img className="contacts-list-img" src={imageUrl + props.chat.sender.profile_pic} alt="User Avatar" />
                <div className="contacts-list-info">
                    <span className="contacts-list-name">
                        {props.chat.sender.name}&nbsp;&nbsp;{props.chat.count_messages > 0 && <span className="badge badge-sm badge-danger">{props.chat.count_messages}</span>}
                        <small className="contacts-list-date float-right">{moment(props.chat.created_at).calendar()}</small>
                    </span>
                    <span className="contacts-list-msg">{props.chat.message}</span>
                </div>
            </a>
        </li></>;
    }
}

function Directchat(props) {
    return <><div className="direct-chat-messages">

        {props.chat.map((msg, key) => {
            var id = "message_div_" + (key + 1);
            if (msg.sender.id !== props.current_user.id) {
                return <div className="direct-chat-msg" key={key} id={id}>

                    {msg.file && <>
                        <div className="massage-style">
                            <div className="image-sec">
                                <div className="direct-chat-infos clearfix">
                                    {/* <span className="direct-chat-name float-left">{msg.sender.name}</span> */}
                                    <span className="direct-chat-timestamp time-left">{moment(msg.created_at).format('lll')}</span>
                                </div>
                                <img className="direct-chat-img" src={imageUrl + msg.sender.profile_pic} alt="message user image" />
                                <div className="direct-chat-image left">
                                    <a onClick={e => { props.view_image(e, msg.file) }}  > <img className="" width="129" src={imageUrl + msg.file} alt="message user image" />  </a>
                                </div>
                                {msg.message && <>
                                    <div className="msgbox-styles">
                                        {msg.message}
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </>
                    }

                    {msg.file === "" && <>
                        <div className="masg-sec">

                            <div className="direct-chat-infos  clearfix">
                                <div className="direct-chat-infos clearfix">
                                    {/* <span className="direct-chat-name float-left">{msg.sender.name}</span> */}
                                    <span className="direct-chat-timestamp float-right">{moment(msg.created_at).format('lll')}</span>
                                </div>
                                <img className="direct-chat-img" src={imageUrl + msg.sender.profile_pic} alt="message user image" />
                                <div className="direct-chat-text">
                                    {msg.message}
                                </div>
                            </div>
                        </div>
                    </>
                    }
                    {msg.file === null && <>
                        <div className="masg-sec">

                            <div className="direct-chat-infos  clearfix">
                                <div className="direct-chat-infos clearfix">
                                    {/* <span className="direct-chat-name float-left">{msg.sender.name}</span> */}
                                    <span className="direct-chat-timestamp float-right">{moment(msg.created_at).format('lll')}</span>
                                </div>
                                <img className="direct-chat-img" src={imageUrl + msg.sender.profile_pic} alt="message user image" />
                                <div className="direct-chat-text">
                                    {msg.message}
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </div>
            } else {
                return <div className="direct-chat-msg right" key={key} id={id}>




                    {msg.file && <>
                        <div className="massage-style">
                            <div className="image-sec">
                                <div className="direct-chat-infos clearfix">
                                    <span className="direct-chat-timestamp time-right">{moment(msg.created_at).format('lll')}</span>
                                </div>
                                <img className="direct-chat-img" src={imageUrl + msg.sender.profile_pic} alt="message user image" />

                                <div className="direct-chat-image right">
                                    <a onClick={e => { props.view_image(e, msg.file) }} > <img className="" width="129" src={imageUrl + msg.file} alt="message user image" />  </a>
                                </div>
                                {msg.message && <>
                                    <div className="msgbox-style">
                                        {msg.message}
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </>
                    }


                    {msg.file === "" && <>
                        <div className="masg-sec">

                            <div className="direct-chat-infos  clearfix">

                                <span className="direct-chat-timestamp change-timesec time-left">{moment(msg.created_at).format('lll')}</span>
                            </div>
                            <img className="direct-chat-img" src={imageUrl + msg.sender.profile_pic} alt="message user image" />
                            <div className="direct-chat-text blue-bg">
                                {msg.message}
                            </div>

                        </div>
                    </>
                    }
                    {msg.file === null && <>
                        <div className="masg-sec">

                            <div className="direct-chat-infos  clearfix">

                                <span className="direct-chat-timestamp change-timesec time-left">{moment(msg.created_at).format('lll')}</span>
                            </div>
                            <img className="direct-chat-img" src={imageUrl + msg.sender.profile_pic} alt="message user image" />
                            <div className="direct-chat-text blue-bg">
                                {msg.message}
                            </div>

                        </div>
                    </>
                    }
                </div>
            }
        })}
    </div >


    </>
}