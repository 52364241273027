import Axios from "axios";
import React from "react";
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import swal from "sweetalert";
class ServiceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            selected_services: []
        }
    }
    componentDidMount = () => {
        this.getServices();
    }
    getServices = () => {
        Axios.get(this.props.apiUrl + "/user/settings/get/paid/services", this.props.headers).then(res => {
            this.setState({ list: res.data.list })
        });
    }
    submitRequest = () => {
        if (this.state.selected_services.length === 0) {
            swal({ title: "Error", text: "Please select desired service to send request.", icon: "error", "buttons": false, timer: 2000 });
            return false;
        }
        Axios.post(this.props.apiUrl + "/user/settings/send/paid/service/request", { services: this.state.selected_services }, this.props.headers).then(res => {
            if (res.data.success) {
                swal("", res.data.message, "sucsess");
                this.props.toggleServiceModal();
            }
            else {
                swal("Error", res.data.message, "error");
            }
        })
    }
    handleCheckBox = (e, service) => {
        var servicelist = [];
        var ss = this.state.selected_services;

        if (e.target.checked) {
            servicelist.push(service.id);
            ss.forEach((ser, k) => {
                servicelist.push(ser);
            })
        }
        else {
            ss.forEach((ser, k) => {
                if (service.id !== ser)
                    servicelist.push(ser);
            })
        }
        this.setState({ selected_services: servicelist });
        setTimeout(() => {
            console.log(this.state.selected_services)
        }, 1000);
    }
    render() {
        return <>
            <Modal size="lg" isOpen={this.props.service_modal} toggle={this.props.toggleServiceModal} backdrop="static" keyboard={false}>
                <ModalHeader style={{ width: "100%" }}>Services Activation Request
                    <h3><small>This service is not accessible to you. Please send request to admin to get paid access.</small></h3>
                </ModalHeader>

                <ModalBody>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Service</th>
                                    <th>Price (One Time)</th>
                                    <th>Select</th>
                                </tr>
                            </thead>
                            <tbody className="table-space">
                                {this.state.list.map((ser, k) => {
                                    return <tr key={k}>
                                        <td>{k + 1}.</td>
                                        <td>{ser.service}</td>
                                        <td>&#8377;{ser.amount}</td>
                                        <td><Input type="checkbox" onClick={e => { this.handleCheckBox(e,ser) }} /></td>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={this.submitRequest}>
                        Submit
                    </button>
                    <button type="button" className="btn btn-primary mr-4" onClick={this.props.toggleServiceModal}>
                        Back
                    </button>
                </ModalFooter>
            </Modal>
        </>;
    }
}
export default ServiceModal;