import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import Login from "layouts/Admin/Login.js";
import Forgetpassword from "layouts/Admin/Forgetpassword.js";
import Register from "layouts/Admin/Register.js";
// import RTLLayout from "layouts/RTL/RTL.js";
// import Home from "layouts/Landing/Home.js";
// import About from "layouts/Landing/About.js";
// import Terms from "layouts/Landing/Terms.js";
// import Privacy from "layouts/Landing/Privacy.js";
// import Refundpolicy from "layouts/Landing/Refundpolicy.js";
import User from "layouts/Admin/User";
import Home from "layouts/OnlineSaathi/Home";
import Contact from "layouts/OnlineSaathi/Contact";
import Becomeanagent from "layouts/OnlineSaathi/BrcomeAnAgent";
import Amlcft from "layouts/OnlineSaathi/AMLCFT";
import Chairmanmessage from "layouts/OnlineSaathi/ChairmanMessage";
import Network from "layouts/OnlineSaathi/Network";
import Ourteam from "layouts/OnlineSaathi/Ourteam";
import Overview from "layouts/OnlineSaathi/Overview";
import Privacypolicy from "layouts/OnlineSaathi/Privacypolicy";
import Services from "layouts/OnlineSaathi/Services";
import Visionmission from "layouts/OnlineSaathi/Visionmission";
import Refundpolicy from "layouts/OnlineSaathi/Refundpolicy";
import Termsandconditions from "layouts/OnlineSaathi/Termsandconditions";
const $ = window.jQuery;
// set NODE_OPTIONS=--max_old_space_size=4096
const hist = createBrowserHistory();
const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
  apiUrl = process.env.REACT_APP_API_URL;
  imageUrl = process.env.REACT_APP_IMAGE_URL;
}
var urls = {
  apiUrl,
  imageUrl
};
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/admin" render={props => <AdminLayout {...props} {...urls} />} />
      <Route path="/user" render={props => <User {...props}  {...urls} />} />
      <Route path="/login" render={props => <Login {...props}  {...urls} />} />
      <Route path="/forgot-password" render={props => <Forgetpassword {...props}  {...urls} />} />
      <Route path="/register" render={props => <Register {...props}  {...urls} />} />

      {/* <Route path="/rtl" render={props => <RTLLayout {...props} />} />
      <Route path="/terms-and-conditions" render={props => <Terms {...props} />} />
      <Route path="/privacy-policy" render={props => <Privacy {...props} />} />
      <Route path="/about-us" render={props => <About {...props} />} />
      <Route path="/home" render={props => <Home {...props} />} />
      <Route exact path="/refund-policy" render={props => <Refundpolicy {...props} />} /> */}


      <Route path="/terms-and-conditions" render={props => <Termsandconditions {...props} />} />
      <Route path="/refund-policy" render={props => <Refundpolicy {...props} />} />
      <Route path="/vision-mission" render={props => <Visionmission {...props} />} />
      <Route path="/services" render={props => <Services {...props} />} />
      <Route path="/privacy-policy" render={props => <Privacypolicy {...props} />} />
      <Route path="/overview" render={props => <Overview {...props} />} />
      <Route path="/our-team" render={props => <Ourteam {...props} />} />
      <Route path="/network" render={props => <Network {...props} />} />
      <Route path="/message-from-chairman" render={props => <Chairmanmessage {...props} />} />
      <Route path="/aml-cft-policy" render={props => <Amlcft {...props} />} />
      <Route path="/become-an-agent" render={props => <Becomeanagent {...props} />} />
      <Route path="/contact" render={props => <Contact {...props} />} />
      <Route path="/home" render={props => <Home {...props} />} />
      <Route exact path="/" render={props => <Home {...props} />} />

      <Redirect from="/" to="/home" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
