
import React from "react";
import { useState, useEffect } from "react";
import Usersidebar from "../Usersidebar";
import Userfooter from "../Footer";
import Userheader from "../Header";
import Razorpay from 'razorpay';

import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import { Modal } from "reactstrap";
import ReceiptModel from "../ReceiptModel";
import swal from 'sweetalert';
import PhoneInput from "react-phone-number-input";

const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}


const Addmoney = (props) => {

    const [errors, setErrors] = useState({});
    const [rzp, setRzp] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [step, setStep] = useState(0);


    const [storeData, setStoreData] = useState({
        loading: false,
        fullName: "",
        organization: "",
        state: "",
        city: "",
        email: "",
        mobile: "",
        description: "",
        other_agent: "",
        bank_account_number: "",
        adhar_card_number: "",
        pan_card_number: "",
        adhar_front: "",
        adhar_back: "",
        pan_image: "",
        bank_image: "",
        other_documents: "",
        payNow: "",
        amount: "",
    });

    // componentDidMount = () => {
    //     if (!sessionStorage.getItem("_access")) {
    //         this.props.history.push("/");

    //     }
    //     this.setState({
    //         user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
    //     })
      
    //     this.getData();
       
    // }

    // useEffect(() => {
        
    //         if (!sessionStorage.getItem("_access")) {
    //             props.history.push("/");
    //         }

    //     setStoreData({
    //         user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
    //     })

    //     const getData = (page = 1) => {
    //         var data = {};
    //         Axios.get(`${apiUrl}/becomeanagent/check`, {
    //             headers: {
    //                 Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
    //             }
    //         }).then(res => {
    //               if(res.data.success){
    //                 setStoreData({
    //                     b_form:false,
                       
    //                 })
    //               }else{
    //                 setStoreData({
    //                     b_form:true,
                       
    //                 })
    //               }
              
    //         },err=>{
    //             swal("","Session expired. Please login again.").then(o=>{
    //                 sessionStorage.clear();
    //                 props.history.push("/");
    //             })
    //         })
    //     }

    //     getData();


    // }, [props.history]);


    const  logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        props.history.push("/");
    }


    useEffect(() => {
        if (step !== 0) {
          validateStep(step);
    
        } else {
          setStep(1)
        }
      }, [storeData])


const validateStep = (step) => {
    const errors = {};
    const numberRegex = /^-?\d+(\.\d+)?$/;
    const digitRegex = /\d/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    switch (step) {
      case 1:

        if (storeData.mobile?.trim() === "") {
          errors.mobile = "This field is required.";
        } else if (storeData.mobile.length > 14) {
          errors.mobile = "Number should be less then 10 char";
        } else {
          delete errors.mobile;
        }
        if (storeData.email?.trim() === "") {
          errors.email = "This field is required.";
        } else if (!emailRegex.test(storeData.email)) {
          errors.email = "Invalid email format.";
        } else {
          delete errors.email;
        }

        if (storeData.fullName?.trim() === "") {
          errors.fullName = "This field is required.";
        } else if (digitRegex.test(storeData.fullName)) {
          errors.fullName = "Numbers are not allowed in this field.";
        } else if (storeData.fullName.length > 30) {
          errors.fullName = "Letter should be less then 30 char";
        } else {
          delete errors.fullName;
        }

        if (storeData.organization?.trim() === "") {
            errors.organization = "This field is required.";
          } else {
            delete errors.organization;
          }

        if (storeData.other_agent?.trim() === "") {
          errors.other_agent = "This field is required.";
        } else {
          delete errors.other_agent;
        }

        if (storeData.state?.trim() === "") {
            errors.state = "This field is required.";
          } else {
            delete errors.state;
          }

          if (storeData.city?.trim() === "") {
            errors.city = "This field is required.";
          } else {
            delete errors.city;
          }

          if (storeData.description?.trim() === "") {
            errors.description = "This field is required.";
          } else {
            delete errors.description;
          }

        if (storeData.bank_account_number === "") {
          errors.bank_account_number = "This field is required.";
        } else {
          delete errors.bank_account_number;
        }

        if (storeData.adhar_card_number?.trim() === "") {
          errors.adhar_card_number = "This field is required.";
        } else {
          delete errors.adhar_card_number;
        }

        if (storeData.pan_card_number?.trim() === "") {
          errors.pan_card_number = "This field is required.";
        } else {
          delete errors.pan_card_number;
        }

        if (storeData.adhar_front?.trim() === "") {
          errors.adhar_front = "This field is required.";
        } else {
          delete errors.adhar_front;
        }

        if (storeData.adhar_back?.trim() === "") {
            errors.adhar_back = "This field is required.";
          } else {
            delete errors.adhar_back;
          }

          if (storeData.pan_image?.trim() === "") {
            errors.pan_image = "This field is required.";
          } else {
            delete errors.pan_image;
          }

          if (storeData.bank_image?.trim() === "") {
            errors.bank_image = "This field is required.";
          } else {
            delete errors.bank_image;
          }

          if (storeData.other_documents?.trim() === "") {
            errors.other_documents = "This field is required.";
          } else {
            delete errors.other_documents;
          }

        break;
      default:
        break;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateStep(step)
    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   [name]: "",
    // }));
  };


  const handlePhoneNumberChange = (value) => {
    console.log(value);
    if (value) {
      setStoreData({
        ...storeData,
        mobile: value,
      });
      setErrors({
        ...errors,
        mobile: "",
      });
    } else {
      setStoreData({
        ...storeData,
        mobile: "",
      });
      setErrors({
        ...errors,
        mobile: "This field is required",
      });
    }
  };



  const handlePaymentSuccess = (response) => {

    alert('Payment successful! Payment ID: ' + response.razorpay_payment_id);
    // for handle the success response here

    sessionStorage.setItem('razorpay_payment_id_session', response.razorpay_payment_id);
  
    console.log('Payment successful! Payment ID:', response.razorpay_payment_id);
    console.log("vvvvvvvvvvvvvvvvvvvv", sessionStorage.getItem('razorpay_payment_id_session'))

    var za = null
    var payment_status = response.razorpay_payment_id ? "successfull" : "faild"
    var amount = options.amount ? (options.amount)/100 : "Free"

    if(response.razorpay_payment_id){
      abc(response.razorpay_payment_id, za, payment_status, amount)
    }
  
  };

const handlePaymentFailure = (error) => {
  alert('Payment failed. Please try again.')
  console.error('Payment failed! Error details:', error);
  // Additional error handling logic

  if (error.code === 'PAYMENT_FAILED') {
    setErrors((prevErrors) => ({
      ...prevErrors,
      paymentFailed: 'Payment failed. Please try again.',
    }));
  }
};

const options = {
  key: 'rzp_test_tYQzj7eoqvt0RZ', // Razorpay Key ID
  amount: storeData.amount ? storeData.amount : 100, // Amount in paise (20000 paise = ₹200)
  name: 'Online Saathi',
  description: 'Payment for your service',
  image: "https://play-lh.googleusercontent.com/QD0rI-xtHDXNtYUaFSoFhdfT4GIRbSBDt4WHk_YzvmDO6Np6LOeHZVRUB0_Kg-NlKcw",
  prefill: {
      name: `${storeData.fullName}`,
      contact: `${storeData.mobile}`,
  },
  theme: {
      color: '#F37254', // Customize the color theme
  },
  handler: handlePaymentSuccess, handlePaymentFailure
};

useEffect(() => {
  const script = document.createElement('script');
  script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  script.async = true;
  script.onload = handleScriptLoad;
  document.body.appendChild(script);

  return () => {
      // Cleanup script on component unmount
      document.body.removeChild(script);
  };
}, []);

const handleScriptLoad = () => {
  const razorpayInstance = new window.Razorpay(options);
  setRzp(razorpayInstance);
};

const handlePayNow = () => {
  if (rzp) {
    rzp.open();

    setErrors((prevErrors) => ({ ...prevErrors, payNow: undefined }));

  }
};

function abc(payment_id, payment_status, amount){
 
 var RequestData = ""

  var ccc = JSON.parse(sessionStorage.getItem("data"))
  
  ccc.payment_id = payment_id ? payment_id : "payment failed"
  ccc.payment_status = payment_id || options.amount < 1 ? "successfull" : "Free"
  ccc.amount = options.amount ? (options.amount)/100 : "Free"

  if(payment_id || options.amount === 0){


    Axios.post(`${apiUrl}/sathi/form/web`, ccc,{
      headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }}).then(res=>{
      console.log(res);
    })
    .catch((err)=>{
      console.log("errr", err);
    });

  sessionStorage.removeItem('razorpay_payment_id_session');
  sessionStorage.removeItem('data');
  alert("form submitted successfully")
}

  console.log("payment idddd", payment_id)
  console.log('payment successssssssssssss:', ccc);

}

const handleSubmit = (e) => {

    e.preventDefault();
    validateStep(step)

  
    if (step < 1 && validateStep(step)) {
      setStep(step + 1);
    }
  
    else {
  
      const razorpayPaymentId = sessionStorage.getItem('razorpay_payment_id_session');
      const paymentStatus = sessionStorage.getItem('razorpay_payment_id_session') ? "successfull" : "faild"
      console.log("fffffffff", razorpayPaymentId, paymentStatus)
      
      if (step === 1 ) {
        const y = {...storeData}
        sessionStorage.setItem("data",JSON.stringify(y));
        
        var z = ""
  
        var bbc = abc(z, y)
      }  
      
    }
  };

// const handleSubmit = (e) => {

//   e.preventDefault();

//     const razorpayPaymentId = sessionStorage.getItem('razorpay_payment_id_session');
//     const paymentStatus = sessionStorage.getItem('razorpay_payment_id_session') ? "successfull" : "faild"
//     console.log("fffffffff", razorpayPaymentId, paymentStatus)
    
//       const y = {...storeData}
//       sessionStorage.setItem("data",JSON.stringify(y));
      
//       var z = ""

//       var bbc = abc(z, y)    
// };


const getdata =()=>{
console.log(sessionStorage);
  Axios.post(`${apiUrl}/admin/remittance/ime-forex/get/datamunicipality`, {
    headers: { Authorization: "Bearer " + sessionStorage.getItem("_access") }
  })
    .then(res => {
      console.log(res)
      console.log(sessionStorage);
    
    })
    .catch(err => {
      console.log(err);
     
    });
};


useEffect(()=>{
getdata();
},[])



        return <>
            <Userheader user={storeData.user} />
            {storeData.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="maine-table-dash-sec">

                          
                             <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p>Become An Agent</p>
                                       
                                    </h3>
                                </div>
                            {storeData.b_form && <> 

                            {step === 1 && 
                            <form onSubmit={handleSubmit}>   

                                <div className="row">


                                    <div class="col-md-6 mt-3">
                                        <div className="form-group">
                                            <div>
                                                <label htmlFor="fullName fw-bold">Name</label>
                                            </div>
                                            <input
                                                type="text"
                                                name="fullName"
                                                value={storeData.fullName}
                                                onChange={handleInputChange}
                                                placeholder="full name"
                                                className="form-control"
                                            />
                                        </div>
                                        <div>
                                            {errors.fullName && (
                                            <p className="error">{errors.fullName}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div class="col-md-6 mt-3">
                                        <div>
                                            <label htmlFor="email fw-bold">Email</label>
                                        </div>
                                        <input
                                            type="email"
                                            name="email"
                                            value={storeData.email}
                                            onChange={handleInputChange}
                                            placeholder="exampe@gmail.com"
                                            className="form-control"
                                        />
                                        <div>
                                            {errors.email && <p className="error">{errors.email}</p>}
                                        </div>
                                    </div>

                                <div class="col-md-6 mt-3">
                                  <div>
                                    <label htmlFor="mobile fw-bold">Mobile Number</label>
                                  </div>
                                  <div className="phone-input-container" id="phoneInput">
                                    <div className="d-grid justify-content-center">
                                      <PhoneInput
                                        name="mobile"
                                        value={storeData.mobile}
                                        onChange={handlePhoneNumberChange}
                                        id="form-mobile-input"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                      {errors.mobile && (
                                        <p className="error">{errors.mobile}</p>
                                      )}
                                    </div>
                                </div>

                                <div className="col-md-6 mt-3">
                                        <label
                                        htmlFor="city"
                                        className="form-label theme-label color__paragraph fw-bold"
                                        >
                                        City </label>
                        
                                        <input 
                                        type="text"
                                        className="form-control" 
                                        placeholder="Enter  City" 
                                        onChange={handleInputChange} 
                                        name="city" 
                                        value={storeData.city}  />

                                        <div>
                                        {errors.city && <p className="error">{errors.city}</p>}
                                        </div>
                                </div>
                                    

                                    <div className="col-md-6 mt-3">
                                        <label
                                        htmlFor="state"
                                        className="form-label theme-label color__paragraph fw-bold"
                                        >
                                        State
                                        </label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter  State" 
                                            onChange={handleInputChange} 
                                            name="state" 
                                            value={storeData.state}  />
                                        {errors.state && <p className="error">{errors.state}</p>}
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <label
                                            htmlFor="state"
                                            className="form-label theme-label color__paragraph fw-bold"
                                        >
                                        Organization
                                        </label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter  Organization" 
                                            onChange={handleInputChange} 
                                            name="organization" 
                                            value={storeData.organization}  />
                                        {errors.organization && <p className="error">{errors.organization}</p>}
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <label
                                            htmlFor="state"
                                            className="form-label theme-label color__paragraph fw-bold"
                                        >
                                        Description
                                        </label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter  Organization" 
                                            onChange={handleInputChange} 
                                            name="description" 
                                            value={storeData.description}  />
                                        {errors.description && <p className="error">{errors.description}</p>}
                                    </div>

                                    <div className="col-md-12 mt-4">
                                        <div className="">
                                            
                                            <label >Do you work as Agent in any other Company</label>
                                             <div>
                                                <label  >Yes</label>     
                                                <input 
                                                    type="radio"  
                                                    onChange={handleInputChange} 
                                                    name="other_agent" 
                                                    value="yes" 
                                                    checked={storeData.other_agent === "yes"}    />
                                                &nbsp; &nbsp;	&nbsp;	

                                                <label >No</label>  
                                                <input 
                                                    type="radio"  
                                                    onChange={handleInputChange} 
                                                    name="other_agent" 
                                                    value="no" 
                                                    checked={storeData.other_agent === "no"}  />
                                            </div>
                                            {errors.other_agent && <p className="error">{errors.other_agent}</p>}
                                         </div>
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <label
                                            htmlFor="state"
                                            className="form-label theme-label color__paragraph fw-bold"
                                        >
                                        Bank Account Number
                                        </label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter  Bank Account Number" 
                                            onChange={handleInputChange} 
                                            name="bank_account_number" 
                                            value={storeData.bank_account_number}  />
                                              {errors.bank_account_number && <p className="error">{errors.bank_account_number}</p>}
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <label
                                            htmlFor="state"
                                            className="form-label theme-label color__paragraph fw-bold"
                                        >
                                        Aadhar Card Number
                                        </label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter  Aadhar Card Number" 
                                            onChange={handleInputChange} 
                                            name="adhar_card_number" 
                                            value={storeData.adhar_card_number}  />
                                             {errors.adhar_card_number && <p className="error">{errors.adhar_card_number}</p>}
                                    </div>

                                    <div className="col-md-6 mt-4">
                                        <label
                                            htmlFor="state"
                                            className="form-label theme-label color__paragraph fw-bold"
                                        >
                                        Pan Card Number
                                        </label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter  Pan Card Number" 
                                            onChange={handleInputChange} 
                                            name="pan_card_number" 
                                            value={storeData.pan_card_number}  />
                                            {errors.pan_card_number && <p className="error">{errors.pan_card_number}</p>}
                                    </div>

                                    <div className="col-md-6 mt-4">
                                        <label
                                            htmlFor="state"
                                            className="form-label theme-label color__paragraph fw-bold"
                                        >
                                        Aadhar Card Front
                                        </label>
                                        <input 
                                            type="file" 
                                            className="form-control" 
                                            placeholder="Enter  Pan Card Number" 
                                            onChange={handleInputChange} 
                                            name="adhar_front" 
                                            value={storeData.adhar_front}  />
                                            {errors.adhar_front && <p className="error">{errors.adhar_front}</p>}
                                    </div>

                                    <div className="col-md-6 mt-4">
                                        <label
                                            htmlFor="state"
                                            className="form-label theme-label color__paragraph fw-bold"
                                        >
                                        Aadhar Card Back
                                        </label>
                                        <input 
                                            type="file" 
                                            className="form-control" 
                                            placeholder="Enter  Aadhar Card Number" 
                                            onChange={handleInputChange} 
                                            name="adhar_back" 
                                            value={storeData.adhar_back}  />
                                            {errors.adhar_back && <p className="error">{errors.adhar_back}</p>}
                                    </div>

                                    <div className="col-md-6 mt-4">
                                        <label
                                            htmlFor="state"
                                            className="form-label theme-label color__paragraph fw-bold"
                                        >
                                        Pan Card Image
                                        </label>
                                        <input 
                                            type="file" 
                                            className="form-control" 
                                            placeholder="Enter  Aadhar Card Number" 
                                            onChange={handleInputChange} 
                                            name="pan_image" 
                                            value={storeData.pan_image}  />
                                            {errors.pan_image && <p className="error">{errors.pan_image}</p>}
                                    </div>

                                    <div className="col-md-6 mt-4">
                                        <div className="">
                                            
                                            <label >Bank Passbook/Cheque Book Image</label>
                                            <input 
                                                type="file"  
                                                className="form-control" 
                                                onChange={handleInputChange} 
                                                name="bank_image"  
                                                value={storeData.bank_image}  />
                                                {errors.bank_image && <p className="error">{errors.bank_image}</p>}
                                        </div>
                                    </div> 

                                    <div className="col-md-6 mt-4">
                                        <div className="">
                                            
                                            <label >Other Documents</label>
                                            <input 
                                                type="file"  
                                                className="form-control" 
                                                onChange={handleInputChange} 
                                                name="other_documents"  
                                                value={storeData.other_documents}  />
                                                 {errors.other_documents && <p className="error">{errors.other_documents}</p>}    
                                        </div>
                                    </div> 

                                <button
                                    className="become-member-next mt-5" 
                                    id="become-agent-submit"
                                        type="submit" 
                                        value={storeData.payNow} 
                                    onClick={ step === 1 && storeData.fullName !== "" && storeData.organization !== "" && storeData.state !== "" && storeData.city !== "" && storeData.email !== "" && storeData.mobile !== "" && storeData.description !== "" && storeData.other_agent !== "" && storeData.bank_account_number !== "" && storeData.adhar_card_number !== "" && storeData.pan_card_number !== "" && storeData.adhar_front !== "" && storeData.adhar_back !== "" && storeData.pan_image !== "" && storeData.bank_image !== "" &&  storeData.other_documents !== "" && 
                                        options.amount > 0
                                        ? handlePayNow
                                        : null
                                        }>
                                       {options.amount > 0 ? "Pay Now & Submit" : "Submit"}
                                </button>  

                                </div>
                            </form>
                            }
                                </>}
                                {!storeData.b_form && 
                                
                                   <h3>Your request is already submitted</h3>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <Userfooter />
        </>;
}
export default Addmoney;



















// import React from "react";
// import Usersidebar from "../Usersidebar";
// import Userfooter from "../Footer";
// import Userheader from "../Header";
// import Razorpay from 'razorpay';

// import * as moment from "moment";
// import SimpleCrypto from "simple-crypto-js";
// import Axios from "axios";
// import { Modal } from "reactstrap";
// import ReceiptModel from "../ReceiptModel";
// import swal from 'sweetalert';

// const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
// const Cryp = new SimpleCrypto(_secretKey);

// const url = window.location.href;
// var apiUrl = process.env.REACT_APP_API_URL_LIVE;
// var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
// if (!url.includes('onlinesaathi.org')) {
//     apiUrl = process.env.REACT_APP_API_URL;
//     imageUrl = process.env.REACT_APP_IMAGE_URL;
// }


// class Addmoney extends React.Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             loading: false,
//             add:false,
//             name:"",
//             other_agent:"no",
//             organization:"",
//             state:"",
//             city:"",
//             email:"",
//             contact_number:"",
//             description:"",
//             bank_account_number:"",
//             pan_card_number:"",
//             adhar_card_number:"",
//             pan_image:null,
//             pan_images:null,
//             adhar_front:null,
//             adhar_fronts:null,
//             adhar_back:null,
//             adhar_backs:null,
//             bank_image:null,
//             bank_images:null,
//             other_documents:null,
//             other_documentss:null,
//             b_form:true,
//             details:[],
//             payment_id: "",
//             payment_status: "",
//             rzp: null,
//             payNowClicked: false,
//             paymentResponse: { razorpay_payment_id: null },      
//         }
//     }


//     componentDidMount = () => {
//         if (!sessionStorage.getItem("_access")) {
//             this.props.history.push("/");

//         }
//         this.setState({
//             user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
//         })
      
//         this.getData();
       
//     }

       
//     getData = (page = 1) => {
//         var data = {};
//         Axios.get(`${apiUrl}/becomeanagent/check`, {
//             headers: {
//                 Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
//             }
//         }).then(res => {
//               if(res.data.success){
//                 this.setState({
//                     b_form:false,
                   
//                 })
//               }else{
//                 this.setState({
//                     b_form:true,
                   
//                 })
//               }
          
//         },err=>{
//             swal("","Session expired. Please login again.").then(o=>{
//                 sessionStorage.clear();
//                 this.props.history.push("/");
//             })
//         })
//     }

//     handleImage = (e, name) => {
//         this.setState({
//             [name]: e.target.files[0]
//         })
//     }
    
//     clearForms = () => {
//         this.setState({
//             deposit_date:"",
//             amount:"",
//             bank_name:"",
//             image:"",
//         })
//     }

//     submitform = e => {
//         e.preventDefault();
       
//         if (this.state.name === "") {
//             swal("", "Please Enter Name.", "error");
//             return false;
//         }
//         if (this.state.email === "") {
//             swal("", "Please Enter E-mail.", "error");
//             return false;
//         }
//         if (this.state.contact_number === "") {
//             swal("", "Please Enter Mobile Number.", "error");
//             return false;
//         }
        
//         if (this.state.city === "") {
//             swal("", "Please Enter City.", "error");
//             return false;
//         }
//         if (this.state.state === "") {
//             swal("", "Please Enter State.", "error");
//             return false;
//         }
//         if (this.state.organization === "") {
//             swal("", "Please Enter Organization.", "error");
//             return false;
//         }
//         if (this.state.description === "") {
//             swal("", "Please Enter Description.", "error");
//             return false;
//         }
//         if (this.state.bank_account_number === "") {
//             swal("", "Please Enter Bank Account Number.", "error");
//             return false;
//         }
//         if (this.state.pan_card_number === "") {
//             swal("", "Please Enter Pan Card Number.", "error");
//             return false;
//         } 
//         if (this.state.adhar_card_number === "") {
//             swal("", "Please Enter Aadhar Card Number.", "error");
//             return false;
//         } 
        
//         if (this.state.pan_image === "") {
//             swal("", "Please  select pan image.", "error");
//             return false;
//         }
//         if (this.state.adhar_front === "") {
//             swal("", "Please  select aadhar front image.", "error");
//             return false;
//         }
//         if (this.state.adhar_back === "") {
//             swal("", "Please  select aadhar back image.", "error");
//             return false;
//         }
//         if (this.state.bank_image === "") {
//             swal("", "Please Select Bank Passbook/Cheque Book Image.", "error");
//             return false;
//         }
         
//         this.setState({ loading: true })

//         var formdata = new FormData();
//         formdata.append("name", this.state.name);
//         formdata.append("organization", this.state.organization);
//         formdata.append("city", this.state.city);
//         formdata.append("state", this.state.state);
//         formdata.append("email", this.state.email);
//         formdata.append("contact_number", this.state.contact_number);
//         formdata.append("description", this.state.description);
//         formdata.append("pan_card_number", this.state.pan_card_number);
//         formdata.append("adhar_card_number", this.state.adhar_card_number);
//         formdata.append("bank_account_number", this.state.bank_account_number);
//        // formdata.append("description", this.state.description);
        
//         formdata.append("pan_image", this.state.pan_image);
//         formdata.append("adhar_front", this.state.adhar_front);
//         formdata.append("adhar_back", this.state.adhar_back);
//         formdata.append("bank_image", this.state.bank_image);
//         formdata.append("other_documents", this.state.other_documents);
//         formdata.append("other_agent", this.state.other_agent);
        
//         Axios.post(`${apiUrl}/become/an/agent`, formdata, {
//             headers: {
//                 Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
//             }
//         }).then(res => {
//             this.setState({ loading: false })
//             console.log("send transaction response", res)
//             if (res.data.success) {
//                 swal("", res.data.message, "success");
//                 this.setState({
//                     name:"",
//                     organization:"",
//                     state:"",
//                     city:"",
//                     email:"",
//                     contact_number:"",
//                     description:"",
//                     bank_account_number:"",
//                     pan_card_number:"",
//                     adhar_card_number:"",
//                     pan_image:null,
//                     pan_images:null,
//                     adhar_front:null,
//                     adhar_fronts:null,
//                     adhar_back:null,
//                     adhar_backs:null,
//                     bank_image:null,
//                     bank_images:null,
//                     other_documents:null,
//                     other_documentss:null,
//                     b_form:false,
//                     payNow: "",
//                     rzp: null,
//                     payNowClicked: false,
//                     errors: {},
//                     paymentResponse: null,
//                     script: null,
//                 })
               
//             }
//             else {
//                 swal("", res.data.message, "error");
//                 this.setState({ loading: false })
//             }
//         }, err => {
//             swal("", err.message, "error");
//             this.setState({ loading: false })
//             // swal("", "Session expired. Please login again.").then(o => {
//             //  //   sessionStorage.clear();
//             // //    this.props.history.push("/");
//             // })
//         })
//     }
    
 
//     logout = (e) => {
//         e.preventDefault();
//         sessionStorage.clear();
//         localStorage.clear();
//         this.props.history.push("/");
//     }

//     handleInput = e => {
//         this.setState({
//             [e.target.name]: e.target.value
//         })
//     }
   
//     options = {
//         key: 'rzp_test_tYQzj7eoqvt0RZ', // Replace with your Razorpay API key
//         name: 'Online Saathi',
//         description: 'Payment for your service',
//         image: 'https://play-lh.googleusercontent.com/QD0rI-xtHDXNtYUaFSoFhdfT4GIRbSBDt4WHk_YzvmDO6Np6LOeHZVRUB0_Kg-NlKcw',
//         theme: {
//           color: '#F37254', // Customize the color theme
//         },
//         handler: this.handlePaymentSuccess,
//         modal: {
//           ondismiss: this.handleModalDismiss,
//         },
//       };

    
//   componentDidMount = () => {
//     const script = document.createElement('script');
//     script.src = 'https://checkout.razorpay.com/v1/checkout.js';
//     script.async = true;
//     script.onload = this.handleScriptLoad;
//     document.body.appendChild(script);

//     this.setState({ script });
//   };

//   componentWillUnmount() {
//     // Cleanup script on component unmount
//     document.body.removeChild(this.state.script);
//   }

//   handlePaymentSuccess = (response) => {
//     alert('Payment successful! Payment ID: ' + response.razorpay_payment_id);
//     // Additional logic for handling success response

//     this.setState({ paymentResponse: response });
//   };

//   handleModalDismiss = () => {
//     // Handle modal close event
//     this.setState({ payNowClicked: false });
//   };

//   handleScriptLoad = () => {
//     const razorpayInstance = new window.Razorpay(this.options);
//     this.setState({ rzp: razorpayInstance });
//   };

//   handlePayNow = () => {
//     if (this.state.rzp) {
//       this.state.rzp.open();

//       this.setState({
//         payNowClicked: true,
//         errors: { ...this.state.errors, payNow: undefined },
//       });
//     }
//   };
   
//     render() {
//         return <>
//             <Userheader user={this.state.user} />
//             {this.state.loading && <div className="the-loader">
//                 <div className="spinner-border">
//                     <span className="sr-only">Loading...</span>
//                 </div>
//             </div>}
//             <section className="main-sidebar_dash">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Usersidebar logout={this.logout} />
//                         <div className="col-md-10 right_contentsec">
//                             <div className="maine-table-dash-sec">

                          
//                              <div className="heading-title-sec">
//                                     <h3 className="admin-heading bg-offwhite">
//                                         <p>Become An Agent</p>
                                       
//                                     </h3>
//                                 </div>
//                          {this.state.b_form && <> 
//                               <div className="row">
                                
//                                     <div className="col-md-6">
//                                         <div className="form-group">
                                            
//                                             <label >Name</label>
//                                             <input type="text" className="form-control" placeholder="Enter  Name" onChange={e => { this.handleInput(e) }} name="name" value={this.state.name}  />
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="">
                                            
//                                             <label >E-mail</label>
//                                             <input type="text" className="form-control" placeholder="Enter  E-mail" onChange={e => { this.handleInput(e) }} name="email" value={this.state.email}  />
//                                          </div>
//                                     </div> 
//                                     <div className="col-md-6 mt-3">
//                                         <div className="">
                                            
//                                             <label >Mobile</label>
//                                             <input type="text" className="form-control" placeholder="Enter  Mobile" onChange={e => { this.handleInput(e) }} name="contact_number" value={this.state.contact_number}  />
//                                          </div>
//                                     </div> 
//                                     <div className="col-md-6 mt-3">
//                                         <div className="">
                                            
//                                             <label >City</label>
//                                             <input type="text" className="form-control" placeholder="Enter  City" onChange={e => { this.handleInput(e) }} name="city" value={this.state.city}  />
//                                          </div>
//                                     </div> 
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >State</label>
//                                             <input type="text" className="form-control" placeholder="Enter  State" onChange={e => { this.handleInput(e) }} name="state" value={this.state.state}  />
//                                          </div>
//                                     </div> 
                                     
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >Organization</label>
//                                             <input type="text" className="form-control" placeholder="Enter  Organization" onChange={e => { this.handleInput(e) }} name="organization" value={this.state.organization}  />
//                                          </div>
//                                     </div> 
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >Description</label>
//                                             <input type="text" className="form-control" placeholder="Enter  Description" onChange={e => { this.handleInput(e) }} name="description" value={this.state.description}  />
//                                          </div>
//                                     </div>
//                                     <div className="col-md-12 mt-4">
//                                         <div className="">
                                            
//                                             <label >Do you work as Agent in any other Company</label>
//                                              <div>
//                                              <label  >Yes</label>     
//                                             <input type="radio"  onChange={e => { this.handleInput(e) }} name="other_agent" value="yes" checked={this.state.other_agent === "yes"}    />
//                                               &nbsp; &nbsp;	&nbsp;	
//                                             <label >No</label>  
//                                             <input type="radio"  onChange={e => { this.handleInput(e) }} name="other_agent" value="no" checked={this.state.other_agent === "no"}  />
//                                             </div>
//                                          </div>
//                                     </div>
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >Bank Account Number</label>
//                                             <input type="text" className="form-control" placeholder="Enter  Bank Account Number" onChange={e => { this.handleInput(e) }} name="bank_account_number" value={this.state.bank_account_number}  />
//                                          </div>
//                                     </div>
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >Aadhar Card Number</label>
//                                             <input type="text" className="form-control" placeholder="Enter  Aadhar Card Number" onChange={e => { this.handleInput(e) }} name="adhar_card_number" value={this.state.adhar_card_number}  />
//                                          </div>
//                                     </div>
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >Pan Card Number</label>
//                                             <input type="text" className="form-control" placeholder="Enter  Pan Card Number" onChange={e => { this.handleInput(e) }} name="pan_card_number" value={this.state.pan_card_number}  />
//                                          </div>
//                                     </div>

                         

     
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >Aadhar Card Front</label>
//                                             <input type="file"  className="form-control" onChange={e => { this.handleImage(e,'adhar_front') }} name="adhar_fronts"   />
                                    
//                                         </div>
//                                     </div> 
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >Aadhar Card Back</label>
//                                             <input type="file"  className="form-control" onChange={e => { this.handleImage(e,'adhar_back') }} name="adhar_backs"   />
                                    
//                                         </div>
//                                     </div> 
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >Pan Card Image</label>
//                                             <input type="file"  className="form-control" onChange={e => { this.handleImage(e,'pan_image') }} name="pan_images"   />
                                    
//                                         </div>
//                                     </div> 
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >Bank Passbook/Cheque Book Image</label>
//                                             <input type="file"  className="form-control" onChange={e => { this.handleImage(e,'bank_image') }} name="bank_images"   />
                                    
//                                         </div>
//                                     </div> 
//                                     <div className="col-md-6 mt-4">
//                                         <div className="">
                                            
//                                             <label >Other Documents</label>
//                                             <input type="file"  className="form-control" onChange={e => { this.handleImage(e,'other_documents') }} name="other_documentss"   />
                                    
//                                         </div>
//                                     </div> 

//                                     <div className="payment-div" id="paymentDivId">
//                                         <p>
//                                           Submit Fee{' '}
//                                           <span>
//                                               <button value={this.state.payNow} onClick={this.handlePayNow} className="payNow-buttton">Pay Now</button>
//                                           </span>{' '}
//                                         </p>
//                                     </div>

//                                     <div className="col-md-12 mt-4">
//                                         <div className="send-bt-dash">
//                                             <a href="javascript:void(0)" onClick={e => { this.submitform(e) }}>Process</a>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 </>}
//                                 {!this.state.b_form && 
                                
//                                    <h3>Your request is already submitted</h3>
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
           
//             <Userfooter />
//         </>;
//     }
// }
// export default Addmoney;

