import React from "react";
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import { Pagination } from 'react-laravel-paginex';
import { CSVLink } from "react-csv";
import SimpleCrypto from "simple-crypto-js";
import ImeUploadDocs from "./forms/ImeUploadDocs";
import ImeCsRegister from "./forms/ImeCspRegister";
import Prabhucsp from "./forms/Prabhucsp";
import env from "variables/constants";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);
var apiUrl = env.url;
var imageUrl = env.img_url;
const moment = require('moment');
class AgentRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            remittance_type: "",
            rewardamount: 0,
            reward_comment: "",
            viewRewardModal: false,
            loading: false,
            dropdownOpen: false,
            showCspForm: false,
            uploadDocumentForm: false,
            users: [],
            placement: "top",
            status: "",
            banner_image: null,
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 15,
                prev_page_url: null,
                to: 0,
                total: 0
            },
            show_form: false,
            viewModal: false,
            blockModal: false,
            imageModal: false,
            input_block_reason: "",
            deductModal: false,
            selected_user: null,
            deduct_from: "remit_balance",
            deduct_amount: 0,
            deduct_reason: "",
            keyword: "",
            additional_course: [],
            alternate_occupation_type: [],
            bank_account_type: [],
            csp_address_proof_type: [],
            csp_business_type: [],
            csp_device: [],
            csp_device_connectivity_type: [],
            csp_document_type: [],
            csp_registration_type: [],
            customer_identification_type_indian: [],
            customer_identification_type_neplease: [],
            educational_qualification: [],
            gender: [],
            marital_status: [],
            occupation: [],
            owner_category: [],
            owners_address_proof_type: [],
            owners_id_type: [],
            physically_handicapped: [],
            purpose_of_remittance: [],
            relationship: [],
            source_of_fund: [],
            transaction_cancel_reason: [],
            states: [],
            districts: [],
            ostates: [],
            odistricts: [],
            municipalities: [],
            banks: [],
            registration_type: "",
            bussiness_type: "",
            pan_number: "",
            csp_country: "",
            csp_state: "",
            csp_district: "",
            municipality: "",
            csp_city: "",
            house_number: "",
            road_name: "",
            pin_code: "",
            address_proof_type: "",
            contact_person_name: "",
            mobile_number: "",
            mobile_number2: "",
            land_line_number: "",
            csp_email: "",
            bank: "",
            csp_bank_account_type: "",
            bank_account_number: "",
            ifsc: "",
            device: "",
            connectivity_type: "",
            provider: "",
            start_time: "",
            end_time: "",
            has_week_off: "",
            off_day: "",
            owners_name: "",
            owners_gender: "",
            owners_dob: "",
            owners_father_name: "",
            owners_spouse_name: "",
            owners_category: "",
            owners_is_handicapped: "",
            owners_mobile_number: "",
            owners_contact_number: "",
            owners_email: "",
            owners_alternate_occupation: "",
            owners_pan_card: "",
            owners_id_types: "",
            owners_id_number: "",
            owners_country: "",
            owners_state: "",
            owners_district: "",
            owners_city: "",
            owners_house_number: "",
            owners_road_name: "",
            owners_pin_code: "",
            owners_village_code: "",
            owners_address_prrof_type: "",
            owners_qualification: "",
            owners_additional_course: "",
            owners_institute_name: "",
            owners_date_of_passed: "",
            uploadOther: null,
            documents: [],
            excelusers: [],
            tempLoginModal: false,
            input_notice: "",
            imagesrcView: null,
            sort_by: "remit_desc",
            status_filter: "all",
            allpermission: [],
            show_edit: false,
            caseback: false,
            balance: false,
            excel: false,
            otp_verified: false,
            otp: "",
            selected_user: null,
            otp_token: "",
            upgrade_type: ""
        }
    }
    componentDidMount = () => {
        this.getAllUsers();
        this.permissions();
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    toggleDropdown = (id) => {
        this.setState({
            dropdownOpen: this.state.dropdownOpen === id ? 0 : id
        })
    }
    permissions = () => {

        this.setState({
            allpermission: JSON.parse(sessionStorage.getItem("adminpermission")),
            cuser: Cryp.decrypt(sessionStorage.getItem("cuser")),

        })
        setTimeout(() => {

            this.state.allpermission.map((val, key) => {
                if (val.page_name === "edit-users") {
                    this.setState({
                        show_edit: true
                    })
                } else if (val.page_name === "cashback") {
                    this.setState({
                        caseback: true
                    })
                } else if (val.page_name === "deduct-balance") {
                    this.setState({
                        balance: true
                    })
                } else if (val.page_name === "export-excel") {
                    this.setState({
                        excel: true
                    })
                }
            })
            //   console.log("truepage",this.state.show_edit);
            // console.log("cuser", this.state.cuser);
            // console.log("allpermission", this.state.allpermission);

        }, 500);

    }
    getAllUsers = (page = 1, keyword = null) => {
        var data = {
            keyword: keyword ? keyword : this.state.keyword,
            sort_by: this.state.sort_by,
            status_filter: this.state.status_filter
        }
        // console.log('send_data', data);
        Axios.post(`${apiUrl}/admin/all/agentresquest?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            // console.log("users response", res)
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.users,
                    users: res.data.users.data
                });
                this.getAllUsersExcel();
            }
            else {
                this.setState({
                    users: []
                })
            }
        })
    }
    getAllUsersExcel = (page = 1, keyword = null) => {
        var data = {
            keyword: keyword ? keyword : this.state.keyword,
            sort_by: this.state.sort_by,
            status_filter: this.state.status_filter,
            forexcel: "all"
        }
        Axios.post(`${apiUrl}/admin/all/agentresquest?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("users response", res)
            if (res.data.success) {
                var excelusers = [];
                res.data.users.forEach((usr, ki) => {
                    var xx = {
                        "JOINING DATE":moment(usr.created_at).format('lll'),
                        "Name": usr.name,
                        "Mobile": usr.mobile,
                        "Email": usr.email,
                        "Wallet Balance": usr.wallet_balance,
                        "Remittance Balance": usr.remit_balance,
                        "Reference": usr.referer ? usr.referer : "Self",
                        "State": usr.state,
                        "District": usr.district,
                        "City": usr.city,
                        "Address": usr.address,
                        "Status": usr.status
                    };
                    excelusers.push(xx);
                })
                this.setState({
                    excelusers: excelusers
                })
            }
            else {
                this.setState({
                    users: []
                })
            }
        })
    }
    getStaticData = () => {
        this.setState({ loading: true })
        Axios.get(apiUrl + "/remittance/ime-forex/get/all/static/data", {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            // console.log("response", res)
            this.setState(res.data.data);
            this.setState({ loading: false })
        })
    }
    makeDealer = (e, val) => {
        e.preventDefault();
        this.getStaticData();
        this.setState({
            selected_user: val,
            showCspForm: true,
            // uploadDocumentForm: val.csp_code !== null
        });
        this.getDocumentsList(val.id);
        this.setState(val);
        // if (window.confirm(`Role of "${val.name}" will be changed to dealer.`)) {
        //     Axios.post(`${apiUrl}/admin/make/dealer`, { user_id: val.id }, {
        //         headers: {
        //             Authorization: "Bearer " + sessionStorage.getItem("_access")
        //         }
        //     }).then(res => {
        //         console.log("make dealer response", res)
        //         this.getAllUsers(this.state.paginatedata.current_page);
        //         if (res.data.success) {
        //             this.notify('success', res.data.message);
        //         }
        //         else {
        //             this.notify('error', res.data.message);
        //         }
        //     }, err => {
        //         console.log("make dealer error", err)
        //         this.notify('error', "Something went wrong.");
        //     })
        // }
        // console.log(val)
    }
    changePage = data => {
        // console.log(data)
        this.getAllUsers(data.page);
    }
    notify = (type, message) => {
        var atype = type === "error" ? "danger" : type;
        var options = {};
        options = {
            place: process.env.REACT_APP_TOAST_POSITION,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: atype,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    viwDetails = (e, user) => {
        e.preventDefault();
        this.setState(user);
        // console.log(user);
        this.toggleViewModal();
    }
    handleError = (event) => {
        this.setState({ profile_pic: "/uploads/static/anime3.png" });
    }
    toggleViewModal = () => {
        this.setState({
            viewModal: !this.state.viewModal
        });
    }
    toggleBlockModal = () => {
        this.setState({
            blockModal: !this.state.blockModal
        })
    }
    toggleImageModal = (e, x = null) => {
        this.setState({
            imageModal: !this.state.imageModal,
            imagesrcView: x
        })
    }
    toggleTempLoginModal = () => {
        this.setState({
            tempLoginModal: !this.state.tempLoginModal
        })
    }
    blockUser = () => {
        if (this.state.input_block_reason === "") {
            this.notify("error", "Please enter reason to block.");
            return false;
        }

        Axios.post(apiUrl + "/admin/block/user", { id: this.state.id, reason: this.state.input_block_reason }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            this.getAllUsers(this.state.paginatedata.current_page);
            if (type === "success") {
                this.setState({
                    status: "blocked",
                    block_reason: this.state.input_block_reason,
                    blockModal: false,
                    input_block_reason: ""
                })
            }
        })
    }
    approveUser = () => {
        if (this.state.input_notice === "") {
            this.notify("error", "Please add notice.");
            return false;
        }

        Axios.post(apiUrl + "/admin/approve/user", { id: this.state.id, status: "temp_login", notice: this.state.input_notice }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            this.getAllUsers(this.state.paginatedata.current_page);
            if (type === "success") {
                this.setState({
                    status: "temp_login",
                    notice: this.state.input_notice,
                    blockModal: false,
                    input_notice: "",
                    tempLoginModal: false
                })
            }
        })
    }
    activeUser = e => {
        e.preventDefault();
        Axios.post(apiUrl + "/admin/make/user/acive", { id: this.state.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.getAllUsers(this.state.paginatedata.current_page);
            var type = res.data.success ? "success" : "error";
            this.notify(type, res.data.message);
            if (type === "success") {
                this.setState({
                    status: "active",
                    block_reason: "",
                    input_block_reason: ""
                })
            }
        })
    }
    toggleDeductModal = () => {
        this.setState({
            deductModal: !this.state.deductModal
        })
    }
    deductBalance = (e, val) => {
        e.preventDefault();
        this.setState({
            selected_user: val,
            deductModal: true
        })
    }
    deductBalanceSubint = () => {
        if (this.state.deduct_amount <= 0) {
            this.notify('error', "Amount should be more than 0.");
            return false;
        }
        if (this.state.deduct_from === "wallet_balance") {
            if (this.state.selected_user.wallet_balance < this.state.deduct_amount) {
                this.notify("error", "Amount should be less than or equal to user's wallet balance.");
                return false;
            }
        }
        if (this.state.deduct_from === "remit_balance") {
            if (this.state.selected_user.remit_balance < this.state.deduct_amount) {
                this.notify("error", "Amount should be less than or equal to user's wallet balance.");
                return false;
            }
        }
        if (this.state.deduct_reason === "") {
            this.notify('error', "Please enter reason.");
            return false;
        }
        var data = {
            deduct_amount: this.state.deduct_amount,
            user_id: this.state.selected_user.id,
            deduct_from: this.state.deduct_from,
            deduct_reason: this.state.deduct_reason,
        }
        Axios.post(apiUrl + `/admin/deduct/balance`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            var type = res.data.success ? "success" : "error";
            if (type === "success") {
                this.getAllUsers(this.state.paginatedata.current_page);
                this.setState({
                    deduct_amount: 0,
                    selected_user: null,
                    deduct_from: "remit_balance",
                    deduct_reason: "",
                    deductModal: false
                })
            }
            this.notify(type, res.data.message);
            // console.log(res)
        })
    }
    searchUser = e => {
        e.preventDefault();
        var keyword = e.target.value;
        this.setState({
            keyword: keyword
        })
        setTimeout(() => {
            if (keyword.length > 2 || keyword === "") {
                this.getAllUsers(1, keyword);
            }
        }, 200);
    }
    handleCountryChange = (e, param) => {
        var data = { country_id: e.target.value }
        var x = 'states';
        if (param === 'owners_state') {
            x = 'ostates';
            this.setState({ owners_country: e.target.value, loading: true })
        }
        else {
            this.setState({ csp_country: e.target.value, loading: true })

        }
        this.getBanks(e.target.value);
        Axios.post(apiUrl + "/remittance/ime-forex/get/state", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {

            this.setState({
                [x]: res.data.data,
                loading: false
            })
        })
    }
    handleInputState = (e, param) => {
        var x = 'districts';
        if (param === 'owners_district') {
            x = 'odistricts';
            this.setState({ owners_state: e.target.value, loading: true })
        }
        else {
            this.setState({ csp_state: e.target.value, loading: true })

        }
        var data = { state_id: e.target.value }
        Axios.post(apiUrl + "/remittance/ime-forex/get/district", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {


            this.setState({
                [x]: res.data.data,
                loading: false
            })
        })
    }

    handleInputDistrict = (e, param) => {
        if (param === 'municipality') {
            this.setState({ csp_district: e.target.value })
        }
        else {
            this.setState({ owners_district: e.target.value })

        }
        if (this.state.csp_country === "NPL") {
            var data = { district_id: e.target.value }
            this.setState({
                loading: true
            })
            Axios.post(apiUrl + "/remittance/ime-forex/get/municipality", data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("_access")
                }
            }).then(res => {
                this.setState({
                    municipalities: res.data.data,
                    loading: false
                })
            })
        }
    }

    getBanks = (country_id) => {
        this.setState({
            loading: true
        })
        Axios.post(apiUrl + "/remittance/ime-forex/get/banks", { country_id: country_id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                loading: false
            })
            this.setState({ banks: res.data.data })
        }, err => {
            this.setState({
                loading: false
            })
        })
    }
    cancelCSPReg = e => {
        e.preventDefault();
        this.setState({ showCspForm: false })
    }
    submitCSP = e => {
        e.preventDefault();
        var data = {
            user_type: this.state.upgrade_type,
            registration_type: this.state.registration_type,
            bussiness_type: this.state.bussiness_type,
            cspname: this.state.cspname,
            pan_number: this.state.pan_number,
            country: this.state.csp_country,
            state: this.state.csp_state,
            district: this.state.csp_district,
            municipality: this.state.municipality,
            city: this.state.csp_city,
            house_number: this.state.house_number,
            road_name: this.state.road_name,
            pin_code: this.state.pin_code,
            address_proof_type: this.state.address_proof_type,
            contact_person_name: this.state.contact_person_name,
            mobile_number: this.state.mobile_number,
            mobile_number2: this.state.mobile_number2,
            land_line_number: this.state.land_line_number,
            email: this.state.csp_email,
            bank: this.state.bank,
            bank_account_type: this.state.csp_bank_account_type,
            bank_account_number: this.state.bank_account_number,
            ifsc: this.state.ifsc,
            device: this.state.device,
            connectivity_type: this.state.connectivity_type,
            provider: this.state.provider,
            start_time: this.state.start_time,
            end_time: this.state.end_time,
            has_week_off: this.state.has_week_off,
            off_day: this.state.off_day,
            owners_name: this.state.owners_name,
            owners_gender: this.state.owners_gender,
            owners_dob: this.state.owners_dob,
            owners_father_name: this.state.owners_father_name,
            owners_spouse_name: this.state.owners_spouse_name,
            owners_category: this.state.owners_category,
            owners_is_handicapped: this.state.owners_is_handicapped,
            owners_mobile_number: this.state.owners_mobile_number,
            owners_contact_number: this.state.owners_contact_number,
            owners_email: this.state.owners_email,
            owners_alternate_occupation: this.state.owners_alternate_occupation,
            owners_pan_card: this.state.owners_pan_card,
            owners_id_type: this.state.owners_id_types,
            owners_id_number: this.state.owners_id_number,
            owners_country: this.state.owners_country,
            owners_state: this.state.owners_state,
            owners_district: this.state.owners_district,
            owners_city: this.state.owners_city,
            owners_house_number: this.state.owners_house_number,
            owners_road_name: this.state.owners_road_name,
            owners_pin_code: this.state.owners_pin_code,
            owners_village_code: this.state.owners_village_code,
            owners_address_prrof_type: this.state.owners_address_prrof_type,
            owners_qualification: this.state.owners_qualification,
            owners_additional_course: this.state.owners_additional_course,
            owners_institute_name: this.state.owners_institute_name,
            owners_date_of_passed: this.state.owners_date_of_passed,
            user_id: this.state.selected_user.id
        }
        if (data.user_type === "") {
            this.notify("error", "Please select upgrade type.");
            return false;
        }
        if (data.registration_type === "") {
            this.notify("error", "Please select Registration type.");
            return false;
        }
        if (data.bussiness_type === "") {
            this.notify("error", "Please select Business type.");
            return false;
        }
        if (data.cspname === "") {
            this.notify("error", "Please CSP name.");
            return false;
        }
        if (data.pan_number === "") {
            this.notify("error", "Please enter pan number.");
            return false;
        }
        if (data.country === "") {
            this.notify("error", "Please select country.");
            return false;
        }
        if (data.state === "") {
            this.notify("error", "Please select state.");
            return false;
        }
        if (data.district === "") {
            this.notify("error", "Please select district.");
            return false;
        }
        if (data.municipality === "" && data.country === "NPL") {
            this.notify("error", "Please select municipality.");
            return false;
        }
        if (data.city === "") {
            this.notify("error", "Please enter city.");
            return false;
        }
        if (data.pin_code === "") {
            this.notify("error", "Please enter pincode.");
            return false;
        }
        if (data.address_proof_type === "") {
            this.notify("error", "Please select address proof type.");
            return false;
        }
        if (data.contact_person_name === "") {
            this.notify("error", "Please enter contact person name.");
            return false;
        }
        if (data.mobile_number === "") {
            this.notify("error", "Please enter mobile number.");
            return false;
        }
        if (data.email === "") {
            this.notify("error", "Please enter email.");
            return false;
        }
        if (data.bank === "") {
            this.notify("error", "Please select Bank.");
            return false;
        }
        if (data.bank_account_type === "") {
            this.notify("error", "Please select bank account type.");
            return false;
        }
        if (data.bank_account_number === "") {
            this.notify("error", "Please enter bank account number.");
            return false;
        }
        if (data.ifsc === "") {
            this.notify("error", "Please enter IFSC.");
            return false;
        }
        if (data.device === "") {
            this.notify("error", "Please select device.");
            return false;
        }
        if (data.connectivity_type === "") {
            this.notify("error", "Please select connectivity type.");
            return false;
        }
        if (data.provider === "") {
            this.notify("error", "Please select internet service provider.");
            return false;
        }
        if (data.start_time === "") {
            this.notify("error", "Please select business start time.");
            return false;
        }
        if (data.end_time === "") {
            this.notify("error", "Please select business end time.");
            return false;
        }
        if (data.has_week_off === "") {
            this.notify("error", "Please select if you has week off.");
            return false;
        }
        if (data.has_week_off === "Y" && data.off_day === "") {
            this.notify("error", "Please select off day.");
            return false;
        }
        if (data.owners_name === "") {
            this.notify("error", "Please enter owner's name.");
            return false;
        }
        if (data.owners_gender === "") {
            this.notify("error", "Please select owner's gender.");
            return false;
        }
        if (data.owners_dob === "") {
            this.notify("error", "Please enter owner's dob.");
            return false;
        }
        if (data.owners_father_name === "") {
            this.notify("error", "Please select owner's father name.");
            return false;
        }

        if (data.owners_mobile_number === "") {
            this.notify("error", "Please enter owner's mobile number.");
            return false;
        }

        if (data.owners_pan_card === "") {
            this.notify("error", "Please enter owner's pan card.");
            return false;
        }

        if (data.owners_id_type === "") {
            this.notify("error", "Please select owner's id type.");
            return false;
        }

        if (data.owners_country === "") {
            this.notify("error", "Please select owner's country.");
            return false;
        }

        if (data.owners_state === "") {
            this.notify("error", "Please select owner's state.");
            return false;
        }

        if (data.owners_district === "") {
            this.notify("error", "Please select owner's district.");
            return false;
        }

        if (data.owners_city === "") {
            this.notify("error", "Please enter owner's city.");
            return false;
        }
        if (data.owners_road_name === "") {
            this.notify("error", "Please enter owner's road name.");
            return false;
        }
        if (data.owners_pin_code === "") {
            this.notify("error", "Please enter owner's pin code.");
            return false;
        }
        if (data.owners_village_code === "") {
            this.notify("error", "Please enter owner's village code.");
            return false;
        }
        if (data.owners_address_prrof_type === "") {
            this.notify("error", "Please select owner's address prrof type.");
            return false;
        }
        if (data.owners_qualification === "") {
            this.notify("error", "Please select owner's qualification.");
            return false;
        }
        if (data.owners_additional_course === "") {
            this.notify("error", "Please select owner's additional course.");
            return false;
        }
        if (data.owners_institute_name === "") {
            this.notify("error", "Please enter owner's institute name.");
            return false;
        }
        if (data.owners_date_of_passed === "") {
            this.notify("error", "Please enter owner's passing date.");
            return false;
        }
        this.setState({
            loading: true
        })
        // console.log(data)
        Axios.post(apiUrl + "/remittance/ime-forex/csp-registration", data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            // console.log(res)
            this.setState({
                loading: false
            })
            if (!res.data.success) {
                this.notify("error", res.data.message);
            }
            else {
                this.getDocumentsList(this.state.selected_user.id);
                this.setState({
                    uploadDocumentForm: true,
                })
            }
        }, err => {
            // console.log(err)
        })
    }
    loadingState = x => {
        this.setState({
            loading: x
        })
    }
    submitAction = (res) => {
        if (!res.data.success) {
            this.notify("error", res.data.message);
        }
        else {
            this.notify("success", res.data.message);
        }
    }
    getDocumentsList = (id) => {
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/ime-forex/csp/documents/list/user", { user_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(Res => {
            // console.log("document response", Res)
            this.setState({
                documents: Res.data.data,
                loading: false
            })
        }, err => {
            this.setState({ loading: false })
        })
    }
    onFileChange = (e, id) => {
        this.setState({ loading: true });
        var formData = new FormData();
        formData.append('image', e.target.files[0]);
        formData.append('id', id);
        formData.append('user_id', this.state.selected_user.id);
        Axios.post(apiUrl + "/remittance/ime-forex/csp/single/document/upload", formData, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                documents: res.data.data,
                loading: false,
                uploadOther: null
            });
        }, err => {
            this.setState({ loading: false });
        })
    }
    uplaodOtherPh = (e, id) => {
        e.preventDefault();
        this.setState({
            uploadOther: id
        })
    }
    cancelUploadOtherPh = (e, id) => {
        e.preventDefault();
        this.setState({
            uploadOther: null
        })
    }
    submitUpload = e => {
        e.preventDefault();
        this.state.documents.forEach((doc, ki) => {
            if (doc.is_required === "Yes" && !doc.file_path) {
                this.notify("error", `Please upload ${doc.document.value}`);
                return false;
            }
        })
        this.setState({ loading: true })
        Axios.post(apiUrl + "/remittance/ime-forex/csp/documents/upload", { user_id: this.state.selected_user.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({ loading: false });
            if (res.data.success) {
                this.setState({
                    showCspForm: false,
                    uploadDocumentForm: false
                })
                Axios.post(`${apiUrl}/admin/make/dealer`, { user_id: this.state.selected_user.id }, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("_access")
                    }
                }).then(res => {
                    // console.log("make dealer response", res)
                    this.getAllUsers(this.state.paginatedata.current_page);
                    if (res.data.success) {
                        this.notify('success', res.data.message);
                    }
                    else {
                        this.notify('error', res.data.message);
                    }
                }, err => {
                    // console.log("make dealer error", err)
                    this.notify('error', "Something went wrong.");
                })
            } else {
                this.notify("error", res.data.message)
            }
            // console.log("upload documens fil", res)
        })
    }
    editDetailsOtp = (e, det) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/send/otp`, { user_id: det.id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.setState({
                editModal: true,
                otp_verified: false,
                selected_user: det,
                otp_token: res.data.otp_token
            })
        })
    }
    editDetails = (e) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/verify/otp`, { otp: this.state.otp, otp_token: this.state.otp_token }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                var det = this.state.selected_user;
                e.preventDefault();
                this.notify("success", res.data.message)
                this.setState({
                    edit_name: det.name,
                    edit_email: det.email,
                    edit_mobile: det.mobile,
                    edit_district: det.district,
                    edit_city: det.city,
                    edit_state: det.state,
                    edit_country: det.country,
                    edit_pincode: det.pincode,
                    edit_address: det.address,
                    user_id: det.id,
                    editModal: true,
                    otp_verified: true
                })
            }
            else {
                this.notify("error", res.data.message)
            }
        })
    }
    editaDetailsSubmit = e => {
        if (!this.state.edit_name || this.state.edit_name === "") {
            this.notify("error", "Please enter name");
            return false;
        }
        if (!this.state.edit_email || this.state.edit_email === "") {
            this.notify("error", "Please enter email");
            return false;
        }
        if (!this.state.edit_mobile || this.state.edit_mobile === "") {
            this.notify("error", "Please enter mobile number");
            return false;
        }
        var lengthcheck = this.state.edit_mobile.length;
        //  console.log("lenght",lengthcheck);
        if (lengthcheck !== 10) {
            this.notify("error", "Please enter correct mobile number.");
            return false;
        }
        if (!this.state.edit_address || this.state.edit_address === "") {
            this.notify("error", "Please enter address");
            return false;
        }
        if (!this.state.edit_country || this.state.edit_country === "") {
            this.notify("error", "Please enter country");
            return false;
        }
        if (!this.state.edit_state || this.state.edit_state === "") {
            this.notify("error", "Please enter state");
            return false;
        }
        if (!this.state.edit_city || this.state.edit_city === "") {
            this.notify("error", "Please enter city");
            return false;
        }
        if (!this.state.edit_district || this.state.edit_district === "") {
            this.notify("error", "Please enter district");
            return false;
        }
        if (!this.state.edit_pincode || this.state.edit_pincode === "") {
            this.notify("error", "Please enter pincode");
            return false;
        }
        var data = {
            name: this.state.edit_name,
            email: this.state.edit_email,
            mobile: this.state.edit_mobile,
            address: this.state.edit_address,
            country: this.state.edit_country,
            state: this.state.edit_state,
            city: this.state.edit_city,
            district: this.state.edit_district,
            pincode: this.state.edit_pincode,
            user_id: this.state.user_id
        }
        Axios.post(`${apiUrl}/admin/edit/user/details`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.getAllUsers(this.state.paginatedata.current_page);
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    edit_email: "",
                    edit_name: "",
                    edit_mobile: "",
                    edit_address: "",
                    edit_country: "",
                    edit_state: "",
                    edit_city: "",
                    edit_district: "",
                    edit_pincode: "",
                    editModal: false,
                    user_id: null,
                    selected_user: null
                })
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");
        })
    }
    toggleEditModal = () => {
        this.setState({
            editModal: !this.state.editModal,
            edit_email: "",
            edit_name: "",
            edit_mobile: "",
            edit_address: "",
            edit_country: "",
            edit_state: "",
            edit_city: "",
            edit_district: "",
            edit_pincode: "",
            user_id: null,
            selected_user: null,
            otp: ""
        })
    }
    allowMaintanance = (e, user) => {
        var data = {
            user_id: user.id
        }
        if (e.target.checked) {
            if (window.confirm("You are allowing this user to use app in maintanace mode.")) {
                data.panel_access = "full_in_maintanance";
            } else {
                e.target.checked = false;
            }
        }
        else {
            data.panel_access = "full";
        }
        Axios.post(`${apiUrl}/admin/change/maintanance/access`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");
        })
    }
    sendCashback = (e, user = null) => {
        e.preventDefault();
        // console.log(user);
        this.setState({
            viewRewardModal: !this.state.viewRewardModal,
            selected_user: user
        })
    }
    submitReward = (e) => {
        e.preventDefault();
        if (this.state.rewardamount === 0) {
            this.notify("error", "Please enter amount.");
            return false;

        }
        if (!this.state.reward_comment || this.state.reward_comment === "") {
            this.notify("error", "Please enter comment.");
            return false;

        }
        var data = {
            user_id: this.state.selected_user.id,
            amount: this.state.rewardamount,
            reward_comment: this.state.reward_comment
        }
        Axios.post(`${apiUrl}/admin/send/cashback`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.setState({
                    selected_user: null,
                    viewRewardModal: false,
                    rewardamount: 0
                });
                this.getAllUsers();
            } else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "something went wrong.");

        })
    }
    render() {
        var uploadImageFunctions = {
            onFileChange: this.onFileChange,
            cancelUploadOtherPh: this.cancelUploadOtherPh,
            uplaodOtherPh: this.uplaodOtherPh,
            submitUpload: this.submitUpload
        }
        var imeCspRegisterFormFunctions = {
            submitCSP: this.submitCSP,
            handleInput: this.handleInput,
            handleInputDistrict: this.handleInputDistrict,
            handleInputState: this.handleInputState,
            handleCountryChange: this.handleCountryChange
        };
        return (
            <>
                {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
                <div className="content">

                    <div className="react-notification-alert-container notification_alert_con">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                {this.state.showCspForm && <CardHeader>
                                    <h3 className="title">CSP registration of {this.state.name}
                                        <small className="float-right"><a href="" onClick={(e) => { this.cancelCSPReg(e) }} >Cancel</a></small>
                                    </h3>

                                </CardHeader>}
                                {!this.state.showCspForm && <CardHeader>
                                    <Row>
                                        <div className="col-md-12">
                                            <h3 className="title userlistsec">Agent Request</h3>
                                            {this.state.excel && <>
                                                <small className="float-right excel-sec">
                                                    {this.state.excelusers.length > 0 && <CSVLink data={this.state.excelusers} style={{ color: "#009b62", background: "none", padding: "10px 8px", borderRadius: "0px" }} title="Export to CSV" filename={"User-List-" + (new Date()).toLocaleString() + ".csv"}><i className="fa fa-file-excel-o fa-2x"></i>Excel</CSVLink>}
                                                </small>
                                            </>
                                            }
                                        </div>
                                       
                                     
                                  
                                       
                                    </Row>
                                </CardHeader>}
                                <CardBody>
                                    {!this.state.showCspForm && <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>Joining Date</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Email</th>
                                                {/* <th>Wallet Balance</th> */}
                                                <th>Wallet Balance</th>
                                                <th>Referal Code</th>
                                                {/* <th>Status</th> */}
                                                <th>Skip Maintanance</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.users.map((val, key) => {
                                                var cclass = val.status === "active" ? "bg-white" : "bg-danger text-white";
                                                cclass = val.status === "temp_login" ? "bg-info text-white" : cclass;
                                                cclass = val.status === "pending" ? "bg-light text-white" : cclass;
                                                var sr = (this.state.paginatedata.per_page * (this.state.paginatedata.current_page - 1)) + key + 1;
                                                var bal = val.remit_balance && val.remit_balance > 0 ? val.remit_balance : val.wallet_balance;
                                                return <tr key={key} className={cclass}>
                                                    <td>{sr}</td>
                                                    <td>{moment(val.created_at).format('lll')}</td>
                                                    <td>{val.name}&nbsp;<a href="" onClick={e => { e.preventDefault() }} title={val.status.toUpperCase()}><i className="fa fa-info-circle"></i></a></td>
                                                    <td>{val.mobile}</td>
                                                    <td>{val.email}</td>
                                                    {/* <td>{"₹" + val.wallet_balance.toFixed(2)}</td> */}
                                                    <td>{val.remit_balance ? "₹" + val.remit_balance.toFixed(2) : "₹0.00"}</td>
                                                    <td>{val.referal_code}</td>
                                                    {/* <td>{val.status.toUpperCase()}</td> */}
                                                    <td>
                                                        <input type="checkbox" defaultChecked={val.panel_access === "full_in_maintanance"} onChange={(e) => { this.allowMaintanance(e, val) }} />
                                                    </td>
                                                    <td className="img-delar">
                                                        <a href="" title="View Details" onClick={(e) => { this.viwDetails(e, val) }}><img src={require('assets/images/other-icons/view.png')} alt="View Details" style={{ width: "25px" }} /></a>&nbsp;
                                                        {val.status === "active" && <><a href="" title="Make Agent" onClick={(e) => { this.makeDealer(e, val) }}><img src={require('assets/images/other-icons/dealer.png')} alt="" style={{ width: "25px" }} /></a>&nbsp;</>}
                                                        {this.state.balance && <>  {bal > 0 && <><a href="" className="balanceammout" title="Deduct Balance" onClick={(e) => { this.deductBalance(e, val) }}><i className="fa fa-money" style={{ fontSize: "20px" }}></i></a> &nbsp;</>} </>}

                                                        {this.state.show_edit && <a href="" className="detailsEdit" title="Edit Details" onClick={(e) => { this.editDetailsOtp(e, val) }}><i className="fa fa-pencil-square-o" style={{ fontSize: "20px", verticalAlign: "middle" }} ></i></a>}

                                                        {this.state.caseback && <a href="" className="detailsEdit" title="Send Cashback" onClick={(e) => { this.sendCashback(e, val) }}><i className="fa fa-gift" style={{ fontSize: "20px", verticalAlign: "middle" }} ></i></a>}

                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>}
                                    {this.state.showCspForm && <>
                                        {this.state.uploadDocumentForm && <>
                                            <ImeUploadDocs {...uploadImageFunctions} documents={this.state.documents} uploadOther={this.state.uploadOther} imageUrl={imageUrl} />
                                        </>}
                                        {!this.state.uploadDocumentForm && <>
                                            <div className="col-md-12 listboxsec">
                                                <FormGroup>
                                                    <label>Upgrade Type</label>
                                                    <select className="form-control" name="upgrade_type" onChange={(e) => { this.handleInput(e) }} value={this.state.upgrade_type}>
                                                        <option value="">Select</option>
                                                        <option value="dealer" >Agent</option>
                                                        <option value="distributor" >Distributor</option>
                                                    </select>
                                                </FormGroup>

                                                <FormGroup>
                                                    <label>Remittance Type</label>
                                                    <select className="form-control" name="remittance_type" onChange={(e) => { this.handleInput(e) }} value={this.state.remittance_type}>
                                                        <option value="">Select</option>
                                                        <option value="IME" >IME</option>
                                                        <option value="Prabhu" >Prabhu</option>
                                                    </select>
                                                </FormGroup>

                                                {this.state.remittance_type === "IME" && <> <ImeCsRegister {...imeCspRegisterFormFunctions} {...this.state} />  <a href="" className="btn btn-primary" onClick={(e) => { this.submitCSP(e) }} >Save</a></>}
                                                {this.state.remittance_type === "Prabhu" && <Prabhucsp submitAction={this.submitAction} user_id={this.state.selected_user.id} loadingState={this.loadingState} upgrade_type={this.state.upgrade_type} />}
                                            </div>

                                        </>}
                                    </>}
                                    {!this.state.showCspForm && <Pagination changePage={this.changePage} data={this.state.paginatedata} />}
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                        <Modal
                            modalClassName="modal"
                            isOpen={this.state.viewModal}
                            toggle={this.toggleViewModal}
                            size="lg"
                        >
                            <div className="modal-header">
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleViewModal} style={{ zIndex: "999" }} >
                                    <i className="tim-icons icon-simple-remove" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="view-popup">
                                    <h3>View User Detail</h3>
                                    <div className="userddetails">
                                        <Row>
                                            <Col md="7">
                                                <div className="userimg">
                                                    <img alt="..." onError={this.handleError} src={imageUrl + this.state.profile_pic} />

                                                </div>
                                                <div className="user-details">
                                                    <strong className="username">{this.state.name}</strong>
                                                    <p>Referal Code: <span>{this.state.referal_code ? this.state.referal_code : "N/A"}</span></p>

                                                </div>
                                            </Col>
                                            <Col md="5">
                                                <div className="activestatus">
                                                    <p><strong className="username">Status</strong> <span>{this.state.status.toUpperCase()}</span></p>
                                                    <div className="btn-account">
                                                        {this.state.status === "active" && <button type="button" className="btn btn-danger" onClick={(e) => { this.toggleBlockModal(e) }}>Block Account</button>}
                                                        {this.state.status === "pending" && <button type="button" className="btn btn-danger" onClick={(e) => { this.toggleTempLoginModal(e) }}>Reject</button>}
                                                        {this.state.status !== "active" && <button type="button" className="btn btn-danger" onClick={(e) => { this.activeUser(e) }}>{this.state.status === "pending" ? "Approve" : "Active"} User</button>}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <Row className="startedformsec">
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Name</label>
                                                <h4>{this.state.name}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Email</label>
                                                <h4>{this.state.email ? this.state.email : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Phone Number</label>
                                                <h4>{this.state.mobile ? this.state.mobile : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Address</label>
                                                <h4>{this.state.address ? this.state.address : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>City</label>
                                                <h4>{this.state.city ? this.state.city.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>District</label>
                                                <h4>{this.state.district ? this.state.district.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>State</label>
                                                <h4>{this.state.state ? this.state.state.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Country</label>
                                                <h4>{this.state.country ? this.state.country.toUpperCase() : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>PIN Code</label>
                                                <h4>{this.state.pincode ? this.state.pincode : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>
                                        {this.state.csp_code && <Col md="6">
                                            <FormGroup>
                                                <label>CSP COde</label>
                                                <h4>{this.state.csp_code}</h4>
                                            </FormGroup>
                                        </Col>}
                                    </Row>
                                    <Row>
                                        {this.state.status === "blocked" && <Col md="6">
                                            <FormGroup>
                                                <label>Block Reason</label>
                                                <h4>{this.state.block_reason ? this.state.block_reason : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>}
                                        {this.state.status === "temp_login" && <Col md="6">
                                            <FormGroup>
                                                <label>Notice</label>
                                                <h4>{this.state.notice ? this.state.notice : "N/A"}</h4>
                                            </FormGroup>
                                        </Col>}
                                    </Row>
                                    <Row>
                                        {this.state.license_back && <Col md="6">
                                            <div className="imageidentity">
                                                <label style={{ textAlign: "center" }}>Identity Back ({this.state.proof_type.replace("_", " ")})</label>
                                                <img src={imageUrl + this.state.license_back} alt=" " onClick={e => { this.toggleImageModal(e, imageUrl + this.state.license_back) }} />
                                            </div>
                                        </Col>}
                                        {this.state.license_front && <Col md="6">
                                            <div className="imageidentity">
                                                <label style={{ textAlign: "center" }}>Identity Front({this.state.proof_type.replace("_", " ")})</label>
                                                <img src={imageUrl + this.state.license_front} alt=" " onClick={e => { this.toggleImageModal(e, imageUrl + this.state.license_front) }} />
                                            </div>
                                        </Col>}

                                    </Row>
                                </div>
                            </div>
                        </Modal>


                        <Modal isOpen={this.state.imageModal} toggle={this.toggleImageModal} size="lg" >
                            <ModalHeader toggle={this.toggleImageModal}>Identity</ModalHeader>
                            <ModalBody>
                                <img src={this.state.imagesrcView} alt="" />
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.blockModal} toggle={this.toggleBlockModal}>
                            <ModalHeader toggle={this.toggleBlockModal}>Block Reason</ModalHeader>
                            <ModalBody>
                                <textarea name="input_block_reason" onChange={(e) => { this.handleInput(e) }} value={this.state.input_block_reason} className="form-control"></textarea>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-danger" onClick={this.blockUser}>
                                    Block <b>{this.state.name} ({this.state.mobile})</b>
                                </button>
                            </ModalFooter>
                        </Modal>


                        <Modal isOpen={this.state.tempLoginModal} toggle={this.toggleTempLoginModal}>
                            <ModalHeader toggle={this.toggleTempLoginModal}>Notice</ModalHeader>
                            <ModalBody>
                                <textarea name="input_notice" placeholder="Notice" onChange={(e) => { this.handleInput(e) }} value={this.state.input_notice} className="form-control"></textarea>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-danger" onClick={this.approveUser}>
                                    Submit
                                </button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={this.state.deductModal} toggle={this.toggleDeductModal}>
                            <ModalHeader toggle={this.toggleDeductModal}>Deduct Amount </ModalHeader>
                            <ModalBody>

                                <div className="form-group">
                                    {this.state.selected_user && <label>Name: {this.state.selected_user.name} ({this.state.selected_user.mobile})</label>}
                                </div>
                                <div className="form-group">
                                    {this.state.selected_user && <label>Wallet Balance: {this.state.selected_user.remit_balance}</label>}
                                </div>
                                {/* <div className="form-group">
                                    <label>Wallet Type</label>
                                    <select className="form-control" onChange={(e) => { this.handleInput(e) }} name="deduct_from" value={this.state.deduct_from}>
                                        <option value="wallet_balance">Deduct from Wallet Balance</option>
                                        <option value="remit_balance">Deduct from Remittance Balance</option>
                                    </select>
                                </div> */}
                                <div className="form-group">
                                    <label>Amount</label>
                                    <input type="number" min="0" className="form-control" onChange={(e) => { this.handleInput(e) }} name="deduct_amount" value={this.state.deduct_amount} />
                                </div>
                                <div className="form-group">
                                    <label>Reason</label>
                                    <textarea name="deduct_reason" onChange={(e) => { this.handleInput(e) }} value={this.state.deduct_reason} className="form-control"></textarea>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-danger" onClick={this.deductBalanceSubint}>
                                    Submit
                                </button>
                            </ModalFooter>
                        </Modal>
                        <Modal modalClassName="modal" isOpen={this.state.editModal} toggle={this.toggleEditModal} size="lg" >
                            <div className="modal-header">
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleEditModal} style={{ zIndex: "999" }} >
                                    <i className="tim-icons icon-simple-remove" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="view-popup">
                                    <h3>Edit User Detail &nbsp;&nbsp;{!this.state.otp_verified && <small>Please verify the otp to continue.</small>}</h3>
                                    <div className="edituserddetails">
                                        {!this.state.otp_verified && <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>OTP</lebel>
                                                    <input className="form-control" type="number" name="otp" value={this.state.otp} onChange={(e) => { this.handleInput(e) }} max="999999" min="100000" />
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.otp_verified && <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Name</lebel>
                                                    <input className="form-control" type="text" name="edit_name" value={this.state.edit_name} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Email</lebel>
                                                    <input className="form-control" type="text" name="edit_email" value={this.state.edit_email} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Mobile Number</lebel>
                                                    <input className="form-control" type="text" name="edit_mobile" value={this.state.edit_mobile} onChange={(e) => { this.handleInput(e) }} disabled={true} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Address</lebel>
                                                    <input className="form-control" type="text" name="edit_address" value={this.state.edit_address} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Country</lebel>
                                                    <input className="form-control" type="text" name="edit_country" value={this.state.edit_country} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>State</lebel>
                                                    <input className="form-control" type="text" name="edit_state" value={this.state.edit_state} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>City</lebel>
                                                    <input className="form-control" type="text" name="edit_city" value={this.state.edit_city} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>District</lebel>
                                                    <input className="form-control" type="text" name="edit_district" value={this.state.edit_district} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <lebel>Pincode</lebel>
                                                    <input className="form-control" type="text" name="edit_pincode" value={this.state.edit_pincode} onChange={(e) => { this.handleInput(e) }} />
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                    <ModalFooter>
                                        {this.state.otp_verified && <button type="button" className="btn btn-danger" onClick={this.editaDetailsSubmit}>
                                            Submit
                                        </button>}
                                        {!this.state.otp_verified && <button type="button" className="btn btn-danger" onClick={e => this.editDetails(e)}>
                                            Verify OTP
                                        </button>}
                                    </ModalFooter>
                                </div>
                            </div>
                        </Modal>
                    </Row>
                </div>

                <Modal
                    modalClassName="modal"
                    isOpen={this.state.viewRewardModal}
                    toggle={this.sendCashback}
                >
                    <div className="modal-header">
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.sendCashback} style={{ zIndex: "999" }} >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="view-popup">
                            {this.state.selected_user && <h3>Send Reward to {this.state.selected_user.name} ({this.state.selected_user.mobile})</h3>}
                            <div className="userddetails">
                                <Row>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <lebel>Amount <span className="text-danger">*</span></lebel>
                                            <input className="form-control" type="number" min="0" name="rewardamount" value={this.state.rewardamount} onChange={(e) => { this.handleInput(e) }} />
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <lebel>Comments <span className="text-danger">*</span></lebel>
                                            <textarea name="reward_comment" value={this.state.reward_comment} onChange={(e) => { this.handleInput(e) }}></textarea>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger mb-4 float-right" onClick={this.submitReward}>
                            Submit
                        </button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default AgentRequest;
