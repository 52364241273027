import React from "react";
import Usersidebar from "../Usersidebar";
import Userfooter from "../Footer";
import Userheader from "../Header";
import Pagination from "../Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import { Modal } from "reactstrap";
import ReceiptModel from "../ReceiptModel";
import swal from 'sweetalert';
import ServiceModal from "../child-components/ServiceModal";
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Dmt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            customer: null,
            loading: false,
            show_form: "",
            mobile: "",
            beneficiaries: [],
            latitude: "",
            longitude: "",
            amount: "",
            selected_beneficiary: null,
            name: "",
            dob: "",
            state: "",
            district: "",
            city: "",
            pincode: "",
            area: "",
            all_states: [],
            all_districts: [],
            otp_ref_id: "",
            otp: "",
            banks: [],
            bank_id: "",
            recipient_mobile: "",
            recipient_name: "",
            account_number: "",
            ifsc: "",
            state_key: "",
            headers: {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access")),
                    Accept: 'application/json'
                }
            },
            service_modal: false
        }
    }

    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");
        }
        this.checkAvailability();
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
    }
    checkAvailability = () => {
        // Axios.post(apiUrl + "/user/check/service/allowed", { service_id: 2 }, this.state.headers).then(res => {
        //     if (!res.data.success) {
        //         this.setState({ service_modal: true })
        //     }
        // })
    }
    toggleServiceModal = e => {
        this.props.history.push('/user/dashboard');
    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
    showForm = (e = null, type) => {
        if (e) e.preventDefault();
        this.setState({
            show_form: type
        })
    }
    handleInputMobile = e => {
        if (e.target.value.length <= 10) {
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            return false;
        }
    }
    searchCustomer = e => {
        e.preventDefault();
        if (this.state.mobile === "" || this.state.mobile.length < 10) {
            swal("Error", "Please enter mobile number.", "error");
            return false;
        }
        this.setState({ loading: true });
        var data = {
            mobile: this.state.mobile
        }
        Axios.post(apiUrl + "/dmt/check/customer", data, this.state.headers).then(res => {
            this.setState({ loading: false });
            if (res.data.success) {
                this.getBeneficiaries();
                this.setState({ show_form: "beneficiaries", customer: res.data.customer });
            }
            else {
                if (res.data.create_new) {
                    swal({
                        title: "",
                        text: "Customer does not exists with mobile number. Do you want to create new customer?",
                        icon: "info",
                        buttons: true,
                    }).then(ok => {
                        if (ok) {
                            this.getStates();
                            this.setState({ show_form: "create_customer" });
                        }
                    })
                }
            }
        }, err => {
            this.setState({ loading: false });
        })
    }
    getBeneficiaries = (e) => {
        if (e) e.preventDefault();
        this.setState({ loading: true });
        var data = {
            mobile: this.state.mobile
        }
        Axios.post(apiUrl + "/dmt/recipients/list", data, this.state.headers).then(res => {
            this.setState({ beneficiaries: res.data.list, loading: false })
        }, err => {
            this.setState({ beneficiaries: [], loading: false })
        })

    }
    cancelTransaction = e => {
        e.preventDefault();
        swal({
            title: "Cancel Transaction",
            text: "Do you want to cancel this transaction?",
            buttons: ["No", "Yes"]
        }).then(ok => {
            console.log(ok)
            if (ok) {
                this.setState({
                    customer: null,
                    show_form: "",
                    mobile: "",
                    beneficiaries: [],
                    latitude: "",
                    longitude: "",
                    amount: "",
                    selected_beneficiary: null
                });
            }
        })
    }
    selectBeneficiary = (e, bene) => {
        e.preventDefault();
        const location = window.navigator && window.navigator.geolocation
        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    selected_beneficiary: bene,
                    show_form: "send_transaction"
                })
            }, (error) => {
                swal({
                    title: "Location access blocked.",
                    text: "Please allow location access to contiunue.",
                    icon: "error"
                });
            })
        }
    }
    handleInputAmount = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    sendTransaction = e => {
        e.preventDefault();
        var customer = this.state.customer;
        if (!this.state.amount || this.state.amount === "") {
            swal("", "Please enter amount to continue.", "error");
            return false;
        }
        if (customer.available_limit < this.state.amount) {
            swal("", "Customer can not send more than &#8377;" + customer.available_limit + ".", "error");
            return false;
        }
        var data = {
            mobile: this.state.mobile,
            recipient_id: this.state.selected_beneficiary.recipient_id,
            amount: this.state.amount,
            channel: 1,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
        };
        this.setState({ loading: true });
        Axios.post(apiUrl + "/dmt/transaction/initiate", data, this.state.headers).then(res => {
            if (res.data.success) {
                swal("", "Amount transferred successfully.", "success");
                this.setState({ receipt: res.data.details, loading: false, show_form: "receipt" })
            }
            else {
                swal("", res.data.message, "error");
                this.setState({ loading: false });
            }
        }, err => {
            this.setState({ loading: false })
            swal("", "Something went wrong. Please try again later.", "error");

        })
    }
    completTransaction = e => {
        if (e) e.preventDefault();
        this.setState({
            customer: null,
            show_form: "",
            mobile: "",
            beneficiaries: [],
            latitude: "",
            longitude: "",
            amount: "",
            selected_beneficiary: null
        });
    }
    getStates = () => {
        Axios.post(apiUrl + "/get/state", { country_id: "IND" }, this.state.headers).then(res => {
            this.setState({ all_states: res.data.data });
        })
    }
    getDistricts = (state_id) => {
        Axios.post(apiUrl + "/get/district", { state_id: state_id }, this.state.headers).then(res => {
            this.setState({ all_districts: res.data.data });
        })
    }
    handleInputState = (e) => {
        var key = e.target.value;
        var sta = this.state.all_states[key];
        this.setState({ state: key !== "" ? sta.state : "", state_key: key, })
        if (key !== "") {
            this.getDistricts(sta.state_id);
        }
    }
    createCustomer = e => {
        e.preventDefault();
        var data = {
            name: this.state.name,
            dob: this.state.dob,
            city: this.state.city,
            state: this.state.state,
            pincode: this.state.pincode,
            district: this.state.district,
            area: this.state.area,
            mobile: this.state.mobile,
            country: "India",
        };
        if (data.name === "") {
            swal("", "Please enter name.", "error");
            return false;
        }
        if (data.dob === "") {
            swal("", "Please enter date of birth.", "error");
            return false;
        }
        if (data.state === "") {
            swal("", "Please select state.", "error");
            return false;
        }
        if (data.district === "") {
            swal("", "Please select district.", "error");
            return false;
        }
        if (data.pincode === "") {
            swal("", "Please enter pincode.", "error");
            return false;
        }
        if (data.area === "") {
            swal("", "Please enter area.", "error");
            return false;
        }
        Axios.post(apiUrl + "/dmt/create/customer", data, this.state.headers).then(res => {
            if (res.data.success) {
                swal("", res.data.message, "success").then(() => {
                    this.setState({
                        otp_ref_id: res.data.otp_ref_id,
                        show_form: "verify_customer_otp"
                    });
                });
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            swal("", "Something went wrong.", "error");
        })
    }

    verifyOtp = (e) => {
        e.preventDefault();
        var data = {
            otp: this.state.otp,
            otp_ref_id: this.state.otp_ref_id,
            mobile: this.state.mobile
        }
        if (data.otp === "" || data.otp.length < 4) {
            swal("", "Please enter a valid OTP.", "error");
            return false;
        }
        Axios.post(apiUrl + "/dmt/verify/customer", data, this.state.headers).then(res => {
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.setState({
                    otp_ref_id: null,
                    customer: null,
                    show_form: "",
                    mobile: "",
                    name: "",
                    dob: "",
                    state: "",
                    district: "",
                    city: "",
                    pincode: "",
                    area: "",
                    all_states: [],
                    all_districts: [],
                })
            }
            else {
                swal("", res.data.message, "error");
            }
        })
    }
    cancelCreate = e => {
        if (e) e.preventDefault();
        swal({
            title: "Cancel Create customer!",
            text: "Do you want to clear the form and go to the search page?",
            buttons: ["No", "Yes"]
        }).then(ok => {
            if (ok) {
                this.setState({
                    customer: null,
                    show_form: "",
                    mobile: "",
                    name: "",
                    dob: "",
                    state: "",
                    district: "",
                    city: "",
                    pincode: "",
                    area: "",
                    all_states: [],
                    all_districts: [],
                });
            }
        })
    }
    getBanks = () => {
        Axios.get(apiUrl + "/dmt/get/banks", this.state.headers).then(res => {
            this.setState({
                banks: res.data.banks
            })
        })
    }
    addNewBene = e => {
        e.preventDefault();
        this.getBanks();
        this.setState({
            show_form: "create_beneficiary"
        })
    }
    saveBeneficairy = e => {
        e.preventDefault();
        var data = {
            mobile: this.state.mobile,
            recipient_mobile: this.state.recipient_mobile,
            bank_id: this.state.bank_id,
            recipient_name: this.state.recipient_name,
            account_number: this.state.account_number,
            ifsc: this.state.ifsc
        };
        if (data.recipient_name === "") {
            swal("", "Please enter recepient name.", "error");
            return false;
        }
        if (data.recipient_mobile === "") {
            swal("", "Please enter recepient mobile number.", "error");
            return false;
        }
        if (data.bank_id === "") {
            swal("", "Please select bank.", "error");
            return false;
        }
        if (data.account_number === "") {
            swal("", "Please enter recepient account number.", "error");
            return false;
        }
        if (data.ifsc === "") {
            swal("", "Please enter ifsc.", "error");
            return false;
        }
        this.setState({ loading: true })
        Axios.post(apiUrl + "/dmt/recipient/add", data, this.state.headers).then(res => {
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.getBeneficiaries();
                this.setState({
                    show_form: "beneficiaries",
                    recipient_mobile: "",
                    bank_id: "",
                    recipient_name: "",
                    account_number: "",
                    ifsc: "",
                    loading: false
                });
            }
            else {
                this.setState({ loading: false })
                swal("", res.data.message, "error");
            }
        })
    }
    render() {
        return <>
            <Userheader user={this.state.user} />
            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="form-sec-dash">
                                <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p>Money Transfer </p>
                                        {this.state.show_form === "" && <span>Search Customer</span>}
                                        {this.state.show_form === "beneficiaries" && <span>Select Beneficiary</span>}
                                        {this.state.show_form === "create_beneficiary" && <span>Add Beneficiary</span>}
                                        {this.state.show_form === "send_transaction" && <span>Send Money</span>}
                                        {this.state.show_form === "show_form" && <span>Receipt</span>}
                                        {this.state.show_form === "create_customer" && <span>Create Customer</span>}

                                        {this.state.show_form !== "" && !["verify_customer_otp", "receipt", "create_customer"].includes(this.state.show_form) && <small className="float-right">
                                            <a href="" className="badge badge-danger" onClick={e => { this.cancelTransaction(e) }}>Cancel Transaction</a>&nbsp;
                                        </small>}

                                        {["create_customer", "verify_customer_otp"].includes(this.state.show_form) && <small className="float-right">
                                            <a href="" className="badge badge-danger" onClick={e => { this.cancelCreate(e) }}>Cancel</a>
                                        </small>}

                                        {["beneficiaries"].includes(this.state.show_form) && <small className="float-right">
                                            <a href="" className="badge badge-danger" onClick={e => { this.addNewBene(e) }}>Add New Beneficiary</a>&nbsp;
                                        </small>}

                                    </h3>
                                </div>
                                {this.state.show_form === "" && <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label>Mobile</label>
                                            <input type="number" className="form-control" name="mobile" placeholder="Enter Sender's Mobile Number" onChange={e => { this.handleInputMobile(e) }} value={this.state.mobile} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="send-bt-dash">
                                            <a href="" onClick={e => { this.searchCustomer(e) }}>Search</a>
                                        </div>
                                    </div>
                                </div>}

                                {this.state.show_form === "create_beneficiary" && <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label>Mobile</label>
                                            <input type="number" className="form-control" name="mobile" placeholder="Enter Mobile Number" readOnly={true} onChange={e => { this.handleInput(e) }} value={this.state.mobile} />
                                        </div>
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label>Beneficairy Name</label>
                                            <input type="text" className="form-control" name="recipient_name" placeholder="Enter Beneficairy Name" onChange={e => { this.handleInput(e) }} value={this.state.mrecipient_nameobile} />
                                        </div>
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label>Beneficiary Mobile</label>
                                            <input type="number" className="form-control" name="recipient_mobile" placeholder="Enter Beneficiary Mobile Number" onChange={e => { this.handleInput(e) }} value={this.state.recipient_mobile} />
                                        </div>
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label>Bank Name</label>
                                            <select className="form-control" name="bank_id" onChange={(e) => { this.handleInput(e) }}>
                                                <option value="">Select</option>
                                                {this.state.banks.map((bank, key) => {
                                                    return <option value={bank.bank_id} ride={key}>{bank.bank_name}</option>
                                                })}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label>Bank Account Number</label>
                                            <input type="number" className="form-control" name="account_number" placeholder="Enter Bank Account Number" onChange={e => { this.handleInput(e) }} value={this.state.account_number} />
                                        </div>
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label>IFSC</label>
                                            <input type="text" className="form-control" name="ifsc" placeholder="Enter IFSC" onChange={e => { this.handleInput(e) }} value={this.state.ifsc} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="send-bt-dash">
                                            <a href="" onClick={e => { this.saveBeneficairy(e) }}>Add</a>
                                        </div>
                                    </div>
                                </div>}

                                {this.state.show_form === "beneficiaries" &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Bank Name</th>
                                                    <th>Account Number</th>
                                                    <th>IFSC</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-space">
                                                {
                                                    this.state.beneficiaries.map((bene, key) => {
                                                        return <tr key={key}>
                                                            <td>{bene.recipient_name}</td>
                                                            <td>{bene.recipient_mobile}</td>
                                                            <td>{bene.bank}</td>
                                                            <td>{bene.account}</td>
                                                            <td>{bene.ifsc}</td>
                                                            <td>
                                                                <a href="" onClick={e => { this.selectBeneficiary(e, bene) }} className="badge badge-primary">
                                                                    Select
                                                                </a>
                                                            </td>
                                                        </tr>;
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>}
                                {this.state.show_form === "send_transaction" && <div className="row">
                                    <div className="col-md-6">
                                        <h4>Sender Name: <b>{this.state.customer.name}</b></h4>
                                        <h4>Sender Mobile: <b>{this.state.customer.mobile}</b></h4>
                                        <h4>Available Limit: <b>{this.state.customer.available_limit}</b></h4>
                                        <h4>Receiver Name: <b>{this.state.selected_beneficiary.recipient_name}</b></h4>
                                        <h4>Receiver Bank: <b>{this.state.selected_beneficiary.bank}</b></h4>
                                        <h4>Receiver Account Number: <b>{this.state.selected_beneficiary.account}</b></h4>
                                        <h4>IFSC: <b>{this.state.selected_beneficiary.ifsc}</b></h4>

                                        <div className="form-group">
                                            <label>Amount</label>
                                            <input type="number" className="form-control" name="amount" placeholder="Enter Amount" onChange={e => { this.handleInputAmount(e) }} value={this.state.amount} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="send-bt-dash">
                                            <a href="" onClick={e => { this.sendTransaction(e) }}>Send</a>
                                        </div>
                                    </div>
                                </div>}
                                {this.state.show_form === "receipt" && <div className="row">
                                    <div className="col-md-6">
                                        <h4>Sender Name: <b>{this.state.customer.name}</b></h4>
                                        <h4>Sender Mobile: <b>{this.state.customer.mobile}</b></h4>
                                        <h4>Receiver Name: <b>{this.state.selected_beneficiary.recipient_name}</b></h4>
                                        <h4>Receiver Bank: <b>{this.state.selected_beneficiary.bank}</b></h4>
                                        <h4>Receiver Account Number: <b>{this.state.selected_beneficiary.account}</b></h4>
                                        <h4>IFSC: <b>{this.state.selected_beneficiary.ifsc}</b></h4>
                                        <h4>Amount Sent: <b>{this.state.receipt.amount}</b></h4>
                                        <h4>Transaction Id: <b>{this.state.receipt.client_ref_id}</b></h4>
                                        <h4>Date: <b>{moment().format("llll")}</b></h4>

                                        <div className="send-bt-dash">
                                            <a href="" onClick={e => { this.completTransaction(e) }}>OK</a>
                                        </div>
                                    </div>
                                </div>}

                                {this.state.show_form === "create_customer" && <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mobile</label>
                                            <input type="number" className="form-control" name="mobile" readOnly={true} value={this.state.mobile} />
                                        </div>
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name="name" placeholder="Enter Customer Name" onChange={e => { this.handleInput(e) }} value={this.state.name} />
                                        </div>
                                        <div className="form-group">
                                            <label>Date Of Birth</label>
                                            <input type="date" className="form-control" max={moment().format("YYYY-MM-DD")} name="dob" onChange={e => { this.handleInput(e) }} value={this.state.dob} />
                                        </div>
                                        <div className="form-group">
                                            <label>State</label>
                                            <select className="form-control" name="state" onChange={e => { this.handleInputState(e) }} value={this.state.state_key}>
                                                <option value="">Select</option>
                                                {this.state.all_states.map((sta, k) => {
                                                    return <option value={k} key={k}>{sta.state}</option>;
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>District</label>
                                            <select className="form-control" name="district" onChange={e => { this.handleInput(e) }} value={this.state.district}>
                                                <option value="">Select</option>
                                                {this.state.all_districts.map((dist, k) => {
                                                    return <option value={dist.district} key={k}>{dist.district}</option>;
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>City</label>
                                            <input type="text" className="form-control" name="city" onChange={e => { this.handleInput(e) }} value={this.state.city} />
                                        </div>
                                        <div className="form-group">
                                            <label>Pincode</label>
                                            <input type="text" className="form-control" name="pincode" onChange={e => { this.handleInput(e) }} value={this.state.pincode} />
                                        </div>
                                        <div className="form-group">
                                            <label>Area</label>
                                            <input type="text" className="form-control" name="area" onChange={e => { this.handleInput(e) }} value={this.state.area} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="send-bt-dash">
                                            <a href="" onClick={e => { this.createCustomer(e) }}>Create Customer</a>
                                        </div>
                                    </div>
                                </div>}
                                {this.state.show_form === "verify_customer_otp" && <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>OTP</label>
                                            <input type="number" className="form-control" name="otp" value={this.state.otp} onChange={e => { this.handleInput(e) }} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="send-bt-dash">
                                            <a href="" onClick={e => { this.verifyOtp(e) }}>Verify</a>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Userfooter />
            <ServiceModal {...this.state} toggleServiceModal={this.toggleServiceModal} apiUrl={apiUrl} />
        </>;
    }
}
export default Dmt;
