import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import "../../assets/css/style.css";
class Ourteam extends React.Component {


    render() {
        return <>
            <div className="site_home">
                <Header banner="our=team" h1Text="Our Team" bannerclassName="inner_head team" />

                <section className="team_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="team_aside">
                                    <div className="team_icon"> <img src={require("assets/images/new/n-sijapati.jpg")} alt="" /> </div>
                                    <div className="team_cnt">
                                        <h2 className="center_head">Naresh Sijapati</h2>
                                        <strong>Partnership & Corporate Communication </strong> </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="team_aside">
                                    <div className="team_icon"> <img src={require("assets/images/new/dilip.jpg")} alt="" /> </div>
                                    <div className="team_cnt">
                                        <h2 className="center_head">Dilip Thapa</h2>
                                        <strong>Marketing & Training</strong> </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="team_aside">
                                    <div className="team_icon"> <img src={require("assets/images/new/mamta.jpg")} alt="" /> </div>
                                    <div className="team_cnt">
                                        <h2 className="center_head">Mamta Rawat</h2>
                                        <strong>Admin & Finance</strong> </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="team_aside">
                                    <div className="team_icon"> <img src={require("assets/images/new/bhavika.jpg")} alt="" /> </div>
                                    <div className="team_cnt">
                                        <h2 className="center_head">Bhavika Bhogekar</h2>
                                        <strong>Support & Communication</strong> </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="team_aside">
                                    <div className="team_icon"> <img src={require("assets/images/new/Sangeeta.jpg")} alt="" /></div>
                                    <div className="team_cnt">
                                        <h2 className="center_head">Sangita Kshatriya</h2>
                                        <strong>Support & Communication</strong> </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="team_aside">
                                    <div className="team_icon"><img src={require("assets/images/new/gaurav.jpg")} alt="" /> </div>
                                    <div className="team_cnt">
                                        <h2 className="center_head">Gaurav Thapa</h2>
                                        <strong>Agent & Distributor Relationship</strong> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>;
    }
}

export default Ourteam;