import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import "../../assets/css/style.css";
class Visionmission extends React.Component {


    render() {
        return <>
            <div className="site_home">
                <Header banner="vision-mission" h1Text="Vision & Mission" bannerclassName="inner_head vismis" />
                <section className="vis_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-xs-12">
                                <div className="vis_aside">
                                    <div className="vis_icon"> <img src={require("assets/images/new/vision.png")} alt="vision" /> </div>
                                    <div className="vis_cnt">
                                        <h2 className="center_head">Our Vision</h2>
                                        <p>Online Saathi is make Everyone Needs Saathi</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-xs-12">
                                <div className="vis_aside">
                                    <div className="vis_icon"> <img src={require("assets/images/new/mission.png")} alt="mission" /> </div>
                                    <div className="vis_cnt">
                                        <h2 className="center_head">Our Mission</h2>
                                        <p>We will create products and services that help our customers achieve their goals </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="core_sec">
                    <div className="container">
                        <h2>Our Core Values</h2>
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="core_list">
                                    <li>We will always be honest, transparent and ethical.</li>
                                    <li>We will respect our customers and fellow associates.</li>
                                    <li>We will be knowledge driven.</li>
                                    <li>We will learn and we will share our learning.</li>
                                    <li>We will never take the easy way out.</li>
                                    <li>We will do everything we can to contribute to the community we work in</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>;
    }
}

export default Visionmission;