import React from "react";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import { Modal } from "reactstrap";
import ReceiptModel from "./ReceiptModel";
import swal from 'sweetalert';
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            add: false,
            form_type: 0,
            reference_number: "",
            complaint_type: "",
            subject: "",
            mod: "",
            mod_of_transaction: "",
            transaction_date: "",
            amount: "",
            description: "",
            images: null,
            details: [],
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }

    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");
        }
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
        this.getData();
    }
    handleImage = (e, name) => {
        this.setState({
            [name]: e.target.files[0]
        })
    }

    clearForms = () => {
        this.setState({
            add: false,
            form_type: 0,
            reference_number: "",
            complaint_type: "",
            subject: "",
            mod: "",
            mod_of_transaction: "",
            transaction_date: "",
            amount: "",
            description: "",
            images: null,
        })
    }
    addreport = () => {
        this.setState({
            add: true,
            form_type: 0,
            reference_number: "",
            complaint_type: "",
            subject: "",
            mod: "",
            mod_of_transaction: "",
            transaction_date: "",
            amount: "",
            description: "",
            images: null,
        })
    }


    getData = (page = 1) => {
        var data = {};
        Axios.post(`${apiUrl}/user/report-issue?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res.data.data.data)
            this.setState({
                details: res.data.data.data,
                pagination_data: res.data.data
            })
        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }

    submit_report = e => {
        e.preventDefault();
        var formdata = new FormData();

        if (this.state.subject === "") {
            swal("Error", "Please select complaint type.", "error");
            return false;
        }
        if (this.state.form_type === 1) {
            if (!this.state.reference_number || this.state.reference_number === "") {
                swal("Error", "Please enter reference/icn number.", "error");
                return false;
            }
            formdata.append("reference_number", this.state.reference_number);
        }
        if (this.state.form_type === 2) {

            if (this.state.mod_of_transaction === "") {
                swal("Error", "Please mod of transaction.", "error");
                return false;
            }
            if (!this.state.transaction_date || this.state.transaction_date === "") {
                swal("Error", "Please enter Date of transaction.", "error");
                return false;
            }

            if (!this.state.reference_number || this.state.reference_number === "") {
                swal("Error", "Please enter UTR/Transaction Ref. Number.", "error");
                return false;
            }
            if (!this.state.amount || this.state.amount === "") {
                swal("Error", "Please enter amount.", "error");
                return false;
            }
            formdata.append("reference_number", this.state.reference_number);
            formdata.append("mod_of_transaction", this.state.mod_of_transaction);
            formdata.append("transaction_date", this.state.transaction_date);
            formdata.append("amount", this.state.amount);
        }
        if (this.state.description === "") {
            swal("Error", "Please enter description.", "error");
            return false;
        }
        this.setState({ loading: true })
        formdata.append("subject", this.state.subject);
        formdata.append("description", this.state.description);
        if (this.state.images) {
            formdata.append("image", this.state.images);
        }
        Axios.post(`${apiUrl}/user/add-report-issue`, formdata, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log("send transaction response", res)
            if (res.data.success) {
                swal("Success", res.data.message, "success");
                this.clearForms();
                this.getData();
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            swal("", err.message, "error");
        })
    }

    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }


    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    // ################################################################

    handleComplaintType = e => {
        var value = e.target.value;
        var form_type = 1;
        if (value === "") {
            form_type = 0;
        }
        if (value === "Wallet Update") {
            form_type = 2;
        }
        this.setState({ form_type: form_type, subject: value })
    }

    handleMod = (e, name) => {
        this.setState({
            [e.target.name]: e.target.value,
            [name]: e.target.value !== "Other" ? e.target.value : ""
        })
    }
    render() {
        return <>
            <Userheader user={this.state.user} />
            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="maine-table-dash-sec">

                                {this.state.add && <>
                                    <div className="heading-title-sec">
                                        <h3 className="admin-heading bg-offwhite">
                                            <p>Add Complaint </p>
                                        </h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="">
                                                <label >Complaint Type</label>
                                                <select name="complaint_type" className="form-control" onChange={e => { this.handleComplaintType(e) }}>
                                                    <option value="">Select</option>
                                                    <option value="Indo Nepal Remittance">Indo Nepal Remittance</option>
                                                    <option value="Wallet Update">Wallet Update</option>
                                                    <option value="Domestic Money Transfer">Domestic Money Transfer</option>
                                                    <option value="Good Governance">Good Governance</option>
                                                    <option value="Indian Recharge and Bill Payment">Indian Recharge and Bill Payment</option>
                                                    <option value="Nepal Recharge and Bill Payment">Nepal Recharge and Bill Payment</option>
                                                    <option value="Saathi Agent">Saathi Agent</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>

                                        {this.state.form_type !== 0 && <>
                                            {this.state.form_type === 1 && <div className="col-md-6">
                                                <div className="">
                                                    <label>Reference/ICN Number</label>
                                                    <input type="text" className="form-control" onChange={e => { this.handleInput(e) }} name="reference_number" value={this.state.reference_number} />
                                                </div>
                                            </div>}
                                            {this.state.form_type === 2 && <>

                                                <div className="col-md-6">
                                                    <div className="">
                                                        <label>Mode of Transaction</label>
                                                        <select name="mod" className="form-control" onChange={e => { this.handleMod(e, 'mod_of_transaction') }}>
                                                            <option value="">Select</option>
                                                            <option value="Bhim UPI">Phone Pay/GooglePay/Bhim UPI</option>
                                                            <option value="Internet Banking">Internet Banking</option>
                                                            <option value="Cash Deposite">Cash Deposite</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {this.state.mod === 'Other' && <div className="col-md-6">
                                                    <div className="">
                                                        <label>Other Payment Mode</label>
                                                        <input type="text" className="form-control" onChange={e => { this.handleInput(e) }} name="mod_of_transaction" value={this.state.mod_of_transaction} />
                                                    </div>
                                                </div>}
                                                <div className="col-md-6">
                                                    <div className="">
                                                        <label>Date of Transaction</label>
                                                        <input type="date" className="form-control" onChange={e => { this.handleInput(e) }} name="transaction_date" value={this.state.transaction_date} />
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="">
                                                        <label>UTR/Transaction Ref. Number</label>
                                                        <input type="text" className="form-control" onChange={e => { this.handleInput(e) }} name="reference_number" value={this.state.reference_number} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="">
                                                        <label>Amount</label>
                                                        <input type="number" className="form-control" onChange={e => { this.handleInput(e) }} name="amount" value={this.state.amount} />
                                                    </div>
                                                </div>
                                            </>}

                                            <div className="col-md-6">
                                                <div className="">
                                                    <label >Image</label>
                                                    <input type="file" className="form-control" onChange={e => { this.handleImage(e, 'images') }} name="images" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="">
                                                    <label >Description</label>
                                                    <textarea className="form-control" onChange={e => { this.handleInput(e) }} name="description" value={this.state.description} ></textarea>
                                                </div>
                                            </div>
                                        </>}



                                        <div className="col-md-12">
                                            <div className="send-bt-dash">
                                                <a href="javascript:void(0)" onClick={e => { this.clearForms(e) }}>Cancel</a>&nbsp;&nbsp;
                                                <a href="javascript:void(0)" onClick={e => { this.submit_report(e) }}>Send</a>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                                {!this.state.add && <>
                                    <div className="heading-title-sec">
                                        <h3 className="admin-heading bg-offwhite">
                                            <p> Complaints </p>
                                            <span>{'Complaints List '}</span>
                                            <a href="javascript:void(0)" className="float-right" onClick={e => { this.addreport(e) }}>+ Add</a>
                                        </h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Complaint Type</th>
                                                    <th>Mode of Transaction</th>
                                                    <th>Reference Number</th>
                                                    <th>Amount</th>
                                                    <th>Transaction Date</th>
                                                    <th>Description</th>
                                                    <th>Image</th>

                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody className="table-space">
                                                {this.state.details.map((hs, key) => {
                                                    return <tr>
                                                        <td>{moment(hs.created_at).calendar()}</td>
                                                        <td>{hs.subject}</td>
                                                        <td>{hs.mod_of_transactio}</td>
                                                        <td>{hs.reference_number}</td>
                                                        <td>{hs.amount}</td>
                                                        <td>{hs.transaction_date}</td>
                                                        <td>{hs.description}</td>
                                                        <td><a href={imageUrl + hs.image} target="_blank" ><img src={imageUrl + hs.image} style={{ "height": "70px" }} /> </a></td>
                                                        <td className="status-dash"><span className="badge badge-dark">{hs.status === 'complete' ? "Completed" : "Pending"}</span></td>

                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination pagination_data={this.state.pagination_data} getData={this.getData} />
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Userfooter />
        </>;
    }
}
export default Reports;

