import React from "react";
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Form,
   Input,
   Row,
   Col,
   Table,
   Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
import Multiselect from 'multiselect-react-dropdown';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
var apiUrl = env.url;
var imageUrl = env.img_url;
class Scheme extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         QuestionModal:false,
         scheme_id:"",
         state_id:"",
         age:"",
         caste:"",
         gender:"",
         bpl:"",
         martial_status:"",
         pregnant:"",
         disability:"",
         education_level:"",
         house_type:"",
         wall_type:"",
         income_source:"",
         own_land:"",
         area_type:"",
         primitive_group:"",
         farmer:"",
         annual_income:"",
         family_income:"",
         sector_type:"",
         aadhar_card:"",
         current_disease:"",
         ration_card:"",
         brahmin_community:"",
         skill_type:"",
         education_percentage:"",
         child_age:"",
         child_type:"",
         worker_type:"",
         farmer_type:"",
         health_card:"",
         halmet_village:"",
         height:"",
         sex_victim:"",
         options: [],
         loading:false,
         scheme_title:"",
         scheme_image:"",
         category_id:"",
         document_id:[],
         eligibility:"",
         detail:"",
         reference:"",
         status:"",
         application_process:"",
         benefit:"",
         AnswerData: [{ answer_option: ""}],
         categories: [],
         position:"",
         topCategory:[],
         description: "",
         scheme:[],
         amount:"",
         status: "",
         id:"",
         document_name: "",
         position: "",
         scheme_image: null,
         show_form: false,
         modal: false,
         selected_category: null
      }
      this.onSelect = this.onSelect.bind(this);
      this.onRemove = this.onRemove.bind(this);
   }
   componentDidMount = () => {
      this.getAllDocument();
      this.getAllCategory()
      this.getAllScheme()
   }

   getAllScheme = () => {
      Axios.get(`${apiUrl}/admin/good/governance/scheme`, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            this.setState({
               scheme: res.data.categories
            })
         }
         else {
            this.setState({
               scheme: []
            })
         }
      })
   }

   getAllCategory = () => {
      Axios.get(`${apiUrl}/admin/good/governance/categories`, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            this.setState({
               topCategory: res.data.categories
            })
         }
         else {
            this.setState({
               topCategory: []
            })
         }
      })
   }


   inputChange = (e) => {
      this.setState({
         [e.target.name]: e.target.value
      })
   }
   onFileChange = (e) => {
      this.setState({ scheme_image: e.target.files[0] })
   }

   saveScheme = e => {
      e.preventDefault();
      if (this.state.scheme_title == "") {
         this.notify("error", "Please enter title.");
         return false;
      }

      if (this.state.category_id == "") {
         this.notify("error", "Please select category.");
         return false;
      }

      if (this.state.document_id == "") {
         this.notify("error", "Please select document.");
         return false;
      }

      if (this.state.scheme_image == "") {
         this.notify("error", "Please choose image.");
         return false;
      }

      if (this.state.detail == "") {
         this.notify("error", "Please enter detail.");
         return false;
      }

      if (this.state.benefit == "") {
         this.notify("error", "Please enter benefit.");
         return false;
      }

      if (this.state.eligibility == "") {
         this.notify("error", "Please enter eligibility.");
         return false;
      }

      if (this.state.application_process == "") {
         this.notify("error", "Please enter application process.");
         return false;
      }
      // if (this.state.question == "") {
      //    this.notify("error", "Please enter eligibility.");
      //    return false;
      // }

      if (this.state.reference == "") {
         this.notify("error", "Please enter reference.");
         return false;
      }


      const formData = new FormData();
      formData.append('scheme_title', this.state.scheme_title);
      formData.append('status', this.state.status);
      formData.append('category_id', this.state.category_id);
      formData.append('document_id', JSON.stringify(this.state.document_id));
      formData.append('scheme_image', this.state.scheme_image);
      formData.append('detail', this.state.detail);
      formData.append('benefit', this.state.benefit);
      formData.append('eligibility', this.state.eligibility);
      formData.append('application_process', this.state.application_process);
      // formData.append('question', this.state.question);
      formData.append('reference', this.state.reference);
      if(this.state.id){
        formData.append('id', this.state.id);
        Axios.post(`${apiUrl}/admin/update/good/governance/scheme`, formData, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            if (res.data.success) {
               this.notify("success", res.data.message);
               this.showForm();
               this.getAllScheme();
            } else {
               this.notify("error", res.data.message);
            }
         })
      }else{
        Axios.post(`${apiUrl}/admin/add/good/governance/scheme`, formData, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            if (res.data.success) {
               this.notify("success", res.data.message);
               this.showForm();
               this.getAllScheme();
            } else {
               this.notify("error", res.data.message);
            }
         })
      }

   }

   getAllDocument = () => {
      Axios.get(`${apiUrl}/admin/all/good/governance/document`, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            var arr=[]
            for(let k of res.data.categories){
               arr.push({"name":k.document_name, "id":k.id})
            }
            setTimeout(() => {
               this.setState({
                  options: arr
               })
            }, 100);

         }
         else {
            this.setState({
               categories: []
            })
         }
      })
   }
   statusChangeCategory = (e, id) => {
      e.preventDefault();
      Axios.post(`${apiUrl}/admin/category/change/status`, { category_id: id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.getAllDocument();
         }
         else {
            this.notify("error", res.data.message);
         }
      }, err => {
         this.notify("error", "Something went wrong");
         console.log("status error", err)
      })
   }

   editForm = (e ,val) => {
    if (e) e.preventDefault();
    var dd=[]
    var doc=[]
    if(val.document){
      for(let k of val.document){
         dd.push({ "name": k.document_name, "id": k.id })
         doc.push(k.id)
      }
    }
    setTimeout(() => {
      var option = [];

      this.setState({
         show_form: true,
        scheme_title: val.scheme_title,
        status:val.status,
        id:val.id,
        eligibility:val.eligibility,
        benefit:val.benefit,
        application_process:val.application_process,
        detail:val.detail,
        reference:val.reference,
        category_id:val.category_id,
        selectedList:dd,
        document_id:doc

     })
    }, 200);

 }

   showForm = (e = null) => {
      if (e) e.preventDefault();
      this.setState({
         show_form: !this.state.show_form,
         scheme_image: null,
         document_name: "",
         description: "",
         category_id:"",
         document_id:[],
         detail:"",
         reference:"",
         application_process:"",
         eligibility:"",
         benefit:"",
         status: "",
         id:"",
         position: "",
         amount:""
      })
   }

   veiwQuestion = (e, cat) => {
      e.preventDefault()
      this.getAllQuestion(cat.id);
      this.setState({
         QuestionModal:true,
         scheme_id:cat.id
      })
   }

   getAllQuestion = (scheme_id) => {
      Axios.post(`${apiUrl}/admin/all/scheme/question`, { scheme_id: scheme_id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            this.setState({
               categories: res.data.categories
            })
         }
         else {
            this.setState({
               categories: []
            })
         }
      })
   }

   toggleQuestion = (e) => {
      this.setState({
         QuestionModal: !this.state.QuestionModal,
         scheme_id:""
      })
   }
   viewEligibility = (e, cat) => {
      e.preventDefault();
      this.setState({
         modal: true,
         scheme_id:cat.id,
         state_id:cat.eligible ? cat.eligible.state_id : "",
         age:cat.eligible ? cat.eligible.age : "",
         caste:cat.eligible ? cat.eligible.caste : "",
         gender:cat.eligible ? cat.eligible.gender : "",
         bpl:cat.eligible ? cat.eligible.bpl : "",
         martial_status:cat.eligible ? cat.eligible.martial_status : "",
         pregnant:cat.eligible ? cat.eligible .pregnant: "",
         disability:cat.eligible ? cat.eligible.disability : "",
         education_level:cat.eligible ? cat.eligible.education_level : "",
         house_type:cat.eligible ? cat.eligible.house_type : "",
         wall_type:cat.eligible ? cat.eligible.wall_type : "",
         income_source:cat.eligible ? cat.eligible.income_source : "",
         own_land:cat.eligible ? cat.eligible.own_land : "",
         area_type:cat.eligible ? cat.eligible.area_type : "",
         primitive_group:cat.eligible ? cat.eligible.primitive_group: "",
         farmer:cat.eligible ? cat.eligible.farmer : "",
         annual_income:cat.eligible ? cat.eligible.annual_income : "",
         family_income:cat.eligible ? cat.eligible.family_income : "",
         sector_type:cat.eligible ? cat.eligible.sector_type : "",
         aadhar_card:cat.eligible ? cat.eligible.aadhar_card : "",
         current_disease:cat.eligible ? cat.eligible.current_disease : "",
         ration_card:cat.eligible ? cat.eligible.ration_card : "",
         brahmin_community:cat.eligible ? cat.eligible.brahmin_community : "",
         skill_type:cat.eligible ? cat.eligible.skill_type : "",
         education_percentage:cat.eligible ? cat.eligible.education_percentage : "",
         child_age:cat.eligible ? cat.eligible.child_age : "",
         child_type:cat.eligible ? cat.eligible.child_type : "",
         worker_type:cat.eligible ? cat.eligible.worker_type : "",
         farmer_type:cat.eligible ? cat.eligible.farmer_type : "",
         health_card:cat.eligible ? cat.eligible.health_card : "",
         halmet_village:cat.eligible ? cat.eligible.halmet_village : "",
         height:cat.eligible ? cat.eligible.height : "",
         sex_victim:cat.eligible ? cat.eligible.sex_victim : "",
      })
   }
   notify = (type, message) => {
      var atype = type === "error" ? "danger" : type;
      var options = {};
      options = {
         place: process.env.REACT_APP_TOAST_POSITION,
         message: (
            <div>
               <div>
                  {message}
               </div>
            </div>
         ),
         type: atype,
         icon: "tim-icons icon-bell-55",
         autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
   };
   toggle = (e) => {
      this.setState({
         modal: !this.state.modal
      })
   }
   addEligibility = (e) => {
         var data = {
            state_id:this.state.state_id,
            age:this.state.age,
            caste:this.state.caste,
            gender:this.state.gender,
            bpl:this.state.bpl,
            martial_status:this.state.martial_status,
            pregnant:this.state.pregnant,
            disability:this.state.disability,
            education_level:this.state.education_level,
            house_type:this.state.house_type,
            wall_type:this.state.wall_type,
            income_source:this.state.income_source,
            own_land:this.state.own_land,
            area_type:this.state.area_type,
            primitive_group:this.state.primitive_group,
            farmer:this.state.farmer,
            annual_income:this.state.annual_income,
            family_income:this.state.family_income,
            sector_type:this.state.sector_type,
            aadhar_card:this.state.aadhar_card,
            current_disease:this.state.current_disease,
            ration_card:this.state.ration_card,
            brahmin_community:this.state.brahmin_community,
            skill_type:this.state.skill_type,
            education_percentage:this.state.education_percentage,
            child_age:this.state.child_age,
            child_type:this.state.child_type,
            worker_type:this.state.worker_type,
            farmer_type:this.state.farmer_type,
            health_card:this.state.health_card,
            halmet_village:this.state.halmet_village,
            height:this.state.height,
            sex_victim:this.state.sex_victim,
            scheme_id:this.state.scheme_id
         }
         Axios.post(`${apiUrl}/admin/add/scheme/eligibile`, data, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
         }).then(res => {
            this.notify("success", "Eligibility added successfully.");
            this.setState({
               categories: res.data.categories,
               modal: false
            })
         })

   }
   commingSoonActioon = (e, cat) => {
      e.preventDefault();
      console.log(cat)
      Axios.post(`${apiUrl}/admin/comming/soon/manage`, { category_id: cat.id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.notify(res.data.success ? "success" : "error", res.data.message);
         this.getAllDocument();

      })
   }
   changeAccess = (e, val, type) => {
      e.preventDefault();
      this.setState({
         loading: true
      })
      var data = {
         type: type,
         category_id: val.id,
         status: e.target.checked ? "yes" : "no"
      }
      Axios.post(`${apiUrl}/admin/categories/update/access`, data, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.setState({
            loading: false
         })
         this.notify(res.data.success ? "success" : "error", res.data.message);
         if (res.data.success) {
            this.setState({
               categories: res.data.categories
            })
         }

      },err=>{

         this.setState({
            loading: false
         })
         this.notify("error", "Something went wrong");
      })
   }

   onSelect(selectedList, selectedItem) {
      var option = [];
      for (let s of selectedList) {
         option.push(s.id)
      }

      setTimeout(() => {
         this.setState({
            document_id: option
         })
         console.log("chooseRest", this.state.document_id);
      }, 100);

   }

   onRemove(selectedList, removedItem) {
      var option = [];
      for (let s of selectedList) {
         option.push(s.id)
      }

      setTimeout(() => {
         this.setState({
            document_id: option
         })
         console.log("chooseRest", this.state.document_id);
      }, 100);

   }

  handleAddOption = () => {
   this.setState({
      AnswerData: this.state.AnswerData.concat([{ answer_option: ""}])
   });
};

handleRemoveOption = (idx) => {
 this.setState({
    AnswerData: this.state.AnswerData.filter((s, sidx) => parseInt(idx.target.value) !== sidx),
 });
 setTimeout(() => {
   console.log(this.state.AnswerData)
 }, 200);
};

handleOptionChange = (evt, idx) => {
const newShareholders = this.state.AnswerData.map((shareholder, sidx) => {
     if (idx !== sidx) return shareholder;
     return { ...shareholder, answer_option: evt.target.value, position: idx + 1 };

});
setTimeout(() => {
  this.setState({ AnswerData: newShareholders });
}, 100);
};
handleInput(e) {


   this.setState({ [e.target.name]: e.target.value })
}

addQuestion = e => {
   e.preventDefault();
   if (this.state.question == "") {
      this.notify("error", "Please enter question.");
      return false;
   }

   if (this.state.AnswerData == "") {
      this.notify("error", "Please enter answer.");
      return false;
   }
   if (this.state.status == "") {
     this.notify("error", "Please choose status.");
     return false;
  }

   const formData = new FormData();
   formData.append('question', this.state.question);
   formData.append('status', this.state.status);
   formData.append('scheme_id', this.state.scheme_id);
   formData.append('answer_option', JSON.stringify(this.state.AnswerData));
   if(this.state.id){
     formData.append('id', this.state.id);
     Axios.post(`${apiUrl}/admin/save/scheme/question`, formData, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.setState({
               question:"",
               answer_option:"",
               AnswerData: [{ answer_option: ""}],
               status:''
            })
            this.getAllQuestion(this.state.scheme_id);
         } else {
            this.notify("error", res.data.message);
         }
      })
   }else{
     Axios.post(`${apiUrl}/admin/save/scheme/question`, formData, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.setState({
               question:"",
               answer_option:"",
               AnswerData: [{ answer_option: ""}],
               status:''
            })
            this.getAllQuestion(this.state.scheme_id);
         } else {
            this.notify("error", res.data.message);
         }
      })
   }

}

editQuestion = (e ,val) => {
   if (e) e.preventDefault();
   var Option=[];
 if(val.answer_option != null){
   for(let k of val.answer_option){
     Option.push({"answer_option":k})
   }
 }
   this.setState({
       show_form: true,
       question:val.question,
       AnswerData:Option,
      status:val.status,
      id:val.id,

   })
}
   render() {
      return (
         <>
         {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
            <div className="content">
               <div className="react-notification-alert-container">
                  <NotificationAlert ref="notificationAlert" />
               </div>
               <Row>
                  <Col md="12">
                     <Card>
                        <CardHeader>
                           <h3 className="title">Scheme
                              {!this.state.show_form && <small className="float-right"><a href="" className="btn btn-success" onClick={(e) => { this.showForm(e) }} >+ Add New</a></small>}
                           </h3>
                        </CardHeader>
                        <CardBody>
                           {this.state.show_form && <Form onSubmit={this.saveScheme}>
                              <Row>
                              <Col md="6">
                                    <FormGroup>
                                       <label>
                                          Title
                                       </label>
                                       <Input value={this.state.scheme_title} name="scheme_title" placeholder="Title" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                              <Col md="6">
                                    <FormGroup>
                                       <label>Choose Category </label>
                                       <select className="form-control" name="category_id" value={this.state.category_id} onChange={(e) => { this.inputChange(e) }}>
                                       <option value="">Select</option>
                                       {this.state.topCategory.map((val, key) => {
                                          return <option value={val.id} key={key}>{val.name}</option>;
                                       })}

                                    </select>
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Choose Document</label>
                                       <Multiselect
                                          options={this.state.options} // Options to display in the dropdown
                                          selectedValues={this.state.selectedList} // Preselected value to persist in dropdown
                                          onSelect={this.onSelect} // Function will trigger on select event
                                          onRemove={this.onRemove} // Function will trigger on remove event
                                          displayValue="name" // Property name to display in the dropdown options
                                          />
                                    </FormGroup>
                                 </Col>

                                 <Col md="6">
                                    <FormGroup>
                                       <label>Service Image</label>
                                       <Input name="banner" type="file" onChange={(e) => { this.onFileChange(e) }} style={{ opacity: 1 }} />
                                    </FormGroup>
                                 </Col>

                                 <Col md="6">
                                    <FormGroup>
                                       <label>Details</label>
                                       <CKEditor
                                          editor={ ClassicEditor }
                                          data={this.state.detail}

                                          onReady={ editor => {
                                             // You can store the "editor" and use when it is needed.
                                             console.log( 'Editor is ready to use!', editor );
                                          } }
                                          onChange={ ( event, editor ) => {
                                             const data = editor.getData();
                                             this.setState({
                                                detail:data
                                             })
                                             console.log( { event, editor, data } );
                                          } }
                                          onBlur={ ( event, editor ) => {
                                             console.log( 'Blur.', editor );
                                          } }
                                          onFocus={ ( event, editor ) => {
                                             console.log( 'Focus.', editor );
                                          } }
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col md="6">
                                    <FormGroup>
                                       <label>Benefits</label>
                                       <CKEditor
                                          editor={ ClassicEditor }
                                          data={this.state.benefit}

                                          onReady={ editor => {
                                             // You can store the "editor" and use when it is needed.
                                             console.log( 'Editor is ready to use!', editor );
                                          } }
                                          onChange={ ( event, editor ) => {
                                             const data = editor.getData();
                                             this.setState({
                                                benefit:data
                                             })
                                             console.log( { event, editor, data } );
                                          } }
                                          onBlur={ ( event, editor ) => {
                                             console.log( 'Blur.', editor );
                                          } }
                                          onFocus={ ( event, editor ) => {
                                             console.log( 'Focus.', editor );
                                          } }
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col md="6">
                                    <FormGroup>
                                       <label>Eligibility</label>
                                       <CKEditor
                                          editor={ ClassicEditor }
                                          data={this.state.eligibility}

                                          onReady={ editor => {
                                             // You can store the "editor" and use when it is needed.
                                             console.log( 'Editor is ready to use!', editor );
                                          } }
                                          onChange={ ( event, editor ) => {
                                             const data = editor.getData();
                                             this.setState({
                                                eligibility:data
                                             })
                                             console.log( { event, editor, data } );
                                          } }
                                          onBlur={ ( event, editor ) => {
                                             console.log( 'Blur.', editor );
                                          } }
                                          onFocus={ ( event, editor ) => {
                                             console.log( 'Focus.', editor );
                                          } }
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col md="6">
                                    <FormGroup>
                                       <label>Application Process</label>
                                       <CKEditor
                                          editor={ ClassicEditor }
                                          data={this.state.application_process}

                                          onReady={ editor => {
                                             // You can store the "editor" and use when it is needed.
                                             console.log( 'Editor is ready to use!', editor );
                                          } }
                                          onChange={ ( event, editor ) => {
                                             const data = editor.getData();
                                             this.setState({
                                                application_process:data
                                             })
                                             console.log( { event, editor, data } );
                                          } }
                                          onBlur={ ( event, editor ) => {
                                             console.log( 'Blur.', editor );
                                          } }
                                          onFocus={ ( event, editor ) => {
                                             console.log( 'Focus.', editor );
                                          } }
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>
                                          Source and Reference URL
                                       </label>
                                       <Input value={this.state.reference} name="reference" placeholder="Referene URL" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>
                                          Status
                                       </label>
                                       <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                                          <option value="">Select</option>
                                          <option value="active">Active</option>
                                          <option value="inactive">Inactive</option>
                                       </select>
                                    </FormGroup>
                                 </Col>


                              </Row>
                              <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                 Cancel
                              </Button>
                              <Button className="btn-fill" color="primary" type="submit">
                                 Save
                              </Button>
                           </Form>}
                           <Table className="tablesorter" responsive>
                              <thead className="text-primary">
                                 <tr>
                                    <th>Sr.</th>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <th>Image</th>
                                    <th>Eligibility Added</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.scheme.map((val, key) => {
                                    return <tr key={key}>
                                       <td>{key+1}</td>
                                       <td>{val.scheme_title}</td>
                                      <td>{val.gov_category.name}</td>
                                      <td className="categoryimg">
                                          {val.scheme_image && <img src={imageUrl + val.scheme_image} alt=" " style={{ width: "50px" }} />}
                                       </td>
                                       <td>{val.eligible ? "Yes" : "No"}</td>
                                       <td>{val.status}</td>
                                       <td>
                                          <a href="" onClick={(e) => { this.editForm(e, val) }} >
                                            <i className="fa fa-pencil-square-o" title="Edit Category"></i></a>

                                            <a href="" onClick={e => { this.viewEligibility(e, val) }}><i className="fa fa-eye" title="Add Eligibility"></i></a>

                                            <a href="" onClick={e => { this.veiwQuestion(e, val) }}><i className="fa fa-eye" title="Add Question"></i>

                                          </a>
                                       </td>
                                    </tr>
                                 })}
                              </tbody>
                           </Table>
                        </CardBody>
                        {/* <CardFooter>

                                </CardFooter> */}
                     </Card>
                  </Col>
               </Row>
            </div>
            <Modal isOpen={this.state.QuestionModal} toggle={this.toggleQuestion} size='lg'>
               <ModalHeader toggle={this.toggleQuestion}>Add Question</ModalHeader>
               <ModalBody>
               <Row>
               <Col md="6">
                  <FormGroup>
                     <label>Frequently Asked Questions</label>
                        <input type="text" class="form-control"  name="question" id="question" placeholder="Question" value={this.state.question} onChange={e => { this.handleInput(e) }} />
                        <label>Answer</label>

                        {this.state.AnswerData.map((option, idx) => {
                           return <row>
                           <input type="text" class="form-control"  name="answer_option" id="answer_option" placeholder="Answer Option" value={option.answer_option} onChange={e => { this.handleOptionChange(e, idx) }} />
                           {idx > 0 &&
                              <button type="button" value={idx} onClick={(e) => { this.handleRemoveOption(e) }} className="small minus-new">
                                    -</button>
                              }
                           <button
                                 type="button"
                                 onClick={(e) => { this.handleAddOption() }}
                                 className="small"
                              >
                                 +
                              </button>
                              </row>
                              })}

                  </FormGroup>
               </Col>
               <div class="form-group col-md-6">
                  <label for="meal_name">Status</label>
                  <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                        <option value="">Select</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                     </select>
               </div>

                  </Row>
                  <ModalFooter>
                  <Button color="primary" onClick={this.addQuestion}>Update</Button>{' '}
                  <Button color="secondary" onClick={this.toggleQuestion}>Cancel</Button>
               </ModalFooter>

                  <Table className="tablesorter" responsive>
                     <thead className="text-primary">
                        <tr>
                           <th>Sr.</th>
                           <th>Question</th>
                           <th>Answer</th>
                           <th>Status</th>
                           <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        {this.state.categories.map((val, key) => {
                           return <tr key={key}>
                              <td>{key+1}</td>
                              <td>{val.question}</td>
                              <td>
                                 {val.answer_option != null && val.answer_option.map((v, k) => {
                                    return <li>{v}</li>
                                    })}
                                    </td>
                                 <td>{val.status}</td>
                              <td>
                                 <a href="" onClick={(e) => { this.editQuestion(e, val) }} >
                                    <i className="fa fa-pencil-square-o" title="Edit Category"></i>

                                 </a>
                              </td>
                           </tr>
                        })}
                     </tbody>
                  </Table>
               </ModalBody>

            </Modal>

            <Modal isOpen={this.state.modal} toggle={this.toggle} size='lg' >
               <ModalHeader toggle={this.toggle}>Add Eligibility</ModalHeader>
               <ModalBody>
               <Row>
                  <Col md="6">
                     <FormGroup>
                        <label>
                           Choose State
                        </label>
                        <select name="state_id" id="state_id" class="form-control" onChange={(e) => { this.inputChange(e) }}>
                        <option value="All">All</option>
                           <option value="Andhra Pradesh">Andhra Pradesh</option>
                           <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                           <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                           <option value="Assam">Assam</option>
                           <option value="Bihar">Bihar</option>
                           <option value="Chandigarh">Chandigarh</option>
                           <option value="Chhattisgarh">Chhattisgarh</option>
                           <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                           <option value="Daman and Diu">Daman and Diu</option>
                           <option value="Delhi">Delhi</option>
                           <option value="Lakshadweep">Lakshadweep</option>
                           <option value="Puducherry">Puducherry</option>
                           <option value="Goa">Goa</option>
                           <option value="Gujarat">Gujarat</option>
                           <option value="Haryana">Haryana</option>
                           <option value="Himachal Pradesh">Himachal Pradesh</option>
                           <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                           <option value="Jharkhand">Jharkhand</option>
                           <option value="Karnataka">Karnataka</option>
                           <option value="Kerala">Kerala</option>
                           <option value="Madhya Pradesh">Madhya Pradesh</option>
                           <option value="Maharashtra">Maharashtra</option>
                           <option value="Manipur">Manipur</option>
                           <option value="Meghalaya">Meghalaya</option>
                           <option value="Mizoram">Mizoram</option>
                           <option value="Nagaland">Nagaland</option>
                           <option value="Odisha">Odisha</option>
                           <option value="Punjab">Punjab</option>
                           <option value="Rajasthan">Rajasthan</option>
                           <option value="Sikkim">Sikkim</option>
                           <option value="Tamil Nadu">Tamil Nadu</option>
                           <option value="Telangana">Telangana</option>
                           <option value="Tripura">Tripura</option>
                           <option value="Uttar Pradesh">Uttar Pradesh</option>
                           <option value="Uttarakhand">Uttarakhand</option>
                           <option value="West Bengal">West Bengal</option>
                           </select>
                     </FormGroup>
                     </Col>
                     <Col md="6">
                        <FormGroup>
                           <label>
                              Age
                           </label>
                           <Input value={this.state.age} name="age" placeholder="Age" type="text" onChange={(e) => { this.inputChange(e) }} />
                        </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                           Caste
                        </label>
                        <select className="form-control" name="caste" value={this.state.caste} onChange={(e) => { this.inputChange(e) }}>
                           <option value="">Select</option>
                           <option value="all">All</option>
                           <option value="sc">SC</option>
                           <option value="st">ST</option>
                           <option value="obc">OBC</option>
                           <option value="general">General</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                              Gender
                           </label>
                           <br></br>
                          Male &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.gender === 'male'} value="male" name="gender" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          Female &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.gender === 'female'} value="female" name="gender" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          Transgender &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.gender === 'transgender'} value="transgender" name="gender" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          All &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.gender === 'all'}  value="transgender" name="all" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                        </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                              BPL
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.bpl === 'yes'} value="yes" name="bpl" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.bpl === 'no'} value="no" name="bpl" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          All &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.bpl === 'all'} value="all" name="bpl" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                           Martial Status
                        </label>
                        <select className="form-control" name="martial_status" value={this.state.martial_status} onChange={(e) => { this.inputChange(e) }}>
                           <option value="">Select</option>
                           <option value="all">All</option>
                           <option value="married">Married</option>
                           <option value="unmarried">UnMarried</option>
                           <option value="widowed">Widowed</option>
                           <option value="separated">Separated</option>
                           <option value="divorced">Divorced</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Pregnant/Lactating
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.pregnant === 'yes'} value="yes" name="pregnant" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.pregnant === 'no'} value="no" name="pregnant" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          All &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input value="all" checked={this.state.pregnant === 'all'} name="pregnant" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Disability
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.disability === 'yes'} value="yes" name="disability" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.disability === 'no'} value="no" name="disability" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          All &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.disability === 'all'} value="all" name="disability" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                           Education Level
                        </label>
                        <select className="form-control" name="education_level" value={this.state.education_level} onChange={(e) => { this.inputChange(e) }}>
                           <option value="">Select</option>
                           <option value="Literate but below primary">Literate but below primary</option>
                           <option value="Primary">Primary</option>
                           <option value="Middle">Middle</option>
                           <option value="9th to 12th class">9th to 12th class</option>
                           <option value="failed in 10th or 12th">failed in 10th or 12th</option>
                           <option value="Secondary">Secondary</option>
                           <option value="Higher Secondary">Higher Secondary</option>
                           <option value="Graduation">Graduation</option>
                           <option value="Law Graduate">Law Graduate</option>
                           <option value="Post Graduation">Post Graduation</option>
                           <option value="Politechnic">Politechnic</option>
                           <option value="Illiterate">Illiterate</option>
                           <option value="other">other</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Housetype
                        </label>
                        <select className="form-control" name="house_type" value={this.state.house_type} onChange={(e) => { this.inputChange(e) }}>
                           <option value="">Select</option>
                           <option value="Normal">Normal</option>
                           <option value="Institutional">Institutional</option>
                           <option value="Houseless">Houseless</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Wall type (Housing)
                        </label>
                        <select className="form-control" name="wall_type" value={this.state.wall_type} onChange={(e) => { this.inputChange(e) }}>
                           <option value="">Select</option>
                           <option value="Grass /Thatch /Bamboo/ Wood/ Mud/ Plastic/ Polythene/ Hand Made Tiles/ Stone/ Slate">Normal</option>
                           <option value="Institutional">Grass /Thatch /Bamboo/ Wood/ Mud/ Plastic/ Polythene/ Hand Made Tiles/ Stone/ Slate</option>
                           <option value="Other">Other</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Main Source of Income
                        </label>
                        <select className="form-control" name="income_source" value={this.state.income_source} onChange={(e) => { this.inputChange(e) }}>
                           <option value="">Select</option>
                           <option value="Manual Casual Labour">Manual Casual Labour</option>
                           <option value="Non agricultural Own Account Enterprise">Non agricultural Own Account Enterprise</option>
                           <option value="Begging/ Charity/ Alms collection">Begging/ Charity/ Alms collection</option>
                           <option value="Cultivation">Cultivation</option>
                           <option value="Other">Other</option>
                           <option value="No">No</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Any Own Land
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.own_land === 'yes'} value="yes" name="own_land" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.own_land === 'no'} value="no" name="own_land" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Type of Area
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.area_type === 'yes'} value="yes" name="area_type" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.area_type === 'no'} value="no" name="area_type" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          All &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input value="all" checked={this.state.area_type === 'all'} name="area_type" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Primitive Tribal Group
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.primitive_group === 'yes'} value="yes" name="primitive_group" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.primitive_group === 'no'} value="no" name="primitive_group" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          All &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.primitive_group === 'yes'} value="all" name="primitive_group" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Farmer
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.farmer === 'yes'} value="yes" name="farmer" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.farmer === 'no'} value="no" name="farmer" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          All &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.farmer === 'all'} value="all" name="farmer" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Annual Income
                           </label>
                           <Input value={this.state.annual_income} name="annual_income" placeholder="Annual Income" type="text" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Family income
                           </label>
                           <Input value={this.state.family_income} name="family_income" placeholder="Family Income" type="text" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Type of sector Workers
                        </label>
                        <select className="form-control" name="sector_type" value={this.state.sector_type} onChange={(e) => { this.inputChange(e) }}>
                        <option value="organized sector workers">organized sector workers</option>
                        <option value="unorganized sector workers">unorganized sector workers</option>
                        <option value="other">other</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Aadhaar card
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.aadhar_card === 'yes'} value="yes" name="aadhar_card" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.aadhar_card === 'no'} value="no"  name="aadhar_card" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Current Disease
                        </label>
                        <select className="form-control" name="current_disease" value={this.state.current_disease} onChange={(e) => { this.inputChange(e) }}>
                        <option value="Continuous Kidney Dialysis">Continuous Kidney Dialysis</option>
                        <option value="Anti Retroviral Therapy">Anti Retroviral Therapy</option>
                        <option value="Other">Other</option>
                        <option value="N/A">N/A</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           White Ration Card
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.ration_card === 'yes'} value="yes" name="ration_card" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.ration_card === 'no'} value="no" name="ration_card" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Brahmin community
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.brahmin_community === 'yes'} value="yes" name="brahmin_community" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.brahmin_community === 'no'} value="no" name="brahmin_community" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Works type/ Skills type
                        </label>
                        <select className="form-control" name="skill_type" value={this.state.skill_type} onChange={(e) => { this.inputChange(e) }}>
                        <option value="Priests">Priests</option>
                           <option value="Dappu Artists">Dappu Artists</option>
                           <option value="Traditional cobblers">Traditional cobblers</option>
                           <option value="Fisherman">Fisherman</option>
                           <option value="Toddy toppers">Toddy toppers</option>
                           <option value="Weavers">Weavers</option>
                           <option value="Small and marginal farmers">Small and marginal farmers</option>
                           <option value="Micro and small enterprise">Micro and small enterprise</option>
                           <option value="Housewives">Housewives</option>
                           <option value="Handloom weavers">Handloom weavers</option>
                           <option value="Cab drivers">Cab drivers</option>
                           <option value="Taxi drivers">Taxi drivers</option>
                           <option value="Maxi drivers">Maxi drivers</option>
                           <option value="Cleaners">Cleaners</option>
                           <option value="beedi worker">beedi worker</option>
                           <option value="iron ore">iron ore</option>
                           <option value="manganese ore">manganese ore</option>
                           <option value="chrome ore mine">chrome ore mine</option>
                           <option value="limestone">limestone</option>
                           <option value="limestone">limestone</option>
                           <option value="dolomite">dolomite</option>
                           <option value="mica mines">mica mines</option>
                           <option value="cine worker">cine worker</option>
                           <option value="Tailors">Tailors</option>
                           <option value="Dhobhis">Dhobhis</option>
                           <option value="Barbers">Barbers</option>
                           <option value="construction worker">construction worker</option>
                           <option value="Agriculture - Animal Health Worker">Agriculture - Animal Health Worker</option>
                           <option value="Agriculture - Bamboo Grower">Agriculture - Bamboo Grower</option>
                           <option value="Agriculture - Banana Farmer">Agriculture - Banana Farmer</option>
                           <option value="Agriculture - Bee Keeper">Agriculture - Bee Keeper</option>
                           <option value="Agriculture - Broiler Farm Worker">Agriculture - Broiler Farm Worker</option>
                           <option value="Agriculture - Chillies Cultivator">Agriculture - Chillies Cultivator</option>
                           <option value="Agriculture - Citrus Fruit Grower">Agriculture - Citrus Fruit Grower</option>
                           <option value="Agriculture - Coffee Plantation Worker">Agriculture - Coffee Plantation Worker</option>
                           <option value="Agriculture - Community Service Provider">Agriculture - Community Service Provider</option>
                           <option value="Agriculture - Cotton Cultivator">Agriculture - Cotton Cultivator</option>
                           <option value="Agriculture - Dairy Farmer/ Entrepreneur">Agriculture - Dairy Farmer/ Entrepreneur</option>
                           <option value="Agriculture - Dairy Worker">Agriculture - Dairy Worker</option>
                           <option value="Agriculture - Friends of Coconut Tree">Agriculture - Friends of Coconut Tree</option>
                           <option value="Agriculture - Gardener">Agriculture - Gardener</option>
                           <option value="Agriculture - Mango Grower">Agriculture - Mango Grower</option>
                           <option value="Agriculture - Tractor operator">Agriculture - Tractor operator</option>
                           <option value="Agriculture - Tea Plantation Worker">Agriculture - Tea Plantation Worker</option>
                           <option value="Agriculture - Fishery Farmer">Agriculture - Fishery Farmer</option>
                           <option value="Agriculture - Paddy and vegetable grower">Agriculture - Paddy and vegetable grower</option>
                           <option value="Agriculture - Nursery enterpreneur">Agriculture - Nursery enterpreneur</option>
                           <option value="Agriculture - Mechanisam agri mechanic">Agriculture - Mechanisam agri mechanic</option>
                           <option value="Agriculture - Livestock breeding farmer">Agriculture - Livestock breeding farmer</option>
                           <option value="Agriculture - Mushroom farmer">Agriculture - Mushroom farmer</option>
                           <option value="Agriculture - Plantation like areca nut">Agriculture - Plantation like areca nut</option>
                           <option value="lemon farmer">lemon farmer</option>
                           <option value="Automotive - Driving Assistant">Automotive - Driving Assistant</option>
                           <option value="Automotive - Fuel Service Dispensing Attendant">Automotive - Fuel Service Dispensing Attendant</option>
                           <option value="Automotive - Packing Executive/Packing Assistant/Packer">Automotive - Packing Executive/Packing Assistant/Packer</option>
                           <option value="Automotive - Press Shop Operator">Automotive - Press Shop Operator</option>
                           <option value="Automotive - Repair - Welder">Automotive - Repair - Welder</option>
                           <option value="Automotive - Sales Executive">Automotive - Sales Executive</option>
                           <option value="Automotive - Taxi Driver">Automotive - Taxi Driver</option>
                           <option value="Automotive - Tele caller">Automotive - Tele caller</option>
                           <option value="Automotive - Vehicle test Driver">Automotive - Vehicle test Driver</option>
                           <option value="Automotive - Washer">Automotive - Washer</option>
                           <option value="Automotive - Welding Assistant">Automotive - Welding Assistant</option>
                           <option value="Home Furnishing -Assistant Beautician">Home Furnishing -Assistant Beautician</option>
                           <option value="Home Furnishing -Assistant Beauty/Wellness Consultant">Home Furnishing -Assistant Beauty/Wellness Consultant</option>
                           <option value="Home Furnishing -Assistant Hair Stylist">Home Furnishing -Assistant Hair Stylist</option>
                           <option value="Home Furnishing -Assistant Nail Technician">Home Furnishing -Assistant Nail Technician</option>
                           <option value="Home Furnishing -Assistant Spa Therapist">Home Furnishing -Assistant Spa Therapist</option>
                           <option value="Home Furnishing -Beauty Therapist">Home Furnishing -Beauty Therapist</option>
                           <option value="Home Furnishing -Hair Stylist">Home Furnishing -Hair Stylist</option>
                           <option value="Home Furnishing -Pedicurist &amp; Manicurist">Home Furnishing -Pedicurist &amp; Manicurist</option>
                           <option value="Construction - Assistant Shuttering Carpenter">Construction - Assistant Shuttering Carpenter</option>
                           <option value="Construction - Carpenter System">Construction - Carpenter System</option>
                           <option value="Construction - Helper Bar Bender &amp; Fixer">Construction - Helper Bar Bender &amp; Fixer</option>
                           <option value="Construction - Helper Electrician">Construction - Helper Electrician</option>
                           <option value="Construction - Helper Laboratory &amp; Field Technician">Construction - Helper Laboratory &amp; Field Technician</option>
                           <option value="Construction - Helper Painter &amp; Decorator">Construction - Helper Painter &amp; Decorator</option>
                           <option value="Construction - Plumber">Construction - Plumber</option>
                           <option value="Construction - Supervisor">Construction - Supervisor</option>
                           <option value="Construction - Drughtsman">Construction - Drughtsman</option>
                           <option value="Construction - Storekeeper">Construction - Storekeeper</option>
                           <option value="Electronics -Field Engineer TV">Electronics -Field Engineer TV</option>
                           <option value="Electronics -Field Technician Air Conditioner">Electronics -Field Technician Air Conditioner</option>
                           <option value="Electronics -Field Technician Computing and Peripherals">Electronics -Field Technician Computing and Peripherals</option>
                           <option value="Electronics -Field Technician-UPS and Inverter">Electronics -Field Technician-UPS and Inverter</option>
                           <option value="Electronics -LED Light Repair Technician">Electronics -LED Light Repair Technician</option>
                           <option value="Electronics -Mobile Phone Hardware Repair Technician">Electronics -Mobile Phone Hardware Repair Technician</option>
                           <option value="Electronics -Solar Panel Installation Technician">Electronics -Solar Panel Installation Technician</option>
                           <option value="Food Industry supporing skills -Butter and Ghee Processing Operator">Food Industry supporing skills -Butter and Ghee Processing Operator</option>
                           <option value="Food Industry supporing skills -Craft Baker">Food Industry supporing skills -Craft Baker</option>
                           <option value="Food Industry supporing skills -Dairy Processing Equipment Operator">Food Industry supporing skills -Dairy Processing Equipment Operator</option>
                           <option value="Food Industry supporing skills -Fruits and Vegetables Selection In-Charge">Food Industry supporing skills -Fruits and Vegetables Selection In-Charge</option>
                           <option value="Food Industry supporing skills -Ice Cream Processing Technician">Food Industry supporing skills -Ice Cream Processing Technician</option>
                           <option value="Food Industry supporing skills -Jam Jelly and Ketchup Processing Technician">Food Industry supporing skills -Jam Jelly and Ketchup Processing Technician</option>
                           <option value="Food Industry supporing skills -Pickle Making Technician">Food Industry supporing skills -Pickle Making Technician</option>
                           <option value="Food Industry supporing skills -Plant Biscuit Production helper">Food Industry supporing skills -Plant Biscuit Production helper</option>
                           <option value="Furniture &amp; Fittings - Assistant Carpenter Wooden Furniture">Furniture &amp; Fittings - Assistant Carpenter Wooden Furniture</option>
                           <option value="Furniture &amp; Fittings - Fitter- Modular Furniture">Furniture &amp; Fittings - Fitter- Modular Furniture</option>
                           <option value="Furniture &amp; Fittings - Lock Technician">Furniture &amp; Fittings - Lock Technician</option>
                           <option value="Handicrafts and Carpet - Acid Cleaner">Handicrafts and Carpet - Acid Cleaner</option>
                           <option value="Handicrafts and Carpet - Agarbatti Packer">Handicrafts and Carpet - Agarbatti Packer</option>
                           <option value="Handicrafts and Carpet - Artisan">Handicrafts and Carpet - Artisan</option>
                           <option value="Handicrafts and Carpet - Automatic Machine Rolled Agarbatti Maker">Handicrafts and Carpet - Automatic Machine Rolled Agarbatti Maker</option>
                           <option value="Handicrafts and Carpet - Bamboo Artwork Maker">Handicrafts and Carpet - Bamboo Artwork Maker</option>
                           <option value="Handicrafts and Carpet - Bamboo Product Maker">Handicrafts and Carpet - Bamboo Product Maker</option>
                           <option value="Handicrafts and Carpet - Brushing Operator">Handicrafts and Carpet - Brushing Operator</option>
                           <option value="Handicrafts and Carpet - Carving Artisan">Handicrafts and Carpet - Carving Artisan</option>
                           <option value="Healthcare -Blood Bank Technician">Healthcare -Blood Bank Technician</option>
                           <option value="Healthcare -GDA ( General Duty Assistant)">Healthcare -GDA ( General Duty Assistant)</option>
                           <option value="Indian Plumbing -Plumber General Helper">Indian Plumbing -Plumber General Helper</option>
                           <option value="Indian Plumbing -Plumbing Products Sales Assistant">Indian Plumbing -Plumbing Products Sales Assistant</option>
                           <option value="Logistics - Commercial Vehicle Driver">Logistics - Commercial Vehicle Driver</option>
                           <option value="Logistics - Courier Delivery Executive">Logistics - Courier Delivery Executive</option>
                           <option value="Logistics - Data Entry Operator">Logistics - Data Entry Operator</option>
                           <option value="Logistics - Documentation Assistant">Logistics - Documentation Assistant</option>
                           <option value="Logistics - Goods Packaging Machine Operator">Logistics - Goods Packaging Machine Operator</option>
                           <option value="Logistics - Kitting and Labelling">Logistics - Kitting and Labelling</option>
                           <option value="Logistics - Loader/Unloader">Logistics - Loader/Unloader</option>
                           <option value="Logistics - Reach Truck Operator">Logistics - Reach Truck Operator</option>
                           <option value="Logistics - Receiving Assistant">Logistics - Receiving Assistant</option>
                           <option value="Logistics - Warehouse Packer">Logistics - Warehouse Packer</option>
                           <option value="Logistics - Warehouse Picker">Logistics - Warehouse Picker</option>
                           <option value="Retailers Association - Distributor Salesman">Retailers Association - Distributor Salesman</option>
                           <option value="Retailers Association - Sales Associate">Retailers Association - Sales Associate</option>
                           <option value="Rubber - Autoclave Operator">Rubber - Autoclave Operator</option>
                           <option value="Rubber - Bicycle/Rickshaw Tyre Building Operator-Mono Band">Rubber - Bicycle/Rickshaw Tyre Building Operator-Mono Band</option>
                           <option value="Rubber - General Worker - Rubber Plantation">Rubber - General Worker - Rubber Plantation</option>
                           <option value="Rubber - Packaging">Rubber - Packaging</option>
                           <option value="Rubber - Rubber Nursery Worker-General">Rubber - Rubber Nursery Worker-General</option>
                           <option value="Rubber - Tyre / Tube repair (Tyre Care / Services)">Rubber - Tyre / Tube repair (Tyre Care / Services)</option>
                           <option value="Security - Security Guard">Security - Security Guard</option>
                           <option value="Green Jobs - Wastewater Treatment Plant Helper">Green Jobs - Wastewater Treatment Plant Helper</option>
                           <option value="Telecom -Customer Care Executive (Call Centre)">Telecom -Customer Care Executive (Call Centre)</option>
                           <option value="Telecom -Customer Care Executive (Repair Center)">Telecom -Customer Care Executive (Repair Center)</option>
                           <option value="Telecom -E-waste Collector">Telecom -E-waste Collector</option>
                           <option value="Telecom -Field Sales Executive">Telecom -Field Sales Executive</option>
                           <option value="Other">Other</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Education percentage
                        </label>
                        <select className="form-control" name="education_percentage" value={this.state.education_percentage} onChange={(e) => { this.inputChange(e) }}>
                        <option value="50">50</option>
                        <option value="55">55</option>
                        <option value="60">60</option>
                        <option value="65">65</option>
                        <option value="70">70</option>
                        <option value="75">75</option>
                        <option value="80">80</option>
                        <option value="85">85</option>
                        <option value="90">90</option>
                        <option value="other">other</option>
                        <option value="N/A">N/A</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Child age
                           </label>
                           <br></br>
                          0-6 month &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.child_age === '0-6'} value="0-6" name="child_age" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          6 month-5 year &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.child_age === '6-5'} value="6-5" name="child_age" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          Above 5 Year &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.child_age === 'above_5'} value="above_5" name="child_age" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          Other &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.child_age === 'other'} value="other" name="child_age" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Child Type
                        </label>
                        <select className="form-control" name="child_type" value={this.state.child_type} onChange={(e) => { this.inputChange(e) }}>
                        <option value="Orphan">Orphan</option>
                        <option value="decibel">decibel</option>
                        <option value="dropout">dropout</option>
                        <option value="other">other</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Family workers type
                        </label>
                        <select className="form-control" name="worker_type" value={this.state.worker_type} onChange={(e) => { this.inputChange(e) }}>
                        <option value="beedi worker">beedi worker</option>
                        <option value="iron ore">iron ore</option>
                        <option value="manganese ore">manganese ore</option>
                        <option value="chrome ore mine">chrome ore mine</option>
                        <option value="limestone">limestone</option>
                        <option value="dolomite">dolomite</option>
                        <option value="mica mines">mica mines</option>
                        <option value="cine worker">cine worker</option>
                        <option value="Others">Others</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Family type
                        </label>
                        <select className="form-control" name="farmer_type" value={this.state.farmer_type} onChange={(e) => { this.inputChange(e) }}>
                        <option value="Small">Small</option>
                        <option value="Marginal">Marginal</option>
                        <option value="Large">Large</option>
                        <option value="Tenant">Tenant</option>
                        <option value="Other">Other</option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Health Card
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.health_card === 'yes'} value="yes" name="health_card" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.health_card === 'no'} value="no" name="health_card" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Name of the Hamlet Village
                           </label>
                           <Input value={this.state.halmet_village} name="halmet_village" placeholder="Halmet Village Income" type="text" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  <Col md="6">
                     <FormGroup>
                        <label>
                        Height ( In Feet )
                        </label>
                        <select className="form-control" name="height" value={this.state.height} onChange={(e) => { this.inputChange(e) }}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value=""></option>
                        </select>
                     </FormGroup>
                  </Col>

                  <Col md="6">
                        <FormGroup>
                           <label>
                           Sex Exploitation Victim
                           </label>
                           <br></br>
                          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.sex_victim === 'yes'} value="yes" name="sex_victim" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />
                          No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input checked={this.state.sex_victim === 'no'} value="no" name="sex_victim" placeholder="Age" type="radio" onChange={(e) => { this.inputChange(e) }} />

                        </FormGroup>
                  </Col>

                  </Row>
               </ModalBody>
               <ModalFooter>
                  <Button color="primary" onClick={this.addEligibility}>Update</Button>{' '}
                  <Button color="secondary" onClick={this.toggle}>Cancel</Button>
               </ModalFooter>
            </Modal>
         </>
      );
   }
}

export default Scheme;
