import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import "../../assets/css/style.css";
class Amlcft extends React.Component {


    render() {
        return <>
            <div className="site_home">
                <Header banner="aml-cft" h1Text="AML-CFT Policy" bannerclassName="inner_head our_policy"/>
                <section className="policy_sec">
                    <div className="container">
                        <div className="policy">
                            <p>I - Sewa  is dedicated to conduct business operations in a safe and sound manner in compliance with the highest legal and ethical standards. I - Sewa  has established strict standards of compliance with regulations designed to assist in the detection and prevention of money laundering and terrorist financing.</p>
                            <p> Money laundering and terrorist financing is a global challenge and has been declared criminal activities in most of the countries.</p>
                            <p> We recognize that the fight against money laundering and financing of terrorism, is a team effort and this “Anti Money Laundering Policy” provides guidance on identifying and controlling risk associated with ML/TF. </p>
                            <p> The purpose of this policy is to enhance the company’s compliance with anti-money laundering laws and regulations, to assist law enforcement in combating ML/TF to minimize the risk of company resources being used for improper purposes and safeguard the interest of our customers, shareholders, staff members and communities where we operate. </p>
                            <p> We always seek to comply with both the letter and spirit of relevant laws, rules, regulation, code and standards of good practice and aim to promptly address any irregularities that may arise, as we believe in transparency in our financial and regulatory reporting with swift disclosure of any breaches. </p>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>;
    }
}

export default Amlcft;