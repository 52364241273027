import React from "react";
import {
    Button,
   Card,
   CardHeader,
   CardBody,
   CardFooter,
   CardText,
   FormGroup,
   Form,
   Input,
   Row,
   Col,
   Table
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import { Pagination } from 'react-laravel-paginex';
import env from "variables/constants";

var apiUrl = env.url;
var imageUrl = env.img_url;
class Serviceproviders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            providers: [],

            edit_form:false,
            provider_name:"",
            cashback:0,
            margin:0,
            id:"",
            icon:"",
            cashback_status:"off",
            paginatedata: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 0,
                last_page: 0,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }
    componentDidMount = () => {
        this.getAllProviders(1);
    }
    getAllProviders = (page = 1) => {
        Axios.get(`${apiUrl}/admin/all/providers?page=${page}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            console.log("providers response", res)
            if (res.data.success) {
                this.setState({
                    paginatedata: res.data.providers,
                    providers: res.data.providers.data
                })
            }
            else {
                this.setState({
                    providers: []
                })
            }
        })
    }
    cancelForm = (e = null ) => {
        if (e) e.preventDefault();
        this.setState({
           edit_form: !this.state.edit_form,
           
           provider_name: null,
           cashback:"",
           margin:"",
           cashback_status:"off",
           id:""
           
        })
     }
     editForm = (e ,val) => {
        if (e) e.preventDefault();
        this.setState({
           edit_form: true,
           provider_name: val.provider_name,
         
          
           cashback:val.cashback,
           margin:val.margin,
           cashback_status:val.cashback_status,
           id:val.id
           
        })
     }
     inputChange = (e) => {
        this.setState({
           [e.target.name]: e.target.value
        })
     }
     updateprovider = e => {
        e.preventDefault();
      
        // if (this.state.cashback === "") {
        //    this.notify("error", "Please enter cashback.");
        //    return false;
        // }
        // if (this.state.margin === "") {
        //     this.notify("error", "Please enter margin.");
        //     return false;
        //  }
        
        
        const formData = new FormData();
        formData.append('id', this.state.id);
       
        formData.append('cashback', this.state.cashback);
        formData.append('margin', this.state.margin);
        formData.append('cashback_status', this.state.cashback_status);
        formData.append('icon', this.state.icon);
        
        
        Axios.post(`${apiUrl}/admin/update/provider`, formData, {
           headers: {
              Authorization: "Bearer " + sessionStorage.getItem("_access")
           }
        }).then(res => {
           if (res.data.success) {
              this.notify("success", res.data.message);
              this.cancelForm();
              this.getAllProviders(this.state.paginatedata.current_page);
           } else {
              this.notify("error", res.data.message);
           }
        })
     }
    changePage = data => {
        console.log(data)
        this.getAllProviders(data.page);
    }
    onFileChange = (e) => {
        this.setState({ icon: e.target.files[0] })
     } 
    statusChangeProvider = (e, id) => {
        e.preventDefault();
        Axios.post(`${apiUrl}/admin/provider/change/status`, { provider_id: id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            if (res.data.success) {
                this.notify("success", res.data.message);
                this.getAllProviders(this.state.paginatedata.current_page);
            }
            else {
                this.notify("error", res.data.message);
            }
        }, err => {
            this.notify("error", "Something went wrong");
            console.log("status error", err)
        })
    }
    notify = (type, message) => {
        console.log("this.refs.notificationAlert before", this.refs.notificationAlert)

        if (this.refs.notificationAlert && this.refs.notificationAlert.state.notifyTR.length <= 1) {
            var atype = type === "error" ? "danger" : type;
            var options = {};
            options = {
                place: process.env.REACT_APP_TOAST_POSITION,
                message: (
                    <div>
                        <div>
                            {message}
                        </div>
                    </div>
                ),
                type: atype,
                icon: "tim-icons icon-bell-55",
                autoDismiss: 7
            };
            this.refs.notificationAlert.notificationAlert(options);
            console.log("this.refs.notificationAlert", this.refs.notificationAlert)
        }
    };
    popularServices = (e, val) => {
        var is_popular = "no";
        var id = val.id;
        if (e.target.checked) {
            is_popular = "yes";
        }
        this.setState({
            loading: val.id
        })
        var data = {
            provider_id: id,
            is_popular: is_popular
        }
        console.log(val.id, this.state);
        Axios.post(`${apiUrl}/admin/update/popular/services`, data, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
        }).then(res => {
            this.getAllProviders(this.state.paginatedata.current_page);
            this.setState({
                loading: false
            })
            if (!res.data.success) {
                this.notify("error", res.data.message);
            }
        })
    }
    render() {
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="title">Service Providers</h3>
                                </CardHeader>
                                <CardBody>
                                {this.state.edit_form && <Form onSubmit={this.updateprovider}>
                              <Row>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Name</label>
                                       <Input value={this.state.provider_name} name="provider_name" placeholder="Name" type="text"  readOnly onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                             
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Margin</label>
                                       <Input value={this.state.margin} name="margin" placeholder="Margin percentage" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Cashback</label>
                                       <Input value={this.state.cashback} name="cashback" placeholder="Cashback" type="text" onChange={(e) => { this.inputChange(e) }} />
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>
                                       Cashback Status
                                    </label>
                                       <select className="form-control" name="cashback_status" value={this.state.cashback_status} onChange={(e) => { this.inputChange(e) }}>
                                          <option value="">Select</option>
                                          <option value="on">On</option>
                                          <option value="off">Off</option>
                                       </select>
                                    </FormGroup>
                                 </Col>
                                 <Col md="6">
                                    <FormGroup>
                                       <label>Icon</label>
                                       <Input name="icon" type="file" onChange={(e) => { this.onFileChange(e) }} style={{ opacity: 1 }} />
                                    </FormGroup>
                                 </Col>
                              </Row>
                            
                              <Button className="btn-fill" color="primary" type="button" onClick={this.cancelForm}>
                                 Cancel
                                        </Button>
                              <Button className="btn-fill" color="primary" type="submit">
                                 Save
                                        </Button>
                           </Form>}
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>#</th>
                                                <th>Service Name</th>
                                                <th>Provider Name</th>
                                                <th>Icon</th>
                                                <th>Status</th>
                                                <th>Popular Service</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.providers.map((val, key) => {
                                                return <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{val.category ? val.category.category_name : ""}</td>
                                                    <td>{val.provider_name}</td>
                                                    <td>
                                                        {val.image && <img src={imageUrl + val.image} alt=" " style={{ width: "50px" }} />}
                                                    </td>
                                                    <td>{val.status}</td>
                                                    <td>
                                                        {this.state.loading !== val.id && <input type="checkbox" name="popular_service" onClick={(e) => { this.popularServices(e, val) }} checked={val.is_popular === 'yes'} />}
                                                        {this.state.loading === val.id && <i className="fa fa-spinner fa-spin" style={{ fontSize: "18px" }}></i>}
                                                    </td>
                                                    <td>
                                                        <a href="" onClick={(e) => { this.statusChangeProvider(e, val.id) }} >
                                                            {val.status === "active" && <i className="tim-icons icon-simple-remove" title="Inactive Category"></i>}
                                                            {val.status === "inactive" && <i className="tim-icons icon-check-2" title="Active Category"></i>}
                                                        </a>
                                                       
                                                          &nbsp; &nbsp;
                                                        <a href="javascript:void(0)" className="detailsEdit" title="Edit Details" onClick={(e) => { this.editForm(e, val) }}><i className="fa fa-pencil-square-o" style={{ fontSize: "", verticalAlign: "middle" }} ></i></a>
                                                        
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <Pagination changePage={this.changePage} data={this.state.paginatedata} />
                                </CardBody>
                                {/* <CardFooter>

                                </CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Serviceproviders;
