import React from "react";
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Form,
   Input,
   Row,
   Col,
   Table,
   Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Axios from "axios";
import NotificationAlert from "react-notification-alert";
import env from "variables/constants";
var apiUrl = env.url;
var imageUrl = env.img_url;
class Document extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading:false,
         status:"",
         question_type:"multiple",
         categories: [],
         AnswerData: [{ answer_option: ""}],
         position:"",
         description: "",
         amount:"",
         status: "",
         id:"",
         document_name: "",
         position: "",
         category_icon: null,
         show_form: false,
         modal: false,
         selected_category: null
      }
   }
   componentDidMount = () => {
      this.getAllQuestion();
   }

   handleInput(e) {


    this.setState({ [e.target.name]: e.target.value })
}
   inputChange = (e) => {
      this.setState({
         [e.target.name]: e.target.value
      })
   }
   onFileChange = (e) => {
      this.setState({ category_icon: e.target.files[0] })
   }

   saveCategory = e => {
      e.preventDefault();
      if (this.state.question == "") {
         this.notify("error", "Please enter question.");
         return false;
      }

      if (this.state.AnswerData == "") {
         this.notify("error", "Please enter answer.");
         return false;
      }
      if (this.state.status == "") {
        this.notify("error", "Please choose status.");
        return false;
     }

      const formData = new FormData();
      formData.append('question', this.state.question);
      formData.append('status', this.state.status);
    formData.append('answer_option', JSON.stringify(this.state.AnswerData));
      if(this.state.id){
        formData.append('id', this.state.id);
        Axios.post(`${apiUrl}/admin/save/common/question`, formData, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            if (res.data.success) {
               this.notify("success", res.data.message);
               this.showForm();
               this.getAllQuestion();
            } else {
               this.notify("error", res.data.message);
            }
         })
      }else{
        Axios.post(`${apiUrl}/admin/save/common/question`, formData, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem("_access")
            }
         }).then(res => {
            if (res.data.success) {
               this.notify("success", res.data.message);
               this.showForm();
               this.getAllQuestion();
            } else {
               this.notify("error", res.data.message);
            }
         })
      }

   }

   getAllQuestion = () => {
      Axios.get(`${apiUrl}/admin/all/common/question`, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         console.log("categories response", res)
         if (res.data.success) {
            this.setState({
               categories: res.data.categories
            })
         }
         else {
            this.setState({
               categories: []
            })
         }
      })
   }
   statusChangeCategory = (e, id) => {
      e.preventDefault();
      Axios.post(`${apiUrl}/admin/category/change/status`, { category_id: id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem("_access")
         }
      }).then(res => {
         if (res.data.success) {
            this.notify("success", res.data.message);
            this.getAllQuestion();
         }
         else {
            this.notify("error", res.data.message);
         }
      }, err => {
         this.notify("error", "Something went wrong");
         console.log("status error", err)
      })
   }

   editForm = (e ,val) => {
    if (e) e.preventDefault();
    var Option=[];
  if(val.answer_option != null){
    for(let k of val.answer_option){
      Option.push({"answer_option":k})
    }
  }
    this.setState({
        show_form: true,
        question:val.question,
        AnswerData:Option,
       status:val.status,
       id:val.id,

    })
 }

   showForm = (e = null) => {
      if (e) e.preventDefault();
      this.setState({
         show_form: !this.state.show_form,
         category_icon: null,
         document_name: "",
         description: "",
         status: "",
         id:"",
         AnswerData: [{ answer_option: ""}],
         answer_option:"",
         question:"",
         position: "",
         amount:""
      })
   }
   positionChangeCategory = (e, cat) => {
      e.preventDefault();
      this.setState({
         modal: true,
         selected_category: cat,
         position: cat.position
      })
   }
   notify = (type, message) => {
      var atype = type === "error" ? "danger" : type;
      var options = {};
      options = {
         place: process.env.REACT_APP_TOAST_POSITION,
         message: (
            <div>
               <div>
                  {message}
               </div>
            </div>
         ),
         type: atype,
         icon: "tim-icons icon-bell-55",
         autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
   };
   toggle = (e) => {
      this.setState({
         modal: !this.state.modal
      })
   }
   changePosition = (e) => {
      if (this.state.selected_category.position != this.state.position) {
         var data = {
            new_position: this.state.position,
            id: this.state.selected_category.id,
            old_position: this.state.selected_category.position
         }
         Axios.post(`${apiUrl}/admin/change/category/position`, data, {
            headers: {
               Authorization: "Bearer " + sessionStorage.getItem('_access')
            }
         }).then(res => {
            this.notify("success", "Posiotn changed successfully.");
            this.setState({
               categories: res.data.categories,
               modal: false
            })
         })
      }
   }
   commingSoonActioon = (e, cat) => {
      e.preventDefault();
      console.log(cat)
      Axios.post(`${apiUrl}/admin/comming/soon/manage`, { category_id: cat.id }, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.notify(res.data.success ? "success" : "error", res.data.message);
         this.getAllQuestion();

      })
   }
   changeAccess = (e, val, type) => {
      e.preventDefault();
      this.setState({
         loading: true
      })
      var data = {
         type: type,
         category_id: val.id,
         status: e.target.checked ? "yes" : "no"
      }
      Axios.post(`${apiUrl}/admin/categories/update/access`, data, {
         headers: {
            Authorization: "Bearer " + sessionStorage.getItem('_access')
         }
      }).then(res => {
         this.setState({
            loading: false
         })
         this.notify(res.data.success ? "success" : "error", res.data.message);
         if (res.data.success) {
            this.setState({
               categories: res.data.categories
            })
         }

      },err=>{

         this.setState({
            loading: false
         })
         this.notify("error", "Something went wrong");
      })
   }

   handleAddOption = () => {
    this.setState({
       AnswerData: this.state.AnswerData.concat([{ answer_option: ""}])
    });
 };

 handleRemoveOption = (idx) => {
  this.setState({
     AnswerData: this.state.AnswerData.filter((s, sidx) => parseInt(idx.target.value) !== sidx),
  });
  setTimeout(() => {
    console.log(this.state.AnswerData)
  }, 200);
};

handleOptionChange = (evt, idx) => {
const newShareholders = this.state.AnswerData.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, answer_option: evt.target.value, position: idx + 1 };

});
setTimeout(() => {
   this.setState({ AnswerData: newShareholders });
}, 100);
};
   render() {
      return (
         <>
         {this.state.loading && <div className="loading-overlay">
                    <span className="fa fa-spinner fa-3x fa-spin" style={{ "float": "right", marginRight: "50%", "marginTop": "20%" }}></span>
                </div>}
            <div className="content">
               <div className="react-notification-alert-container">
                  <NotificationAlert ref="notificationAlert" />
               </div>
               <Row>
                  <Col md="12">
                     <Card>
                        <CardHeader>
                           <h3 className="title">Common Question
                              {!this.state.show_form && <small className="float-right"><a href="" className="btn btn-success" onClick={(e) => { this.showForm(e) }} >+ Add New</a></small>}
                           </h3>
                        </CardHeader>
                        <CardBody>
                           {this.state.show_form && <Form onSubmit={this.saveCategory}>
                              <Row>
                              {/* <div class="form-group col-md-6">
                                <label for="question_type">Choose Question Type</label>
                                <select class="form-control" id="question_type"  name="question_type" value={this.state.question_type} onChange={e => { this.handleInput(e) }} >
                                <option value="" >Select </option>
                                <option value="text" >Text</option>
                                <option value="multiple" >Multiple Choice</option>

                                </select>
                            </div> */}

                                <div class="form-group col-md-6">
                                    <label for="meal_name">Question</label>
                                    <input type="text" class="form-control"  name="question" id="question" placeholder="Question" value={this.state.question} onChange={e => { this.handleInput(e) }} />
                                </div>



                                {this.state.question_type == 'multiple' && <> <div class="form-group col-md-6">
                                    <label for="meal_name">Answer</label>
                                    {this.state.AnswerData.map((option, idx) => {
                                    return <row>
                                    <input type="text" class="form-control"  name="answer_option" id="answer_option" placeholder="Answer Option" value={option.answer_option} onChange={e => { this.handleOptionChange(e, idx) }} />
                                    {idx > 0 &&
                                        <button type="button" value={idx} onClick={(e) => { this.handleRemoveOption(e) }} className="small minus-new">
                                            -</button>
                                    }
                                    <button
                                        type="button"
                                        onClick={(e) => { this.handleAddOption() }}
                                        className="small"
                                    >
                                        +
                                    </button>
                                    </row>
                                    })}
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="meal_name">Status</label>
                                    <select className="form-control" name="status" value={this.state.status} onChange={(e) => { this.inputChange(e) }}>
                                          <option value="">Select</option>
                                          <option value="active">Active</option>
                                          <option value="inactive">Inactive</option>
                                       </select>
                                </div>
                                </>}
                              </Row>
                              <Button className="btn-fill" color="primary" type="button" onClick={this.showForm}>
                                 Cancel
                              </Button>
                              <Button className="btn-fill" color="primary" type="submit">
                                 Save
                              </Button>
                           </Form>}
                           <Table className="tablesorter" responsive>
                              <thead className="text-primary">
                                 <tr>
                                    <th>Sr.</th>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.categories.map((val, key) => {
                                    return <tr key={key}>
                                       <td>{key+1}</td>
                                       <td>{val.question}</td>
                                       <td>
                                        {val.answer_option != null && val.answer_option.map((v, k) => {
                                            return <li>{v}</li>
                                            })}
                                            </td>
                                        <td>{val.status}</td>
                                       <td>
                                          <a href="" onClick={(e) => { this.editForm(e, val) }} >
                                            <i className="fa fa-pencil-square-o" title="Edit Category"></i>

                                          </a>
                                       </td>
                                    </tr>
                                 })}
                              </tbody>
                           </Table>
                        </CardBody>
                        {/* <CardFooter>

                                </CardFooter> */}
                     </Card>
                  </Col>
               </Row>
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} >
               <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
               <ModalBody>
                  <p>Changing position warning</p>
                  <FormGroup>
                     <label>Position</label>
                     <Input name="position" value={this.state.position} placeholder="position" type="number" min={1} max={this.state.categories.length} onChange={(e) => { this.inputChange(e) }} />
                  </FormGroup>
               </ModalBody>
               <ModalFooter>
                  <Button color="primary" onClick={this.changePosition}>Update</Button>{' '}
                  <Button color="secondary" onClick={this.toggle}>Cancel</Button>
               </ModalFooter>
            </Modal>
         </>
      );
   }
}

export default Document;
