import React from "react";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import swal from 'sweetalert';
import ReceiptModel from "./ReceiptModel";
import ServiceModal from "./child-components/ServiceModal";
import KycForm from "./child-components/KycForm";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Typeahead } from 'react-bootstrap-typeahead';
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}


class Remittance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resendOK: false,
            receiver_country:"",
            resend_time: null,
            user: null,
            receiver_district:"",
            receiver_state:"",
            receiver_municipality:"",
            sender: null,
            show_form: "check_user",
            check_mobile: "",
            items:[],
            beneficiary_list: [],
            tempstatelist: [],
            permanentstatelist: [],
            districtlist: [],
            tempdistrictlist: [],
            municipalitylist: [],
            additional_course: [],
            alternate_occupation_type: [],
            bank_account_type: [],
            csp_address_proof_type: [],
            csp_business_type: [],
            csp_device: [],
            csp_device_connectivity_type: [],
            csp_document_type: [],
            csp_registration_type: [],
            customer_identification_type_indian: [],
            customer_identification_type_neplease: [],
            educational_qualification: [],
            gender: [],
            marital_status: [],
            StaticData: [],
            occupation: [],
            owner_category: [],
            owners_address_proof_type: [],
            owners_id_type: [],
            physically_handicapped: [],
            purpose_of_remittance: [],
            relationship: [],
            source_of_fund: [],
            transaction_cancel_reason: [],
            sender_temp_country: "",
            sender_name: "",
            sender_mobile_number: "",
            sender_occupation: "",
            receiver_name: "",
            receiver_address: "",
            receiver_gender: "",
            receiver_mobile_number: "",
            receiver_city: "",
            relationshipw: "",
            source_fund: "",
            remittance_reason: "",
            payment_type: "",
            bank_id: "",
            bank_branch_id: "",
            bank_account_number: "",
            collect_amount: "",
            payout_amount: "",
            service_charge: "",
            exchange_rate: "",
            banks_list: [],
            branch_list: [],
            forex_session_id: "",
            otp_token: "",
            referance_number: "",
            modalState: false,
            otp: "",
            sender_mobile: "",
            sender_first_name: "",
            sender_middle_name: "",
            sender_last_name: "",
            sender_nationality: "",
            id_issue_place: "",
            sender_marital_status: "",
            sender_dob: "",
            sender_gender: "",
            sender_father_mother_name: "",
            sender_email: "",
            sender_source_of_fund: "",
            sender_permanent_state: "",
            sender_permanent_district: "",
            sender_permanent_municipality: "",
            sender_permanent_address: "",
            sender_permanent_ward_number: "",
            sender_permanent_house_number: "",
            sender_temp_state: "",
            sender_temp_district: "",
            sender_temp_address: "",
            sender_temp_postal_code: "",
            sender_temp_house_number: "",
            sender_id_type: "",
            sender_id_number: "",
            sender_id_issue_date: "",
            sender_id_expiry_date: "",
            id_proof: null,
            photo: null,
            sender_otp: false,
            customer_token: "",
            id_place_of_issue: "",
            bene_selected: false,
            headers: {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access")),
                    Accept: 'application/json'
                }
            },
            service_modal: false
        }
    }

    componentDidMount = () => {
        this.checkAvailability();
        this.getAllMunicipaty()
        var diff = 30;
        var self = this;
        var countDownDate = moment().add(30, 'seconds');

        var x = setInterval(function () {
            diff = countDownDate.diff(moment());

            if (diff <= 0) {
                clearInterval(x);
                self.setState({
                    resend_time: 0
                })
            } else
                self.setState({
                    resend_time: (diff / 1000).toFixed(0)
                })
        }, 1000);
        this.getStaticData();
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");
        }
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
    }

    getAllMunicipaty(){
        Axios.post(`${apiUrl}/remittance/ime-forex/get/datamunicipality`, { keyword: "" }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log(res)
            // this.setState({
            //     municipalitylist: res.data.data
            // })
            var dd=[]
            if(res.data.data){
                for(let k of res.data.data){
                    dd.push({"id":k.municipality_id, "name":k.municipality, "district_id":k.district_id, "state_id":k.state_id})
                }
                setTimeout(() => {
                    this.setState({
                        items:dd
                    })
                }, 100);
            }
        })
    }

    checkAvailability = () => {
        // Axios.post(apiUrl + "/user/check/service/allowed", { service_id: 4 }, this.state.headers).then(res => {
        //     if (!res.data.success) {
        //         this.setState({ service_modal: true })
        //     }
        // })
    }
    toggleServiceModal = e => {
        this.props.history.push('/user/dashboard');
    }
    getData = (page = 1) => {

    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleInputMobile = e => {
        if (e.target.value.length <= 10) {
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            return false;
        }
    }
    handleInputCheckbox = e => {
        this.setState({
            save_beneficiary: e.target.checked ? "yes" : "no"
        })
    }
    handleInputOTP = e => {
        if (e.target.value.length <= 4) {
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            console.log(this.state.otp)
            return false;
        }
    }



    handleOnBlur = e => {
        if (!e.target.value) {
            this.setState({
                collect_amount: "",
                payout_amount: "",
                service_charge: "",
                exchange_rate: ""
            });
            return false;
        }
        var data = {
            bank_id: this.state.bank_id,
            remit_amount: this.state.collect_amount,
            payment_type: this.state.payment_type
        }
        this.setState({
            forex_session_id: "",
            payout_amount: ""
        })
        if (this.state.payment_type === "") {
            swal("", "Please select payment type.", "");
            this.setState({ collect_amount: "" })
            return false;
        }
        if (this.state.payment_type === "B") {
            if (this.state.bank_id === "") {
                swal("", "Please select bank.", "error");
                this.setState({ collect_amount: "" });
                return false;
            }
            if (this.state.bank_branch_id === "") {
                swal("", "Please select bank branch.", "error");
                this.setState({ collect_amount: "" })
                return false;
            }
            if (this.state.bank_account_number === "") {
                swal("", "Please enter bank account number.", "error");
                this.setState({ collect_amount: "" })
                return false;
            }
        }
        this.getCalculation(data);
    }
    getCalculation = (data) => {
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/ime-forex/get/calculation`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("calculation response", res)
            this.setState({ loading: false })
            if (res.data.success) {
                this.setState(res.data.data);
            }
            else {
                swal("", res.data.message, "error");
                this.setState({
                    collect_amount: "",
                    payout_amount: "",
                    service_charge: "",
                    exchange_rate: ""
                });
            }
        })
    }
    checkMobile = e => {
        e.preventDefault();

        var lengthcheck = this.state.check_mobile.length;
        //  console.log("lenght",lengthcheck);
        if (lengthcheck !== 10) {
            swal("", "Please enter correct mobile number.", "error");
            return false;
        }

        this.setState({ loading: true })

        Axios.post(`${apiUrl}/remittance/ime-forex/check-customer`, { mobile_number: this.state.check_mobile }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res);
            this.setState({ loading: false })
            if (res.data.success) {
                this.getStaticData();
                this.setState({
                    show_form: res.data.beneficiary.length > 0 ? "show_beneficiary" : "remittance",
                    sender_name: res.data.data.Name,
                    sender_mobile_number: res.data.data.MobileNo,
                    beneficiary_list: res.data.beneficiary,
                    sender: res.data.sender
                });
            } else {
                if (this.state.user.role === "dealer" && this.state.user.ime_registered === "yes") {
                    swal({ text: res.data.message, icon: "error", buttons: ["ok", "Add New Customer"] }).then(ok => {
                        if (ok) {
                            this.setState({
                                show_form: "add_new_sender"
                            })
                        }
                    })
                }
                else if (this.state.user.ime_registered === "no" && res.data.showkycbtn) {
                    swal({ text: res.data.message, icon: "error", buttons: ["ok", "Update KYC"] }).then(ok => {
                        if (ok) {
                            this.setState({
                                show_form: "update_kyc",
                                send_user_id: res.data.send_user_id ? this.state.user.id : null
                            })
                        }
                    })
                }
                else {
                    swal({ text: res.data.message, icon: "error" });
                }
            }
        })
    }
    getStaticData = () => {
        Axios.get(`${apiUrl}/remittance/ime-forex/get/all/static/data`, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log("statisc data response", res)
            this.setState(res.data.data)
            setTimeout(() => {
                if (this.state.sender) {
                    this.setState({
                        sender_occupation: this.state.sender.Occupation
                    })
                }
            }, 300);
        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }

    handelPaymentType = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.value === "B") {
            Axios.post(`${apiUrl}/remittance/ime-forex/get/banks`, { country_id: "NPL" }, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({
                    banks_list: res.data.data,
                    branch_list: [],
                    bank_id: "",
                    bank_branch_id: "",
                    bank_account_number: "",
                    bank_account_type: ""
                })
            })
        }
        else {
            this.setState({
                banks_list: [],
                branch_list: [],
                bank_id: "",
                bank_branch_id: "",
                bank_account_number: "",
                bank_account_type: ""
            })
        }
    }
    handleBanksInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.value !== "") {
            this.setState({ loading: true })
            Axios.post(`${apiUrl}/remittance/ime-forex/get/bank/branches`, { bank_id: e.target.value }, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({ loading: false })
                this.setState({ branch_list: res.data.data })
            })
        }
        else {
            this.setState({
                branch_list: [],
                bank_id: "",
                bank_branch_id: "",
                bank_account_number: "",
                bank_account_type: ""
            })
        }
    }

    sendTransaction = e => {
        e.preventDefault();

        if (this.state.sender_occupation === "") {
            swal("", "Please select sender occupation...", "error");
            return false;
        }
        if (this.state.receiver_name === "") {
            swal("", "Please enter receiver name.", "error");
            return false;
        }
        // if (this.state.receiver_address === "") {
        //     swal("", "Please enter receiver address.", "error");
        //     return false;
        // }
        if (this.state.receiver_gender === "") {
            swal("", "Please select receiver gender.", "error");
            return false;
        }
        if (this.state.receiver_mobile_number === "") {
            swal("", "Please receiver mobile number.", "error");
            return false;
        }
        var lengthcheck = this.state.receiver_mobile_number.length;
        //  console.log("lenght",lengthcheck);
        if (lengthcheck !== 10) {
            swal("", "Please enter correct mobile number.", "error");
            return false;
        }
        if (this.state.receiver_city === "") {
            swal("", "Please enter receiver city.", "error");
            return false;
        }
        if (this.state.relationshipw === "") {
            swal("", "Please select relationship with sender.", "error");
            return false;
        }

        if (this.state.source_fund === "") {
            swal("", "Please select source of fund.", "error");
            return false;
        }
        if (this.state.remittance_reason === "") {
            swal("", "Please select purpose of remittance.", "error");
            return false;
        }
        if (this.state.payment_type === "") {
            swal("", "Please select payment type.", "error");
            return false;
        }
        if (this.state.payment_type === "B") {
            if (this.state.bank_id === "") {
                swal("", "Please select bank.", "error");
                return false;
            }
            if (this.state.bank_branch_id === "") {
                swal("", "Please select bank branch.", "error");
                return false;
            }
            if (this.state.bank_account_number === "") {
                swal("", "Please enter bank account number.", "error");
                return false;
            }
        }
        if (this.state.collect_amount === "" || +this.state.collect_amount <= 0) {
            swal("", "Please enter amount.", "error");
            return false;
        }
        this.setState({ loading: true })
        var data = {
            sender_name: this.state.sender_name,
            sender_mobile_number: this.state.sender_mobile_number,
            sender_occupation: this.state.sender_occupation,
            receiver_name: this.state.receiver_name,
            receiver_address: this.state.receiver_address,
            receiver_country:"NPL",
            receiver_gender: this.state.receiver_gender,
            receiver_mobile_number: +this.state.receiver_mobile_number,
            receiver_city: this.state.receiver_city,
            relationship: this.state.relationshipw,
            source_of_fund: this.state.source_fund,
            remittance_reason: this.state.remittance_reason,
            payment_type: this.state.payment_type,
            bank_id: this.state.bank_id,
            bank_branch_id: this.state.bank_branch_id,
            bank_account_number: this.state.bank_account_number,
            collect_amount: this.state.collect_amount,
            forex_session_id: this.state.forex_session_id,
            service_charge: this.state.service_charge,
            payout_amount: this.state.payout_amount,
            save_beneficiary: this.state.save_beneficiary,
            receiver_state:this.state.receiver_state,
            receiver_district:this.state.receiver_district,
            receiver_city:this.state.receiver_city,
            receiver_municipality:this.state.receiver_municipality
        }
        Axios.post(`${apiUrl}/remittance/ime-forex/send/transaction`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log("send transaction response", res)
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.setState({
                    otp_token: res.data.otptoken,
                    referance_number: res.data.referance_number,
                    show_form: "otp_verify",
                })
                var countDownDate = moment().add(60, 'seconds');
                var diff = 60;
                var self = this;
                var x = setInterval(function () {
                    diff = countDownDate.diff(moment());

                    if (diff <= 0) {
                        clearInterval(x);
                        self.setState({
                            resend_time: 0
                        })
                    } else
                        self.setState({
                            resend_time: diff
                        })
                }, 1000);
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    toggleModal = e => {
        this.setState({
            modalState: !this.state.modalState
        })
    }

    resendOTP = e => {
        e.preventDefault();
        var data = {
            action: "ST",
            icn_number:this.state.referance_number
        }
        Axios.post(`${apiUrl}/remittance/ime-forex/send/otp`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log("verify otp response", res)
            if (res.data.success) {
                swal("", res.data.message, "success");
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    verifyOtp = e => {
        e.preventDefault();
        var data = {
            referance_number: this.state.referance_number,
            otp_token: this.state.otp_token,
            otp: this.state.otp,
        }
        if (this.state.otp === "" || this.state.otp.length < 4) {
            swal("", "Please enter valid OTP.", "error");
            return false;
        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/ime-forex/confirm/transaction`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log("verify otp response", res)
            if (res.data.success) {
                if (res.data.data.user) {
                    sessionStorage.setItem(process.env.REACT_APP_USER_DETAILS, Cryp.encrypt(res.data.data.user));
                    this.setState({
                        user: res.data.data.user
                    })
                }
                swal("", res.data.message, "success");
                this.clearForms();
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    cancel = e => {
        e.preventDefault();
        this.clearForms();
    }
    clearForms = () => {
        this.setState({
            sender_name: "",
            sender_mobile_number: "",
            sender_occupation: "",
            receiver_name: "",
            receiver_address: "",
            receiver_gender: "",
            receiver_mobile_number: "",
            receiver_city: "",
            relationshipw: "",
            source_fund: "",
            payment_type: "",
            bank_id: "",
            bank_branch_id: "",
            bank_account_number: "",
            collect_amount: "",
            payout_amount: "",
            service_charge: "",
            exchange_rate: "",
            banks_list: [],
            branch_list: [],
            forex_session_id: "",
            otp_token: "",
            referance_number: "",
            modalState: false,
            otp: "",
            show_form: "check_user",
            sender_temp_country: "",
            check_mobile: "",
            sender_mobile: "",
            sender_first_name: "",
            sender_middle_name: "",
            sender_last_name: "",
            sender_nationality: "",
            sender_marital_status: "",
            sender_dob: "",
            sender_gender: "",
            sender_father_mother_name: "",
            sender_email: "",
            sender_occupation: "",
            sender_source_of_fund: "",
            sender_permanent_state: "",
            sender_permanent_district: "",
            sender_permanent_municipality: "",
            sender_permanent_address: "",
            sender_permanent_ward_number: "",
            sender_permanent_house_number: "",
            sender_temp_state: "",
            sender_temp_district: "",
            sender_temp_address: "",
            sender_temp_postal_code: "",
            sender_temp_house_number: "",
            sender_id_type: "",
            sender_id_number: "",
            sender_id_issue_date: "",
            sender_id_expiry_date: "",
            photo: null,
            id_proof: null,
            customer_token: "",
            otp_token: "",
            id_place_of_issue: "",
            sender_otp: false
        })
    }
    handleImage = (e, name) => {
        this.setState({
            [name]: e.target.files[0]
        })
    }
    handleInputCountry = (e, type = "permanent") => {
        this.setState({
            [e.target.name]: e.target.value,
            permanentstatelist: type === "permanent" ? [] : this.state.permanentstatelist,
            tempstatelist: type !== "permanent" ? [] : this.state.tempstatelist,
            districtlist: type === "permanent" ? [] : this.state.districtlist,
            tempdistrictlist: type !== "permanent" ? [] : this.state.tempdistrictlist,
            municipalitylist: type === "permanent" ? [] : this.state.municipalitylist
        })
        if (e.target.value != "") {
            this.setState({ loading: true })
            Axios.post(`${apiUrl}/remittance/ime-forex/get/state`, { country_id: e.target.value }, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({ loading: false })
                console.log(res)
                if (type === "permanent") {
                    this.setState({
                        permanentstatelist: res.data.data
                    })
                } else {
                    this.setState({
                        tempstatelist: res.data.data
                    })
                }
            })
        }
    }
    handleInputState = (e, type = "permanent") => {
        this.setState({
            [e.target.name]: e.target.value,
            districtlist: type === "permanent" ? [] : this.state.districtlist,
            tempdistrictlist: type !== "permanent" ? [] : this.state.tempdistrictlist,
            municipalitylist: []
        })
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/ime-forex/get/district`, { state_id: e.target.value }, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log(res)
            if (type === "temp") {
                this.setState({
                    tempdistrictlist: res.data.data
                })
            } else {
                this.setState({
                    districtlist: res.data.data
                })
            }
        })
    }
    handleInputDistrict = e => {
        this.setState({
            [e.target.name]: e.target.value,
            municipalitylist: []
        })
        if (this.state.sender_nationality === "NPL") {
            this.setState({ loading: true })
            Axios.post(`${apiUrl}/remittance/ime-forex/get/municipality`, { district_id: e.target.value }, {
                headers: {
                    Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
                }
            }).then(res => {
                this.setState({ loading: false })
                console.log(res)
                this.setState({
                    municipalitylist: res.data.data
                })
            })
        }
    }


    customerRegister = e => {
        e.preventDefault();
        if (this.state.sender_mobile === "") {
            swal("", "Please enter mobile number.", "error");
            return false;
        }
        var lengthcheck = this.state.sender_mobile.length;
        //  console.log("lenght",lengthcheck);
        if (lengthcheck !== 10) {
            swal("", "Please enter correct mobile number.", "error");
            return false;
        }
        if (this.state.sender_first_name === "") {
            swal("", "Please enter first name.", "error");
            return false;
        }
        if (this.state.sender_last_name === "") {
            swal("", "Please enter last name.", "error");
            return false;
        }
        if (this.state.sender_nationality === "") {
            swal("", "Please select nationality.", "error");
            return false;
        }
        if (this.state.sender_marital_status === "") {
            swal("", "Please select marital status.", "error");
            return false;
        }
        if (this.state.sender_dob === "") {
            swal("", "Please enter date of birth.", "error");
            return false;
        }
        if (this.state.sender_gender === "") {
            swal("", "Please select gender.", "error");
            return false;
        }
        if (this.state.sender_father_mother_name === "") {
            swal("", "Please enter father or mother name.", "error");
            return false;
        }
        if (this.state.sender_occupation === "") {
            swal("", "Please select occupation.", "error");
            return false;
        }
        if (this.state.sender_source_of_fund === "") {
            swal("", "Please select source of fund.", "error");
            return false;
        }
        if (this.state.sender_permanent_state === "") {
            swal("", "Please select permanent address state.", "error");
            return false;
        }
        if (this.state.sender_permanent_district === "") {
            swal("", "Please select permanent address district.", "error");
            return false;
        }
        if (this.state.sender_permanent_address === "") {
            swal("", "Please enter permanent address.", "error");
            return false;
        }
        if (this.state.sender_temp_state === "") {
            swal("", "Please select temporary address state.", "error");
            return false;
        }
        if (this.state.sender_temp_district === "") {
            swal("", "Please select temporary address district.", "error");
            return false;
        }
        if (this.state.sender_id_type === "") {
            swal("", "Please select id type.", "error");
            return false;
        }
        if (this.state.sender_id_number === "") {
            swal("", "Please enter id number.", "error");
            return false;
        }
        if (this.state.sender_id_issue_date === "") {
            swal("", "Please enter id issue date.", "error");
            return false;
        }
        if (this.state.sender_id_expiry_date === "") {
            swal("", "Please enter id expiry date.", "error");
            return false;
        }
        if (!this.state.id_proof) {
            swal("", "Please upload id proof.", "error");
            return false;
        }
        var formdata = new FormData();
        formdata.append("mobile_number", this.state.sender_mobile);
        formdata.append("first_name", this.state.sender_first_name);
        formdata.append("middle_name", this.state.sender_middle_name);
        formdata.append("last_name", this.state.sender_last_name);
        formdata.append("nationality", this.state.sender_nationality);
        formdata.append("marital_status", this.state.sender_marital_status);
        formdata.append("dob", moment(this.state.sender_dob).format('YYYY/MM/DD'));
        formdata.append("gender", this.state.sender_gender);
        formdata.append("father_or_mother_name", this.state.sender_father_mother_name);
        formdata.append("email", this.state.sender_email);
        formdata.append("occupation", this.state.sender_occupation);
        formdata.append("source_of_fund", this.state.sender_source_of_fund);
        formdata.append("state", this.state.sender_permanent_state);
        formdata.append("district", this.state.sender_permanent_district);
        formdata.append("municipality", this.state.sender_permanent_municipality);
        formdata.append("address", this.state.sender_permanent_address);
        formdata.append("ward_number", this.state.sender_permanent_ward_number);
        formdata.append("house_number", this.state.sender_permanent_house_number);
        formdata.append("temp_state", this.state.sender_temp_state);
        formdata.append("temp_district", this.state.sender_temp_district);
        formdata.append("temp_address", this.state.sender_temp_address);
        formdata.append("temp_postal_code", this.state.sender_temp_postal_code);
        formdata.append("temp_house_number", this.state.sender_temp_house_number);
        formdata.append("id_place_of_issue", this.state.id_place_of_issue);

        formdata.append("id_type", this.state.sender_id_type);
        formdata.append("id_number", this.state.sender_id_number);
        formdata.append("issue_date", moment(this.state.sender_id_issue_date).format('YYYY/MM/DD'));
        formdata.append("expiry_date", moment(this.state.sender_id_expiry_date).format('YYYY/MM/DD'));
        if (this.state.photo) {
            formdata.append("webphoto", this.state.photo);
        }
        formdata.append("webid_proof", this.state.id_proof);
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/ime-forex/customer/registration`, formdata, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.setState({
                    customer_token: res.data.customer_token,
                    otp_token: res.data.otptoken,
                    sender_otp: true
                })
            }
            else {
                swal("", res.data.message, "error");
            }
        }, err => {
            this.setState({ loading: false })
        })
    }

    verifySenderOtp = e => {
        e.preventDefault();
        var data = {
            customer_token: this.state.customer_token,
            otp_token: this.state.otp_token,
            otp: this.state.otp,
        }
        if (this.state.otp === "" || this.state.otp.length < 4) {
            swal("", "Please enter valid OTP.", "error");
            return false;
        }
        this.setState({ loading: true })
        Axios.post(`${apiUrl}/remittance/ime-forex/customer/registration/confirm`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            this.setState({ loading: false })
            console.log("verify otp response", res)
            if (res.data.success) {
                swal("", res.data.message, "success");
                this.clearForms();
            }
            else {
                swal("", res.data.message, "error");
            }
        })
    }
    getRelationship = (id) => {
        var x = id;
        this.state.relationship.forEach((rs) => {
            if (rs.option_id === id) {
                x = rs.value;
            }
        });
        return x;
    }
    selectSender = (e, bene) => {
        e.preventDefault();
        this.setState({
            receiver_name: bene.ReceiverName,
            receiver_address: bene.ReceiverAddress,
            receiver_gender: bene.ReceiverGender,
            receiver_mobile_number: bene.ReceiverMobileNo,
            receiver_city: bene.ReceiverCity,
            relationshipw: bene.Relationship,
            source_fund: bene.SourceOfFund,
            remittance_reason: bene.PurposeOfRemittance,
            payment_type: bene.PaymentType,
            bank_id: bene.BankId ? bene.BankId : "",
            bank_branch_id: bene.BankBranchId ? bene.BankBranchId : "",
            bank_account_number: bene.BankAccountNumber ? bene.BankAccountNumber : "",
            show_form: "remittance",
            bene_selected: true
        })
    }
    newTransaction = e => {
        e.preventDefault();
        this.setState({ show_form: "remittance" })
    }
    hidekyc = () => {
        this.setState({
            show_form: 'check_user',
            send_user_id: null
        })
    }
    setValue(e){
        console.log(e)
        setTimeout(() => {
            this.setState({
                receiver_state:e[0].state_id,
                receiver_district:e[0].district_id,
                receiver_city:e[0].name,
                receiver_municipality:e[0].id
            })
            console.log("ahasdfasdfa",this.state.receiver_state)
        }, 100);

    }
    render() {
        var kyc_functions = {
            hidekyc: this.hidekyc
        };
        return <>
            <Userheader user={this.state.user} />
            {this.state.loading && <div className="the-loader">
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}


            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="form-sec-dash">
                                <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p> Remittance </p>
                                        {this.state.show_form === "check_user" && <span>Search Customer</span>}
                                        {this.state.show_form === "otp_verify" && <span>Verify OTP</span>}
                                        {this.state.show_form === "add_new_sender" && <span>Add New Customer</span>}
                                        {this.state.show_form === "remittance" && <span>Send Transaction</span>}
                                        {this.state.show_form === "show_beneficiary" && <span>Saved Beneficiaries</span>}
                                        {this.state.show_form === "show_beneficiary" && <small className="float-right"><a href="" onClick={e => { this.newTransaction(e) }}>New Transaction</a></small>}
                                    </h3>
                                </div>
                                {this.state.show_form == "update_kyc" && <KycForm user_id={this.state.send_user_id} {...kyc_functions} />}
                                {this.state.show_form === "show_beneficiary" && <>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Mobile Number</th>
                                                    <th>Address</th>
                                                    <th>City</th>
                                                    <th>Relationship</th>
                                                    <th>Payment Type</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-space">
                                                {this.state.beneficiary_list.map((bene, key) => {
                                                    return <Tabledata bene={bene} key={key} getRelationship={this.getRelationship} selectSender={this.selectSender} />
                                                })}
                                            </tbody>
                                        </table>
                                    </div>


                                </>}
                                {this.state.show_form === "check_user" && <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label >Sender Mobile</label>
                                            <input type="number" className="form-control" name="check_mobile" placeholder="Enter Mobile Number" onChange={e => { this.handleInputMobile(e) }} value={this.state.check_mobile} />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="send-bt-dash">
                                            <a href="" onClick={e => { this.checkMobile(e) }}>Check</a>
                                        </div>
                                    </div>
                                </div>
                                }
                                {this.state.show_form === "otp_verify" && <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/otp.png")} alt="" />

                                            <label >OTP</label>
                                            <input type="number" className="form-control" placeholder="Enter OTP" onChange={e => { this.handleInputOTP(e) }} name="otp" value={this.state.otp} />
                                            {this.state.resend_time > 0 ? <><span className="float-right small">Please wait {this.state.resend_time} seconds to <a href="" className="text-dark" onClick={(e) => e.preventDefault()} style={{ pointerEvents: "none" }}>Resend OTP</a></span></> : <a href="" className="float-right small" onClick={(e) => this.resendOTP(e)} >Resend OTP</a>}

                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="send-bt-dash">
                                            <a href="" onClick={e => { this.verifyOtp(e) }}>Verify</a>
                                        </div>
                                    </div>
                                </div>}
                                {this.state.show_form === "add_new_sender" && <>
                                    {this.state.sender_otp && <><div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/otp.png")} alt="" />
                                                <label >OTP</label>
                                                <input type="text" className="form-control" placeholder="Enter OTP" onChange={e => { this.handleInputOTP(e) }} name="otp" value={this.state.otp} />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="send-bt-dash">
                                                <a href="" onClick={e => { this.verifySenderOtp(e) }}>Verify</a>
                                            </div>
                                        </div>
                                    </div>
                                    </>}
                                    {!this.state.sender_otp && <><div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/mobile_no.png")} alt="" />
                                                <label >Mobile No.</label>
                                                <input type="text" className="form-control" placeholder="Enter Phone Number" onChange={e => { this.handleInput(e) }} name="sender_mobile" value={this.state.sender_mobile} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/username.png")} alt="" />
                                                <label >First Name</label>
                                                <input type="text" className="form-control" placeholder="Enter First Name" onChange={e => { this.handleInput(e) }} name="sender_first_name" value={this.state.sender_first_name} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/username.png")} alt="" />
                                                <label >Middle Name</label>
                                                <input type="text" className="form-control" placeholder="Enter Middle Name" onChange={e => { this.handleInput(e) }} name="sender_middle_name" value={this.state.sender_middle_name} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/username.png")} alt="" />
                                                <label >Last Name</label>
                                                <input type="text" className="form-control" placeholder="Enter Last Name" onChange={e => { this.handleInput(e) }} name="sender_last_name" value={this.state.sender_last_name} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/username.png")} alt="" />
                                                <label >Marital Status</label>
                                                <select className="form-control" onChange={e => { this.handleInput(e) }} name="sender_marital_status" value={this.state.sender_marital_status} >
                                                    <option value="">Select</option>
                                                    {this.state.marital_status.map((ms, key) => {
                                                        return <option value={ms.option_id} key={key}>{ms.value}</option>;
                                                    })}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/id-proof.png")} alt="" />
                                                <label >DOB</label>
                                                <input type="date" className="form-control" onChange={e => { this.handleInput(e) }} name="sender_dob" value={this.state.sender_dob} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/gender.png")} alt="" />
                                                <label >Gender</label>
                                                <select className="form-control" onChange={e => { this.handleInput(e) }} name="sender_gender" value={this.state.sender_gender}>
                                                    <option value="">Select</option>
                                                    {this.state.gender.map((gen, key) => {
                                                        return <option value={gen.option_id} key={key}>{gen.value}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/username.png")} alt="" />
                                                <label >Father Or Mother Name</label>
                                                <input type="text" className="form-control" placeholder="Enter " onChange={e => { this.handleInput(e) }} name="sender_father_mother_name" value={this.state.sender_father_mother_name} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/username.png")} alt="" />
                                                <label >Sender Email</label>
                                                <input type="text" className="form-control" placeholder="Enter Email" onChange={e => { this.handleInput(e) }} name="sender_email" value={this.state.sender_email} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/occupation.png")} alt="" />
                                                <label >Sender Occupation</label>
                                                <select className="form-control" onChange={e => { this.handleInput(e) }} name="sender_occupation" value={this.state.sender_occupation}>
                                                    <option valule="">Select</option>
                                                    {this.state.occupation.map((occ, key) => {
                                                        return <option value={occ.option_id} key={key}>{occ.value}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <img src={require("assets/images/deposit.png")} alt="" />
                                                <label >Source of Fund</label>
                                                <select className="form-control" onChange={e => { this.handleInput(e) }} name="sender_source_of_fund" value={this.state.sender_source_of_fund}>
                                                    <option value="">Select</option>
                                                    {this.state.source_of_fund.map((sf, key) => {
                                                        return <option value={sf.option_id} key={key}>{sf.value}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4>Permanent address</h4>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/remmit.png")} alt="" />
                                                    <label >Country</label>
                                                    <select className="form-control" onChange={e => { this.handleInputCountry(e, "permanent") }} name="sender_nationality" value={this.state.sender_nationality} >
                                                        <option value="">Select</option>
                                                        <option value="IND">India</option>
                                                        <option value="NPL">Nepal</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >State</label>
                                                    <select className="form-control" onChange={e => { this.handleInputState(e) }} name="sender_permanent_state" value={this.state.sender_permanent_state}>
                                                        <option value="">Select</option>
                                                        {this.state.permanentstatelist.map((st, key) => {
                                                            return <option value={st.state_id} key={key}>{st.state}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >District</label>
                                                    <select className="form-control" onChange={e => { this.handleInputDistrict(e) }} name="sender_permanent_district" value={this.state.sender_permanent_district}>
                                                        <option value="">Select</option>
                                                        {this.state.districtlist.map((dis, key) => {
                                                            return <option value={dis.district_id} key={key}>{dis.district}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            {this.state.municipalitylist.length > 0 && <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >Municipality</label>
                                                    <select className="form-control" onChange={e => { this.handleInput(e) }} name="sender_permanent_municipality" value={this.state.sender_permanent_municipality}>
                                                        <option value="">Select</option>
                                                        {this.state.municipalitylist.map((dis, key) => {
                                                            return <option value={dis.municipality_id} key={key}>{dis.municipality}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >Address</label>
                                                    <textarea className="form-control" placeholder="Enter Address" onChange={e => { this.handleInput(e) }} name="sender_permanent_address" value={this.state.sender_permanent_address} ></textarea>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >Ward Number</label>
                                                    <input type="text" className="form-control" placeholder="Enter Postal Code" onChange={e => { this.handleInput(e) }} name="sender_permanent_ward_number" value={this.state.sender_permanent_ward_number} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >House Number</label>
                                                    <input type="text" className="form-control" placeholder="Enter House Number" onChange={e => { this.handleInput(e) }} name="sender_permanent_house_number" value={this.state.sender_permanent_house_number} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4>Temporary address</h4>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/remmit.png")} alt="" />
                                                    <label >Country</label>
                                                    <select className="form-control" onChange={e => { this.handleInputCountry(e, "temp") }} name="sender_temp_country" value={this.state.sender_temp_country} >
                                                        <option value="">Select</option>
                                                        <option value="IND">India</option>
                                                        <option value="NPL">Nepal</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >State</label>
                                                    <select className="form-control" onChange={e => { this.handleInputState(e, 'temp') }} name="sender_temp_state" value={this.state.sender_temp_state}>
                                                        <option value="">Select</option>
                                                        {this.state.tempstatelist.map((st, key) => {
                                                            return <option value={st.state_id} key={key}>{st.state}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >District</label>
                                                    <select className="form-control" onChange={e => { this.handleInput(e) }} name="sender_temp_district" value={this.state.sender_temp_district}>
                                                        <option value="">Select</option>
                                                        {this.state.tempdistrictlist.map((dis, key) => {
                                                            return <option value={dis.district_id} key={key}>{dis.district}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >Address</label>
                                                    <textarea className="form-control" placeholder="Enter Address" onChange={e => { this.handleInput(e) }} name="sender_temp_address" value={this.state.sender_temp_address} ></textarea>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >Postal Code</label>
                                                    <input type="text" className="form-control" placeholder="Enter Postal Code" onChange={e => { this.handleInput(e) }} name="sender_temp_postal_code" value={this.state.sender_temp_postal_code} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/adderss.png")} alt="" />
                                                    <label >House Number</label>
                                                    <input type="text" className="form-control" placeholder="Enter House Number" onChange={e => { this.handleInput(e) }} name="sender_temp_house_number" value={this.state.sender_temp_house_number} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4>Identity Proof</h4>
                                            </div>

                                            {this.state.sender_nationality === "IND" && <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/account_no.png")} alt="" />
                                                    <label >Id Type</label>
                                                    <select className="form-control" onChange={e => { this.handleInput(e) }} name="sender_id_type" value={this.state.sender_id_type}>
                                                        <option valule="">Select</option>
                                                        {this.state.customer_identification_type_indian.map((idt, key) => {
                                                            return <option value={idt.option_id} key={key}>{idt.value}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>}
                                            {this.state.sender_nationality === "NPL" && <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/account_no.png")} alt="" />
                                                    <label >Id Type</label>
                                                    <select className="form-control" onChange={e => { this.handleInput(e) }} name="sender_id_type" value={this.state.sender_id_type}>
                                                        <option valule="">Select</option>
                                                        {this.state.customer_identification_type_neplease.map((idt, key) => {
                                                            return <option value={idt.option_id} key={key}>{idt.value}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>}

                                            {this.state.sender_nationality === "NPL" && <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/account_no.png")} alt="" />
                                                    <label >ID Issue Place </label>
                                                    <select className="form-control" onChange={e => { this.handleInput(e) }} name="id_place_of_issue" value={this.state.id_place_of_issue}>
                                                        <option valule="">Select</option>
                                                        <option value="5001">Ilam</option>
                                                        <option value="5002">Jhapa</option>
                                                        <option value="5003">Panchthar</option>
                                                        <option value="5004">Taplejung</option>
                                                        <option value="5005">Bhojpur</option>
                                                        <option value="5006">Dhankuta</option>
                                                        <option value="5007">Morang</option>
                                                        <option value="5008">Sankhuwasabha</option>
                                                        <option value="5009">Sunsari</option>
                                                        <option value="5010">Terhathum</option>
                                                        <option value="5011">Khotang</option>
                                                        <option value="5012">Okhaldhunga</option>
                                                        <option value="5013">Saptari</option>
                                                        <option value="5014">Siraha</option>
                                                        <option value="5015">Solukhumbu</option>
                                                        <option value="5016">Udayapur</option>
                                                        <option value="5017">Dhanusa</option>
                                                        <option value="5018">Dolakha</option>
                                                        <option value="5019">Mahottari</option>
                                                        <option value="5020">Ramechhap</option>
                                                        <option value="5021">Sarlahi</option>
                                                        <option value="5022">Sindhuli</option>
                                                        <option value="5023">Bhaktapur</option>
                                                        <option value="5024">Dhading</option>
                                                        <option value="5025">Kathmandu</option>
                                                        <option value="5026">Kavrepalanchok</option>
                                                        <option value="5027">Lalitpur</option>
                                                        <option value="5028">Nuwakot</option>
                                                        <option value="5029">Rasuwa</option>
                                                        <option value="5030">Sindhupalchok</option>
                                                        <option value="5031">Gorkha</option>
                                                        <option value="5032">Kaski</option>
                                                        <option value="5033">Lamjung</option>
                                                        <option value="5034">Manang</option>
                                                        <option value="5035">Syangja</option>
                                                        <option value="5036">Tanahu</option>
                                                        <option value="5037">Bara</option>
                                                        <option value="5038">Chitawan</option>
                                                        <option value="5039">Makwanpur</option>
                                                        <option value="5040">Parsa</option>
                                                        <option value="5041">Rautahat</option>
                                                        <option value="5042">Dolpa</option>
                                                        <option value="5043">Humla</option>
                                                        <option value="5044">Jumla</option>
                                                        <option value="5045">Kalikot</option>
                                                        <option value="5046">Mugu</option>
                                                        <option value="5047">Dang</option>
                                                        <option value="5048">Pyuthan</option>
                                                        <option value="5049">Rolpa</option>
                                                        <option value="5050">Rukum</option>
                                                        <option value="5051">Salyan</option>
                                                        <option value="5052">Banke</option>
                                                        <option value="5053">Bardiya</option>
                                                        <option value="5054">Dailekh</option>
                                                        <option value="5055">Jajarkot</option>
                                                        <option value="5056">Surkhet</option>
                                                        <option value="5057">Achham</option>
                                                        <option value="5058">Bajhang</option>
                                                        <option value="5059">Bajura</option>
                                                        <option value="5060">Doti</option>
                                                        <option value="5061">Kailali</option>
                                                        <option value="5062">Baglung</option>
                                                        <option value="5063">Mustang</option>
                                                        <option value="5064">Myagdi</option>
                                                        <option value="5065">Parbat</option>
                                                        <option value="5066">Arghakhanchi</option>
                                                        <option value="5067">Gulmi</option>
                                                        <option value="5068">Kapilvastu</option>
                                                        <option value="5069">Nawalparasi</option>
                                                        <option value="5070">Palpa</option>
                                                        <option value="5071">Rupandehi</option>
                                                        <option value="5072">Baitadi</option>
                                                        <option value="5073">Dadeldhura</option>
                                                        <option value="5074">Darchula</option>
                                                        <option value="5075">Kanchanpur</option>
                                                        <option value="5076">Rukum West</option>
                                                        <option value="5077">Nawalparasi West</option>
                                                    </select>
                                                </div>
                                            </div>}

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/id-proof.png")} alt="" />
                                                    <label >Id Number</label>
                                                    <input type="text" className="form-control" placeholder="Enter Id Number" onChange={e => { this.handleInput(e) }} name="sender_id_number" value={this.state.sender_id_number} />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/account_no.png")} alt="" />
                                                    <label >Id Issue Date</label>
                                                    <input type="date" className="form-control" placeholder="Enter Id Issue Date" onChange={e => { this.handleInput(e) }} name="sender_id_issue_date" value={this.state.sender_id_issue_date} />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <img src={require("assets/images/account_no.png")} alt="" />
                                                    <label >Id Expiry Date</label>
                                                    <input type="date" className="form-control" placeholder="Enter Id Expiry Date" onChange={e => { this.handleInput(e) }} name="sender_id_expiry_date" value={this.state.sender_id_expiry_date} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div >
                                                    <img src={require("assets/images/username.png")} alt="" />
                                                    <label >Photo</label>
                                                    <input type="file" onChange={e => { this.handleImage(e, 'photo') }} name="sender_photo" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div >
                                                    <img src={require("assets/images/id-proof.png")} alt="" />
                                                    <label >Id Proof</label>
                                                    <input type="file" onChange={e => { this.handleImage(e, 'id_proof') }} name="sender_id_proof" />
                                                </div>
                                            </div>
                                            <div className="row" >



                                 <input type="checkbox" style={{    "width": "55px", "height": "18px"}} className="" name="checkbox" placeholder="Enter address" onChange={e => { this.handleInput(e) }} value={this.state.checkbox} />
                                <p>By clicking the check box.I accept the below declaration.</p> <br></br>
                                <p>1. I hereby declare, that time information filled here in above is correct as per the information provided by the customer.</p><br></br>
                                <p>2. The Customer has approached me in person for the remittance service.</p><br></br>
                                <p>3. The Customer is a Nepal Migrant.</p><br></br>
                                <p>4. The Customer ID document have been verified in original.</p><br></br>
                                <p>5. The Customer has not registered under any other mobile no, or under any other ID document.</p>


                         </div>
                                            <div className="col-md-12">
                                                <div className="send-bt-dash">
                                                    <a href="" onClick={e => { this.cancel(e) }}>Cancel</a>&nbsp;&nbsp;
                                                    <a href="" onClick={e => { this.customerRegister(e) }}>Register</a>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </>}
                                {this.state.show_form === "remittance" &&  <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/username.png")} alt="" />

                                            <label >Sender Name</label>
                                            <input type="text" className="form-control" placeholder="Enter Sender Name" onChange={e => { this.handleInput(e) }} name="sender_name" value={this.state.sender_name} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />

                                            <label >Sender Mobile No</label>
                                            <input type="text" placeholder="Enter Mobile No" onChange={e => { this.handleInput(e) }} name="sender_mobile_number" value={this.state.sender_mobile_number} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/occupation.png")} alt="" />

                                            <label >Sender Occupation</label>
                                            <select className="form-control" onChange={e => { this.handleInput(e) }} name="sender_occupation" value={this.state.sender_occupation}>
                                                <option valule="">Select</option>
                                                {this.state.occupation.map((occ, key) => {
                                                    return <option value={occ.option_id} key={key}>{occ.value}</option>
                                                })}

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/username.png")} alt="" />
                                            <label >Recevier Name</label>
                                            <input type="text" onChange={e => { this.handleInput(e) }} name="receiver_name" value={this.state.receiver_name} placeholder="Enter Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/adderss.png")} alt="" />

                                            <label >Receiver Country</label>
                                            <select className="form-control" onChange={e => { this.handleInput(e) }} name="receiver_country" value={this.state.receiver_country}>
                                                <option value="NPL">Nepal</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/gender.png")} alt="" />
                                            <label >Receiver Gender</label>
                                            <select className="form-control" onChange={e => { this.handleInput(e) }} name="receiver_gender" value={this.state.receiver_gender}>
                                                <option value="">Select</option>
                                                {this.state.gender.map((gen, key) => {
                                                    return <option value={gen.option_id} key={key}>{gen.value}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label >Receiver Mobile Number</label>
                                            <input type="text" onChange={e => { this.handleInput(e) }} name="receiver_mobile_number" value={this.state.receiver_mobile_number} placeholder="Enter Mobile Number" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/city.png")} alt="" />
                                            <label >Receiver City</label>
                                            {/* <input type="text" onChange={e => { this.handleInput(e) }} name="receiver_city" value={this.state.receiver_city} placeholder="Enter City" /> */}
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                labelKey="name"
                                                  onChange={e => { this.setValue(e) }}
                                                options={this.state.items}
                                                placeholder="Choose a state..."
                                                //   selected={singleSelections}
                                                />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/relationship.png")} alt="" />

                                            <label >Relationship</label>
                                            <select className="form-control" onChange={e => { this.handleInput(e) }} name="relationshipw" value={this.state.relationshipw}>
                                                <option value="">Select</option>
                                                {this.state.relationship.map((rel, key) => {
                                                    return <option value={rel.option_id} key={key}>{rel.value}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label >Source of Fund</label>
                                            <select className="form-control" onChange={e => { this.handelPaymentType(e) }} name="source_fund" value={this.state.source_fund}>
                                                <option value="">Select</option>
                                                {this.state.source_of_fund.map((sf, key) => {
                                                    return <option value={sf.option_id} key={key}>{sf.value}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src="images/mobile_no.png" alt="" />
                                            <label >Purpose of Remittance</label>
                                            <select className="form-control" onChange={e => { this.handelPaymentType(e) }} name="remittance_reason" value={this.state.remittance_reason}>
                                                <option value="">Select</option>
                                                {this.state.purpose_of_remittance.map((rr, key) => {
                                                    return <option value={rr.option_id} key={key}>{rr.value}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/mobile_no.png")} alt="" />
                                            <label >Payment Mode</label>
                                            <select className="form-control" onChange={e => { this.handelPaymentType(e) }} name="payment_type" value={this.state.payment_type}>
                                                <option value="">Select</option>
                                                <option value="C">Cash Payment</option>
                                                <option value="B">Bank Deposit</option>
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.banks_list.length > 0 && <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/bank-name.png")} alt="" />
                                            <label >Bank</label>
                                            <select className="form-control" onChange={e => { this.handleBanksInput(e) }} name="bank_id" value={this.state.bank_id}>
                                                <option value="">Select</option>
                                                {this.state.banks_list.map((bank, key) => {
                                                    return <option value={bank.bank_id} key={key}>{bank.bank}</option>
                                                })}

                                            </select>
                                        </div>
                                    </div>}
                                    {this.state.branch_list.length > 0 && <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/bank-name.png")} alt="" />
                                            <label >Bank Branch</label>
                                            <select className="form-control" onChange={e => { this.handleInput(e) }} name="bank_branch_id" value={this.state.bank_branch_id}>
                                                <option value="">Select</option>
                                                {this.state.branch_list.map((br, key) => {
                                                    return <option value={br.branch_id} key={key}>{br.branch}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>}

                                    {this.state.payment_type === "B" && <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/account_no.png")} alt="" />
                                            <label >Bank Account Number</label>
                                            <input type="text" onChange={e => { this.handleInput(e) }} name="bank_account_number" value={this.state.bank_account_number} placeholder="Enter Bank Account Number" />
                                        </div>
                                    </div>}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/amount.png")} alt="" />
                                            <label >Amount(in &#8377;)</label>
                                            <input type="number" onChange={e => { this.handleInput(e) }} onBlur={e => { this.handleOnBlur(e) }} name="collect_amount" value={this.state.collect_amount} placeholder="Enter Amount" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/amount.png")} alt="" />
                                            <label >Amount Collected(in &#8377;)</label>
                                            <input type="text" onChange={e => { this.handleInput(e) }} name="collect_amount" value={this.state.collect_amount} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/amount.png")} alt="" />
                                            <label >Amount Payable(in NPR)</label>
                                            <input type="text" onChange={e => { this.handleInput(e) }} name="payout_amount" value={this.state.payout_amount} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/amount.png")} alt="" />
                                            <label >Service Charge(in &#8377;)</label>
                                            <input type="text" onChange={e => { this.handleInput(e) }} name="service_charge" value={this.state.service_charge} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <img src={require("assets/images/amount.png")} alt="" />
                                            <label >Exchange Rate</label>
                                            <input type="text" onChange={e => { this.handleInput(e) }} name="exchange_rate" value={this.state.exchange_rate} disabled={true} />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        {!this.state.bene_selected && <div className="col-md-6">
                                            <div className="check_box">
                                                <label >Save Beneficiary</label>
                                                <input type="checkbox" onChange={e => { this.handleInputCheckbox(e) }} name="save_beneficiary" value={this.state.save_beneficiary} />
                                            </div>
                                        </div>}
                                        <div className="send-bt-dash">

                                            <a href="" onClick={e => { this.cancel(e) }}>Cancel</a>&nbsp;&nbsp;
                                            <a href="" onClick={e => { this.sendTransaction(e) }}>Send</a>
                                        </div>
                                    </div>
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <ReceiptModel modalState={this.state.modalState} toggleModal={this.toggleModal} />
            <Userfooter />
            <ServiceModal {...this.state} toggleServiceModal={this.toggleServiceModal} apiUrl={apiUrl} />
        </>;
    }
}
export default Remittance;
function Tabledata(props) {
    return <><tr className="spacer" >
        <td colSpan="100%"></td>
    </tr>
        <tr>
            <td>{props.bene.ReceiverName}</td>
            <td>{props.bene.ReceiverMobileNo}</td>
            <td>{props.bene.ReceiverAddress}</td>
            <td>{props.bene.ReceiverCity}</td>
            <td>{props.getRelationship(props.bene.Relationship)}</td>
            <td>{props.bene.PaymentType === "C" ? "Cash Payment" : "Bank Deposit"}</td>
            <td><a href="" onClick={e => { props.selectSender(e, props.bene) }} title="select beneficiary"><i className="fa fa-send"></i></a></td>
        </tr></>
}
