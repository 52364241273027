import React from "react";
import Usersidebar from "./Usersidebar";
import Userfooter from "./Footer";
import Userheader from "./Header";
import Pagination from "./Pagination";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import swal from 'sweetalert';

const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

class Wallethostory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            history: [],
            pagination_data: {
                current_page: 1,
                data: [],
                first_page_url: "",
                from: 1,
                last_page: 1,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 10,
                prev_page_url: null,
                to: 0,
                total: 0
            }
        }
    }

    componentDidMount = () => {
        if (!sessionStorage.getItem("_access")) {
            this.props.history.push("/");

        }
        this.setState({
            user: Cryp.decrypt(sessionStorage.getItem(process.env.REACT_APP_USER_DETAILS))
        })
        this.getData();
    }

    getData = (page = 1) => {
        var data = {};
        Axios.post(`${apiUrl}/user/remittance/wallet/history?page=${page}`, data, {
            headers: {
                Authorization: "Bearer " + Cryp.decrypt(sessionStorage.getItem("_access"))
            }
        }).then(res => {
            console.log(res)
            this.setState({
                history: res.data.data.remittance.data,
                pagination_data: res.data.data.remittance
            })
        }, err => {
            swal("", "Session expired. Please login again.").then(o => {
                sessionStorage.clear();
                this.props.history.push("/");
            })
        })
    }
    logout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
    }
    render() {
        return <>
            <Userheader user={this.state.user} />
            <section className="main-sidebar_dash">
                <div className="container-fluid">
                    <div className="row">
                        <Usersidebar logout={this.logout} />
                        <div className="col-md-10 right_contentsec">
                            <div className="maine-table-dash-sec">
                                <div className="heading-title-sec">
                                    <h3 className="admin-heading bg-offwhite">
                                        <p> Remittance Wallet History </p>
                                        <span>{`Showing ${this.state.pagination_data.from} to ${this.state.pagination_data.to} from ${this.state.pagination_data.total} records`}</span>
                                    </h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Date & Time</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Old Balance</th>
                                                <th>New Balance</th>
                                                <th>Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-space">
                                            {this.state.history.map((hs, key) => {
                                                return <><tr className="spacer">
                                                    <td colSpan=""></td>
                                                </tr>
                                                    <tr>
                                                        <td>{moment(hs.created_at).format('DD-MMM-YYYY, h:mm:ss a')}</td>
                                                        <td><i className="fa fa-inr" aria-hidden="true"></i>{(+hs.amount).toFixed(2)}</td>
                                                        <td className="status-arrow">

                                                            {hs.type === "credit" && <a href="" onClick={e => { e.preventDefault() }} className="green-status"><i className="fa fa-caret-up" aria-hidden="true"></i></a>}
                                                            {hs.type === "debit" && <a href="" onClick={e => { e.preventDefault() }} className="red-status"><i className="fa fa-caret-down" aria-hidden="true"></i></a>}
                                                        </td>
                                                        <td><i className="fa fa-inr" aria-hidden="true"></i>{hs.old_balance}</td>
                                                        <td><i className="fa fa-inr" aria-hidden="true"></i>{hs.new_balance}</td>
                                                        <td>
                                                            {hs.description.length > 15 ? hs.description.substring(0, 15) : hs.description}...&nbsp;
                                                            <i className="fa fa-info-circle" aria-hidden="true" style={{ cursor: "pointer" }} title={hs.description}></i>
                                                        </td>
                                                    </tr>
                                                </>;
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination pagination_data={this.state.pagination_data} getData={this.getData} />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Userfooter />
        </>;
    }
}
export default Wallethostory;
