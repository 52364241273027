import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import * as moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import Axios from "axios";
import { Modal } from "reactstrap";
import swal from 'sweetalert';
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

const url = window.location.href;
var apiUrl = process.env.REACT_APP_API_URL_LIVE;
var imageUrl = process.env.REACT_APP_IMAGE_URL_LIVE;
if (!url.includes('onlinesaathi.org')) {
    apiUrl = process.env.REACT_APP_API_URL;
    imageUrl = process.env.REACT_APP_IMAGE_URL;
}

// import "../../assets/css/style.css";
class Network extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           loading:false,
           states: [],
           total_agent:0,
        }
     }
     componentDidMount = () => {
        this.getstates();
     }
     getstates = () => {
       
       
       
        Axios.get(`${apiUrl}/network/list`, {
           headers: {
              Authorization: "Bearer " + sessionStorage.getItem("_access")
           }
        }).then(res => {
           if (res.data.success) {
            
                this.setState({
                    states:res.data.data,
                    total_agent:res.data.total_agent
                })
           } else {
            this.setState({
                states:[]
            })
           }
        })
     }
    render() {
        return <>
            <div className="site_home">
                <Header banner="network" h1Text="Network" bannerclassName="inner_head network_head" />

                <section className="network_sec">
                    <div className="container">
                        <div className="network">
                            <table style={{"width":"100%"}} cellspacing="0" border="0">
                                <tbody>                                  
                                    <tr>
                                        <th>States</th>
                                        <th>No of Agents</th>
                                    </tr>
                                    {this.state.states.map((hs, key) => {
                                          
                                   return  <tr>
                                        <td>{hs.state}</td>
                                        <td>{hs.agent}</td>
                                    </tr>
                                      })}
                                 
                                    <tr>
                                        <td><strong>Total</strong></td>
                                        <td><strong>{this.state.total_agent}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>;
    }
}

export default Network;